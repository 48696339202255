import { FunctionComponent, useEffect } from 'react'
import { Box, InputBase, Typography, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material'

// IMPORTADOS
import { FormProgramValues } from '../../../../interfaces/pdi/program'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import ErrorField from '../../../ErrorField'
import CustomSelect from '../../../inputSelect/CustomSelect'
import { Objetive } from '../../../../interfaces/pdi/objetive'
import { formProgramSchema } from '../../../../schemas/pdi/programSchema'
import formStyles from '../../form.styles'
import SelectPagination from '../../../inputSelect/SelectPagination'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface FormProgramProp {
  defaultValues?: Partial<FormProgramValues>
  pdi: PdiModel
  objetives: Objetive[]
  onCancel: () => void
  onSubmit: (formValues: FormProgramValues, reset: () => void) => void
  viewMode?: 'create' | 'update'
}

const _defaultValues: Partial<FormProgramValues> = {
  description: '',
  idObjetive: '',
  title: '',
}

const FormProgram: FunctionComponent<FormProgramProp> = ({
  viewMode = 'create',
  pdi,
  objetives,
  defaultValues = _defaultValues,
  onSubmit,
  onCancel,
}) => {
  const getFixInitialValues = (): Partial<FormProgramValues> => {
    const { idObjetive } = defaultValues
    const isInRange = objetives.some(ob => ob._id === idObjetive)
    return { ...defaultValues, idObjetive: isInRange ? idObjetive : '', idPdi: pdi._id }
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormProgramValues>({
    mode: 'onChange',
    resolver: yupResolver(formProgramSchema),
    defaultValues: _defaultValues,
  })

  useEffect(() => {
    const fixDefaultValues = getFixInitialValues()
    reset(fixDefaultValues)
  }, [defaultValues])

  const submit: SubmitHandler<FormProgramValues> = formValues => onSubmit(formValues, () => reset(_defaultValues))

  return (
    <>
      {/**********************************
                CONTENEDOR FORMULARIO GUARDAR
            ***********************************/}
      <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
        {/**************
                    OBJETIVO
                ***************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Nombre del objetivo</Typography>

          <Controller
            name="idObjetive"
            control={control}
            render={({ field }) => <SelectPagination {...field} propValue="_id" propText="title" items={objetives} />}
          />
        </Box>

        {/***********
                    TITULO
                ************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Título</Typography>

          {/**********
                        INPUT
                    **********/}
          <Box sx={formStyles.input}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.title?.message} />
        </Box>

        {/****************
                    DESCRIPCION
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Descripción</Typography>

          {/***********
                        INPUT
                    ***********/}
          <Box
            sx={{
              ...formStyles.inputDescription,
              minHeight: '174px',
            }}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputBase multiline {...field} maxRows={6} sx={formStyles.inputBaseDescription} />
              )}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.description?.message} />
        </Box>

        {/**************************
                    PORCENTAJE DE CUMPLIMIENTO
                ****************************/}
        {/* <Box sx={formStyles.percentageOfCompletion}>
                    <Typography>Porcentaje de cumplimiento</Typography>
                    <Box sx={formStyles.input}>
                        <Controller
                            name="percentageOfCompletion"
                            control={control}
                            render={({ field: { value, ...field } }) => (
                                <InputBase
                                    {...field}
                                    placeholder='100%...'
                                    value={value ?? ''}
                                    type='number'
                                    sx={formProgramsStyles.inputBase}
                                />
                            )}
                        />
                    </Box>
                    <ErrorField message={errors.percentageOfCompletion?.message} />
                </Box> */}

        {/***********
                    ESTADO 
                ************/}
        {viewMode === 'update' && (
          <>
            <Box sx={formStyles.containerState}>
              <Typography sx={formStyles.title}>Estado</Typography>

              {/***********************
                                CHECKBOX DE ESTADO
                            ***********************/}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === true ? true : false}
                          onChange={({ target: { checked } }) => onChange(checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Activo"
                  labelPlacement="start"
                />

                <FormControlLabel
                  className="inactivo"
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === false ? true : false}
                          onChange={({ target: { checked } }) => onChange(!checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Inactivo"
                  labelPlacement="start"
                />
              </FormGroup>
            </Box>

            {/*********************
                            MENSAJE DE ERROR
                        *********************/}
            <ErrorField message={errors.state?.message} />
          </>
        )}

        {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
        <Box sx={formStyles.divButton}>
          {/*****************************
                        BOTONES GUARDAR/CANCELAR
                    ******************************/}
          <div>
            {/********************
                            BOTON GUARDAR
                        *********************/}
            <Button disableTouchRipple type="submit" disabled={!isValid} sx={formStyles.buttonSave}>
              Guardar
            </Button>

            {/********************
                            BOTON CANCELAR
                        *********************/}
            <Button onClick={onCancel} disableTouchRipple sx={formStyles.buttonCancel}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default FormProgram
