import { Box, Pagination } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import CardItemPdi from "../../../cards/cardItemPdi/CardItemPdi";
import ModalDetailItemPdi, {
    ModalDetailItemPdiProps,
} from "../../../modals/modalDetailItemPdi/ModalDetailItemPdi";
import IconDirection from "../../../icons/IconDirection";
import usePagination from "../../../../hooks/usePagination";
import dayjs from "dayjs";

// IMPORTADOS
import { Challenge } from "../../../../interfaces/pdi/challenge";
import { Project } from "../../../../interfaces/pdi/project";
import tableItemsPdiStyles from "../tableItemsPdiStyles";
import ConfirmAlert, {
    ConfirmAlertprops,
} from "../../../Alerts/confirmAlert/ConfirmAlert";
import paginationStyles from "../../../Pagination.styles";

interface TableChallengesProps {
    challenges: Challenge[];
    projects: Project[];
    filterOption: Project | null;
    onUpdate: (challenge: Challenge) => void;
    onDelete: (challenge: Challenge) => void;
}

const itemsPerPage = 9;

const TableChallenges: FunctionComponent<TableChallengesProps> = ({
    challenges,
    projects,
    filterOption,
    onUpdate,
    onDelete,
}) => {
    const { countPage, currentPage, currentData, handleChangePage } =
        usePagination(challenges, itemsPerPage);

    const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>(
        {
            show: false,
            confirmMessage: "Estas seguro que deseas eliminar este reto?",
            confirmMessageBottom:
                "Recuerde que si elimina este reto usted no podrá editarla",
            textButtonActionConfirm: "Si",
            textButtonActionCancel: "No",
        }
    );

    const [detailModalData, setDetailModalData] =
        useState<ModalDetailItemPdiProps>({
            show: false,
            title: `Detalles del reto`,
            itemsRender: [],
            textButtonAction: "Editar",
        });

    const handleConfirmAlertData = (challenge: Challenge) => {
        setConfirmAlertData((state) => ({
            ...state,
            show: true,
            onClickButtonActionConfirm: () => {
                setConfirmAlertData((state) => ({ ...state, show: false }));
                onDelete(challenge);
            },
        }));
    };

    const handleDetailModal = (
        challenge: Challenge,
        itemsRender: ModalDetailItemPdiProps["itemsRender"]
    ) => {
        setDetailModalData((state) => ({
            ...state,
            show: true,
            itemsRender,
            onClickButtonAction: () => {
                setDetailModalData((state) => ({ ...state, show: false }));
                onUpdate(challenge);
            },
        }));
    };

    const getNameProject = (id: string) => {
        const project = projects.find(
            (project) => project._id === id && project.is_active
        );
        if (!project) return "No disponible.";
        return project.title;
    };

    const getDate = (timestamp: number) => {
        return dayjs.unix(timestamp).format("DD/MM/YYYY");
    };

    useEffect(() => {
        handleChangePage(1);
    }, [filterOption?._id]);
    return (
        <>
            <ConfirmAlert
                {...confirmAlertData}
                onClickButtonActionCancel={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
                onCloseForButtom={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
            />
            <ModalDetailItemPdi
                {...detailModalData}
                maxWidth={1067}
                maxHeight={656}
                onCloseForButtom={() =>
                    setDetailModalData((state) => ({ ...state, show: false }))
                }
            />
            {/*********************
                CONTENEDOR RETOS
            *********************/}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        ...tableItemsPdiStyles.container,
                        justifyContent:
                            currentData().length === 2
                                ? "center !important"
                                : "space-around",
                    }}
                >
                    {/*****************
                        CARDS DE RETOS
                    ******************/}
                    {currentData().map((challenge) => (
                        <CardItemPdi
                            key={`${challenge._id}`}
                            title={challenge.description}
                            text={getDate(challenge.complienceDate)}
                            smallText={challenge.state ? "Activo" : "Inactivo"}
                            textButtonLeft="Editar"
                            textButtonRight="Eliminar"
                            textButtonDetail={<IconDirection />}
                            onClickButtonDetail={() =>
                                handleDetailModal(challenge, [
                                    {
                                        title: "Descripción",
                                        value: challenge.description,
                                    },
                                    {
                                        title: "Fecha de cumplimiento",
                                        value: getDate(
                                            challenge.complienceDate
                                        ),
                                    },
                                    {
                                        title: "Nombre del proyecto",
                                        value: getNameProject(
                                            challenge.idProject
                                        ),
                                    },
                                    {
                                        title: "Estado",
                                        value: challenge.state
                                            ? "Activo"
                                            : "Inactivo",
                                    },
                                ])
                            }
                            onClickButtonLeft={() => onUpdate(challenge)}
                            onClickButtonRight={() =>
                                handleConfirmAlertData(challenge)
                            }
                        />
                    ))}
                    {currentData().length == 1 && (
                        <>
                            <Box />
                            <Box />
                        </>
                    )}
                    {currentData().length == 2 && <Box />}
                </Box>

                {/****************
                    PAGINACION
                ****************/}
                <Box sx={tableItemsPdiStyles.pagesList}>
                    <Pagination
                        sx={paginationStyles.paginationNumbers}
                        page={currentPage}
                        defaultPage={1}
                        count={countPage}
                        onChange={(e, _page) => handleChangePage(_page)}
                    />
                </Box>
            </Box>
        </>
    );
};

export default TableChallenges;
