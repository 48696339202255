import React, { useState } from 'react'
import { Box, Button, Collapse, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import IconFilter from '../../icons/IconFilter'

interface DataButtonFilter {
  title?: string
  width?: string
  api: any[]
  setValue?: Function
}

const ButtonFilter = ({ title = 'Titulo Filtro', width = '141px', api = [], setValue }: DataButtonFilter) => {
  const [filter, setFilter] = useState(false)
  const [openCollapse, setOpenCollapse] = useState(false)

  // ABRIR Y CERRAR FILTRO
  const openFilter = () => {
    setFilter(!filter)
    setOpenCollapse(false)
  }

  // VALOR DEL FILTRO
  const handleItem = (value: any) => {
    setValue && setValue(value)
    setFilter(!filter)
  }

  // ABRIR Y CERRAR SUBFILTRO
  const openFilterCollapse = () => {
    setOpenCollapse(!openCollapse)
  }

  return (
    // CONTENEDOR BOTON FILTRAR
    <Box className="buttonFilter">
      {/*****************/}
      {/* BOTON FILTRAR */}
      {/*****************/}
      <Button disableTouchRipple onClick={openFilter}>
        <IconFilter />
        <Typography>{title}</Typography>
      </Button>

      {/*****************/}
      {/* LISTA FILTRAR */}
      {/*****************/}
      <Box className="contentListFilter">
        {/************/}
        {/* COLLAPSE */}
        {/************/}
        <Collapse in={filter}>
          <List className="listCollapse1" sx={{ width: { width } }}>
            {api.map(item => (
              <>
                <ListItem disablePadding key={item.value}>
                  <ListItemButton
                    className="buttonListFilter"
                    disableTouchRipple
                    onClick={item.children ? openFilterCollapse : () => handleItem(item.value)}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>

                {/*********/}
                {/* HIJOS */}
                {/*********/}
                {item.children?.map((item2: any) => (
                  // COLLAPSE 2
                  <Collapse in={openCollapse}>
                    <List>
                      <ListItemButton disableTouchRipple onClick={() => handleItem(item2.value)}>
                        <ListItemText primary={item2.name} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
              </>
            ))}
          </List>
        </Collapse>
      </Box>
    </Box>
  )
}

export default ButtonFilter
