import { FunctionComponent } from "react";

interface IconX2Props {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconX2: FunctionComponent<IconX2Props> = ({
    width = 10,
    height = 10,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 1L1 9"
                stroke="#003487"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M9 9L1 1"
                stroke="#003487"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconX2;
