import { Box, Typography, Button } from '@mui/material'

// IMPORTADOS
import TableProcess from './TableProcess'
import processStyles from './process.styles'
import IconAdd from '../../../../components/icons/IconAdd'
import IconOpenEye from '../../../../components/icons/IconOpenEye'
import IconRelation from '../../../../components/icons/IconRelation'
import ButtonSearch from '../../../../components/buttons/ButtonSearch'
import { useNavigate } from 'react-router-dom'
import ValidateAction from '../../../../routes/validateAction'
import { listPermissions } from '../../../../routes/permissions'

const Process = () => {
  const navigate = useNavigate()

  return (
    // CONTENEDOR PROCESOS
    <Box sx={processStyles.container}>
      {/**********************************
                CONTENEDOR TITULO Y BOTON CREAR
            ***********************************/}
      <Box sx={processStyles.title}>
        {/******************
                    TITULO PROCESOS
                *******************/}
        <Typography>Procesos</Typography>

        {/**********
                    BOTONES
                ************/}
        <Box className="buttons">
          {/***************************
                        BOTON VER SUBPROCESOS
                    ****************************/}
          <ValidateAction permission={[listPermissions.subProcess.read]}>
            <Button className="buttonCreate iconProcess" href="process/sub-process" disableTouchRipple>
              <IconOpenEye width={11} height={11} />

              <Typography>Ver subprocesos</Typography>
            </Button>
          </ValidateAction>
          {/***************************
                        BOTON CREAR RELACIONES
                    ****************************/}
          <ValidateAction permission={[listPermissions.relationsProcessAndSubProcess.create]}>
            <Button className="buttonCreate" href="process/relations" disableTouchRipple>
              <IconRelation />

              <Typography>Crear relaciones</Typography>
            </Button>
          </ValidateAction>

          {/***************************
                        BOTON CREAR PROCESO
                    ****************************/}
          <ValidateAction permission={[listPermissions.process.create]}>
            <Button
              className="buttonCreate"
              onClick={() => navigate('/plan/process/create')}
              disableTouchRipple
              sx={{ minWidth: '223px !important' }}
            >
              <IconAdd />

              <Typography>Crear nuevo proceso</Typography>
            </Button>
          </ValidateAction>

          {/************
                        BUSCADOR 
                    *************/}
          <ButtonSearch />
        </Box>
      </Box>

      {/*******************
                TABLA PROCESOS
            ********************/}
      <TableProcess />
    </Box>
  )
}

export default Process
