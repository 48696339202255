import React, { useEffect, useState } from 'react'
import Spinner from '../../../components/utilities/Spinner'
import useCompany from "../../../hooks/company/useCompany";
import { useNavigate } from "react-router-dom";
import Companies from './Companies';
import { Box } from '@mui/material';

const CompaniesValidator = () => {
  const { fetchGetCompany } = useCompany();
  const navigate = useNavigate();

  const [company, setCompany] = useState<boolean>(false)

  useEffect(() => {
    getCompany()
  }, [])

  const getCompany = async () => {
    const company: any = await fetchGetCompany()
    if (company.items.length > 0) {
      setCompany(true)
    } else {
      navigate("/companies/create")
    }
  }
  return (
    <>
      {
        company ?
          <Companies />
          :
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}>
            <Spinner />
          </Box>
      }
    </>

  )
}

export default CompaniesValidator
