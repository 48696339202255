import { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'
const useCompany = () => {
  const { showErrorDialog, showLoadingDialog, showSuccessDialog } = useAppDialog()
  const { fetchList, fetchCreate, fetchUpdate } = useFetch()

  const fetchGetCompany = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getCompany',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar la Imagen, contactar con soporte.',
          })
        })
    },
    [fetchList]
  )

  const fetchCreateCompany = useCallback(
    (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      fetchCreate({ action: 'createCompany', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Empresa creada exitosamente!.',
          })
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar la Imagen, contactar con soporte.',
          })
        })
    },
    [fetchCreate]
  )

  const fetchUpdateCompany = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchUpdate({ action: 'updateCompany', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Empresa actualizada exitosamente!.',
          })
          return {
            ok: true,
          }
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error, contactar con soporte.',
          })
          return {
            ok: false,
          }
        })
    },
    [fetchUpdate]
  )

  return {
    fetchGetCompany,
    fetchCreateCompany,
    fetchUpdateCompany,
  }
}

export default useCompany
