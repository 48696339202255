import React, { useEffect, useState } from 'react'
import { Box, Typography, InputBase, Button } from '@mui/material'
import formAreasStyles from './formAreas.styles'
import CustomTitleButtonAdd from '../../../../components/CustomTitleButtonAdd'
import formPoliticsStyles from '../../../../components/forms/pdi/formPolitics/formPolitics.styles'
import { useNavigate, useParams } from 'react-router-dom'
import { ValidateFields, ValidateForm } from '../../../../services/ValidateForm'
import { departamentCreateSchema } from '../../../../schemas/departamentSchema'
import ErrorField from '../../../../components/ErrorField'
import useDepartament from '../../../../hooks/departament/useDepartament'
import formStyles from '../../../../components/forms/form.styles'

const FormAreas = () => {
  let navigate = useNavigate()
  const params = useParams()
  const { fetchCreateDepartament, fetchGetOneDepartament, fetchUpdateDepartament } = useDepartament()
  const [dataForm, setDataForm] = useState<any>({})
  const [errors, setErrors] = useState<any>({})

  const handleInputChange = async (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
    const result = await ValidateFields({
      schema: departamentCreateSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }

  const handleSubmit = async () => {
    const result = await ValidateForm({
      schema: departamentCreateSchema,
      data: dataForm,
    })

    if (result.isValid) {
      if (params.id) {
        fetchUpdateDepartament({ ...dataForm, _id: params.id, phoneNumber: String(dataForm.phoneNumber) })
        navigate(-1)
      } else {
        fetchCreateDepartament({ ...dataForm })
        setDataForm({})
      }
    } else {
      setErrors(result.errors)
    }
  }

  useEffect(() => {
    if (params.id) {
      getDepartament()
    }
  }, [])

  const getDepartament = async () => {
    const response: any = await fetchGetOneDepartament(params.id || '')
    const { is_active, createdAt, updatedAt, __v, ...data } = response
    setDataForm({ ...dataForm, ...data })
  }

  return (
    <>
      {/**************************
                CONTENEDOR FORMULARIO
            ****************************/}
      <Box sx={formAreasStyles.container}>
        {/*****************
                    TITULO AREAS
                *******************/}
        <CustomTitleButtonAdd title="Áreas" display="none" />

        {/**************
                    CONTENIDO
                **************/}
        <Box sx={formAreasStyles.content}>
          {/*************
                        NOMBRE
                    ***************/}
          <Box
            sx={{
              ...formAreasStyles.title,
            }}
          >
            <Typography>Nombre del área</Typography>

            {/* INPUT */}
            <Box sx={formAreasStyles.input}>
              <InputBase
                sx={formAreasStyles.inputBase}
                name="name"
                value={dataForm?.name ?? ''}
                onChange={handleInputChange}
              />
              <ErrorField message={errors?.name ?? ''} />
            </Box>
          </Box>

          {/****************
                        DESCRIPCION
                    *****************/}
          <Box sx={formAreasStyles.description}>
            <Typography>Descripción</Typography>

            {/* INPUT */}
            <Box sx={formAreasStyles.inputDescription}>
              <InputBase
                multiline
                rows={4}
                sx={formAreasStyles.inputBaseDescription}
                name="description"
                value={dataForm?.description ?? ''}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: '500',
                }}
              />
            </Box>
            <ErrorField message={errors?.description ?? ''} />
          </Box>

          {/********************
                        NOMBRE JEFE AREA
                    **********************/}
          <Box
            sx={{
              ...formAreasStyles.title,
            }}
          >
            <Typography>Nombre del jefe de área</Typography>

            {/* INPUT */}
            <Box sx={formAreasStyles.input}>
              <InputBase
                sx={formAreasStyles.inputBase}
                name="nameManager"
                value={dataForm?.nameManager ?? ''}
                onChange={handleInputChange}
              />
              <ErrorField message={errors?.nameManager ?? ''} />
            </Box>
          </Box>

          {/********************
                        EMAIL JEFE AREA
                    **********************/}
          <Box
            sx={{
              ...formAreasStyles.title,
            }}
          >
            <Typography>Email del jefe de área</Typography>

            {/* INPUT */}
            <Box sx={formAreasStyles.input}>
              <InputBase
                sx={formAreasStyles.inputBase}
                name="emailManager"
                value={dataForm?.emailManager ?? ''}
                onChange={handleInputChange}
              />
              <ErrorField message={errors?.emailManager ?? ''} />
            </Box>
          </Box>

          {/********************
                        CELULAR JEFE AREA
                    **********************/}
          <Box
            sx={{
              ...formAreasStyles.title,
            }}
          >
            <Typography>Celular del jefe de área</Typography>

            {/* INPUT */}
            <Box sx={formAreasStyles.input}>
              <InputBase
                sx={formAreasStyles.inputBase}
                name="phoneNumber"
                value={dataForm?.phoneNumber ?? ''}
                onChange={handleInputChange}
              />
              <ErrorField message={errors?.phoneNumber ?? ''} />
            </Box>
          </Box>

          {/**********************
                                                CONTENEDOR BOTONES 
                                           ***********************/}
          <Box
            sx={{
              ...formStyles.divButton,
              marginTop: '23px !important',
            }}
          >
            {/**********************************
                            BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
            <div>
              {/********************
                                BOTON ACTUALIZAR
                            *********************/}
              <Button disableTouchRipple sx={formStyles.buttonSave} onClick={handleSubmit}>
                {params.id ? 'Actualizar' : 'Crear'}
              </Button>

              {/********************
                                BOTON DESACTIVAR
                            *********************/}
              <Button onClick={() => navigate(-1)} disableTouchRipple sx={formStyles.buttonCancel}>
                Cancelar
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormAreas
