import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

// IMPORTADOS
import IconAdd from '../../../../components/icons/IconAdd'
import IconSearch from '../../../../components/icons/IconSearch'
import areasStyles from './areas.styles'
import TableAreas from './TableAreas'
import IconButton from '@mui/material/IconButton/IconButton'
import InputBase from '@mui/material/InputBase'
import IconX2 from '../../../../components/icons/IconX2'
import ValidateAction from '../../../../routes/validateAction'
import { listPermissions } from '../../../../routes/permissions'

const Areas = () => {
  // BOTON BUSCADOR
  const [btnOpen, setBtnOpen] = useState(false)

  return (
    // CONTENEDOR PDI
    <Box sx={areasStyles.container}>
      {/**********************************
                CONTENEDOR TITULO Y BOTON CREAR
            ***********************************/}
      <Box sx={areasStyles.title}>
        {/***************
                    TITULO USUARIOS
                ****************/}
        <Typography>Áreas</Typography>

        {/**************************
                    BOTONES CREAR Y BUSCAR
                ***************************/}
        <Box className="buttons">
          <ValidateAction permission={[listPermissions.area.create]}>
            <Button className="buttonCreate" href="areas/create" disableTouchRipple>
              <IconAdd />

              <Typography>Crear nueva área</Typography>
            </Button>
          </ValidateAction>
          {/************
                        BUSCADOR 
                    *************/}
          <Box
            className="buttonSearch"
            sx={{
              width: btnOpen === true ? '100%' : 'fit-content',
            }}
          >
            <Box
              sx={{
                backgroundColor: btnOpen === true ? 'rgba(114, 130, 184, 0.1)' : 'transparent',
                width: btnOpen === true ? '100%' : 'fit-content',
              }}
            >
              {/******************
                                ICONO BUSCADOR
                            *******************/}
              <IconButton onClick={e => setBtnOpen(true)} disableTouchRipple>
                <IconSearch width={19} height={19} />
              </IconButton>

              <InputBase
                type="text"
                placeholder="Buscar..."
                sx={{
                  display: btnOpen === false ? 'none' : 'inline-block',
                }}
              />

              {/******************
                                ICONO BUSCADOR
                            *******************/}
              <IconButton
                onClick={e => setBtnOpen(false)}
                sx={{
                  display: btnOpen === false ? 'none' : 'inline-flex',
                }}
              >
                <IconX2 />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      {/**************
                TABLA PDI
            ***************/}
      <TableAreas />
    </Box>
  )
}

export default Areas
