import { Box, Pagination } from '@mui/material'
import { FunctionComponent, useContext, useEffect, useState } from 'react'

// IMPORTADOS
import ModalDetailItemPda from '../../../modals/modalDetailItemPda/ModalDetailItemPda'
import usePagination from '../../../../hooks/usePagination'
import paginationStyles from '../../../Pagination.styles'
import tableItemsPdiStyles from '../../pdi/tableItemsPdiStyles'
import { GoalPdaModel } from '../../../../interfaces/pda/goal-pda'
import CardItemPda from '../../../cards/cardItemPda/cardItemPda'
import IconDirection from '../../../icons/IconDirection'
import { ModalDetailItemPdaProps } from '../../../modals/modalDetailItemPda/ModalDetailItemPda'
import ConfirmAlert, { ConfirmAlertprops } from '../../../Alerts/confirmAlert/ConfirmAlert'
import { PdaContext } from '../../../context/PdaProvider'

interface TableGoalsPdaProps {
  goalsPda: GoalPdaModel[]
  onUpdate?: (goalPda: GoalPdaModel) => void
  onDelete: (goalPda: GoalPdaModel) => void
}

const itemsPerPage = 9

const TableGoalsPda: FunctionComponent<TableGoalsPdaProps> = ({ goalsPda, onUpdate, onDelete }) => {
  const { countPage, currentPage, currentData, handleChangePage } = usePagination(goalsPda, itemsPerPage)

  // const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>({
  //   show: false,
  //   confirmMessage: 'Estas seguro que deseas eliminar esta meta?',
  //   confirmMessageBottom: 'Recuerde que si elimina esta meta usted no podrá editarla',
  //   textButtonActionConfirm: 'Si',
  //   textButtonActionCancel: 'No',
  // })
  const pdaContext = useContext(PdaContext)

  const [modalDetailData, setModalDetailData] = useState<ModalDetailItemPdaProps>({
    open: false,
    itemsRender: [],
  })

  const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>({
    show: false,
    confirmMessage: 'Estas seguro que deseas eliminar esta meta?',
    confirmMessageBottom: 'Recuerde que si elimina esta meta usted no podrá editarla',
    textButtonActionConfirm: 'Si',
    textButtonActionCancel: 'No',
  })

  const handleModalDetailData = (goalPa: GoalPdaModel, itemsRender: ModalDetailItemPdaProps['itemsRender']) => {
    setModalDetailData({
      open: true,
      itemsRender,
      onClose: () => setModalDetailData(state => ({ ...state, itemsRender: [], open: false })),
    })
  }

  const handleConfirmAlertData = (goal: GoalPdaModel) => {
    setConfirmAlertData(state => ({
      ...state,
      show: true,
      onClickButtonActionConfirm: () => {
        setConfirmAlertData(state => ({ ...state, show: false }))
        onDelete(goal)
        if (pdaContext?.pda?._id) {
          pdaContext?.getInitialDataForUpdatePda({ findId: pdaContext?.pda?._id })
        }
      },
    }))
  }

  // useEffect(() => {
  //   handleChangePage(1)
  // }, [])

  // const getIndicatorsList = (idGoal: string) => {
  //     const indicator = indicators.find(
  //         (indicator) => indicator.idGoal === idGoal && indicator.is_active
  //     );
  //     if (!indicator) return "No disponible.";
  //     console.log(indicator);
  //     return indicator.name;
  // };

  return (
    <>
      {/*****************************
        ALERTA DE CONFIRMACIÓN
      *****************************/}
      <ConfirmAlert
        {...confirmAlertData}
        onClickButtonActionCancel={() => setConfirmAlertData(state => ({ ...state, show: false }))}
        onCloseForButtom={() => setConfirmAlertData(state => ({ ...state, show: false }))}
      />
      {/***************************
        MODAL DETALLE DEL PDA
      ***************************/}
      <ModalDetailItemPda maxWidth={'md'} textButtonAction="Editar" title="Detalles de la meta" {...modalDetailData} />

      {/*********************
        CONTENEDOR METAS
      *********************/}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            ...tableItemsPdiStyles.container,
            justifyContent: currentData().length === 2 ? 'center !important' : 'space-around',
          }}
        >
          {/*****************
            CARD METAS
          ******************/}
          {currentData().map((goalPda: any) => (
            <CardItemPda
              key={`${goalPda._id}`}
              title={goalPda.title}
              text={`No. indicadores: ${5}`}
              smallText={goalPda.state ? 'Activo' : 'Inactivo'}
              textButtonLeft="Editar"
              textButtonRight="Eliminar"
              textButtonDetail={<IconDirection />}
              onClickButtonDetail={() =>
                handleModalDetailData(goalPda, [
                  {
                    title: 'Título',
                    value: goalPda.title,
                  },
                  {
                    title: 'Estado',
                    value: goalPda.state ? 'Activo' : 'Inactivo',
                  },
                ])
              }
              onClickButtonLeft={() => onUpdate && onUpdate(goalPda)}
              onClickButtonRight={() => handleConfirmAlertData(goalPda)}
            />
          ))}
          {currentData().length === 1 && (
            <>
              <Box />
              <Box />
            </>
          )}
          {currentData().length === 2 && <Box />}
        </Box>

        {/****************
          PAGINACION
        ****************/}
        <Box sx={tableItemsPdiStyles.pagesList}>
          <Pagination
            sx={paginationStyles.paginationNumbers}
            page={currentPage}
            defaultPage={1}
            count={countPage}
            onChange={(e, _page) => handleChangePage(_page)}
          />
        </Box>
      </Box>
    </>
  )
}

export default TableGoalsPda
