import React, { useEffect } from 'react'
import { Box, TextField, ThemeProvider, Typography } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dateFieldStyles from '../hooks/dateField/dateField.styles'
import customizeDateField from '../hooks/dateField/customizeDateField'
import { dateToStringReverse } from '../services/ParseDate'

interface InputDatePickerProps {
  label: string
  inputName?: string
  onChange?: Function
  value?: any
}

const InputDatePicker = ({ label, onChange, inputName, value }: InputDatePickerProps) => {
  const [selectedDate, setSelectedDate] = React.useState<any | null>()

  useEffect(() => {
    console.log(value)
    value && setSelectedDate(value)
  }, [value])
  

  const handleDateChange = (date: any) => {
    setSelectedDate(date)
    onChange && inputName && onChange({ date: dateToStringReverse(date.$d), name: inputName })
  }

  // TEMA
  const myTheme = customizeDateField('dateInitial')

  return (
    <Box>
      <Typography>{label}</Typography>

      <ThemeProvider theme={myTheme}>
        <LocalizationProvider locale="es-mx" dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={params => <TextField sx={dateFieldStyles.textField} {...params} />}
            PopperProps={{
              placement: 'right',
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </Box>
  )
}

export default InputDatePicker
