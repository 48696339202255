/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Box, Typography, Pagination, Tabs, Tab, Divider, Button, useTheme } from '@mui/material'

// IMPORTADOS
import { Link, useNavigate } from 'react-router-dom'
import IconCalendar30 from '../../../../components/icons/IconCalendar30'
import taskAssignmentTabsStyles from '../../plan/taskAssignment/TaskAssignmentTabs.styles'
import IconCalendarCheck from '../../../../components/icons/IconCalendarCheck'
import TabInfoEvent from './tabsVerifyEvents/TabInfoEvent'
import TabPdiEvent from './tabsVerifyEvents/TabPdiEvent'
import formStyles from '../../../../components/forms/form.styles'
import useEvents from '../../../../hooks/verify/events/useEvents'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const VerifyEventTabs = ({ events, setEvents, historyEvents, setHistoryEvents }: any) => {
  const theme: any = useTheme()
  const primary = theme.palette.primary.main
  const { createEvents } = useEvents()
  const navigate = useNavigate()
  // TAB
  const [valueTab, setValueTab] = React.useState(0)
  const [moveNote, setMoveNote] = React.useState<boolean>(true)
  const [event, setEvent] = React.useState<any>({})

  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setValueTab(value - 1)
  }

  const handleSubmit = async () => {
    const data: any = []
    await events.forEach((element: any) => {
      data.push({
        ...element,
        userNotifies: element.userNotifies?.flatMap((item: any) => item._id),
        idIndicator: element.idIndicator.id,
      })
    })
    const response = await createEvents(data)
    if (response.ok) {
      // navigate('assignment')
    }
  }

  useEffect(() => {
    setMoveNote(true)
    const tag: any = document.querySelector('#note')
    tag?.focus()
  }, [moveNote])
  
  return (
    <Box sx={taskAssignmentTabsStyles.contentCard}>
      <Box>
        {/*******************/}
        {/* CONTENEDOR TABS */}
        {/*******************/}
        <Tabs value={valueTab} onChange={handleTab} aria-label="icon label tabs example">
          {/***********************/}
          {/* TAB INFO DEL EVENTO */}
          {/***********************/}
          <Tab
            icon={<IconCalendar30 width={40} height={40} color={valueTab === 0 ? '#1F398F' : 'rgb(114, 130, 184)'} />}
            {...a11yProps(0)}
          />

          {/***********/}
          {/* TAB PDI */}
          {/***********/}
          <Tab
            icon={
              <IconCalendarCheck width={40} height={40} color={valueTab === 1 ? '#1F398F' : 'rgb(114, 130, 184)'} />
            }
            {...a11yProps(1)}
          />
        </Tabs>

        {/**************/}
        {/* TITULO PDI */}
        {/**************/}
        <Box className="titleButtonSearch" sx={taskAssignmentTabsStyles.titleButtonSearch}>
          <Typography>{valueTab === 0 && 'Información del Evento'}</Typography>
          <Typography>{valueTab === 1 && 'Plan de Desarrollo Institucional'}</Typography>
        </Box>

        {/*****************/}
        {/* CONTENIDO TAB */}
        {/*****************/}
        {/******************/}
        {/* TAB INFO-EVENT */}
        {/******************/}
        <TabPanel value={valueTab} index={0}>
          <TabInfoEvent
            event={event}
            setEvent={setEvent}
            events={events}
            setEvents={setEvents}
            historyEvents={historyEvents}
            setHistoryEvents={setHistoryEvents}
          />
        </TabPanel>

        {/***********/}
        {/* TAB PDI */}
        {/***********/}
        <TabPanel value={valueTab} index={1}>
          <TabPdiEvent
            event={event}
            events={events}
            setEvents={setEvents}
            historyEvents={historyEvents}
            setHistoryEvents={setHistoryEvents}
            setMoveNote={setMoveNote}
            moveNote={moveNote}
          />
        </TabPanel>

        {/***********/}
        {/* DIVISOR */}
        {/***********/}
        <br />
        <Divider />
        <br />

        {/******************/}
        {/* TEXTO INFERIOR */}
        {/******************/}
        <Box sx={taskAssignmentTabsStyles.text}>
          <Typography>
            {valueTab === 0 && 'Escoge el componente deseado del Plan de Desarrollo Institucional.'}

            {valueTab === 1 && 'Escoge el componente deseado del módulo ¿Quién mide?'}
          </Typography>
          {valueTab === 1 && (
            <Typography className="move" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              Nota: Los indicadores inhabilitados no se han asignado a ningun usuario, por lo tanto no podran ser
              seleccionados.{' '}
              <Link to={'/plan/taskAssignment/assignment'} style={{ color: primary }}>
                Asignar indicadores
              </Link>
            </Typography>
          )}
        </Box>

        {/********************************/}
        {/* CONTENEDOR BUSCAR/PAGINACION */}
        {/********************************/}
        <Box sx={taskAssignmentTabsStyles.containerPagination}>
          {/**************/}
          {/* PAGINACION */}
          {/**************/}
          <Pagination
            page={valueTab + 1}
            count={2}
            onChange={changePage}
            sx={{
              width: '100% !important',
            }}
          />
          {valueTab === 1 && (
            <Button sx={formStyles.buttonSave} onClick={handleSubmit}>
              Guardar
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default VerifyEventTabs
