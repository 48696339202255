import { FunctionComponent, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Box, Button, Typography, Tab, Tabs } from '@mui/material'
import { useParams } from 'react-router-dom'

// ICONOS
import IconArrowRight from '../../../components/icons/IconArrowRight'
import IconGoals from '../../../components/icons/IconGoals'
import IconIndicators from '../../icons/IconIndicatorsPdi'

// IMPORTADOS
import TabPanelContainer from '../../../components/tabPanels/TabPanelContainer'
import tabManagerPdiStyles from '../tabManagerPdi/tabManagerPdi.styles'
import IconCalendarCheckPa from '../../icons/IconCalendarCheckPa'
import TabPanelPda from '../../tabPanels/tabPanelsPda/tabPanelPda/TabPanelPda'
import TabPanelGoalsPda from '../../tabPanels/tabPanelsPda/tabPanelGoalsPda/TabPanelGoalsPda'
import { PdaContext } from '../../context/PdaProvider'
import TabPanelIndicatorsPda from '../../tabPanels/tabPanelsPda/tabPanelIndicatorPda.tsx/TabPanelIndicatorsPda'

interface TabManagerPdaProps {
  updateId?: string
}

const TAB_PANEL_ID = 'nav-pda'
// TABS
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${TAB_PANEL_ID}-${index}`,
    'aria-controls': `vertical-tabpanel-${TAB_PANEL_ID}-${index}`,
  }
}

const TabManagerPda: FunctionComponent<TabManagerPdaProps> = ({ updateId }) => {
  const pdaContext = useContext(PdaContext)
  const params = useParams()

  const [activeTabTitle, setActiveTabTitle] = useState<string | null>(null)

  // const handleActiveTabTitle = (tabTitle: string | null) => setActiveTabTitle(tabTitle)

  useEffect(() => {
    if (!pdaContext) return
    const id = params.id
    if (id && !pdaContext.pda) {
      pdaContext.getInitialDataForUpdatePda({ findId: id })
      return
    }
    pdaContext.getInitialDataForCreatePda()
  }, [])

  if (!pdaContext) return null

  return (
    <>
      {/*************************/}
      {/* CONTENEDOR CREAR PDIS */}
      {/*************************/}
      <Box sx={tabManagerPdiStyles.container}>
        {/****************************/}
        {/* CONTENEDOR BARRA LATERAL */}
        {/****************************/}
        <Box sx={tabManagerPdiStyles.containerLateralMenu}>
          {/****************/}
          {/* MENU LATERAL */}
          {/****************/}
          <Box>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={pdaContext.tabIndex}
              onChange={(e, nextIndex) => pdaContext.handleTabIndex(nextIndex)}
              aria-label="Navegación PDA"
              sx={{
                ...tabManagerPdiStyles.tabsLateralMenu,
                '>div>div': { height: '37%' },
              }}
            >
              {/**************/}
              {/* PDI/INICIO */}
              {/**************/}
              <Tab
                disableTouchRipple
                label={
                  <>
                    {/************/}
                    {/* ICONO PA */}
                    {/************/}
                    <IconCalendarCheckPa width={40} height={40} color="#7282B8" />

                    {/**********/}
                    {/* TITULO */}
                    {/**********/}
                    <Typography>PA</Typography>
                  </>
                }
                {...a11yProps(0)}
              />

              {/*********/}
              {/* METAS */}
              {/*********/}
              <Tab
                disableTouchRipple
                disabled={!pdaContext.isActiveTabPanelGoalPda}
                label={
                  <>
                    <IconGoals />
                    <Typography>Metas</Typography>
                  </>
                }
                {...a11yProps(1)}
              />

              {/***************/}
              {/* INDICADORES */}
              {/***************/}
              <Tab
                // disabled={true}
                disableTouchRipple
                label={
                  <>
                    {/*********************/}
                    {/* ICONO INDICADORES */}
                    {/*********************/}
                    <IconIndicators />

                    {/**********************/}
                    {/* TITULO INDICADORES */}
                    {/**********************/}
                    <Typography>Indicadores</Typography>
                  </>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          {/******************/}
          {/* CONTENIDO TABS */}
          {/******************/}
          <Box sx={tabManagerPdiStyles.contentItems}>
            {/*******************/}
            {/* TÍTULO DINAMICO */}
            {/*******************/}

            <Box sx={tabManagerPdiStyles.title}>
              {/**************/}
              {/* TITULO PDI */}
              {/**************/}
              <Typography>PA</Typography>

              {/******************/}
              {/* FLECHA DERECHA */}
              {/******************/}
              <IconArrowRight width={11} height={22} />

              {/****************************/}
              {/* BOTON PLAN DE DESARROLLO */}
              {/*****************************/}
              <Button disableTouchRipple>
                <Typography>{pdaContext?.pda ? pdaContext?.pda.title : 'Crear Plan de Acción'}</Typography>
              </Button>

              {pdaContext.pda && activeTabTitle && (
                <>
                  <IconArrowRight width={11} height={22} />
                  <Button disableTouchRipple>
                    <Typography>{activeTabTitle} </Typography>
                  </Button>
                </>
              )}
            </Box>

            {/*********************************/}
            {/* FORMULARIO PLAN DE DESARROLLO */}
            {/*********************************/}
            <TabPanelContainer value={pdaContext.tabIndex} index={0} parentId={TAB_PANEL_ID}>
              <TabPanelPda />
            </TabPanelContainer>

            {/*********************/}
            {/* ADMIN PANEL METAS */}
            {/*********************/}
            <TabPanelContainer value={pdaContext.tabIndex} index={1} parentId={TAB_PANEL_ID}>
              {pdaContext.isActiveTabPanelGoalPda ? <TabPanelGoalsPda /> : null}
            </TabPanelContainer>

            {/***************************/}
            {/* ADMIN PANEL INDICADORES */}
            {/***************************/}
            <TabPanelContainer value={pdaContext.tabIndex} index={2} parentId={TAB_PANEL_ID}>
              {pdaContext.isActiveTabPanelGoalPda ? <TabPanelIndicatorsPda /> : null}
            </TabPanelContainer>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TabManagerPda
