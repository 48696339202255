/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/styles'

// IMPORTADOS
import doStyles from './Do.styles'
import DatePicker from '../home/DatePicker'
import IconAssignment from '../../../components/icons/IconAssignment'
import useCalendar from '../../../hooks/calendar/useCalendar'
import { useEffect, useState } from 'react'
import { dateToStringReverse } from '../../../services/ParseDate'
import useEvents from '../../../hooks/verify/events/useEvents'

const Do = () => {
  const navigate = useNavigate()
  const { getTaskDates } = useCalendar()
  // TEMA
  const { getMyEvents } = useEvents()

  const [selectDate, setSelectDate] = useState<any>(null)
  const [taskDates, setTaskDates] = useState<string[]>([])
  const [listAssignments, setListAssignments] = useState<any[]>([])
  const [infoAgenda, setInfoAgenda] = useState<any[]>([])

  const getSpecialDates = async () => {
    const response: any = await getTaskDates()
    const dates: string[] = []
    response.items?.forEach((item: any) => {
      const date = item.split('T')[0]
      dates.push(date)
    })
    setTaskDates(dates)

    const response2: any = await getMyEvents()
    setListAssignments(response2.items)
    setInfoAgenda(response2.items)
  }

  useEffect(() => {
    getSpecialDates()
  }, [])

  const filterPerDate = () => {
    if (selectDate !== null) {
      const dataFilter = listAssignments.filter(
        (item: any) => item.startDate.split('T')[0] === dateToStringReverse(selectDate)
      )
      setInfoAgenda(dataFilter)
    } else {
      setInfoAgenda(listAssignments)
    }
  }

  useEffect(() => {
    filterPerDate()
  }, [selectDate])

  return (
    <Box sx={doStyles.container}>
      {/****************/}
      {/* TITULO HACER */}
      {/****************/}
      <Typography>Hacer</Typography>

      {/**********************************/}
      {/* CONTENEDOR IZQUIERDA / DERECHA */}
      {/**********************************/}
      <Box>
        {/************************/}
        {/* CONTENEDOR IZQUIERDA */}
        {/************************/}
        <Box sx={doStyles.contentLeft}>
          <Box className="contentParagraph">
            {/* ******* */}
            {/* PARRAFO */}
            {/* ******* */}
            <Typography className="parrafo">
              Una vez que tengas un plan de acción es el momento para poner a prueba los cambios propuestos
              inicialmente, el objetivo de esta etapa es recopilar datos e información sobre el impacto generado por el
              plan , ya que esto indicará las siguientes etapas del proceso.
            </Typography>
          </Box>

          {/*************/}
          {/* BOTON PDI */}
          {/*************/}
          <Button
            disableTouchRipple
            onClick={() => navigate('./assignment')}
            className="buttomPdi"
            sx={doStyles.buttom}
          >
            {/* ********* */}
            {/* ICONO PDI */}
            {/* ********* */}
            <IconAssignment />

            {/* ********** */}
            {/* TITULO PDI */}
            {/* ********** */}
            <Typography className="parrafoPdi">Asignación</Typography>
          </Button>
        </Box>

        {/**********************/}
        {/* CONTENEDOR DERECHA */}
        {/**********************/}
        <Box sx={doStyles.contentRight}>
          <DatePicker
            specialDates={taskDates}
            setSelectDate={setSelectDate}
            infoAgenda={infoAgenda}
            name="Calendario de asignaciones"
            urlToRedirect="/do/assignment/indicating"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Do
