import { Box, Typography } from "@mui/material";
import IconMeasurement from "../../../components/icons/IconMeasurement";

const Quality = () => {
    {
        /********************************
        ESTADO DE GESTION DE CALIDAD
    **********************************/
    }
    return (
        <Box
            sx={{
                backgroundColor: "#ffffff",
                width: "1399px",
                height: "369px",
                marginBottom: "44px",
                marginRight: "35px",
                // transition: "1.5s .7s",

                // RESPONSIVE
                "@media(max-width: 1440px)": {
                    width: "100vw",
                    marginRight: "0px",
                },

                ">div": {
                    display: "flex",
                    alignItems: "center",
                    marginTop: "49px",
                    marginLeft: "47px",
                    marginRight: "47px",
                    marginBottom: "25px",
                    ">svg": {
                        marginRight: "7px",
                    },
                    ">p": {
                        fontSize: "20px",
                        color: "#1F398F",
                        fontWeight: "400",
                    },
                },
            }}
        >
            <Box>
                <IconMeasurement />

                <Typography>
                    Estado de sistema de gestión de calidad.
                </Typography>
            </Box>
        </Box>
    );
};

export default Quality;
