import * as yup from 'yup'
import { FormPdiValues } from "../../interfaces/pdi/pdi";
import { testMaxLength } from '../validations';

export const formPdiSchema: yup.SchemaOf<FormPdiValues> = yup.object({
    title: yup.string()
        .required('El título es un campo requerido')
        .test('maxLength', 'El título no puede contener mas de 50 caracteres', val => testMaxLength(val, 50))
        .trim('El título no puede contener solo espacios en blanco'),
    description: yup.string()
        .required('La descripción es un campo requerido')
        .test('maxLength', 'La descripción no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('La descripción no puede contener solo espacios en blanco'),
    idCompany: yup.string()
        .required('La sucursal es requerida')
        .length(24, 'La sucursal seleccionada no es válida'),
    startDate: yup.number()
        .typeError('La fecha inicial tiene un formato incorrecto.')
        .integer('La fecha inicial seleccionada no es valida')
        .min(1, 'La fecha inicial seleccionada no es valida')
        .required('La fecha inicial es un campo requerido'),
    finalDate: yup.number()
        .typeError('La fecha final tiene un formato incorrecto.')
        .integer('La fecha inicial seleccionada no es valida')
        .min(1, 'La fecha inicial seleccionada no es valida')
        .required('La fecha final es un campo requerido'),
    // percentageOfCompletion: yup.number()
    //     .typeError('El porcentaje de cumplimiento requiere un valor numerico')
    //     .min(0, 'El porcentaje de cumplimiento debe ser mayor o igual a 0')
    //     .max(100, 'El porcentaje de cumplimiento debe ser menor o igual a 100')
    //     .required('El porcentaje de cumplimiento es requerido')
}).required() 