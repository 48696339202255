import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys = "container" | "center" ;

const confirmDeletestyles: StyleComponent<Keys> = {
  container: {
    marginX: "60px",
    marginY: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  center: {
    display: "flex",
    justifyContent: "center",
    marginY: "30px",
  },
};

export default confirmDeletestyles;
