import { FormPdaFields } from './../../interfaces/pda/pda'
import * as yup from 'yup'
import { testDifference, testMaxLength, testMinDate } from '../validations'

export const formPdaSchema: yup.SchemaOf<FormPdaFields> = yup
  .object({
    title: yup
      .string()
      .required('El título es un campo requerido')
      .test('maxLength', 'El título no puede contener mas de 50 caracteres', val => testMaxLength(val, 50))
      .trim('El título no puede contener solo espacios en blanco'),
    description: yup
      .string()
      .required('La descripción es un campo requerido')
      .test('maxLength', 'La descripción no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
      .trim('La descripción no puede contener solo espacios en blanco'),
    idBranchOffice: yup
      .string()
      .required('La sucursal es requerida')
      .length(24, 'La sucursal seleccionada no es válida'),
    startDate: yup
      .number()
      .typeError('La fecha inicial tiene un formato incorrecto.')
      .integer('La fecha inicial seleccionada no es valida')
      .required('La fecha inicial es un campo requerido'),
    finalDate: yup
      .number()
      .typeError('La fecha final tiene un formato incorrecto.')
      .integer('La fecha inicial seleccionada no es valida')
      .test('minDate', 'La fecha final requerida debe ser posterior a la fecha de inicio', (current, {parent : {startDate}}) => testMinDate(current, startDate))
      .test('difference', 'La fecha final debe ser menor o igual a 1 años de diferencia con respecto a la fecha inicial', (current , {parent : { startDate }} ) => testDifference(1, current, startDate))
      .required('La fecha final es un campo requerido'),
  })
  .required()
