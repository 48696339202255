import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

// IMPORTADOS
import infoVerifyEventAssignedStyles from './InfoVerifyEventAssigned.styles'
import DetailVerifyEventAssigned from '../infoVerifyEventAssigned/DetailVerifyEventAssigned'
import EditVerifyEventAssigned from './EditVerifyEventAssigned'

const InfoVerifyEventAssigned = ({ dataInfo }: any) => {
  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = () => {
    setOpenEdit(!openEdit)
  }
  
  return (
    <Box sx={infoVerifyEventAssignedStyles.container}>
      {/***************/}
      {/* TITULO INFO */}
      {/***************/}
      <Box sx={infoVerifyEventAssignedStyles.contentTitle}>
        <Typography>{openEdit ? <>Editar el evento</> : <>Detalles del evento</>}</Typography>

        {/******************/}
        {/* BOTONES EDITAR */}
        {/******************/}
        {openEdit ? null : (
          <Button disableTouchRipple onClick={handleOpenEdit}>
            Editar
          </Button>
        )}
      </Box>

      {openEdit ? (
        // EDITAR EL EVENTO
        <EditVerifyEventAssigned onClose={handleOpenEdit}  dataInfo={dataInfo} />
      ) : (
        // DETALLES DEL EVENTO
        <DetailVerifyEventAssigned dataInfo={dataInfo} />
      )}
    </Box>
  )
}

export default InfoVerifyEventAssigned
