import { Box, IconButton, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import IconPensil from '../../icons/IconPensil'
import IconTrash from '../../icons/IconTrash'
import { Area } from '../../../interfaces/area'
import { Factor } from '../../../interfaces/factor'
import infoProcessStyles from '../../../pages/private/plan/process/infoProcess.styles'
import CustomDialog from '../customDialog/CustomDialog'
import { IndicatorFactor } from '../../../interfaces/indicator-factor'

export interface InfoFactorProps {
  open?: boolean
  onClose?: () => void
  indicatorsFactors?: IndicatorFactor[]
  factor: Factor | null
  onButtonUpdate?: (factor: Factor) => void
  onButtonDelete?: (factor: Factor) => void
}
const InfoFactor: FunctionComponent<InfoFactorProps> = ({
  open = false,
  onClose,
  indicatorsFactors = [],
  factor,
  onButtonUpdate,
  onButtonDelete,
}) => {
  if (!factor) return null

  return (
    <CustomDialog open={open} onClose={onClose} maxWidth="md">
      <Box sx={infoProcessStyles.container}>
        {/**************
                TITULO INFO
            ***************/}
          <Box className='áquiiiiiiii' sx={infoProcessStyles.contentTitle}>
            <Box sx={infoProcessStyles.title}>
              <Typography>Detalles del factor</Typography>

              {/*****************
                    BOTONES DE ACCIÒN
                ******************/}

              <Box>
                <IconButton onClick={() => onButtonUpdate && onButtonUpdate(factor)}>
                  <IconPensil />
                </IconButton>
              </Box>
              <Box>
                <IconButton onClick={() => onButtonDelete && onButtonDelete(factor)}>
                  <IconTrash />
                </IconButton>
              </Box>
            </Box>
          </Box>
          

        {/*************
                CONTENIDO
            ***************/}
        <Box sx={infoProcessStyles.content}>
          {/************** 
                CONTENT 1 
            **************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                    TITULO
                ***********/}
            <Typography>Nombre del área</Typography>

            {/*********
                    INFO
                *********/}
            <Typography component={'span'}>{factor?.idDepartament?.name}</Typography>
          </Box>

          {/*************** 
                CONTENT 2 
            ***************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                    TITULO
                ***********/}
            <Typography>Nombre del factor</Typography>

            {/*********
                    INFO
                *********/}
            <Typography component={'span'}>{factor?.name}</Typography>
          </Box>

          {/************** 
                CONTENT 3
            **************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                TITULO
            ***********/}
            <Typography>Descripción</Typography>

            {/*********
                    INFO
                *********/}
            <Typography component={'span'}>{factor?.description}</Typography>
          </Box>

          {/************** 
                CONTENT 4
            **************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                    TITULO
                ***********/}
            <Typography>Justificación.</Typography>

            {/*********
                    INFO
                *********/}
            <Typography component={'span'}>{factor?.justification}</Typography>
          </Box>

          {/**************
                CONTENT 6 - CONTENEDOR LISTA
            **************/}
          <Box sx={infoProcessStyles.data}>
            <Box>
              {/***************
                        INDICADORES
                    ****************/}
              <Box>
                <Typography>Indicadores</Typography>

                {/**********
                    LISTA
                **********/}

                <Box>
                  {indicatorsFactors
                    .filter(i => i.idFactor?._id === factor?._id && i.idIndicator)
                    .map(rel => (
                      <Typography key={rel._id}>{rel.idIndicator?.name}</Typography>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default InfoFactor
