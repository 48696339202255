import * as yup from 'yup'
import { FormCompanyValues } from '../interfaces/company'

export const companyCreateSchema: yup.SchemaOf<FormCompanyValues> = yup.object({
  nameCompany: yup.string().required('Este campo es obligatorio'),
  nit: yup.string().required('Este campo es obligatorio'),
  activity: yup.string().required('Este campo es obligatorio'),
  mission: yup.string().required('Este campo es obligatorio'),
  vision: yup.string().required('Este campo es obligatorio'),
  emailLegalRepresentative: yup
    .string()
    .email('Este campo requiere un email valido')
    .required('Este campo es obligatorio'),
  logo: yup.string(),
  logoLink: yup.string(),
  horizontalLogo: yup.string(),
  horizontalLogoLink: yup.string(),
})
