import { FunctionComponent } from "react";

interface IconArrowLeftProps {
    width?: number | string;
    height?: number | string;
}

const IconArrowLeft: FunctionComponent<IconArrowLeftProps> = ({
    width = 7,
    height = 15,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 7 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 1L1 7.5L6 14"
                stroke="#1F398F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconArrowLeft;
