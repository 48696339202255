import React from "react";
import { Box, IconButton, InputBase } from "@mui/material";
import IconSearch from "./icons/IconSearch";
import headerStyles from "../layouts/full-layout/headers/Header.styles";

const InputSearch = () => {
    return (
        <Box className="inputSearch" sx={headerStyles.containerInputSearch}>
            {/************
                BUSCADOR
            ************/}
            <Box>
                {/******************
                    ICONO BUSCADOR
                *******************/}
                <IconButton>
                    <IconSearch width={20} height={19} />
                </IconButton>

                {/*****************************
                    CUADRO DE TEXTO/INPUTBASE
                *****************************/}
                <InputBase placeholder="Busque en..." />
            </Box>
        </Box>
    );
};

export default InputSearch;
