/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import taskAssignmentTabsStyles from '../TaskAssignmentTabs.styles'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const TabTaskInitResponsability: any = ({
  tasksAssignment,
  setTasksAssignment,
  historyTasksAssignment,
  setHistoryTasksAssignment,
}: any) => {
  const [date, setDate] = useState<string | null>(null)

  const handleChange = (value: any) => {
    setDate(value)
    const newValues: any[] = []
    const day = value.$D < 10 ? `0${value.$D}` : value.$D
    const month = value.$M + 1 < 10 ? `0${value.$M + 1}` : value.$M + 1
    const year = value.$y

    tasksAssignment.forEach((item: any) => {
      newValues.push({
        ...item,
        date: `${year}-${month}-${day}`,
      })
    })
    setTasksAssignment(newValues)
    setHistoryTasksAssignment({
      ...historyTasksAssignment,
      tabDate: {
        ...historyTasksAssignment.tabDate,
        isActive: true,
        date: value,
        stringDate: `${year}-${month}-${day}`,
      },
    })
  }

  useEffect(() => {
    if (historyTasksAssignment?.tabDate?.isActive) {
      setDate(historyTasksAssignment?.tabDate?.date)
    }
  }, [])

  return (
    <Box sx={taskAssignmentTabsStyles.contentTab}>
      <Typography>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
        dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
        suscipit lobortis nisl ut aliquip ex ea commodo consequat.
      </Typography>
      <Box sx={{ textAlign: 'center', marginY: '50px' }}>
        <LocalizationProvider locale="es-mx" dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            value={date}
            onChange={value => handleChange(value)}
            renderInput={params => <TextField {...params} />}
            PopperProps={{
              placement: 'right',
            }}
            showDaysOutsideCurrentMonth={true}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  )
}

export default TabTaskInitResponsability
