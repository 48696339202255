import * as yup from 'yup'
import { FormGoalValues } from '../../interfaces/pdi/goal'
import { testMaxLength } from '../validations'

export const formGoalSchema: yup.SchemaOf<FormGoalValues> = yup
  .object({
    idProject: yup
      .string()
      .required('El programa es un campo requerido')
      .length(24, 'El programa seleccionado no es válido'),
    goalNumber: yup
      .number()
      .typeError('El numero de meta requiere un valor numerico')
      .min(1, 'El numero de meta debe ser mayor o igual a 1')
      .integer('La meta requiere un valor numerico entero')

      .required('El numero de meta es un campo requerido'),
    idMeasureUnit: yup
      .string()
      .required('La unidad de medida es un campo requerido')
      .length(24, 'La unidad de medida seleccionada no es válida'),
    idPdi: yup
      .string()
      .required('La unidad de medida es un campo requerido')
      .length(24, 'La unidad de medida seleccionada no es válida'),
    // percentageOfCompletion: yup.number()
    //     .typeError('El porcentaje de cumplimiento requiere un valor numerico')
    //     .min(0, 'El porcentaje de cumplimiento debe ser mayor o igual a 0')
    //     .max(100, 'El porcentaje de cumplimiento debe ser menor o igual a 100')
    //     .required('El porcentaje de cumplimiento es un campo requerido'),
    state: yup.boolean().required('El estado es un campo requerido').default(true),
    title: yup
      .string()
      .required('El título es un campo requerido')
      .trim('El título no puede contener solo espacios en blanco'),
  })
  .required()
