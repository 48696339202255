import { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../../useFetch'
import useAppDialog from '../../useAppDialog'

const useMeasurement = () => {
  const { fetchList } = useFetch()
  const { showErrorDialog } = useAppDialog()

  const getAssignments = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getAssignments',
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  return {
    getAssignments,
  }
}

export default useMeasurement
