import { StyleComponent } from '../../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'btnEvidence'
  | 'menu'
  | 'inputSelectFormat'
  | 'title'
  | 'pagesList'
  | 'contentBarProgress'
  | 'linearBarProgress'
  | 'numberProgress'
  | 'containerCheckIn'
  | 'checkInDecision'
  | 'inputJustify'

const doAssignmentStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    // maxWidth: "1350px",
    maxWidth: '1388px',
    margin: 'auto',
    backgroundColor: '#ffffff',

    '>.contentCumplimientoIndicador': {
      background: '#7282B8',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
      borderRadius: '50px',
      width: '813px',
      height: '313px',
      margin: 'auto',

      // TITULO
      '>p': {
        fontSize: '25px',
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: '600',
        paddingTop: '39px',
        paddingBottom: '20px',
      },

      // CONTENEDOR INPUTS
      '>.contentInputs': {
        // backgroundColor: 'red',
        margin: '0px 55px 0px',

        '>div': {
          display: 'flex',
          justifyContent: 'space-evenly',

          // CONTENEDOR TEXT/INPUT
          '>div': {
            '>p': {
              textAlign: 'center',
              color: '#ffffff',
              fontSize: '18px',
              fontWeight: '600',
            },

            // CONTENT INPUT
            '>div': {
              width: '150px',
              backgroundColor: '#FFFFFFAB',

              '>div': {
                '>input': {
                  color: '#1F398F',
                  fontWeight: '600',
                  textAlign: 'center',
                },
                '>.Mui-disabled': {
                  // -webkit-text-fill-color
                  WebkitTextFillColor: '#1F398F',
                },
                '>div': {
                  '>p': {
                    color: '#1F398F',
                  },
                },
              },
            },
          },
        },
      },

      '>.typeIndiFrecu': {
        display: 'flex',
        justifyContent: 'center',

        '>div': {
          marginX: '15px',
          '>span': {
            color: '#FFFFFF',
            display: 'block',
            fontSize: '13px',
            textAlign: 'center',
          },

          '>.contentText': {
            marginTop: '12px',

            '>p': {
              backgroundColor: '#1F398F',
              color: '#FFFFFF',
              width: '150px',
              paddingTop: '6px',
              paddingBottom: '6px',
              // height: '35px',
              margin: 'auto',
              borderRadius: '200px',
              textAlign: 'center',
            },
          },
        },
      },
    },

    // FORMULARIO DE MEDICION
    '>.containerFormMeasureIndicating': {
      marginY: '100px',

      // TITULO
      '>.titleForm': {
        color: '#1F398F',
        textAlign: 'center',
        fontSize: '25px',
        fontFamily: 'Poppins',
        fontWeight: '600',
      },

      // FORMULA
      '.formula': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '39px',

        '>div': {
          marginX: '15px',
          width: '200px',

          '>div': {
            '>div': {
              '>input': {
                color: '#1F398F',
                fontWeight: '600',
                textAlign: 'center',
              },
              '>.MuiInputAdornment-positionEnd': {
                '>p': {
                  color: '#1F398F',
                  fontSize: '15px',
                },
              },
            },
          },
        },

        '>span': {
          color: '#1F398F',
          textAlign: 'center',
          fontSize: '20px',
          fontFamily: 'Poppins',
          fontWeight: '500',
          marginY: 'auto',
        },
      },

      '>.inputsLaterales': {
        display: 'flex',

        '>div': {
          marginRight: '30px',
        },

        '>.Npagina': {
          '>.textNpagina': {
            color: '#000000',
            width: '296px',
            fontSize: '13px',
            fontFamily: 'Poppins',
            marginRight: '20px',
          },
        },

        '>.format': {
          '>span': {
            paddingX: '8px',
          },
        },
      },
    },

    // TABLA DE REGISTRO DE MEDICION
    '>.containerTable, >.containerFormMeasureIndicating>div>.containerTable': {
      backgroundColor: '#FFFFFF',
      padding: '65px 74px 70px',
      borderRadius: '50px',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',

      '>.contentTable': {
        '>table': {
          '>thead': {
            '>tr': {
              '>th': {
                width: '12%',
                whiteSpace: 'nowrap',
              },
            },
          },

          '>tbody': {
            '>tr': {
              '>td': {
                '>.containerState': {
                  '>div': {
                    margin: 'auto',
                    '>label': {
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',

                      '>span': {
                        // width: '30px',
                        // height: '30px',
                        '>svg': {
                          // fontSize: '60px !important',
                          width: '28px',
                          height: '28px',
                          // '>path': {
                          //   fill: 'green',
                          // },
                        },
                      },

                      // CHECKBOX
                      '>.MuiCheckbox-root': {
                        width: '20px',
                        height: '20px',
                      },

                      // SELECCIONADO
                      '>.Mui-checked': {
                        backgroundColor: '#7282B8',
                        width: '20px',
                        height: '20px',
                        ':hover': {
                          backgroundColor: '#7282B8 !important',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  btnEvidence: {
    width: '186px',
    height: '38px',
    borderRadius: '50px',
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 10px 0px rgba(114, 130, 184, 0.25)',
    textTransform: 'capitalize',

    color: '#1F398F',
    fontSize: '16px',
    fontFamily: 'Poppins',

    ':hover': {
      backgroundColor: '#fff',
    },
  },

  menu: {
    '>div': {
      marginTop: '10px',
      '>ul': {
        // backgroundColor: 'red',
        '>li': {
          justifyContent: 'center',
          marginX: '14px',
          padding: '10px',

          ':hover': {
            borderRadius: '7px',
            backgroundColor: '#F1F3F8',
            color: '#1F398F',
          },
        },
      },
    },
  },

  inputSelectFormat: {
    backgroundColor: '#ffffff',
    borderRadius: '50px',
    width: '100%',
    boxShadow: '0px 0px 10px 0px rgba(114, 130, 184, 0.25)',
    '>div': {
      backgroundColor: '#ffffff',
      borderRadius: '200px',
      height: '39px',

      ':before, :after': {
        display: 'none',
      },

      '>div': {
        backgroundColor: '#FFFFFF !important',
        height: '39px !important',
        borderRadius: '200px !important',
        padding: '0px 0px 0px 10px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
      },
      '>svg': {
        backgroundColor: 'transparent',
        fontSize: '50px !important',
        '>path': {
          fill: '#1F398F',
        },
      },
    },
  },

  // TITULO, BOTONES CREAR Y BUSCAR
  title: {
    maxWidth: '1350px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 1425px)': {
      marginX: '30px',
    },

    // TYPOGRAPHY
    '>p': {
      color: '#1F398F',
      fontSize: '25px',
      fontWeight: '600',
      lineHeight: '75px',
      fontStyle: 'normal',
    },

    // BOTONES CREAR Y BUSCAR
    '>div': {
      marginY: 'auto',
      display: 'flex',

      // BOTON BUSCAR
      '>.buttonSearch': {
        maxWidth: '268px',
        // "@media(max-width: 1440px)": {
        //     width: "30vw",
        // },

        '>div': {
          maxWidth: '268px',
          minHeight: '41px',
          color: '#1F398F',
          marginLeft: 'auto',
          borderRadius: '500px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          '>button:hover': {
            backgroundColor: 'transparent',
          },
          '>div>input': {
            color: '#1F398F',
            fontSize: '12px',
            fontWeight: '400',
            width: '70%',
            '@media(max-width: 590px)': {
              width: '70%',
            },
          },
        },
      },
    },
  },

  pagesList: {
    backgroundColor: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },

  // CONTENEDOR BARRA Y PORCENTAJE DE PROGRESO
  contentBarProgress: {
    display: 'flex',
    alignItems: 'center',
  },

  // BARRA DE PROGRESO
  linearBarProgress: {
    width: '100%',
    mr: 1,
    '>div': {
      backgroundColor: '#7282B840',
      padding: '10px',
      borderRadius: '200px',

      '>span': {
        backgroundColor: 'transparent',
        height: '40px',
        borderRadius: '100px',

        '>span': {
          backgroundColor: '#7282B8',
          borderRadius: '100px',
        },
      },
    },
  },

  // PROGRESO EN PORCENTAJE
  numberProgress: {
    borderRadius: '100px',
    backgroundColor: '#7282B840',
    // minWidth: 40,
    '>p': {
      backgroundColor: '#7282B8',
      color: '#ffffff',
      margin: '10px',
      padding: '10px 8px',
      fontSize: '12px',
      borderRadius: '100px',
    },
  },

  // CHECK-IN
  containerCheckIn: {
    backgroundColor: '#ffffff',
    // backgroundColor: 'pink',
    // width: '1388px',
    width: 'inherit',
    minHeight: '589px',
    display: 'flex',
    borderRadius: '50px',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',

    '>.contentCheckIn': {
      // backgroundColor: 'aqua',
      margin: '65px 83px',
      width: '100%',

      '>p': {
        // backgroundColor: 'red',
        textAlign: 'center',
        color: '#1F398F',
        fontSize: '25px',
        fontFamily: 'Poppins',
        fontWeight: '600',
      },

      '>.rowCheckIn': {
        // backgroundColor: 'red',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        WebkitJustifyContent: 'space-around',
        marginTop: '30px',
        marginBottom: '50px',

        '>div': {
          '>p': {
            color: '#1F398F',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '300%',
          },

          '>span': {
            color: '#000',
            fontSize: '15px',
            lineHeight: '100%',
          },
        },
      },

      '>.justifyCheckIn': {
        marginBottom: '60px',

        '>p': {
          color: '#1F398F',
          fontSize: '20px',
          fontWeight: '600',
          marginBottom: '22px',
        },

        '>span': {
          color: '#000',
          fontSize: '15px',
          textAlign: 'justify',
        },
      },

      '>.containerBtns': {
        // backgroundColor: 'yellow',
        display: 'flex',
        justifyContent: 'space-between',

        '>button, >div>button': {
          backgroundColor: '#FFF',
          borderRadius: '50px',
          padding: '7px 20px',
          boxShadow: '0px 0px 10px 0px rgba(114, 130, 184, 0.25)',

          color: '#1F398F',
          fontSize: '16px',
          textTransform: 'capitalize',

          '>svg': {
            marginRight: '8px',
          },
        },
      },
    },
  },

  checkInDecision: {
    marginTop: '50px',
    '>p': {
      color: '#1F398F',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },

  // INPUT JUSTIFICACION
  inputJustify: {
    backgroundColor: '#F1F3F8',
    width: '100%',
    borderBottomWidth: '0px',
    borderRadius: '25px',

    '>div': {
      // height: '138px',
      color: '#1F398F',
      fontSize: '15px',
      lineHeight: 'normal',
      borderRadius: '25px',
      padding: '25px 35px',

      '>textarea': {
        height: '128px !important',
      },
    },
  },
}

export default doAssignmentStyles
