import { useTheme } from '@mui/styles'
import { FunctionComponent } from 'react'

interface IconLocationProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconLocation: FunctionComponent<IconLocationProps> = ({ width = 20, height = 20, color }) => {
  const theme: any = useTheme()
  const secondary: string = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.99 17.88V19.9C13 19.7 14.83 18.9 16.31 17.69L14.89 16.26C13.76 17.1421 12.4124 17.7019 10.99 17.88ZM2 9.95C2 5.9 5.03 2.54 8.95 2.02V0C3.92 0.53 0 4.79 0 9.95C0 15.11 3.92 19.37 8.95 19.9V17.88C5.03 17.36 2 14 2 9.95ZM17.92 8.95H19.94C19.74 6.94 18.94 5.11 17.73 3.63L16.3 5.06C17.16 6.16 17.74 7.49 17.92 8.95ZM16.31 2.21C14.7924 0.966461 12.942 0.197771 10.99 0V2.02C12.45 2.2 13.78 2.78 14.89 3.64L16.31 2.21ZM16.3 14.85L17.73 16.27C18.9752 14.7534 19.7441 12.9025 19.94 10.95H17.92C17.7419 12.3724 17.1821 13.72 16.3 14.85Z"
        fill={color ?? secondary}
      />
      <path
        d="M13.9697 9.0502C13.9697 6.5602 12.0697 4.9502 9.96973 4.9502C7.86973 4.9502 5.96973 6.5602 5.96973 9.0502C5.96973 10.7102 7.29973 12.6802 9.96973 14.9502C12.6397 12.6802 13.9697 10.7102 13.9697 9.0502ZM9.96973 9.9502C9.69395 9.93827 9.43342 9.82033 9.2425 9.62097C9.05158 9.42161 8.945 9.15623 8.945 8.8802C8.945 8.60416 9.05158 8.33878 9.2425 8.13942C9.43342 7.94006 9.69395 7.82212 9.96973 7.81019C10.2455 7.82212 10.506 7.94006 10.697 8.13942C10.8879 8.33878 10.9945 8.60416 10.9945 8.8802C10.9945 9.15623 10.8879 9.42161 10.697 9.62097C10.506 9.82033 10.2455 9.93827 9.96973 9.9502Z"
        fill={color ?? secondary}
      />
    </svg>
  )
}

export default IconLocation
