import { FunctionComponent, useEffect } from "react";
import { Box, Fade } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import FormPdi from "../../../forms/pdi/formPdi/FormPdi";
import customAlerts from "../../../../helpers/customAlerts";
import usePdi from "../../../../hooks/pdi/usePdi";
import useViewControlTabPanel from "../../../../hooks/pdi/useViewControlTabPanelPdi";
import useAuth from "../../../../hooks/useAuth";
import { BranchOffice } from "../../../../interfaces/branchOffice";
import {
    FormPdiValues,
    PdiModel,
    UpdatePdi,
} from "../../../../interfaces/pdi/pdi";
import { Company } from "../../../../interfaces/company";

interface TabPanelPdiProps {
    onCreate: (_id: string) => void;
    onChangeTitle: (tabTitle: string | null) => void;
    onUpdate: (pdi: PdiModel) => void;
    pdi: PdiModel | null;
    userCompany: Company;
    userBranchOffice: BranchOffice;
}

const { errorAlert } = customAlerts;

const TabPanelPdi: FunctionComponent<TabPanelPdiProps> = ({
    onCreate,
    onChangeTitle,
    onUpdate,
    userBranchOffice,
    userCompany,
    pdi,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const { fetchCreatePdi, fetchUpdatePdi, ViewAlert } = usePdi();

    const { showViewCreate, showViewUpdate, updateItem, viewMode } =
        useViewControlTabPanel<PdiModel>({
            onChangeTitle,
            titleOptions: {
                none: null,
                create: null,
                update: null,
                data: null,
            },
        });

    const onBackPage = () => navigate("/plan/pdis");

    const getFormDefaultValues = (): Partial<FormPdiValues> | undefined => {
        if (!updateItem) return undefined;
        const { __v, _id, createdAt, updatedAt, is_active, ...defaultValues } =
            updateItem;
        return defaultValues;
    };

    const create = (formValues: FormPdiValues) => {
        fetchCreatePdi(
            formValues,
            (id) => {
                setSearchParams({ id }, { replace: true });
            },
            onBackPage
        );
    };

    const update = (formValues: FormPdiValues) => {
        if (!updateItem) {
            errorAlert({ text: "Operación no disponible" });
            return;
        }
        const { _id } = updateItem;
        const updatePdi: UpdatePdi = { _id, ...formValues };
        fetchUpdatePdi(updatePdi, (_updateValues) => {
            onUpdate({ ...updateItem, ...formValues });
        });
    };

    const onSubmitForm = (formValues: FormPdiValues) => {
        if (updateItem) return update(formValues);
        create(formValues);
    };

    useEffect(() => {
        if (!pdi) return showViewCreate();
        showViewUpdate(pdi);
    }, [pdi]);

    if (viewMode === "create" || viewMode === "update")
        return (
            <>
                <ViewAlert />
                <Fade in={true}>
                    <Box>
                        <FormPdi
                            branchOffice={userBranchOffice}
                            company={userCompany}
                            onSubmit={onSubmitForm}
                            onCancel={onBackPage}
                            defaultValues={getFormDefaultValues()}
                        />
                    </Box>
                </Fade>
            </>
        );

    return <ViewAlert />;
};

export default TabPanelPdi;
