import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys = "container" | "title" | "spinner";

const processStyles: StyleComponent<Keys> = {
    container: {
        backgroundColor: "#ffffff",
        minHeight: "52.7vh",
    },

    spinner: {
        height: "300px",
        minHeight: "52.7vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",

        "@media(max-width: 1863px)": {
            margin: "auto",
            ">div": {
                marginRight: "0px !important",
            },
        },

        ">div": {
            backgroundColor: "pink",
            marginRight: "70px",
        },
    },

    // TITULO, BOTONES CREAR Y BUSCAR
    title: {
        maxWidth: "1350px",
        display: "flex",
        margin: "auto",
        justifyContent: "space-between",

        "@media(max-width: 1670px)": {
            marginX: "160px",
        },

        "@media(max-width: 830px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
            marginX: "100px",

            ">div": {
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "wrap",

                // BOTON BUSCAR
                ">.buttonSearch": {
                    maxWidth: "100% !important",
                    order: "-1",

                    ">div": {
                        maxWidth: "inherit !important",
                        ">div": {
                            width: "inherit",
                        },
                    },
                },
            },
        },

        "@media(max-width: 640px)": {
            marginX: "50px",
        },

        // TYPOGRAPHY
        ">p": {
            color: "#1F398F",
            fontSize: "50px",
            fontWeight: "600",
            lineHeight: "75px",
            fontStyle: "normal",

            // RESPONSIVE
            "@media (max-width: 565px)": {
                fontSize: "40px",
            },
        },

        // BOTONES CREAR Y BUSCAR
        ">div": {
            marginY: "auto",
            display: "flex",

            // BOTON CREAR
            ">.buttonCreate": {
                backgroundColor: "#ffffff",
                minWidth: "180px",
                height: "38px",
                marginY: "auto",
                marginRight: "20px",
                padding: "0px 15px",
                textTransform: "inherit",
                borderRadius: "92px",
                boxShadow: "0px 0px 10px rgba(114, 130, 184, 0.25)",

                // RESPONSIVE
                "@media (max-width: 1260px)": {
                    minWidth: "inherit !important",
                    height: "auto",
                    padding: "10px",
                    ">p": {
                        display: "none",
                    },

                    ">svg": {
                        width: 16,
                        height: 16,
                    },
                },

                // HOVER
                ":hover": {
                    backgroundColor: "#7282B8",
                    ">svg": {
                        ">path": {
                            fill: "#ffffff",
                        },
                    },
                    ">p": {
                        color: "#ffffff",
                    },
                },

                // PARRAFO
                ">p": {
                    fontSize: "16px",
                    marginLeft: "4px",
                },
            },

            ">.iconProcess": {
                ">svg": {
                    ">path": {
                        fill: "inherit",
                        stroke: "#1F398F",
                    },
                    ">circle": {
                        fill: "inherit",
                        stroke: "#1F398F",
                    },
                },

                // HOVER
                ":hover": {
                    backgroundColor: "#7282B8",
                    ">svg": {
                        ">path": {
                            fill: "inherit",
                            stroke: "#ffffff",
                        },
                        ">circle": {
                            fill: "inherit",
                            stroke: "#ffffff",
                        },
                    },
                },
            },

            // BOTON BUSCAR
            ">.buttonSearch": {
                maxWidth: "268px",

                ">div": {
                    maxWidth: "268px",
                    minHeight: "41px",
                    color: "#1F398F",
                    marginLeft: "auto",
                    borderRadius: "500px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    ">button:hover": {
                        backgroundColor: "transparent",
                    },
                    ">div>input": {
                        color: "#1F398F",
                        fontSize: "12px",
                        fontWeight: "400",
                    },
                },
            },
        },
    },
};

export default processStyles;
