import { FunctionComponent } from "react";

interface IconAlertSuccessProps {
    width?: number | string;
    height?: number | string;
}

const IconAlertSuccess : FunctionComponent<IconAlertSuccessProps> = ({  
    width = 68,
    height = 68}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.24 40.12L21.845 32.725C21.2217 32.1017 20.4431 31.8047 19.5092 31.8342C18.5731 31.8614 17.7933 32.1867 17.17 32.81C16.5467 33.4333 16.235 34.2267 16.235 35.19C16.235 36.1533 16.5467 36.9467 17.17 37.57L26.86 47.26C27.4833 47.8833 28.2767 48.195 29.24 48.195C30.2033 48.195 30.9967 47.8833 31.62 47.26L50.915 27.965C51.5383 27.3417 51.8364 26.5619 51.8092 25.6258C51.7797 24.6919 51.4533 23.9133 50.83 23.29C50.2067 22.6667 49.4133 22.355 48.45 22.355C47.4867 22.355 46.6933 22.6667 46.07 23.29L29.24 40.12ZM34 68C29.2967 68 24.8767 67.1069 20.74 65.3208C16.6033 63.5369 13.005 61.115 9.945 58.055C6.885 54.995 4.46307 51.3967 2.6792 47.26C0.893067 43.1233 0 38.7033 0 34C0 29.2967 0.893067 24.8767 2.6792 20.74C4.46307 16.6033 6.885 13.005 9.945 9.945C13.005 6.885 16.6033 4.46193 20.74 2.6758C24.8767 0.891933 29.2967 0 34 0C38.7033 0 43.1233 0.891933 47.26 2.6758C51.3967 4.46193 54.995 6.885 58.055 9.945C61.115 13.005 63.5369 16.6033 65.3208 20.74C67.1069 24.8767 68 29.2967 68 34C68 38.7033 67.1069 43.1233 65.3208 47.26C63.5369 51.3967 61.115 54.995 58.055 58.055C54.995 61.115 51.3967 63.5369 47.26 65.3208C43.1233 67.1069 38.7033 68 34 68ZM34 61.2C41.5367 61.2 47.9547 58.5514 53.2542 53.2542C58.5514 47.9547 61.2 41.5367 61.2 34C61.2 26.4633 58.5514 20.0453 53.2542 14.7458C47.9547 9.4486 41.5367 6.8 34 6.8C26.4633 6.8 20.0464 9.4486 14.7492 14.7458C9.44973 20.0453 6.8 26.4633 6.8 34C6.8 41.5367 9.44973 47.9547 14.7492 53.2542C20.0464 58.5514 26.4633 61.2 34 61.2Z" fill="#7282B8" />
        </svg>

    )
}

export default IconAlertSuccess;