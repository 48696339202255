import { FunctionComponent, JSXElementConstructor, MouseEventHandler, ReactElement, useState } from 'react'
import { Box, Modal, Button } from '@mui/material'
import customModalStyles from './customModal.styles'
import IconClose from '../../icons/IconClose'

interface CustomModalProps {
  viewEntrie?: any
  maxWidth?: string | number
  maxHeight?: string | number
  borderRadius?: string
  open: boolean
  onCloseModal?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  onCloseForButtom?: MouseEventHandler<HTMLButtonElement>
  children?: ReactElement<any, string | JSXElementConstructor<any>> | null
  showCloseButton?: boolean
}

const CustomModal: FunctionComponent<CustomModalProps> = ({
  open,
  onCloseModal,
  onCloseForButtom,
  viewEntrie,
  maxWidth = 1066.67,
  maxHeight = 600,
  showCloseButton = true,
  children,
  borderRadius = '50px',
}) => {
  const [openBlur, setOpenBlur] = useState(true)
  // setOpenBlur((blur) => !blur);

  return (
    <>
      {/* Si el modal tiene una entrada de acceso, boton/ texto/otros */}
      {viewEntrie ?? viewEntrie}

      <Modal
        hideBackdrop
        open={open}
        onClose={onCloseModal}
        // onFocus={() => setOpenBlur(true)}
        onBlur={() => setOpenBlur(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={customModalStyles.modal}
      >
        <>
          {/* Fondo opaco */}
          <Box sx={customModalStyles.opaqueBackground} />
          {/* Card */}
          <Box
            sx={{
              ...customModalStyles.card,
              maxWidth,
              maxHeight,
              borderRadius,
            }}
          >
            {showCloseButton && (
              <Button onClick={onCloseForButtom} sx={customModalStyles.closeButton}>
                <IconClose />
              </Button>
            )}
            {(children ??= null)}
          </Box>
        </>
      </Modal>
    </>
  )
}

export default CustomModal
