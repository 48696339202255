import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import IconTask from '../../../components/icons/IconTask'
const tareasInfo = [
  {
    nombre: 'Aprobación del documento',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Documento del plan de estudio ',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Proyecto de Plan de mantenimiento',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Resultado final del cargue masivo de archivos N°3',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Proyecto de Nueva conformidad',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Crear objetivo',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Proyecto de Plan de mantenimiento',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Aprobación del documento',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Resultado final del cargue masivo de archivos N°5',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Proyecto de programa nuevo',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Aprobación del documento',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Documento del plan de estudio',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Proyecto de Plan de mantenimiento',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Resultado final del cargue masivo de archivos N°7',
    valor: false,
    url: '#',
  },
  {
    nombre: 'Proyecto de Nueva conformidad',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Crear objetivo',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Aprobación del documento',
    valor: true,
    url: '#',
  },
  {
    nombre: 'Proyecto de programa nuevo',
    valor: false,
    url: '#',
  },
]

const Task = () => {
  return (
    <>
      {/*************************
        CONTENEDOR MIS TAREAS
      ***************************/}
      <Box className="contenedorTareas">
        {/**********************
          TITULO MIS TAREAS
        ************************/}
        <Box className="tituloTareas">
          {/***************
            ICONO TAREAS
          ***************/}
          <IconTask width={19} height={25} />

          <Typography>Mis tareas</Typography>

          <Box>
            <Box>
              <span>{tareasInfo.length}</span>
            </Box>

            <Typography
              component="a"
              href="#"
              sx={{
                // backgroundColor: "pink",
                fontSize: '12px !important',
                fontWeight: '300 !important',
                lineHeight: '18px',
                fontStyle: 'normal',
                display: 'flex',
                justifyContent: 'end',
                textDecoration: 'none',
                color: '#1F398F',
              }}
            >
              Ver Más...
            </Typography>
          </Box>
        </Box>

        {/*********************
          LISTA MIS TAREAS
        **********************/}
        <Box
          className="listaTareas"
          sx={{
            overflowY: tareasInfo.length > 5 ? 'scroll' : 'hidden',
          }}
        >
          {tareasInfo.map(({ nombre, valor, url }, index) => (
            <List key={index + nombre}>
              <ListItem component="a" href={url}>
                {valor !== true ? (
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5.5" cy="5.5" r="5.5" fill="#FE5E5E" />
                  </svg>
                ) : (
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5.5" cy="5.5" r="5.5" fill="#6BFE5E" />
                  </svg>
                )}

                {/**********
                  LISTA
                ***********/}
                <ListItemText primary={nombre} />
              </ListItem>
            </List>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default Task
