import { FunctionComponent, useEffect } from "react";
import { Box, Button, Typography, InputBase } from "@mui/material";
import CustomSelect from "../../../inputSelect/CustomSelect";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ErrorField from "../../../ErrorField";
import { FormIndicatorValues } from "../../../../interfaces/pdi/indicator";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Goal } from "../../../../interfaces/pdi/goal";
import { MeasureUnit } from "../../../../interfaces/pdi/measureUnit";
import { Trend } from "../../../../interfaces/pdi/trend";
import { MeasurementFrecuency } from "../../../../interfaces/pdi/measurementFrecuency";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { formIndicatorSchema } from "../../../../schemas/pdi/indicatorSchema";
import { Type } from "../../../../interfaces/pdi/types";
import formStyles from "../../form.styles";
import SelectPagination from "../../../inputSelect/SelectPagination";
import { PdiModel } from "../../../../interfaces/pdi/pdi";

interface FormIndicatorProps {
    pdi: PdiModel
    defaultValues?: Partial<FormIndicatorValues>;
    goals: Goal[];
    measureUnits: MeasureUnit[];
    measurementFrecuencies: MeasurementFrecuency[];
    trends: Trend[];
    types: Type[];
    onCancel: () => void;
    onSubmit: (formValues: FormIndicatorValues, reset: () => void) => void;
    viewMode?: "create" | "update";
}

const _defaultValues: Partial<FormIndicatorValues> = {
    idGoal: "",
    idMeasurementFrecuency: "",
    idTrend: "",
    observation: "",
    formula: "",
    toleranceLower: "",
    toleranceUpper: "",
    idType: "",
    idMeasureUnit: "",
    name: "",
};

const FormIndicator: FunctionComponent<FormIndicatorProps> = ({
    pdi,
    goals,
    measureUnits,
    measurementFrecuencies,
    trends,
    types,
    defaultValues = _defaultValues,
    viewMode,
    onSubmit,
    onCancel,
}) => {
    const getFixInitialValues = (): Partial<FormIndicatorValues> => {
        const {
            idGoal,
            idMeasureUnit,
            idMeasurementFrecuency,
            idTrend,
            idType,
        } = defaultValues;
        const isInRangeGoal = goals.some((g) => g._id === idGoal);
        const inRangeMeasureUnit = measureUnits.some(
            (m) => m._id === idMeasureUnit
        );
        const inRangeMeasurementFrecuency = measurementFrecuencies.some(
            (m) => m._id === idMeasurementFrecuency
        );
        const inRangeTrend = trends.some((t) => t._id === idTrend);
        const inRangeType = types.some((t) => t._id === idType);
        return {
            ...defaultValues,
            idGoal: isInRangeGoal ? idGoal : "",
            idMeasureUnit: inRangeMeasureUnit ? idMeasureUnit : "",
            idMeasurementFrecuency: inRangeMeasurementFrecuency
                ? idMeasurementFrecuency
                : "",
            idTrend: inRangeTrend ? idTrend : "",
            idType: inRangeType ? idType : "",
            idPdi: pdi._id
        };
    };

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<FormIndicatorValues>({
        mode: "onChange",
        resolver: yupResolver(formIndicatorSchema),
        defaultValues: getFixInitialValues(),
    });

    useEffect(() => {
        const fixDefaultValues = getFixInitialValues();
        reset(fixDefaultValues);
    }, [defaultValues]);

    const submit: SubmitHandler<FormIndicatorValues> = (formValues) =>
        onSubmit(formValues, () => reset(_defaultValues));

    return (
        <>
            {/*************************************
                CONTENEDOR FORMULARIO INDICADORES
            *************************************/}
            <Box
                component="form"
                onSubmit={handleSubmit(submit)}
                sx={formStyles.container}
            >
                {/**********************
                    NOMBRE DE LA META
                ************************/}
                <Box sx={formStyles.containerTitle}>
                    <Typography sx={formStyles.title}>
                        Nombre de la meta
                    </Typography>

                    <Controller
                        name="idGoal"
                        control={control}
                        render={({ field }) => (
                            <SelectPagination
                                {...field}
                                propValue="_id"
                                propText="title"
                                items={goals}
                            />
                        )}
                    />

                    {/*********************
                        MENSAJE DE ERROR
                    *********************/}
                    <ErrorField message={errors.idGoal?.message} />
                </Box>

                {/***********
                    NOMBRE
                ************/}
                <Box sx={formStyles.containerTitle}>
                    <Typography sx={formStyles.title}>Nombre</Typography>

                    {/**********
                        INPUT
                    **********/}
                    <Box sx={formStyles.input}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <InputBase
                                    {...field}
                                    sx={formStyles.inputBase}
                                />
                            )}
                        />
                    </Box>

                    {/*********************
                        MENSAJE DE ERROR
                    *********************/}
                    <ErrorField message={errors.name?.message} />
                </Box>

                {/**********************************
                    TIPO DE INDICADOR/TENDENCIA
                ***********************************/}
                <Box sx={formStyles.containerFlex}>
                    {/*********************
                        TIPO DE INDICADOR
                    **********************/}
                    <Box
                        sx={{ ...formStyles.contentGrid, mr: { md: 4, xs: 0 } }}
                    >
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography sx={formStyles.titleHorizontal}>
                                Tipo de indicador
                            </Typography>

                            <Box sx={{ width: `100%` }}>
                                <Box
                                    sx={{
                                        ...formStyles.input,
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        name="idType"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomSelect
                                                {...field}
                                                propValue="_id"
                                                propText="name"
                                                items={types}
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField message={errors.idType?.message} />
                            </Box>
                        </Box>
                    </Box>

                    {/**************
                        TENDENCIA
                    ***************/}
                    <Box sx={formStyles.contentGrid}>
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography
                                sx={formStyles.titleHorizontal}
                                width="160px"
                            >
                                Tendencia
                            </Typography>

                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        ...formStyles.input,
                                        ml: { xs: 0, md: 0 },
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        name="idTrend"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomSelect
                                                {...field}
                                                propValue="_id"
                                                propText="name"
                                                items={trends}
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField message={errors.idTrend?.message} />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/********************************************
                    UNIDAD DE MEDIDA/FRECUENCIA DE MEDICION
                *********************************************/}
                <Box sx={formStyles.containerFlex}>
                    {/*********************
                        UNIDAD DE MEDIDA
                    **********************/}
                    <Box
                        sx={{ ...formStyles.contentGrid, mr: { md: 4, xs: 0 } }}
                    >
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography sx={formStyles.titleHorizontal}>
                                Unidad de medida
                            </Typography>

                            <Box sx={{ width: `100%` }}>
                                <Box
                                    sx={{
                                        ...formStyles.input,
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        name="idMeasureUnit"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomSelect
                                                {...field}
                                                propValue="_id"
                                                propText="name"
                                                items={measureUnits}
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField
                                    message={errors.idMeasureUnit?.message}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {/***************************
                        FRECUENCIA DE MEDICION
                    ****************************/}
                    <Box sx={formStyles.contentGrid}>
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography sx={formStyles.titleHorizontal}>
                                Frecuencia de medición
                            </Typography>

                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        ...formStyles.input,
                                        ml: { xs: 0, md: 0 },
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        name="idMeasurementFrecuency"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomSelect
                                                {...field}
                                                propValue="_id"
                                                propText="name"
                                                items={measurementFrecuencies}
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField
                                    message={
                                        errors.idMeasurementFrecuency?.message
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/************************************************
                    META/TOLERANCIA SUPERIOR/TOLERANCIA INFERIOR
                *************************************************/}
                <Box
                    sx={{
                        ...formStyles.containerFlex,
                        "@media (max-width: 1150px)": {
                            flexDirection: "column",
                        },
                    }}
                >
                    {/*********
                        META
                    **********/}
                    <Box
                        sx={{
                            ...formStyles.contentGridThree,
                            mr: { md: 4, xs: 0 },
                        }}
                    >
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography sx={{ ...formStyles.titleHorizontal }}>
                                Meta
                            </Typography>

                            <Box sx={{ ...formStyles.input, width: "100%" }}>
                                <Controller
                                    name="goal"
                                    control={control}
                                    render={({
                                        field: { value, ...field },
                                    }) => (
                                        <InputBase
                                            {...field}
                                            placeholder="50..."
                                            value={value ?? ""}
                                            type="number"
                                            sx={formStyles.inputBase}
                                        />
                                    )}
                                />
                            </Box>

                            {/*********************
                                MENSAJE DE ERROR
                            *********************/}
                            <ErrorField message={errors.goal?.message} />
                        </Box>
                    </Box>

                    {/*************************
                        TOLERANCIA SUPERIOR
                    **************************/}
                    <Box
                        sx={{
                            ...formStyles.contentGridThree,
                            mr: { md: 4, xs: 0 },
                        }}
                    >
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography sx={formStyles.titleHorizontal}>
                                Tolerancia superior
                            </Typography>

                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        ...formStyles.input,
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        name="toleranceUpper"
                                        control={control}
                                        render={({ field }) => (
                                            <InputBase
                                                {...field}
                                                fullWidth
                                                sx={formStyles.inputBase}
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField
                                    message={errors.toleranceUpper?.message}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {/************************
                        TOLERANCIA INFERIOR
                    *************************/}
                    <Box sx={formStyles.contentGridThree}>
                        <Box sx={formStyles.containerTitleHorizontal}>
                            <Typography sx={formStyles.titleHorizontal}>
                                Tolerancia inferior
                            </Typography>

                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        ...formStyles.input,
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        name="toleranceLower"
                                        control={control}
                                        render={({ field }) => (
                                            <InputBase
                                                {...field}
                                                fullWidth
                                                sx={formStyles.inputBase}
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField
                                    message={errors.toleranceLower?.message}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/*************************
                    FORMULA/OBSERVACIONES
                **************************/}
                <Box sx={formStyles.containerFlex}>
                    {/****************
                        FORMULA
                    *****************/}
                    <Box
                        sx={{ ...formStyles.contentGrid, mr: { md: 4, xs: 0 } }}
                    >
                        <Box sx={formStyles.containerTitle}>
                            <Typography sx={formStyles.title}>
                                Formula
                            </Typography>

                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        ...formStyles.inputDescription,
                                    }}
                                >
                                    <Controller
                                        name="formula"
                                        control={control}
                                        render={({ field }) => (
                                            <InputBase
                                                {...field}
                                                multiline
                                                maxRows={5}
                                                sx={
                                                    formStyles.inputBaseDescription
                                                }
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField message={errors.formula?.message} />
                            </Box>
                        </Box>
                    </Box>

                    {/****************
                        OBSERVACIONES
                    *****************/}
                    <Box sx={formStyles.contentGrid}>
                        <Box sx={formStyles.containerTitle}>
                            <Typography sx={formStyles.title} width="130px">
                                Observaciones
                            </Typography>

                            <Box sx={{ width: `100%` }}>
                                <Box sx={{ ...formStyles.inputDescription }}>
                                    <Controller
                                        name="observation"
                                        control={control}
                                        render={({ field }) => (
                                            <InputBase
                                                {...field}
                                                multiline
                                                maxRows={5}
                                                sx={
                                                    formStyles.inputBaseDescription
                                                }
                                            />
                                        )}
                                    />
                                </Box>

                                {/*********************
                                    MENSAJE DE ERROR
                                *********************/}
                                <ErrorField
                                    message={errors.observation?.message}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/***********
                    ESTADO
                *************/}
                {viewMode === "update" && (
                    <>
                        <Box sx={formStyles.containerState}>
                            <Typography sx={formStyles.title}>
                                Estado
                            </Typography>

                            {/***********************
                                CHECKBOX DE ESTADO
                            ***********************/}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="state"
                                            control={control}
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    ...field
                                                },
                                            }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        value === true
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={({
                                                        target: { checked },
                                                    }) => onChange(checked)}
                                                    disableTouchRipple
                                                />
                                            )}
                                        />
                                    }
                                    label="Activo"
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className="inactivo"
                                    control={
                                        <Controller
                                            name="state"
                                            control={control}
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    ...field
                                                },
                                            }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        value === false
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={({
                                                        target: { checked },
                                                    }) => onChange(!checked)}
                                                    disableTouchRipple
                                                />
                                            )}
                                        />
                                    }
                                    label="Inactivo"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Box>

                        {/*********************
                            MENSAJE DE ERROR
                        *********************/}
                        <ErrorField message={errors.state?.message} />
                    </>
                )}

                {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
                <Box sx={formStyles.divButton}>
                    {/**********************************
                        BOTONES ACTUALIZAR/DESACTIVAR
                    ***********************************/}
                    <Box>
                        {/********************
                            BOTON ACTUALIZAR
                        *********************/}
                        <Button
                            disableTouchRipple
                            disabled={!isValid}
                            type="submit"
                            sx={formStyles.buttonSave}
                        >
                            Guardar
                        </Button>

                        {/********************
                            BOTON DESACTIVAR
                        *********************/}
                        <Button
                            disableTouchRipple
                            sx={formStyles.buttonCancel}
                            onClick={onCancel}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FormIndicator;
