import { FunctionComponent } from "react";
import { Box, Button, Typography } from '@mui/material'
import modalDetailItemPdiStyles from "./modalDetailItemPdi.styles";
import CustomModal from "../customModal/CustomModal";

type KeyRender = { title: string, value: string | number }

export interface ModalDetailItemPdiProps {
    show?: boolean,
    title?: string,
    textButtonAction?: string,
    onClickButtonAction?: React.MouseEventHandler<HTMLButtonElement>
    onCloseForButtom?: React.MouseEventHandler<HTMLButtonElement>
    itemsRender: KeyRender[]
    maxHeight?: string | number
    maxWidth?: string | number
}
const ModalDetailItemPdi: FunctionComponent<ModalDetailItemPdiProps> = (
    { 
        show = false,
        itemsRender,
        title = 'Detalles',
        onClickButtonAction,
        onCloseForButtom,
        textButtonAction = 'Action',
        maxHeight = 1000,
        maxWidth = 650
    }
) => {

    return (
            <CustomModal
                open={show}
                onCloseForButtom={onCloseForButtom}
                maxHeight = {maxHeight}
                maxWidth = {maxWidth}
            >
                {/**************
                    CONTENEDOR
                ***************/}
                <Box sx={modalDetailItemPdiStyles.container}>

                    <Box sx={modalDetailItemPdiStyles.header}>
                        {/**************
                            TITULO ITEM
                        ***************/}
                        <Box>
                            <Typography noWrap sx={modalDetailItemPdiStyles.title}>
                                {title}
                            </Typography>
                        </Box>
                        <Box sx={modalDetailItemPdiStyles.containerButtonheader}>
                            <Button sx={modalDetailItemPdiStyles.buttonHeader}
                                fullWidth
                                onClick={onClickButtonAction}
                            >
                                {textButtonAction}
                            </Button>
                        </Box>

                    </Box>

                    {/**************
                        BODY
                    ****************/}
                    <Box sx={modalDetailItemPdiStyles.body}>
                        {itemsRender.map(({ title, value }, index) => (
                            <Box key={`${value}--content--${index}`} sx={modalDetailItemPdiStyles.contentText}>
                                <Typography sx={modalDetailItemPdiStyles.subtitle}>
                                    {title}
                                </Typography>
                                <Typography sx={modalDetailItemPdiStyles.text}>
                                    {value}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </CustomModal>
    )
}

export default ModalDetailItemPdi;