/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Button, Pagination } from '@mui/material'

// IMPORTADOS
import IconAdd from '../../../components/icons/IconAdd'
import usersStyles from './users.styles'
import CustomModal from '../../../components/CustomModal'
import InfoUser from './InfoUser'
import { useEffect, useState } from 'react'
import useUsers from '../../../hooks/users/useUsers'
import ButtonEdit from '../../../components/utilities/buttons/ButtonEdit'
import ButtonDelete from '../../../components/utilities/buttons/ButtonDelete'
import Spinner from '../../../components/utilities/Spinner'
import ButtonSearch from '../../../components/buttons/ButtonSearch'
import ValidateAction from '../../../routes/validateAction'
import { listPermissions } from '../../../routes/permissions'

const Users = () => {
  const [btnOpen, setBtnOpen] = useState(false)
  const [listUsers, setListUsers] = useState<any[]>([])
  const [users, setUsers] = useState<any[]>([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { fetchGetUsers } = useUsers()

  const list = async () => {
    setLoading(true)
    const response: any = await fetchGetUsers({ limit, from })
    setListUsers(response.items)
    setUsers(response.items)
    const pages = response.total / 10

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    list()
  }, [page])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  return (
    <>
      {/***********************
                CONTENEDOR USUARIOS
            ************************/}
      <Box sx={usersStyles.container}>
        {/**********************************
                    CONTENEDOR TITULO Y BOTON CREAR
                ***********************************/}
        <Box sx={usersStyles.title}>
          {/***************
                        TITULO USUARIOS
                    ****************/}
          <Typography>Usuarios</Typography>

          {/**************************
                        BOTONES CREAR Y BUSCAR
                    ***************************/}
          <Box>
            <ValidateAction permission={[listPermissions.user.create]}>
              <Button className="buttonCreate" href="users/create" disableTouchRipple>
                <IconAdd />

                <Typography>Crear nuevo usuario</Typography>
              </Button>
            </ValidateAction>
            <ButtonSearch
              data={users}
              setData={setListUsers}
              fieldSearch={[
                'idProfile.name',
                'idProfile.lastName',
                'idBranchOffice.nameBranchOffice',
                'idDepartament.name',
                'idPosition.name',
                'idBranchOffice.city',
              ]}
            />
          </Box>
        </Box>

        {/**********************
                    TABLA DE USUARIOS
                ***********************/}
        <Box sx={usersStyles.userTable}>
          <table>
            {/***********
                            TITULOS
                        ************/}
            <thead>
              <tr>
                <th>Nombre del usuario</th>
                <th>Sede</th>
                <th>Área</th>
                <th>Cargo</th>
                <th>Ciudad</th>
                <th></th>
              </tr>
            </thead>

            {/**********************
                            LISTA DE USUARIOS
                        ***********************/}
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} rowSpan={5}>
                    <Box
                      sx={{
                        height: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </Box>
                  </td>
                </tr>
              ) : (
                listUsers.map(listUser => (
                  <tr key={listUser._id}>
                    <td>
                      <Box>
                        <CustomModal
                          text={`${listUser.idProfile?.name} ${listUser.idProfile?.lastName}` || 'Sin Datos'}
                          margin="5.5% auto"
                          maxHeight="761px"
                        >
                          <InfoUser dataUser={listUser} />
                        </CustomModal>
                      </Box>

                      {/* <Button disableTouchRipple>
                                            {listUser.name}
                                        </Button> */}
                    </td>

                    <td>
                      <Box>{listUser.idProfile?.idBranchOffice?.nameBranchOffice || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <Box>{listUser.idProfile?.idDepartament?.name || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Box>{listUser.idProfile?.idPosition?.name || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Box>{listUser.idProfile?.idBranchOffice?.city || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <Box sx={{ display: 'flex' }}>
                        <ValidateAction permission={[listPermissions.user.update]}>
                          <ButtonEdit id={listUser._id} />
                        </ValidateAction>
                        <ValidateAction permission={[listPermissions.user.delete]}>
                          <ButtonDelete id={listUser._id} listUser={list} />
                        </ValidateAction>
                      </Box>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>

        {/****************
                    PAGINACION
                ****************/}
        <Box sx={usersStyles.pagesList}>
          <Pagination count={pages} page={page} onChange={changePage} />
        </Box>
      </Box>
    </>
  )
}

export default Users
