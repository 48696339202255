import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys = "container" | "content" | "contentLeft" | "contentRight";

const familyStyles: StyleComponent<Keys> = {
    container: {
        backgroundColor: "#ffffff",
        minHeight: "52.7vh",
        maxWidth: "1340px",
        margin: "auto",
    },

    // CONTENIDO
    content: {
        // backgroundColor: "yellow",
        marginTop: "42px",
        display: "flex",
        justifyContent: "center",

        // RESPONSIVE
        "@media (max-width: 1090px)": {
            marginX: "20px",
        },

        "@media (max-width: 565px)": {
            flexWrap: "wrap",

            ">button, >a": {
                marginRight: "0px",
                marginLeft: "0px",
            },
        },

        // CONTENTIDO IZQUIERDO Y DERECHO
        ">button, >a": {
            width: "500px",
            height: "425px",
            padding: "0px",
            borderRadius: "17px",
            boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",
            // display: "block",

            ">div": {
                // backgroundColor: "aquamarine",
                // marginTop: "120px",
                // marginX: "auto",
                width: "min-content",
                display: "flex",
                flexDirection: "column",

                ">svg": {
                    marginBottom: "50px",
                },

                ">p": {
                    color: "#1F398F",
                    fontSize: "25px",
                    fontWeight: 600,
                    textAlign: "center",
                    textTransform: "capitalize",
                },
            },
        },
    },

    // CONTENIDO IZQUIERDO
    contentLeft: {
        backgroundColor: "#FFFFFF",
        marginRight: "29px",
    },

    // CONTENIDO DERECHA
    contentRight: {
        backgroundColor: "#FFFFFF",
        marginLeft: "29px",

        // RESPONDIVE
        "@media (max-width: 565px)": {
            marginTop: "20px",
        },
    },
};

export default familyStyles;
