import { FunctionComponent } from 'react'
import { Box, Button, Typography, Fade } from '@mui/material'
import customAlerts from '../../../../helpers/customAlerts'
import useViewControlTabPanel from '../../../../hooks/pdi/useViewControlTabPanelPdi'
import { MeasureUnit } from '../../../../interfaces/pdi/measureUnit'
import { Goal } from '../../../../interfaces/pdi/goal'
import {
  ChangeActiveIndicator,
  FormIndicatorValues,
  IndicatorModel,
  NewIndicator,
  UpdateIndicator,
} from '../../../../interfaces/pdi/indicator'
import { Trend } from '../../../../interfaces/pdi/trend'
import IconAdd from '../../../icons/IconAdd'
import FormIndicator from '../../../forms/pdi/formIndicator/FormIndicator'
import useIndicator from '../../../../hooks/pdi/useIndicator'
import FilterPdi from '../../../inputSelect/FilterPdi'
import TableIndicators from '../../../tables/pdi/tableIndicators/TableIndicators'
import { MeasurementFrecuency } from '../../../../interfaces/pdi/measurementFrecuency'
import { Type } from '../../../../interfaces/pdi/types'
import formStyles from '../../../forms/form.styles'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface TabPanelIndicatorsProps {
  pdi: PdiModel
  indicators: IndicatorModel[]
  measureUnits: MeasureUnit[]
  types: Type[]
  trends: Trend[]
  goals: Goal[]
  measurementFrecuencies: MeasurementFrecuency[]
  onCreate: (_id: string) => void
  onChangeTitle: (tabTitle: string | null) => void
  onUpdate: (indicators: IndicatorModel[]) => void
}

const { errorAlert } = customAlerts

const TabPanelIndicators: FunctionComponent<TabPanelIndicatorsProps> = ({
  pdi,
  indicators,
  measureUnits,
  goals,
  trends,
  measurementFrecuencies,
  types,
  onCreate,
  onChangeTitle,
  onUpdate,
}) => {
  const { fetchCreateIndicator, fetchUpdateIndicator, fetchDisableIndicator, ViewAlert } = useIndicator()

  const { viewMode, showViewData, showViewCreate, showViewUpdate, updateItem, filterOption, changeFilterOption } =
    useViewControlTabPanel<IndicatorModel, Goal>({
      items: indicators,
      onChangeTitle,
      titleOptions: {
        none: null,
        create: 'Crear indicador',
        update: 'Actualizar indicador',
        data: 'Indicadores',
      },
    })

  const indicatorsFiltered = (): IndicatorModel[] => {
    if (filterOption === null) return indicators
    const { _id } = filterOption
    return indicators.filter(indi => indi.idGoal === _id)
  }

  const getFormDefaultValues = (): Partial<FormIndicatorValues> | undefined => {
    if (!updateItem) return undefined
    const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } = updateItem
    return updateValues
  }

  const create = ({ state, ...other }: FormIndicatorValues, reset: () => void) => {
    const newIndicator: NewIndicator = other
    fetchCreateIndicator(newIndicator, _id => {
      reset()
      onCreate(_id)
    })
  }

  const update = (formValues: FormIndicatorValues, reset: () => void) => {
    if (!updateItem) {
      errorAlert({ text: 'Operación no disponible' })
      return
    }
    const { _id } = updateItem
    const updateIndicator: UpdateIndicator = { _id, ...formValues }
    fetchUpdateIndicator(updateIndicator, _updateValues => {
      reset()
      const { _id } = _updateValues
      const _updateIndicators = indicators.map(indicator => {
        if (indicator._id === _id) {
          return { ...indicator, ..._updateValues }
        }
        return indicator
      })
      onUpdate(_updateIndicators)
      showViewData()
    })
  }

  const disable = ({ _id }: IndicatorModel) => {
    const disableIndicator: ChangeActiveIndicator = {
      _id,
      is_active: false,
    }
    fetchDisableIndicator(disableIndicator, () => {
      const _indicators = indicators.filter(indicator => indicator._id !== _id)
      onUpdate(_indicators)
    })
  }

  const onSubmitForm = (formValues: FormIndicatorValues, reset: () => void) => {
    if (updateItem) return update(formValues, reset)
    create(formValues, reset)
  }

  return (
    <>
      <ViewAlert />
      <Fade in={viewMode === 'update' || viewMode === 'create'}>
        <Box
          sx={{
            display: viewMode === 'data' || !viewMode ? 'none' : 'block',
          }}
        >
          <FormIndicator
            pdi={pdi}
            defaultValues={getFormDefaultValues()}
            goals={goals}
            measureUnits={measureUnits}
            measurementFrecuencies={measurementFrecuencies}
            types={types}
            trends={trends}
            onCancel={showViewData}
            onSubmit={onSubmitForm}
            viewMode={viewMode === 'data' || !viewMode ? undefined : viewMode}
          />
        </Box>
      </Fade>
      {/***************
                CONTENEDOR TABLA DE CARTAS INDICADORES
            ****************/}
      <Fade in={viewMode === 'data'}>
        <Box sx={{ display: viewMode === 'data' ? 'block' : 'none' }}>
          <Box sx={{ ...formStyles.container }}>
            {/****************
                            TITULO
                        *****************/}
            <Box sx={formStyles.buttonTitle}>
              {/***************
                                TITULO PDI
                            ****************/}
              <Typography sx={{ fontSize: '25px' }}>
                {filterOption ? `Indicadores de ${filterOption.title}` : 'Todos los indicadores'}
              </Typography>

              <Box>
                {/***************************
                                    BOTON FILTRAR POR METAS
                                ****************************/}
                <FilterPdi
                  getOptionLabel={option => option.title}
                  id="filter-indicator-by-goals"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  labelInput="Metas"
                  noOptionsText="Sin resultados"
                  options={goals}
                  onChange={(e, value) => changeFilterOption(value)}
                  placeholder="Buscar meta..."
                  value={filterOption}
                />

                {/*************
                                    BOTON MÁS
                                **************/}
                <Button onClick={showViewCreate} disableTouchRipple>
                  <IconAdd />
                  <Typography>Crear nuevo indicador</Typography>
                </Button>
              </Box>
            </Box>

            <TableIndicators
              indicators={indicatorsFiltered()}
              filterOption={filterOption}
              goals={goals}
              measureUnits={measureUnits}
              measurementFrecuencies={measurementFrecuencies}
              trends={trends}
              types={types}
              onDelete={disable}
              onUpdate={showViewUpdate}
            />
          </Box>
        </Box>
      </Fade>
    </>
  )
}

export default TabPanelIndicators
