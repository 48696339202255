/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography, InputBase, Button } from '@mui/material'
import useDateField, { toDaysFormat } from '../../../../hooks/dateField/useDateField'
import ErrorField from '../../../ErrorField'
import formStyles from '../../form.styles'
import SelectPagination from '../../../inputSelect/SelectPagination'
import { FormPdaFields } from '../../../../interfaces/pda/pda'
import { formPdaSchema } from '../../../../schemas/pda/pdaSchema'
import { BranchOffice } from '../../../../interfaces/branchOffice'
import { LinkedPdi, Pdi, PdiModel } from '../../../../interfaces/pdi/pdi'

interface FormPdaProps {
  activePdi: PdiModel | Pdi | LinkedPdi | null
  buttonSubmitTitle?: string
  branchOffices: BranchOffice[]
  defaultValues?: Partial<FormPdaFields>
  onSubmit: (formValues: FormPdaFields) => void
  onCancel: () => void
}

const _defaultValues: Partial<FormPdaFields> = {
  description: '',
  title: '',
  idBranchOffice: '',
}

const FormPda: FunctionComponent<FormPdaProps> = ({
  activePdi,
  buttonSubmitTitle = 'Crear',
  branchOffices,
  defaultValues = _defaultValues,
  onSubmit,
  onCancel,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<FormPdaFields>({
    mode: 'onChange',
    resolver: yupResolver(formPdaSchema),
    defaultValues: defaultValues,
  })

  const DateField = useDateField<FormPdaFields>()

  const submit: SubmitHandler<FormPdaFields> = formValues => onSubmit(formValues)

  useEffect(() => {
    reset({...defaultValues})
  }, [defaultValues])

  return (
    <>
      {/*****************************
        CONTENEDOR FORMULARIO PDA
      ******************************/}
      <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
        {/****************
          TITULO
        *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Título</Typography>

          {/***********
            INPUT
          ***********/}
          <Box sx={formStyles.input}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
            />
          </Box>

          {/*********************
            MENSAJE DE ERROR
          *********************/}
          <ErrorField message={errors.title?.message} />
        </Box>

        {/****************
          DESCRIPCION
        *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Descripción</Typography>

          {/***********
            INPUT
          ***********/}
          <Box sx={formStyles.inputDescription}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <InputBase {...field} maxRows={5} multiline sx={formStyles.inputBaseDescription} />
              )}
            />
          </Box>

          {/*********************
            MENSAJE DE ERROR
          *********************/}
          <ErrorField message={errors.description?.message} />
        </Box>

        {/*******************
          NOMBRE SUCURSAL
        ********************/}
        <Box sx={formStyles.containerGrid}>
          <Box
            sx={{
              ...formStyles.containerTitle,
              marginRight: '30px',
            }}
          >
            <Typography sx={formStyles.title}>Nombre de la sucursal</Typography>
            <Controller
              name="idBranchOffice"
              control={control}
              render={({ field }) => (
                <SelectPagination {...field} propValue="_id" propText="nameBranchOffice" items={branchOffices} />
              )}
            />
          </Box>

          {/**********************************
            NOMBRE DEL PLAN DE DESARROLLO
          ***********************************/}
          <Box sx={formStyles.containerTitle}>
            <Typography sx={formStyles.title}>Nombre del Plan de Desarrollo</Typography>

            <Typography component={'span'} sx={formStyles.span}>
              {activePdi ? activePdi.title : 'No se encuentra asociado a un PDI activo'}
            </Typography>
          </Box>
        </Box>

        {/************
          PERIODO
        **************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Periodo</Typography>

          {/****************
            CALENDARIOS
          *****************/}
          {/**********************
            CALENDARIO INICIAL
          ***********************/}
          <DateField
            name="startDate"
            control={{ ...control }}
            labelText="Fecha inicial"
            translateCalendar="dateInitial"
            // disabled={!branchOffice}
          />

          {/*********************
            MENSAJE DE ERROR
          *********************/}
          <ErrorField message={errors.startDate?.message} />

          {/*********************
            CALENDARIO FINAL
          *********************/}
          <DateField
            name="finalDate"
            control={control}
            labelText="Fecha final"
            translateCalendar="dateFinal"
            disabled={!watch().startDate}
            minDate={watch().startDate && toDaysFormat(watch().startDate)?.add(1, 'day')}
          />

          {/*********************
            MENSAJE DE ERROR
          *********************/}
          <ErrorField message={errors.finalDate?.message} />
        </Box>

        {/**********************
          CONTENEDOR BOTONES 
        ***********************/}
        <Box sx={formStyles.divButton}>
          {/**********************************
            BOTONES ACTUALIZAR/DESACTIVAR
          ***********************************/}
          <Box>
            {/********************
              BOTON ACTUALIZAR
            *********************/}
            <Button disableTouchRipple sx={formStyles.buttonSave} type="submit" disabled={!isValid}>
              {buttonSubmitTitle}
            </Button>

            {/********************
              BOTON DESACTIVAR
            *********************/}
            <Button onClick={onCancel} disableTouchRipple sx={formStyles.buttonCancel}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormPda
