import { useTheme } from '@mui/styles'
import { FunctionComponent } from 'react'

interface IconProcessProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconProcess: FunctionComponent<IconProcessProps> = ({ width = 116, height = 116, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.1384 56.835C32.4867 55.9552 31.6609 54.574 31.6609 52.6913C31.6609 51.7412 31.9069 50.879 32.3989 50.1048C32.8909 49.313 33.6376 48.6884 34.6391 48.2309C35.6406 47.7558 36.8705 47.5183 38.3289 47.5183C39.7872 47.5183 41.0084 47.7558 41.9923 48.2309C42.9938 48.6884 43.7406 49.313 44.2325 50.1048C44.7245 50.879 44.9705 51.7412 44.9705 52.6913C44.9705 53.6414 44.7421 54.4684 44.2852 55.1722C43.846 55.876 43.2486 56.4303 42.4931 56.835C43.4419 57.2924 44.171 57.9259 44.6806 58.7352C45.1901 59.527 45.4449 60.4596 45.4449 61.5329C45.4449 62.7821 45.1286 63.8642 44.4961 64.7792C43.8636 65.6766 43.0026 66.3628 41.9132 66.8378C40.8414 67.3129 39.6467 67.5504 38.3289 67.5504C37.0111 67.5504 35.8075 67.3129 34.7182 66.8378C33.6464 66.3628 32.7942 65.6766 32.1617 64.7792C31.5292 63.8642 31.2129 62.7821 31.2129 61.5329C31.2129 60.442 31.4677 59.5006 31.9772 58.7089C32.4867 57.8995 33.2071 57.2748 34.1384 56.835ZM40.5955 53.3775C40.5955 52.6561 40.3846 52.1019 39.9629 51.7148C39.5588 51.3101 39.0141 51.1077 38.3289 51.1077C37.6436 51.1077 37.0902 51.3101 36.6685 51.7148C36.2644 52.1195 36.0623 52.6825 36.0623 53.4039C36.0623 54.0901 36.2732 54.6356 36.6948 55.0403C37.1165 55.4274 37.6612 55.6209 38.3289 55.6209C38.9966 55.6209 39.5412 55.4186 39.9629 55.0139C40.3846 54.6092 40.5955 54.0637 40.5955 53.3775ZM38.3289 58.788C37.5031 58.788 36.8354 59.0168 36.3259 59.4742C35.8163 59.9141 35.5616 60.53 35.5616 61.3217C35.5616 62.0607 35.8075 62.6678 36.2995 63.1428C36.8091 63.6179 37.4855 63.8554 38.3289 63.8554C39.1723 63.8554 39.8312 63.6179 40.3056 63.1428C40.7975 62.6678 41.0435 62.0607 41.0435 61.3217C41.0435 60.5476 40.7887 59.9317 40.2792 59.4742C39.7872 59.0168 39.1371 58.788 38.3289 58.788Z"
        fill="#1F398F"
      />
      <path
        d="M48.0656 57.2836C48.0656 54.2573 48.6454 51.8731 49.8051 50.1312C50.9823 48.3893 52.8711 47.5183 55.4715 47.5183C58.0719 47.5183 59.952 48.3893 61.1116 50.1312C62.2888 51.8731 62.8774 54.2573 62.8774 57.2836C62.8774 60.3452 62.2888 62.747 61.1116 64.4889C59.952 66.2308 58.0719 67.1018 55.4715 67.1018C52.8711 67.1018 50.9823 66.2308 49.8051 64.4889C48.6454 62.747 48.0656 60.3452 48.0656 57.2836ZM58.4497 57.2836C58.4497 55.5065 58.2564 54.1429 57.8699 53.1928C57.4833 52.225 56.6839 51.7412 55.4715 51.7412C54.2592 51.7412 53.4597 52.225 53.0732 53.1928C52.6866 54.1429 52.4933 55.5065 52.4933 57.2836C52.4933 58.4801 52.5636 59.4742 52.7042 60.266C52.8447 61.0402 53.1259 61.6736 53.5476 62.1663C53.9868 62.6414 54.6281 62.8789 55.4715 62.8789C56.3149 62.8789 56.9474 62.6414 57.3691 62.1663C57.8084 61.6736 58.0983 61.0402 58.2388 60.266C58.3794 59.4742 58.4497 58.4801 58.4497 57.2836Z"
        fill="#1F398F"
      />
      <path
        d="M64.8895 52.7705C64.8895 51.3453 65.2936 50.2456 66.1019 49.4714C66.9277 48.6972 67.9907 48.3101 69.2909 48.3101C70.5911 48.3101 71.6453 48.6972 72.4535 49.4714C73.2618 50.2456 73.6659 51.3453 73.6659 52.7705C73.6659 54.1957 73.2618 55.2954 72.4535 56.0696C71.6453 56.8438 70.5911 57.2309 69.2909 57.2309C67.9907 57.2309 66.9277 56.8438 66.1019 56.0696C65.2936 55.2954 64.8895 54.1957 64.8895 52.7705ZM82.7849 48.6004L72.638 67.1809H68.2894L78.4362 48.6004H82.7849ZM69.2645 50.9494C68.4211 50.9494 67.9995 51.5564 67.9995 52.7705C67.9995 53.967 68.4211 54.5652 69.2645 54.5652C69.6686 54.5652 69.9849 54.4156 70.2133 54.1165C70.4417 53.8174 70.5559 53.3687 70.5559 52.7705C70.5559 51.5564 70.1255 50.9494 69.2645 50.9494ZM77.4347 63.0109C77.4347 61.5857 77.8389 60.486 78.6471 59.7118C79.4553 58.9376 80.5095 58.5505 81.8098 58.5505C83.11 58.5505 84.1642 58.9376 84.9724 59.7118C85.7807 60.486 86.1848 61.5857 86.1848 63.0109C86.1848 64.4361 85.7807 65.5358 84.9724 66.31C84.1642 67.0842 83.11 67.4713 81.8098 67.4713C80.5095 67.4713 79.4553 67.0842 78.6471 66.31C77.8389 65.5358 77.4347 64.4361 77.4347 63.0109ZM81.7834 61.1898C81.3793 61.1898 81.063 61.3393 80.8346 61.6385C80.6238 61.9376 80.5183 62.395 80.5183 63.0109C80.5183 64.2074 80.94 64.8056 81.7834 64.8056C82.1875 64.8056 82.5038 64.656 82.7322 64.3569C82.9606 64.0578 83.0748 63.6091 83.0748 63.0109C83.0748 62.4126 82.9606 61.964 82.7322 61.6648C82.5038 61.3481 82.1875 61.1898 81.7834 61.1898Z"
        fill="#1F398F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 101.354C81.9435 101.354 101.354 81.9435 101.354 58C101.354 34.0565 81.9435 14.6465 58 14.6465C34.0565 14.6465 14.6465 34.0565 14.6465 58C14.6465 81.9435 34.0565 101.354 58 101.354ZM58 116C90.0325 116 116 90.0325 116 58C116 25.9675 90.0325 0 58 0C25.9675 0 0 25.9675 0 58C0 90.0325 25.9675 116 58 116Z"
        fill="#7282B8"
      />
      <path
        d="M58.2324 101.351V116C67.3352 116 74.073 114.122 76.3041 113.182C87.3479 109.701 95.0451 103.402 99.2284 99.4236L88.8539 88.9802C87.5153 90.1406 84.3508 93.4484 77.4754 96.9371C70.6149 100.418 62.583 101.413 58.2324 101.351Z"
        fill={secondary ?? color}
      />
    </svg>
  )
}

export default IconProcess
