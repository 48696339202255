import { FunctionComponent, useState } from 'react'
import { Box, Typography, List, ListItem, ListItemButton, ListItemText, Pagination, Fade } from '@mui/material'
import relationsStyles from '../../process/relations/Relations.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import { Condition } from '../../../../../interfaces/condition'
import usePagination from '../../../../../hooks/usePagination'

interface RelationConditionsProps {
  onChangeCondition: (condition: Condition | null) => void
  selectedCondition: Condition | null
  conditions: Condition[] | null
}

const itemsPerPage = 11

const RelationConditions: FunctionComponent<RelationConditionsProps> = ({
  conditions,
  selectedCondition,
  onChangeCondition,
}) => {
  const [searchConditions, setSearchConditions] = useState<Condition[]>([])

  const { currentData, currentPage, countPage, handleChangePage } = usePagination(searchConditions, itemsPerPage)

  return (
    // LISTA CONDICIONES/RELACIONES
    <Box>
      {/***********************
        TITULO CONDICIONES/RELACIONES
    ************************/}
      <Box className="titleButtonSearch" sx={relationsStyles.titleButtonSearch}>
        <Typography>Condiciones</Typography>
        <ButtonSearch data={conditions ?? []} setData={setSearchConditions} fieldSearch={['name']} />
      </Box>

      <Box className="containerList">
        <Fade in={Boolean(currentData().length ) && Boolean(conditions)} hidden={!Boolean(currentData().length) && Boolean(conditions)}>
          <List>
            {currentData().map((condition, index) => (
              <ListItem key={index} onClick={() => onChangeCondition(condition)}>
                <ListItemButton selected={selectedCondition?._id === condition._id} disableTouchRipple>
                  <ListItemText>{condition.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Fade>
        <Fade in={Boolean(!currentData().length && conditions)} hidden={Boolean(currentData().length) || !Boolean(conditions)}>
          <List>
            <Typography sx={{ my: 1 }}>No hay se enconrtraron condiciones disponibles para relacionar.</Typography>
          </List>
        </Fade>
        <Pagination
          page={currentPage}
          defaultPage={1}
          count={countPage}
          onChange={(e, _page) => handleChangePage(_page)}
        />
      </Box>
    </Box>
  )
}

export default RelationConditions
