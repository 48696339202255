import { Box, InputBase, Typography, Button, FormControlLabel, Checkbox, FormGroup } from '@mui/material'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import * as yup from 'yup'

// IMPORTADOS
import { Project } from '../../../../interfaces/pdi/project'
import { FormChallengeValues } from '../../../../interfaces/pdi/challenge'
import { FunctionComponent, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorField from '../../../ErrorField'
import useDateField from '../../../../hooks/dateField/useDateField'
import CustomSelect from '../../../inputSelect/CustomSelect'
import { formChallengeSchema } from '../../../../schemas/pdi/challengeSchema'
import formStyles from '../../form.styles'
import SelectPagination from '../../../inputSelect/SelectPagination'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface FormChallengeProps {
  pdi: PdiModel
  defaultValues?: Partial<FormChallengeValues>
  projects: Project[]
  onCancel: () => void
  onSubmit: (formValues: FormChallengeValues, reset: () => void) => void
  viewMode?: 'create' | 'update'
}

const _defaultValues: Partial<FormChallengeValues> = {
  idProject: '',
  description: '',
}

const FormChallenge: FunctionComponent<FormChallengeProps> = ({
  pdi,
  onCancel,
  onSubmit,
  projects,
  defaultValues = _defaultValues,
  viewMode,
}) => {
  const ComplienceDateField = useDateField<FormChallengeValues>()

  const getFixInitialValues = (): Partial<FormChallengeValues> => {
    const { idProject } = defaultValues
    const isInRangeProject = projects.some(p => p._id === idProject)
    return {
      ...defaultValues,
      idProject: isInRangeProject ? idProject : '',
      idPdi: pdi._id,
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormChallengeValues>({
    mode: 'onChange',
    resolver: yupResolver(formChallengeSchema),
    defaultValues: getFixInitialValues(),
  })

  useEffect(() => {
    const fixDefaultValues = getFixInitialValues()
    reset(fixDefaultValues)
  }, [defaultValues])

  const submit: SubmitHandler<FormChallengeValues> = formValues => onSubmit(formValues, () => reset(_defaultValues))

  return (
    <>
      {/*******************************
                CONTENEDOR FORMULARIO RETOS
            *******************************/}
      <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
        {/**************************
                    NOMBRE DEL PROYECTO
                ****************************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Nombre del proyecto</Typography>

          <Controller
            name="idProject"
            control={control}
            render={({ field }) => <SelectPagination {...field} propValue="_id" propText="title" items={projects} />}
          />

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.idProject?.message} />
        </Box>

        {/****************
                    DESCRIPCION
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Descripción</Typography>

          {/***********
                        INPUT
                    ***********/}
          <Box sx={formStyles.inputDescription}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputBase {...field} multiline maxRows={5} sx={formStyles.inputBaseDescription} />
              )}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.description?.message} />
        </Box>

        {/*************************
                    FECHA DE CUMPLIMIENTO
                **************************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Fecha de cumplimiento</Typography>

          {/****************
                        CALENDARIO
                    *****************/}
          <ComplienceDateField name="complienceDate" control={{ ...control }} translateCalendar="dateFulfillment" />

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.complienceDate?.message} />
        </Box>
        {/************
                    ESTADO
                ************/}
        {viewMode === 'update' && (
          <>
            <Box sx={formStyles.containerState}>
              <Typography sx={formStyles.title}>Estado</Typography>

              {/***********************
                                CHECKBOX DE ESTADO
                            ***********************/}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === true ? true : false}
                          onChange={({ target: { checked } }) => onChange(checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Activo"
                  labelPlacement="start"
                />

                <FormControlLabel
                  className="inactivo"
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === false ? true : false}
                          onChange={({ target: { checked } }) => onChange(!checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Inactivo"
                  labelPlacement="start"
                />
              </FormGroup>
            </Box>

            {/*********************
                            MENSAJE DE ERROR
                        *********************/}
            <ErrorField message={errors.state?.message} />
          </>
        )}

        {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
        <Box sx={formStyles.divButton}>
          {/**********************************
                        BOTONES ACTUALIZAR/DESACTIVAR
                    ***********************************/}
          <div>
            {/********************
                            BOTON ACTUALIZAR
                        *********************/}
            <Button disableTouchRipple disabled={!isValid} sx={formStyles.buttonSave} type="submit">
              Guardar
            </Button>

            {/********************
                            BOTON DESACTIVAR
                        *********************/}
            <Button disableTouchRipple onClick={onCancel} sx={formStyles.buttonCancel}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default FormChallenge
