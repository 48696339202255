import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys = "container" | "pagesList";

const tableItemsPdiStyles: StyleComponent<Keys> = {
    container: {
        height: "665px",
        overflow: "hidden",
        paddingTop: "10px",
        paddingBottom: "25px",
        display: "grid",
        // gridTemplateColumns :'repeat(auto-fit, minmax(370px, 1fr))',
        gridTemplateColumns: "repeat(auto-fit, minmax(33%, 1fr))",
        justifyItems: "center",
        transition: "1s 0.5s",
        alignContent: "start",

        "@media (max-width: 1330px)": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
        },
    },

    pagesList: {
        backgroundColor: "inherit",
        margin: "50px auto",
    },
};

export default tableItemsPdiStyles;
