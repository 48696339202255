import { FunctionComponent } from 'react'
import { useTheme } from '@mui/styles'

interface IconCalendar30Props {
  width?: number | string
  height?: number | string
  color?: string
}

const IconCalendar30: FunctionComponent<IconCalendar30Props> = ({ width = 40, height = 40, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8198 1.97104C14.8198 0.882465 13.9945 0 12.9763 0C11.9582 0 11.1328 0.882465 11.1328 1.97104V3.12991H3.80958C2.79921 3.12991 1.83023 3.52833 1.1158 4.2375C0.401365 4.94668 0 5.90853 0 6.91146V36.2184C0 37.2214 0.401364 38.1832 1.1158 38.8924C1.83024 39.6016 2.79922 40 3.80958 40H36.191C37.2013 40 38.1703 39.6016 38.8848 38.8924C39.5992 38.1832 40.0006 37.2214 40.0006 36.2184V6.91146C40.0006 5.90853 39.5992 4.94668 38.8848 4.2375C38.1703 3.52833 37.2013 3.12991 36.191 3.12991H29.5679V1.97104C29.5679 0.882465 28.7425 0 27.7243 0C26.7062 0 25.8808 0.882465 25.8808 1.97104V3.12991H14.8198V1.97104ZM11.1328 6.91146V9.85521C11.1328 10.9438 11.9582 11.8262 12.9763 11.8262C13.9945 11.8262 14.8198 10.9438 14.8198 9.85521V6.91146L25.8808 6.91146V9.85521C25.8808 10.9438 26.7062 11.8262 27.7243 11.8262C28.7425 11.8262 29.5679 10.9438 29.5679 9.85521V6.91146H36.191V13.5292H3.80958V6.91146H11.1328ZM3.80958 36.2184V17.3107H36.191V36.2184H3.80958Z"
        fill={color ?? secondary}
      />
      <path
        d="M12.6633 23.8499C11.8385 23.8499 11.1401 23.145 11.424 22.3706C11.6231 21.8276 11.9303 21.3712 12.3455 21.0013C13.0949 20.3338 14.1111 20 15.3941 20C16.2457 20 16.9724 20.1496 17.5742 20.4489C18.1873 20.7481 18.6471 21.1567 18.9537 21.6746C19.2716 22.1926 19.4306 22.7738 19.4306 23.4183C19.4306 24.178 19.2432 24.7995 18.8685 25.2829C18.4939 25.7548 18.0567 26.077 17.5571 26.2497V26.3187C18.2043 26.5374 18.7153 26.9 19.09 27.4064C19.4646 27.9128 19.652 28.5631 19.652 29.3572C19.652 30.0708 19.4874 30.7039 19.1581 31.2563C18.8402 31.7973 18.369 32.2231 17.7445 32.5339C17.1313 32.8446 16.399 33 15.5474 33C14.1849 33 13.0949 32.6605 12.2774 31.9814C11.7824 31.5644 11.4308 31.0173 11.2226 30.34C10.9892 29.5806 11.6584 28.9084 12.4529 28.9084H12.5172C13.2454 28.9084 13.7627 29.5784 14.253 30.1169C14.5255 30.4046 14.9229 30.5485 15.4452 30.5485C15.888 30.5485 16.2287 30.4219 16.4671 30.1687C16.7169 29.9039 16.8418 29.5587 16.8418 29.1328C16.8418 28.5803 16.6658 28.1833 16.3138 27.9416C15.9732 27.6884 15.4225 27.5618 14.6618 27.5618C14.3608 27.5618 14.1168 27.3177 14.1168 27.0167V25.707C14.1168 25.406 14.3608 25.162 14.6618 25.162C15.2408 25.162 15.7064 25.0642 16.0584 24.8685C16.4217 24.6614 16.6034 24.2988 16.6034 23.7809C16.6034 23.3665 16.4898 23.0443 16.2627 22.8141C16.0356 22.5839 15.7234 22.4688 15.326 22.4688C14.8945 22.4688 14.5709 22.6012 14.3552 22.8659C13.9881 23.3413 13.5365 23.8499 12.9358 23.8499H12.6633Z"
        fill={color ?? secondary}
      />
      <path
        d="M20.4283 26.4223C20.4283 24.4427 20.803 22.8831 21.5524 21.7437C22.3131 20.6042 23.5337 20.0345 25.2141 20.0345C26.8946 20.0345 28.1095 20.6042 28.8589 21.7437C29.6196 22.8831 30 24.4427 30 26.4223C30 28.425 29.6196 29.996 28.8589 31.1355C28.1095 32.2749 26.8946 32.8446 25.2141 32.8446C23.5337 32.8446 22.3131 32.2749 21.5524 31.1355C20.803 29.996 20.4283 28.425 20.4283 26.4223ZM27.1387 26.4223C27.1387 25.2599 27.0138 24.3679 26.764 23.7463C26.5142 23.1133 25.9976 22.7968 25.2141 22.7968C24.4307 22.7968 23.9141 23.1133 23.6643 23.7463C23.4145 24.3679 23.2896 25.2599 23.2896 26.4223C23.2896 27.205 23.335 27.8552 23.4258 28.3732C23.5167 28.8796 23.6983 29.2939 23.9709 29.6162C24.2547 29.927 24.6691 30.0823 25.2141 30.0823C25.7592 30.0823 26.1679 29.927 26.4404 29.6162C26.7243 29.2939 26.9116 28.8796 27.0025 28.3732C27.0933 27.8552 27.1387 27.205 27.1387 26.4223Z"
        fill={color ?? secondary}
      />
    </svg>
  )
}

export default IconCalendar30
