import React from "react";
import { Box, Typography, Button } from "@mui/material";

// IMPORTADOS
import familyStyles from "./Family.styles";
import IconFactors from "../../../../components/icons/IconFactors";
import IconCondition from "../../../../components/icons/IconCondition";
import CustomTitleButtonAdd from "../../../../components/CustomTitleButtonAdd";
import { useNavigate } from "react-router-dom";

const Family = () => {

    const navigate = useNavigate()

    const goToFactors = () => navigate("./factors")

    const goToConditions = () => navigate("./conditions")

    return (
        <Box sx={familyStyles.container}>
            <CustomTitleButtonAdd title="Familias" display="none" />

            {/**************
                CONTENIDO
            **************/}
            <Box sx={familyStyles.content}>
                {/***********************
                    CONTENIDO IZQUIERDA
                ***********************/}
                <Button
                    disableTouchRipple
                    onClick={goToFactors}
                    sx={familyStyles.contentLeft}
                >
                    <Box>
                        <IconFactors />
                        <Typography>Factores</Typography>
                    </Box>
                </Button>

                {/**********************
                    CONTENIDO DERECHA
                **********************/}
                <Button
                    disableTouchRipple
                    onClick={goToConditions}
                    sx={familyStyles.contentRight}
                >
                    <Box>
                        <IconCondition />
                        <Typography>Condiciones</Typography>
                    </Box>
                </Button>
            </Box>
        </Box>
    );
};

export default Family;
