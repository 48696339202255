/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Fade, Typography } from '@mui/material'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import formStyles from '../../../forms/form.styles'
import FormGoalPda from '../../../forms/pda/formGoalPda/FormGoalPda'
import { FormGoalPdaFields, GoalPdaModel } from '../../../../interfaces/pda/goal-pda'
import TableGoalsPda from '../../../tables/pda/tableGoalsPda/TableGoalsPda'
import IconAdd from '../../../icons/IconAdd'
import { PdaContext } from '../../../context/PdaProvider'

// interface TabPanelGoalsPdaProps {
//   measureUnits: MeasureUnit[]
//   goalsPdaIndicators: GoalPdaIndicator[]
//   goalsPda: GoalPdaModel[]
//   indicators: IndicatorModel[]
//   idPa: string
// }

type ViewMode = 'form' | 'table' | null

const _formInitialValues: Partial<FormGoalPdaFields> = {
  title: '',
}
// Pasar el setGoalPaList
const TabPanelGoalsPda: FunctionComponent = () => {
  const [view, setView] = useState<ViewMode>(null)
  const [updateGoalPa, setUpdateGoalPa] = useState<GoalPdaModel | null>(null)

  const [formInitialValues, setFormInitialValues] = useState<Partial<FormGoalPdaFields>>(_formInitialValues)

  const pdaContext = useContext(PdaContext)

  const handleView = (v: ViewMode) => {
    if (v === 'table') setUpdateGoalPa(null)
    setView(v)
  }
  // Controla si se muestra la tabla o el formulario inicialmente
  useEffect(() => {
    if (!pdaContext || !pdaContext.isActiveTabPanelGoalPda) return
    if (pdaContext.goalPaList?.items.length) return setView('table')
    setView('form')
  }, [])

  const disable = ({ _id }: GoalPdaModel) => {
    pdaContext?.deleteGoalPa({ _id })
  }
  
  useEffect(() => {
    if (!updateGoalPa) return setFormInitialValues(_formInitialValues)
    // const { idMeasureUnit, state, title } = updateGoalPa
    setFormInitialValues(updateGoalPa)
    handleView('form')
  }, [updateGoalPa])

  if (!view || !pdaContext || !pdaContext.isActiveTabPanelGoalPda) return null

  return (
    <>
      {view === 'form' && (
        <Fade in={view === 'form'}>
          <Box>
            <FormGoalPda
              disabledButtonCancel={!Boolean(pdaContext.goalPaList?.items.length)}
              defaultValues={formInitialValues}
              onCancel={() => handleView('table')}
              idGoalPda={updateGoalPa?._id}
            />
          </Box>
        </Fade>
      )}

      {/********************************
        CONTENEDOR TABLA METAS PDA
      *********************************/}
      {view === 'table' && (
        <Fade in={view === 'table'}>
          <Box>
            <Box sx={formStyles.container}>
              {/**********/}
              {/* TITULO */}
              {/**********/}
              <Box sx={formStyles.buttonTitle}>
                {/*******************/}
                {/* TITULO META PDA */}
                {/*******************/}
                <Typography sx={{ fontSize: '25px' }}>Metas</Typography>

                <Box>
                  {/*******************************/}
                  {/* BOTON FILTRAR POR PROYECTOS */}
                  {/*******************************/}
                  {/* <FilterPdi
                  getOptionLabel={option => option.title}
                  id="filter-goal-by-project"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  labelInput="Plan de acción"
                  noOptionsText="Sin resultados"
                  options={projects}
                  onChange={(e, value) => changeFilterOption(value)}
                  placeholder="Buscar proyecto..."
                  value={filterOption}
                  minWidth={176}
                /> */}
                  <Button disableTouchRipple onClick={() => handleView('form')}>
                    <IconAdd />
                    <Typography>Crear nueva meta</Typography>
                  </Button>
                </Box>
              </Box>

              <TableGoalsPda
                goalsPda={pdaContext.goalPaList?.items ?? []}
                onUpdate={g => setUpdateGoalPa(g)}
                onDelete={disable}
              />
            </Box>
          </Box>
        </Fade>
      )}
    </>
  )
}

export default TabPanelGoalsPda
