
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { LoginData } from "../interfaces/auth";
import { CustomResponse } from "../interfaces/custom-response";
import { User } from "../interfaces/user";
import { startLogin, logout, setError } from "../redux/features/auth/authSlice";
import { RootState, useAppDispatch } from "../redux/Store";
import authService from "../services/auth.service";
import useMessages from "./useMessages";

type OnLogoutProps = {
    expired: boolean
}

export type ResponseFetchLogin = Partial<CustomResponse> & {
    data: {
        user: User,
        token: string
    },
}

const useAuth = () => {
    
    const dispatch = useAppDispatch()

    const { isLogged, token : _token, user, isLoading, errorMessage } = useSelector((state: RootState) => state.auth)


    const { getMessage } = useMessages()

    const fetchLogin = async (loginData: LoginData) => {
        try {
            const response = await authService.login(loginData)
            const { data, message, msg } = await (response.json() as Promise<ResponseFetchLogin>)
            if (response.status !== 200) {
                const errorMessage = message ?? msg ?? getMessage(response.status)
                throw new Error(errorMessage)
            }
            if (!data) throw new Error('Sesión no válida')
            const { user, token } = data
            return { user, token }
        } catch (error) {
            throw error
        }
    }

    const userData: any = useMemo(() => user, [user])

    const token = useMemo(() => _token, [_token])

    const isAuth = useMemo(() => {
        return (isLogged && user && token) ? true : false
    }, [isLogged, user, token])

    const onLogin = useCallback((formValue: LoginData) => {
        dispatch(startLogin(fetchLogin(formValue)))
    }, [])

    const onLogout = useCallback(({ expired }: OnLogoutProps | undefined = { expired: false }): void => {
        if (expired) dispatch(setError('La sesión actual ha expirado, por favor, inicia sesión nuevamente.'))
        dispatch(logout())
    }, [dispatch])

    return {
        isAuth,
        isLoading,
        errorMessage,
        userData,
        token,
        onLogin,
        onLogout
    }
}

export default useAuth;