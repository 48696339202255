import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// IMPORTADOS
import processStyles from "../../process/process.styles";
import IconRelation from "../../../../../components/icons/IconRelation";
import IconAdd from "../../../../../components/icons/IconAdd";
import ButtonSearch from "../../../../../components/buttons/ButtonSearch";
import TableFactors from "../../../../../components/tables/family/tableFactors/TableFactors";
import AlertDialog from "../../../../../components/Dialogs/customDialog/CustomDialog";

const Factors = () => {

    const navigate = useNavigate()

    return (
        <Box>
            <Box>
                {/**********************************
                    CONTENEDOR TITULO Y BOTON CREAR
                ***********************************/}
                <Box sx={processStyles.title}>
                    {/******************
                        TITULO FACTORES
                    *******************/}
                    <Typography>Factores</Typography>

                    {/**********
                        BOTONES
                    ************/}
                    <Box className="buttons">
                        {/***************************
                            BOTON CREAR RELACIONES
                        ****************************/}
                        <Button
                            className="buttonCreate"
                            onClick = {() => navigate('./relations')}
                            disableTouchRipple
                        >
                            <IconRelation />

                            <Typography>Crear relaciones</Typography>
                        </Button>

                        {/***************************
                            BOTON CREAR PROCESO
                        ****************************/}
                        <Button
                            className="buttonCreate"
                            onClick = {() => navigate('./create')}
                            disableTouchRipple
                            sx={{ minWidth: "200px !important" }}
                        >
                            <IconAdd />

                            <Typography>Crear nuevo factor</Typography>
                        </Button>

                        {/************
                            BUSCADOR 
                        *************/}
                        <ButtonSearch />
                    </Box>
                </Box>

                {/*******************
                    TABLA FACTORES
                ********************/}
                <TableFactors/>
            </Box>
        </Box>
    );
};

export default Factors;
