import { FunctionComponent } from "react";

interface IconClockProps {
    height?: string | number;
    width?: string | number;
    color?: string;
}

const IconClock: FunctionComponent<IconClockProps> = ({
    height = 40,
    width = 40,
    color = "#7282B8",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.98 0C8.94 0 0 8.96 0 20C0 31.04 8.94 40 19.98 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 19.98 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36ZM19.56 10H19.44C18.64 10 18 10.64 18 11.44V20.88C18 21.58 18.36 22.24 18.98 22.6L27.28 27.58C27.96 27.98 28.84 27.78 29.24 27.1C29.3404 26.9381 29.4072 26.7576 29.4364 26.5693C29.4656 26.381 29.4567 26.1887 29.41 26.0039C29.3633 25.8192 29.28 25.6457 29.1649 25.4938C29.0498 25.342 28.9053 25.2148 28.74 25.12L21 20.52V11.44C21 10.64 20.36 10 19.56 10Z"
                fill={color}
            />
        </svg>
    );
};

export default IconClock;
