import { FunctionComponent } from "react";

interface IconProgramsProps {
    width?: number | string;
    height?: number | string;
}

const IconPrograms: FunctionComponent<IconProgramsProps> = ({
    width = 46,
    height = 40,
}) => {
    return (
        <svg
            width="46"
            height="40"
            viewBox="0 0 46 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.76 15.0304C45.761 14.4077 45.5974 13.7958 45.286 13.257C44.9746 12.7183 44.5264 12.2718 43.987 11.963L24.7132 0.496085C24.1577 0.171195 23.5262 0 22.883 0C22.2399 0 21.6084 0.171195 21.0529 0.496085L1.75047 11.963C1.21707 12.2803 0.775198 12.7312 0.468291 13.2715C0.161384 13.8118 0 14.4229 0 15.0447C0 15.6665 0.161384 16.2775 0.468291 16.8179C0.775198 17.3582 1.21707 17.8091 1.75047 18.1264L7.24093 21.3371V29.3926C7.24424 30.0222 7.40906 30.6403 7.71957 31.1875C8.03007 31.7348 8.4758 32.1927 9.0139 32.5173L21.1673 39.5408C21.7099 39.842 22.32 40 22.9402 40C23.5605 40 24.1706 39.842 24.7132 39.5408L36.8666 32.5173C37.4047 32.1927 37.8504 31.7348 38.1609 31.1875C38.4714 30.6403 38.6362 30.0222 38.6395 29.3926V21.2511L42.1855 19.1584V25.1686C42.1855 26.1557 42.9857 26.9559 43.9727 26.9559C44.9598 26.9559 45.76 26.1557 45.76 25.1686V15.0304ZM35.0936 29.364L22.883 36.4161L10.8155 29.3926V23.4585L21.0529 29.5646C21.6103 29.8839 22.2411 30.0518 22.883 30.0518C23.525 30.0518 24.1558 29.8839 24.7132 29.5646L35.065 23.3725L35.0936 29.364ZM22.883 26.4686L3.58062 15.0017L22.883 3.50614L42.1855 14.973L22.883 26.4686Z"
                fill="#7282B8"
            />
        </svg>
    );
};

export default IconPrograms;
