import { StyleComponent } from '../../../../../interfaces/styleComponent'

type Keys = 'container' | 'contentTitle'

const infoVerifyEventAssignedStyles: StyleComponent<Keys> = {
  // CONTENEDOR
  container: {
    marginX: '100px',
    marginY: '93px',
  },

  contentTitle: {
    // backgroundColor: 'red',
    display: 'flex',
    alignItems: 'center',

    '>p': {
      color: '#1F398F',
      // fontFamily: Poppins;
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: 600,
    },

    '>button': {
      backgroundColor: 'rgba(31, 57, 143, 0.10)',
      width: '62px',
      height: '23px',
      borderRadius: '200px',

      color: '#1F398F',
      textAlign: 'center',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      textTransform: 'capitalize',
      marginLeft: '20px',

      ':hover': {
        backgroundColor: 'rgba(31, 57, 143, 0.10)',
      },
    },
  },
}

export default infoVerifyEventAssignedStyles
