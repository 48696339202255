import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'
import containerRelationsStyles from './containerRelations.styles'
import CustomTitleButtonAdd from '../../CustomTitleButtonAdd'

interface ContainerRelationsProps {
  children: ReactNode
  showTitle?: boolean
}

type ContainerRelationsLeftProps = ContainerRelationsProps

type ContainerRightProps = ContainerRelationsProps

type ContainerRelationsType = FC<ContainerRelationsProps> & {
  Left: FC<ContainerRelationsLeftProps>
  Right: FC<ContainerRightProps>
  Divisor: FC
}

const ContainerRelations: ContainerRelationsType = ({ children, showTitle = true }) => {
  return (
    <Box sx={containerRelationsStyles.container}>
      {showTitle && <CustomTitleButtonAdd title="Relaciones" display={'none'} />}
      <Box sx={containerRelationsStyles.content}>{children}</Box>
    </Box>
  )
}

ContainerRelations.Left = ({ children }) => {
  return <Box sx={containerRelationsStyles.contentLeft}>{children}</Box>
}

ContainerRelations.Right = ({ children }) => {
  return <Box sx={containerRelationsStyles.contentRight}>{children}</Box>
}

ContainerRelations.Divisor = () => {
  return <Box sx={containerRelationsStyles.divisor} />
}

export default ContainerRelations
