import { StyleComponent } from "../../../../../interfaces/styleComponent";

type Keys = "container";

const indicadoresStyles: StyleComponent<Keys> = {
    container: {
        marginBottom: "40px",
        ">ul": {
            minHeight: "646px",

            ".container-spinner": {
                justifyContent: "center",
            },

            ">li": {
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                ">div": {
                    backgroundColor: "transparent !important",
                    paddingLeft: "0px",
                    ":hover": {
                        backgroundColor: "transparent",
                        ">div": {
                            ">span": {
                                color: "#1F398F",
                            },
                        },
                    },

                    // CHECKED
                    ">div": {
                        minWidth: "37px",
                        ">span, >input": {
                            fontSize: "20px",
                            color: "#000000 !important",
                            marginLeft: "0px",
                            padding: "0px",
                        },

                        // SELECCIONADO
                        ">.Mui-checked": {
                            backgroundColor: "#7282B8",
                            width: "14px",
                            height: "14px",
                        },
                    },

                    // INPUT PORCENTAJE
                    ">.MuiOutlinedInput-root": {
                        backgroundColor: "#1F398F1A",
                        width: "66px",
                        height: "30px",
                        paddingX: "8px",
                        borderRadius: "20px",

                        ">input, >div>p": {
                            fontSize: "15px",
                            color: "#7282B8",
                        },

                        ">fieldset": {
                            display: "none",
                        },
                    },

                    // BOTON EDITAR
                    ">button": {
                        marginLeft: "7px",
                    },
                },
            },
        },

        // PAGINACION Y BOTON
        ">div": {
            display: "flex",
            flexDirection: "column",

            // PAGINACION
            ">nav": {
                width: "100%",
                height: "38px",
                ">ul": {
                    justifyContent: "space-between",
                    ">li": {
                        ">.Mui-disabled": {
                            visibility: "hidden",
                        },

                        // BOTON DE NUMEROS
                        ">.MuiPaginationItem-page": {
                            display: "none",
                        },

                        // BOTON DE FLECHAS
                        ">.MuiPaginationItem-previousNext": {
                            backgroundColor: "transparent",
                            height: "100%",
                            ">svg": {
                                fontSize: "2.5rem !important",
                                ">path": {
                                    color: "#7282B8",
                                },
                            },
                        },
                    },
                },
            },

            // BOTON
            ">button": {
                backgroundColor: "#7282B8",
                marginTop: "-38px",
                width: "144px",
                height: "38px",
                marginX: "auto",
                borderRadius: "20px",
                color: "#ffffff",
                fontSize: "15px",

                ":hover": {
                    backgroundColor: "#7282B8",
                },
            },
        },
    },
};

export default indicadoresStyles;
