import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import IconIndicators from '../../../components/icons/IconIndicators'

const indicadoresInfo = [
  {
    nombre: 'Eficacia en la atención Canal telefónico',
    valor: 95.789,
    url: '#',
  },
  {
    nombre: 'Disponibilidad de la plataforma tecnológica',
    valor: 99.932,
    url: '#',
  },
  {
    nombre: 'Efectividad atención servicios',
    valor: 100,
    url: '#',
  },
  {
    nombre: 'Nivel de Servicio Canal Presencial',
    valor: 100,
    url: '#',
  },
  {
    nombre: 'Oportunidad de PQRSD',
    valor: 100,
    url: '#',
  },
]

const Indicating = () => {
  {
    /*************************
      CONTENEDOR INDICADORES
    ***************************/
  }
  return (
    <Box>
      {/**********************
        TITULO INDICADORES
      ************************/}
      <Box className="tituloIndicadores">
        {/******************
          ICONO INDICADOR
        ******************/}
        <IconIndicators />

        <Typography>Indicadores</Typography>

        <Typography
          component="a"
          href="#"
          sx={{
            // backgroundColor: "pink",
            fontSize: '12px !important',
            fontWeight: '300 !important',
            lineHeight: '18px',
            fontStyle: 'normal',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          Ver Más...
        </Typography>
      </Box>

      {/*********************
        LISTA INDICADORES
      **********************/}
      <Box
        className="listaIndicadores"
        sx={{
          overflowY: indicadoresInfo.length > 5 ? 'scroll' : 'hidden',

          // RESPONSIVE
          '@media(max-width: 585px)': {
            overflowY: 'scroll',
          },
        }}
      >
        {indicadoresInfo.map(({ nombre, valor, url }, index) => (
          <List key={index + nombre}>
            <ListItem component="a" href={url} secondaryAction={<ListItemText>{valor}%</ListItemText>}>
              {valor !== 100 ? (
                <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.36602 10.5C6.98112 11.1667 6.01887 11.1667 5.63397 10.5L0.870834 2.25C0.485934 1.58333 0.96706 0.75 1.73686 0.75L11.2631 0.750001C12.0329 0.750001 12.5141 1.58333 12.1292 2.25L7.36602 10.5Z"
                    fill="#FE5E5E"
                  />
                </svg>
              ) : (
                <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.63398 0.499999C6.01888 -0.166667 6.98113 -0.166666 7.36603 0.500001L12.1292 8.75C12.5141 9.41667 12.0329 10.25 11.2631 10.25L1.73686 10.25C0.967058 10.25 0.485934 9.41666 0.870834 8.75L5.63398 0.499999Z"
                    fill="#6BFE5E"
                  />
                </svg>
              )}

              <ListItemText primary={nombre} />
            </ListItem>
          </List>
        ))}
      </Box>
    </Box>
  )
}

export default Indicating
