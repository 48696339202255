import React, { useState } from 'react'
import { Box, Button, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import IconLeave from '../../../components/icons/IconLeave'
import IconPerson from '../../../components/icons/IconPerson'
import InputSearch from '../../../components/InputSearch'
import { logout } from '../../../redux/features/auth/authSlice'
import headerStyles from './Header.styles'
import { Link } from 'react-router-dom'

const ContainerRightHeader = () => {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const dispatch = useDispatch()

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleLogout = () => {
    handleCloseUserMenu()
    dispatch(logout())
  }

  return (
    <Box
      sx={{
        ...headerStyles.containerRight,
        '@media(max-width: 1230px)': {
          display: 'none',
        },
      }}
    >
      <Box>
        {/**************************
          CONTENEDOR DE BOTONES
        **************************/}
        <Box sx={headerStyles.containerButton}>
          {/******************
            BOTON PLANEAR
          *******************/}
          <div>
            <Link to="plan">
              <Typography>Planear</Typography>
            </Link>
          </div>

          {/******************
            BOTON MEDIR
          *******************/}
          <div>
            <Link to="do">
              <Typography>Hacer</Typography>
            </Link>
          </div>

          {/******************
            BOTON MEJORAR
          *******************/}
          <div>
            <Link to="verify">
              <Typography>Verificar</Typography>
            </Link>
          </div>

          {/******************
            BOTON ACTUAR
          *******************/}
          <div>
            <Link to="#">
              <Typography>Actuar</Typography>
            </Link>
          </div>
        </Box>
      </Box>

      {/************************
        CONTENEDOR BUSCADOR
      *************************/}
      <InputSearch />

      {/********************************
        MENÚ USUARIO/PROFILE/LOGOUT
      *********************************/}
      <Box sx={headerStyles.containerMenu}>
        <Tooltip title="Perfíl">
          <IconButton disableTouchRipple onClick={handleOpenUserMenu}>
            <IconPerson />
          </IconButton>
        </Tooltip>

        {/***************
          MENÚ DE USUARIO
        ****************/}
        <Menu
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
          sx={headerStyles.menu}
        >
          <Box>
            {/***********
              PERFÍL
            ************/}
            <MenuItem
              onClick={handleCloseUserMenu}
              sx={{
                '&': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Typography
                component="a"
                href="/profile"
                textAlign={'center'}
                sx={{
                  display: 'block',
                  width: '100%',
                }}
              >
                Perfíl
              </Typography>
            </MenuItem>

            {/***********
              DIVISOR
            ************/}
            <Divider
              sx={{
                marginTop: '0px !important',
                marginBottom: '0px !important',
              }}
            />

            {/*********
              LOGOUT
            **********/}
            <MenuItem
              onClick={handleLogout}
              sx={{
                '&': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Typography
                component="a"
                textAlign={'center'}
                sx={{
                  display: 'block',
                  width: '100%',
                }}
              >
                <IconLeave width={9} height={12} />
                Salir
              </Typography>
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    </Box>
  )
}

export default ContainerRightHeader
