import { FunctionComponent } from "react";

interface IconDownloadProp {
    width?: number | string;
    height?: number | string;
}

const IconDownload: FunctionComponent<IconDownloadProp> = ({
    width = 21,
    height = 26,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 26"
            // fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.279516 13.406C0.761029 12.7319 1.69785 12.5757 2.37197 13.0573L10.4446 18.8234L18.5171 13.0573C19.1913 12.5757 20.1281 12.7319 20.6096 13.406C21.0911 14.0801 20.935 15.0169 20.2609 15.4985L11.3164 21.8873C10.7949 22.2599 10.0942 22.2599 9.5727 21.8873L0.628259 15.4985C-0.0458599 15.0169 -0.201998 14.0801 0.279516 13.406Z"
                fill="#1F398F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4446 0C11.273 0 11.9446 0.671573 11.9446 1.5V19.3889C11.9446 20.2173 11.273 20.8889 10.4446 20.8889C9.61615 20.8889 8.94458 20.2173 8.94458 19.3889V1.5C8.94458 0.671573 9.61615 0 10.4446 0Z"
                fill="#1F398F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.889 24.5C20.889 25.3284 20.2175 26 19.389 26L1.50015 26C0.67172 26 0.00014866 25.3284 0.0001487 24.5C0.000148741 23.6716 0.671721 23 1.50015 23L19.389 23C20.2175 23 20.889 23.6716 20.889 24.5Z"
                fill="#1F398F"
            />
        </svg>
    );
};

export default IconDownload;
