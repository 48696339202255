import { FunctionComponent } from "react";
import { Box, Typography, Link } from "@mui/material";

// IMPORTADOS
import logoAplanner from "../../../images/logos/logoAplanner.png";
import footerStyles from "./footer.styles";

/***********
    FOOTER
************/
interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => {
    return (
        // CONTENEDOR FOOTER
        <Box sx={footerStyles.container}>
            {/***********
                GRILLA
            ************/}
            <Box sx={footerStyles.grid}>
                <Link href="#">Sobre</Link>
                <Link href="#">Terminos y Condiciones</Link>
                <Link href="/login" id="logoFooter">
                    <img
                        src={logoAplanner}
                        style={{
                            width: "100%",
                            minWidth: 100,
                            maxWidth: 200,
                        }}
                    />
                </Link>
                <Link href="#">Privacidad</Link>
                <Link href="#">Productos Frecuentes</Link>
            </Box>

            {/*********************
                DERECHOS DE AUTOR
            **********************/}
            <Typography sx={footerStyles.parrafo} variant="h5">
                Todos los derechos reservados © 2022. Aplanner
            </Typography>
        </Box>
    );
};

export default Footer;
