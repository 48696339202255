import React from 'react'
import { Box, InputBase, Typography } from '@mui/material'
// IMPORTADOS
import verifyStyles from '../../Verify.styles'
import formStyles from '../../../../../components/forms/form.styles'
import InputDatePicker from '../../../../../components/InputDatePicker'

const TabInfoEvent = ({ event, setEvent, events, setEvents }: any) => {
  const handleEvents = (data: any) => {
    if (events.length > 0) {
      const copyEvents = events.map((item: any) => {
        return { ...item, ...data }
      })
      setEvents(copyEvents)
    }
  }

  const handleInputChange = (e: any) => {
    setEvent({ ...event, [e.target.name]: e.target.value })
    handleEvents({ ...event, [e.target.name]: e.target.value })
  }

  const handleInputDateChange = ({ date, name }: any) => {
    setEvent({ ...event, [name]: date })
    handleEvents({ ...event, [name]: date })
  }

  return (
    <Box>
      {/**********/}
      {/* NOMBRE */}
      {/**********/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Nombre</Typography>

        {/****************/}
        {/* INPUT NOMBRE */}
        {/****************/}
        <Box sx={formStyles.input}>
          <InputBase
            sx={{ ...formStyles.inputBase, fontSize: '15px !important' }}
            name="name"
            onChange={handleInputChange}
            value={event.name}
            inputProps={{
              maxlength: 80,
            }}
          />
        </Box>
      </Box>

      {/*****************/}
      {/* JUSTIFICACION */}
      {/*****************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Justificación</Typography>

        {/**************/}
        {/* INPUT INFO */}
        {/**************/}
        <Box sx={formStyles.inputDescription}>
          <InputBase
            multiline
            maxRows={6}
            sx={{ ...formStyles.inputBaseDescription, fontSize: '15px !important' }}
            name="justification"
            value={event.justification}
            onChange={handleInputChange}
            inputProps={{
              maxlength: 500,
            }}
          />
        </Box>
      </Box>

      {/***************/}
      {/* CALENDARIOS */}
      {/***************/}
      <Box sx={{ ...verifyStyles.contentDate, justifyContent: 'center' }}>
        {/**********************/}
        {/* CALENDARIO INICIAL */}
        {/**********************/}
        <InputDatePicker label="Fecha inicial" onChange={handleInputDateChange} inputName="startDate" />

        {/********************/}
        {/* CALENDARIO FINAL */}
        {/********************/}
        <InputDatePicker label="Fecha final" onChange={handleInputDateChange} inputName="finalDate" />
      </Box>
    </Box>
  )
}

export default TabInfoEvent
