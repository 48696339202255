import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Typography,
} from '@mui/material'
import { useState } from 'react'

// IMPORTADOS
import Spinner from './utilities/Spinner'
import ButtonSearch from './buttons/ButtonSearch'
import IconArrowBottom from './icons/IconArrowDown'
import verifyStyles from '../pages/private/verify/Verify.styles'
import relationsStyles from '../pages/private/plan/process/relations/Relations.styles'
import indicadoresStyles from '../pages/private/plan/process/relations/IndicatingRelations.styles'
import customSelectListStyles from './CustomSelectList.styles'

interface OptionsProps {
  label: string
  value: any
}

interface CustomDialogTitleProps {
  api: OptionsProps[]
  name?: string
  onClose?: () => void
  onChange?: Function
  titleSelect?: string
  value?: Boolean
}

function CustomSelectList(props: CustomDialogTitleProps) {
  const {
    api,
    name,
    titleSelect = 'Titulo del selector',
    onClose,
    onChange,
    value = '',
    ...other
  } = props

  const [loading /* setLoading */] = useState<boolean>(false)
  // const [pageList, setPageList] = useState<any>([])
  const [open, setOpen] = useState(false)

  // ABRIR DIALOG
  const handleClickOpen = () => {
    setOpen(true)
  }
  // CERRAR DIALOG
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {/***************************/}
      {/* BOTON SELECCIONAR LISTA */}
      {/***************************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>{titleSelect}</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Box sx={verifyStyles.selectButton}>
          <Typography>{api.find((item: any) => item.value === value)?.label}</Typography>

          {/**********************/}
          {/* BOTON SELECT MODAL */}
          {/**********************/}
          <Button disableTouchRipple onClick={handleClickOpen}>
            <IconArrowBottom width={16} height={14} />
          </Button>
        </Box>
      </Box>

      {/*********************/}
      {/* COMPONENTE DIALOG */}
      {/*********************/}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="dialog aqui"
        sx={customSelectListStyles.customDialog}
      >
        <DialogContent {...other} className="contenidoDialog">
          {/* AQUI DENTRO VA CHILDREN */}

          {/*************************/}
          {/* TITULO Y BOTON BUSCAR */}
          {/*************************/}
          <Box className="titleButtonSearch" sx={relationsStyles.titleButtonSearch}>
            {/**********/}
            {/* TITULO */}
            {/**********/}
            <Typography>Indicadores</Typography>

            {/****************/}
            {/* BOTON BUSCAR */}
            {/****************/}
            <ButtonSearch />
          </Box>

          {/*************/}
          {/* CONTENIDO */}
          {/*************/}
          <DialogContent className="listaDialog">
            <Box sx={{ ...indicadoresStyles.container, marginBottom: '0px !important' }} className="aqui">
              {/*********************/}
              {/* LISTA INDICADORES */}
              {/*********************/}
              {loading ? (
                <List>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                    }}
                  >
                    <Spinner />
                  </Box>
                </List>
              ) : (
                <List
                  sx={{
                    minHeight: 'fit-content !important',
                    height: '420px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '12px',
                      size: '10px',
                      '&-track': {
                        backgroundColor: theme => theme.palette.secondary.main,
                        borderRadius: '10px',
                        border: '5px solid #ffffff',
                      },
                      '&-thumb': {
                        backgroundColor: '#7282B8',
                        borderRadius: '20px',
                        width: '10px',
                      },
                    },
                  }}
                >
                  {api.map((item: any) => {
                    return (
                      <ListItem>
                        <ListItemButton disableTouchRipple disableRipple dense>
                          {/************/}
                          {/* CHECKBOX */}
                          {/************/}
                          <ListItemIcon>
                            <Checkbox
                              disableRipple
                              name={name}
                              checked={item.value === value}
                              onChange={e => onChange && onChange(name, item.value)}
                            />
                          </ListItemIcon>

                          {/**********/}
                          {/* NOMBRE */}
                          {/**********/}
                          <ListItemText onClick={e => onChange && onChange(name, item.value)}>{item.label}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              )}

              {/********************************/}
              {/* BOTONES PAGINACION Y GUARDAR */}
              {/********************************/}
              <Box sx={customSelectListStyles.containerBtn} className="contenedorBtnPage">
                {/**************/}
                {/* PAGINACION */}
                {/**************/}
                <Pagination /* count={totalPages} page={page} onChange={changePage} */ />

                {/*****************/}
                {/* BOTON GUARDAR */}
                {/*****************/}
                <Button disableTouchRipple onClick={handleClose}>
                  Guardar
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CustomSelectList
