import React from 'react'
import { Box, Button } from '@mui/material'
import IconTrash from '../../../components/icons/IconTrash'
import useUsers from '../../../hooks/users/useUsers'
import confirmDeletestyles from './confirmDelete.styles'

interface ConfirmDeleteProps {
  setOpen: Function
  open: boolean
  id: string
  list?: Function
}

const ConfirmDelete = ({ setOpen, open, id, list }: ConfirmDeleteProps) => {
  const { fetchDeleteUser } = useUsers()

  const handleDelete = async () => {
    setOpen(!open)
    await fetchDeleteUser({ _id: id })
    if (list) {
      list()
    }
  }
  return (
    <>
      {/**************
        CONTENEDOR
      ***************/}
      <Box sx={confirmDeletestyles.container}>
        <Box>
          <Box sx={confirmDeletestyles.center}>
            <IconTrash color="#7282B8" width={68} height={68} />
          </Box>

          <p>¿Esta seguro que desea eliminar este usuario?</p>

          <Box sx={confirmDeletestyles.center}>
            <Button
              sx={{
                backgroundColor: 'rgb(31, 47, 143, 0.1)',
                marginRight: '5px',
              }}
              onClick={handleDelete}
            >
              SI
            </Button>

            <Button
              sx={{
                backgroundColor: theme => theme.palette.secondary.main,
                marginLeft: '5px',
              }}
              onClick={() => setOpen(!open)}
            >
              NO
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ConfirmDelete
