import * as yup from 'yup'
import { FormIndicatorValues } from '../../interfaces/pdi/indicator'
import { testMaxLength } from '../validations';

export const formIndicatorSchema: yup.SchemaOf<FormIndicatorValues> = yup.object({
    idType: yup.string()
        .required('El tipo es un campo requerido')
        .length(24, 'El tipo seleccionada no es válida'),
    idPdi: yup.string()
        .required('El tipo es un campo requerido')
        .length(24, 'El tipo seleccionada no es válida'),
    toleranceUpper: yup.string()
        .required('La tolerancia superior es un campo requerido')
        .test('maxLength', 'La tolerancia superior no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('La tolerancia superior no puede contener solo espacios en blanco'),
    toleranceLower: yup.string()
        .required('La tolerancia inferior es un campo requerido')
        .test('maxLength', 'La tolerancia inferior no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('La tolerancia inferior no puede contener solo espacios en blanco'),
    observation: yup.string()
        .required('La observación es un campo requerido')
        .test('maxLength', 'La observación no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('La observación no puede contener solo espacios en blanco'),
    idMeasureUnit: yup.string()
        .required('La unidad de medida es un campo requerido')
        .length(24, 'La unidad de medida seleccionada no es válida'),
    idMeasurementFrecuency: yup.string()
        .required('La frecuencia de medición es un campo requerido')
        .length(24, 'La frecuencia de medición seleccionada no es válida'),
    idTrend: yup.string()
        .required('La tendencia es un campo requerido')
        .length(24, 'La tendencia seleccionada no es válida'),
    formula: yup.string()
        .required('La formula es un campo requerido')
        .test('maxLength', 'La formula no puede contener mas de 100 caracteres', val => testMaxLength(val, 100))
        .trim('La formula no puede contener solo espacios en blanco'),
    idGoal: yup.string()
        .required('La meta es un campo requerido')
        .length(24, 'La meta seleccionada no es válida'),
    goal: yup.number()
        .typeError('La meta requiere un valor numerico.')
        .min(0, 'La meta requiere un valor minimo de 0')
        .required(),
    // percentageOfCompletion: yup.number()
    //     .typeError('El porcentaje de cumplimiento requiere un valor numerico')
    //     .min(0, 'El porcentaje de cumplimiento debe ser mayor o igual a 0')
    //     .max(100, 'El porcentaje de cumplimiento debe ser menor o igual a 100')
    //     .required('El porcentaje de cumplimiento es un campo requerido'),
    state: yup.boolean().required('El estado es un campo requerido').default(true),
    name: yup.string()
        .required('El nombre es un campo requerido')
        .trim('La formula no puede contener solo espacios en blanco'),
}).required()