import { Box, Button, Typography } from '@mui/material'
// IMPORTADOS
import subHeaderStyles from './subHeader.styles'
import { ButtonBack } from '../../../components/buttons/ButtonBack'
import { listPermissions } from '../../../routes/permissions'
import ValidateAction from '../../../routes/validateAction'
import { Link } from 'react-router-dom'
import IconLocation from '../../../components/icons/IconLocation'
import IconArrowRight from '../../../components/icons/IconArrowRight'
import { useSelector } from 'react-redux'

const SubHeader = () => {
  const { idBranchOffice } = useSelector((state: any) => state.auth?.user?.idProfile)
  const subHeader = [
    {
      url: '/companies',
      name: 'Empresa',
      permission: listPermissions.company.read,
    },
    {
      url: '/branchOffice',
      name: 'Sucursales',
      permission: listPermissions.branchOffice.read,
    },
    {
      url: '/areas',
      name: 'Áreas',
      permission: listPermissions.area.read,
    },
    {
      url: '/users',
      name: 'Usuarios',
      permission: listPermissions.user.read,
    },
  ]
  
  return (
    <Box className="subHeader" sx={subHeaderStyles.subHeader}>
      <Box>
        <ButtonBack />
        <div className="subHeader1">
          <div className="containerButton">
            {subHeader.map(({ url, name, permission }, index) => (
              <ValidateAction permission={[permission]}>
                <Link to={url} id={`composition-button-enterprise-${name}-${index}`} key={`${name}-${url}-${index}`}>
                  <Button>
                    <Typography>{name}</Typography>
                  </Button>
                </Link>
              </ValidateAction>
            ))}
          </div>
        </div>
        <Box className="subHeader2">
          <div className="containerLocation">
            <Box>
              {/* ICONO UBICACION */}
              <IconLocation />

              <Typography sx={{ marginLeft: '6px' }}>
                {idBranchOffice?.idCompany?.nameCompany ?? 'Sin Datos'}
              </Typography>

              {/* ICONO FLECHA DERECHA */}
              <IconArrowRight />

              {/* AQUI VA LA UBICACION DE LA SEDE */}
              <Typography>{idBranchOffice?.nameBranchOffice ?? 'Sin Datos'}</Typography>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default SubHeader
