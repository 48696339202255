import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ThemeProvider,
  createTheme,
  useTheme,
  Theme,
} from '@mui/material'

// IMPORTADOS
// import areaNameStyles from "../../pages/private/new-pdi/politics/formPolitics.styles";
import areaNameStyles from './AreaName.styles'
import ErrorField from '../ErrorField'
import useDepartament from '../../hooks/departament/useDepartament'
import { Area } from '../../interfaces/area'

interface AreaNameProps {
  handleSelectChange?: Function
  defaultValue?: string
  errorMessage?: string
}

const AreaName = ({ handleSelectChange, defaultValue, errorMessage }: AreaNameProps) => {
  const { fetchDepartaments } = useDepartament()

  const [area, setArea] = useState<any>('')
  const [nombreArea, setNombreArea] = useState<Area[]>([])

  const handleChange = (event: { target: { value: string; name: string } }) => {
    setArea(event.target.value)
    if (handleSelectChange) {
      handleSelectChange({ [event.target.name]: event.target.value })
    }
  }

  const getDepartaments = () => {
    fetchDepartaments().then(({ items }) => {
      setNombreArea(items)
    })
  }

  useEffect(() => {
    getDepartaments()
  }, [])

  useEffect(() => {
    setArea(defaultValue)
  }, [defaultValue, nombreArea])

  const themeAreaName = (): Theme => {
    // eslint-disable-next-line
    const theme: any = useTheme()

    return createTheme({
      components: {
        MuiMenu: {
          styleOverrides: {
            root: {
              '>.MuiPaper-elevation': {
                backgroundColor: '#ffffff',
                maxHeight: '270px',
                minHeight: '86px',
                borderRadius: '15px',
                overflowY: 'hidden',

                // UL
                '>ul': {
                  backgroundColor: 'inherit',
                  maxHeight: '214px',
                  // maxWidth: "570px",
                  margin: '20px 20px',

                  // SCROLL
                  overflowY: nombreArea.length > 6 ? 'scroll' : 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                    size: '10px',
                    '&-track': {
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: '10px',
                      border: '5px solid #ffffff',
                    },
                    '&-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '20px',
                      width: '10px',
                    },
                  },

                  // LI
                  '>li': {
                    color: '#000000',
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    lineHeight: '24px',
                    backgroundColor: 'inherit',
                    ':hover': {
                      color: '#1F398F',
                      fontWeight: '600',
                      backgroundColor: 'inherit',
                    },
                  },

                  '>.Mui-selected': {
                    backgroundColor: '#F1F3F8',
                    color: '#1F398F',
                    fontWeight: '600',
                    borderRadius: '200px',
                    ':hover': {
                      backgroundColor: '#F1F3F8 !important',
                    },
                  },
                },
              },
            },
          },
        },
      },
    })
  }

  // TEMA
  const myTheme = themeAreaName()

  return (
    <>
      {/*******************
        CONTENEDOR INPUT
      *******************/}
      <Box className="areaName" sx={areaNameStyles.containerInputSelect}>
        <Typography>Nombre área</Typography>

        {/***************
          INPUT SELECT
        ***************/}
        <ThemeProvider theme={myTheme}>
          <FormControl sx={areaNameStyles.inputSelect} variant="standard">
            <Select value={area} name="idDepartament" onChange={handleChange}>
              {nombreArea.map(({ _id, name }, index) => (
                <MenuItem key={_id} disableTouchRipple value={_id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ErrorField message={errorMessage ?? ''} />
        </ThemeProvider>
      </Box>
    </>
  )
}

export default AreaName
