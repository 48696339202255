import { FunctionComponent, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConditionModel, FormConditionValues, UpdateConditionValues } from '../../../../../interfaces/condition'
import FormCondition from '../../../../../components/forms/family/formConditions/FormConditions'
import useCondition from '../../../../../hooks/family/useCondition'
import { Box, Fade } from '@mui/material'

const UpdateCondition: FunctionComponent = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const {
    getConditionAndAreas,
    handleUpdateCondition,
    areaList: { items: areas },
  } = useCondition()

  const [defaultValues, setDefaultValues] = useState<FormConditionValues | undefined>(undefined)

  const goToBack = () => navigate('./../../')

  const handleValueForm = (condition: ConditionModel) => {
    const { __v, _id, is_active, createdAt, updatedAt, ...other } = condition
    setDefaultValues(other)
  }

  useEffect(() => {
    // Definir acción de error
    if (!id) return goToBack()
    getConditionAndAreas(id, {
      success: handleValueForm,
      error: goToBack,
    })
  }, [])

  const handleSubmit = (formValues: FormConditionValues) => {
    if (!id) return
    const updateCondition: UpdateConditionValues = { _id: id, ...formValues }
    handleUpdateCondition(updateCondition, goToBack)
  }

  return (
    <Fade in={Boolean(defaultValues)}>
      <Box>
        <FormCondition
          areas={areas}
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          textButtonSubmit="Actualizar"
          title="Editar condición"
          onCancel={goToBack}
        />
      </Box>
    </Fade>
  )
}

export default UpdateCondition
