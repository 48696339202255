/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import useFetch from '../../useFetch'
import useAppDialog from '../../useAppDialog'

const useGraphics = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchFindOne } = useFetch()
  const { showErrorDialog, showSuccessDialog, showLoadingDialog } = useAppDialog()

  const fetchGetDataGraphicPa = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getGraphicPa',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )
  const fetchGetDataGraphicPdi = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getGraphicPaPdi',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  return {
    fetchGetDataGraphicPa,
    fetchGetDataGraphicPdi,
  }
}

export default useGraphics
