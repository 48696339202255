import { FunctionComponent } from 'react'
import { useTheme } from '@mui/styles'

interface IconIndicatorsProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconIndicators: FunctionComponent<IconIndicatorsProps> = ({ width = 19, height = 19, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6429 0C18.3924 0 19 0.607614 19 1.35714V17.6429C19 18.3924 18.3924 19 17.6429 19C16.8933 19 16.2857 18.3924 16.2857 17.6429V1.35714C16.2857 0.607614 16.8933 0 17.6429 0ZM12.2143 4.07143C12.9638 4.07143 13.5714 4.67904 13.5714 5.42857V17.6429C13.5714 18.3924 12.9638 19 12.2143 19C11.4648 19 10.8571 18.3924 10.8571 17.6429V5.42857C10.8571 4.67904 11.4648 4.07143 12.2143 4.07143ZM6.78571 8.14286C7.53524 8.14286 8.14286 8.75047 8.14286 9.5V17.6429C8.14286 18.3924 7.53524 19 6.78571 19C6.03618 19 5.42857 18.3924 5.42857 17.6429V9.5C5.42857 8.75047 6.03618 8.14286 6.78571 8.14286ZM1.35714 12.2143C2.10667 12.2143 2.71429 12.8219 2.71429 13.5714V17.6429C2.71429 18.3924 2.10667 19 1.35714 19C0.607614 19 0 18.3924 0 17.6429V13.5714C0 12.8219 0.607614 12.2143 1.35714 12.2143Z"
        fill={secondary}
      />
    </svg>
  )
}

export default IconIndicators
