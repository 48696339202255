/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Box, Typography, Pagination, Tabs, Tab, Divider, Button, useTheme } from '@mui/material'
import '../../../../styles.css'

// IMPORTADOS
import IconCalendarCheck from '../../../../components/icons/IconCalendarCheck'
import IconUser2 from '../../../../components/icons/IconUser2'
import IconUserNotification from '../../../../components/icons/IconUserNotification'
import IconClock from '../../../../components/icons/IconClock'
import taskAssignmentTabsStyles from './TaskAssignmentTabs.styles'
import TabTaskPdi from './tabsTaskAssignment/TabTaskPdi'
import TabTaskWhoMeasure from './tabsTaskAssignment/TabTaskWhoMeasure'
import TabTaskInitResponsability from './tabsTaskAssignment/TabTaskInitResponsability'
import TabTaskWhoNotify from './tabsTaskAssignment/TabTaskWhoNotify'
import useTasks from '../../../../hooks/useTasks'
import { Link, useNavigate } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const TaskAssignmentTabs = ({
  tasksAssignment,
  setTasksAssignment,
  historyTasksAssignment,
  setHistoryTasksAssignment,
}: any) => {
  const theme: any = useTheme()
  const primary = theme.palette.primary.main
  const secondary = theme.palette.secondary.main
  const { fetchCreateTaskAssignment } = useTasks()
  const navigate = useNavigate()
  // TAB
  const [valueTab, setValueTab] = React.useState(0)
  const [moveNote, setMoveNote] = React.useState<boolean>(true)
  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setValueTab(value - 1)
  }

  console.log(tasksAssignment)
  const handleSubmit = async () => {
    const data: any = []
    await tasksAssignment.forEach((element: any) => {
      const userNotifies: any = []
      element.who_notify.forEach((item: any) => {
        userNotifies.push(item.id)
      })
      data.push({
        idPa: element.indicator.idPa,
        idPdi: element.idPdi,
        idUser: element.who_measure.id,
        userNotifies,
        idIndicator: element.indicator.id,
        complianceDate: element.date,
      })
    })
    const response = await fetchCreateTaskAssignment(data)
    if (response.ok) {
      navigate('assignment')
    }
  }

  useEffect(() => {
    setMoveNote(true)
    const tag: any = document.querySelector('#note')
    tag?.focus()
  }, [moveNote])

  return (
    <Box sx={taskAssignmentTabsStyles.contentCard}>
      <Box>
        {/*****************
          CONTENEDOR TABS
        *****************/}
        <Tabs value={valueTab} onChange={handleTab} aria-label="icon label tabs example">
          {/************
            TAB PDI
          ************/}
          <Tab
            icon={
              <IconCalendarCheck width={40} height={40} color={valueTab === 0 ? '#1F398F' : 'rgb(114, 130, 184)'} />
            }
            {...a11yProps(0)}
          />

          {/*********************
            TAB QUIEN MIDE?
          *********************/}
          <Tab
            icon={<IconUser2 color={valueTab === 1 ? '#1F398F' : 'rgb(114, 130, 184)'} />}
            {...a11yProps(1)}
            disabled={!historyTasksAssignment.tabPdi?.isActive}
          />

          {/****************************
            TAB A QUIEN NOTIFICA?
          ****************************/}
          <Tab
            icon={<IconUserNotification color={valueTab === 2 ? '#1F398F' : 'rgb(114, 130, 184)'} />}
            {...a11yProps(2)}
            disabled={!historyTasksAssignment.tabWhoMeasure?.isActive}
          />

          {/********************************
            TAB INICIACION DE LA TAREA
          ********************************/}
          <Tab
            icon={<IconClock color={valueTab === 3 ? '#1F398F' : 'rgb(114, 130, 184)'} />}
            {...a11yProps(3)}
            disabled={!historyTasksAssignment.tabWhoNotify?.isActive}
          />
        </Tabs>

        {/***************
                    TITULO PDI
                ****************/}
        <Box className="titleButtonSearch" sx={taskAssignmentTabsStyles.titleButtonSearch}>
          <Typography>{valueTab === 0 && 'Plan de Desarrollo Institucional'}</Typography>
          <Typography>{valueTab === 1 && '¿Quién mide?'}</Typography>
          <Typography>{valueTab === 2 && '¿A quién notifica?'}</Typography>
          <Typography>{valueTab === 3 && 'Iniciación de Responsabilidad'}</Typography>
        </Box>

        {/*****************
                    CONTENIDO TAB
                *****************/}
        {/************
                    TAB PDI
                ************/}
        <TabPanel value={valueTab} index={0}>
          <TabTaskPdi
            tasksAssignment={tasksAssignment}
            setTasksAssignment={setTasksAssignment}
            historyTasksAssignment={historyTasksAssignment}
            setHistoryTasksAssignment={setHistoryTasksAssignment}
            setMoveNote={setMoveNote}
            moveNote={moveNote}
          />
        </TabPanel>

        {/********************
          TAB QUIEN MIDE
        ********************/}
        <TabPanel value={valueTab} index={1}>
          <TabTaskWhoMeasure
            tasksAssignment={tasksAssignment}
            setTasksAssignment={setTasksAssignment}
            historyTasksAssignment={historyTasksAssignment}
            setHistoryTasksAssignment={setHistoryTasksAssignment}
          />
        </TabPanel>

        {/********************
          TAB QUIEN NOTIFICA
        ********************/}
        <TabPanel value={valueTab} index={2}>
          <TabTaskWhoNotify
            tasksAssignment={tasksAssignment}
            setTasksAssignment={setTasksAssignment}
            historyTasksAssignment={historyTasksAssignment}
            setHistoryTasksAssignment={setHistoryTasksAssignment}
          />
        </TabPanel>

        {/**************************************
          TAB INICIACION DE RESPONSABILIDAD
        **************************************/}
        <TabPanel value={valueTab} index={3}>
          <TabTaskInitResponsability
            tasksAssignment={tasksAssignment}
            setTasksAssignment={setTasksAssignment}
            historyTasksAssignment={historyTasksAssignment}
            setHistoryTasksAssignment={setHistoryTasksAssignment}
          />
        </TabPanel>

        {/*************
                    DIVISOR
                *************/}
        <br />
        <Divider />
        <br />

        {/********************
                    TEXTO INFERIOR
                ********************/}
        <Box sx={taskAssignmentTabsStyles.text}>
          <Typography>
            {valueTab === 0 && 'Escoge el componente deseado del Plan de Desarrollo Institucional.'}

            {valueTab === 1 && 'Escoge el componente deseado del módulo ¿Quién mide?'}

            {valueTab === 2 && 'Escoge el componente deseado del módulo ¿A quién notifica?'}
          </Typography>
          {moveNote && (
            <a href="#" id="note" style={{ textDecoration: 'none', color: 'black' }}>
              {valueTab === 0 && (
                <Typography className="move" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  Nota: Los indicadores inhabilitados no se han relacionado a ningun plan de acción, por lo tanto no
                  podrán ser asignados.{' '}
                  <Link to={'/plan/pdas'} style={{ color: primary }}>
                    Relacionar indicadores
                  </Link>
                </Typography>
              )}
            </a>
          )}

          {valueTab === 3 && (
            <>
              {/**********************
                                CONTENEDOR   BOTONES 
                            ***********************/}
              <Box sx={taskAssignmentTabsStyles.divButton}>
                {/**********************************
                                    BOTONES ACTUALIZAR/DESACTIVAR
                                ***********************************/}
                <div>
                  {/********************
                                        BOTON DESACTIVAR
                                    *********************/}
                  <Button disableTouchRipple onClick={e => changePage(e, 1)} sx={taskAssignmentTabsStyles.buttonBack}>
                    Regresar
                  </Button>

                  {/********************
                                        BOTON ACTUALIZAR
                                    *********************/}
                  <Button
                    disableTouchRipple
                    onClick={handleSubmit}
                    sx={taskAssignmentTabsStyles.buttonSave}
                    type="submit"
                    disabled={!tasksAssignment[0]?.date}
                  >
                    Guardar
                  </Button>
                </div>
              </Box>
            </>
          )}
        </Box>

        {/*********************************
                    CONTENEDOR BUSCAR/PAGINACION
                *********************************/}
        <Box sx={taskAssignmentTabsStyles.containerPagination}>
          {/***************
                        PAGINACION
                    ***************/}
          <Pagination
            page={valueTab + 1}
            count={4}
            onChange={changePage}
            sx={{
              display: valueTab === 3 ? 'none' : 'inherit',
            }}
            hideNextButton={
              (valueTab === 0 && !historyTasksAssignment.tabPdi?.isActive) ||
              (valueTab === 1 && !historyTasksAssignment.tabWhoMeasure?.isActive) ||
              (valueTab === 2 && !historyTasksAssignment.tabWhoNotify?.isActive)
            }
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TaskAssignmentTabs
