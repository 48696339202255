import { Box, Typography } from '@mui/material'

// IMPORTADOS
import infoProcessStyles from '../../../plan/process/infoProcess.styles'
import verifyStyles from '../../Verify.styles'

const DetailVerifyEventAssigned = ({ dataInfo }: any) => {
  return (
    // DETALLES DEL EVENTO
    <Box sx={infoProcessStyles.content}>
      {/***************/}
      {/* CONTENIDO 1 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Nombre</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>{dataInfo.name}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 2 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Justificación</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>{dataInfo.justification}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 3 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Fecha inicial</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>{dataInfo.startDate.split('T')[0]}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 4 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Fecha final</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>{dataInfo.finalDate.split('T')[0]}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 5 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Nombre del indicador</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>{dataInfo.idIndicator?.name}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 6 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Nombre del usuario</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography
          component={'span'}
        >{`${dataInfo.idUser?.idProfile?.name} ${dataInfo.idUser?.idProfile?.lastName}`}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 7 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Nombre del área</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>{dataInfo.idUser?.idProfile?.idDepartament?.name}</Typography>
      </Box>

      {/***************/}
      {/* CONTENIDO 8 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Estado</Typography>

        {/********/}
        {/* INFO */}
        {/********/}
        <Typography component={'span'}>
          {dataInfo.idStatus?.code === 0 ? 'Sin Cumplir' : dataInfo.idStatus?.code === 1 && 'Cumplido'}
        </Typography>
      </Box>
    </Box>
  )
}

export default DetailVerifyEventAssigned
