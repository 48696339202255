import { FunctionComponent } from "react";

interface IconLessProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconLess: FunctionComponent<IconLessProps> = ({
    width = 23,
    height = 23,
    color = "#F24E1E",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.66634 10.542C7.41218 10.542 7.16842 10.643 6.9887 10.8227C6.80897 11.0024 6.70801 11.2462 6.70801 11.5003C6.70801 11.7545 6.80897 11.9982 6.9887 12.178C7.16842 12.3577 7.41218 12.4587 7.66634 12.4587H15.333C15.5872 12.4587 15.8309 12.3577 16.0107 12.178C16.1904 11.9982 16.2913 11.7545 16.2913 11.5003C16.2913 11.2462 16.1904 11.0024 16.0107 10.8227C15.8309 10.643 15.5872 10.542 15.333 10.542H7.66634Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0413 11.5002C22.0413 17.322 17.3216 22.0418 11.4997 22.0418C5.6778 22.0418 0.958008 17.322 0.958008 11.5002C0.958008 5.67829 5.6778 0.958496 11.4997 0.958496C17.3216 0.958496 22.0413 5.67829 22.0413 11.5002ZM20.1247 11.5002C20.1247 12.6328 19.9016 13.7544 19.4681 14.8008C19.0347 15.8472 18.3994 16.7981 17.5985 17.599C16.7976 18.3999 15.8468 19.0352 14.8003 19.4686C13.7539 19.9021 12.6323 20.1252 11.4997 20.1252C10.367 20.1252 9.24546 19.9021 8.19903 19.4686C7.1526 19.0352 6.20178 18.3999 5.40088 17.599C4.59997 16.7981 3.96466 15.8472 3.53121 14.8008C3.09777 13.7544 2.87467 12.6328 2.87467 11.5002C2.87467 9.21267 3.78338 7.01887 5.40088 5.40137C7.01838 3.78387 9.21218 2.87516 11.4997 2.87516C13.7872 2.87516 15.981 3.78387 17.5985 5.40137C19.216 7.01887 20.1247 9.21267 20.1247 11.5002Z"
                fill={color}
            />
        </svg>
    );
};

export default IconLess;
