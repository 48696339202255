/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from 'react'
import { Box, Button, InputBase, Typography } from '@mui/material'

// IMPORTADOS
import verifyStyles from '../../Verify.styles'
import infoProcessStyles from '../../../plan/process/infoProcess.styles'
import formStyles from '../../../../../components/forms/form.styles'
import CustomSelectList from '../../../../../components/CustomSelectList'
import InputDatePicker from '../../../../../components/InputDatePicker'
import useIndicator from '../../../../../hooks/pdi/useIndicator'
import useMeasurement from '../../../../../hooks/verify/measure/useMeasurement'
import useEvents from '../../../../../hooks/verify/events/useEvents'

export interface EditVerifyEventAssignedProps {
  open?: boolean
  onClose: () => void
  dataInfo: any
}

const EditVerifyEventAssigned: FunctionComponent<EditVerifyEventAssignedProps> = ({ onClose, dataInfo }) => {
  const { fetchGetIndicator } = useIndicator()
  const { getAssignments } = useMeasurement()
  const { updateEvent } = useEvents()

  const [loading, setLoading] = useState<boolean>(false)
  const [dataForm, setDataForm] = useState<any>({})
  const [assignments, setAssignments] = useState<any>([])
  const [indicatorsOptions, setIndicatorsOptions] = useState<any>([])

  useEffect(() => {
    setDataForm({
      _id: dataInfo._id,
      name: dataInfo.name,
      justification: dataInfo.justification,
      startDate: dataInfo.startDate.split("T")[0],
      finalDate: dataInfo.finalDate.split("T")[0],
      idIndicator: dataInfo.idIndicator._id,
      userNotifies: dataInfo.userNotifies,
    })
  }, [dataInfo])
  console.log(dataForm)

  useEffect(() => {
    getDataSelects()
  }, [])

  const getDataSelects = async () => {
    setLoading(true)
    const res: any = await fetchGetIndicator()
    const res2: any = await getAssignments()
    setAssignments(res2.items)

    const _indicatorsAvaliable: any = []
    const _indicatorsAssignments: any = res2.items ?? []

    // Validacion de los indicadores
    res.items?.forEach((element: any) => {
      // Verifica que se encuentre relacionado a un PDA
      const isAssignment = _indicatorsAssignments.some((item: any) => element._id === item.idIndicator?._id)

      if (isAssignment) {
        _indicatorsAvaliable.push({ ...element })
      }
    })

    // Ordenar el array poniendo primero los relacionados y disponibles para checkar
    _indicatorsAvaliable.sort((a: any, b: any) => {
      const existA = a.assignment
      const existB = b.assignment
      return Number(existB) - Number(existA)
    })

    const _indicators: any = []
    _indicatorsAvaliable.forEach((element: any) => {
      _indicators.push({
        label: element.name,
        value: element._id,
      })
    })
    setIndicatorsOptions(_indicators)
    setLoading(false)
  }

  const handleInputChange = (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
  }

  const handleInputDateChange = ({ date, name }: any) => {
    setDataForm({ ...dataForm, [name]: date })
  }

  const handleSelectChange = (name: any, value: any) => {
    const _userNotifies = assignments.find((element: any) => element.idIndicator?._id === value).userNotifies
    setDataForm({
      ...dataForm,
      [name]: value,
      userNotifies: _userNotifies.flatMap((element: any) => element._id),
    })
  }

  const hanldleSubmit = async() => {
    const resp = await updateEvent(dataForm)
    if (resp.ok) {
      window.location.reload()
    }
  }

  return (
    // EDITAR EL EVENTO
    <Box sx={infoProcessStyles.content}>
      {/***************/}
      {/* CONTENIDO 1 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Nombre</Typography>

        {/****************/}
        {/* INPUT NOMBRE */}
        {/****************/}
        <Box sx={formStyles.input}>
          <InputBase
            sx={{ ...formStyles.inputBase, fontSize: '15px !important' }}
            name="name"
            value={dataForm.name}
            onChange={handleInputChange}
          />
        </Box>
      </Box>

      {/***************/}
      {/* CONTENIDO 2 */}
      {/***************/}
      <Box sx={verifyStyles.contentInfoData}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>Justificación</Typography>

        {/**************/}
        {/* INPUT INFO */}
        {/**************/}
        <Box sx={formStyles.inputDescription}>
          <InputBase
            multiline
            maxRows={6}
            sx={{ ...formStyles.inputBaseDescription, fontSize: '15px !important' }}
            name="justification"
            value={dataForm.justification}
            onChange={handleInputChange}
          />
        </Box>
      </Box>

      {/***************/}
      {/* CONTENIDO 3 */}
      {/***************/}
      <Box sx={verifyStyles.contentDate}>
        {/**********************/}
        {/* CALENDARIO INICIAL */}
        {/**********************/}
        <InputDatePicker label="Fecha inicial" onChange={handleInputDateChange} inputName="startDate" value={dataForm.startDate} />

        {/********************/}
        {/* CALENDARIO FINAL */}
        {/********************/}
        <InputDatePicker label="Fecha Final" onChange={handleInputDateChange} inputName="finalDate" value={dataForm.finalDate}/>
      </Box>

      {/***************/}
      {/* CONTENIDO 4 */}
      {/***************/}
      {/************************/}
      {/* NOMBRE DEL INDICADOR */}
      {/************************/}
      <CustomSelectList
        titleSelect="Nombre del indicador"
        api={indicatorsOptions}
        name="idIndicator"
        value={dataForm.idIndicator}
        onChange={handleSelectChange}
      />

      {/***************/}
      {/* CONTENIDO 6 */}
      {/***************/}
      {/**********************/}
      {/* CONTENEDOR BOTONES */}
      {/**********************/}
      <Box sx={{ ...formStyles.divButton, marginTop: '30px' }}>
        {/****************************/}
        {/* BOTONES GUARDAR/CANCELAR */}
        {/****************************/}
        <div>
          {/*****************/}
          {/* BOTON GUARDAR */}
          {/*****************/}
          <Button
            disableTouchRipple
            type="submit"
            // disabled={!isValid}
            sx={{
              ...formStyles.buttonSave,
              maxWidth: '196.741px !important',
              width: '196.741px !important',
              backgroundColor: '#7282B8',
              color: '#ffffff',
              ':hover': { backgroundColor: '#7282B8' },
            }}
            onClick={hanldleSubmit}
          >
            Guardar
          </Button>

          {/******************/}
          {/* BOTON CANCELAR */}
          {/******************/}
          <Button
            onClick={onClose}
            disableTouchRipple
            sx={{
              ...formStyles.buttonCancel,
              maxWidth: '196.741px !important',
              width: '196.741px !important',
            }}
          >
            Cancelar
          </Button>
        </div>
      </Box>
    </Box>
  )
}

export default EditVerifyEventAssigned
