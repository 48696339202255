import * as yup from 'yup'
import { FormChallengeValues } from '../../interfaces/pdi/challenge'
import { testMaxLength } from '../validations';

export const formChallengeSchema: yup.SchemaOf<FormChallengeValues> = yup.object({
    idProject: yup.string()
        .required('El programa es un campo requerido')
        .length(24, 'El proyecto seleccionado no es válido'),
    idPdi: yup.string()
        .required('El programa es un campo requerido')
        .length(24, 'El proyecto seleccionado no es válido'),
    complienceDate: yup.number()
        .typeError('La fecha de cumplimiento tiene un formato incorrecto.')
        .integer('La fecha de cumplimiento seleccionada no es valida')
        .min(1, 'La fecha cumplimiento seleccionada no es valida')
        .required('La fecha cumplimiento es un campo requerido'),
    state: yup.boolean().required('El estado es un campo requerido').default(true),
    description: yup.string()
        .trim('La formula no puede contener solo espacios en blanco')
        .test('maxLength', 'La descripción no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .required('La descripcion es un campo requerido'),
}).required()