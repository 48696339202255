import * as yup from 'yup'
import { testMaxLength, testFileMaxSize, testRequiredFile, testValidExtensionsFile } from '../validations';
import { FormConditionValues } from '../../interfaces/condition';

export const validExtensionJustificationFile = ['pdf','docx','doc','ppsx','pptx','xls','xlsm','xlsx','accdt','accdb']
// export const validExtensionJustificationFile = ['pdf']

const maxLength = 104857600 // 100MB

export const formConditionSchema: yup.SchemaOf<Omit<FormConditionValues, 'justification' | 'justificationLink'>> = yup.object().shape({
    idDepartament: yup.string()
        .required('El area es un campo requerido')
        .length(24, 'El area seleccionada no es válida'),
    name: yup.string()
        .trim('El nombre no puede contener solo espacios en blanco')
        .test('maxLength', 'El nombre no puede contener mas de 50 caracteres', val => testMaxLength(val, 50))
        .required('El nombre es un campo requerido'),
    
    justificationLink: yup.mixed<FileList | string>()
        .test("required", "La justificación requiere un archivo",
            files => {
                if (typeof files === 'string') {
                    return files.trim().length > 0
                }
                return testRequiredFile(files)
            })
        .test("fileSize", "El archivo es demasiado grande, máximo 100MB",
            files => {
                if (typeof files === 'string') return true
                return testFileMaxSize(files, maxLength)
            })
        .test("extension",
            `El archivo requiere una extención válida: ${validExtensionJustificationFile.length > 1
                ? validExtensionJustificationFile
                    .slice(0, -1)
                    .join(', ') + ' o ' + validExtensionJustificationFile.at(-1)
                : validExtensionJustificationFile.toString()}`,
            files => {
                if (typeof files === 'string') return true
                return testValidExtensionsFile(files, validExtensionJustificationFile)
            })
}).required()