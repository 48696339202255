import React, { useEffect, useState } from 'react'
import { Box, Typography, List, ListItem, ListItemButton, ListItemText, Pagination } from '@mui/material'

// IMPORTADOS
import relationsStyles from './Relations.styles'
import { listSubProcesos } from '../../../../../components/data/dataAPI'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import useSubProcess from '../../../../../hooks/pdi/process/useSubprocess'
import Spinner from '../../../../../components/utilities/Spinner'

interface SubProcessRelationsProps {
  subProcess: string
  setSubProcess: Function
}

const SubProcessRelations = ({ subProcess, setSubProcess }: SubProcessRelationsProps) => {
  // PAGINACION
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [limit, setLimit] = useState<number>(12)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const { fetchGetSubProcess } = useSubProcess()
  const [list, setList] = useState(listSubProcesos)

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  const getList = async () => {
    setLoading(true)
    const response: any = await fetchGetSubProcess({
      limit,
      from,
    })
    const data = response.items
    setList(data)
    const pages = response.total / 10

    if (Number.isInteger(pages)) {
      setTotalPages(pages)
    } else {
      setTotalPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    getList()
  }, [page])

  return (
    // LISTA SUBPROCESOS
    <Box>
      {/***********************
                TITULO SUBPROCESOS
            ************************/}
      <Box className="titleButtonSearch" sx={relationsStyles.titleButtonSearch}>
        <Typography>Subrocesos</Typography>

        <ButtonSearch />
      </Box>

      <Box className="containerList">
        {loading ? (
          <List>
            <Spinner />
          </List>
        ) : (
          <List>
            {list.map((item: any, index: any) => (
              <ListItem onClick={() => setSubProcess(item._id)}>
                <ListItemButton disableTouchRipple selected={subProcess === item._id}>
                  <ListItemText>{item.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        <Pagination count={totalPages} page={page} onChange={changePage} />
      </Box>
    </Box>
  )
}

export default SubProcessRelations
