/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'

const useMeasure = () => {
  const { fetchList, fetchFindOne } = useFetch()
  const { showErrorDialog } = useAppDialog()

  const fetchGetMyAssignments = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getMyAssignments',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )
  const fetchGetMyAssignmentsAll = useCallback(async () => {
    return fetchList<any>({
      action: 'getMyAssignments',
    })
      .then(res => {
        return res
      })
      .catch(error => {
        showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
      })
  }, [fetchList])

  const fetchGetOneAssignment = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneAssignment',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchGetOneAssignmentByIndicator = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getIndicatorEvents',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  return {
    fetchGetMyAssignments,
    fetchGetOneAssignment,
    fetchGetMyAssignmentsAll,
    fetchGetOneAssignmentByIndicator,
  }
}

export default useMeasure
