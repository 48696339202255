import { Box } from '@mui/material'

// IMPORTADOS
import homeStyles from './Home.styles'
import Quality from './Quality'
import QuickAccess from './QuickAccess'
import Indicating from './Indicating'
import Task from './Task'
import DatePicker from './DatePicker'
import WhatHappened from './WhatHappened'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import useAppDialog from '../../../hooks/useAppDialog'
import { setError } from '../../../redux/features/auth/authSlice'

const Home = () => {
  const { errorMessage } = useSelector((state: any) => state.auth)
  const { showErrorDialog } = useAppDialog()
  const dispatch = useDispatch()

  useEffect(() => {
    if (errorMessage) {
      showErrorDialog({ text: errorMessage })
      dispatch(setError(''))
    }
  }, [])

  return (
    <>
      {/*******************
        CONTENEDOR HOME
      ********************/}
      <Box sx={homeStyles.containerHome}> 
        <div>
          {/******************************************************
            ESTADO DE SISTEMA DE GESTIÓN DE CALIDAD - IMPORTADO
          *******************************************************/}
          <Quality />

          {/***************************
            ACCESO RAPIDO - IMPORTADO
          ****************************/}
          <QuickAccess />
        </div>

        <div>
          {/***********************************
            CONTENEDOR INDICADORES Y TAREAS
          *************************************/}
          <Box sx={homeStyles.contentIndicatingTask}>
            {/**************************
              INDICADORES - IMPORTADO
            ***************************/}
            <Indicating />

            {/*************************
              MIS TAREAS - IMPORTADO
            ***************************/}
            <Task />
          </Box>

          {/*************************
            CALENDARIO - IMPORTADO
          *************************/}
          <DatePicker />

          {/*******************************
            QUE HA PASADO... - IMPORTADO
          ********************************/}
          <WhatHappened />
        </div>
      </Box>
    </>
  )
}

export default Home
