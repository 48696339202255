import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import IconQuickAccess from '../../../components/icons/IconQuickAccess'

const QuickAccess = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          width: '346px',
          height: '369px',
          marginBottom: '44px',
          // transition: "1.5s 7s",

          // RESPONSIVE
          '@media (max-width: 1440px)': {
            width: '100%',
            // transition: "1.5s 7s",
          },

          // TITULO
          '>div': {
            display: 'flex',
            alignItems: 'center',
            marginTop: '49px',
            marginLeft: '47px',
            marginRight: '47px',
            marginBottom: '22px',
            '>svg': {
              marginRight: '7px',
            },
            '>p': {
              fontSize: '20px',
              color: '#1F398F',
              fontWeight: '400',
            },
          },

          // LISTA
          '>ul': {
            marginLeft: '50px',
            marginRight: '44px',
            paddingTop: '0px',
            paddingBottom: '0px',
            '>li, >a': {
              paddingLeft: '0px',
              paddingRight: '0px',
              paddingTop: '0px',
              '>div>span': {
                color: '#000000',
                fontSize: '14.5px',
                fontWeight: '300',
                lineHeight: '22px',
                textDecoration: 'underline',
              },
            },
          },
        }}
      >
        <Box>
          {/***********************
            ICONO ACCESO RAPIDO
          ***********************/}
          <IconQuickAccess />

          <Typography>Acceso rápido.</Typography>
        </Box>

        <List>
          <ListItem component="a" href="#">
            <ListItemText primary="Listado maestro de documentos." />
          </ListItem>

          <ListItem component="a" href="#">
            <ListItemText primary="Mapa de procesos." />
          </ListItem>

          <ListItem component="a" href="#">
            <ListItemText primary="Listado maestro de actas." />
          </ListItem>

          <ListItem component="a" href="#">
            <ListItemText primary="Manuales." />
          </ListItem>

          <ListItem component="a" href="#">
            <ListItemText primary="Listado maestro de docs externos." />
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export default QuickAccess
