import { FunctionComponent, memo, useEffect, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, SxProps, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'

import { useForm, SubmitHandler, Controller } from 'react-hook-form'

// IMPORTADOS
import logoAmericana from '../../../images/logos/logoGrande.png'
import IconOpenEye from '../../../components/icons/IconOpenEye'
import CloseEye from '../../../components/icons/IconCloseEye'
import CustomModal from '../../../components/CustomModal'
import FormRecoverPassword from './FormRecoverPassword'
import useAuth from '../../../hooks/useAuth'
import { LoginData } from '../../../interfaces/auth'
import Swal from 'sweetalert2'
import IconPerson from '../../../components/icons/IconPerson'
import IconPasswordSecure from '../../../components/icons/IconPasswordSecure'

const Login: FunctionComponent = () => {
  const { onLogin, isLoading, errorMessage } = useAuth()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>()

  useEffect(() => {
    if (isLoading && !errorMessage) {
      Swal.fire({
        title: 'Iniciando sesión.',
        html: 'Por favor, espera un momento...',
        didOpen: () => Swal.showLoading(),
      })
    }
    if (errorMessage && !isLoading) {
      Swal.fire({
        title: 'Error.',
        html: errorMessage,
        icon: 'error',
        showConfirmButton: true,
      })
    }
    return () => Swal.close()
  }, [isLoading, errorMessage])

  /*****************/
  // AUTENTICACION
  /*****************/
  const onSubmit: SubmitHandler<LoginData> = data => onLogin(data)
  /*****************************/
  // MOSTRAR/OCULTAR CONTRASEÑA
  /*****************************/
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  return (
    <Grid container justifyContent={'center'} sx={{ minHeight: '90vh' }}>
      <Grid item sx={{ mt: 20 }} xs={12} sm={8} md={5} lg={3}>
        {/********************/}
        {/* IMAGEN AMERICANA */}
        {/********************/}
        <Box sx={{ ...justifyContent, '@media (max-width: 380px)': { marginX: '30px' } }}>
          <img src={logoAmericana} alt="bg" style={imgPropStyle} />
        </Box>

        {/**************/}
        {/* FORMULARIO */}
        {/**************/}
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {/********************/}
          {/* DATOS DE USUARIO */}
          {/********************/}
          <Box
            sx={{
              margin: 5,
              marginTop: '60px',
              marginBottom: 2,
            }}
          >
            {/************************/}
            {/* CONTENIDO FORMULARIO */}
            {/************************/}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/*****************/}
              {/* ICONO USUARIO */}
              {/*****************/}
              <Box
                sx={{
                  width: 50,
                  display: 'flex',
                  marginTop: 'auto',
                  justifyContent: 'center',
                }}
              >
                {/**********************/}
                {/* ICONO USERNAME SVG */}
                {/**********************/}
                <IconPerson width={30} height={30} color="#1F398F" />
              </Box>

              {/******************/}
              {/* INPUT USERNAME */}
              {/******************/}
              <Controller
                name="userName"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'El campo usuario es requerido',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Usuario"
                    placeholder="Digite su Usuario"
                    variant="standard"
                    sx={{
                      borderBottom: '2px solid #003487',
                      '>.Mui-focused:after': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </Box>

            {/********************/}
            {/* MENSAJE DE ERROR */}
            {/********************/}
            {errors.userName && (
              <Box sx={{ m: 1, ml: 5 }}>
                <Typography component="p" color="red">
                  {errors.userName?.message}
                </Typography>
              </Box>
            )}
          </Box>

          {/**************/}
          {/* CONTRASEÑA */}
          {/**************/}
          <Box
            sx={{
              margin: '22px 40px 40px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/********************/}
              {/* ICONO CONTRASEÑA */}
              {/********************/}
              <Box
                sx={{
                  width: 50,
                  display: 'flex',
                  marginTop: 'auto',
                  justifyContent: 'center',
                }}
              >
                {/************************/}
                {/* ICONO CONTRASEÑA SVG */}
                {/************************/}
                <IconPasswordSecure />
              </Box>

              {/********************/}
              {/* INPUT CONTRASEÑA */}
              {/********************/}
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Debe ingresar una contraseña.',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Contraseña"
                    placeholder="Digite su contraseña"
                    variant="standard"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableTouchRipple
                            onClick={handleClickShowPassword!}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <IconOpenEye /> : <CloseEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '>.Mui-focused:after': {
                        display: 'none',
                      },
                      '&>div>div>button': {
                        padding: '0px !important',
                        margin: '8px',
                        ':hover': {
                          backgroundColor: 'inherit',
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>

            {errors.password && (
              <Box sx={{ m: 1, ml: 5 }}>
                <Typography component="p" color="red">
                  {errors.password.message}
                </Typography>
              </Box>
            )}
          </Box>

          {/************************/}
          {/* BOTÓN INICIAR SESIÓN */}
          {/************************/}
          <Box sx={{ ...justifyContent }}>
            <Button variant="contained" type="submit" sx={buttonPropSx}>
              Iniciar sesión
            </Button>
          </Box>
        </Box>

        {/*********************/}
        {/* OLVIDÓ CONTRASEÑA */}
        {/*********************/}
        <Box sx={{ ...justifyContent }}>
          {/***************************/}
          {/* MODAL OLVIDÓ CONTRASEÑA */}
          {/***************************/}
          <CustomModal text="Olvidó su contraseña">
            <FormRecoverPassword />
          </CustomModal>
        </Box>
      </Grid>
    </Grid>
  )
}

const imgPropStyle: React.CSSProperties = {
  maxWidth: '100%',
  height: '202px',
  alignSelf: 'center',
  objectFit: 'scale-down',
}
const justifyContent: SxProps = {
  m: 'auto',
  marginTop: {
    xs: 5,
    sm: 5,
    md: 5,
    lg: 7,
    xl: 7,
  },
  display: 'flex',
  justifyContent: 'center',
}

const buttonPropSx = {
  background: '#003487',
  mt: '10px',
  paddingX: 4,
  paddingY: 1.1,
  borderRadius: 20,
  textTransform: 'initial',
  ':hover': {
    background: '#003487',
    opacity: 0.9,
  },
}
export default memo(Login)
