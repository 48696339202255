import { useTheme } from '@mui/styles'
import { FunctionComponent } from 'react'

interface IconCalendarCheckProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconCalendarCheck: FunctionComponent<IconCalendarCheckProps> = ({ width = 85, height = 85, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.37106 9.00474C3.88923 7.49775 5.9483 6.65112 8.09532 6.65112H76.9055C79.0525 6.65112 81.1116 7.49774 82.6298 9.00474C84.1479 10.5117 85.0008 12.5557 85.0008 14.6869V76.964C85.0008 79.0952 84.1479 81.1391 82.6298 82.6461C81.1116 84.1531 79.0525 84.9997 76.9055 84.9997H8.09532C5.94831 84.9997 3.88924 84.1531 2.37106 82.6461C0.852894 81.1391 0 79.0952 0 76.964V14.6869C0 12.5557 0.852897 10.5117 2.37106 9.00474ZM8.09532 36.7852V76.964H76.9055V36.7852H8.09532ZM76.9055 28.7494H8.09532V14.6869L76.9055 14.6869V28.7494Z"
        fill={color ?? secondary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.1479 43.8421C59.284 44.9648 59.284 46.7852 58.1479 47.9079L40.6934 65.1579C39.5573 66.2807 37.7154 66.2807 36.5793 65.1579L27.8521 56.5329C26.716 55.4102 26.716 53.5898 27.8521 52.4671C28.9881 51.3443 30.8301 51.3443 31.9661 52.4671L38.6364 59.0591L54.0339 43.8421C55.1699 42.7193 57.0119 42.7193 58.1479 43.8421Z"
        fill={color ?? secondary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5717 0C29.7353 0 31.4892 1.87523 31.4892 4.18845V20.9422C31.4892 23.2554 29.7353 25.1307 27.5717 25.1307C25.4082 25.1307 23.6543 23.2554 23.6543 20.9422V4.18845C23.6543 1.87523 25.4082 0 27.5717 0ZM58.9112 0C61.0747 0 62.8286 1.87523 62.8286 4.18845V20.9422C62.8286 23.2554 61.0747 25.1307 58.9112 25.1307C56.7476 25.1307 54.9937 23.2554 54.9937 20.9422V4.18845C54.9937 1.87523 56.7476 0 58.9112 0Z"
        fill={color ?? secondary}
      />
    </svg>
  )
}

export default IconCalendarCheck
