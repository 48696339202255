import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys = "container" | "title";

const areasStyles: StyleComponent<Keys> = {
    container: {
        backgroundColor: "#ffffff",
        minHeight: "52.7vh",
    },

    // TITULO, BOTONES CREAR Y BUSCAR
    title: {
        maxWidth: "1350px",
        display: "flex",
        margin: "auto",
        justifyContent: "space-between",
        "@media(max-width: 1670px)": {
            marginX: "160px",
        },

        // TYPOGRAPHY
        ">p": {
            color: "#1F398F",
            fontSize: "50px",
            fontWeight: "600",
            lineHeight: "75px",
            fontStyle: "normal",
        },

        // BOTONES CREAR Y BUSCAR
        ">div": {
            marginY: "auto",
            // backgroundColor: "aqua",
            display: "flex",

            // BOTON CREAR
            ">.buttonCreate": {
                backgroundColor: "#ffffff",
                // minWidth: "180px",
                minWidth: "191px",
                height: "38px",
                marginY: "auto",
                marginRight: "20px",
                padding: "0px 15px",
                textTransform: "inherit",
                borderRadius: "92px",
                boxShadow: "0px 0px 10px rgba(114, 130, 184, 0.25)",

                // HOVER
                ":hover": {
                    backgroundColor: "#7282B8",
                    ">svg": {
                        ">path": {
                            fill: "#ffffff",
                        },
                    },
                    ">p": {
                        color: "#ffffff",
                    },
                },

                // PARRAFO
                ">p": {
                    fontSize: "16px",
                    marginLeft: "4px",
                },
            },

            // BOTON BUSCAR
            ">.buttonSearch": {
                maxWidth: "268px",

                ">div": {
                    maxWidth: "268px",
                    minHeight: "41px",
                    color: "#1F398F",
                    marginLeft: "auto",
                    borderRadius: "500px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    ">button:hover": {
                        backgroundColor: "transparent",
                    },
                    ">div>input": {
                        color: "#1F398F",
                        fontSize: "12px",
                        fontWeight: "400",
                        width: "70%",
                        "@media(max-width: 590px)": {
                            width: "70%",
                        },
                    },
                },
            },
        },
    },
};

export default areasStyles;
