import { useCallback, useState } from 'react'
import { Area } from '../../interfaces/area'
import {
  FactorModel,
  Factor,
  LinkedFactorIndicators,
  NewFactor,
  UpdateFactorValues,
} from '../../interfaces/factor'
import { IndicatorFactor } from '../../interfaces/indicator-factor'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'

type FetchFactorsAndIndicatorsRelations = BasicFetchListProps

type FetchFactorsProps = BasicFetchListProps

interface GetFactorAndAreasActionsProps {
  successFactor?: (factor: FactorModel) => void
  errorFactor?: () => void
}

// interface GetLinkedFactorIndicatorsProps {
//   onSuccess?: (linkFactorIndicator: LinkedFactorIndicators) => void
//   onError?: () => void
// }

const useFactor = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchDisable, fetchFindOne } = useFetch()

  const [factorList, setFactorList] = useState<{ items: Factor[]; total: number }>({ items: [], total: 0 })

  const [factor, setFactor] = useState<FactorModel | null>(null)

  const [linkedFactorIndicators, setLinkedFactorIndicators] = useState<LinkedFactorIndicators | null>(null)

  const [indicatorFactorList, setIndicatorFactorList] = useState<{ items: IndicatorFactor[]; total: number }>({
    items: [],
    total: 0,
  })

  const [areaList, setAreaList] = useState<{ items: Area[]; total: number }>({ items: [], total: 0 })

  const { showLoadingDialog, showSuccessDialog, showErrorDialog, hideDialog } = useAppDialog()

  const getFactors = useCallback(
    ({ limit = 0, from = 0, active = true }: FetchFactorsProps = {}) => {
      showLoadingDialog()
      fetchList<Factor>({
        action: 'getFactors',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(({ items, total }) => {
          setFactorList(() => ({ items, total }))
          hideDialog()
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const getFactorsWithRelations = useCallback(
    ({ limit = 0, from = 0, active = true }: FetchFactorsAndIndicatorsRelations = {}) => {
      showLoadingDialog()
      const promiseFactors = fetchList<Factor>({
        action: 'getFactors',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })

      const promiseIndicatorFactor = fetchList<IndicatorFactor>({
        action: 'getIndicatorsFactors',
      })

      Promise.allSettled([promiseFactors, promiseIndicatorFactor]).then(
        ([factorsResponse, indicatorFactorResponse]) => {
          if (indicatorFactorResponse.status === 'fulfilled') {
            setIndicatorFactorList(() => indicatorFactorResponse.value)
          } else {
            setIndicatorFactorList(() => ({ items: [], total: 0 }))
          }

          if (factorsResponse.status === 'rejected') {
            showErrorDialog({ text: factorsResponse.reason?.message || 'Ha ocurrido un error, contactar con soporte.' })
            setFactorList(() => ({ items: [], total: 0 }))
            return
          }

          setFactorList(factorsResponse.value)
          hideDialog()
        }
      )
    },
    [fetchList]
  )

  const getFactorAndAreas = useCallback(
    (idFactor: string, { successFactor, errorFactor }: GetFactorAndAreasActionsProps) => {
      showLoadingDialog()
      const promiseAreas = fetchList<Area>({
        action: 'getDepartaments',
      })

      const promiseFactor = fetchFindOne<FactorModel>({
        action: 'getOneFactor',
        findId: idFactor,
      })

      Promise.allSettled([promiseFactor, promiseAreas]).then(([factorResponse, areasResponse]) => {
        if (areasResponse.status == 'fulfilled') {
          setAreaList(() => areasResponse.value)
        } else {
          setAreaList(() => ({ items: [], total: 0 }))
        }

        if (factorResponse.status === 'rejected') {
          showErrorDialog({
            text: factorResponse.reason?.message || 'Ha ocurrido un error, contactar con soporte.',
            didClose: () => errorFactor && errorFactor(),
          })
          setFactor(() => null)
          return
        }

        setFactor(() => factorResponse.value)
        hideDialog()
        successFactor && successFactor(factorResponse.value)
      })
    },
    [fetchList]
  )

  // const getLinkedFactorIndicators = useCallback(
  //   (idFactor: string, { onSuccess, onError }: GetLinkedFactorIndicatorsProps | undefined = {}) => {
  //     showLoadingDialog()
  //     fetchFindOne<LinkedFactorIndicators>({
  //       findId: idFactor,
  //       action: 'getOneFactorIndicators',
  //     })
  //       .then(linkFactorIndicator => {
  //         setLinkedFactorIndicators(linkedFactorIndicators)
  //         hideDialog({ didClose: () => onSuccess && onSuccess(linkFactorIndicator) })
  //       })
  //       .catch(error => {
  //         showErrorDialog({
  //           text: error?.message || 'Ha ocurrido un error al cargar las relaciones, contactar con soporte.',
  //           didClose: onError,
  //         })
  //       })
  //   },
  //   [fetchFindOne]
  // )

  const createNewFactor = useCallback(
    (body: NewFactor, done?: (_id: string) => void) => {
      showLoadingDialog()
      fetchCreate({ action: 'createFactor', body })
        .then(_id => {
          showSuccessDialog({
            text: 'El factor se ha creado exitosamente!.',
            didClose: () => done && done(_id),
          })
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchCreate]
  )

  const handleUpdateFactor = useCallback(
    (body: UpdateFactorValues, done?: (_updateValues: UpdateFactorValues) => void) => {
      showLoadingDialog()
      fetchUpdate<UpdateFactorValues>({ action: 'updateFactor', body })
        .then(_updateValues => {
          showSuccessDialog({
            text: 'El factor se ha actualizado exitosamente!.',
            didClose: () => done && done(_updateValues),
          })
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchUpdate]
  )

  const deleteFactor = useCallback(
    (id: string, done?: () => void) => {
      showLoadingDialog()
      fetchDisable({ action: 'deleteFactor', body: { _id : id } })
        .then(() => {
          showSuccessDialog({
            text: `El factor ha sido eliminado exitosamente!`,
            didClose: done,
          })
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchDisable]
  )

  return {
    areaList,
    factorList,
    factor,
    getFactors,
    // getLinkedFactorIndicators,
    getFactorAndAreas,
    getFactorsWithRelations,
    createNewFactor,
    handleUpdateFactor,
    indicatorFactorList,
    linkedFactorIndicators,
    deleteFactor,
  }
}

export default useFactor
