import { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../../useFetch'
import useAppDialog from '../../useAppDialog'

const useProcess = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchFindOne, fetchDisable } = useFetch()

  const { showLoadingDialog, showSuccessDialog, showErrorDialog } = useAppDialog()

  const fetchGetProcess = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getProcess',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const fetchGetOneProcess = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneProcess',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchCreateProcess = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchCreate({ action: 'createProcess', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Proceso creado exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchCreate]
  )

  const fetchUpdateProcess = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchUpdate({ action: 'updateProcess', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Proceso actualizado exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchUpdate]
  )

  const fetchDeleteProcess = useCallback(
    async (body: any, done?: () => void) => {
      showLoadingDialog()
      return await fetchDisable({ action: 'disableProcess', body })
        .then(() => {
          showSuccessDialog({
            text: 'Proceso eliminado exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchDisable]
  )

  return {
    fetchGetProcess,
    fetchGetOneProcess,
    fetchCreateProcess,
    fetchUpdateProcess,
    fetchDeleteProcess,
  }
}

export default useProcess
