/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from '@mui/material'

// IMPORTADOS
import areaNameStyles from '../../../../../components/inputSelect/AreaName.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import useTasks from '../../../../../hooks/useTasks'
import IconArrowLeft from '../../../../../components/icons/IconArrowLeft'
import useAppDialog from '../../../../../hooks/useAppDialog'
import Spinner from '../../../../../components/utilities/Spinner'
import taskAssignmentTabsStyles from '../../../plan/taskAssignment/TaskAssignmentTabs.styles'
import useMeasurement from '../../../../../hooks/verify/measure/useMeasurement'

export const assignmentTaskPdi = [
  {
    valor: '1',
    name: 'Políticas',
  },
  {
    valor: '2',
    name: 'Objetivos',
  },
  {
    valor: '3',
    name: 'Programas',
  },
  {
    valor: '4',
    name: 'Proyectos',
  },
  {
    valor: '5',
    name: 'Metas',
  },
  {
    valor: '6',
    name: 'Indicadores',
  },
]

// TEMA
const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '>.MuiPaper-elevation': {
            backgroundColor: '#ffffff',
            boxShadow: 'none',
            minHeight: '86px',
            borderRadius: '15px',
            overflowY: 'hidden',

            // UL
            '>ul': {
              backgroundColor: 'inherit',
              margin: '20px 20px',

              // SCROLL
              overflowY: assignmentTaskPdi.length > 14 ? 'scroll' : 'hidden',
              '&::-webkit-scrollbar': {
                width: '12px',
                size: '10px',
                '&-track': {
                  backgroundColor: '#FECF5E',
                  borderRadius: '10px',
                  border: '5px solid #ffffff',
                },
                '&-thumb': {
                  backgroundColor: '#1F398F',
                  borderRadius: '20px',
                  width: '10px',
                },
              },

              // LI
              '>li': {
                color: '#000000',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                fontSize: '16px',
                lineHeight: '24px',
                backgroundColor: 'inherit',
                ':hover': {
                  color: '#1F398F',
                  fontWeight: '600',
                  backgroundColor: 'inherit',
                },
              },

              '>.Mui-selected': {
                backgroundColor: '#F1F3F8',
                color: '#1F398F',
                fontWeight: '600',
                borderRadius: '200px',
                ':hover': {
                  backgroundColor: '#F1F3F8',
                },
              },

              // ">.Mui-disabled": {
              // backgroundColor: "red",
              // color: "#1F398F",
              // fontWeight: "600",
              // borderRadius: "200px",
              // ":hover": {
              //   backgroundColor: "#F1F3F8",
              // },
              // },
            },
          },
        },
      },
    },
  },
})

const TabPdiEvent = ({ event, events, setEvents, historyEvents, setHistoryEvents, moveNote, setMoveNote }: any) => {
  // hooks
  const { getPdiActive, fetchGetTaskAssigment } = useTasks()
  const { showErrorDialog } = useAppDialog()
  const { getAssignments } = useMeasurement()

  // ESTADO DE SELECT
  const [active, setActive] = useState(false)
  const [checkeds, setCheckeds] = useState<any>({})
  const [checkAll, setCheckAll] = useState<any>(false)

  // INPUT CHECKED
  const [list, setList] = useState([])
  const [indicators, setIndicators] = useState([])
  const [indicatorsAvaliable, setIndicatorsAvaliable] = useState([])
  const [listIndicators, setListIndicators] = useState([])
  const [indicatorsAssignmnet, setIndicatorsAssignment] = useState<any>([])
  const [table, setTable] = useState('')
  const [pdiActive, setPdiActive] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    validateIndicators()
  }, [indicators])

  const validateIndicators = async () => {
    setLoading(true)
    const response2: any = await getAssignments()

    const _indicatorsAvaliable: any = []
    const _indicatorsAssignments: any = response2.items ?? []
    setIndicatorsAssignment(_indicatorsAssignments)

    // Validacion de los indicadores
    indicators?.forEach((element: any) => {
      // Verifica que se encuentre relacionado a un PDA
      const isAssignment = _indicatorsAssignments.find((item: any) => element._id === item.idIndicator?._id)

      if (isAssignment) {
        _indicatorsAvaliable.push({ ...element, idPa: isAssignment.idPa, assignment: true })
      } else {
        _indicatorsAvaliable.push({ ...element, assignment: false })
      }
    })
    // Ordenar el array poniendo primero los relacionados y disponibles para checkar
    _indicatorsAvaliable.sort((a: any, b: any) => {
      const existA = a.assignment
      const existB = b.assignment
      return Number(existB) - Number(existA)
    })

    setIndicatorsAvaliable(_indicatorsAvaliable)
    setListIndicators(_indicatorsAvaliable)

    let checks: any = checkeds || {}
    _indicatorsAvaliable.forEach((object: any) => {
      if (object.assignment) {
        checks = {
          ...checks,
          [object._id]: checks[object._id] ?? false,
        }
      }
    })
    setCheckeds(checks)
    setLoading(false)
  }

  // Guardar al informacion del indicador seleccionado
  const handleToggleChecked = (checked: boolean, object: any) => {
    if (checked) {
      setEvents([
        ...events,
        {
          ...event,
          idPdi: pdiActive._id,
          idPa: pdiActive._id,
          idUser: indicatorsAssignmnet.find((item: any) => item?.idIndicator?._id === object?._id)?.idUser ?? '',
          idIndicator: {
            id: object._id,
            name: object.name,
          },
          userNotifies:
            indicatorsAssignmnet.find((item: any) => item?.idIndicator?._id === object?._id)?.userNotifies ?? [],
        },
      ])
    } else {
      const copyEvents = [...events]
      const index = copyEvents.findIndex(item => item.idIndicator.id === object._id)
      copyEvents.splice(index, 1)
      setEvents(copyEvents)
    }
    setCheckeds({
      ...checkeds,
      [object._id]: checked,
    })
    setCheckAll(
      !Object.values({
        ...checkeds,
        [object._id]: checked,
      }).some(item => item === false)
    )
    // Guardando el historial de acciones, y si se valida que todos los inidcadores estene checkeados o no
    setHistoryEvents({
      ...historyEvents,
      tabPdi: {
        ...historyEvents.tabPdi,
        isActive: Object.values({
          ...checkeds,
          [object._id]: checked,
        }).some(item => item === true),

        checkAll: !Object.values({
          ...checkeds,
          [object._id]: checked,
        }).some(item => item === false),
      },
    })
  }

  // Funcion para checkear todos los checkbox excepto los que esten desabilitados
  const handleCheckAll = () => {
    const check = !checkAll
    setCheckAll(check)
    let checks: any = checkeds
    const newEvents: any[] = []

    listIndicators.forEach((item: any) => {
      if (item.assignment) {
        checks = {
          ...checks,
          [item._id]: check,
        }
        if (check) {
          newEvents.push({
            ...event,
            idPdi: pdiActive._id,
            idPa: pdiActive._id,
            idUser: indicatorsAssignmnet.find((item2: any) => item2?.idIndicator?._id === item?._id)?.idUser ?? '',
            idIndicator: {
              id: item._id,
              name: item.name,
            },
            userNotifies:
              indicatorsAssignmnet.find((item2: any) => item2?.idIndicator?._id === item?._id)?.userNotifies ?? [],
          })
        } else {
          const copyTasksAssignment = [...events]
          const index = copyTasksAssignment.findIndex(item => item.idIndicator.id === item._id)
          copyTasksAssignment.splice(index, 1)
          setEvents(copyTasksAssignment)
          setHistoryEvents({})
        }
      }
    })
    setCheckeds(checks)
    setEvents(newEvents)
    setHistoryEvents({
      ...historyEvents,
      tabPdi: {
        ...historyEvents.tabPdi,
        isActive: !checkAll,
        checkAll: check,
      },
    })
  }

  useEffect(() => {
    getHistory()
  }, [])

  // Obtener el arbol del Pdi para realizar los diferentes filtros
  const getPdi = async () => {
    const response: any = await getPdiActive({})
    if (response._id) {
      setPdiActive(response ?? {})
      return true
    }
    return false
  }

  // Obtener el historial de seleccion en los diferentes tabs
  const getHistory = async () => {
    const result = await getPdi()
    if (result && historyEvents.tabPdi?.isActive) {
      setActive(true)
      setTable(historyEvents.tabPdi?.table ?? '')
      setIndicators(historyEvents.tabPdi?.indicators ?? [])
      const result = Object.fromEntries(events.map((item: any) => [item.idIndicator?.id, true]))
      setCheckeds({ ...checkeds, ...result })
      setCheckAll(historyEvents.tabPdi?.checkAll)
    }
  }

  // Funcion para extraer las listas
  const handleSelectChange = (e: any) => {
    if (!pdiActive) {
      return showErrorDialog({ text: 'Lo sentimos, no se encontro ningun pdi activo' })
    }
    setTable(e.target.value)
    setActive(false)
    switch (e.target.value) {
      case '1':
        setList(pdiActive.link_pdi_policies)
        break
      case '2':
        setList(pdiActive.link_pdi_policies.flatMap((item: any) => item.link_policies_objetive))
        break
      case '3':
        setList(
          pdiActive.link_pdi_policies.flatMap((item: any) =>
            item.link_policies_objetive.flatMap((item2: any) => item2.link_objetive_programs)
          )
        )
        break
      case '4':
        setList(
          pdiActive.link_pdi_policies.flatMap((item: any) =>
            item.link_policies_objetive.flatMap((item2: any) =>
              item2.link_objetive_programs.flatMap((item3: any) => item3.link_programs_projects)
            )
          )
        )
        break
      case '5':
        setList(
          pdiActive.link_pdi_policies.flatMap((item: any) =>
            item.link_policies_objetive.flatMap((item2: any) =>
              item2.link_objetive_programs.flatMap((item3: any) =>
                item3.link_programs_projects.flatMap((item4: any) => item4.link_projects_goals)
              )
            )
          )
        )
        break
      case '6':
        setActive(true)
        const result = pdiActive.link_pdi_policies.flatMap((item: any) =>
          item.link_policies_objetive.flatMap((item2: any) =>
            item2.link_objetive_programs.flatMap((item3: any) =>
              item3.link_programs_projects.flatMap((item4: any) =>
                item4.link_projects_goals.flatMap((item5: any) => item5.link_goals_indicators)
              )
            )
          )
        )
        setIndicators(result)
        setHistoryEvents({
          ...historyEvents,
          tabPdi: {
            // isActive: true,
            table: e.target.value,
            indicators: result,
          },
        })
        break

      default:
        break
    }
  }

  // Funcion para extraer los indicadores
  const handleClickItem = (id: string) => {
    setActive(true)
    let result = []
    switch (table) {
      case '1':
        result = pdiActive.link_pdi_policies
          .filter((item: any) => item._id === id)
          .flatMap((item: any) =>
            item.link_policies_objetive.flatMap((item2: any) =>
              item2.link_objetive_programs.flatMap((item3: any) =>
                item3.link_programs_projects.flatMap((item4: any) =>
                  item4.link_projects_goals.flatMap((item5: any) => item5.link_goals_indicators)
                )
              )
            )
          )
        setIndicators(result)
        break
      case '2':
        result = pdiActive.link_pdi_policies
          .flatMap((item: any) => item.link_policies_objetive)
          .filter((item: any) => item._id === id)
          .flatMap((item2: any) =>
            item2.link_objetive_programs.flatMap((item3: any) =>
              item3.link_programs_projects.flatMap((item4: any) =>
                item4.link_projects_goals.flatMap((item5: any) => item5.link_goals_indicators)
              )
            )
          )
        setIndicators(result)
        break
      case '3':
        result = pdiActive.link_pdi_policies
          .flatMap((item: any) => item.link_policies_objetive.flatMap((item2: any) => item2.link_objetive_programs))
          .filter((item: any) => item._id === id)
          .flatMap((item3: any) =>
            item3.link_programs_projects.flatMap((item4: any) =>
              item4.link_projects_goals.flatMap((item5: any) => item5.link_goals_indicators)
            )
          )
        setIndicators(result)

        break
      case '4':
        result = pdiActive.link_pdi_policies
          .flatMap((item: any) =>
            item.link_policies_objetive.flatMap((item2: any) =>
              item2.link_objetive_programs.flatMap((item3: any) => item3.link_programs_projects)
            )
          )
          .filter((item: any) => item._id === id)
          .flatMap((item4: any) => item4.link_projects_goals.flatMap((item5: any) => item5.link_goals_indicators))
        setIndicators(result)
        break
      case '5':
        result = pdiActive.link_pdi_policies
          .flatMap((item: any) =>
            item.link_policies_objetive.flatMap((item2: any) =>
              item2.link_objetive_programs.flatMap((item3: any) =>
                item3.link_programs_projects.flatMap((item4: any) => item4.link_projects_goals)
              )
            )
          )
          .filter((item: any) => item._id === id)
          .flatMap((item5: any) => item5.link_goals_indicators)
        setIndicators(result)
        break
      default:
        break
    }

    setHistoryEvents({
      ...historyEvents,
      tabPdi: {
        // isActive: true,
        table,
        id,
        indicators: result,
      },
    })
  }

  return (
    <Box className="contentTab" sx={taskAssignmentTabsStyles.contentTab}>
      <Box
        className="areaName"
        sx={{
          ...areaNameStyles.containerInputSelect,
          display: 'flex',
          '>.MuiFormControl-root': {
            marginRight: '25px',

            '>.MuiInputBase-root': {
              '>.MuiInputBase-input': {
                paddingLeft: '25px',
                color: '#1F398F',
                fontWeight: '600',
              },
            },
          },

          '>.buttonSearch': {
            marginY: 'auto',
            '>div': {
              '>div': {
                width: 'inherit',
              },
            },
          },
        }}
      >
        {/**************
          INPUT SELECT
        ***************/}
        <ThemeProvider theme={theme}>
          <FormControl className="aqui" sx={areaNameStyles.inputSelect} variant="standard">
            <Select value={table} onChange={handleSelectChange} displayEmpty>
              <MenuItem value="" disabled disableTouchRipple>
                Seleccione una opción
              </MenuItem>

              {assignmentTaskPdi.map((item: any, index) => (
                <MenuItem key={index} disableTouchRipple value={item?.valor}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ThemeProvider>

        <ButtonSearch data={indicatorsAvaliable} setData={setListIndicators} fieldSearch={['name']} />
      </Box>

      {/*********
          LISTA
      *********/}
      <Box sx={taskAssignmentTabsStyles.containerList} className="containerList">
        <List
          sx={{
            overflowY: 'auto',
          }}
        >
          {active ? (
            <>
              <Box sx={taskAssignmentTabsStyles.contentCheckAll}>
                {table !== '6' && (
                  <ListItem className="checkAll checkAllLeft">
                    <ListItemButton disableTouchRipple>
                      <Button onClick={() => setActive(false)}>
                        <IconArrowLeft height="9px" />
                        Volver
                      </Button>
                    </ListItemButton>
                  </ListItem>
                )}

                <ListItem className="checkAll checkAllRight">
                  <ListItemButton disableTouchRipple onClick={handleCheckAll}>
                    <ListItemIcon className="checked" sx={taskAssignmentTabsStyles.checked}>
                      <Checkbox disableRipple checked={checkAll} />
                    </ListItemIcon>
                    <ListItemText primary="Seleccionar todos" />
                  </ListItemButton>
                </ListItem>
              </Box>

              {loading ? (
                <ListItem>
                  <Spinner />
                </ListItem>
              ) : listIndicators.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No se encontraron indicadores" />
                </ListItem>
              ) : (
                listIndicators?.map((item: any) => (
                  <ListItem
                    key={item._id}
                    onClick={() => {
                      !item.related && setMoveNote(!moveNote)
                    }}
                  >
                    <ListItemButton
                      disableTouchRipple
                      onClick={() => handleToggleChecked(!checkeds[item._id], item)}
                      disabled={!item.assignment}
                    >
                      <ListItemIcon className="checked" sx={taskAssignmentTabsStyles.checked}>
                        <Checkbox
                          disableRipple
                          edge="start"
                          checked={checkeds[item._id] || false}
                          onChange={e => handleToggleChecked(e.target.checked, item)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.title ?? item.name} />
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </>
          ) : (
            list?.map((item: any) => (
              <ListItem key={item._id}>
                <ListItemButton disableTouchRipple onClick={() => handleClickItem(item._id)}>
                  <ListItemText primary={item.title ?? item.name} />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Box>
    </Box>
  )
}

export default TabPdiEvent
