import { FunctionComponent } from 'react'
import { Button, TextField, Typography, Box } from '@mui/material'

const FormRecoverPassword: FunctionComponent = () => {
  return (
    <>
      {/*******************************/}
      {/* TITULO RECUPERAR CONTRASEÑA */}
      {/*******************************/}
      <Typography
        variant="h2"
        sx={{
          maxWidth: '372px',
          margin: 'auto',
          paddingTop: '152px',
          textAlign: 'center',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 35,
          lineHeight: '41px',
          color: '#1F398F',

          '@media (max-width: 500px)': {
            fontSize: 30,
          },
          '@media (max-width: 350px)': {
            fontSize: 25,
          },
        }}
      >
        Recuperar contraseña
      </Typography>

      <form
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          maxWidth: 358,
          margin: 'auto',
        }}
      >
        {/**************************/}
        {/* INPUT TEXTFIELD CORREO */}
        {/**************************/}
        <Box
          sx={{
            marginLeft: 44.2,
            marginTop: 30,
            margin: '30px auto auto',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <TextField
            fullWidth
            label="Correo electronico"
            name="email"
            placeholder="Digite su correo electronico"
            type="text"
            variant="standard"
            sx={{
              '>.Mui-focused:after': {
                display: 'none',
              },
            }}
          />
        </Box>
      </form>

      {/**********/}
      {/* TEXTO  */}
      {/**********/}
      <Typography
        sx={{
          maxWidth: '451px',
          margin: '39px auto 30px',
          fontSize: '10px',
          lineHeight: '12px',
          textAlign: 'center',
          fontWeight: '400px',
        }}
      >
        Un Email será enviado a su cuenta de correo electrónico con su contraseña de acceso a Aplanner. Si no tiene una
        cuenta de correo asociada a Aplanner contacte al administrador de la aplicación.
      </Typography>

      {/*****************/}
      {/* BOTON ENVIAR  */}
      {/*****************/}
      <Box
        sx={{
          width: '136px',
          height: '40px',
          left: '440px',
          top: '408px',
          margin: 'auto',
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            background: '#003487',
            paddingX: 5,
            paddingY: 1.1,
            borderRadius: 20,
            ':hover': {
              background: '#003487',
              opacity: 0.9,
            },
          }}
        >
          Enviar
        </Button>
      </Box>
    </>
  )
}

export default FormRecoverPassword
