import { useCallback } from "react"
import { MeasureUnit } from "../../interfaces/pdi/measureUnit"
import useFetch from "../useFetch"
interface FetchMeasureUnitsProps {
    limit? : number
    from? : number
    active? : boolean
}

const useMeasureUnit = () => {

    const {fetchList} = useFetch()

    const fetchMeasureUnits = useCallback( async ({ limit = 0, from = 0, active = true } : FetchMeasureUnitsProps = {}) => {
        return fetchList<MeasureUnit>({
            action : 'getMeasureUnits',
            params : [
                ['from', `${from}`],
                ['limit', `${limit}`],
                ['active' , `${active}`]
            ]
        })
    }, [fetchList])

    return {
        fetchMeasureUnits
    }
}

export default useMeasureUnit

