import React, { useState } from 'react'
import CustomModal from '../customModal/CustomModal'
import { Box, Button, InputBase, Typography } from '@mui/material'
import formStyles from '../../forms/form.styles'
import usePosition from '../../../hooks/position/usePosition'

const ModalCreateCharge = ({ open, setOpen, list }: any) => {
  const { fetchCreatePosition } = usePosition()
  const [dataForm, setDataForm] = useState({})

  const handleInputChange = (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
  }
  const createPosition = async () => {
    setOpen(false)
    const res: any = await fetchCreatePosition(dataForm)
    if (res.ok) {
      list && list()
    }
  }
  return (
    <CustomModal open={open} maxHeight={300} onCloseForButtom={() => setOpen(false)}>
      <Box
        sx={{
          ...formStyles.containerTitle,
          padding: '60px 50px',
        }}
      >
        <Typography sx={formStyles.title}>Nombre del cargo</Typography>

        {/* INPUT */}
        <Box
          sx={{
            ...formStyles.inputDescription,
            minHeight: '60px !important',
            borderRadius: '10px',
          }}
        >
          <InputBase
            multiline
            autoFocus
            name="name"
            sx={formStyles.inputBaseDescription}
            onChange={handleInputChange}
          />
        </Box>

        {/**********************
                        CONTENEDOR BOTONES 
                      ***********************/}
        <Box
          sx={{
            ...formStyles.divButton,
            marginTop: '23px !important',
          }}
        >
          {/**********************************
                          BOTONES ACTUALIZAR/DESACTIVAR
                          ***********************************/}
          <div>
            {/********************
                            BOTON ACTUALIZAR
                          *********************/}
            <Button
              disableTouchRipple
              sx={{
                ...formStyles.buttonSave,
                maxWidth: '97.73px !important',
                width: '97.73px !important',
                height: '19px !important',

                // TEXTO
                fontSize: '12px !important',
                lineHeight: '14.09px !important',
              }}
              onClick={createPosition}
            >
              Crear
            </Button>
            <Button
              onClick={e => setOpen(false)}
              disableTouchRipple
              sx={{
                ...formStyles.buttonCancel,
                maxWidth: '97.73px !important',
                width: '97.73px !important',
                height: '19px !important',

                // TEXTO
                fontSize: '12px !important',
                lineHeight: '14.09px !important',
              }}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </CustomModal>
  )
}

export default ModalCreateCharge
