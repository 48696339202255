import { FunctionComponent, ReactElement } from 'react'
import { useSelector } from 'react-redux'

interface ValidateActionProps {
  children: any
  permission: string[]
}

const ValidateAction: FunctionComponent<ValidateActionProps> = ({ permission, children }): ReactElement => {
  const { user } = useSelector((state: any) => state.auth)

  const userPermissions = user?.idProfile?.idRol?.idRolPermissions ?? []

  const isAllowed = userPermissions?.some((item: any) => permission.includes(item.idPermissions))

  return isAllowed ? children : null
}

export default ValidateAction
