import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys =
    | "container"
    | "containerTitle"
    | "title"
    | "content"
    | "contentLeft"
    | "keyHidden"
    | "contentRight";

const infoBranchOfficeStyles: StyleComponent<Keys> = {
    // CONTENEDOR
    container: {
        width: "1340px",
        margin: "auto",
        marginBottom: "348px",
    },

    // CONTENEDOR TITULO
    containerTitle: {
        display: "flex",
        alignItems: "center",
        ">p": {
            color: "#1F398F",
            fontSize: "30px",
            lineHeight: "45px",
            fontStyle: "normal",
            marginLeft: "18px",
        },
        ">span": {
            fontSize: "20px",
            fontWeight: "500",
        },

        ">button, >a": {
            backgroundColor: "#ffffff",
            width: "95px",
            height: "38px",
            color: "#1F398F",
            alignSelf: "end",
            fontSize: "16px",
            textTransform: "capitalize",
            borderRadius: "92px",
            // marginTop: "20px",
            marginLeft: "30px",
            boxShadow: "0px 0px 10px rgba(114, 130, 184, 0.25)",
            ":hover": {
                backgroundColor: "#7282B8",
                color: "#ffffff",
                ">svg>path": {
                    fill: "#ffffff",
                },
            },
            ">svg": {
                marginRight: "4px",
            },
        },
    },

    // TITULO Y SUBTITULO
    title: {
        marginBottom: "30px",
        ">p": {
            fontSize: "20px",
            color: "#1F398F",
            fontWeight: "600",
            marginBottom: "9px",
        },
    },

    // CONTENIDO
    content: {
        marginTop: "71px",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
    },

    // CONTENIDO IZQUIERDO
    contentLeft: {
        backgroundColor: "white",

        ">ul": {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",

            ">li": {
                padding: "0px",
                display: "block",
                marginBottom: "26px",
            },
        },

        ">.listInline": {
            gridTemplateColumns: "repeat(auto-fill, minmax(100%, 1fr))",
        },

        // ESTILO TITULOS
        "&>ul>li>div>span, &>div>span": {
            fontFamily: "Poppins",
            color: "#1F398F",
            fontSize: "20px",
            lineHeight: "30px",
        },

        // ESTILO TEXTOS
        "&>ul>li>div>p, &>div>p, &>ul>li>a": {
            fontFamily: "Poppins",
            color: "#000000",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "22px",
        },

        "&>ul>li>a": {
            color: "#1F398F",
            display: "block",
        },

        // VER PLAN DE DESARROLLO
        ">button, >a": {
            backgroundColor: "#7282B8",
            borderRadius: "92px",
            color: "#FFFFFF",
            paddingX: "17px",
            textTransform: "inherit",
            fontSize: "16px",
            marginTop: "82px",

            ":hover": {
                backgroundColor: "#7282B8",
            },

            ">svg": {
                marginRight: "4px",
            },
        },
    },
    // CLAVE OCULTA
    keyHidden: {
        display: "block",
        width: "100%",
        "&:after": { display: "none" },
        "&:before": { display: "none" },
        "&>input.Mui-disabled": {
            WebkitTextFillColor: "rgba(0, 0, 0) !important",
        },
    },

    // CONTENIDO DERECHO
    contentRight: {
        ">div": {
            margin: "auto 0px auto auto",
            backgroundColor: "#F1F3F8",
            width: "501px",
            height: "554px",
            borderRadius: "50px",
            display: "flex",

            ">button, >label": {
                backgroundColor: "inherit",
                textTransform: "inherit",
                width: "100%",
                height: "100%",
                boxShadow: "none",
                borderRadius: "inherit",
                textAlign: "center",
                ":hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },

                ">div": {
                    display: "block",
                    textTransform: "inherit",
                    ">p": {
                        color: "#1F398F",
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "30px",
                    },
                    ">span": {
                        color: "#7282B8",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        lineHeight: "22px",
                    },
                },
            },
        },

        // BOTON REEMPLAZAR FOTOGRAFIA
        ">span": {
            display: "block",
            margin: "32px 0px auto auto",
            width: "501px",
            textAlign: "center",
            ">label": {
                backgroundColor: "inherit",
                color: "#000000",
                fontSize: "15px",
                fontStyle: "normal",
                textAlign: "center",
                padding: "0px",
                textTransform: "initial",
                textDecoration: "underline",
                boxShadow: "none",
                ":hover": {
                    backgroundColor: "inherit",
                    textDecoration: "underline",
                    boxShadow: "none",
                },
            },

            "@media (max-width:1150px)": {
                margin: "32px auto auto auto",
            },
        },
    },
};

export default infoBranchOfficeStyles;
