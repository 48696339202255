import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, InputBase, Typography } from '@mui/material'

// IMPORTADOS
import formStyles from '../../../../../components/forms/form.styles'
import useFormula from '../../../../../hooks/do/useFormula'

interface IFullfillment {
  data: any
}

const FullfillmentDoAssignmentIndicating = ({ data }: IFullfillment) => {
  const { fetchGetAcumulative } = useFormula()
  const [acumulative, setAcumulative] = useState<any>(0)

  const getAcumulative = async () => {
    const res = await fetchGetAcumulative(data._id)
    console.log(res)

    setAcumulative(res)
  }
  useEffect(() => {
    if (data._id) {
      getAcumulative()
    }
  }, [data])

  return (
    <>
      <Box className="contentCumplimientoIndicador">
        <Typography>Cumplimiento del Indicador</Typography>

        <Box className="contentInputs">
          <Box>
            {/****************
              NUMERADOR
            *****************/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Númerador</Typography>

              {/*******************
                INPUT NUMERADOR
              *******************/}
              <Box sx={formStyles.input}>
                <InputBase disabled value={acumulative?.accumulated ?? "0"} sx={formStyles.inputBase} />
              </Box>
            </Box>

            {/****************
              DENOMINADOR
            *****************/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Denominador</Typography>

              {/*******************
                INPUT DENOMINADOR
              *******************/}
              <Box sx={formStyles.input}>
                <InputBase disabled value={acumulative?.goalindicator ?? "0"} sx={formStyles.inputBase} />
              </Box>
            </Box>

            {/****************
              RESULTADO
            *****************/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Resultado</Typography>

              {/*******************
                INPUT RESULTADO
              *******************/}
              <Box sx={formStyles.input}>
                <InputBase
                  disabled
                  value={Math.round(acumulative?.result ?? "0")}
                  sx={{ ...formStyles.inputBase, '>input': { paddingLeft: '15px' } }}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </Box>
            </Box>

            {/*******
              META
            ********/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Meta</Typography>

              {/**************
                INPUT META
              **************/}
              <Box sx={formStyles.input}>
                <InputBase disabled value={acumulative?.goalindicator ?? "0"} sx={formStyles.inputBase} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="typeIndiFrecu">
          <Box>
            <Typography component={'span'}>Tipo de indicador</Typography>

            <Box className="contentText">
              <Typography>{data?.idIndicator?.idType?.name ?? 'Sin Datos'}</Typography>
            </Box>
          </Box>

          <Box>
            <Typography component={'span'}>Frecuencia de medición </Typography>

            <Box className="contentText">
              <Typography>{data?.idIndicator?.idMeasurementFrecuency?.name ?? 'Sin Datos'}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FullfillmentDoAssignmentIndicating
