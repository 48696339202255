import { FunctionComponent } from "react";

interface IconObjetivesProps {
    width?: number | string;
    height?: number | string;
}

const IconObjetives: FunctionComponent<IconObjetivesProps> = ({
    width = 40,
    height = 40,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1113 20C11.1113 17.6425 12.0478 15.3816 13.7148 13.7146C15.3818 12.0476 17.6427 11.1111 20.0002 11.1111C22.3577 11.1111 24.6186 12.0476 26.2856 13.7146C27.9526 15.3816 28.8891 17.6425 28.8891 20C28.8891 22.3575 27.9526 24.6184 26.2856 26.2854C24.6186 27.9524 22.3577 28.8889 20.0002 28.8889C17.6427 28.8889 15.3818 27.9524 13.7148 26.2854C12.0478 24.6184 11.1113 22.3575 11.1113 20ZM20.0002 22.2222C19.4108 22.2222 18.8456 21.9881 18.4289 21.5713C18.0121 21.1546 17.778 20.5893 17.778 20C17.778 19.4106 18.0121 18.8454 18.4289 18.4286C18.8456 18.0119 19.4108 17.7778 20.0002 17.7778C20.5896 17.7778 21.1548 18.0119 21.5716 18.4286C21.9883 18.8454 22.2224 19.4106 22.2224 20C22.2224 20.5893 21.9883 21.1546 21.5716 21.5713C21.1548 21.9881 20.5896 22.2222 20.0002 22.2222Z"
                fill="#7282B8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 20C-3.9137e-08 17.3736 0.517315 14.7728 1.52241 12.3463C2.5275 9.91982 4.00069 7.71504 5.85786 5.85786C7.71504 4.00069 9.91982 2.5275 12.3463 1.52241C14.7728 0.517316 17.3736 0 20 0C22.6264 0 25.2272 0.517316 27.6537 1.52241C30.0802 2.5275 32.285 4.00069 34.1421 5.85786C35.9993 7.71504 37.4725 9.91982 38.4776 12.3463C39.4827 14.7728 40 17.3736 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 7.90407e-08 25.3043 0 20ZM20 35.5556C17.9572 35.5556 15.9344 35.1532 14.0471 34.3715C12.1599 33.5897 10.445 32.4439 9.00056 30.9994C7.55609 29.555 6.41028 27.8401 5.62854 25.9529C4.8468 24.0656 4.44444 22.0428 4.44444 20C4.44444 17.9572 4.8468 15.9344 5.62854 14.0471C6.41028 12.1599 7.55609 10.445 9.00056 9.00056C10.445 7.5561 12.1599 6.41028 14.0471 5.62854C15.9344 4.8468 17.9572 4.44445 20 4.44445C24.1256 4.44445 28.0822 6.08333 30.9994 9.00056C33.9167 11.9178 35.5556 15.8744 35.5556 20C35.5556 24.1256 33.9167 28.0822 30.9994 30.9994C28.0822 33.9167 24.1256 35.5556 20 35.5556Z"
                fill="#7282B8"
            />
        </svg>
    );
};

export default IconObjetives;
