import { Box, Button, Collapse, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface CollapseEventInfoProps {
  open: boolean
  data: any
}

const CollapseEventInfo = ({ open, data }: CollapseEventInfoProps) => {
  const navigate = useNavigate()
  console.log(data)

  return (
    <Collapse in={open}>
      <Box sx={{ padding: '10px 15px' }}>
        <Typography fontSize="14px" fontWeight={'bold'}>
          Justificación
        </Typography>
        <Typography height="50px" fontSize="12px" sx={{ overflowY: 'auto', marginBottom: '12px' }}>
          {data?.justification}
        </Typography>
        <Typography fontSize="14px" fontWeight={'bold'}>
          Nombre del indicador
        </Typography>
        <Typography height="30px" fontSize="12px">
          {data.idIndicator?.name}
        </Typography>
        <Button
          sx={{
            textTransform: 'initial',
            backgroundColor: '#7282B8',
            marginY: '10px',
            width: '144px',
            height: '38px',
            marginX: 'auto',
            borderRadius: '20px',
            color: '#ffffff',
            ':hover': {
              backgroundColor: '#7282B8',
            },
          }}
          onClick={() => navigate(`/do/assignment/indicating/${data._id}/${data.idIndicator?._id}`)}
        >
          Cumplir evento
        </Button>
      </Box>
    </Collapse>

    /*
          
          
          A: {1:1}
          B: {2:2}

          C: {
            ...A,
            B
          }
          
          C= {
            A: {1:1},
            B: {2,2},
          }
          
          
          
          
          
          
          
          
          
          */
  )
}

export default CollapseEventInfo
