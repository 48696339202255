import { Box, Typography, Button, Pagination } from '@mui/material'

// IMPORTADOS
import tableProcessStyles from './tableProcess.styles'
import IconDirection from '../../../../components/icons/IconDirection'
import CustomModal from '../../../../components/CustomModal'
import InfoProcess from './InfoProcess'
import { useEffect, useState } from 'react'
import useProcess from '../../../../hooks/pdi/process/useProcess'
import Spinner from '../../../../components/utilities/Spinner'
import { useNavigate } from 'react-router-dom'
import ConfirmDeleteContent from '../../../../components/utilities/ConfirmDeleteContent'

const TableProcess = () => {
  const navigate = useNavigate()
  const { fetchDeleteProcess } = useProcess()

  const [listProcess, setListProcess] = useState([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)

  const { fetchGetProcess } = useProcess()

  const list = async () => {
    setLoading(true)
    const response: any = await fetchGetProcess({
      limit: limit,
      from: from,
    })
    setListProcess(response.items)
    const pages = response.total / 10

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  useEffect(() => {
    list()
  }, [page])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/************************
                CONTENEDOR CARD PDI
            ************************/}
      <Box sx={tableProcessStyles.container}>
        {/********************
                    CARD PROCESOS
                *********************/}
        {loading ? (
          <Box
            sx={{
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </Box>
        ) : (
          listProcess.map((process: any, index) => (
            <Box key={index} sx={{ ...tableProcessStyles.card }}>
              {/**************************
                                CONTENEDOR TITULO CARD
                            ***************************/}
              <Box sx={tableProcessStyles.title}>
                {/*******************
                                BOTON DESCARGAR
                            *********************/}
                <Button disableRipple>
                  <CustomModal text={<IconDirection />} maxHeight="869px">
                    <InfoProcess id={process._id} />
                  </CustomModal>
                </Button>
              </Box>

              {/***************************
                                NOMBRE Y FECHA CARD PDI
                            ****************************/}
              <Box sx={tableProcessStyles.nameAndDate}>
                {/*******************
                                    NOMBRE CARD PDI
                                ********************/}
                <Typography sx={tableProcessStyles.name}>{process.name}</Typography>

                {/*******************
                                    FECHA CARD PDI
                                ********************/}
                <Typography sx={tableProcessStyles.date}>
                  No. Indicadores: {process.indicadores === null ? 0 : process.indicadores}
                </Typography>

                {/*******************
                                    FECHA CARD PDI
                                ********************/}
                <Typography sx={tableProcessStyles.date}>
                  No. Subprocesos: {process.subprocesos === null ? 0 : process.subprocesos}
                </Typography>
              </Box>

              {/**********************
                                CONTENEDOR BOTONES 
                            ***********************/}
              <Box sx={tableProcessStyles.buttonsContainer}>
                {/**********************************
                                    BOTONES ACTUALIZAR/DESACTIVAR
                                ***********************************/}
                <div>
                  {/********************
                                        BOTON ACTUALIZAR
                                    *********************/}
                  <Button
                    disableTouchRipple
                    sx={{
                      backgroundColor: '#1F398F1A',
                      ':hover': {
                        backgroundColor: '#1F398F1A',
                      },
                    }}
                    onClick={() => navigate(`/plan/process/update/${process._id}`)}
                  >
                    Editar
                  </Button>

                  {/********************
                                        BOTON DESACTIVAR
                                    *********************/}
                  <CustomModal
                    maxWidth={600}
                    maxHeight={350}
                    text={
                      <Button
                        disableTouchRipple
                        sx={{
                          backgroundColor: theme => theme.palette.secondary.main,

                          // HOVER
                          ':hover': {
                            backgroundColor: theme => theme.palette.secondary.main,
                          },
                        }}
                      >
                        Eliminar
                      </Button>
                    }
                    stateOpen={openModalDelete}
                  >
                    <ConfirmDeleteContent
                      setOpen={setOpenModalDelete}
                      open={openModalDelete}
                      id={process._id}
                      list={list}
                      fetchDelete={fetchDeleteProcess}
                      message="¿Estas seguro de eliminar este proceso?"
                    />
                  </CustomModal>
                </div>
              </Box>
            </Box>
          ))
        )}
      </Box>

      {/****************
                PAGINACION
            ****************/}
      <Box sx={tableProcessStyles.pagesList}>
        <Pagination count={pages} onChange={changePage} />
      </Box>
    </Box>
  )
}

export default TableProcess
