import { FunctionComponent, useEffect, useState } from 'react'
import { Box, Typography, Button, Pagination, Grow } from '@mui/material'

// IMPORTADOS
import tableProcessStyles from '../../../../pages/private/plan/process/tableProcess.styles'
// import CustomModal from "../../../../../components/CustomModal";
import IconDirection from '../../../icons/IconDirection'
import InfoFactor, { InfoFactorProps } from '../../../Dialogs/family/InfoFactor'
import useFactor from '../../../../hooks/family/useFactor'
import { Factor, LinkedFactorIndicators } from '../../../../interfaces/factor'
import { useNavigate } from 'react-router-dom'
import useDinamicPagination from '../../../../hooks/useDinamicPagination'
import { growTimeoutEffect } from '../../../../helpers/efects'
import useAppDialog from '../../../../hooks/useAppDialog'

const itemsPerPage = 9

const TableFactors: FunctionComponent = () => {
  const navigate = useNavigate()

  const { factorList, indicatorFactorList, getFactorsWithRelations, deleteFactor, getFactors } = useFactor()

  const { showConfirmDialog } = useAppDialog()

  const [isLoadFactorAndRelations, setIsLoadFactorAndRelations] = useState(false)

  const { currentPage, handleChangePage, limit, from, countPages } = useDinamicPagination(
    itemsPerPage,
    factorList.total
  )

  useEffect(() => {
    if (!isLoadFactorAndRelations) {
      getFactorsWithRelations({ limit, from })
      setIsLoadFactorAndRelations(true)
      return
    }
    getFactors({ limit, from })
  }, [currentPage])

  const [infoFactorData, setInfoFactorData] = useState<InfoFactorProps>({
    open: false,
    factor: null,
  })

  const handleUpdate = ({ _id }: Factor | LinkedFactorIndicators) => navigate(`./update/${_id}`)

  const handleConfirmDialog = (factor: Factor | LinkedFactorIndicators) => {
    showConfirmDialog({
      icon: 'warning',
      text: 'Estas seguro que deseas eliminar este factor?',
      smallText: 'Recuerde que si elimina este factor usted no podrá editarla',
      confirmButton: {
        text: 'Si',
        onClick: () => {
          handleDelete(factor)
        },
      },
      cancelButton: { text: 'No' },
    })
  }

  const handleDelete = ({ _id }: Factor | LinkedFactorIndicators) => {
    deleteFactor(_id, () => {
      if (infoFactorData.open) setInfoFactorData(state => ({ ...state, open: false }))
      if (currentPage >= 2) return handleChangePage(1)
      getFactors({ limit, from })
    })
  }

  const handleModalInfo = (factor: Factor) => {
    setInfoFactorData(state => ({
      ...state,
      open: true,
      onClose: () => setInfoFactorData(prev => ({ ...prev, open: false })),
      factor,
    }))
  }

  const countNumbersIndicators = (factorId: string) => {
    return indicatorFactorList.items.filter(
      rel => rel.is_active && rel.idFactor?.is_active && rel.idIndicator?.is_active && rel.idFactor._id === factorId
    ).length
  }

  return (
    <>
      <InfoFactor
        indicatorsFactors={indicatorFactorList.items}
        onClose={() => setInfoFactorData(_state => ({ ..._state, show: false }))}
        onButtonDelete={handleConfirmDialog}
        onButtonUpdate={handleUpdate}
        {...infoFactorData}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/************************
          CONTENEDOR CARD PDI 
        ************************/}
        <Box sx={tableProcessStyles.container}>
          {/******************
            CARD PROCESOS
          *******************/}
          {factorList.items.map((factor, index) => (
            <Grow key={factor._id} in={factorList.total > 0} timeout={growTimeoutEffect(index)}>
              <Box sx={{ ...tableProcessStyles.card }}>
                {/**************************
                  CONTENEDOR TITULO CARD
                ***************************/}
                <Box sx={tableProcessStyles.title}>
                  {/*******************
                    BOTON DESCARGAR
                  *********************/}
                  <Button disableRipple onClick={() => handleModalInfo(factor)}>
                    <IconDirection />
                  </Button>
                </Box>

                {/***************************
                  NOMBRE Y FECHA CARD PDI
                ****************************/}
                <Box
                  sx={{
                    ...tableProcessStyles.nameAndDate,
                    paddingBottom: '70px !important',
                  }}
                >
                  {/*******************
                    NOMBRE CARD PDI
                  ********************/}
                  <Typography sx={tableProcessStyles.name}>{factor.name}</Typography>

                  {/*******************
                    FECHA CARD PDI
                  ********************/}
                  <Typography sx={tableProcessStyles.date}>
                    No. Indicadores: {countNumbersIndicators(factor._id)}
                  </Typography>
                </Box>

                {/**********************
                  CONTENEDOR BOTONES 
                ***********************/}
                <Box sx={tableProcessStyles.buttonsContainer}>
                  {/**********************************
                    BOTONES ACTUALIZAR/DESACTIVAR
                  ***********************************/}
                  <div>
                    {/********************
                      BOTON ACTUALIZAR
                    *********************/}
                    <Button
                      onClick={() => handleUpdate(factor)}
                      disableTouchRipple
                      sx={{
                        backgroundColor: '#1F398F1A',
                        ':hover': {
                          backgroundColor: '#1F398F1A',
                        },
                      }}
                    >
                      Editar
                    </Button>

                    {/********************
                      BOTON DESACTIVAR
                    *********************/}
                    <Button
                      onClick={() => handleConfirmDialog(factor)}
                      disableTouchRipple
                      sx={{
                        backgroundColor: theme => theme.palette.secondary.main,

                        // HOVER
                        ':hover': {
                          backgroundColor: theme => theme.palette.secondary.main,
                        },

                        '>a': {
                          paddingRight: '0px !important',
                          textDecoration: 'none',
                          ':hover': {
                            color: 'inherit',
                          },
                        },
                      }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </Box>
              </Box>
            </Grow>
          ))}
        </Box>
        {/****************
          PAGINACION
        ****************/}
        {factorList.items.length >= 1 && (
          <Box sx={tableProcessStyles.pagesList}>
            <Pagination count={countPages} onChange={(e, page) => handleChangePage(page)} page={currentPage} />
          </Box>
        )}
      </Box>
    </>
  )
}

export default TableFactors
