import { FunctionComponent } from "react";

interface IconXProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconX: FunctionComponent<IconXProps> = ({ 
    width = 68, 
    height = 68, 
    color = "#7282B8"
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.542 22.5417C23.7491 21.3346 25.7062 21.3346 26.9132 22.5417L34.0004 29.6288L41.0875 22.5417C42.2946 21.3346 44.2516 21.3346 45.4587 22.5417C46.6658 23.7487 46.6658 25.7058 45.4587 26.9129L38.3716 34L45.4587 41.0871C46.6658 42.2942 46.6658 44.2512 45.4587 45.4583C44.2516 46.6654 42.2946 46.6654 41.0875 45.4583L34.0004 38.3712L26.9132 45.4583C25.7062 46.6654 23.7491 46.6654 22.542 45.4583C21.3349 44.2512 21.3349 42.2942 22.542 41.0871L29.6291 34L22.542 26.9129C21.3349 25.7058 21.3349 23.7487 22.542 22.5417Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 6.18182C18.6364 6.18182 6.18182 18.6364 6.18182 34C6.18182 49.3636 18.6364 61.8182 34 61.8182C49.3636 61.8182 61.8182 49.3636 61.8182 34C61.8182 18.6364 49.3636 6.18182 34 6.18182ZM0 34C0 15.2223 15.2223 0 34 0C52.7777 0 68 15.2223 68 34C68 52.7777 52.7777 68 34 68C15.2223 68 0 52.7777 0 34Z"
                fill={color}
            />
        </svg>
    );
};

export default IconX;
