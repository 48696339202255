import { FunctionComponent } from "react";

interface IconUser2Props {
    height?: string | number;
    width?: string | number;
    color?: string;
}

const IconUser2: FunctionComponent<IconUser2Props> = ({
    height = 40,
    width = 40,
    color = "#7282B8",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.0367 33.2056C26.4654 34.9685 23.3531 36 20 36C16.6602 36 13.5593 34.9767 10.9939 33.2265C13.1584 30.3887 16.4366 28.7406 20.0235 28.7406C23.6012 28.7406 26.8721 30.3813 29.0367 33.2056ZM31.9603 30.6281C30.4929 28.781 28.6125 27.291 26.4619 26.2823C27.753 25.1292 28.7129 23.6465 29.233 21.9869C29.837 20.0597 29.8173 17.9918 29.1767 16.0764C28.5362 14.161 27.3073 12.4953 25.6644 11.3151C24.0216 10.1349 22.0481 9.5 20.0235 9.5C17.9988 9.5 16.0253 10.1349 14.3825 11.3151C12.7396 12.4953 11.5108 14.161 10.8702 16.0764C10.2296 17.9918 10.2099 20.0597 10.8139 21.9869C11.334 23.6465 12.2939 25.1292 13.585 26.2823C11.4236 27.296 9.53516 28.796 8.06456 30.656C5.53669 27.8266 4 24.0927 4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 24.0793 34.4734 27.8021 31.9603 30.6281ZM40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM21.1474 13.4935C20.0298 13.2719 18.8715 13.3856 17.8188 13.8202C16.7662 14.2548 15.8668 14.9906 15.2341 15.9343C14.6015 16.878 14.2639 17.9873 14.2639 19.1219C14.2658 20.6429 14.8728 22.1015 15.9524 23.1775C17.0321 24.2537 18.4964 24.8593 20.024 24.8611C21.1633 24.861 22.2769 24.5242 23.2239 23.8935C24.1711 23.2627 24.9091 22.3663 25.3448 21.3178C25.7805 20.2693 25.8945 19.1157 25.6724 18.0028C25.4503 16.8899 24.902 15.8673 24.0967 15.0646C23.2913 14.2619 22.265 13.715 21.1474 13.4935Z"
                fill={color}
            />
        </svg>
    );
};

export default IconUser2;
