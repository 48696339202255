import { Box, Button, Typography, Fade } from '@mui/material'
import { FunctionComponent } from 'react'
import IconAdd from '../../../icons/IconAdd'
import FilterPdi from '../../../inputSelect/FilterPdi'
import customAlerts from '../../../../helpers/customAlerts'
import useGoal from '../../../../hooks/pdi/useGoal'
import useViewControlTabPanel from '../../../../hooks/pdi/useViewControlTabPanelPdi'
import { ChangeActiveGoal, FormGoalValues, Goal, NewGoal, UpdateGoal } from '../../../../interfaces/pdi/goal'
import { MeasureUnit } from '../../../../interfaces/pdi/measureUnit'
import { Project } from '../../../../interfaces/pdi/project'
import FormGoal from '../../../forms/pdi/formGoal/FormGoal'
import TableGoals from '../../../tables/pdi/tableGoals/TableGoals'
import { IndicatorModel } from '../../../../interfaces/pdi/indicator'
import formStyles from '../../../forms/form.styles'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface TabPanelGoalsProps {
  pdi: PdiModel
  measureUnits: MeasureUnit[]
  projects: Project[]
  goals: Goal[]
  indicators: IndicatorModel[]
  onCreate: (_id: string) => void
  onChangeTitle: (tabTitle: string | null) => void
  onUpdate: (goal: Goal[]) => void
}

const TabPanelGoals: FunctionComponent<TabPanelGoalsProps> = ({
  pdi,
  measureUnits,
  goals,
  projects,
  indicators,
  onCreate,
  onChangeTitle,
  onUpdate,
}) => {
  const { fetchCreateGoal, fetchUpdateGoal, fetchDisableGoal, ViewAlert } = useGoal()

  const { viewMode, showViewData, showViewCreate, showViewUpdate, updateItem, filterOption, changeFilterOption } =
    useViewControlTabPanel<Goal, Project>({
      items: goals,
      onChangeTitle,
      titleOptions: {
        none: null,
        create: 'Crear meta',
        update: 'Actualizar meta',
        data: 'Metas',
      },
    })

  const goalsFiltered = (): Goal[] => {
    if (filterOption === null) return goals
    const { _id } = filterOption
    return goals.filter(goal => goal.idProject === _id)
  }

  const getFormDefaultValues = (): Partial<FormGoalValues> | undefined => {
    if (!updateItem) return undefined
    const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } = updateItem
    return updateValues
  }

  const create = ({ state, ...other }: FormGoalValues, reset: () => void) => {
    const newGoal: NewGoal = other
    fetchCreateGoal(newGoal, _id => {
      reset()
      onCreate(_id)
    })
  }

  const update = (formValues: FormGoalValues, reset: () => void) => {
    if (!updateItem) {
      return
    }
    const { _id } = updateItem
    const updateGoal: UpdateGoal = { _id, ...formValues }
    fetchUpdateGoal(updateGoal, _updateValues => {
      reset()
      const { _id } = _updateValues
      const _updateGoals = goals.map(goal => {
        if (goal._id === _id) {
          return { ...goal, ..._updateValues }
        }
        return goal
      })
      onUpdate(_updateGoals)
      showViewData()
    })
  }

  const disable = ({ _id }: Goal) => {
    const disableGoal: ChangeActiveGoal = { _id, is_active: false }
    fetchDisableGoal(disableGoal, () => {
      const _goals = goals.filter(goal => goal._id !== _id)
      onUpdate(_goals)
    })
  }

  const onSubmitForm = (formValues: FormGoalValues, reset: () => void) => {
    if (updateItem) return update(formValues, reset)
    create(formValues, reset)
  }

  return (
    <>
      <ViewAlert />
      {/****************
                CONTENEDOR FORMULARIO
            *****************/}
      <Fade in={viewMode === 'update' || viewMode === 'create'}>
        <Box
          sx={{
            display: viewMode === 'data' || !viewMode ? 'none' : 'block',
          }}
        >
          <FormGoal
            pdi={pdi}
            defaultValues={getFormDefaultValues()}
            measureUnits={measureUnits}
            onCancel={showViewData}
            onSubmit={onSubmitForm}
            projects={projects}
            viewMode={viewMode === 'data' || !viewMode ? undefined : viewMode}
          />
        </Box>
      </Fade>

      {/****************
                CONTENEDOR TABLA METAS
            *****************/}
      <Fade in={viewMode === 'data'}>
        <Box sx={{ display: viewMode === 'data' ? 'block' : 'none' }}>
          <Box sx={formStyles.container}>
            {/****************
                            TITULO
                        *****************/}
            <Box sx={formStyles.buttonTitle}>
              {/***************
                                TITULO META
                            ****************/}
              <Typography sx={{ fontSize: '25px' }}>
                {filterOption ? `Metas de ${filterOption.title}` : 'Todas las metas'}
              </Typography>
              <Box>
                {/***************************
                                    BOTON FILTRAR POR PROYECTOS
                                ****************************/}
                <FilterPdi
                  getOptionLabel={option => option.title}
                  id="filter-goal-by-project"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  labelInput="Proyectos"
                  noOptionsText="Sin resultados"
                  options={projects}
                  onChange={(e, value) => changeFilterOption(value)}
                  placeholder="Buscar proyecto..."
                  value={filterOption}
                />

                {/*************
                                    BOTON MÁS
                                **************/}
                <Button disableTouchRipple onClick={showViewCreate}>
                  <IconAdd />
                  <Typography>Crear nueva meta</Typography>
                </Button>
              </Box>
            </Box>

            <TableGoals
              filterOption={filterOption}
              goals={goalsFiltered()}
              projects={projects}
              measureUnits={measureUnits}
              indicators={indicators}
              onDelete={disable}
              onUpdate={showViewUpdate}
            />
          </Box>
        </Box>
      </Fade>
    </>
  )
}

export default TabPanelGoals
