import { useState, useCallback } from "react";
import GenericAlert, { GenericAlertProps } from "../../components/Alerts/GenericAlert/GenericAlert";
import { ChangeActiveProject, NewProject, UpdateProject } from "../../interfaces/pdi/project";
import useFetch from "../useFetch";

interface FetchProjectsProps {
    idPdi: string
    limit?: number
    from?: number
    active?: boolean
}

const useProject = () => {

    const { fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()

    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })

    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )

    const fetchGetProjects = useCallback(async ({ idPdi, limit = 0, from = 0, active = true }: FetchProjectsProps) => {
        return fetchList<any>({
            action: 'getProjects',
            params: [
                ['idPdi', `${idPdi}`],
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchList])

    const fetchCreateProject = useCallback((body: NewProject, done?: (_id: string) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createProject', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El proyecto se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_id),
                })
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchCreate])

    const fetchUpdateProject = useCallback((body: UpdateProject, done?: (_updateValues: UpdateProject) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate<UpdateProject>({ action: 'updateProject', body })
            .then(_updateValues => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El proyecto se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchUpdate])

    const fetchDisableProject = useCallback((body: ChangeActiveProject, done?: () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disableProject', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `El proyecto ha sido ${body.is_active ? 'habilitado' : 'eliminado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return {
        fetchGetProjects,
        fetchCreateProject,
        fetchUpdateProject,
        fetchDisableProject,
        ViewAlert
    }
}

export default useProject;