import { useEffect, useState } from "react";

const useDinamicPagination = (itemsPerPage : number, totalItems : number) => {

    const [currentPage, setCurrentPage] = useState<number>(1)

    const [from, setFrom] = useState<number>(0)

    const [limit, setLimit] = useState<number>(itemsPerPage)
    
    const [countPages, setCountPages] = useState<number>(0)

    const handleItemsPerPage = (_itemsPerPage : number) => {
        if(_itemsPerPage < 1) throw 'Items por páginas no puede ser menor a 1'
        setLimit(() => _itemsPerPage)
        setCurrentPage(() => 1)
    }

    const handleChangePage = (page : number) => {
        setFrom(() => (page - 1) * itemsPerPage)
        setCurrentPage(() => page)
    }

    useEffect(() => {
        const pages = Number.isInteger(totalItems/limit) 
            ? totalItems/limit 
            : Math.trunc(totalItems/limit) + 1
        setCountPages(() => pages)
    },[totalItems])

    
    return {
        currentPage,
        handleChangePage,
        handleItemsPerPage,
        limit,
        from,
        countPages
    }
}

export default useDinamicPagination;