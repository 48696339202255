import { Navigate, useRoutes } from 'react-router-dom'

// IMPORTADOS
// LOGIN
import Login from '../pages/public/login/Login'

// HOME
import Home from '../pages/private/home/Home'

import BranchOffice from '../pages/private/brachOffice/BranchOffice'
// import Companies from '../pages/private/companies/Companies'
import CreatePdi from '../pages/private/create-pdi/CreatePdi'
import Pdis from '../pages/private/pdis/Pdis'
import Plan from '../pages/private/plan/Plan'
import Profile from '../pages/private/profile/Profile'
import Users from '../pages/private/users/Users'
import FormUsers from '../pages/private/users/FormUsers'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Areas from '../pages/private/plan/areas/Areas'
import FormAreas from '../pages/private/plan/areas/FormAreas'
import Process from '../pages/private/plan/process/Process'
import FormProcess from '../pages/private/plan/process/FormProcess'
import FormCompany from '../pages/private/companies/FormCompany'
import FormBranchOffice from '../pages/private/brachOffice/FormBranchOffice'
import SubProcess from '../pages/private/plan/process/subProcess/SubProcess'
import Relations from '../pages/private/plan/process/relations/Relations'
import InfoBranchOffice from '../pages/private/brachOffice/InfoBranchOffice'
import Family from '../pages/private/plan/family/Family'
import Factors from '../pages/private/plan/family/factors/Factors'
import Conditions from '../pages/private/plan/family/conditions/Conditions'
// import FormConditions from '../components/forms/family/formConditions/FormConditions'
import TaskAssignment from '../pages/private/plan/taskAssignment/TaskAssignment'
import CreateFactor from '../pages/private/plan/family/create-factor/CreateFactor'
import UpdatePdi from '../pages/private/update-pdi/UpdatePdi'
import UpdateFactor from '../pages/private/plan/family/update-factor/UpdateFactor'
import CreateCondition from '../pages/private/plan/family/create-condition/CreateCondition'
import CompaniesValidator from '../pages/private/companies/CompaniesValidator'
import ViewTaskAssignment from '../pages/private/plan/taskAssignment/ViewTaskAssignment'
import UpdateCondition from '../pages/private/plan/family/update-condition/UpdateCondition'
import ConditionsAndFactorsRelations from '../pages/private/plan/family/relation-factor-condition/ConditionsAndFactorsRelations'

// PDA
import Pdas from '../pages/private/pdas/Pdas'
import CreatePda from '../pages/private/create-pda/CreatePda'
import UpdatePda from '../pages/private/update-pda/UpdatePda'
import ValidateRoute from './validateRoute'
import { listPermissions } from './permissions'

// HACER
import Do from '../pages/private/do/Do'
import DoAssignment from '../pages/private/do/DoAssignment/DoAssignment'
import DoAssignmentIndicating from '../pages/private/do/DoAssignment/DoAssignmentIndicating'
import CheckIn from '../pages/private/do/DoAssignment/checkIn/CheckIn'

// VERIFICAR
import Verify from '../pages/private/verify/Verify'
import VerifyMeasurement from '../pages/private/verify/VerifyMeasurement/VerifyMeasurement'
import VerifyEventAssigned from '../pages/private/verify/VerifyEvent/VerifyEventAssigned'
import VerifyEvent from '../pages/private/verify/VerifyEvent/VerifyEvent'
import VerifyMeasurementProgress from '../pages/private/verify/VerifyMeasurement/VerifyMeasurementProgress'

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: '',
      index: true,
      element: <Navigate to="login" />,
    },
    {
      path: '/',
      element: <PublicRoute redirectIstLogged="home" />,
      children: [
        // LOGIN
        {
          path: 'login',
          element: <Login />,
        },
      ],
    },
    {
      path: '/',
      element: <PrivateRoute redirectIsNotLogged="login" />,
      children: [
        // HOME
        {
          path: 'home',
          element: <Home />,
        },

        // PERFIL
        {
          path: 'profile',
          element: (
            <ValidateRoute permission={[listPermissions.user.read]}>
              <Profile />
            </ValidateRoute>
          ),
        },

        // EMPRESAS
        {
          path: 'companies',
          element: (
            <ValidateRoute permission={[listPermissions.company.read]}>
              <CompaniesValidator />
            </ValidateRoute>
          ),
        },
        {
          path: 'companies/create',
          element: (
            <ValidateRoute permission={[listPermissions.company.create]}>
              <FormCompany />
            </ValidateRoute>
          ),
        },
        {
          path: 'companies/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.company.update]}>
              <FormCompany />
            </ValidateRoute>
          ),
        },

        // SUCURSALES
        {
          path: 'branchOffice',
          element: (
            <ValidateRoute permission={[listPermissions.branchOffice.read]}>
              <BranchOffice />
            </ValidateRoute>
          ),
        },
        {
          path: 'branchOffice/create',
          element: (
            <ValidateRoute permission={[listPermissions.branchOffice.create]}>
              <FormBranchOffice />
            </ValidateRoute>
          ),
        },
        {
          path: 'branchOffice/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.branchOffice.update]}>
              <FormBranchOffice />
            </ValidateRoute>
          ),
        },
        {
          path: 'branchOffice/info/:id',
          element: (
            <ValidateRoute permission={[listPermissions.branchOffice.read]}>
              <InfoBranchOffice />
            </ValidateRoute>
          ),
        },

        // USUARIOS
        {
          path: 'users',
          element: (
            <ValidateRoute permission={[listPermissions.user.read]}>
              <Users />
            </ValidateRoute>
          ),
        },
        {
          path: 'users/create',
          element: (
            <ValidateRoute permission={[listPermissions.user.create]}>
              <FormUsers />
            </ValidateRoute>
          ),
        },
        {
          path: 'users/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.user.update]}>
              <FormUsers />
            </ValidateRoute>
          ),
        },

        // AREAS
        {
          path: 'areas',
          element: (
            <ValidateRoute permission={[listPermissions.area.read]}>
              <Areas />
            </ValidateRoute>
          ),
        },
        {
          path: 'areas/create',
          element: (
            <ValidateRoute permission={[listPermissions.area.create]}>
              <FormAreas />
            </ValidateRoute>
          ),
        },
        {
          path: 'areas/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.area.update]}>
              <FormAreas />
            </ValidateRoute>
          ),
        },

        // PLANEAR
        {
          path: 'plan',
          element: <Plan />,
        },

        // PLANEAR / PDI
        {
          path: 'plan/pdis',
          element: (
            <ValidateRoute permission={[listPermissions.pdi.read]}>
              <Pdis />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/pdis/create',
          element: (
            <ValidateRoute permission={[listPermissions.pdi.create]}>
              <CreatePdi />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/pdis/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.pdi.update]}>
              <UpdatePdi />
            </ValidateRoute>
          ),
        },

        // PLANEAR / PROCESOS
        {
          path: 'plan/process',
          element: (
            <ValidateRoute permission={[listPermissions.process.read]}>
              <Process />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/process/create',
          element: (
            <ValidateRoute permission={[listPermissions.process.create]}>
              <FormProcess />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/process/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.process.update]}>
              <FormProcess />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/process/sub-process',
          element: (
            <ValidateRoute permission={[listPermissions.subProcess.read]}>
              <SubProcess />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/process/relations',
          element: (
            <ValidateRoute permission={[listPermissions.relationsProcessAndSubProcess.create]}>
              <Relations />
            </ValidateRoute>
          ),
        },

        // PLANEAR / FAMILIAS
        {
          path: 'plan/family',
          element: <Family />,
        },
        {
          path: 'plan/family/factors',
          element: (
            <ValidateRoute permission={[listPermissions.factor.read]}>
              <Factors />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/factors/create',
          element: (
            <ValidateRoute permission={[listPermissions.factor.create]}>
              <CreateFactor />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/factors/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.factor.update]}>
              <UpdateFactor />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/factors/relations',
          element: (
            <ValidateRoute permission={[listPermissions.relationsFactorsAndConditions.create]}>
              <ConditionsAndFactorsRelations />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/conditions',
          element: (
            <ValidateRoute permission={[listPermissions.condition.read]}>
              <Conditions />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/conditions/create',
          element: (
            <ValidateRoute permission={[listPermissions.condition.create]}>
              <CreateCondition />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/conditions/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.condition.update]}>
              <UpdateCondition />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/family/conditions/relations',
          element: (
            <ValidateRoute permission={[listPermissions.relationsFactorsAndConditions.create]}>
              <ConditionsAndFactorsRelations />
            </ValidateRoute>
          ),
        },

        // PLANEAR / PDA
        {
          path: 'plan/pdas',
          element: (
            <ValidateRoute permission={[listPermissions.pa.read]}>
              <Pdas />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/pdas/create',
          element: (
            <ValidateRoute permission={[listPermissions.pa.create]}>
              <CreatePda />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/pdas/update/:id',
          element: (
            <ValidateRoute permission={[listPermissions.pa.update]}>
              <UpdatePda />
            </ValidateRoute>
          ),
        },

        // PLANEAR / ASIGNACION DE TAREAS
        {
          path: 'plan/taskAssignment',
          element: (
            <ValidateRoute permission={[listPermissions.pa.create]}>
              <TaskAssignment />
            </ValidateRoute>
          ),
        },
        {
          path: 'plan/taskAssignment/assignment',
          element: (
            <ValidateRoute permission={[listPermissions.pa.read]}>
              <ViewTaskAssignment />
            </ValidateRoute>
          ),
        },

        // HACER
        {
          path: 'do',
          element: <Do />,
        },
        {
          path: 'do/assignment',
          element: (
            <ValidateRoute permission={[listPermissions.measurement.read]}>
              <DoAssignment />
            </ValidateRoute>
          ),
        },
        {
          path: 'do/assignment/indicating/:id',
          element: (
            <ValidateRoute permission={[listPermissions.measurement.create]}>
              <DoAssignmentIndicating />
            </ValidateRoute>
          ),
        },
        {
          path: 'do/assignment/indicating/:idevent/:idindicator',
          element: (
            <ValidateRoute permission={[listPermissions.measurement.create]}>
              <DoAssignmentIndicating />
            </ValidateRoute>
          ),
        },
        {
          path: 'do/assignment/indicating/checkIn/:id',
          element: (
            <ValidateRoute permission={[listPermissions.checkin.create]}>
              <CheckIn />
            </ValidateRoute>
          ),
        },

        // VERIFICAR
        {
          path: 'verify',
          element: <Verify />,
        },
        {
          path: 'verify/measurement',
          element: <VerifyMeasurement />,
        },
        {
          path: 'verify/measurement/progress/:idT/:idI',
          element: <VerifyMeasurementProgress />,
        },
        {
          path: 'verify/event',
          element: <VerifyEvent />,
        },
        {
          path: 'verify/event/eventAssigned',
          element: <VerifyEventAssigned />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate replace to="login" />,
    },
  ])

  return routes
}

export default AppRoutes
