import { FunctionComponent, ReactElement, useEffect } from "react";
import { Navigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import FullLayout from "../layouts/full-layout/FullLayout";
interface PrivateRouteProps {
    redirectIsNotLogged: string;
}
const PrivateRoute : FunctionComponent<PrivateRouteProps> = ({redirectIsNotLogged}) : ReactElement => {

    const { isAuth } = useAuth()

    return isAuth
    ? <FullLayout/>
    : <Navigate to = {redirectIsNotLogged}/>
}

export default PrivateRoute;
