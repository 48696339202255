import { Box, IconButton, Typography } from '@mui/material'
import infoProcessStyles from '../../../pages/private/plan/process/infoProcess.styles'
import IconFile from '../../icons/IconFile'
import { Area } from '../../../interfaces/area'
import { Condition, LinkedConditionIndicators } from '../../../interfaces/condition'
import { FunctionComponent } from 'react'
import IconPensil from '../../icons/IconPensil'
import IconTrash from '../../icons/IconTrash'
import { IndicatorModel } from '../../../interfaces/pdi/indicator'
import CustomDialog from '../customDialog/CustomDialog'
import { IndicatorCondition } from '../../../interfaces/indicator-condition'

export interface InfoConditionProps {
  open?: boolean
  onClose?: () => void
  condition: Condition | null
  indicatorsConditions?: IndicatorCondition[]
  onButtonUpdate?: (condition: Condition) => void
  onButtonDelete?: (condition: Condition) => void
}

const InfoCondition: FunctionComponent<InfoConditionProps> = ({
  open = false,
  condition,
  indicatorsConditions = [],
  onClose,
  onButtonUpdate,
  onButtonDelete,
}) => {

  if(!condition) return null
  return (
    <CustomDialog open={open} onClose={onClose} maxWidth="md">
      <Box sx={infoProcessStyles.container}>
        {/**************
              TITULO INFO
          ***************/}
        <Box sx={infoProcessStyles.title}>
          <Typography>Detalles de la condición</Typography>

          {/*****************
              BOTONES DE ACCIÒN
          ******************/}

          <Box>
            <IconButton onClick={() => onButtonUpdate && onButtonUpdate(condition)}>
              <IconPensil />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={() => onButtonDelete && onButtonDelete(condition)}>
              <IconTrash />
            </IconButton>
          </Box>
        </Box>

        {/*************
            CONTENIDO
        ***************/}
        <Box sx={infoProcessStyles.content}>
          {/************** 
              CONTENT 1 
          **************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                TITULO
            ***********/}
            <Typography>Nombre del área</Typography>

            {/*********
                INFO
            *********/}
            <Typography component={'span'}>{condition.idDepartament?.name}</Typography>
          </Box>

          {/*************** 
                CONTENT 2 
            ***************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                TITULO
            ***********/}
            <Typography>Nombre de la condición</Typography>

            {/*********
                INFO
            *********/}
            <Typography component={'span'}>{condition.name}</Typography>
          </Box>

        {/************** 
            CONTENT 3
        **************/}
          <Box sx={infoProcessStyles.data}>
            {/*********** 
                  TITULO
              ***********/}
            <Typography>Justificación.</Typography>

            {/*********
              INFO
            *********/}
            <Typography component={'span'}>
              <IconFile />
              <a href={condition.justificationLink} target="_blank">
                ver anexo
              </a>
            </Typography>
          </Box>

          {/************** 
              CONTENT 4
          **************/}
          {/* <Box sx={infoProcessStyles.data}> */}
          {/*********** 
              TITULO
          ***********/}
          {/* <Typography>Porcentaje de cumplimiento</Typography> */}

          {/*********
                INFO
            *********/}
          {/* <Typography component={"span"}>{linkedConditionIndicators?.justification}</Typography> */}
          {/* </Box> */}

          {/*********************************
                CONTENT 5 - CONTENEDOR LISTA
            *********************************/}
          <Box sx={infoProcessStyles.data}>
            <Box>
              {/***************
                    INDICADORES
                ****************/}
              <Box>
                <Typography>Indicadores</Typography>

                {/**********
                      LISTA
                  **********/}
                <Box>
                  {indicatorsConditions
                    .filter(i => i.idCondition?._id === condition?._id && i.idIndicator)
                    .map(rel => (
                      <Typography key={rel._id}>{rel.idIndicator?.name}</Typography>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default InfoCondition
