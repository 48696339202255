import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useTheme,
} from '@mui/material'
import { Menu } from '@mui/icons-material'
import IconPerson from '../../../components/icons/IconPerson'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'

// IMPORTADOS
import headerStyles from './Header.styles'
import InputSearch from '../../../components/InputSearch'
import IconLeave from '../../../components/icons/IconLeave'
import { useNavigate } from 'react-router-dom'
import IconCalendarCheck from '../../../components/icons/IconCalendarCheck'
import IconAssignment from '../../../components/icons/IconAssignment'
import IconMeasurement from '../../../components/icons/IconMeasurement'

const listHeader = [
  {
    text: 'Planear',
    url: 'Plan',
  },
  {
    text: 'Hacer',
    url: 'Do',
  },
  {
    text: 'Verificar',
    url: 'Verify',
  },
  {
    text: 'Actuar',
    url: '#',
  },
]

const MenuHeader = () => {
  const [state, setState] = React.useState({
    right: false,
  })

  // TEMA
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  const navigate = useNavigate()

  const toggleDrawer = (anchor: 'right', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor: 'right') => (
    <Box sx={headerStyles.drawer}>
      <InputSearch />

      <Box
        sx={{ width: 250 }}
        className="listaHeader"
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {listHeader.map(({ text, url }, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => navigate(url)}>
                {/* <ListItemIcon>
                  {text === 'Planear' && <IconCalendarCheck width={24} height={24} />}
                  {text === 'Hacer' && <IconAssignment width={24} height={24} />}
                  {text === 'Verificar' && <IconMeasurement width={24} height={24} colorBarras={secondary} />}
                  {text === 'Actuar' && <IconLeave width={24} height={24} />}
                </ListItemIcon> */}
                <ListItemText primary={text} className="modulos" />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {/************
          DIVISOR
        ************/}
        <Divider />

        <List>
          {/***********
            PERFIL 
          ***********/}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/profile')}>
              <ListItemIcon>
                <IconPerson width={24} height={24} color="#000000" />
              </ListItemIcon>
              <ListItemText primary={'Perfíl'} />
            </ListItemButton>
          </ListItem>

          {/**********
            SALIR
          **********/}
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <IconLeave width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary={'Salir'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  )

  return (
    <>
      {(['right'] as const).map(anchor => (
        <Box
          key={anchor}
          sx={{
            ...headerStyles.iconMenu,
            '@media(min-width: 1230px)': {
              display: 'none',
            },
          }}
        >
          <IconButton disableTouchRipple onClick={toggleDrawer(anchor, true)}>
            <Menu sx={{ '>path': { color: '#1F398F' } }} />
          </IconButton>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </Box>
      ))}
    </>
  )
}

export default MenuHeader
