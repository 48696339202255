import React, { useContext } from 'react'
import TableIndicatorGoalsPda from '../../../tables/pda/tableIndicatorsGoalsPda/TableIndicatorGoalsPda'
import { PdaContext } from '../../../context/PdaProvider'
import { Box, Button, Typography } from '@mui/material'
import formStyles from '../../../forms/form.styles'

const TabPanelIndicatorsPda = () => {
  const pdaContext = useContext(PdaContext)

  return (
    <>
      <Box>
        <Box sx={formStyles.container}>
          {/****************
                TITULO
              *****************/}
          <Box sx={formStyles.buttonTitle}>
            {/***************
                  TITULO META PDA
                ****************/}
            <Typography sx={{ fontSize: '25px' }}>Indicadores relacionados con las metas del plan de acción </Typography>

            <Box>
              <Button disableTouchRipple>
                <Typography>Metas</Typography>
              </Button>
            </Box>
          </Box>

          <TableIndicatorGoalsPda indicators={pdaContext?.goalPdaIndicatorList?.items ?? []} />
        </Box>
      </Box>
    </>
  )
}

export default TabPanelIndicatorsPda
