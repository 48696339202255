import { FunctionComponent, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import TabManagerPda from '../../../components/tabs/tabManagerPda/tabManagerPda'
import PdaProvider, { PdaContext } from '../../../components/context/PdaProvider'

const CreatePda: FunctionComponent = () => {
  const navigate = useNavigate()
  return (
    <PdaProvider>
      <TabManagerPda />
    </PdaProvider>
  )
}

export default CreatePda
