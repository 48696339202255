import { useCallback, useState } from 'react'
import useFetch, { BasicFetchListProps } from './useFetch'
import GenericAlert, { GenericAlertProps } from '../components/Alerts/GenericAlert/GenericAlert'
import useAppDialog from './useAppDialog'

const useTasks = () => {
  const { fetchFindOne, fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()
  const { showLoadingDialog, showSuccessDialog, showErrorDialog } = useAppDialog()

  const getPdiActive = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchFindOne<any>({
        action: 'getPdiActive',
        findId: '',
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )
  const fetchGetTaskAssigment = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getTaskAssigment',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const fetchCreateTaskAssignment = useCallback(
    async (body: any, done?: () => void) => {
      showLoadingDialog()
      return await fetchCreate({ action: 'createTasks', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Asiganción creada exitosamente!.',
            didClose: done,
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchCreate]
  )

  const fetchUpdateTaskAssignment = useCallback(
    async (body: any, done?: () => void) => {
      showLoadingDialog()
      return await fetchUpdate({ action: 'updateTaskAssignment', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Asiganción actualizada exitosamente!.',
            didClose: done,
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchUpdate]
  )

  const fetchDeleteTaskAssignment = useCallback(
    async (body: any, done?: () => void) => {
      showLoadingDialog({ text: 'Cargando por favor espere...' })
      return await fetchDisable({ action: 'disableTaskAssigment', body })
        .then(() => {
          showSuccessDialog({
            text: 'Asiganción eliminada exitosamente!.',
            didClose: done,
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchDisable]
  )

  return {
    getPdiActive,
    fetchCreateTaskAssignment,
    fetchUpdateTaskAssignment,
    fetchGetTaskAssigment,
    fetchDeleteTaskAssignment,
  }
}

export default useTasks
