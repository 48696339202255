import React, { useState } from 'react'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { dateToString, getWeekDayName } from '../../../services/ParseDate'
import IconCalendar from '../../../components/icons/IconCalendar'

interface ListDiaryProps {
  specialDates?: string[]
  setSelectDate?: Function
  infoAgenda?: any[]
  name?: string
  urlToRedirect?: string
}

const ListDiary = ({ specialDates, setSelectDate, infoAgenda = [], name, urlToRedirect }: ListDiaryProps) => {
  const navigate = useNavigate()

  const [selectedDay, setSelectedDay] = useState<any>(null)

  const handleClick = (day: any) => {
    if (selectedDay && dateToString(day.$d) === dateToString(selectedDay[0]?.$d)) {
      setSelectedDay(null)
      setSelectDate && setSelectDate(null)
    } else {
      setSelectedDay([day])
      setSelectDate && setSelectDate(day.$d)
    }
  }

  const theme: any = useTheme()
  const primary = theme.palette.primary.main
  const secondary = theme.palette.secondary.main

  const renderDay = (day: any, selectedDays: any[], pickersDayProps: any) => {
    const isCustomBorderDate = specialDates?.includes(day.toISOString().split('T')[0])
    const isSelected =
      selectedDay?.some((item: any) => dateToString(item.$d) === dateToString(day.$d)) ??
      selectedDays.some((item: any) => dateToString(item.$d) === dateToString(day.$d))
    const isToday = selectedDays.some((item: any) => dateToString(item.$d) === dateToString(day.$d))
    const isNotThisMonth = pickersDayProps.outsideCurrentMonth

    let dayStyles: any = {
      cursor: 'pointer',
      textAlign: 'center',
    }

    if (isCustomBorderDate) {
      dayStyles.color = secondary // Agregar borde a las fechas personalizadas
      dayStyles.fontWeight = 'bold'
    }

    if (isSelected) {
      dayStyles.background = primary // Agregar borde a las fechas personalizadas
      dayStyles.color = '#fff'
    }

    if (isToday) {
      dayStyles.border = `1px Solid ${secondary}`
    }

    if (isNotThisMonth) {
      dayStyles.color = '#cacaca'
      dayStyles.cursor = 'default'
    }

    return (
      <div
        key={day.$d}
        style={{
          ...dayStyles,
          /* Estilos adicionales según sea necesario */
        }}
        onClick={() => {
          !isNotThisMonth && handleClick(day)
        }}
      >
        {day.$D}
      </div>
    )
  }

  return (
    // CONTENEDOR AGENDA
    <Box className="contentListDiary">
      <Box className="contentListDiary">
        <Box
          className="titleListDiary"
          sx={{
            // backgroundColor: 'aqua',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            '>p': { marginLeft: '12px' },
          }}
        >
          <Box>
            <IconCalendar />
          </Box>

          <Typography>Eventos de hoy</Typography>
        </Box>

        {/**********************
          CONTENEDOR ITERADO
        ************************/}
        {infoAgenda.length > 0 ? (
          infoAgenda.map((item: any, index) => (
            <Box
              className="scale"
              key={index + item._id}
              sx={{
                // backgroundColor: "purple",
                display: 'flex',
                paddingRight: '5px',
                height: '45px',
                marginTop: '6px',
                marginBottom: '7px',
                cursor: urlToRedirect ? 'pointer' : 'default',

                // RESPONSIVE
                '@media (max-width: 490px)': {
                  height: 'auto',
                },
              }}
              onClick={() => {
                urlToRedirect && navigate(`${urlToRedirect}/${item._id}`)
              }}
            >
              {/******************************
              LATERAL DE COLOR ALTERNADO
            *******************************/}
              <Box
                sx={{
                  backgroundColor: index % 2 ? '#1F398F' : theme => theme.palette.secondary.main,
                  width: '9px',
                  height: '38px',
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                  margin: 'auto 0px auto 0px',
                  // ":before": {
                  //     counterIncrement: infoAgenda.length,
                  //     // content: infoAgenda.length,
                  //     content: infoAgenda,
                  // },
                  // ":nth-child(2n+1)": {
                  //     backgroundColor: "red",
                  // },
                  // ":nth-child(2n)": {
                  //     backgroundColor: "blue",
                  // },
                }}
              ></Box>

              {/********************
              CONTENEDOR TAREA
            **********************/}
              <Box
                sx={{
                  // backgroundColor: "blue",
                  backgroundColor: '#ffffff',
                  display: 'flex',
                  width: '100%',
                  borderRadius: '7px',
                  boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.08)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  {/**************** 
                  NUMERO Y DIA
                *****************/}
                  <Box
                    sx={{
                      width: '62px',
                      textAlign: 'center',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      '>p': {
                        fontSize: '15px',
                        fontWeight: '600',
                        lineHeight: '15px',
                        '>span': {
                          fontSize: '10px',
                          fontWeight: '300',
                          lineHeight: '12px',
                          display: 'block',
                        },
                      },
                    }}
                  >
                    <Typography component="p">
                      {item.startDate.split('T')[0].split('-')[2]}

                      <Typography component="span">
                        {getWeekDayName(item.startDate.split('T')[0]).substring(0, 2)}
                      </Typography>
                    </Typography>
                  </Box>

                  {/***********
                  DIVISOR
                ************/}
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: '37px',
                      margin: 'auto 0',
                      borderColor: '#cacaca',
                    }}
                  />

                  {/*******************
                  FECHA Y DETALLE
                ********************/}
                  <Box
                    sx={{
                      paddingLeft: '21px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      '>p': {
                        fontSize: '8px',
                        fontWeight: '300',
                        '>span': {
                          fontSize: '10px',
                          fontWeight: '600',
                          display: 'block',
                        },
                      },
                    }}
                  >
                    <Typography component="p">
                      {item.startDate.split('T')[0]}
                      <Typography component="span">{item.idIndicator?.name}</Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography component="p" sx={{ color: '#9d9d9d' }}>
              No tienes pendientes para{' '}
              <span>
                {!selectedDay || dateToString(selectedDay[0]?.$d) === dateToString(new Date()) ? 'hoy' : 'esta fecha'}
              </span>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ListDiary
