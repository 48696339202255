import { FunctionComponent, JSXElementConstructor, ReactElement } from 'react'
import { Box, Typography, Button } from '@mui/material'
import IconArrowRight from './icons/IconArrowRight'
import customTitleStyles from './customTitle.styles'

interface CustomTitleProps {
  title?: string
  subTitle?: string
  display?: string | null
  titleChildren?: ReactElement<any, string | JSXElementConstructor<any>> | null
  children?: ReactElement<any, string | JSXElementConstructor<any>> | null
}

const CustomTitle: FunctionComponent<CustomTitleProps> = ({
  title = 'Titulo',
  subTitle = 'Subtitulo',
  display = null,
  titleChildren,
  children,
}) => {
  return (
    <>
      {/*********************/}
      {/* CONTENEDOR TITULO */}
      {/*********************/}
      <Box sx={customTitleStyles.containerTitle} className="customTitle">
        <Box
          sx={{ ...customTitleStyles.title, '>svg, >button': { display: { display } } }}
          className="customTitleTitle"
        >
          {/**********/}
          {/* TITULO */}
          {/**********/}
          <Typography>{title}</Typography>

          {/******************/}
          {/* FLECHA DERECHA */}
          {/******************/}
          <IconArrowRight width={11} height={22} />

          {/*******************/}
          {/* BOTON SUBTITULO */}
          {/*******************/}
          <Button disableTouchRipple>
            <Typography>{subTitle}</Typography>
          </Button>

          {/*****************/}
          {/* TITULOS HIJOS */}
          {/*****************/}
          <Box>{(titleChildren ??= null)}</Box>
        </Box>

        {/*****************/}
        {/* OBJETOS HIJOS */}
        {/*****************/}
        <Box sx={customTitleStyles.buttons} className="customTitleButtons">
          {(children ??= null)}
        </Box>
      </Box>
    </>
  )
}

export default CustomTitle
