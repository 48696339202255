import { Box } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from 'chart.js'
import { FC } from 'react'
import { Chart } from 'react-chartjs-2'
import { dateToString } from '../../services/ParseDate'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
)

interface CustomGraphicBarProps {
  valueTs: number
  valueTi: number
  labelsApi: any[]
  meta: number
  valuesApi: any[]
}

// ****************
// GRAFICA DE BARRA
// ****************
const CustomGraphicBar: FC<CustomGraphicBarProps> = ({ valueTs, valueTi, meta, labelsApi, valuesApi}) => {
  // ******************
  // OPTIONS DE GRAFICA
  // ******************
  const options: any = {
    type: 'bar',
    responsive: true,
    plugins: {
      // **********************
      // TITULOS DE DESCRIPCION
      // **********************
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: '',
      },
    },
    // *******************
    // ESCALA AL 100% EN Y
    // *******************
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: meta,
        ticks: {
          callback: function (value: string) {
            return value 
          },
        },
      },
    },
  }

  // **********
  // DATOS EN X
  // **********
  const labels = labelsApi

  // *****
  // DATOS
  // *****
  const values = valuesApi

  // *******************
  // TOLERANCIA SUPERIOR
  // *******************
  const dataTs = labels.map(() => valueTs)

  // *******************
  // TOLERANCIA INFERIOR
  // *******************
  const dataTi = labels.map(() => valueTi)

  // **************************
  // PROMEDIO ENTRE TOLERANCIAS
  // **************************
  const sumaPro = valueTs + valueTi
  const resulPro = sumaPro / 2
  const promedio = labels.map(() => resulPro)

  const data = {
    labels,
    datasets: [
      // *****************
      // DATOS DE LA TABLA
      // *****************
      {
        type: 'bar' as const,
        label: 'Meta',
        data: values,
        backgroundColor: '#1F398F',
        borderColor: '#1F398F',
        borderWidth: 3,
      },
      // *******************
      // TOLERANCIA SUPERIOR
      // *******************
      {
        type: 'line' as const,
        label: 'Tolerancia Superior',
        data: dataTs,
        backgroundColor: 'transparent',
        borderColor: '#7EBD52',
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderDash: [5, 5],
      },
      // ********
      // PROMEDIO
      // ********
      {
        type: 'line' as const,
        label: 'Promedio',
        data: promedio,
        backgroundColor: 'transparent',
        borderColor: '#FCCC64',
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderDash: [5, 5],
      },
      // *******************
      // TOLERANCIA INFERIOR
      // *******************
      {
        type: 'line' as const,
        label: 'Tolerancia Inferior',
        data: dataTi,
        backgroundColor: 'transparent',
        borderColor: '#D94A4A',
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderDash: [5, 5],
      },
    ],
  }

  return (
    <Box>
      <Chart type="bar" data={data} options={options} />
    </Box>
  )
}

export default CustomGraphicBar
