import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import tableItemsPdiStyles from '../../pdi/tableItemsPdiStyles'
import IconDirection from '../../../icons/IconDirection'
import { PdaContext } from '../../../context/PdaProvider'
import ConfirmAlert from '../../../Alerts/confirmAlert/ConfirmAlert'
import CardItemPda from '../../../cards/cardItemPda/cardItemPda'
import ModalDetailItemPda from '../../../modals/modalDetailItemPda/ModalDetailItemPda'
import { useNavigate } from 'react-router-dom'
import { fa } from '@faker-js/faker'

interface TableIndicatorGoalsPdaProps {
  indicators: any[]
}

const TableIndicatorGoalsPda = ({ indicators }: TableIndicatorGoalsPdaProps) => {
  const pdaContext = useContext(PdaContext)
  const navigate = useNavigate()
  const [modalDetailData, setModalDetailData] = useState<any>({
    open: false,
    itemsRender: [],
  })

  const [confirmAlertData, setConfirmAlertData] = useState<any>({
    show: false,
    confirmMessage: 'Estas seguro que deseas eliminar esta meta?',
    confirmMessageBottom: 'Recuerde que si elimina esta meta usted no podrá editarla',
    textButtonActionConfirm: 'Si',
    textButtonActionCancel: 'No',
  })

  const handleModalDetailData = (indicator: any, itemsRender: any[]) => {
    console.log(indicator)
    setModalDetailData({
      open: true,
      itemsRender,
      onClose: () => setModalDetailData((state: any) => ({ ...state, itemsRender: [], open: false })),
    })
  }

  const handleConfirmAlertData = (indicator: any) => {
    setConfirmAlertData((state: any) => ({
      ...state,
      show: true,
      onClickButtonActionConfirm: async () => {
        setConfirmAlertData((state: any) => ({ ...state, show: false }))
        await pdaContext?.deleteIndicatorPa({ _id: indicator._id })
        if (pdaContext?.pda?._id) {
          pdaContext?.getInitialDataForUpdatePda({ findId: pdaContext?.pda?._id })
        }
      },
    }))
  }

  return (
    <>
      <ConfirmAlert
        {...confirmAlertData}
        onClickButtonActionCancel={() => setConfirmAlertData((state: any) => ({ ...state, show: false }))}
        onCloseForButtom={() => setConfirmAlertData((state: any) => ({ ...state, show: false }))}
      />
      <ModalDetailItemPda maxWidth={'md'} textButtonAction="Editar" title="Detalles de la meta" {...modalDetailData} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            ...tableItemsPdiStyles.container,
            justifyContent: indicators.length === 2 ? 'center !important' : 'space-around',
          }}
        >
          {/*****************
            CARD METAS
          ******************/}
          {indicators.map((indicator: any) => (
            <CardItemPda
              key={`${indicator._id}`}
              title={indicator?.idIndicator?.indicator[0]?.name}
              text={indicator?.idGoalPa?.title}
              smallText={indicator?.idIndicator?.indicator[0]?.state ? 'Activo' : 'Inactivo'}
              showButtonLeft={false}
              showButtonRight={false}
              textButtonDetail={<IconDirection />}
              onClickButtonDetail={() =>
                handleModalDetailData(indicator, [
                  {
                    title: 'Nombre de la meta',
                    value: indicator?.idGoalPa?.title,
                  },
                  {
                    title: 'Nombre',
                    value: indicator?.idIndicator?.indicator[0]?.name,
                  },
                  {
                    title: 'Tipo de indicador',
                    value: indicator?.idIndicator?.indicator[0]?.idType?.name,
                  },
                  {
                    title: 'Tendencia',
                    value: indicator?.idIndicator?.indicator[0]?.idTrend?.name,
                  },
                  {
                    title: 'Unidad de medida',
                    value: indicator?.idIndicator?.indicator[0]?.idMeasureUnit?.name,
                  },
                  {
                    title: 'Frecuencia de medición',
                    value: indicator?.idIndicator?.indicator[0]?.idMeasurementFrecuency?.name,
                  },
                  {
                    title: 'Meta',
                    value: indicator?.idIndicator?.goal,
                  },
                  {
                    title: 'Tolerancia superior',
                    value: indicator.idIndicator?.toleranceUpper,
                  },
                  {
                    title: 'Tolerancia inferior',
                    value: indicator.idIndicator?.toleranceLower,
                  },
                  {
                    title: 'Formula',
                    value: indicator?.idIndicator?.indicator[0]?.formula,
                  },
                  {
                    title: 'Observaciones',
                    value: indicator?.idIndicator?.indicator[0]?.observation,
                  },
                  {
                    title: 'Estado',
                    value: indicator?.idIndicator?.indicator[0]?.state ? 'Activo' : 'Inactivo',
                  },
                ])
              }
            />
          ))}
          {indicators.length === 1 && (
            <>
              <Box />
              <Box />
            </>
          )}
          {indicators.length === 2 && <Box />}
        </Box>
      </Box>
    </>
  )
}

export default TableIndicatorGoalsPda
