export const dateToString = (date: any) => {
  const _date = new Date(date)

  const year = _date.getFullYear()
  let month = _date.getMonth() + 1
  let day = _date.getDate()

  return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
}

export const dateToStringReverse = (date: any) => {
  const _date = new Date(date)

  const year = _date.getFullYear()
  let month = _date.getMonth() + 1
  let day = _date.getDate()

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

export const getWeekDayName = (date: string) => {
  const WeeKDayNames = ['DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO']
  const change: any = date.split('-')
  const parseDate = new Date(change[0], change[1] - 1, change[2])
  const index = parseDate.getDay()
  return WeeKDayNames[index]
}
