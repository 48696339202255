import { useTheme } from '@mui/material'
import { FunctionComponent } from 'react'

interface IconAssignmentProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconAssignment: FunctionComponent<IconAssignmentProps> = ({ width = 134, height = 130, color = '#FCCC64' }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 134 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6842 109.474V13.6842H61.5789V61.5789H109.474V68.4211C114.263 68.4211 118.847 69.3105 123.158 70.8158V41.0526L82.1053 0H13.6842C6.08947 0 0 6.08947 0 13.6842V109.474C0 113.103 1.44172 116.584 4.00801 119.15C6.5743 121.716 10.0549 123.158 13.6842 123.158H70.8158C69.3105 118.847 68.4211 114.263 68.4211 109.474H13.6842ZM75.2632 10.2632L112.895 47.8947H75.2632V10.2632ZM133.421 97.5L100.921 130L82.1053 109.474L90.0421 101.537L100.921 112.416L125.484 87.8526L133.421 97.5Z"
        fill={secondary ?? color}
      />
    </svg>
  )
}

export default IconAssignment
