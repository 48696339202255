import { useCallback, useEffect, useState } from 'react'

export interface PaginationData<T extends {}> {
  countPage: number
  prev: () => void
  currentPage: number
  currentData: () => T[]
  handleChangePage: (page: number) => void
}

const usePagination = <T extends {}>(items: T[], itemsPerPage: number) => {
  const countPage = Math.ceil(items.length / itemsPerPage)

  const [currentPage, setCurrentPage] = useState(1)

  const currentData = useCallback(() => {
    const begin = (currentPage - 1) * itemsPerPage
    const end = begin + itemsPerPage
    return items.slice(begin, end)
  }, [itemsPerPage, currentPage, items])

  const next = () => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, countPage))
  }

  const prev = () => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1))
  }

  useEffect(() => {
    const countCurrentData = currentData().length
    // if(countCurrentData <= itemsPerPage && currentPage ===1) return handleChangePage(1)
    if (currentPage > 1 && countCurrentData === 0) return prev()
  }, [items, currentPage, currentData])

  const handleChangePage = (page: number) => {
    const pageNumber = Math.max(1, page)
    setCurrentPage(currentPage => Math.min(pageNumber, countPage))
  }

  return { next, prev, handleChangePage, currentData, currentPage, countPage }
}

export default usePagination
