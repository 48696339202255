import { FunctionComponent, useEffect } from 'react'
import { Box, Button, InputBase, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PdiModel } from '../../../../interfaces/pdi/pdi'
import { FormPoliticsValues } from '../../../../interfaces/pdi/politics'
import formPoliticsStyles from './formPolitics.styles'
import ErrorField from '../../../ErrorField'
import { formPoliticsSchema } from '../../../../schemas/pdi/politicsSchema'
import formStyles from '../../form.styles'

interface FormPoliticsProps {
  pdi: PdiModel
  viewMode?: 'create' | 'update'
  defaultValues?: Partial<FormPoliticsValues>
  onSubmit: (formValues: FormPoliticsValues, reset: () => void) => void
  onCancel: () => void
}

const _defaultValues: Partial<FormPoliticsValues> = {
  title: '',
  subTitle: '',
  description: '',
  link: '',
}

const FormPolitics: FunctionComponent<FormPoliticsProps> = ({
  pdi,
  defaultValues = _defaultValues,
  viewMode = 'create',
  onSubmit,
  onCancel,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormPoliticsValues>({
    mode: 'onChange',
    resolver: yupResolver(formPoliticsSchema),
    defaultValues: { ...defaultValues, idPdi: pdi._id },
  })

  const getFixInitialValues = (): Partial<FormPoliticsValues> => {
    const { _id } = pdi
    return { ...defaultValues, idPdi: _id }
  }
  
  useEffect(() => {
    const fixDefaultValues = getFixInitialValues()
    reset(fixDefaultValues)
  }, [defaultValues])

  const submit: SubmitHandler<FormPoliticsValues> = formValues =>
    onSubmit(formValues, () => reset({ ..._defaultValues, idPdi: pdi._id }))

  return (
    <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
      {/**************************
                    NOMBRE PDI REGISTRADO
                ****************************/}
      <Box
        sx={{
          ...formStyles.containerTitle,
          width: '370px',
          maxWidth: '370px',
          marginRight: '39px',
        }}
      >
        <Typography sx={formStyles.title}>Nombre PDI</Typography>

        <Typography component={'span'} sx={formStyles.span}>
          {pdi.title}
        </Typography>
      </Box>

      {/****************
                TITULO
            *****************/}
      <Box sx={formStyles.containerTitle}>
        <Typography sx={formStyles.title}>Título</Typography>

        {/**********
                    INPUT
                **********/}
        <Box sx={formStyles.input}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
          />
        </Box>

        {/*********************
                    MENSAJE DE ERROR
                *********************/}
        <ErrorField message={errors.title?.message} />
      </Box>

      {/****************
                SUBTITULO
            *****************/}
      <Box sx={formStyles.containerTitle}>
        <Typography sx={formStyles.title}>Subtítulo</Typography>

        {/**********
                    INPUT
                **********/}
        <Box sx={formStyles.input}>
          <Controller
            name="subTitle"
            control={control}
            render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
          />
        </Box>

        {/*********************
                    MENSAJE DE ERROR
                *********************/}
        <ErrorField message={errors.subTitle?.message} />
      </Box>

      {/****************
                DESCRIPCION
            *****************/}
      <Box sx={formStyles.containerTitle}>
        <Typography sx={formStyles.title}>Descripción</Typography>

        {/**********
                    INPUT
                **********/}
        <Box sx={formStyles.inputDescription}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => <InputBase {...field} maxRows={5} multiline sx={formStyles.inputBaseDescription} />}
          />
        </Box>

        {/*********************
                    MENSAJE DE ERROR
                *********************/}
        <ErrorField message={errors.description?.message} />
      </Box>

      {/*****************************
                CONTAINER ORDEN / ENLACE
            ******************************/}
      <Box sx={formStyles.containerFlex}>
        {/*********************
                    CONTAINER ENLACE
                *********************/}
        <Box sx={formPoliticsStyles.containerEnlace}>
          <Typography sx={formStyles.title}>Enlace</Typography>

          {/**********
                        INPUT
                    **********/}
          <Box sx={formPoliticsStyles.inputOrdenEnlace}>
            <Controller
              name="link"
              control={control}
              render={({ field }) => <InputBase {...field} sx={formPoliticsStyles.inputBaseOrdenEnlace} />}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.link?.message} />
        </Box>
      </Box>

      {/***********
                    ESTADO 
                ************/}
      {viewMode === 'update' && (
        <>
          <Box sx={formStyles.containerState}>
            <Typography sx={formStyles.title}>Estado</Typography>

            {/*********************************
                            CONTENEDOR CHECKBOX DE ESTADO
                        *********************************/}
            <FormGroup>
              {/************************
                                CHECKBOX DE ESTADO
                            ************************/}
              <FormControlLabel
                control={
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Checkbox
                        {...field}
                        checked={value === true ? true : false}
                        onChange={({ target: { checked } }) => onChange(checked)}
                        disableTouchRipple
                      />
                    )}
                  />
                }
                label="Activo"
                labelPlacement="start"
              />

              <FormControlLabel
                className="inactivo"
                control={
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Checkbox
                        {...field}
                        checked={value === false ? true : false}
                        onChange={({ target: { checked } }) => onChange(!checked)}
                        disableTouchRipple
                      />
                    )}
                  />
                }
                label="Inactivo"
                labelPlacement="start"
              />
            </FormGroup>
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.state?.message} />
        </>
      )}

      {/**********************
                CONTENEDOR BOTONES 
            ***********************/}
      <Box sx={formStyles.divButton}>
        {/**********************************
                    BOTONES ACTUALIZAR/DESACTIVAR
                ***********************************/}
        <div>
          {/********************
                        BOTON ACTUALIZAR
                    *********************/}
          <Button type="submit" disabled={!isValid} disableTouchRipple sx={formStyles.buttonSave}>
            Guardar
          </Button>

          {/********************
                        BOTON DESACTIVAR
                    *********************/}
          <Button disableTouchRipple onClick={onCancel} sx={formStyles.buttonCancel}>
            Cancelar
          </Button>
        </div>
      </Box>
    </Box>
  )
}

export default FormPolitics
