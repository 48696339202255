import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys = 'modal' | 'opaqueBackground' | 'card' | 'closeButton'
const customDialogStyles: StyleComponent<Keys> = {
  modal: {
    overflow: 'show',
    display: 'flex',
  },
  opaqueBackground: {
    backgroundColor: '#ffffff',
    opacity: 0.7,
  },
  card: {
    backgroundColor: '#fff',
  },
  closeButton: {
    width: 15,
    height: 15,
    position: 'absolute',
    right: 28,
    top: 28,
    cursor: 'pointer',
    ':hover': {
      transitionDuration: '0.2s',
      width: 14,
      height: 14,
    },
  },
}

export default customDialogStyles
