import {useCallback} from 'react'

import useFetch from "../useFetch"

interface FetchTypesProps {
    limit? : number
    from? : number
    active? : boolean
}

const useTypesObjetives = () => {

    const {fetchList} = useFetch()

    const fetchTypes = useCallback( async ({ limit = 0, from = 0, active = true } : FetchTypesProps = {}) => {
        return fetchList<any>({
            action : 'getTypeObjetives',
        })
    }, [fetchList])

    return {
        fetchTypes,
    }
}

export default useTypesObjetives;