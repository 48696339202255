import { FunctionComponent } from "react";

interface IconTrashProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconTrash: FunctionComponent<IconTrashProps> = ({
    width = 15,
    height = 15,
    color = "#F24E1E",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3299_1342)">
                <path
                    d="M9.4 4.35142e-08C9.7498 8.16448e-05 10.0907 0.0992142 10.3744 0.283356C10.6581 0.467497 10.8703 0.727313 10.9808 1.026L11.4333 2.25H14.1667C14.3877 2.25 14.5996 2.32902 14.7559 2.46967C14.9122 2.61032 15 2.80109 15 3C15 3.19891 14.9122 3.38968 14.7559 3.53033C14.5996 3.67098 14.3877 3.75 14.1667 3.75L14.1642 3.80325L13.4417 12.9105C13.3966 13.478 13.1143 14.009 12.6517 14.3967C12.1891 14.7844 11.5805 14.9999 10.9483 15H4.05167C3.41955 14.9999 2.81092 14.7844 2.34831 14.3967C1.88569 14.009 1.60342 13.478 1.55833 12.9105L0.835833 3.8025C0.834325 3.78503 0.833492 3.76752 0.833333 3.75C0.61232 3.75 0.400358 3.67098 0.244078 3.53033C0.0877973 3.38968 0 3.19891 0 3C0 2.80109 0.0877973 2.61032 0.244078 2.46967C0.400358 2.32902 0.61232 2.25 0.833333 2.25H3.56667L4.01917 1.026C4.12975 0.727191 4.34203 0.467289 4.62592 0.283136C4.9098 0.0989837 5.25089 -7.57959e-05 5.60083 4.35142e-08H9.4ZM12.4975 3.75H2.5025L3.22083 12.8032C3.23579 12.9924 3.32981 13.1694 3.48397 13.2987C3.63812 13.428 3.84096 13.4999 4.05167 13.5H10.9483C11.159 13.4999 11.3619 13.428 11.516 13.2987C11.6702 13.1694 11.7642 12.9924 11.7792 12.8032L12.4975 3.75ZM5.83333 6C6.03744 6.00002 6.23445 6.06747 6.38698 6.18954C6.53951 6.31161 6.63695 6.47981 6.66083 6.66225L6.66667 6.75V10.5C6.66643 10.6912 6.5851 10.875 6.43929 11.014C6.29349 11.153 6.09421 11.2367 5.88217 11.2479C5.67014 11.2591 5.46135 11.197 5.29847 11.0743C5.1356 10.9516 5.03092 10.7776 5.00583 10.5878L5 10.5V6.75C5 6.55109 5.0878 6.36032 5.24408 6.21967C5.40036 6.07902 5.61232 6 5.83333 6ZM9.16667 6C9.38768 6 9.59964 6.07902 9.75592 6.21967C9.9122 6.36032 10 6.55109 10 6.75V10.5C10 10.6989 9.9122 10.8897 9.75592 11.0303C9.59964 11.171 9.38768 11.25 9.16667 11.25C8.94565 11.25 8.73369 11.171 8.57741 11.0303C8.42113 10.8897 8.33333 10.6989 8.33333 10.5V6.75C8.33333 6.55109 8.42113 6.36032 8.57741 6.21967C8.73369 6.07902 8.94565 6 9.16667 6ZM9.4 1.5H5.6L5.3225 2.25H9.6775L9.4 1.5Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3299_1342">
                    <rect width={width} height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconTrash;
