import { FunctionComponent, useEffect } from "react";
import { Box } from "@mui/material";

// IMPORTADOS
import TablePdis from "../../../components/tables/pdi/tablePdis/TablePdis";
import CustomTitleButtonAdd from "../../../components/CustomTitleButtonAdd";
import { ChangeActivePdi, Pdi } from "../../../interfaces/pdi/pdi";
import { useNavigate } from "react-router-dom";
import usePdi from "../../../hooks/pdi/usePdi";

const Pdis: FunctionComponent = () => {

    const navigate = useNavigate();

    const { fetchPdis, fetchDisablePdi, listPdis: { items : pdis }, ViewAlert } = usePdi()

    useEffect(() => {
        fetchPdis()
    }, [])

    const disable = ({ _id }: Pdi) => {
        const changeActive: ChangeActivePdi = { _id, is_active: false }
        fetchDisablePdi(changeActive)
    };
 
    const update = ({ _id }: Pdi) => {
        navigate(`./update/${_id}`);
    };

    return (
        <>
            <ViewAlert/>
            {/* CONTENEDOR PDI */}
            <Box
                sx={{
                    backgroundColor: "#ffffff",
                    // maxWidth : '1530px',
                    // m : 'auto'
                    minHeight: "52.7vh",
                }}
            >
                {/**************************
                    CONTENEDOR TITULO PDI 
                ***************************/}
                <CustomTitleButtonAdd navigateUrl="./create" />

                {/**************
                    TABLA PDI
                ***************/}
                <TablePdis
                    pdis = {pdis}
                    onUpdate={update}
                    onDisable={disable}
                />
            </Box>
        </>
    );
};

export default Pdis;
