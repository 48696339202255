import { useCallback } from 'react'
import useFetch from '../useFetch'
import useAppDialog from '../useAppDialog'

const useFormula = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchFindOne } = useFetch()
  const { showErrorDialog, showSuccessDialog, showLoadingDialog } = useAppDialog()

  const fetchCreateFormula = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      return await fetchCreate({ action: 'createFormula', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Medicion creada exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchCreate]
  )

  const fetchUpdateFormula = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      return await fetchUpdate({ action: 'updateFormula', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Medicion actualizada exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchUpdate]
  )

  const fetchCheckin = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchUpdate({ action: 'checkin', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Medicion actualizada exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchUpdate]
  )

  const fetchGetFormula = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getFormulas',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchGetOneFormula = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getFormula',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchGetStatus = useCallback(async () => {
    return fetchList<any>({
      action: 'getStatus',
    })
      .then(res => {
        return res
      })
      .catch(error => {
        showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
      })
  }, [fetchList])

  const fetchGetAcumulative = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getAcumulative',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchGetAcumulativePdi = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getAcumulativePdi',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  return {
    fetchCreateFormula,
    fetchUpdateFormula,
    fetchCheckin,
    fetchGetFormula,
    fetchGetOneFormula,
    fetchGetStatus,
    fetchGetAcumulative,
    fetchGetAcumulativePdi,
  }
}

export default useFormula
