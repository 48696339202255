import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys = 'subHeader'

const subHeaderStyles: StyleComponent<Keys> = {
  subHeader: {
    '>div': {
      display: 'flex',
      margin: '42px auto 55px auto',

      // RESPONSIVE
      '@media(max-width:1230px)': {
        flexDirection: 'column-reverse',
        alignItems: 'center',

        '>.subHeader1': {
          marginRight: 'auto',
          '>.containerButton': {
            textAlign: 'center',
          },
        },
        '>.subHeader2': {
          marginBottom: '20px',
        },
      },

      // SUBHEADER 1
      '>.subHeader1': {
        display: 'flex',
        marginLeft: 'auto',

        // BOTON
        '>.containerButton>a': {
          textDecoration: 'none',
        },
        '>.containerButton>a>button': {
          backgroundColor: '#7282B81A',
          color: '#1F398F',
          textAlign: 'center',
          textTransform: 'capitalize',
          alignItems: 'center',
          alignContent: 'center',
          borderRadius: '92px',
          boxShadow: 'none',
          paddingX: '17px',
          marginLeft: '18px',

          // HOVER BOTON EMPRESAS - ENTERPRISE
          ':hover': {
            '&>p': {
              color: '#1F398F',
              fontWeight: '600',
            },
          },

          // RESPONSIVE
          '@media(max-width:1400px)': {
            width: '13vw',
          },

          '@media(max-width: 858px)': {
            width: 'inherit',
            backgroundColor: 'transparent',
          },

          // PARRAFO DE BOTON
          '>p': {
            color: '#1F398F',
            display: 'inline',
            fontSize: '16px',
            fontWeight: '400',
            ':hover': {
              color: '#1F398F',
              fontWeight: '600',
            },
          },
        },

        // BOTON SOMBREADO
        '>.containerButton>.botonSombreado>p': {
          backgroundColor: '#ffffff',
          padding: '5px 15px',
          borderRadius: '92px',
          filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',
          ':hover': {
            backgroundColor: '#7282B8',
            border: '1px solid #7282B8',
            color: '#ffffff',
          },
        },

        '&>.containerButton>div>div': {
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        },
      },

      // SUBHEADER 2
      '>.subHeader2': {
        display: 'flex',

        '>.containerLocation': {
          display: 'flex',
          paddingRight: '105px',
          marginLeft: '50px',

          // RESPONSIVE
          '@media (max-width: 1390px)': {
            paddingRight: '20px',
          },
          // "@media (max-width: 655px)": {
          //     paddingRight: "10px",
          // },

          '>div': {
            marginY: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',

            '>p': {
              fontSize: '16px',
              color: '#1F398F',
              fontWeight: '400',
            },
            '>svg': {
              marginY: 'auto',
              color: '#1F398F',
              fontWeight: '400',
            },
          },
        },
      },
    },
  },
}

export default subHeaderStyles
