import { FunctionComponent } from "react";

interface IconFilterProps {
    width?: number | string;
    height?: number | string;
}

const IconFilter: FunctionComponent<IconFilterProps> = ({
    width = 11,
    height = 11,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.55 1.09998C0.853757 1.09998 1.1 1.34622 1.1 1.64998C1.1 1.82116 1.18224 2.10729 1.3885 2.50709C1.58686 2.89155 1.86884 3.32293 2.19434 3.76628C2.84407 4.65126 3.63197 5.5367 4.17211 6.11662C4.66724 6.64214 4.94517 7.33559 4.94999 8.05765L4.95001 8.06132H4.95V9.34997C4.95 9.49584 5.00795 9.63574 5.11109 9.73888C5.21424 9.84203 5.35413 9.89997 5.5 9.89997C5.64587 9.89997 5.78576 9.84203 5.88891 9.73888C5.99205 9.63574 6.05 9.49584 6.05 9.34997V8.06132C6.05 7.33102 6.3396 6.64138 6.82655 6.11806L6.82684 6.11775C7.36723 5.5379 8.15577 4.652 8.80586 3.76649C9.13131 3.32319 9.41324 2.89184 9.61155 2.50737C9.81775 2.1076 9.9 1.82136 9.9 1.64998C9.9 1.34622 10.1462 1.09998 10.45 1.09998C10.7538 1.09998 11 1.34622 11 1.64998C11 2.09211 10.8162 2.57148 10.5892 3.01162C10.3542 3.46707 10.0358 3.94996 9.69256 4.41746C9.00533 5.35355 8.18347 6.2755 7.63156 6.8677C7.32339 7.19904 7.15 7.62467 7.15 8.06132V9.34997C7.15 9.78758 6.97616 10.2073 6.66673 10.5167C6.35729 10.8261 5.93761 11 5.5 11C5.06239 11 4.64271 10.8261 4.33327 10.5167C4.02384 10.2073 3.85 9.78758 3.85 9.34997V8.06328C3.8466 7.61934 3.6754 7.1931 3.37073 6.87014L3.36834 6.86761L3.36835 6.8676C2.81677 6.27545 1.99493 5.35339 1.30765 4.41726C0.964413 3.94974 0.645901 3.46685 0.410942 3.01144C0.183895 2.57137 0 2.09204 0 1.64998C0 1.34622 0.246243 1.09998 0.55 1.09998Z"
                fill="#1F398F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.31914 1.65C1.50838 1.73116 1.77527 1.8145 2.11913 1.89091C2.96529 2.07895 4.16088 2.2 5.5 2.2C6.83912 2.2 8.03471 2.07895 8.88087 1.89091C9.22473 1.8145 9.49162 1.73116 9.68086 1.65C9.49162 1.56884 9.22473 1.4855 8.88087 1.40909C8.03471 1.22105 6.83912 1.1 5.5 1.1C4.16088 1.1 2.96529 1.22105 2.11913 1.40909C1.77527 1.4855 1.50838 1.56884 1.31914 1.65ZM1.88051 0.33528C2.8259 0.125192 4.10531 0 5.5 0C6.89469 0 8.1741 0.125192 9.11949 0.33528C9.58772 0.439332 10.0041 0.570643 10.3168 0.73494C10.4726 0.816791 10.6296 0.920656 10.754 1.05575C10.8811 1.19379 11 1.39475 11 1.65C11 1.90525 10.8811 2.10621 10.754 2.24425C10.6296 2.37934 10.4726 2.48321 10.3168 2.56506C10.0041 2.72936 9.58772 2.86067 9.11949 2.96472C8.1741 3.17481 6.89469 3.3 5.5 3.3C4.10531 3.3 2.8259 3.17481 1.88051 2.96472C1.41227 2.86067 0.995901 2.72936 0.683187 2.56506C0.527397 2.48321 0.37037 2.37934 0.245972 2.24425C0.118858 2.10621 0 1.90525 0 1.65C0 1.39475 0.118858 1.19379 0.245972 1.05575C0.37037 0.920656 0.527397 0.816791 0.683187 0.73494C0.995901 0.570643 1.41227 0.439332 1.88051 0.33528Z"
                fill="#1F398F"
            />
        </svg>
    );
};

export default IconFilter;
