import { FunctionComponent } from "react";
import Box from "@mui/material/Box";
import LogoSpinner from "../../images/logos/logoFaviconAplanner.png";
import "./spinner-styles.css";
import IconLoadingAplanner from "../icons/IconLoadingAplanner";

interface SpinnerProps {
    width?: string | number;
    height?: string | number;
    alignItems?: string;
}
const Spinner: FunctionComponent<SpinnerProps> = ({
    width = 50,
    height = 50,
    alignItems = "center",
}) => {
    return (
        <Box className="container-spinner" sx={{ alignItems: { alignItems } }}>
            <Box className="logo-spinner">
                <IconLoadingAplanner width={width} height={height} />
            </Box>
        </Box>
    );
    // return (
    //   <img
    //
    //     src={LogoSpinner}
    //     alt="Loading..."
    //     width={width}
    //   ></img>
    // )
};

export default Spinner;
