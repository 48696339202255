import { useCallback, useState } from 'react'
import useFetch, { BasicFetchListProps } from '../useFetch'
import {
  Condition,
  ConditionModel,
  FormConditionValues,
  UpdateConditionValues,
} from '../../interfaces/condition'
import { Area } from '../../interfaces/area'
import { IndicatorCondition } from '../../interfaces/indicator-condition'
import useAppDialog from '../useAppDialog'

interface ResponseUploadJustification {
  info: string | null | undefined
  key: string | null | undefined
  url: string | null | undefined
}

type GetConditionsAndIndicatorsRelations = BasicFetchListProps

type GetConditionsProps = BasicFetchListProps

// interface GetLinkedConditionIndicatorsProps {
//   onSuccess?: (linkConditionIndicator: LinkedConditionIndicators) => void
//   onError?: () => void
// }

interface GetIndicatorAndAreasActionsProps {
  success?: (condition: ConditionModel) => void
  error?: () => void
}

const useCondition = () => {
  const { fetchList, fetchCreate, fetchUpload, fetchFindOne, fetchDisable, fetchUpdate } = useFetch()

  const [conditionList, setConditionList] = useState<{ items: Condition[]; total: number }>({ items: [], total: 0 })

  const [condition, setCondition] = useState<ConditionModel | null>(null)

  const [indicatorConditionList, setIndicatorConditionList] = useState<{ items: IndicatorCondition[]; total: number }>({
    items: [],
    total: 0,
  })

  const [areaList, setAreaList] = useState<{ items: Area[]; total: number }>({ items: [], total: 0 })

  const { showLoadingDialog, showSuccessDialog, showErrorDialog, hideDialog } = useAppDialog()

  const getConditions = useCallback(
    ({ limit = 0, from = 0, active = true }: GetConditionsProps = {}) => {
      showLoadingDialog()
      fetchList<Condition>({
        action: 'getConditions',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(({ items, total }) => {
          setConditionList(() => ({ items, total }))
          hideDialog()
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const getConditionsWithRelations = useCallback(
    ({ limit = 0, from = 0, active = true }: GetConditionsAndIndicatorsRelations = {}) => {
      showLoadingDialog()

      const promiseConditions = fetchList<Condition>({
        action: 'getConditions',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })

      const promiseIndicatorCondition = fetchList<IndicatorCondition>({
        action: 'getIndicatorsConditions',
      })

      Promise.allSettled([promiseConditions, promiseIndicatorCondition]).then(
        ([conditionsResponse, indicatorConditionResponse]) => {
          if (indicatorConditionResponse.status === 'fulfilled') {
            setIndicatorConditionList(() => indicatorConditionResponse.value)
          } else {
            setIndicatorConditionList(() => ({ items: [], total: 0 }))
          }

          if (conditionsResponse.status === 'rejected') {
            showErrorDialog({
              text: conditionsResponse.reason?.message || 'Ha ocurrido un error, contactar con soporte.',
            })
            setConditionList(() => ({ items: [], total: 0 }))
            return
          }

          setConditionList(conditionsResponse.value)
          hideDialog()
        }
      )
    },
    [fetchList]
  )

  const getConditionAndAreas = useCallback(
    (idCondition: string, { success, error }: GetIndicatorAndAreasActionsProps) => {
      showLoadingDialog()

      const promiseAreas = fetchList<Area>({
        action: 'getDepartaments',
      })

      const promiseCondition = fetchFindOne<ConditionModel>({
        action: 'getOneCondition',
        findId: idCondition,
      })

      Promise.allSettled([promiseCondition, promiseAreas]).then(([conditionResponse, areasResponse]) => {
        if (areasResponse.status == 'fulfilled') {
          setAreaList(() => areasResponse.value)
        } else {
          setAreaList(() => ({ items: [], total: 0 }))
        }

        if (conditionResponse.status === 'rejected') {
          showErrorDialog({
            text: conditionResponse.reason?.message || 'Ha ocurrido un error, contactar con soporte.',
            didClose: error,
          })
          setCondition(() => null)
          return
        }

        setCondition(() => conditionResponse.value)
        success && success(conditionResponse.value)
        hideDialog()
      })
    },
    [fetchList]
  )

  // const getLinkedConditionIndicators = useCallback(
  //   (idCondition: string, { onSuccess, onError }: GetLinkedConditionIndicatorsProps | undefined = {}) => {
  //     showLoadingDialog()
  //     fetchFindOne<LinkedConditionIndicators>({
  //       findId: idCondition,
  //       action: 'getOneCondition',
  //     })
  //       .then(linkConditionIndicator => {
  //         hideDialog()
  //         onSuccess && onSuccess(linkConditionIndicator)
  //       })
  //       .catch(error => {
  //         showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.', didClose: error })
  //       })
  //   },
  //   [fetchFindOne]
  // )

  const uploadJustification = (justificationFile: File) => {
    const formData = new FormData()
    formData.append('justification', justificationFile)
    return fetchUpload<ResponseUploadJustification>({
      action: 'uploadJustificationCondition',
      body: formData,
    })
  }

  const createCondition = useCallback(
    ({ justificationLink, ...other }: FormConditionValues, done?: (_id: string) => void) => {
      if (!justificationLink) return

      if (typeof justificationLink === 'string') return

      const justificationFile = justificationLink.item(0)

      if (!justificationFile) return

      showLoadingDialog({
        text: 'Espera un momento, se está subiendo el archivo de justificación.',
      })

      uploadJustification(justificationFile)
        .then(({ key, url }) => {
          if (!key || !url) {
            showErrorDialog({
              text: 'El archivo de justificación no se ha subido correctamente. por favor, contactar con soporte.',
            })
            return
          }
          showLoadingDialog()
          fetchCreate({
            action: 'createCondition',
            body: { ...other, justification: key, justificationLink: url },
          })
            .then(_id => {
              showSuccessDialog({
                text: 'La condición se ha creado exitosamente!',
                didClose: () => done && done(_id),
              })
            })
            .catch(error => {
              showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
            })
        })
        .catch(() => {
          showErrorDialog({
            text: 'Ha ocurrido un error al subir el archivo de justificación. por favor, contactar con soporte.',
          })
        })
    },
    [fetchCreate]
  )

  const handleUpdateCondition = useCallback(
    async (body: UpdateConditionValues, done?: (_updateValues: UpdateConditionValues) => void) => {
      try {
        const { justificationLink, ...other } = body

        showLoadingDialog()
        let newJustificationLink: string | undefined
        let newJustificationKey: string | undefined

        if (typeof justificationLink === 'string' ) {
          newJustificationKey = undefined
        }

        if (justificationLink !== undefined && typeof justificationLink !== 'string') {
          const file = justificationLink.item(0)
          if (file) {
            showLoadingDialog({
              text: 'Espera un momento, se está subiendo el nuevo archivo de justificación.',
            })
            const { key, url } = await uploadJustification(file)
            if (!key || !url) {
              showErrorDialog({
                text: 'El archivo de justificación no se ha subido correctamente. por favor, contactar con soporte.',
              })
              return
            }
            newJustificationLink = url
            newJustificationKey = key
          }
        }

        showLoadingDialog()

        fetchUpdate<UpdateConditionValues & { justification? : string }>({
          action: 'updateCondition',
          body: {
            ...other,
            justification: newJustificationKey,
            justificationLink: newJustificationLink,
          },
        })
          .then(_updateValues => {
            showSuccessDialog({
              text: 'La condición se ha actualizado exitosamente!.',
              didClose: () => done && done(_updateValues),
            })
          })
          .catch(error => {
            showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
          })
      } catch {
        showErrorDialog({
          text: 'Ha ocurrido un error al subir el nuevo archivo de justificación. por favor, contactar con soporte.',
        })
      }
    },
    [fetchUpdate]
  )

  const deleteCondition = useCallback(
    (id: string, done?: () => void) => {
      showLoadingDialog()
      fetchDisable({ action: 'deleteCondition', body: { _id: id } })
        .then(() => {
          showSuccessDialog({
            text: `La condición ha sido eliminada exitosamente!`,
            didClose: done,
          })
        })
        .catch(error => {
          showErrorDialog({ text: error?.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchDisable]
  )

  return {
    getConditions,
    condition,
    conditionList,
    areaList,
    getConditionsWithRelations,
    // getLinkedConditionIndicators,
    indicatorConditionList,
    createCondition,
    getConditionAndAreas,
    handleUpdateCondition,
    deleteCondition,
  }
}

export default useCondition
