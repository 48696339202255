import { Box, InputBase, Typography, Button, FormControlLabel, Checkbox, FormGroup } from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

// IMPORTADOS
import { FormGoalValues } from '../../../../interfaces/pdi/goal'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorField from '../../../ErrorField'
import CustomSelect from '../../../inputSelect/CustomSelect'
import { FunctionComponent, useEffect } from 'react'
import { Project } from '../../../../interfaces/pdi/project'
import { MeasureUnit } from '../../../../interfaces/pdi/measureUnit'
import { formGoalSchema } from '../../../../schemas/pdi/goalSchema'
import formStyles from '../../form.styles'
import SelectPagination from '../../../inputSelect/SelectPagination'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface FormGoalProps {
  pdi: PdiModel
  defaultValues?: Partial<FormGoalValues>
  projects: Project[]
  measureUnits: MeasureUnit[]
  onCancel: () => void
  onSubmit: (formValues: FormGoalValues, reset: () => void) => void
  viewMode?: 'create' | 'update'
}

const _defaultValues: Partial<FormGoalValues> = {
  idProject: '',
  idMeasureUnit: '',
  title: '',
}

const FormGoal: FunctionComponent<FormGoalProps> = ({
  pdi,
  defaultValues = _defaultValues,
  measureUnits,
  projects,
  onCancel,
  onSubmit,
  viewMode,
}) => {
  const getFixInitialValues = (): Partial<FormGoalValues> => {
    const { idProject, idMeasureUnit } = defaultValues
    const isInRangeProject = projects.some(p => p._id === idProject)
    const inRangeMeasureUnit = measureUnits.some(m => m._id === idMeasureUnit)
    return {
      ...defaultValues,
      idProject: isInRangeProject ? idProject : '',
      idMeasureUnit: inRangeMeasureUnit ? idMeasureUnit : '',
      idPdi: pdi._id,
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormGoalValues>({
    mode: 'onChange',
    resolver: yupResolver(formGoalSchema),
    defaultValues: getFixInitialValues(),
  })

  useEffect(() => {
    const fixDefaultValues = getFixInitialValues()
    reset(fixDefaultValues)
  }, [defaultValues])

  const submit: SubmitHandler<FormGoalValues> = formValues => onSubmit(formValues, () => reset(_defaultValues))

  return (
    <>
      {/*******************************
                CONTENEDOR FORMULARIO METAS
            *******************************/}
      <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
        {/**************************
                    NOMBRE DEL PROYECTO
                ****************************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Nombre del proyecto</Typography>

          <Controller
            name="idProject"
            control={control}
            render={({ field }) => <SelectPagination {...field} propValue="_id" propText="title" items={projects} />}
          />

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.idProject?.message} />
        </Box>

        {/****************
                    TITULO
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Título</Typography>

          {/**********
                        INPUT
                    **********/}
          <Box sx={formStyles.input}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.title?.message} />
        </Box>

        {/*********
                    TÍTULO
                ***********/}
        <Box sx={formStyles.containerTitleHorizontal}>
          <Typography sx={formStyles.titleHorizontal}>No. meta:</Typography>

          {/****************
                        INPUT SMALL
                    ****************/}
          <Box sx={formStyles.inputSmall}>
            <Controller
              name="goalNumber"
              control={control}
              render={({ field: { value, ...field } }) => (
                <InputBase {...field} value={value ?? ''} type="number" sx={formStyles.inputBase} />
              )}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.goalNumber?.message} />
        </Box>

        {/*******************
                    UNIDAD DE MEDIDA
                ********************/}

        <Box sx={formStyles.containerTitleHorizontal}>
          <Typography sx={formStyles.titleHorizontal}>Unidad de medida:</Typography>

          <Controller
            name="idMeasureUnit"
            control={control}
            render={({ field }) => <CustomSelect {...field} propValue="_id" propText="name" items={measureUnits} />}
          />

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.idMeasureUnit?.message} />
        </Box>

        {/*******************************
                    PORCENTAJE DE CUMPLIMIENTO
                *********************************/}
        {/* <Box sx={formStyles.percentageOfCompletion}>
                    <Typography>Porcentaje de cumplimiento</Typography>
                    <Box sx={formGoalStyles.input}>
                        <Controller
                            name="percentageOfCompletion"
                            control={control}
                            render={({ field: { value, ...field } }) => (
                                <InputBase
                                    {...field}
                                    placeholder='100%...'
                                    value={value ?? ''}
                                    type='number'
                                    sx={formGoalStyles.inputBase}
                                />
                            )}
                        />
                    </Box>
                    <ErrorField message={errors.percentageOfCompletion?.message} />
                </Box> */}

        {/**********
                    ESTADO
                ************/}
        {viewMode === 'update' && (
          <>
            <Box sx={formStyles.containerState}>
              <Typography sx={formStyles.title}>Estado</Typography>

              {/***********************
                                CHECKBOX DE ESTADO
                            ***********************/}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === true ? true : false}
                          onChange={({ target: { checked } }) => onChange(checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Activo"
                  labelPlacement="start"
                />

                <FormControlLabel
                  className="inactivo"
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === false ? true : false}
                          onChange={({ target: { checked } }) => onChange(!checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Inactivo"
                  labelPlacement="start"
                />
              </FormGroup>
            </Box>

            {/*********************
                            MENSAJE DE ERROR
                        *********************/}
            <ErrorField message={errors.state?.message} />
          </>
        )}

        {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
        <Box sx={formStyles.divButton}>
          {/*****************************
                        BOTONES GUARDAR/CANCELAR
                    ******************************/}
          <div>
            {/********************
                            BOTON GUARDAR
                        *********************/}
            <Button disableTouchRipple disabled={!isValid} type="submit" sx={formStyles.buttonSave}>
              Guardar
            </Button>

            {/********************
                            BOTON CANCELAR
                        *********************/}
            <Button disableTouchRipple onClick={onCancel} sx={formStyles.buttonCancel}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default FormGoal
