import { useEffect, useState } from "react";

type ViewMode = "create" | "update" | "data" | null
interface UseViewControlTabPanelPdi<T> {
    items? : T[],
    titleOptions ? : {
        none   : string | null
        create : string | null
        update : string | null
        data   : string | null
    },
    onChangeTitle? : (title : string|null) => void
}


const useViewControlTabPanel = <T extends {}, F = {}>({ items = [], onChangeTitle, titleOptions = {
    none : null, create : null, update : null, data : null
}} : UseViewControlTabPanelPdi<T> = {}) => {

    const [title, setTitle] = useState<string|null>(null)

    useEffect(() => {
        if(!onChangeTitle) return
        onChangeTitle(title)
    },[title])

    const [viewMode, setViewMode] = useState<ViewMode>(null);
    
    const [updateItem, setUpdateItem] = useState<T | null>(null)

    const [filterOption, setFilterOption] = useState<F | null>(null)

    const changeFilterOption = (option : F|null) => {
        setFilterOption(option)
    }

    const showViewCreate = () => {
        setUpdateItem(() => null)
        setViewMode(() => 'create')
        setTitle(() => titleOptions['create'])
    }

    const showViewUpdate = (itemUpdate: T) => {
        setUpdateItem(() => itemUpdate)
        setViewMode(() => 'update')
        setTitle(() => titleOptions['update'])
    }

    const showViewData = () => {
        if(updateItem) setUpdateItem(() => null)
        setViewMode(() => 'data')
        setTitle(() => titleOptions['data'])
    }
    
    useEffect(() => {
        if(items.length) return showViewData()
        showViewCreate()
    }, []);

    return {
        viewMode,
        title,
        showViewCreate,
        showViewData,
        showViewUpdate,
        updateItem,
        filterOption,
        changeFilterOption
    }
}

export default useViewControlTabPanel;