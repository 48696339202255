/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  ListItemIcon,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import areaNameStyles from '../../../../components/inputSelect/AreaName.styles'
import taskAssignmentTabsStyles from './TaskAssignmentTabs.styles'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useUsers from '../../../../hooks/users/useUsers'
import usersStyles from '../../users/users.styles'
import Spinner from '../../../../components/utilities/Spinner'
import useTasks from '../../../../hooks/useTasks'

interface IFormEditProps {
  defaultValues: any
  list: Function
  setOpen: Function
}

const FormEdit = ({ defaultValues, list, setOpen }: IFormEditProps) => {
  const { fetchGetUsers } = useUsers()
  const { fetchUpdateTaskAssignment } = useTasks()
  const [users, setUsers] = useState<any[]>([])
  const [dataForm, setDataForm] = useState<any>({})

  // Paginacion
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [from, setFrom] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)
  const [loading, setLoading] = useState<boolean>(false)

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setFrom((value - 1) * limit)
    setPage(value)
  }

  const getUsers = async () => {
    setLoading(true)
    const response: any = await fetchGetUsers()
    const result = response.items ?? []
    const pages = result.length / limit

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }

    // Ordenar la lista mostrando primero los usuarios checkeados
    const usersOrdened = [...result]
    usersOrdened.sort((a, b) => {
      const existA = defaultValues?.userNotifies.some((item: any) => item?._id === a._id)
      const existB = defaultValues?.userNotifies.some((item: any) => item?._id === b._id)
      return Number(existB) - Number(existA)
    })
    
    setUsers(usersOrdened)
    setLoading(false)
  }

  useEffect(() => {
    getUsers()
    let userNotifies: any = []
    defaultValues.userNotifies?.forEach((element: any) => {
      userNotifies = [...userNotifies, element._id]
    })
    setDataForm({
      ...dataForm,
      _id: defaultValues._id ?? '',
      idPdi: defaultValues.idPdi?._id ?? '',
      idIndicator: defaultValues.idIndicator?._id ?? '',
      idUser: defaultValues.idUser?._id ?? '',
      userNotifies,
      complianceDate: defaultValues.complianceDate ?? '',
    })
  }, [defaultValues])

  const handleSelectChange = (e: any) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeDate = (value: any) => {
    const day = value.$D < 10 ? `0${value.$D}` : value.$D
    const month = value.$M + 1 < 10 ? `0${value.$M + 1}` : value.$M + 1
    const year = value.$y

    setDataForm({
      ...dataForm,
      complianceDate: `${year}-${month}-${day}`,
    })
  }

  const handleCheck = (id: any, check: boolean) => {
    if (check) {
      setDataForm({
        ...dataForm,
        userNotifies: [...dataForm.userNotifies, id],
      })
    } else {
      const copyUserNotifies: any[] = dataForm.userNotifies ?? []
      const index = copyUserNotifies.findIndex((item: any) => item === id)
      copyUserNotifies.splice(index, 1)
      setDataForm({
        ...dataForm,
        userNotifies: copyUserNotifies,
      })
    }
  }

  const handleSubmit = async () => {
    const response: any = await fetchUpdateTaskAssignment(dataForm)
    if (response.ok) {
      list()
      setOpen(false)
    }
  }

  return (
    <Box
      sx={{
        marginX: '60px',
        marginY: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100% !important',
        }}
      >
        <Box>
          <Box className="titleButtonSearch" sx={taskAssignmentTabsStyles.titleButtonSearch}>
            <Typography>Nombre del indicador</Typography>
          </Box>
          <FormControl
            className="aqui"
            sx={{
              ...areaNameStyles.inputSelect,
            }}
            variant="standard"
          >
            <Select value={defaultValues.idIndicator?._id} disabled>
              <MenuItem value={defaultValues.idIndicator?._id} disabled disableTouchRipple>
                {defaultValues.idIndicator?.name}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Box className="titleButtonSearch" sx={taskAssignmentTabsStyles.titleButtonSearch}>
            <Typography>¿Quién mide?</Typography>
          </Box>
          <FormControl
            className="aqui"
            sx={{
              ...areaNameStyles.inputSelect,
            }}
            variant="standard"
          >
            <Select value={dataForm.idUser ?? ''} name="idUser" onChange={handleSelectChange}>
              {users.map((item: any) => (
                <MenuItem value={item._id} key={item._id} disableTouchRipple>
                  {`${item.idProfile?.name} ${item.idProfile?.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={usersStyles.userTable}>
          <Box className="titleButtonSearch" sx={taskAssignmentTabsStyles.titleButtonSearch}>
            <Typography>¿A quién notifica?</Typography>
          </Box>
          <Table>
            <TableHead>
              <tr>
                <td>Nombre</td>
                <td>Seleccion</td>
              </tr>
            </TableHead>
            <TableBody>
              {loading ? (
                <tr>
                  <td colSpan={2} rowSpan={5}>
                    <Box
                      sx={{
                        height: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </Box>
                  </td>
                </tr>
              ) : (
                users.slice(from, page * limit).map((item: any) => (
                  <tr>
                    <td>{`${item.idProfile?.name} ${item.idProfile?.lastName}`}</td>
                    <td>
                      <ListItemIcon className="checked" sx={taskAssignmentTabsStyles.checked}>
                        <Checkbox
                          disableRipple
                          edge="start"
                          checked={dataForm.userNotifies.some((item2: any) => item2 === item._id)}
                          onChange={e => handleCheck(item._id, e.target.checked)}
                        />
                      </ListItemIcon>
                    </td>
                  </tr>
                ))
              )}
            </TableBody>
          </Table>
          <Box sx={usersStyles.pagesList}>
            <Pagination count={pages} page={page} onChange={changePage} />
          </Box>
        </Box>
        <Box>
          <Box className="titleButtonSearch" sx={taskAssignmentTabsStyles.titleButtonSearch}>
            <Typography>Fecha de Iniciación</Typography>
          </Box>
          <Box sx={{ textAlign: 'center', marginY: '50px' }}>
            <Box sx={{ textAlign: 'center', marginY: '50px' }}>
              <LocalizationProvider locale="es-mx" dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={dataForm.complianceDate}
                  onChange={value => handleChangeDate(value)}
                  renderInput={params => <TextField {...params} />}
                  PopperProps={{
                    placement: 'right',
                  }}
                  showDaysOutsideCurrentMonth={true}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Box sx={taskAssignmentTabsStyles.divButton}>
          <div>
            <Button disableTouchRipple sx={taskAssignmentTabsStyles.buttonBack}>
              Regresar
            </Button>
            <Button disableTouchRipple sx={taskAssignmentTabsStyles.buttonSave} type="submit" onClick={handleSubmit}>
              Guardar
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default FormEdit
