/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import taskAssignmentTabsStyles from '../TaskAssignmentTabs.styles'
import areaNameStyles from '../../../../../components/inputSelect/AreaName.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import useUsers from '../../../../../hooks/users/useUsers'
import useDepartament from '../../../../../hooks/departament/useDepartament'
import Spinner from '../../../../../components/utilities/Spinner'

export const assignmentTaskWhoMeasures = [
  {
    valor: '1',
    name: 'Usuarios',
  },
  {
    valor: '2',
    name: 'Areas',
  },
]

// TEMA
const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '>.MuiPaper-elevation': {
            backgroundColor: '#ffffff',
            boxShadow: 'none',
            minHeight: '86px',
            borderRadius: '15px',
            overflowY: 'hidden',

            // UL
            '>ul': {
              backgroundColor: 'inherit',
              margin: '20px 20px',

              // SCROLL
              overflowY: assignmentTaskWhoMeasures.length > 14 ? 'scroll' : 'hidden',
              '&::-webkit-scrollbar': {
                width: '12px',
                size: '10px',
                '&-track': {
                  backgroundColor: '#FECF5E',
                  borderRadius: '10px',
                  border: '5px solid #ffffff',
                },
                '&-thumb': {
                  backgroundColor: '#1F398F',
                  borderRadius: '20px',
                  width: '10px',
                },
              },

              // LI
              '>li': {
                color: '#000000',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                fontSize: '16px',
                lineHeight: '24px',
                backgroundColor: 'inherit',
                ':hover': {
                  color: '#1F398F',
                  fontWeight: '600',
                  backgroundColor: 'inherit',
                },
              },

              '>.Mui-selected': {
                backgroundColor: '#F1F3F8',
                color: '#1F398F',
                fontWeight: '600',
                borderRadius: '200px',
                ':hover': {
                  backgroundColor: '#F1F3F8',
                },
              },
            },
          },
        },
      },
    },
  },
})

const TabTaskWhoNotify = ({
  tasksAssignment,
  setTasksAssignment,
  historyTasksAssignment,
  setHistoryTasksAssignment,
}: any) => {
  //hooks
  const { fetchGetUsers } = useUsers()
  const { fetchDepartaments } = useDepartament()

  // ARRAY
  const [list, setList] = useState<any>([])
  const [users, setUsers] = useState<any>([])
  const [usersApi, setUsersApi] = useState<any>([])
  const [option, setOption] = useState<any>('')
  const [checkeds, setCheckeds] = useState<any>({})
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let checks: any = checkeds || {}
    users.forEach((object: any) => {
      checks = {
        ...checks,
        [object._id]: checks[object._id] ?? false,
      }
    })
    setCheckeds(checks)
  }, [users])

  const handleToggleChecked = (checked: boolean, object: any) => {
    let newTasksAssignment: any = []
    if (checked) {
      tasksAssignment.forEach((item: any) => {
        let whoNotify = item.who_notify ?? []
        whoNotify = [
          ...whoNotify,
          {
            id: object._id,
            name: object.idProfile?.name ?? '',
            lastName: object.idProfile?.lastName ?? '',
          },
        ]
        newTasksAssignment = [
          ...newTasksAssignment,
          {
            ...item,
            who_notify: whoNotify,
          },
        ]
      })
      setTasksAssignment([...newTasksAssignment])
    } else {
      tasksAssignment.forEach((item: any) => {
        const who_notify: any[] = []
        item.who_notify.forEach((user: any) => {
          if (user.id !== object._id) {
            who_notify.push(user)
          }
        })
        newTasksAssignment = [
          ...newTasksAssignment,
          {
            ...item,
            who_notify,
          },
        ]
      })
      setTasksAssignment([...newTasksAssignment])
    }
    setCheckeds({
      ...checkeds,
      [object._id]: checked,
    })
    setCheckAll(
      !Object.values({
        ...checkeds,
        [object._id]: checked,
      }).some(item => item === false)
    )
    setHistoryTasksAssignment({
      ...historyTasksAssignment,
      tabWhoNotify: {
        ...historyTasksAssignment.tabWhoNotify,
        usersNotify: newTasksAssignment[0]?.who_notify ?? [],
        isActive: Object.values({
          ...checkeds,
          [object._id]: checked,
        }).some(item => item === true),
      },
    })
  }

  const handleCheckAll = () => {
    const check = !checkAll
    setCheckAll(check)
    let checks: any = checkeds
    let whoNotify: any[] = []
    users.forEach((item: any) => {
      checks = {
        ...checks,
        [item._id]: check,
      }
      if (check) {
        whoNotify = [
          ...whoNotify,
          {
            id: item._id,
            name: item.idProfile?.name ?? '',
            lastName: item.idProfile?.lastName ?? '',
          },
        ]
      } else {
        whoNotify = []
      }
    })
    let newTasksAssignment: any[] = []
    tasksAssignment.forEach((item: any) => {
      newTasksAssignment = [
        ...newTasksAssignment,
        {
          ...item,
          who_notify: whoNotify,
        },
      ]
    })
    setCheckeds(checks)
    setTasksAssignment([...newTasksAssignment])
    setHistoryTasksAssignment({
      ...historyTasksAssignment,
      tabWhoNotify: {
        ...historyTasksAssignment.tabWhoNotify,
        usersNotify: newTasksAssignment[0]?.who_notify ?? [],
        isActive: !checkAll,
        checkAll: check,
      },
    })
  }

  const handleSelectChange = async (e: any) => {
    setLoading(true)
    let response: any
    setOption(e.target.value)
    switch (e.target.value) {
      case '1':
        setActive(true)
        response = await fetchGetUsers()
        setUsers(response?.items ?? [])
        setUsersApi(response?.items ?? [])
        setHistoryTasksAssignment({
          ...historyTasksAssignment,
          tabWhoNotify: {
            ...historyTasksAssignment.tabWhoNotify,
            option: e.target.value,
            users: response?.items ?? [],
          },
        })
        break

      case '2':
        setActive(false)
        response = await fetchDepartaments()
        setList(response?.items ?? [])
        break

      default:
        break
    }
    setLoading(false)
  }

  const handleClickItem = async (id: any) => {
    setLoading(true)
    setUsers([])
    setUsersApi([])
    setActive(true)
    const response: any = await fetchGetUsers()
    const result = response.items.filter((item: any) => item?.idDepartament?._id === id)
    setUsers(result)
    setUsersApi(result)
    setHistoryTasksAssignment({
      ...historyTasksAssignment,
      tabWhoNotify: {
        ...historyTasksAssignment.tabWhoNotify,
        option,
        users: result,
      },
    })
    setLoading(false)
  }

  useEffect(() => {
    getHistory()
  }, [])

  const getHistory = async () => {
    if (historyTasksAssignment.tabWhoNotify?.isActive) {
      setActive(true)
      setOption(historyTasksAssignment.tabWhoNotify?.option ?? '')
      setUsers(historyTasksAssignment.tabWhoNotify?.users ?? [])
      setUsersApi(historyTasksAssignment.tabWhoNotify?.users ?? [])
      const whoNotify = tasksAssignment[0].who_notify
      const result = Object.fromEntries(whoNotify.map((item: any) => [item.id, true]))
      setCheckeds({ ...checkeds, ...result })
      setCheckAll(historyTasksAssignment.tabWhoNotify?.checkAll)
    }
  }

  return (
    <Box className="contentTab" sx={taskAssignmentTabsStyles.contentTab}>
      {/*********************
                CONTENEDOR INPUT
            *********************/}
      <Box
        className="areaName"
        sx={{
          ...areaNameStyles.containerInputSelect,
          display: 'flex',
          '>.MuiFormControl-root': {
            marginRight: '25px',

            '>.MuiInputBase-root': {
              '>.MuiInputBase-input': {
                paddingLeft: '25px',
                color: '#1F398F',
                fontWeight: '600',
              },
            },
          },

          '>.buttonSearch': {
            marginY: 'auto',
            '>div': {
              '>div': {
                width: 'inherit',
              },
            },
          },
        }}
      >
        {/*****************
                    INPUT SELECT
                *****************/}
        <ThemeProvider theme={theme}>
          <FormControl className="aqui" sx={areaNameStyles.inputSelect} variant="standard">
            <Select onChange={handleSelectChange} value={option} displayEmpty>
              <MenuItem value="" disabled disableTouchRipple>
                Selecciona una receptor
              </MenuItem>

              {assignmentTaskWhoMeasures.map(({ valor, name }, index) => (
                <MenuItem key={index} disableTouchRipple value={valor}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ThemeProvider>

        <ButtonSearch data={usersApi} setData={setUsers} fieldSearch={['idProfile.name']} />
      </Box>

      {/*********
                LISTA
            *********/}
      <Box sx={taskAssignmentTabsStyles.containerList} className="containerList">
        <List
          sx={{
            overflowY: active && list.length > 10 ? 'scroll' : 'hidden',
          }}
        >
          {active ? (
            <>
              {option !== '1' && (
                <>
                  <ListItem className="checkAll">
                    <ListItemButton disableTouchRipple onClick={() => setActive(false)}>
                      Volver
                    </ListItemButton>
                  </ListItem>
                </>
              )}
              <ListItem className="checkAll">
                <ListItemButton disableTouchRipple onClick={handleCheckAll}>
                  <ListItemIcon className="checked" sx={taskAssignmentTabsStyles.checked}>
                    <Checkbox disableRipple checked={checkAll} />
                  </ListItemIcon>
                  <ListItemText primary="Seleccionar todos" />
                </ListItemButton>
              </ListItem>
              {loading ? (
                <ListItem>
                  <Spinner />
                </ListItem>
              ) : users.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No se encontraron usuarios" />
                </ListItem>
              ) : (
                users.map((item: any) => (
                  <ListItem key={item._id}>
                    <ListItemButton disableTouchRipple onClick={() => handleToggleChecked(!checkeds[item._id], item)}>
                      <ListItemIcon className="checked" sx={taskAssignmentTabsStyles.checked}>
                        <Checkbox
                          disableRipple
                          edge="start"
                          checked={checkeds[item._id] || false}
                          onChange={e => handleToggleChecked(e.target.checked, item)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item?.name ?? `${item?.idProfile?.name} ${item?.idProfile?.lastName}`} />
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </>
          ) : (
            list?.map((item: any) => (
              <ListItem key={item._id}>
                <ListItemButton disableTouchRipple onClick={() => handleClickItem(item._id)}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Box>
    </Box>
  )
}

export default TabTaskWhoNotify
