import { FunctionComponent, ReactNode, createContext, useState } from 'react'
import { AlertDialogProps } from '../Dialogs/alertDialog/AlertDialog'

interface AppContextState {
  alertDialogState: AlertDialogProps
  handleAlertDialog: (data: AlertDialogProps) => void
}

interface AppContextProps {
  children: ReactNode
}

const initialState : AppContextState = {
  alertDialogState : {open : false},
  handleAlertDialog : () => null
}

const AppContext = createContext<AppContextState>(initialState)

const AppProvider: FunctionComponent<AppContextProps> = ({ children }) => {
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogProps>({ open : false })
  const handleAlertDialog = (data: AlertDialogProps) => {
    setAlertDialogState(data)
  }
  return <AppContext.Provider value={{ alertDialogState, handleAlertDialog }}>{children}</AppContext.Provider>
}

export {
  AppProvider as default,
  AppContext
}
