import { IconButton, Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import IconPensil from '../../icons/IconPensil'

const ButtonEditUser = ({ id }: any) => {
  const navigate = useNavigate()
  const handleUpdateUser = (id: string) => {
    navigate(`/users/update/${id}`)
  }

  return (
    <Box>
      <IconButton
        onClick={() => handleUpdateUser(id)}
      >
        <IconPensil />
      </IconButton>
    </Box>
  )
}

export default ButtonEditUser
