import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import ConfirmDelete from '../../../pages/private/users/ConfirmDelete'
import CustomModal from '../../CustomModal'
import IconTrash from '../../icons/IconTrash'

interface ButtonDeleteProps {
  id: string;
  listUser?: Function;
}

const ButtonDeleteUser = ({ id, listUser }: ButtonDeleteProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)

  return (
    <CustomModal
      maxWidth={600}
      maxHeight={350}
      text={
        <IconButton>
          <IconTrash />
        </IconButton>
      }
      stateOpen={openModalDelete}
    >
      <ConfirmDelete
        setOpen={setOpenModalDelete}
        open={openModalDelete}
        id={id}
        list={listUser}
      />
    </CustomModal>
  )
}

export default ButtonDeleteUser
