import { useCallback, useEffect, useState } from 'react'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'
import { FormPdaFields, LinkedPda, Pda, UpdatePdaValues } from '../../interfaces/pda/pda'
import { LinkedPdi } from '../../interfaces/pdi/pdi'
import { BranchOffice } from '../../interfaces/branchOffice'
import { MeasureUnit } from '../../interfaces/pdi/measureUnit'
import { CreateNewGoalPdaValues, GoalPdaIndicator, GoalPdaModel } from '../../interfaces/pda/goal-pda'
import { IndicatorModel } from '../../interfaces/pdi/indicator'

type ListState<T extends {}> = {
  total: number
  items: T[]
} | null

type ActionsCreate =
  | {
      handleSuccess?: (_id: string) => void
      handleError?: () => void
    }
  | undefined

type CreateNewPdaProps = ActionsCreate & {
  values: FormPdaFields
}

type CreateNewGoalPdaProps = ActionsCreate & {
  values: CreateNewGoalPdaValues
}

type GetPdasProps = BasicFetchListProps

type GetPdaProps = {
  findId: string
  handleError?: () => void
}

type EditPdaProps = {
  updateValues: UpdatePdaValues
}

const usePda = () => {
  const { fetchList, fetchCreate, fetchFindOne, fetchUpdate, fetchDisable } = useFetch()

  const { showLoadingDialog, showErrorDialog, showWarningDialog, showSuccessDialog, hideDialog } = useAppDialog()

  // PDI ACTIVO
  const [activePdi, setActivePdi] = useState<LinkedPdi | null>(null)

  // PDA ARBOL, CON SUS RELACIONES DE NIVEL INFERIOR
  const [linkedPda, setLinkedPda] = useState<LinkedPda | null>(null)

  // SOLO PDA
  const [pda, setPda] = useState<Pda | null>(null)

  // LISTA PDAS
  const [pdaList, setPdaList] = useState<ListState<Pda>>(null)

  // TODAS LAS RELACIONES DE META DEL PDA CON INDICADORES DEL PDI
  const [goalPdaIndicatorList, setGoalPdaIndicatorList] = useState<ListState<GoalPdaIndicator>>(null)

  // INDICADORES DEL PDI
  const [indicatorList, setIndicatorList] = useState<ListState<IndicatorModel>>(null)

  // LISTA DE METAS DEL PDA
  const [goalPaList, setGoalPaList] = useState<ListState<GoalPdaModel>>(null)

  // Estados necesaros para completar la vista de crear PDA
  const [branchOfficeList, setBranchOfficeList] = useState<ListState<BranchOffice>>(null)

  // UNIDADES DE MEDIDAS
  const [measureUnitList, setMeasureUnitList] = useState<ListState<MeasureUnit>>(null)

  const getPdas = useCallback(
    (params: GetPdasProps = { active: true }) => {
      showLoadingDialog()
      fetchList<Pda>({ action: 'getPdas', params: Object.entries(params) })
        .then(res => {
          hideDialog()
          setPdaList(res)
        })
        .catch(err => {
          showErrorDialog({ text: err?.message || 'Ha ocurrido un error, contactar con soporte.' })
          setPdaList(null)
        })
    },
    [fetchList]
  )

  const getInitialDataForCreatePda = () => {
    showLoadingDialog()
    const promiseActivePdi = fetchFindOne<LinkedPdi>({ action: 'getPdiActive', findId: '' })
    const promiseBranchOffices = fetchList<BranchOffice>({ action: 'getBranchOffices' })
    const promiseMeasureUnits = fetchList<MeasureUnit>({ action: 'getMeasureUnits' })
    const promiseGoalsPdaIndicators = fetchList<GoalPdaIndicator>({ action: 'getGoalsPaIndicators' })
    const promiseIndicators = fetchList<IndicatorModel>({ action: 'getIndicators' })
    // Cambiar
    Promise.all([
      promiseActivePdi,
      promiseBranchOffices,
      promiseMeasureUnits,
      promiseGoalsPdaIndicators,
      promiseIndicators,
    ])
      .then(([resActivePdi, resBranchOffices, resMeasureUnits, resGoalsPdaIndicators, resIndicators]) => {
        if (!resActivePdi) {
          return showErrorDialog({
            text: 'ha ocurrido un error al cargar el PDI activo,  no es posible crear un plan de acción',
          })
        }
        setActivePdi(resActivePdi)
        setGoalPdaIndicatorList(resGoalsPdaIndicators)
        setBranchOfficeList(resBranchOffices)
        setMeasureUnitList(resMeasureUnits)
        setIndicatorList(resIndicators)
        hideDialog()
      })
      .catch(err => {
        showErrorDialog({ text: 'Ha ocurrido un error al cargar los datos.' })
      })
  }

  const getInitialDataForUpdatePda = ({ findId, handleError }: GetPdaProps) => {
    showLoadingDialog()
    const promiseLinkedPda = fetchFindOne<LinkedPda>({ action: 'getOnePa', findId })
    const promiseBranchOffices = fetchList<BranchOffice>({ action: 'getBranchOffices' })
    const promiseMeasureUnits = fetchList<MeasureUnit>({ action: 'getMeasureUnits' })
    const promiseGoalsPdaIndicators = fetchList<GoalPdaIndicator>({ action: 'getGoalsPaIndicators' })
    const promiseIndicators = fetchList<IndicatorModel>({ action: 'getIndicators' })
    Promise.all([
      promiseLinkedPda,
      promiseBranchOffices,
      promiseMeasureUnits,
      promiseGoalsPdaIndicators,
      promiseIndicators,
    ])
      .then(([resLinkedPda, resBranchOfficeList, resMeasureUnitList, resGoalPdaIndicatorList, resIndicatorList]) => {
        if (!resLinkedPda) {
          return showErrorDialog({
            text: 'ha ocurrido un error al cargar el PDI activo, no es posible actualizar un plan de acción',
          })
        }
        setLinkedPda(resLinkedPda)
        setBranchOfficeList(resBranchOfficeList)
        setMeasureUnitList(resMeasureUnitList)
        setGoalPdaIndicatorList(resGoalPdaIndicatorList)
        setIndicatorList(resIndicatorList)
        hideDialog()
      })
      .catch(() => {
        showErrorDialog({
          text: 'Ha ocurrido un error al cargar el contenido del plan de acción, reintentar mas tarde',
          didClose: handleError,
        })
      })
  }

  const createNewPda = ({ values, handleSuccess, handleError }: CreateNewPdaProps) => {
    showLoadingDialog()
    fetchCreate({ action: 'createPa', body: values })
      .then(findId => {
        fetchFindOne<LinkedPda>({ action: 'getOnePa', findId })
          .then(_linkedPda => {
            setLinkedPda(_linkedPda)
            showSuccessDialog({
              text: 'El plan de acción se ha creado exitosamente!',
              didClose: () => handleSuccess && handleSuccess(findId),
            })
          })
          .catch(() => {
            showWarningDialog({
              text: 'El plan de acción se ha creado exitosamente, pero no se ha podido visualizar correctamente',
              didClose: handleError,
            })
          })
      })
      .catch(err => {
        showErrorDialog({
          text:
            err?.message ||
            'Se ha producido un inconveniente al crear este plan de acción. Por favor, póngase en contacto con el equipo de soporte para obtener asistencia.',
          didClose: handleError,
        })
      })
  }

  const getGoalPdaIndicatorList = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getGoalsPaIndicators',
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const createNewGoalPda = async ({ values, handleSuccess, handleError }: CreateNewGoalPdaProps) => {
    showLoadingDialog()
    return await fetchCreate({ action: 'createGoalPa', body: values })
      .then(_id => {
        fetchFindOne<LinkedPda>({ action: 'getOnePa', findId: values.idPa })
          .then(_linkedPda => {
            setLinkedPda(_linkedPda)
            showSuccessDialog({
              text: 'La meta del plan de acción se ha creado exitosamente',
              didClose: () => handleSuccess && handleSuccess(_id),
            })
            return { ok: true }
          })
          .catch(err => {
            showWarningDialog({
              text: 'La meta del plan de acción se ha creado exitosamente, sin embargo, no fue posible mostrar esta meta.',
              didClose: handleError,
            })
            return { ok: false }
          })
      })
      .catch(err => {
        showErrorDialog({
          text:
            err?.message ??
            'Ha ocurrido un error al crear esta meta. Por favor, póngase en contacto con el equipo de soporte para obtener asistencia.',
          didClose: handleError,
        })
        return { ok: false }
      })
  }

  const editPda = ({ updateValues }: EditPdaProps) => {
    showLoadingDialog()
    fetchUpdate<any>({ action: 'updatePa', body: updateValues })
      .then(() => {
        hideDialog()
      })
      .catch(() => {
        showErrorDialog({
          text: 'Algo no salió bien. No se pudo actualizar el plan de acción. Por favor, inténtelo de nuevo más tarde.',
        })
      })
  }

  const updateGoalPda = async ({ updateValues }: EditPdaProps) => {
    showLoadingDialog()
    return await fetchUpdate<any>({ action: 'updateGoaLPa', body: updateValues })
      .then(() => {
        hideDialog()
        return { ok: true }
      })
      .catch(() => {
        showErrorDialog({
          text: 'Algo no salió bien. No se pudo actualizar el plan de acción. Por favor, inténtelo de nuevo más tarde.',
        })
        return { ok: false }
      })
  }

  const createRelationsIndicatorsGoal = async ({ body }: any) => {
    showLoadingDialog()
    return await fetchCreate({ action: 'createRelationsIndicatorsGoal', body })
      .then(_id => {
        showSuccessDialog({
          text: 'Se guardado las relaciones exitosamente!',
        })
        return { ok: true }
      })
      .catch(error => {
        showErrorDialog({
          text: error?.message || 'contactar con soporte.',
        })
        return { ok: false }
      })
  }

  const deleteGoalPa = (body: any) => {
    showLoadingDialog()
    fetchDisable({ action: 'deleteGoalPa', body })
      .then(() => {
        showSuccessDialog({
          text: 'Meta eliminada exitosamente!.',
        })
      })
      .catch(error => showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' }))
  }

  const deleteIndicatorPa = async (body: any) => {
    showLoadingDialog()
    return await fetchDisable({ action: 'deleteIndicatorPa', body })
      .then(() => {
        showSuccessDialog({
          text: 'Indicador eliminado exitosamente!.',
        })
      })
      .catch(error => showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' }))
  }

  const disabledPA = async (body: any) => {
    showLoadingDialog()
    return await fetchDisable({ action: 'disabledPA', body })
      .then(() => {
        showSuccessDialog({
          text: 'Plan de accion desactivado exitosamente!.',
        })
        return { ok: true }
      })
      .catch((error: any) => {
        showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        return { ok: false }
      })
  }

  // Separa las relaciones inferiores del ARBOL PDA
  const matchLinkedPda = (_linkedPda: LinkedPda) => {
    const { link_pa_goals, ..._pda } = _linkedPda
    // const goalsPda: GoalPdaModel[] = link_pa_goals.map(({ link_goal_indicators, ...data }) => {
    //   return data
    // })
    setPda(_pda)
    setGoalPaList({ items: link_pa_goals, total: link_pa_goals.length })
  }

  useEffect(() => {
    if (!linkedPda) return
    matchLinkedPda(linkedPda)
  }, [linkedPda])

  return {
    getInitialDataForCreatePda,
    pdaList,
    branchOfficeList,
    createNewGoalPda,
    updateGoalPda,
    goalPaList,
    goalPdaIndicatorList,
    getGoalPdaIndicatorList,
    measureUnitList,
    createRelationsIndicatorsGoal,
    indicatorList,
    getPdas,
    getInitialDataForUpdatePda,
    linkedPda,
    pda,
    activePdi,
    createNewPda,
    editPda,
    disabledPA,
    deleteGoalPa,
    deleteIndicatorPa,
  }
}

export default usePda
