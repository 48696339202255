import { Box, Pagination } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'

// IMPORTADOS
import CardItemPdi from '../../../cards/cardItemPdi/CardItemPdi'
import ModalDetailItemPdi, { ModalDetailItemPdiProps } from '../../../modals/modalDetailItemPdi/ModalDetailItemPdi'
import usePagination from '../../../../hooks/usePagination'
import { MeasureUnit } from '../../../../interfaces/pdi/measureUnit'
import { Goal } from '../../../../interfaces/pdi/goal'
import { Project } from '../../../../interfaces/pdi/project'
import { IndicatorModel } from '../../../../interfaces/pdi/indicator'
import ConfirmAlert, { ConfirmAlertprops } from '../../../Alerts/confirmAlert/ConfirmAlert'
import IconDirection from '../../../icons/IconDirection'
import tableItemsPdiStyles from '../tableItemsPdiStyles'
import paginationStyles from '../../../Pagination.styles'

interface TableGoalsProps {
  filterOption: Project | null
  goals: Goal[]
  projects: Project[]
  measureUnits: MeasureUnit[]
  indicators: IndicatorModel[]
  onUpdate: (goal: Goal) => void
  onDelete: (goal: Goal) => void
}

const itemsPerPage = 9

const TableGoals: FunctionComponent<TableGoalsProps> = ({
  filterOption,
  goals,
  projects,
  measureUnits,
  indicators,
  onUpdate,
  onDelete,
}) => {
  const { countPage, currentPage, currentData, handleChangePage } = usePagination(goals, itemsPerPage)
  console.log('cd', currentData().length)

  const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>({
    show: false,
    confirmMessage: 'Estas seguro que deseas eliminar esta meta?',
    confirmMessageBottom: 'Recuerde que si elimina esta meta usted no podrá editarla',
    textButtonActionConfirm: 'Si',
    textButtonActionCancel: 'No',
  })

  const [detailModalData, setDetailModalData] = useState<ModalDetailItemPdiProps>({
    show: false,
    itemsRender: [],
    textButtonAction: 'Editar',
  })

  const handleConfirmAlertData = (goal: Goal) => {
    setConfirmAlertData(state => ({
      ...state,
      show: true,
      onClickButtonActionConfirm: () => {
        setConfirmAlertData(state => ({ ...state, show: false }))
        onDelete(goal)
      },
    }))
  }

  const handleDetailModal = (goal: Goal, itemsRender: ModalDetailItemPdiProps['itemsRender']) => {
    setDetailModalData(state => ({
      ...state,
      show: true,
      title: `Detalles de la meta`,
      itemsRender,
      onClickButtonAction: () => {
        setDetailModalData(state => ({ ...state, show: false }))
        onUpdate(goal)
      },
    }))
  }

  useEffect(() => {
    handleChangePage(1)
  }, [filterOption?._id])

  const getNameProject = (id: string) => {
    const project = projects.find(project => project._id === id && project.is_active)
    if (!project) return 'No disponible.'
    return project.title
  }

  const getNameMeasureUnit = (id: string) => {
    const measureUnit = measureUnits.find(measureUnit => measureUnit._id === id && measureUnit.is_active)
    if (!measureUnit) return 'No disponible.'
    return measureUnit.name
  }

  const getIndicatorsNumber = (idGoal: string) => {
    return indicators.filter(i => i.idGoal === idGoal && i.is_active).length
  }

  return (
    <>
      <ConfirmAlert
        {...confirmAlertData}
        onClickButtonActionCancel={() => setConfirmAlertData(state => ({ ...state, show: false }))}
        onCloseForButtom={() => setConfirmAlertData(state => ({ ...state, show: false }))}
      />
      <ModalDetailItemPdi
        {...detailModalData}
        maxWidth={1067}
        maxHeight={819}
        onCloseForButtom={() => setDetailModalData(state => ({ ...state, show: false }))}
      />
      {/*********************
                CONTENEDOR METAS
            *********************/}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            ...tableItemsPdiStyles.container,
            justifyContent: currentData().length === 2 ? 'center !important' : 'space-around',
          }}
        >
          {/*****************
                        CARD METAS
                    ******************/}
          {currentData().map(goal => (
            <CardItemPdi
              key={`${goal._id}`}
              title={goal.title}
              text={`No. indicadores: ${5}`}
              smallText={goal.state ? 'Activo' : 'Inactivo'}
              textButtonLeft="Editar"
              textButtonRight="Eliminar"
              textButtonDetail={<IconDirection />}
              onClickButtonDetail={() =>
                handleDetailModal(goal, [
                  {
                    title: 'Títulos',
                    value: goal.title,
                  },
                  {
                    title: 'Numero de meta',
                    value: goal.goalNumber,
                  },
                  {
                    title: 'Numero de indicadores',
                    value: getIndicatorsNumber(goal._id),
                  },
                  {
                    title: 'Unidad de medida',
                    value: getNameMeasureUnit(goal.idMeasureUnit),
                  },
                  {
                    title: 'Nombre del proyecto',
                    value: getNameProject(goal.idProject),
                  },
                  {
                    title: 'Porcentaje de cumplimiento',
                    value: `${goal.percentageOfCompletion}%`,
                  },
                  {
                    title: 'Estado',
                    value: goal.state ? 'Activo' : 'Inactivo',
                  },
                ])
              }
              onClickButtonLeft={() => onUpdate(goal)}
              onClickButtonRight={() => handleConfirmAlertData(goal)}
            />
          ))}
          {currentData().length == 1 && (
            <>
              <Box />
              <Box />
            </>
          )}
          {currentData().length == 2 && <Box />}
        </Box>

        {/****************
                    PAGINACION
                ****************/}
        <Box sx={tableItemsPdiStyles.pagesList}>
          <Pagination
            sx={paginationStyles.paginationNumbers}
            page={currentPage}
            defaultPage={1}
            count={countPage}
            onChange={(e, _page) => handleChangePage(_page)}
          />
        </Box>
      </Box>
    </>
  )
}

export default TableGoals
