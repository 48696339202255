import { Box, Grow, Pagination } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import CardItemPdi from "../../../cards/cardItemPdi/CardItemPdi";
import ModalDetailItemPdi, {
    ModalDetailItemPdiProps,
} from "../../../modals/modalDetailItemPdi/ModalDetailItemPdi";
import IconDirection from "../../../icons/IconDirection";
import usePagination from "../../../../hooks/usePagination";
import { Objetive } from "../../../../interfaces/pdi/objetive";
import { Politics } from "../../../../interfaces/pdi/politics";
import tableItemsPdiStyles from "../tableItemsPdiStyles";
import ConfirmAlert, {
    ConfirmAlertprops,
} from "../../../Alerts/confirmAlert/ConfirmAlert";
import { growTimeoutEffect } from "../../../../helpers/efects";
import paginationStyles from "../../../Pagination.styles";

interface TableObjetivesProps {
    filterOption: Politics | null;
    onUpdate: (objetive: Objetive) => void;
    onDelete: (objetive: Objetive) => void;
    objetives: Objetive[];
    policies: Politics[];
}

const itemsPerPage = 9;

const TableObjetives: FunctionComponent<TableObjetivesProps> = ({
    filterOption,
    objetives,
    policies,
    onUpdate,
    onDelete,
}) => {
    const { countPage, currentPage, currentData, handleChangePage } =
        usePagination(objetives, itemsPerPage);

    const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>(
        {
            show: false,
            confirmMessage: "Estas seguro que deseas eliminar este objetivo?",
            confirmMessageBottom:
                "Recuerde que si elimina este objetivo usted no podrá editarla",
            textButtonActionConfirm: "Si",
            textButtonActionCancel: "No",
        }
    );

    const [detailModalData, setDetailModalData] =
        useState<ModalDetailItemPdiProps>({
            show: false,
            title: `Detalles del objetivo`,
            itemsRender: [],
            textButtonAction: "Editar",
        });

    const handleConfirmAlertData = (objetive: Objetive) => {
        setConfirmAlertData((state) => ({
            ...state,
            show: true,
            onClickButtonActionConfirm: () => {
                setConfirmAlertData((state) => ({ ...state, show: false }));
                onDelete(objetive);
            },
        }));
    };

    const handleDetailModal = (
        objetive: Objetive,
        itemsRender: ModalDetailItemPdiProps["itemsRender"]
    ) => {
        setDetailModalData((state) => ({
            ...state,
            show: true,
            itemsRender,
            onClickButtonAction: () => {
                setDetailModalData((state) => ({ ...state, show: false }));
                onUpdate(objetive);
            },
        }));
    };

    const getNamePolitics = (id: string): string => {
        const politics = policies.find((p) => p._id === id && p.is_active);
        if (!politics) return "No disponible.";
        return politics.title;
    };

    useEffect(() => {
        handleChangePage(1);
    }, [filterOption?._id]);

    return (
        <>
            <ConfirmAlert
                {...confirmAlertData}
                onClickButtonActionCancel={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
                onCloseForButtom={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
            />
            <ModalDetailItemPdi
                {...detailModalData}
                maxWidth={1067}
                maxHeight={819}
                onCloseForButtom={() =>
                    setDetailModalData((state) => ({ ...state, show: false }))
                }
            />
            {/**************************
                CONTENEDOR OBJETIVES
            **************************/}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        ...tableItemsPdiStyles.container,
                        justifyContent:
                            currentData().length === 2
                                ? "center !important"
                                : "space-around",
                    }}
                >
                    {/*****************
                        CARDS DE OBJETIVOS
                    ******************/}
                    {currentData().map((objetive: any, index) => (
                        <Grow
                            key={objetive._id}
                            in
                            timeout={growTimeoutEffect(index)}
                        >
                            <Box>
                                <CardItemPdi
                                    title={`${objetive.title}`}
                                    text={objetive.idTypeObjetive?.name}
                                    smallText={
                                        objetive.state ? "Activo" : "Inactivo"
                                    }
                                    textButtonLeft="Editar"
                                    textButtonRight="Eliminar"
                                    textButtonDetail={<IconDirection />}
                                    onClickButtonDetail={() =>
                                        handleDetailModal(objetive, [
                                            {
                                                title: "Título",
                                                value: objetive.title,
                                            },
                                            {
                                                title: "Descripción",
                                                value: objetive.description,
                                            },
                                            {
                                                title: "Tipo de objetivo",
                                                value: objetive.idTypeObjetive?.name,
                                            },
                                            {
                                                title: "Nombre de la política",
                                                value: getNamePolitics(
                                                    objetive.idPolicies
                                                ),
                                            },
                                            {
                                                title: "Porcentaje de cumplimiento",
                                                value: `${objetive.percentageOfCompletion}%`,
                                            },
                                            {
                                                title: "Estado",
                                                value: objetive.state
                                                    ? "Activo"
                                                    : "Inactivo",
                                            },
                                        ])
                                    }
                                    onClickButtonLeft={() => onUpdate(objetive)}
                                    onClickButtonRight={() =>
                                        handleConfirmAlertData(objetive)
                                    }
                                />
                            </Box>
                        </Grow>
                    ))}
                    {currentData().length == 1 && (
                        <>
                            <Box />
                            <Box />
                        </>
                    )}
                    {currentData().length == 2 && <Box />}
                </Box>

                {/****************
                    PAGINACION
                ****************/}
                <Box sx={tableItemsPdiStyles.pagesList}>
                    <Pagination
                        sx={paginationStyles.paginationNumbers}
                        page={currentPage}
                        defaultPage={1}
                        count={countPage}
                        onChange={(e, _page) => handleChangePage(_page)}
                    />
                </Box>
            </Box>
        </>
    );
};

export default TableObjetives;
