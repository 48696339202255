import { useCallback } from "react";
import { BranchofficeModel } from "../interfaces/branchOffice";
import useFetch, { BasicFetchListProps } from "./useFetch";
import useAuth from "./useAuth";
import { useAppDispatch } from "../redux/Store";
import customAlerts from "../helpers/customAlerts";

type FetchBranchOficcesProps = BasicFetchListProps

const { closeLoading, errorAlert } = customAlerts

const useBranchOffice = () => {
    const { fetchList } = useFetch()

    const dispatch = useAppDispatch()

    const { userData } = useAuth()

    const fetchBranchOffices = useCallback(({ limit = 0, from = 0, active = true }: FetchBranchOficcesProps = {}) => {
        return fetchList<BranchofficeModel>({
            action: 'getBranchOffices',
            params: [
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                errorAlert({ html: error?.message })
            })
            .finally(() => closeLoading())
    }, [fetchList])

    return {
        fetchBranchOffices
    }
}

export default useBranchOffice;