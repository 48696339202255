/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Typography, List, Button, Pagination } from '@mui/material'
import indicadoresStyles from './IndicatingRelations.styles'
import relationsStyles from './Relations.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import useRelations from '../../../../../hooks/pdi/relations/useRelations'
import ItemIndicators from './ItemIndicators'
import Spinner from '../../../../../components/utilities/Spinner'
import useAppDialog from '../../../../../hooks/useAppDialog'

interface IndicatingRelationsProps {
  id: string
  type: 'idProcess' | 'idSubProcess'
  getList: Function
  indicators: any[]
  relatedIndicators: any[]
}
const IndicatingRelations = ({ id, type, getList, indicators, relatedIndicators }: IndicatingRelationsProps) => {
  // HABILITAR/DESHABILITAR EDITAR PORCENTAJE
  const { fetchCreateIndiWeigthProcess, fetchCreateIndiWeigthSubProcess } = useRelations()
  const { showErrorDialog } = useAppDialog()

  // INPUTSELECT
  const [checked, setChecked] = React.useState<any[]>([])

  // Guardar relaciones
  const handleSubmit = async () => {
    // Validar que no halla ningun peso de indicador en 0
    const weigthIndiValues = checked.flatMap((item: any) => item.weigthIndi)
    const valid = weigthIndiValues.some((item2: any) => item2 === 0)
    console.log(weigthIndiValues)
    if (!valid || !weigthIndiValues.length) {
      // Validar que la suma de los pesos no sea mayor ni menor a 100
      const total = weigthIndiValues.reduce((a, b) => a + b, 0)
      if (weigthIndiValues.length && total !== 100) {
        return showErrorDialog({ text: 'La suma de los indicadores debe ser igual a 100' })
      }
      // Si se cumple todo se realiza el cargue de la informacion
      if (type === 'idProcess') {
        fetchCreateIndiWeigthProcess(checked)
      }
      if (type === 'idSubProcess') {
        fetchCreateIndiWeigthSubProcess(checked)
      }
    } else {
      showErrorDialog({ text: 'No pueden haber indicadores en 0' })
    }
    getList()
  }

  // PAGINACION
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [limit, setLimit] = useState<number>(12)
  const [from, setfrom] = useState<number>(0)

  // Indicadores
  const [loading, setLoading] = useState<boolean>(false)

  const [indicatorsAvailable, setIndicatorsAvailable] = useState<any>([])
  const [list, setList] = useState<any>([])
  const [pageList, setPageList] = useState<any>([])

  // Cambiar pagina
  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    const init = (value - 1) * limit
    setfrom(init)
    setPage(value)
  }

  // Cambiar los datos de lista segun la pagina
  const handlePageList = () => {
    const data = list.slice(from, page * limit)
    setPageList(data)
  }

  // Validar que se muestren solo los indicadores disponibles
  const validateIndicator = () => {
    let newList: any[] = [...indicators]
    let checks: any[] = []
    // Recorrer todos los indicadores relacionados y comparar
    indicators.forEach((element: any) => {
      // Verificar si el indicador esta relacionado a algun proceso o subproceso
      const isRelated = relatedIndicators.some((item: any) => item.idIndicator?._id === element._id)
      if (isRelated) {
        const indicatorRelated = relatedIndicators.find((item3: any) => item3.idIndicator?._id === element._id)
        // Verificar si esta relacionado al item elegido
        const isRelatedToThis = indicatorRelated[type]?._id === id
        if (isRelatedToThis) {
          // Checkear el checkbox del indicador que SI esta relacionado al item elegido
          checks = [
            ...checks,
            {
              [type]: indicatorRelated[type]?._id,
              idIndicator: indicatorRelated.idIndicator?._id,
              weigthIndi: indicatorRelated.weigthIndi,
            },
          ]
        } else {
          // Eliminar de la lista de disponibles si NO esta relacionado al item elegido
          const index = newList.findIndex((item: any) => item._id === element._id)
          newList.splice(index, 1)
        }
      }
    })
    // Ordenar la lista mostrando primero los indicadores checkeados
    newList.sort((a, b) => {
      const existA = relatedIndicators.some((item: any) => item.idIndicator?._id === a._id)
      const existB = relatedIndicators.some((item: any) => item.idIndicator?._id === b._id)
      return Number(existB) - Number(existA)
    })
    setChecked(checks)
    setList(newList)
    setIndicatorsAvailable(newList)
  }

  useEffect(() => {
    setChecked([])
    validateIndicator()
  }, [relatedIndicators, id, indicators])

  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    const pages = list.length / 10
    if (Number.isInteger(pages)) {
      setTotalPages(pages)
    } else {
      setTotalPages(Math.trunc(pages) + 1)
    }
    handlePageList()
  }, [list])

  useEffect(() => {
    handlePageList()
  }, [page])

  return (
    <Box>
      {/***********************
                TITULO INDICADORES
            ***********************/}
      <Box className="titleButtonSearch" sx={relationsStyles.titleButtonSearch}>
        <Typography>Indicadores</Typography>

        <ButtonSearch data={indicatorsAvailable} setData={setList} fieldSearch={['name']} />
      </Box>

      <Box sx={indicadoresStyles.container}>
        {/***********************
              LISTA INDICADORES
          ************************/}
        {loading ? (
          <List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
              }}
            >
              <Spinner />
            </Box>
          </List>
        ) : (
          <List>
            {pageList.map((item: any, value: any) => {
              return (
                <ItemIndicators item={item} checked={checked} setChecked={setChecked} id={id} type={type} key={value} />
              )
            })}
          </List>
        )}
        {/********************************
                    BOTONES PAGINACION Y GUARDAR
                ********************************/}
        <Box>
          {/****************
                        PAGINACION
                    ****************/}
          <Pagination count={totalPages} page={page} onChange={changePage} />

          {/******************
                        BOTON GUARDAR
                    ******************/}
          <Button disableTouchRipple onClick={handleSubmit}>
            Guardar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default IndicatingRelations
