import { Theme, useTheme, createTheme } from '@mui/material'

const customizeDateField = (translate: 'dateInitial' | 'dateFinal' | 'dateFulfillment'): Theme => {
  // eslint-disable-next-line
  const theme: any = useTheme()

  return createTheme({
    components: {
      MuiPickersPopper: {
        styleOverrides: {
          root: {
            marginLeft: '50px !important',
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '18px',
            '>div>div': {
              maxWidth: '218px',
              width: '218px',
              minWidth: '218px',
              minHeight: '222px',
              maxHeight: '232px',
              borderRadius: '18px',
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',

              // CALENDARIO
              '>div': {
                position: 'right',
                minHeight: '222px',
                maxHeight: '232px',
                maxWidth: '218px',
                width: '218px',
                minWidth: '218px',
                margin: '0px',
                borderRadius: '18px',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
                overflowY: 'hidden',

                // CONTENEDOR DE HEADER
                '>.MuiPickersCalendarHeader-root': {
                  display: 'block',
                  paddingRight: '24px',
                  marginTop: '30px',
                  marginBottom: '0px',

                  // PRIMER HEADER
                  '>.MuiPickersCalendarHeader-labelContainer': {
                    overflow: 'visible',
                    lineHeight: '18px',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    color: '#1F398F',
                    width: '100%',
                    height: '17px',
                    justifyContent: 'space-between',
                    textAlign: 'center',

                    // BOTON AÑOS Y MESES
                    '>.MuiPickersFadeTransitionGroup-root': {
                      margin: 'auto',
                      '>div': {
                        lineHeight: '14px',
                        marginRight: '0px',
                        width: 'min-content',
                        textTransform: 'capitalize',
                      },
                    },

                    // BOTON DESPLEGABLE DE AÑOS
                    '>button': {
                      display: 'none',
                      height: '17px',
                      justifyContent: 'space-between',
                      textAlign: 'center',
                      padding: '0px',
                      marginRight: '0px',
                      color: theme.palette.secondary.main,
                    },
                  },

                  // SEGUNDO HEADER - BOTONES FLECHAS
                  '>.MuiPickersArrowSwitcher-root': {
                    width: '100%',
                    height: '17px',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginTop: '-11px',
                    '>button': {
                      height: '17px',
                      justifyContent: 'space-between',
                      textAlign: 'center',
                      color: theme.palette.secondary.main,
                      backgroundColor: 'inherit',
                      '>svg': {
                        fontSize: '2rem',
                        width: '7',
                        height: '15',
                        viewBox: '0 0 7 15',
                      },
                    },
                  },
                },

                // CONTENEDOR SEMANAS Y DIAS
                '>.MuiPickersFadeTransitionGroup-root>div': {
                  minHeight: '168px',
                  maxHeight: '168px',
                  '>div': {
                    minHeight: '168px',
                    maxHeight: '168px',
                    marginTop: '8px',

                    // DIAS DE LA SEMANA DEL CALENDARIO
                    '.MuiDayPicker-header': {
                      '>span': {
                        fontFamily: 'Poppins',
                        fontSize: '10px',
                        fontWeight: '300',
                        lineHeight: '15px',
                        width: '16px',
                        height: '15px',
                        margin: '1px 6px',
                        color: '#000000',
                      },
                    },

                    // NUMEROS DEL CALENDARIO
                    '>.PrivatePickersSlideTransition-root': {
                      minHeight: '128px',
                      maxHeight: '128px',
                      overflowX: 'visible',
                      '>div': {
                        '>.MuiDayPicker-weekContainer': {
                          margin: '5px 0',
                          // backgroundColor:
                          //     "pink",

                          // BOTONES DE DIAS
                          '>div, >button': {
                            fontFamily: 'Poppins',
                            fontSize: '10px',
                            fontWeight: '300',
                            lineHeight: '15px',
                            width: '16px',
                            height: '15px',
                            margin: '1px 6px',
                            borderRadius: '2px',
                            color: '#000000',
                          },

                          // BOTON DIA SELECCIONADO
                          '>.Mui-selected': {
                            backgroundColor: '#1F398F',
                            color: '#ffffff',
                          },

                          // BOTON DIA ACTUAL SIN SELECCIONAR
                          '.MuiPickersDay-today:not(.Mui-selected)': {
                            border: `1px solid ${theme.palette.secondary.main}`,
                          },

                          // DIAS FUERA DEL MES ACTUAL
                          '>.MuiPickersDay-dayOutsideMonth': {
                            color: '#CACACA',
                            pointerEvents: 'none',
                          },
                        },
                      },
                    },
                  },

                  // COLUMNA DE AÑOS
                  '>.MuiYearPicker-root': {
                    justifyContent: 'center',
                    maxHeight: '0px',

                    // SCROLL
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '12px',
                      size: '10px',
                      '&-track': {
                        backgroundColor: theme.palette.secondary.main,
                        borderRadius: '10px',
                        border: '5px solid #ffffff',
                      },
                      '&-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '20px',
                        width: '10px',
                      },
                    },

                    // BOTON SELECCIONADO
                    '>.PrivatePickersYear-root': {
                      '>.Mui-selected': {
                        backgroundColor: '#1F398F',
                        color: '#ffffff',
                      },
                      '>button': {
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        margin: '4px 0px',
                        height: '34px',
                        width: '70px',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  })
}

export default customizeDateField
