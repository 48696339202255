import { FunctionComponent } from 'react'
import { Box } from '@mui/material'

// IMPORTADOS
import LogoAmericana from '../../../images/logos/logoAmericanaHorizontalGrande.png'
import headerStyles from './Header.styles'
import ContainerRightHeader from './ContainerRightHeader'
import MenuHeader from './MenuHeader'
import { Link } from 'react-router-dom'

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  return (
    <>
      {/********************/}
      {/* CONTAINER HEADER */}
      {/********************/}
      <Box sx={headerStyles.container}>
        {/******************/}
        {/* LADO IZQUIERDO */}
        {/******************/}
        <Box sx={headerStyles.containerLeft}>
          <Link to="/home">
            <img
              src={LogoAmericana}
              alt="Logo Principal"
              style={{
                width: '172px',
                height: '39px',
                cursor: 'pointer',
              }}
            />
          </Link>
        </Box>

        {/****************/}
        {/* LADO DERECHO */}
        {/****************/}
        <ContainerRightHeader />

        {/********************/}
        {/* MENU HAMBURGUESA */}
        {/********************/}
        <MenuHeader />
      </Box>
    </>
  )
}

export default Header
