import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys = 'modal' | 'opaqueBackground' | 'card' | 'closeButton'
const customModalStyles: StyleComponent<Keys> = {
  modal: {
    overflow: 'show',
    display: 'flex',
  },
  opaqueBackground: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    opacity: 0.7,
  },
  card: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    minWidth: '10px',
    // minHeight : '600px',
    boxShadow: '0px 0px 20px 0px rgba(114, 130, 184, 0.25)',

    // SCROLL
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '12px',
      size: '10px',
      '&-track': {
        backgroundColor: 'transparent',
        borderRadius: '10px',
        border: '5px solid transparent',
      },
      '&-thumb': {
        backgroundColor: 'transparent',
        borderRadius: '20px',
        width: '10px',
      },
    },

    // RESPONSIVE
    '@media (max-width: 1120px)': {
      margin: 'auto 30px auto 30px',
    },

    '@media (max-width: 590px)': {
      margin: 'auto',
    },
  },
  closeButton: {
    width: '0px',
    height: '17px',
    display: 'float',
    float: 'right',
    right: '30px',
    top: '40px',
    ':hover': {
      backgroundColor: 'white',
    },
  },
}

export default customModalStyles
