import { FunctionComponent, useEffect } from 'react'
import CustomDialog, { CustomDialogProps } from '../customDialog/CustomDialog'
import { Box, Button, Typography } from '@mui/material'
import alertDialogStyle from './alertDialog.styles'
import Spinner from '../../utilities/Spinner'
import IconAlertSuccess from '../../icons/IconAlertSuccess'
import IconX from '../../icons/IconX'
import IconTrash from '../../icons/IconTrash'
import IconWarning from '../../icons/IconWarning'

type ButtonConfig = {
  text: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export type AlertDialogProps = Omit<CustomDialogProps, 'children'> & {
  icon?: 'loadingAplanner' | 'success' | 'error' | 'trash' | 'warning'
  title?: string
  text?: string
  smallText?: string
  didClose?: () => void
  showCloseButton?: boolean
  confirmButton?: ButtonConfig
  cancelButton?: ButtonConfig
}
const AlertDialog: FunctionComponent<AlertDialogProps> = ({
  open,
  onClose,
  maxWidth = 'xs',
  icon,
  title,
  text,
  smallText,
  didClose,
  showCloseButton,
  confirmButton,
  cancelButton,
}) => {
  useEffect(() => {
    if(!didClose) return
    if (open) {
      return () => didClose()
    }
   
    didClose()
 
  }, [open, didClose])

  const handleConfirmButtonClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (onClose) onClose()
    if (!confirmButton) return
    if (!confirmButton.onClick) return
    confirmButton.onClick(e)
  }

  const handleCancelButtonClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (onClose) onClose()
    if (!cancelButton) return
    if (!cancelButton.onClick) return
    cancelButton.onClick(e)
  }

  return (
    <CustomDialog open={open} onClose={onClose} maxWidth={maxWidth} showCloseButton={showCloseButton}>
      <Box sx={{ mb: 5 }}>
        {icon && (
          <Box sx={alertDialogStyle.icon}>
            {icon === 'loadingAplanner' && <Spinner width={55} height={50} alignItems="center" />}
            {icon !== 'loadingAplanner' && (
              <Box sx={{ m: 'auto' }}>
                {icon === 'success' && <IconAlertSuccess width={55} height={50} />}
                {icon === 'error' && <IconX width={55} height={50} />}
                {icon === 'trash' && <IconTrash width={55} height={50} color='#7282B8'/>}
                {icon === 'warning' && <IconWarning width={55} height={50} color='#7282B8'/>}
              </Box>
            )}
          </Box>
        )}
        {/* Título */}
        {title && (
          <Box sx={{ ...alertDialogStyle.titleContainer }}>
            <Typography sx={{ ...alertDialogStyle.title }}>{title}</Typography>
          </Box>
        )}
        {/* Texto */}
        {text && (
          <Box sx={{ ...alertDialogStyle.textContainer }}>
            <Typography sx={{ ...alertDialogStyle.text }}>{text}</Typography>
          </Box>
        )}
        {/* Botones de confirmación */}
        {(confirmButton || cancelButton) && (
          <Box sx={{ ...alertDialogStyle.containerButtonsConfirm }}>
            {confirmButton && (
              <Button onClick={handleConfirmButtonClick} sx={{ ...alertDialogStyle.buttonYes }}>
                {confirmButton.text}
              </Button>
            )}
            {cancelButton && (
              <Button onClick={handleCancelButtonClick} sx={{ ...alertDialogStyle.buttonNo }}>
                {cancelButton.text}
              </Button>
            )}
          </Box>
        )}
        {smallText && (
          <Box sx={{ ...alertDialogStyle.textBottomContainer }}>
            <Typography sx={{ ...alertDialogStyle.textBottom }}>{smallText}</Typography>
          </Box>
        )}
      </Box>
    </CustomDialog>
  )
}

export default AlertDialog
