import { FunctionComponent } from "react";

interface IconPersonProp {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconPerson: FunctionComponent<IconPersonProp> = ({
    width = 41,
    height = 41,
    color = "#7282B8",
}) => {
    return (
        <svg
            className="IconPerson"
            width={width}
            height={height}
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.5 35.26C15.375 35.26 10.8445 32.636 8.2 28.7C8.2615 24.6 16.4 22.345 20.5 22.345C24.6 22.345 32.7385 24.6 32.8 28.7C31.4447 30.718 29.614 32.3719 27.4691 33.5158C25.3242 34.6598 22.9309 35.2587 20.5 35.26ZM20.5 6.15C22.1311 6.15 23.6954 6.79794 24.8487 7.95129C26.0021 9.10464 26.65 10.6689 26.65 12.3C26.65 13.9311 26.0021 15.4954 24.8487 16.6487C23.6954 17.8021 22.1311 18.45 20.5 18.45C18.8689 18.45 17.3046 17.8021 16.1513 16.6487C14.9979 15.4954 14.35 13.9311 14.35 12.3C14.35 10.6689 14.9979 9.10464 16.1513 7.95129C17.3046 6.79794 18.8689 6.15 20.5 6.15ZM20.5 0C17.8079 0 15.1422 0.530248 12.655 1.56047C10.1678 2.59069 7.90791 4.10071 6.00431 6.00431C2.15982 9.84881 0 15.0631 0 20.5C0 25.9369 2.15982 31.1512 6.00431 34.9957C7.90791 36.8993 10.1678 38.4093 12.655 39.4395C15.1422 40.4698 17.8079 41 20.5 41C25.9369 41 31.1512 38.8402 34.9957 34.9957C38.8402 31.1512 41 25.9369 41 20.5C41 9.1635 31.775 0 20.5 0Z"
                fill={color}
            />
        </svg>
    );
};

export default IconPerson;
