import { Box, Typography, Button, List, ListItem, ListItemText, IconButton } from '@mui/material'
import IconSuitcase from '../../../components/icons/IconSuitcase'
import companiesStyles from './Companies.styles'
import IconPensil from '../../../components/icons/IconPensil'
import { useEffect, useState } from 'react'
import useImage from '../../../hooks/image/useImage'
import useCompany from '../../../hooks/company/useCompany'
import { useNavigate } from 'react-router-dom'
import { Company } from '../../../interfaces/company'
import useAppDialog from '../../../hooks/useAppDialog'
import IconX from '../../../components/icons/IconX'
import { text } from 'stream/consumers'

interface ImageState {
  file?: any
  preview?: string
}

const Companies = () => {
  const { uploadImage, getUrl } = useImage()
  const { showSuccessDialog, showLoadingDialog } = useAppDialog()
  const { fetchGetCompany, fetchUpdateCompany } = useCompany()
  const navigate = useNavigate()

  const [data, setData] = useState<any>({})
  const [imgPrincipal, setImgPrincipal] = useState<ImageState>()
  const [imgHeader, setImgHeader] = useState<ImageState>()

  useEffect(() => {
    getCompany()
  }, [])

  const getCompany = async () => {
    const company: any = await fetchGetCompany()
    if (company.items.length > 0) {
      setData(company.items[0])
    } else {
      navigate('/companies/create')
    }
  }

  const handleChangeImg = (e: any, option: 1 | 2) => {
    if (option === 1) {
      setImgPrincipal({
        file: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      })
    } else {
      setImgHeader({
        file: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0]),
      })
    }
  }

  const sendImage = async (option: 1 | 2) => {
    let dataImg: any = {}
    if (option === 1 && imgPrincipal?.file) {
      showLoadingDialog()
      const formData = new FormData()
      formData.append('logo', imgPrincipal?.file)

      const response: any = await uploadImage({ action: 'companyUploadImg', form: formData })

      if (response.ok) {
        dataImg = {
          ...dataImg,
          logo: response.data?.key,
          logoLink: response.data?.url ?? response.data?.Url,
        }
        showSuccessDialog({
          text: 'Imagen guardada exitosamente!.',
        })
      }
      setImgPrincipal({})
    }
    if (option === 2 && imgHeader?.file) {
      showLoadingDialog()
      const formData = new FormData()
      formData.append('horizontalLogo', imgHeader?.file)

      const response2: any = await uploadImage({ action: 'companyUploadImgH', form: formData })
      if (response2.ok) {
        dataImg = {
          ...dataImg,
          horizontalLogo: response2.data?.key,
          horizontalLogoLink: response2.data?.url ?? response2.data?.Url,
        }
        showSuccessDialog({
          text: 'Imagen guardada exitosamente!.',
        })
      }
      setImgHeader({})
    }
    const { createdAt, is_active, updatedAt, __v, ...data2 } = data
    fetchUpdateCompany({ ...data2, ...dataImg })
  }

  const getUrlImg = async () => {
    const keys = {
      logo: data?.logo,
      horizontalLogo: data?.horizontalLogo,
    }
    const response: any = await getUrl({ action: 'getUrl', form: keys })
    setImgHeader({
      preview: response?.data?.horizontalLogo,
    })
    setImgPrincipal({
      preview: response?.data?.logo,
    })
  }

  useEffect(() => {
    if (data?._id) {
      getUrlImg()
    }
  }, [data])

  return (
    <>
      {/***********************/}
      {/* CONTENEDOR EMPRESAS */}
      {/***********************/}
      <Box sx={companiesStyles.container}>
        {/*********************/}
        {/* CONTENEDOR TITULO */}
        {/*********************/}
        <Box sx={companiesStyles.containerTitle}>
          {/* ************* */}
          {/* ICONO USUARIO */}
          {/* ************* */}
          <IconSuitcase />

          {/* ****** */}
          {/* TITULO */}
          {/* ****** */}
          <Typography>Información empresa</Typography>

          {/*************/}
          {/* BOTON MÁS */}
          {/*************/}
          <Button onClick={() => navigate(`/companies/update/${data?._id}`)} disableTouchRipple>
            <IconPensil />
            <Typography>Editar</Typography>
          </Button>
        </Box>

        {/*************/}
        {/* CONTENIDO */}
        {/*************/}
        <Box sx={companiesStyles.content}>
          {/************************/}
          {/* CONTENEDOR IZQUIERDO */}
          {/************************/}
          <Box sx={companiesStyles.contentLeft}>
            <List>
              {/* ********************* */}
              {/* CONTENEDOR NOMBRE/NIT */}
              {/* ********************* */}
              <Box>
                {/**********/}
                {/* NOMBRE */}
                {/**********/}
                <ListItem>
                  <ListItemText primary="Nombre de la empresa" secondary={data?.nameCompany ?? 'Sin datos'} />
                </ListItem>

                {/*********/}
                {/* CARGO */}
                {/*********/}
                <ListItem
                  sx={{
                    paddingLeft: '30px !important',
                    '@media (max-width:1150px)': {
                      paddingLeft: '0px !important',
                    },
                  }}
                >
                  <ListItemText primary="No. NIT" secondary={data?.nit ?? 'Sin datos'} />
                </ListItem>
              </Box>

              {/***********/}
              {/* USUARIO */}
              {/***********/}
              <ListItem>
                <ListItemText primary="Actividad de la empresa" secondary={data?.activity ?? 'Sin datos'} />
              </ListItem>

              {/********/}
              {/* AREA */}
              {/********/}
              <ListItem>
                <ListItemText primary="Misión de la empresa" secondary={data?.mission ?? 'Sin datos'} />
              </ListItem>

              {/**********/}
              {/* CORREO */}
              {/**********/}
              <ListItem>
                <ListItemText primary="Visión de la empresa" secondary={data?.vision ?? 'Sin datos'} />
              </ListItem>

              {/************/}
              {/* OFICINAS */}
              {/************/}
              <ListItem>
                <ListItemText
                  primary="E-mail representante legal"
                  secondary={data?.emailLegalRepresentative ?? 'Sin datos'}
                />
              </ListItem>
            </List>
          </Box>

          {/**********************/}
          {/* CONTENEDOR DERECHO */}
          {/**********************/}
          <Box>
            <Box sx={companiesStyles.contentRightPrincipal}>
              <Box
                // variant="contained"
                component="label"
                sx={{ position: 'relative' }}
              >
                {imgPrincipal?.file && (
                  <div style={{ position: 'absolute', right: '40px', width: '0' }}>
                    <IconButton onClick={() => setImgPrincipal({})}>
                      <IconX width={20} height={20} color="red" />
                    </IconButton>
                  </div>
                )}
                <Box>
                  <img width="100%" src={imgPrincipal?.preview} alt="Logo Principal de la empresa" loading="lazy" />
                </Box>
              </Box>

              {/*******************************/}
              {/* BOTON REEMPLAZAR FOTOGRAFIA */}
              {/*******************************/}
              <span>
                {imgPrincipal?.file ? (
                  <Button disableTouchRipple variant="contained" component="label" onClick={() => sendImage(1)}>
                    Guardar nuevo logo
                  </Button>
                ) : (
                  <Button disableTouchRipple variant="contained" component="label">
                    <input hidden accept="image/*" type="file" onChange={(e: any) => handleChangeImg(e, 1)} />
                    Reemplazar Logo
                  </Button>
                )}
              </span>
            </Box>
            <Box sx={companiesStyles.contentRightSecondary}>
              <Box
                // variant="contained"
                component="label"
                sx={{ position: 'relative' }}
              >
                {imgHeader?.file && (
                  <div style={{ position: 'absolute', right: '40px', width: '0' }}>
                    <IconButton onClick={() => setImgHeader({})}>
                      <IconX width={20} height={20} color="red" />
                    </IconButton>
                  </div>
                )}
                <Box>
                  <img src={imgHeader?.preview} alt="Logo secundario de la empresa" loading="lazy" />
                </Box>
              </Box>

              {/*******************************/}
              {/* BOTON REEMPLAZAR FOTOGRAFIA */}
              {/*******************************/}
              <span>
                {imgHeader?.file ? (
                  <Button disableTouchRipple variant="contained" component="label" onClick={() => sendImage(2)}>
                    Guardar nuevo logo
                  </Button>
                ) : (
                  <Button disableTouchRipple variant="contained" component="label">
                    <input hidden accept="image/*" type="file" onChange={(e: any) => handleChangeImg(e, 2)} />
                    Reemplazar Logo
                  </Button>
                )}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Companies
