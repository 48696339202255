import { useTheme } from '@mui/styles'
import { FunctionComponent } from 'react'

interface IconPaProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconPa: FunctionComponent<IconPaProps> = ({ width = 11, height = 11, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main
  return (
    <svg width="142" height="128" viewBox="0 0 142 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3990_1390)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.5 13.4444C0.5 6.32407 6.31278 0.5 13.4 0.5H128.6C135.687 0.5 141.5 6.32407 141.5 13.4444V114.556C141.5 121.676 135.687 127.5 128.6 127.5H13.4C6.31278 127.5 0.5 121.676 0.5 114.556V13.4444ZM130.1 11.9444H11.9V116.056H130.1V11.9444Z"
          fill={color ?? secondary}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.0996 35.1109C22.0996 31.9506 24.6615 29.3887 27.8218 29.3887H49.3774C52.5377 29.3887 55.0996 31.9506 55.0996 35.1109C55.0996 38.2712 52.5377 40.8331 49.3774 40.8331H27.8218C24.6615 40.8331 22.0996 38.2712 22.0996 35.1109ZM110.065 48.5329C112.307 46.2843 115.949 46.2843 118.19 48.5329C120.424 50.7735 120.422 54.3995 118.186 56.6377L94.4924 80.3525C92.7369 82.1096 89.8868 82.1031 88.1394 80.3378L77.0902 69.1758C74.8899 66.9532 74.8962 63.3713 77.1043 61.1564C79.3332 58.9207 82.9561 58.9271 85.177 61.1706L91.2999 67.3559L110.065 48.5329ZM22.0996 63.9998C22.0996 60.8395 24.6615 58.2776 27.8218 58.2776H49.3774C52.5377 58.2776 55.0996 60.8395 55.0996 63.9998C55.0996 67.1601 52.5377 69.722 49.3774 69.722H27.8218C24.6615 69.722 22.0996 67.1601 22.0996 63.9998ZM22.0996 92.8887C22.0996 89.7284 24.6615 87.1665 27.8218 87.1665H49.3774C52.5377 87.1665 55.0996 89.7284 55.0996 92.8887C55.0996 96.049 52.5377 98.6109 49.3774 98.6109H27.8218C24.6615 98.6109 22.0996 96.049 22.0996 92.8887Z"
          fill={color ?? secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_3990_1390">
          <rect width="141" height="127" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconPa
