import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
// IMPORTADOS
import profileStyles from "./profile.styles";
import FormChangePassword from "./FormChangePassword";
import CustomModal from "../../../components/CustomModal";
import IconPerson from "../../../components/icons/IconPerson";
import IconPensil from "../../../components/icons/IconPensil";
import useAuth from "../../../hooks/useAuth";
import UpdateImage from "./UpdateImage";

const Profile = () => {
    const { userData } = useAuth();

    return (
        <>
            {/**********************
                CONTENEDOR PERFIL
            ************************/}
            <Box sx={profileStyles.container}>
                {/*********************
                    CONTENEDOR TITULO
                ***********************/}
                <Box sx={profileStyles.containerTitle}>
                    {/* ICONO USUARIO */}
                    <IconPerson />

                    {/* TITULO */}
                    <Typography>Perfil del usuario</Typography>

                    <Button
                        href={`/users/update/${userData?._id}`}
                        disableTouchRipple
                    >
                        <IconPensil />
                        Editar
                    </Button>
                </Box>

                {/*************
                    CONTENIDO
                **************/}
                <Box sx={profileStyles.content}>
                    {/************************
                        CONTENEDOR IZQUIERDO
                    **************************/}
                    <Box sx={profileStyles.contentLeft}>
                        {/***********************
                            NOMBRES DEL USUARIO
                        ************************/}
                        <Typography>Nombre del Usuario</Typography>

                        <List>
                            {/**********************
                                NOMBRE DEL USUARIO
                            ***********************/}
                            <ListItem sx={{ textTransform: "capitalize" }}>
                                <ListItemText
                                    primary="Nombres del Usuario"
                                    secondary={
                                        userData?.idProfile?.name ??
                                        "Nombre no registrado"
                                    }
                                />
                            </ListItem>

                            {/*************************
                                APELLIDOS DEL USUARIO
                            **************************/}
                            <ListItem sx={{ textTransform: "capitalize" }}>
                                <ListItemText
                                    primary="Apellidos del usuario"
                                    secondary={
                                        userData?.idProfile?.lastName ??
                                        "Apellido no registrado"
                                    }
                                />
                            </ListItem>

                            {/***********
                                EMAIL
                            ************/}
                            <ListItem>
                                <ListItemText
                                    primary="E-mail"
                                    secondary={
                                        userData?.idProfile?.email ??
                                        "Email no cargado"
                                    }
                                />
                            </ListItem>

                            {/********
                                CELULAR
                            *********/}
                            <ListItem>
                                <ListItemText
                                    primary="Celular"
                                    secondary={
                                        userData?.idProfile?.phone ??
                                        "Celular no registrado"
                                    }
                                />
                            </ListItem>
                        </List>

                        {/***********************
                            DATOS RELACIONALES
                        ************************/}
                        <Typography>Datos relacionales</Typography>

                        <List>
                            {/**********
                                PROCESO
                            ***********/}
                            <ListItem>
                                <ListItemText
                                    primary="Proceso"
                                    secondary={"Proceso **estatico**"}
                                />
                            </ListItem>

                            {/*************
                                CARGO
                            **************/}
                            <ListItem>
                                <ListItemText
                                    primary="Cargo"
                                    secondary={
                                        userData?.idPosition?.name ??
                                        "Cargo no registrado"
                                    }
                                />
                            </ListItem>

                            {/*********
                                ÁREA
                            ***********/}
                            <ListItem>
                                <ListItemText
                                    primary="Área"
                                    secondary={
                                        userData?.idDepartament?.name ??
                                        "Área no registrada"
                                    }
                                />
                            </ListItem>

                            {/**********
                                SEDE
                            ***********/}
                            <ListItem>
                                <ListItemText
                                    primary="Sede"
                                    secondary={
                                        userData?.idBranchOffice
                                            ?.nameBranchOffice ??
                                        "Sede no registrada"
                                    }
                                />
                            </ListItem>

                            {/*******************
                                CIUDAD
                            ********************/}
                            <ListItem>
                                <ListItemText
                                    primary="Ciudad"
                                    secondary={
                                        userData?.idBranchOffice?.city ??
                                        "Ciudad no registrada"
                                    }
                                />
                            </ListItem>

                            {/*******************
                                ROL
                            ********************/}
                            <ListItem>
                                <ListItemText
                                    primary="Rol"
                                    secondary={
                                        userData?.idProfile?.idRol?.name ??
                                        "Rol no registrado"
                                    }
                                />
                            </ListItem>
                        </List>

                        {/***********************
                            DATOS RELACIONALES
                        ************************/}
                        <Typography>Datos relacionales</Typography>

                        <List>
                            {/*******************
                                USUARIO
                            ********************/}
                            <ListItem>
                                <ListItemText
                                    primary="Usuario"
                                    secondary={
                                        userData?.userName ??
                                        "Usuario no registrado"
                                    }
                                />
                            </ListItem>

                            {/***********************
                                CLAVE / CONTRASEÑA
                            ************************/}
                            <ListItem>
                                <ListItemText primary="Clave" />

                                {/* CLAVE OCULTA */}
                                {/* <Input
                                    disabled
                                    defaultValue={"Clave **estatico**"}
                                    type="password"
                                    sx={profileStyles.keyHidden}
                                /> */}

                                {/* CAMBIAR CONTRASEÑA */}

                                <CustomModal text="Cambiar contraseña">
                                    <FormChangePassword />
                                </CustomModal>
                            </ListItem>
                        </List>
                    </Box>

                    {/**********************
                        CONTENEDOR DERECHO
                    ************************/}
                    <UpdateImage />
                </Box>
            </Box>
        </>
    );
};

export default Profile;
