import { FunctionComponent } from "react";

interface IconGoalsProps {
    width?: number | string;
    height?: number | string;
}

const IconGoals: FunctionComponent<IconGoalsProps> = ({
    width = 34,
    height = 40,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 34 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2C0 0.89543 0.895431 0 2 0H29.73C30.4901 0.000285029 31.2351 0.217111 31.8763 0.625087C32.5176 1.03306 33.0294 1.6153 33.3517 2.30362C33.6741 2.99193 33.7936 3.75784 33.6964 4.51167C33.5992 5.26549 33.2892 5.976 32.8028 6.56L26.6033 14L32.8025 21.4397C33.2889 22.0237 33.5992 22.7345 33.6964 23.4883C33.7936 24.2422 33.6741 25.0081 33.3517 25.6964C33.0294 26.3847 32.5176 26.9669 31.8763 27.3749C31.2351 27.7829 30.4908 27.9997 29.7308 28H4V38C4 39.1046 3.10457 40 2 40C0.895431 40 0 39.1046 0 38V2ZM4 24H29.7293L22.4635 15.2803C21.8455 14.5386 21.8455 13.4614 22.4635 12.7197L29.7293 4L4 4V24Z"
                fill="#7282B8"
            />
        </svg>
    );
};

export default IconGoals;
