import { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'

const useUsers = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchFindOne, fetchDisable } = useFetch()
  const { showErrorDialog, showSuccessDialog, showLoadingDialog } = useAppDialog()

  const fetchGetUsers = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getUsers',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const fetchGetOneUser = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneUser',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchCreateUser = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchCreate({ action: 'createUser', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Usuario creado exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchCreate]
  )

  const fetchUpdateUser = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchUpdate({ action: 'updateUser', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Usuario actualizado exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchUpdate]
  )

  const fetchDeleteUser = useCallback(
    async (body: any, done?: () => void) => {
      showLoadingDialog()
      return await fetchDisable({ action: 'disableUser', body })
        .then(() => {
          showSuccessDialog({
            text: 'Usuario eliminado exitosamente!.',
            didClose: done,
          })
        })
        .catch(error => showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' }))
    },
    [fetchDisable]
  )

  return {
    fetchGetUsers,
    fetchGetOneUser,
    fetchCreateUser,
    fetchUpdateUser,
    fetchDeleteUser,
  }
}

export default useUsers
