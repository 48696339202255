import { FunctionComponent } from "react";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { AutocompleteProps } from "@mui/material/Autocomplete";

type FilterPdiProps = Omit<
    AutocompleteProps<any, any, any, any>,
    "renderInput" | "sx" | "blurOnSelect" | "size"
> & {
    labelInput: string;
    placeholder?: string;
    minWidth?: string | number;
};

const FilterPdi: FunctionComponent<FilterPdiProps> = ({
    labelInput,
    placeholder,
    minWidth = 140,
    ...props
}) => {
    return (
        <Autocomplete
            {...props}
            blurOnSelect
            size="small"
            sx={{
                // minWidth: 350,
                // minWidth: 140,
                minWidth: { minWidth },

                ".Mui-focused": {
                    minWidth: 350,
                },

                ".MuiAutocomplete-hasClearIcon": {
                    minWidth: 350,
                },

                ">div": {
                    backgroundColor: "#FFFFFF",
                    border: "0px solid red",
                    borderRadius: "92px",
                    boxShadow: "0px 0px 10px rgba(114, 130, 184, 0.25)",
                    // LABEL
                    ">label": {
                        color: "#1F398F",
                        fontSize: "16px",
                        paddingTop: "0px",
                        paddingLeft: "5px",
                    },
                    ">.MuiInputLabel-shrink": {
                        marginTop: "-8px",
                    },
                    ">div": {
                        ">input": {
                            // backgroundColor: "red",
                            paddingLeft: "11px !important",
                        },
                        ">.MuiAutocomplete-endAdornment": {
                            backgroundColor: "transparent",
                            marginTop: "-6px",
                            // BOTON X
                            ">.MuiAutocomplete-clearIndicator": {
                                ":hover": {
                                    backgroundColor: "transparent",
                                },
                                ":active": {
                                    backgroundColor: "transparent",
                                },
                                ">svg": {
                                    // width: "40px",
                                    // height: "36px",
                                    color: "#7282B8",
                                },
                            },
                            // BOTON FLECHA
                            ">.MuiAutocomplete-popupIndicator": {
                                ":hover": {
                                    backgroundColor: "transparent",
                                },
                                ":active": {
                                    backgroundColor: "transparent",
                                },
                                ">svg": {
                                    width: "40px",
                                    height: "36px",
                                    color: "#7282B8",
                                },
                                marginRight: "-9px",
                            },
                        },
                        ">fieldset": {
                            borderWidth: "0px",
                        },
                        ">.MuiOutlinedInput-notchedOutline": {
                            border: "0px solid red !important",
                        },
                        ":focus": {},
                    },
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelInput}
                    placeholder={placeholder}
                    title={labelInput}
                />
            )}
            disableCloseOnSelect={true}
            disablePortal={true}
            openOnFocus={false}
            // DROPDOWN
            PaperComponent={({ children }) => (
                <Paper
                    sx={{
                        // background: "yellow",
                        minWidth: 350,
                        width: "350px !important",
                        marginLeft: "0px",
                        // marginLeft: "auto",
                        marginRight: "0px",
                        // marginRight: "auto",
                        textAlign: "left",
                        borderRadius: "20px",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        lineHeight: "24px",
                        color: "#000000",
                        // transform: "translate(1007px, 361px)",
                        // transform: "translate(0px, 0px) !important",
                        ":focus": { backgroundColor: "red" },
                    }}
                >
                    {children}
                </Paper>
            )}
        />
    );
};

export default FilterPdi;
