import { FunctionComponent } from 'react'
import { useTheme } from '@mui/styles'

interface IconCalendarProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconCalendar: FunctionComponent<IconCalendarProps> = ({ width = 19, height = 19, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.15 1.9H14.25V0.95C14.25 0.698044 14.1499 0.456408 13.9718 0.278249C13.7936 0.100089 13.552 0 13.3 0C13.048 0 12.8064 0.100089 12.6282 0.278249C12.4501 0.456408 12.35 0.698044 12.35 0.95V1.9H6.65V0.95C6.65 0.698044 6.54991 0.456408 6.37175 0.278249C6.19359 0.100089 5.95196 0 5.7 0C5.44804 0 5.20641 0.100089 5.02825 0.278249C4.85009 0.456408 4.75 0.698044 4.75 0.95V1.9H2.85C2.09413 1.9 1.36922 2.20027 0.834746 2.73475C0.300267 3.26922 0 3.99413 0 4.75V16.15C0 16.9059 0.300267 17.6308 0.834746 18.1653C1.36922 18.6997 2.09413 19 2.85 19H16.15C16.9059 19 17.6308 18.6997 18.1653 18.1653C18.6997 17.6308 19 16.9059 19 16.15V4.75C19 3.99413 18.6997 3.26922 18.1653 2.73475C17.6308 2.20027 16.9059 1.9 16.15 1.9ZM17.1 16.15C17.1 16.402 16.9999 16.6436 16.8218 16.8218C16.6436 16.9999 16.402 17.1 16.15 17.1H2.85C2.59804 17.1 2.35641 16.9999 2.17825 16.8218C2.00009 16.6436 1.9 16.402 1.9 16.15V9.5H17.1V16.15ZM17.1 7.6H1.9V4.75C1.9 4.49804 2.00009 4.25641 2.17825 4.07825C2.35641 3.90009 2.59804 3.8 2.85 3.8H4.75V4.75C4.75 5.00196 4.85009 5.24359 5.02825 5.42175C5.20641 5.59991 5.44804 5.7 5.7 5.7C5.95196 5.7 6.19359 5.59991 6.37175 5.42175C6.54991 5.24359 6.65 5.00196 6.65 4.75V3.8H12.35V4.75C12.35 5.00196 12.4501 5.24359 12.6282 5.42175C12.8064 5.59991 13.048 5.7 13.3 5.7C13.552 5.7 13.7936 5.59991 13.9718 5.42175C14.1499 5.24359 14.25 5.00196 14.25 4.75V3.8H16.15C16.402 3.8 16.6436 3.90009 16.8218 4.07825C16.9999 4.25641 17.1 4.49804 17.1 4.75V7.6Z"
        fill={color ?? secondary}
      />
    </svg>
  )
}

export default IconCalendar
