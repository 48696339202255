import { useState, useCallback } from "react";
import GenericAlert, { GenericAlertProps } from "../../components/Alerts/GenericAlert/GenericAlert";
import { ChangeActiveProgram, NewProgram, UpdateProgram } from "../../interfaces/pdi/program";
import useFetch from "../useFetch";

interface FetchProgramsProps {
    idPdi: string
    limit?: number
    from?: number
    active?: boolean
}

const useProgram = () => {

    const { fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()

    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })

    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )

    const fetchGetPrograms = useCallback(async ({ idPdi, limit = 0, from = 0, active = true }: FetchProgramsProps) => {
        return fetchList<any>({
            action: 'getPrograms',
            params: [
                ['idPdi', `${idPdi}`],
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchList])

    const fetchCreateProgram = useCallback((body: NewProgram, done?: (_id: string) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createProgram', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El programa se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_id),
                })
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchCreate])

    const fetchUpdateProgram = useCallback((body: UpdateProgram, done?: (_updateValues: UpdateProgram) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate<UpdateProgram>({ action: 'updateProgram', body })
            .then(_updateValues => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El programa se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchUpdate])

    const fetchDisableProgram = useCallback((body : ChangeActiveProgram, done? : () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disableProgram', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `El programa ha sido ${body.is_active ? 'habilitado' : 'eliminado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return {
        fetchGetPrograms,
        fetchCreateProgram,
        fetchUpdateProgram,
        fetchDisableProgram,
        ViewAlert
    }
}

export default useProgram;