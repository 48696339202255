/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, TextField, Typography, ThemeProvider, createTheme, Divider, useTheme, Theme } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import 'dayjs/locale/es-mx'
import '../../../styles.css'

// IMPORTADOS
import IconCalendar from '../../../components/icons/IconCalendar'
import { dateToString, getWeekDayName } from '../../../services/ParseDate'
import CollapseEventInfo from './CollapseEventInfo'

// TEMA Y ESTILOS DEL CALENDARIO
const themeDatePicker = (): Theme => {
  // eslint-disable-next-line
  const theme: any = useTheme()

  return createTheme({
    components: {
      MuiPickerStaticWrapper: {
        styleOverrides: {
          root: {
            // CONTENEDOR CALENDARIO
            '>div': {
              // backgroundColor: "blue",
              minHeight: '222px',
              maxHeight: '232px',
              minWidth: 'auto',
              paddingTop: '5px',
              paddingBottom: '5px',
              '>div': {
                minHeight: '232px',
                // backgroundColor: "blue",
                '>div': {
                  backgroundColor: '#ffffff',
                  // backgroundColor: "blue",
                  maxWidth: '218px',
                  width: '218px',
                  minWidth: '218px',
                  minHeight: '222px',
                  maxHeight: '232px',
                  borderRadius: '18px',
                  boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

                  // CALENDARIO
                  '>div': {
                    // backgroundColor: "pink",
                    minHeight: '222px',
                    maxHeight: '232px',
                    maxWidth: '218px',
                    width: '218px',
                    minWidth: '218px',
                    margin: '0px',
                    borderRadius: '18px',
                    boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',
                    overflowY: 'hidden',

                    // CONTENEDOR DE HEADER
                    '>.MuiPickersCalendarHeader-root': {
                      // backgroundColor: "red",
                      display: 'block',
                      paddingRight: '24px',
                      marginTop: '30px',
                      marginBottom: '0px',

                      // PRIMER HEADER
                      '>.MuiPickersCalendarHeader-labelContainer': {
                        // backgroundColor: "yellow",
                        overflow: 'visible',
                        lineHeight: '18px',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        color: '#1F398F',
                        width: '100%',
                        height: '17px',
                        justifyContent: 'space-between',
                        textAlign: 'center',

                        // BOTON AÑOS Y MESES
                        '>.MuiPickersFadeTransitionGroup-root': {
                          margin: 'auto',
                          '>div': {
                            // backgroundColor:
                            //     "pink",
                            lineHeight: '14px',
                            marginRight: '0px',
                            width: 'min-content',
                            textTransform: 'capitalize',
                          },
                        },

                        // BOTON DESPLEGABLE DE AÑOS
                        '>button': {
                          display: 'none',
                          height: '17px',
                          justifyContent: 'space-between',
                          textAlign: 'center',
                          padding: '0px',
                          marginRight: '0px',
                          color: theme.palette.secondary.main,
                        },
                      },

                      // SEGUNDO HEADER - BOTONES FLECHAS
                      '>.MuiPickersArrowSwitcher-root': {
                        width: '100%',
                        height: '17px',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        marginTop: '-11px',
                        '>button': {
                          height: '17px',
                          justifyContent: 'space-between',
                          textAlign: 'center',
                          color: theme.palette.secondary.main,
                          ':hover': {
                            backgroundColor: 'inherit',
                          },
                          '>svg': {
                            fontSize: '2rem',
                            width: '7',
                            height: '15',
                            viewBox: '0 0 7 15',
                          },
                        },
                      },
                    },

                    // CONTENEDOR SEMANAS Y DIAS
                    '>.MuiPickersFadeTransitionGroup-root>div': {
                      minHeight: '168px',
                      maxHeight: '168px',
                      '>div': {
                        minHeight: '168px',
                        maxHeight: '168px',
                        marginTop: '8px',

                        // DIAS DE LA SEMANA DEL CALENDARIO
                        '.MuiDayPicker-header': {
                          '>span': {
                            fontFamily: 'Poppins',
                            fontSize: '10px',
                            fontWeight: '300',
                            lineHeight: '15px',
                            width: '16px',
                            height: '15px',
                            margin: '1px 6px',
                            color: '#000000',
                          },
                        },

                        // NUMEROS DEL CALENDARIO
                        '>.PrivatePickersSlideTransition-root': {
                          minHeight: '128px',
                          maxHeight: '128px',
                          overflowX: 'visible',
                          '>div>div': {
                            margin: '5px 0',

                            // BOTONES DE DIAS
                            '>div, >button': {
                              fontFamily: 'Poppins',
                              fontSize: '10px',
                              fontWeight: '300',
                              lineHeight: '15px',
                              width: '16px',
                              height: '15px',
                              margin: '1px 6px',
                              borderRadius: '2px',
                              color: '#000000',
                            },

                            // BOTON DIA SELECCIONADO
                            '>.Mui-selected': {
                              backgroundColor: '#1F398F',
                              color: '#ffffff',
                            },

                            // BOTON DIA ACTUAL SIN SELECCIONAR
                            '.MuiPickersDay-today:not(.Mui-selected)': {
                              border: `1px solid ${theme.palette.secondary.main}`,
                            },

                            // DIAS FUERA DEL MES ACTUAL
                            '>.MuiPickersDay-dayOutsideMonth': {
                              color: '#CACACA',
                              pointerEvents: 'none',
                            },

                            // DIAS FUERA DEL MES ACTUAL
                            '>.MuiPickersDay-root': {
                              borderBottom: '1px solid red',
                            },
                          },
                        },
                      },

                      // COLUMNA DE AÑOS
                      '>.MuiYearPicker-root': {
                        justifyContent: 'center',
                        maxHeight: '0px',

                        // SCROLL
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '12px',
                          size: '10px',
                          '&-track': {
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: '10px',
                            border: '5px solid #ffffff',
                          },
                          '&-thumb': {
                            backgroundColor: '#1F398F',
                            borderRadius: '20px',
                            width: '10px',
                          },
                        },

                        // BOTON SELECCIONADO
                        '>.PrivatePickersYear-root': {
                          '>.Mui-selected': {
                            backgroundColor: '#1F398F',
                            color: '#ffffff',
                          },
                          '>button': {
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            margin: '4px 0px',
                            height: '34px',
                            width: '70px',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  })
}

interface DatePickerProps {
  specialDates?: string[]
  setSelectDate?: Function
  infoAgenda?: any[]
  name?: string
  urlToRedirect?: string
}

const DatePicker = ({ specialDates, setSelectDate, infoAgenda = [], name, urlToRedirect }: DatePickerProps) => {
  const navigate = useNavigate()
  const [date, setDate] = useState<Dayjs | null>()
  const [finalDate, setFinalDate] = useState<string | null>(null)
  const [openCollapse, setOpenCollapse] = useState<string | null>(null)

  // TEMA
  const myTheme = themeDatePicker()

  const [selectedDay, setSelectedDay] = useState<any>(null)

  const handleClick = (day: any) => {
    setFinalDate(null)
    if (selectedDay && dateToString(day.$d) === dateToString(selectedDay[0]?.$d)) {
      setSelectedDay(null)
      setSelectDate && setSelectDate(null)
    } else {
      setSelectedDay([day])
      setSelectDate && setSelectDate(day.$d)
    }
  }
  const handleClickCard = (startDate: any, finalDate: any, id: any) => {
    if (!openCollapse || openCollapse !== id) {
      setFinalDate(finalDate.split('T')[0])
      setSelectedDay([new Date(startDate.split('T')[0])])
      setOpenCollapse(id)
    } else {
      setSelectedDay(null)
      setOpenCollapse(null)
      setFinalDate(null)
    }
  }

  // TEMA DEL CALENDARIO
  const theme: any = useTheme()
  const primary = theme.palette.primary.main
  const secondary = theme.palette.secondary.main
  const tertiary = '#B4BDDB'

  const renderDay = (day: any, selectedDays: any[], pickersDayProps: any) => {
    const isDateWithEvent = specialDates?.includes(day.toISOString().split('T')[0])
    const isSelected =
      selectedDay?.some((item: any) => dateToString(item.$d) === dateToString(day.$d)) ??
      selectedDays.some((item: any) => dateToString(item.$d) === dateToString(day.$d))
    const isToday = selectedDays.some((item: any) => dateToString(item.$d) === dateToString(day.$d))
    const isNotThisMonth = pickersDayProps.outsideCurrentMonth

    let dayContentStyle: any = {
      padding: '1px 6px',
      margin: '0px',
      borderRadius: '0%',
    }

    let dayStyles: any = {
      margin: '0px',
      cursor: 'pointer',
      textAlign: 'center',
      borderRadius: '20%',
    }

    // Estilos para el dia actual
    if (isToday) {
      dayStyles.height = '15px !important'
      dayStyles.border = `1px Solid ${secondary}`
      dayStyles.color = secondary
      dayStyles.height = '-webkit-fill-available'
      dayStyles.display = 'flex'
      dayStyles.alignItems = 'center'
      dayStyles.justifyContent = 'center'
      if (isDateWithEvent) {
        dayStyles.border = `1px Solid ${primary}`
        dayStyles.color = primary
        if (isSelected) {
          dayStyles.background = primary
          dayStyles.border = `1px Solid ${primary}`
          dayStyles.color = secondary
        } else {
          dayStyles.background = 'none'
          dayStyles.border = `1px Solid ${primary}`
          dayStyles.color = primary
        }
      }else{
        if (isSelected) {
          dayStyles.background = secondary
          dayStyles.border = `1px Solid ${secondary}`
          dayStyles.color = "#fff"
        }
      }
    } else if (isDateWithEvent) {
      dayStyles.height = '15px !important'
      dayStyles.border = `1px Solid ${tertiary}`
      dayStyles.color = tertiary
      dayStyles.height = '-webkit-fill-available'
      dayStyles.display = 'flex'
      dayStyles.alignItems = 'center'
      dayStyles.justifyContent = 'center'
      if (isSelected) {
        dayStyles.background = secondary
        dayStyles.border = `1px Solid ${secondary}`
        dayStyles.color = primary
      }
    } else if (isSelected) {
      dayStyles.background = primary
      dayStyles.border = `1px Solid ${primary}`
      dayStyles.color = '#fff'
    }

    // Estilos para marcar un rango de fechas siempre que exista una fecha final
    if (finalDate && selectedDay) {
      if (
        day.toISOString().split('T')[0] <= finalDate &&
        day.toISOString().split('T')[0] >= selectedDay[0].toISOString().split('T')[0]
      ) {
        dayContentStyle.background = primary
        dayStyles.color = '#fff'
        dayStyles.border = `none`
        if (isToday) {
          dayStyles.color = secondary
        }
      }

      if (day.toISOString().split('T')[0] === finalDate) {
        dayContentStyle.borderRadius = '0 15% 15% 0'
      }

      if (day.toISOString().split('T')[0] === selectedDay[0].toISOString().split('T')[0]) {
        dayContentStyle.borderRadius = '15% 0 0 15%'
      }
    }

    // Estilos para los dias que no pertenecen al mes
    if (isNotThisMonth) {
      dayStyles.color = '#cacaca'
      dayStyles.cursor = 'default'
    }

    return (
      <div style={dayContentStyle}>
        <div
          key={day.$d}
          style={{
            ...dayStyles,
            /* Estilos adicionales según sea necesario */
          }}
          onClick={() => {
            !isNotThisMonth && handleClick(day)
          }}
        >
          {day.$D}
        </div>
      </div>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        // width: "664px",
        width: '680px',
        minHeight: '616px',
        marginBottom: '44px',
        marginRight: '44px',
        borderRadius: '30px',
        boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',

        '>.tituloCalendario': {
          display: 'flex',
          alignItems: 'center',
          margin: '49px 47px 19px 47px',
          '>svg': {
            marginRight: '7px',
          },
          '>p': {
            fontSize: '20px',
            color: '#1F398F',
            fontWeight: '400',
          },
        },

        '@media(max-width: 1440px)': {
          width: '100%',
          marginRight: '0px',
        },
      }}
    >
      {/**********************
        TITULO CALENDARIO
      ***********************/}
      <Box className="tituloCalendario">
        <IconCalendar />

        <Typography>{name ?? 'Calendario'}</Typography>
      </Box>

      {/**************
        CALENDARIO
      ***************/}
      <ThemeProvider theme={myTheme}>
        <Box
          sx={{
            width: '565.3px',
            margin: 'auto',

            // RESPONSIVE
            '@media (max-width: 650px)': {
              width: '100%',
            },
          }}
        >
          <LocalizationProvider locale="es-mx" dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              className="aqui"
              // views={["year", "day"]}
              displayStaticWrapperAs="desktop"
              value={date}
              // formatStyle={"large"}
              // readOnly={true}
              onChange={newValue => {
                setDate(newValue)
              }}
              renderInput={params => <TextField {...params} />}
              showDaysOutsideCurrentMonth={true}
              renderDay={renderDay}
            />
          </LocalizationProvider>
        </Box>
      </ThemeProvider>

      {/**********************
        CONTENEDOR AGENDA
      ************************/}
      <Box
        className="contentDiary"
        sx={{
          // backgroundColor: "pink",
          margin: '25px 53px auto 58px',
          maxHeight: '318px',
          padding: '10px',
          marginBottom: '46px',

          // SCROLLBAR
          overflowY: 'auto !important',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '12px',
            height: '100px',
            '&-track': {
              backgroundColor: theme => theme.palette.secondary.main,
              borderRadius: '10px',
              border: '5px solid #ffffff',
            },
            '&-thumb': {
              backgroundColor: '#1F398F',
              borderRadius: '20px',
              width: '10px',
            },
          },
        }}
      >
        {/**********************
          CONTENEDOR ITERADO
        ************************/}
        {infoAgenda.length > 0 ? (
          infoAgenda.map((item: any, index) => (
            <>
              <Box
                className="scale"
                key={index + item._id}
                sx={{
                  // backgroundColor: "purple",
                  display: 'flex',
                  paddingRight: '5px',
                  height: '45px',
                  marginTop: '6px',
                  marginBottom: '7px',
                  cursor: urlToRedirect ? 'pointer' : 'default',

                  // RESPONSIVE
                  '@media (max-width: 490px)': {
                    height: 'auto',
                  },
                }}
                onClick={() => handleClickCard(item.startDate, item.finalDate, item._id)}
              >
                {/******************************
              LATERAL DE COLOR ALTERNADO
            *******************************/}
                <Box
                  sx={{
                    backgroundColor: index % 2 ? '#1F398F' : theme => theme.palette.secondary.main,
                    width: '9px',
                    height: '38px',
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    margin: 'auto 0px auto 0px',
                    // ":before": {
                    //     counterIncrement: infoAgenda.length,
                    //     // content: infoAgenda.length,
                    //     content: infoAgenda,
                    // },
                    // ":nth-child(2n+1)": {
                    //     backgroundColor: "red",
                    // },
                    // ":nth-child(2n)": {
                    //     backgroundColor: "blue",
                    // },
                  }}
                ></Box>

                {/********************
              CONTENEDOR TAREA
            **********************/}
                <Box
                  sx={{
                    // backgroundColor: "blue",
                    backgroundColor: '#ffffff',
                    display: 'flex',
                    width: '100%',
                    borderRadius: '7px',
                    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.08)',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    {/**************** 
                  NUMERO Y DIA
                *****************/}
                    <Box
                      sx={{
                        width: '62px',
                        textAlign: 'center',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        '>p': {
                          fontSize: '15px',
                          fontWeight: '600',
                          lineHeight: '15px',
                          '>span': {
                            fontSize: '10px',
                            fontWeight: '300',
                            lineHeight: '12px',
                            display: 'block',
                          },
                        },
                      }}
                    >
                      <Typography component="p">
                        {item.startDate.split('T')[0].split('-')[2]}

                        <Typography component="span">
                          {getWeekDayName(item.startDate.split('T')[0]).substring(0, 2)}
                        </Typography>
                      </Typography>
                    </Box>

                    {/***********
                  DIVISOR
                ************/}
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: '37px',
                        margin: 'auto 0',
                        borderColor: '#cacaca',
                      }}
                    />

                    {/*******************
                  FECHA Y DETALLE
                ********************/}
                    <Box
                      sx={{
                        paddingLeft: '21px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        '>p': {
                          fontSize: '8px',
                          fontWeight: '300',
                          '>span': {
                            fontSize: '10px',
                            fontWeight: '600',
                            display: 'block',
                          },
                        },
                      }}
                    >
                      <Typography component="p">
                        {item.startDate.split('T')[0] ?? ''} - {item.finalDate.split('T')[0] ?? ''}
                        <Typography component="span">{item.name ?? ''}</Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <CollapseEventInfo open={openCollapse === item._id} data={item} />
            </>
          ))
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography component="p" sx={{ color: '#9d9d9d' }}>
              No tienes eventos para{' '}
              <span>
                {!selectedDay || dateToString(selectedDay[0]?.$d) === dateToString(new Date()) ? 'hoy' : 'esta fecha'}
              </span>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default DatePicker
