import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys =
    | "containerOrden"
    | "inputOrdenEnlace"
    | "inputBaseOrdenEnlace"
    | "containerEnlace"
    | "constainerAreaName";

const formPoliticsStyles: StyleComponent<Keys> = {
    // ORDEN
    containerOrden: {
        marginBottom: "30px",
        marginRight: "30px",
        width: "251px",
    },
    inputOrdenEnlace: {
        backgroundColor: "#F1F3F8",
        height: "39px",
        borderRadius: "200px",
        ">div": {
            width: "100%",
        },
    },
    inputBaseOrdenEnlace: {
        height: "100%",
        fontSize: "16px",
        fontWeight: "500",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "500px",
    },

    // ENLACE
    containerEnlace: {
        marginBottom: "30px",
        width: "100%",
    },

    constainerAreaName: {
        width: "62%",
    },
};

export default formPoliticsStyles;
