import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

// IMPORTADOS
import CustomTitle from '../../../../components/CustomTitle'
import doAssignmentStyles from './DoAssignment.styles'
import FullfillmentDoAssignmentIndicating from './doAssignmentComponents/FullfillmentDoAssignmentIndicating'
import FormDoAssignmentIndicating from './doAssignmentComponents/FormDoAssignmentIndicating'
import TableDoAssignmentIndicating from './doAssignmentComponents/TableDoAssignmentIndicating'
import useMeasure from '../../../../hooks/do/useMeasure'
import { useParams } from 'react-router-dom'
import useFormula from '../../../../hooks/do/useFormula'

const DoAssignmentIndicating = () => {
  const { fetchGetOneAssignment, fetchGetOneAssignmentByIndicator } = useMeasure()
  const { fetchGetFormula } = useFormula()
  const params: any = useParams()
  const [data, setData] = useState<any>({})
  const [dataTable, setDataTable] = useState<any>([])
  const [measureEdit, setMeasureEdit] = useState<any>({})

  const getData = async () => {
    if (params.id) {
      const res = await fetchGetOneAssignment(params.id)
      if (res) {
        setData(res)
      }
    } else {
      if (params.idindicator) {
        const resp = await fetchGetOneAssignmentByIndicator(params.idindicator)
        if (resp) {
          setData(resp)
        }
      }
    }
  }

  const getDataTable = async () => {
    console.log(1)
    const response: any = await fetchGetFormula(data?.idIndicator?._id)
    setDataTable(response.reverse())
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data?.idIndicator?._id) {
      getDataTable()
    }
  }, [data])

  return (
    <Box sx={doAssignmentStyles.container}>
      <CustomTitle title="Asignación" subTitle={data.idIndicator?.name} />

      {/******************************/}
      {/* CUMPLIMIENTO DEL INDICADOR */}
      {/******************************/}
      <FullfillmentDoAssignmentIndicating data={data} />

      {/******************************/}
      {/* CUMPLIMIENTO DEL INDICADOR */}
      {/******************************/}
      <FormDoAssignmentIndicating data={data} measureEdit={measureEdit} listDataTable={getDataTable} />

      {/*********************************/}
      {/* TABLA DE ASIGNACION DE TAREAS */}
      {/*********************************/}
      <TableDoAssignmentIndicating dataTable={dataTable} setMeasureEdit={setMeasureEdit} />
    </Box>
  )
}

export default DoAssignmentIndicating
