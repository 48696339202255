import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, IconButton } from '@mui/material'
import profileStyles from './profile.styles'
import IconUpload from '../../../components/icons/IconUpload'
import customAlerts from '../../../helpers/customAlerts'
import IconX from '../../../components/icons/IconX'
import useImage from '../../../hooks/image/useImage'
import useBranchOffice from '../../../hooks/branchOffice/useBranchOffice'
import useUsers from '../../../hooks/users/useUsers'

interface ImgStateProps {
  preview?: string
  file?: any
}
const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg']

const dropAreaActive = {
  border: 'dashed 2px #fff',
  background: '#7282B8 !important',
}

const UpdateImage = ({ data }: any) => {
  const { errorAlert } = customAlerts
  const { fetchUpdateUser } = useUsers()
  const { uploadImage } = useImage()

  const [dropAreaStyles, setDropAreaStyles] = useState({})
  const [drag, setDrag] = useState(false)
  const [img, setImg] = useState<ImgStateProps>()

  useEffect(() => {
    const dropArea = document.querySelector('#drop-area')

    // Evento dragover - acciones que debe hacer al detectar que entra el archivo dentro del area
    dropArea?.addEventListener('dragover', (e: any) => {
      e.preventDefault()
      setDropAreaStyles(dropAreaActive)
      setDrag(true)
    })

    // Evento dragleave - acciones que debe hacer al detectar que sale el archivo dentro del area
    dropArea?.addEventListener('dragleave', (e: any) => {
      e.preventDefault()
      setDropAreaStyles({})
      setDrag(false)
    })

    // Evento Drop - Accciones que debe hacer al soltar el archivo dentro del area
    dropArea?.addEventListener('drop', (e: any) => {
      e.preventDefault()
      setDrag(false)
      setDropAreaStyles({}) 
      previewImg(e.dataTransfer.files[0])
      e.target.value = null
    })
  }, [])

  const previewImg = (image: any) => {
    if (allowedTypes.some((value: string) => value === image.type)) {
      const img = { preview: URL.createObjectURL(image), file: image }
      setImg(img)
    } else {
      errorAlert({ html: 'Tipo de archivo no permitido ❌' })
    }
  }

  const handleSubmit = async () => {
    const res = await sendImage()
    if (res.ok) {
      const { is_active, createdAt, updatedAt, __v, ...data2 } = data
      fetchUpdateUser({
        ...data2,
        urlPhoto: res.data?.key,
        urlPhotoLink: res.data?.url ?? res.data?.Url,
      })
      setImg({})
    }
  }

  const sendImage = async () => {
    if (img?.file) {
      const formData = new FormData()
      formData.append('urlPhoto', img?.file)

      const resImg: any = await uploadImage({
        action: 'userUploadImg',
        form: formData,
      })

      return resImg
    }
  }

  return (
    <Box sx={profileStyles.contentRight}>
      {img?.preview ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <IconButton onClick={() => setImg(undefined)}>
                <IconX width={20} height={20} color="red" />
              </IconButton>
            </Box>
            <Box>
              <img alt="Imagen" src={img?.preview && img?.preview} width="250" />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box id="drop-area" sx={dropAreaStyles}>
          <Button disableTouchRipple variant="contained" component="label">
            <Box>
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e: any) => previewImg(e.target.files[0])}
              />
              {/********************** 
              ICONO CARGA/SUBIDA 
            ***********************/}
              <IconUpload color={drag ? '#fff' : '#7282B8'} />
              <Typography style={drag ? { color: 'white' } : undefined}>Agregar imagen</Typography>
              <span style={drag ? { color: 'white' } : undefined}>o arrastra y suelta</span>
            </Box>
          </Button>
        </Box>
      )}

      {/******************************
        LINK REEMPLAZAR FOTOGRAFIA
      *******************************/}
      <span>
        <Button disableTouchRipple variant="contained" component="label" onClick={handleSubmit}>
          Reemplazar fotografía
        </Button>
      </span>
    </Box>
  )
}

export default UpdateImage
