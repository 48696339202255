import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material'

// IMPORTADOS
// import usersStyles from '../../users/users.styles'
import CustomTitle from '../../../../components/CustomTitle'
import IconOpenEye from '../../../../components/icons/IconOpenEye'
import { useNavigate } from 'react-router-dom'
import taskAssignment from '../../plan/taskAssignment/TaskAssignment.styles'
import IconCalendarCheck from '../../../../components/icons/IconCalendarCheck'
import IconUser2 from '../../../../components/icons/IconUser2'
import IconUserNotification from '../../../../components/icons/IconUserNotification'
import WhoNotifyPopopver from '../../plan/taskAssignment/tabsTaskAssignment/WhoNotifyPopover'
import IconClock from '../../../../components/icons/IconClock'
import { useEffect, useState } from 'react'
// import TaskAssignmentTabs from '../../plan/taskAssignment/TaskAssignmentTabs'
import areaNameStyles from '../../../../components/inputSelect/AreaName.styles'
import verifyStyles from '../Verify.styles'
import VerifyEventTabs from './VerifyEventTabs'
import IconCalendar30 from '../../../../components/icons/IconCalendar30'

const sede = [
  {
    id: 1,
    name: 'Barranquilla',
  },
  {
    id: 2,
    name: 'Medellín',
  },
  {
    id: 3,
    name: 'Monteria',
  },
]

// TEMA Y ESTILOS
const themeCustomSelect = (): Theme => {
  // eslint-disable-next-line
  const theme: any = useTheme()

  return createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            '>.MuiPaper-elevation': {
              backgroundColor: '#ffffff',
              maxHeight: '270px',
              minHeight: '86px',
              borderRadius: '15px',
              overflowY: 'hidden',

              // UL
              '>ul': {
                backgroundColor: 'inherit',
                maxHeight: '214px',
                // maxWidth: "570px",
                margin: '20px 20px',

                // SCROLL
                overflowY: sede.length > 6 ? 'scroll' : 'hidden',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  size: '10px',
                  '&-track': {
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '10px',
                    border: '5px solid #ffffff',
                  },
                  '&-thumb': {
                    borderRadius: '20px',
                    backgroundColor: theme.palette.primary.main,
                    width: '10px',
                  },
                },

                // LI
                '>li': {
                  color: '#000000',
                  display: 'flex',
                  justifyContent: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  lineHeight: '24px',
                  backgroundColor: 'inherit',
                  ':hover': {
                    color: '#1F398F',
                    fontWeight: '600',
                    backgroundColor: 'inherit',
                  },
                },

                '>.Mui-selected': {
                  backgroundColor: '#F1F3F8',
                  color: '#1F398F',
                  fontWeight: '600',
                  borderRadius: '200px',
                  ':hover': {
                    backgroundColor: '#F1F3F8 !important',
                  },
                },
              },
            },
          },
        },
      },
    },
  })
}

const VerifyEvent = () => {
  const navigate = useNavigate()

  // PAGINACION
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [events, setEvents] = useState([])
  const [historyEvents, setHistoryEvents] = useState({})
  const listPerPage = 4

  useEffect(() => {
    const pages = events.length / listPerPage

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
  }, [page, events])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const [onSede, setOnSede] = useState<any>('')

  const handleChange = (event: { target: { value: string; name: string } }) => {
    setOnSede(event.target.value)
    // if (handleSelectChange) {
    //   handleSelectChange({ [event.target.name]: event.target.value })
    // }
  }

  // TEMA
  const myTheme = themeCustomSelect()
  console.log(events)

  return (
    <Box sx={verifyStyles.container}>
      <CustomTitle title="Eventos" display="none">
        <>
          {/* BOTON FILTRAR AQUI */}
          {/*****************/}
          {/* BOTON FILTRAR */}
          {/*****************/}
          <ThemeProvider theme={myTheme}>
            <FormControl sx={areaNameStyles.inputSelect} variant="standard">
              {/*********/}
              {/* LABEL */}
              {/*********/}
              <InputLabel id="demo-simple-select-label">Sede</InputLabel>

              {/**********/}
              {/* SELECT */}
              {/**********/}
              <Select value={onSede} name="Estado" onChange={handleChange}>
                {sede.map(({ id, name }, index) => (
                  <MenuItem key={id} disableTouchRipple value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>

          {/*********************/}
          {/* BOTON VER EVENTOS */}
          {/*********************/}
          <Button disableTouchRipple className="btnsTitle" onClick={() => navigate('/verify/event/eventAssigned')}>
            {/*********/}
            {/* ICONO */}
            {/*********/}
            <IconOpenEye width={11} height={11} color="#1F398F" />

            {/*********************/}
            {/* BOTON VER EVENTOS */}
            {/*********************/}
            <Typography>Ver eventos</Typography>
          </Button>
        </>
      </CustomTitle>

      {/****************************/}
      {/* CONTENIDO TABS Y EVENTOS */}
      {/****************************/}
      <Box sx={taskAssignment.content}>
        {/******************/}
        {/* CONTENIDO TABS */}
        {/******************/}
        <VerifyEventTabs
          events={events}
          setEvents={setEvents}
          historyEvents={historyEvents}
          setHistoryEvents={setHistoryEvents}
        />

        {/*********************/}
        {/* CONTENIDO EVENTOS */}
        {/*********************/}
        <Box sx={taskAssignment.contentCard}>
          {/******************/}
          {/* TITULO EVENTOS */}
          {/******************/}
          <Box className="titleButtonSearch" sx={taskAssignment.titleButtonSearch}>
            <Typography>Eventos</Typography>
          </Box>

          <Box className="containerList">
            <List>
              {events.slice((page - 1) * listPerPage, listPerPage * page).map((item: any, index: any) => (
                <ListItem key={index}>
                  <ListItemButton disableTouchRipple sx={{ display: 'block' }}>
                    {/* Nombre del Evento */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box>
                        <IconCalendarCheck width={20} height={20} color="#1F398F" />
                      </Box>
                      <ListItemText
                        primary={`${item.name?.substring(0, 25)} ${
                          item.name?.length > 30 ? '...' : ''
                        }`}
                        sx={{ marginLeft: '10px', minWidth: '280px' }}
                      />
                    </Box>

                    {/* Nombre del indicador */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box>
                        <IconCalendar30 width={20} height={20} color="#1F398F" />
                      </Box>
                      <ListItemText
                        secondary={`${item.idIndicator?.name?.substring(0, 30)} ${
                          item.idIndicator?.name?.length > 30 ? '...' : ''
                        }`}
                        sx={{ marginLeft: '10px' }}
                      />
                    </Box>

                    {/* Nombre de quien mide */}
                    {item.who_measure?.name && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                          <IconUser2 width={20} height={20} color="#1F398F" />
                        </Box>
                        <ListItemText
                          secondary={`${item.who_measure?.name} ${item.who_measure?.lastName}`}
                          sx={{ marginLeft: '10px' }}
                        />
                      </Box>
                    )}

                    {/* nombre de los que notifican */}
                    {item.userNotifies?.length > 0 && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                          <IconUserNotification width={20} height={20} color="#1F398F" />
                        </Box>
                        {item.userNotifies?.slice(0, 2).map((subItem: any) => (
                          <Box sx={{ marginLeft: '10px' }}>
                            <ListItemText secondary={`${subItem.idProfile?.name},`} />
                          </Box>
                        ))}

                        {/* Lista desplegable de todos los usuarios a quien notifica */}
                        {item.userNotifies?.length > 2 && (
                          <Box
                            sx={{
                              marginLeft: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ListItemText secondary={`...`} />
                            <WhoNotifyPopopver whoNotify={item.userNotifies} indexInicial={0} />
                          </Box>
                        )}
                      </Box>
                    )}

                    {/* Fecha de inicio/final del evento */}
                    {item.startDate && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                          <IconClock width={20} height={20} color="#1F398F" />
                        </Box>
                        <ListItemText
                          secondary={`${item.startDate ?? ''} - ${item.finalDate ?? ''}`}
                          sx={{ marginLeft: '10px' }}
                        />
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            {/****************************/}
            {/* CONTENEDOR DE PAGINACION */}
            {/****************************/}
            <Box>
              {/* <ButtonSearch data={} fieldSearch='' setData={} /> */}
              {pages > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemText secondary={`pagina ${page} de ${pages}`} sx={{ marginLeft: '10px' }} />
                </Box>
              )}
              <Pagination count={pages} page={page} onChange={changePage} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VerifyEvent
