import { Box, Typography} from "@mui/material";
import { FunctionComponent } from "react";

interface ErrorFieldProps {
    message: string | undefined
}
const ErrorField: FunctionComponent<ErrorFieldProps> = ({ message }) => {
    if (!message) return null
    return (
        <Box sx={{ my: 1, ml: 1, w : '100%' }}>
            <Typography component="p" color="red">
                {message}
            </Typography>
        </Box>
    )
}

export default ErrorField;