import _ from "lodash";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const baseTheme: any = {
  palette: {
    primary: {
      main: "#1F398F",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FCCC64",
      contrastText: "#000000",
    },
  },
  typography: {
    fontFamily: ["Poppins"].join(","),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          disableUnderline: false,
          borderBottom: "2px solid #003487",
          "&>label": {
            fontSize: "12px",
            paddingLeft: "12px",
            paddingTop: 5,
          },
          "&>div:before": {
            display: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {},
      },
    },

    // ESTILOS DE CHECKBOX GLOBAL
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: "14px",
          height: "14px",
          backgroundColor: "#E9EBF4",

          ">svg": {
            width: "20px",
            height: "20px",
            color: "#E9EBF4",
            ">path": {
              fill: "#E9EBF4",
              backgroundColor: "#E9EBF4",
            },
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: "#F1F3F8",
          color: "#1F398F",
          fontWeight: "600",
          ":hover": {
            backgroundColor: "#F1F3F8 !important",
          },
        },
      },
    },
  },
};

export const BuildTheme = () => {
  const { themeApp } = useSelector((state: any) => state.auth);

  const baseMode = {
    palette: {
      primary: {
        main: themeApp?.mainColor ?? "#1F398F",
      },
      secondary: {
        main: themeApp?.colorTwo ?? "#FCCC64",
      },
    },
  };

  const theme = createTheme(_.merge({}, baseTheme, baseMode));
  return theme;
};

export { baseTheme };
