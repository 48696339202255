import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys = "container" | "titleButtonSearch" | "content" | "contentCard";

const taskAssignment: StyleComponent<Keys> = {
    container: {
        backgroundColor: "#ffffff",
        maxWidth: "1388px",
        minHeight: "60vh",
        margin: "auto",

        "@media (max-width: 1322px)": {
            paddingX: "100px",
        },
    },

    content: {
        // backgroundColor: "pink",
        marginTop: "42px",
        display: "flex",
        justifyContent: "space-between",
        // display: "grid",

        "@media (max-width: 1322px)": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
        },
    },

    contentCard: {
        backgroundColor: "#ffffff",
        // backgroundColor: "aqua",
        width: "424px",
        minHeight: "736px",
        // height: "868px",
        borderRadius: "17px",
        boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",

        "@media (max-width: 1322px)": {
            marginBottom: "50px",
        },

        "@media (max-width: 980px)": {
            width: "100%",
        },

        ">.titleButtonSearch": {
            marginTop: "43px",
            marginBottom: "40px",

            // TITULO
            ">p": {
                color: "#1F398F",
                fontSize: "25px",
                fontWeight: "600",
                lineHeight: "38px",
            },
        },

        // LISTA PROCESOS Y SUBPROCESOS
        ">.containerList": {
            // marginBottom: "40px",
            margin: "0px 40px 62px",
            ">ul": {
                minHeight: "548px",
                paddingTop: "18px",
                ">li": {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                    ">div": {
                        // paddingLeft: "0px",
                        ":hover": {
                            backgroundColor: "transparent",
                            ">div": {
                                ">span": {
                                    fontWeight: "bold !important",
                                    color: "#1F398F",
                                },
                            },
                        },

                        ">div": {
                            marginY: "0px",
                            ">span": {
                                fontSize: "15px",
                            },
                        },

                        // CHECKBOX
                        ">.checked": {
                            minWidth: "20px",
                            ">span, >input": {
                                fontSize: "20px",
                                color: "#000000 !important",
                                marginLeft: "0px",
                                padding: "0px",
                            },

                            // SELECCIONADO
                            ">.Mui-checked": {
                                backgroundColor: "#7282B8",
                                width: "14px",
                                height: "14px",
                            },
                        },
                    },
                },
            },

            // CONTENEDOR BUSCADOR/PAGINACION
            ">div": {
                display: "flex",

                "@media (max-width: 540px)": {
                    flexDirection: "column-reverse",

                    ">nav": {
                        marginBottom: "5px",
                    },
                },

                // BUSCADOR
                ".buttonSearch": {
                    backgroundColor: "yellow",
                    maxWidth: "228px",
                },

                // PAGINACION
                ">nav": {
                    width: "fit-content",
                    height: "40px",
                    alignSelf: "end",
                    // marginTop: "-40px",
                    marginLeft: "auto",

                    ">ul": {
                        justifyContent: "space-between",
                        flexWrap: "inherit",
                        ">li": {
                            ">.Mui-disabled": {
                                visibility: "hidden",
                            },

                            // BOTON DE NUMEROS
                            ">.MuiPaginationItem-page": {
                                display: "none",
                            },

                            // BOTON DE FLECHAS
                            ">.MuiPaginationItem-previousNext": {
                                backgroundColor: "transparent",
                                height: "100%",
                                ">svg": {
                                    fontSize: "2.5rem !important",
                                    ">path": {
                                        color: "#7282B8",
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },

    titleButtonSearch: {
        display: "flex",
        justifyContent: "center",

        // TITULO
        ">p": {
            color: "#1F398F",
            fontSize: "25px",
            fontWeight: "600",
            lineHeight: "38px",
        },

        // BOTON BUSCAR
        ">div": {
            width: "fit-content",
        },
    },
};

export default taskAssignment;
