import { FunctionComponent } from 'react'
interface IconCircleLoadingModalProps {
  width?: number | string
  height?: number | string
}

const IconCircleLoading: FunctionComponent<IconCircleLoadingModalProps> = ({ width = 25, height = 25 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28125 8.125H2.65625C2.32473 8.125 2.00679 7.9933 1.77237 7.75888C1.53795 7.52446 1.40625 7.20652 1.40625 6.875V1.25C1.40625 0.918479 1.53795 0.600537 1.77237 0.366116C2.00679 0.131696 2.32473 0 2.65625 0C2.98777 0 3.30571 0.131696 3.54013 0.366116C3.77455 0.600537 3.90625 0.918479 3.90625 1.25V5.625H8.28125C8.61277 5.625 8.93071 5.7567 9.16513 5.99112C9.39955 6.22554 9.53125 6.54348 9.53125 6.875C9.53125 7.20652 9.39955 7.52446 9.16513 7.75888C8.93071 7.9933 8.61277 8.125 8.28125 8.125Z"
        fill="#7282B8"
      />
      <path
        d="M23.75 13.75C23.4185 13.75 23.1005 13.6183 22.8661 13.3839C22.6317 13.1495 22.5 12.8315 22.5 12.5C22.501 10.2994 21.7756 8.16006 20.4364 6.41389C19.0972 4.66771 17.2191 3.41237 15.0935 2.84266C12.968 2.27295 10.7138 2.42073 8.68085 3.26306C6.64787 4.1054 4.94975 5.59519 3.85 7.50126C3.68391 7.78819 3.41064 7.99739 3.0903 8.08283C2.76997 8.16828 2.42881 8.12297 2.14188 7.95688C1.85495 7.79079 1.64575 7.51752 1.5603 7.19718C1.47485 6.87684 1.52016 6.53569 1.68625 6.24875C3.06157 3.86655 5.18461 2.00481 7.72602 0.952364C10.2674 -0.100084 13.0851 -0.284407 15.742 0.427991C18.3988 1.14039 20.7463 2.70968 22.4203 4.89241C24.0942 7.07515 25.001 9.7493 25 12.5C25 12.8315 24.8683 13.1495 24.6339 13.3839C24.3995 13.6183 24.0815 13.75 23.75 13.75ZM22.3438 25C22.0122 25 21.6943 24.8683 21.4599 24.6339C21.2254 24.3995 21.0938 24.0815 21.0938 23.75V19.375H16.7188C16.3872 19.375 16.0693 19.2433 15.8349 19.0089C15.6004 18.7745 15.4688 18.4565 15.4688 18.125C15.4688 17.7935 15.6004 17.4755 15.8349 17.2411C16.0693 17.0067 16.3872 16.875 16.7188 16.875H22.3438C22.6753 16.875 22.9932 17.0067 23.2276 17.2411C23.4621 17.4755 23.5938 17.7935 23.5938 18.125V23.75C23.5938 24.0815 23.4621 24.3995 23.2276 24.6339C22.9932 24.8683 22.6753 25 22.3438 25Z"
        fill="#7282B8"
      />
      <path
        d="M12.5 25C9.18601 24.996 6.00889 23.6778 3.66555 21.3345C1.3222 18.9911 0.00396964 15.814 0 12.5C0 12.1685 0.131696 11.8505 0.366116 11.6161C0.600537 11.3817 0.918479 11.25 1.25 11.25C1.58152 11.25 1.89946 11.3817 2.13388 11.6161C2.3683 11.8505 2.5 12.1685 2.5 12.5C2.49905 14.7006 3.22445 16.8399 4.56363 18.5861C5.90282 20.3323 7.7809 21.5876 9.90645 22.1573C12.032 22.7271 14.2862 22.5793 16.3191 21.7369C18.3521 20.8946 20.0502 19.4048 21.15 17.4987C21.2322 17.3567 21.3417 17.2322 21.472 17.1324C21.6024 17.0326 21.7511 16.9595 21.9097 16.9172C22.0683 16.8749 22.2337 16.8642 22.3964 16.8858C22.5592 16.9074 22.7161 16.9609 22.8581 17.0431C23.0002 17.1254 23.1247 17.2348 23.2245 17.3651C23.3243 17.4955 23.3974 17.6442 23.4397 17.8028C23.482 17.9614 23.4927 18.1268 23.4711 18.2896C23.4494 18.4523 23.396 18.6092 23.3137 18.7512C22.2144 20.6468 20.6375 22.2213 18.7401 23.3176C16.8428 24.414 14.6913 24.9941 12.5 25Z"
        fill="#7282B8"
      />
    </svg>
  )
}

export default IconCircleLoading
