import { FunctionComponent, MouseEventHandler } from "react";
import { Box, Typography, Fade } from "@mui/material";
import CustomModal from "../../modals/customModal/CustomModal";
import genericAlertStyles from "./genericAlert.styles";
import Spinner from "../../utilities/Spinner";
import IconAlertSuccess from "../../icons/IconAlertSuccess";
import IconX from "../../icons/IconX";

export interface GenericAlertProps {
  show: boolean;
  title?: string;
  text?: string;
  icon?: "loadingAplanner" | "success" | "error" | undefined;
  onCloseModal?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onCloseButton?: MouseEventHandler<HTMLButtonElement>;
  didClose?: () => void;
  showCloseButton?: boolean;
}

const GenericAlert: FunctionComponent<GenericAlertProps> = (
  {
    show,
    title = "",
    text = "",
    icon = null,
    showCloseButton = true,
    onCloseModal,
    onCloseButton,
    didClose,
  } = { show: false }
) => {
  return (
    <CustomModal
      open={show}
      maxWidth={898}
      maxHeight={520}
      onCloseForButtom={(e) => {
        onCloseButton && onCloseButton(e);
        didClose && didClose();
      }}
      onCloseModal={(event, reason) => {
        onCloseModal && onCloseModal(event, reason);
        didClose && didClose();
      }}
      showCloseButton={showCloseButton}
    >
      <>
        {/***********
                    ICONO X
                *************/}
        <Fade in={Boolean(icon)}>
          <Box sx={genericAlertStyles.icon}>
            {icon === "loadingAplanner" && <Spinner width={116} height={119} />}
            {icon === "success" && <IconAlertSuccess />}
            {icon === "error" && <IconX />}
          </Box>
        </Fade>

        {/*********
                    TITLE
                ***********/}
        <Typography sx={genericAlertStyles.title}>{title}</Typography>

        {/*********
                    TEXTO 
                **********/}
        <Typography sx={genericAlertStyles.text}>{text}</Typography>
      </>
    </CustomModal>
  );
};

export default GenericAlert;
