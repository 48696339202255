import { StyleComponent } from '../../../../interfaces/styleComponent'

type Keys =
  | 'containerOpenForm'
  | 'buttonOpenForm'
  | 'content'
  | 'title'
  | 'subtitle'
  | 'input'
  | 'inputBase'
  | 'divButton'
  | 'buttonCreate'

const OpenFormStyles: StyleComponent<Keys> = {
  containerOpenForm: {
    // backgroundColor: "blue",
  },

  content: {
    paddingX: '70px',
    height: '509px',
  },

  buttonOpenForm: {
    width: '0px',
    height: '17px',
    display: 'float',
    float: 'right',
    right: '30px',
    top: '40px',
    ':hover': {
      backgroundColor: 'transparent',
    },
  },

  title: {
    paddingTop: '90px',
    marginBottom: '30px',
    '>p': {
      fontSize: '25px',
    },
  },

  // SUBTITULOS
  subtitle: {
    marginBottom: '30px',
    '>p': {
      fontSize: '15px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
    '>span': {
      fontSize: '15px',
      marginBottom: '9px',
    },
  },
  input: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },
  inputBase: {
    width: '100%',
    height: '100%',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
    borderBottom: '0px solid transparent',
    '>div': {
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  buttonCreate: {
    backgroundColor: '#7282B8',
    maxWidth: '169.74px',
    width: '169.74px',
    height: '33px',
    borderRadius: '20px',

    // TEXTO
    color: '#FFFFFF',
    fontSize: '15px',
    fontWeight: '500',
    textTransform: 'uppercase',
    lineHeight: '18px',
    fontStyle: 'normal',

    ':hover': {
      backgroundColor: '#7282B8',
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    marginTop: '70px',
    marginX: 'auto',
    width: 'fit-content',

    '>Button': {
      backgroundColor: '#7282B8',
      maxWidth: '169.74px',
      // width: "169.74px",
      height: '33px',
      borderRadius: '20px',
      padding: '10px 20px',

      // TEXTO
      color: '#FFFFFF',
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',

      ':hover': {
        backgroundColor: '#7282B8',
      },
    },
  },
}

export default OpenFormStyles
