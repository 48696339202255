import { useEffect, FunctionComponent } from "react";
import { Box, Typography, InputBase, Button } from "@mui/material";
// IMPORTADOS
import CustomTitleButtonAdd from "../../../CustomTitleButtonAdd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import IconFile from "../../../icons/IconFile";
import IconLess from "../../../icons/IconLess";
import IconUpload from "../../../icons/IconUpload";
import formBrachOfficeStyles from "../../../../pages/private/brachOffice/FormBranchOffice.styles";
import formProcessStyles from "../../../../pages/private/plan/process/formProcess.styles";
import { Area } from "../../../../interfaces/area";
import CustomSelect from "../../../inputSelect/CustomSelect";
import { formConditionSchema, validExtensionJustificationFile } from "../../../../schemas/condition/conditionSchema";
import ErrorField from "../../../ErrorField";
import { FormConditionValues } from "../../../../interfaces/condition";
import * as yup from 'yup'

interface FormConditionProps {
    areas: Area[]
    defaultValues? : FormConditionValues
    onCancel: () => void
    onSubmit: (formValues: FormConditionValues, reset: () => void) => void,
    textButtonSubmit?: string
    title?: string
}

const _defaultValues = {
    idDepartament: '',
    name: '',
}

const justificationExtensions = validExtensionJustificationFile
    .map(ex => `.${ex}`)
    .toString()

const FormCondition: FunctionComponent<FormConditionProps> = ({
    areas, onSubmit, onCancel,
    defaultValues = _defaultValues,
    textButtonSubmit = 'Crear', title = 'Crear condición'
}) => {

    const { control, handleSubmit, register, watch, formState: { errors, isValid }, setValue, reset } = useForm<FormConditionValues>({
        mode: "onChange",
        resolver: yupResolver(formConditionSchema),
        defaultValues: _defaultValues,
        shouldUnregister : true
    });

    useEffect(() => {
        const isContainDepartament = areas.some(a => a._id === defaultValues.idDepartament && a.is_active)
        reset({ ...defaultValues, idDepartament: isContainDepartament ? defaultValues.idDepartament : '' })
    }, [defaultValues])

    const InputFile = () => {
        return (
            <input
                {...register('justificationLink')}
                hidden
                accept={justificationExtensions}
                type="file"
            />
        )
    }

    const removeJustification = () => {
        const dt = new DataTransfer()
        setValue('justificationLink', dt.files, {shouldValidate : true})
    }

    const justificationName = () : { type : 'string' | 'file', value : string | null } => {
        const { justificationLink } = watch()
        let value = null
        if(typeof justificationLink  === 'string') {
            value = justificationLink
        } else {
            value = justificationLink?.item(0)?.name || null
        }
        return {
            type : typeof justificationLink === 'string' ? 'string' : 'file',
            value
        }
    }

    const submit: SubmitHandler<FormConditionValues> = (formValues) => onSubmit(formValues, () => reset(_defaultValues))

    return (
        <Box>
            <Box>
                <CustomTitleButtonAdd title={title} display="none" />

                {/**************************
                    CONTENEDOR FORMULARIO
                ****************************/}
                <Box sx={formProcessStyles.container}>
                    {/**************
                        CONTENIDO
                    **************/}
                    <Box
                        component='form'
                        onSubmit={handleSubmit(submit)}
                        sx={formProcessStyles.content}
                    >
                        {/*************
                            NOMBRE
                        ***************/}
                        <Box
                            sx={{
                                ...formProcessStyles.subtitle,
                            }}
                        >
                            <Typography>Nombre del área</Typography>

                            {/* INPUT */}
                            <Box sx={formProcessStyles.input}>
                                <Box sx={formProcessStyles.input}>
                                    <Controller
                                        name='idDepartament'
                                        control={control}
                                        render={({ field }) => (
                                            <CustomSelect
                                                {...field}
                                                propValue='_id'
                                                propText='name'
                                                items={areas}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <ErrorField message={errors.idDepartament?.message} />
                        </Box>

                        {/********************
                            NOMBRE DEL PROCESO
                        **********************/}
                        <Box
                            sx={{
                                ...formProcessStyles.subtitle,
                            }}
                        >
                            <Typography>Nombre de la condición</Typography>

                            {/* INPUT */}
                            <Box sx={formProcessStyles.input}>
                                <Controller
                                    name='name'
                                    control={control}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            sx={formProcessStyles.inputBase}
                                        />
                                    )}
                                />
                            </Box>
                            <ErrorField message={errors.name?.message} />
                        </Box>

                        {/*************
                            IMAGEN
                        ***************/}
                        <Box
                            sx={{
                                ...formBrachOfficeStyles.subTitle,
                                display: "block",
                            }}
                        >
                            <Typography>Justificación</Typography>
                            {/************
                                IMAGEN 
                            ************/}
                            {/* !watch().justification?.item(0)  */}
                            {  !justificationName().value ? (
                                <Box sx={formBrachOfficeStyles.imagen}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        disableTouchRipple
                                    >
                                        <Box>
                                            <InputFile />

                                            {/********************** 
                                                ICONO CARGA/SUBIDA 
                                            ***********************/}
                                            <IconUpload />

                                            <Typography>
                                                Agregar archivo
                                            </Typography>
                                            <span>o arrastra y suelta</span>
                                        </Box>
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={formBrachOfficeStyles.formImage}>
                                    <Box>
                                        <Box>
                                            <IconFile />

                                            <Typography>
                                                {/* {typeof watch().justification !== 'string' && watch().justification.include('item') && watch().justification.item(0)?.name} */}
                                                { justificationName().type === 'file' 
                                                    ? justificationName().value
                                                    :   <a href={justificationName()?.value || undefined} target = '_blank'>
                                                            Ver anexo
                                                        </a> 
                                                    }
                                            </Typography>
                                        </Box>

                                        <Button onClick={removeJustification} disableTouchRipple>
                                            <IconLess />
                                        </Button>
                                    </Box>

                                    <Button
                                        disableTouchRipple
                                        variant="contained"
                                        component="label"
                                    >
                                        Reemplazar archivo
                                        <InputFile />

                                    </Button>
                                </Box>
                            )}
                                    <ErrorField message={errors.justificationLink?.message} />

                        </Box>

                        {/**********************
                            CONTENEDOR BOTONES 
                        ***********************/}
                        <Box
                            sx={{
                                ...formProcessStyles.divButton,
                                marginTop: "210px !important",
                            }}
                        >
                            {/**********************************
                                BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
                            <div>
                                {/********************
                                    BOTON CREAR
                                *********************/}
                                <Button
                                    type='submit'
                                    disableTouchRipple
                                    sx={formProcessStyles.buttonSave}
                                    disabled={!isValid}
                                >
                                    {textButtonSubmit}
                                </Button>

                                {/********************
                                    BOTON CANCELAR
                                *********************/}
                                <Button
                                    onClick={onCancel}
                                    disableTouchRipple
                                    sx={formProcessStyles.buttonCancel}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FormCondition;
