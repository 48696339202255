import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

// IMPORTACION
import IconAdd from "./icons/IconAdd";
import customTitleButtonAddStyles from "./customTitleButtonAdd.styles";

interface CustomTitleButtonAddProps {
    title?: string;
    titleMini?: string;
    fontSize?: string;
    navigateUrl?: string;
    titleButton?: string;
    display?: string | null;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const CustomTitleButtonAdd: FunctionComponent<CustomTitleButtonAddProps> = ({
    title = "Plan de Desarrollo Institucional",
    titleMini = "PDI",
    fontSize = "50px",
    navigateUrl,
    titleButton = "Crear nuevo PDI",
    display = null,
    onClick,
}) => {
    const navigate = useNavigate();

    const handleButton = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        onClick && onClick(e);
        if (navigateUrl !== undefined) {
            navigate(navigateUrl);
        }
    };
    return (
        <>
            {/**************************
                CONTENEDOR TITULO PDI 
            ***************************/}
            <Box sx={customTitleButtonAddStyles.container}>
                {/***************
                    TITULO PDI
                ****************/}
                <Box sx={customTitleButtonAddStyles.containerTitle}>
                    <Typography
                        sx={{ ...customTitleButtonAddStyles.title, fontSize }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{ ...customTitleButtonAddStyles.title, fontSize }}
                    >
                        {titleMini}
                    </Typography>
                </Box>

                {/*************
                    BOTON MÁS
                **************/}
                <Box sx={customTitleButtonAddStyles.containerButton}>
                    <Button
                        onClick={handleButton}
                        disableTouchRipple
                        sx={{ ...customTitleButtonAddStyles.button, display }}
                    >
                        <IconAdd />

                        <Typography>{titleButton}</Typography>
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default CustomTitleButtonAdd;
