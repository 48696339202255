import { useCallback } from "react";
import customAlerts from "../../helpers/customAlerts";
import useFetch, { BasicFetchListProps } from "../useFetch";

const { errorAlert } = customAlerts

const useColorThemes = () => {
  const { fetchList } = useFetch()

  const fetchGetColorThemes = useCallback(async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
    return fetchList<any>({
      action: 'getColorThemes',
      params: [
        ['limit', `${limit}`],
        ['active', `${active}`],
        ['from', `${from}`]
      ]
    })
      .then(res => {
        return res
      })
      .catch((error) => {
        errorAlert({ html: "Contactar con soporte 🚫" })
      })
  }, [fetchList])

  return {
    fetchGetColorThemes,
  }
}

export default useColorThemes
