import * as yup from 'yup'
import { FormProjectValues } from '../../interfaces/pdi/project'
import { testMaxLength } from '../validations';

export const formProjectSchema: yup.SchemaOf<FormProjectValues> = yup.object({
    description: yup.string()
        .required('La descripción es un campo requerido')
        .test('maxLength', 'La descripción no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('La descripción no puede contener solo espacios en blanco'),
    idProgram: yup.string()
        .required('El programa es un campo requerido')
        .length(24, 'El programa seleccionado no es válido'),
    idPdi: yup.string()
        .required('El programa es un campo requerido')
        .length(24, 'El programa seleccionado no es válido'),
    // percentageOfCompletion: yup.number()
    //     .typeError('El porcentaje de cumplimiento requiere un valor numerico')
    //     .min(0, 'El porcentaje de cumplimiento debe ser mayor o igual a 0')
    //     .max(100, 'El porcentaje de cumplimiento debe ser menor o igual a 100')
    //     .required('El porcentaje de cumplimiento es un campo requerido'),
    state: yup.boolean()
        .required('El estado es un campo requerido')
        .default(true),
    title: yup.string()
        .required('El título es un campo requerido')
        .test('maxLength', 'El título no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('El título no puede contener solo espacios en blanco'),
}).required()