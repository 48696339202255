/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IconPensil from '../../../components/icons/IconPensil'
import Spinner from '../../../components/utilities/Spinner'
import useColorThemes from '../../../hooks/colorThemes/useColorThemes'
import customTitleButtonAddStyles from '../../../components/customTitleButtonAdd.styles'
import { setThemeApp } from '../../../redux/features/auth/authSlice'
import { useDispatch } from 'react-redux'

const CustomizeTheme = () => {
  const [open, setOpen] = useState(false)
  const [listTheme, setListTheme] = useState([])
  const { fetchGetColorThemes } = useColorThemes()
  const dispatch = useDispatch()

  const handleClick = async (item: any) => {
    dispatch(setThemeApp(item))
    setOpen(false)
  }

  useEffect(() => {
    getColorThemes()
  }, [])

  const getColorThemes = async () => {
    const response: any = await fetchGetColorThemes()
    const data = response.items
    setListTheme(data)
  }

  return (
    // CONTENEDOR BOTON TEMA
    <Box>
      <Button
        onClick={() => setOpen(!open)}
        // onBlur={() => setOpen(false)}
        disableTouchRipple
        sx={{
          ...customTitleButtonAddStyles.button,
          minWidth: 'auto',
          marginRight: '40px',
        }}
      >
        <IconPensil />

        <Typography
          sx={{
            ...customTitleButtonAddStyles.title,
            fontWeight: '200',
          }}
        >
          Personalizar
        </Typography>
      </Button>

      {/***********************
                CARD TEMA FLOTANTE
            ***********************/}
      <Box
        sx={{
          ...customTitleButtonAddStyles.containerButtonTheme,
          display: open === true ? 'block' : 'none',
        }}
      >
        <Typography>Escoger tema</Typography>

        {/**********
                    TEMAS
                **********/}
        {listTheme.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </Box>
        ) : (
          <Box>
            {listTheme.reverse().map((theme: any, i: any) => (
              <button key={i} onClick={() => handleClick(theme)}>
                <Typography>{theme.name}</Typography>

                <span
                  style={{
                    backgroundColor: `${theme.mainColor}`,
                  }}
                ></span>
                <span
                  style={{
                    backgroundColor: `${theme.colorTwo}`,
                  }}
                ></span>
              </button>
            ))}
            <Button>cambios</Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CustomizeTheme
