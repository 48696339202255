import { FunctionComponent } from "react";

interface IconDirectionProps {
    width?: number | string;
    height?: number | string;
}

const IconDirection: FunctionComponent<IconDirectionProps> = ({
    width = 20,
    height = 20,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.75 13.75C18.3958 13.75 18.0992 13.63 17.86 13.39C17.62 13.1508 17.5 12.8542 17.5 12.5V2.5H7.5C7.14583 2.5 6.84917 2.38 6.61 2.14C6.37 1.90083 6.25 1.60417 6.25 1.25C6.25 0.895833 6.37 0.59875 6.61 0.35875C6.84917 0.119583 7.14583 0 7.5 0H18.75C19.1042 0 19.4008 0.119583 19.64 0.35875C19.88 0.59875 20 0.895833 20 1.25V12.5C20 12.8542 19.88 13.1508 19.64 13.39C19.4008 13.63 19.1042 13.75 18.75 13.75ZM12.5 20C12.1458 20 11.8492 19.88 11.61 19.64C11.37 19.4008 11.25 19.1042 11.25 18.75V8.75H1.25C0.895833 8.75 0.599167 8.63 0.36 8.39C0.12 8.15083 0 7.85417 0 7.5C0 7.14583 0.12 6.84875 0.36 6.60875C0.599167 6.36958 0.895833 6.25 1.25 6.25H12.5C12.8542 6.25 13.1513 6.36958 13.3913 6.60875C13.6304 6.84875 13.75 7.14583 13.75 7.5V18.75C13.75 19.1042 13.6304 19.4008 13.3913 19.64C13.1513 19.88 12.8542 20 12.5 20Z"
                fill="#1F398F"
            />
        </svg>
    );
};

export default IconDirection;
