import { FunctionComponent, useEffect } from 'react'
import { Box, Typography, Button, InputBase, Fade } from "@mui/material";
import CustomTitleButtonAdd from "../../../CustomTitleButtonAdd";
import formProcessStyles from "../../../../pages/private/plan/process/formProcess.styles";
import { FormFactorValues } from "../../../../interfaces/factor";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formFactorSchema } from "../../../../schemas/factor/factorSchema";
import CustomSelect from "../../../inputSelect/CustomSelect";
import ErrorField from "../../../ErrorField";
import { Area } from '../../../../interfaces/area';

interface FormFactorProps {
    defaultValues?: FormFactorValues
    areas: Area[]
    onCancel: () => void
    onSubmit: (formValues: FormFactorValues, reset: () => void) => void,
    textButtonSubmit?: string
    title?: string
}

const _defaultValues: Partial<FormFactorValues> = {
    idDepartament: '',
    name: '',
    description: '',
    justification: ''
}

const FormFactor: FunctionComponent<FormFactorProps> = ({ defaultValues = _defaultValues,
    areas, onSubmit, onCancel, 
    textButtonSubmit = 'Crear', title = 'Crear factor'
}) => {
    const { control, handleSubmit, formState: { errors, isValid }, reset } = useForm<FormFactorValues>({
        mode: "onChange",
        resolver: yupResolver(formFactorSchema),
        defaultValues: defaultValues
    });

    useEffect(() => {
        const isContainDepartament = areas.some(a => a._id === defaultValues.idDepartament && a.is_active)
        reset({ ...defaultValues, idDepartament: isContainDepartament ? defaultValues.idDepartament : '' })
    }, [defaultValues])

    const submit: SubmitHandler<FormFactorValues> = (formValues) => onSubmit(formValues, () => reset(_defaultValues))
    
    return (
        <Fade in = {true}>
            <Box>
                <Box sx={{
                    maxWidth: '1350px',
                    m: 'auto'
                }}>
                    <CustomTitleButtonAdd title={title} display="none" />
                </Box>

                {/**************************
                    CONTENEDOR FORMULARIO
                ****************************/}
                <Box sx={formProcessStyles.container}>
                    {/**************
                        CONTENIDO
                    **************/}
                    <Box
                        component='form'
                        onSubmit={handleSubmit(submit)}
                        sx={formProcessStyles.content}
                    >
                        {/*************
                            NOMBRE
                        ***************/}
                        <Box
                            sx={{
                                ...formProcessStyles.subtitle,
                            }}
                        >
                            <Typography>Nombre del área</Typography>

                            {/* INPUT */}
                            <Box sx={formProcessStyles.input}>
                                <Controller
                                    name='idDepartament'
                                    control={control}
                                    render={({ field }) => (
                                        <CustomSelect
                                            {...field}
                                            propValue='_id'
                                            propText='name'
                                            items={areas}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>


                        {/********************
                            NOMBRE DEL PROCESO
                        **********************/}
                        <Box
                            sx={{
                                ...formProcessStyles.subtitle,
                            }}
                        >
                            <Typography>Nombre del factor</Typography>

                            {/* INPUT */}
                            <Box sx={formProcessStyles.input}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            sx={formProcessStyles.inputBase}
                                        />
                                    )}
                                />
                            </Box>
                            <ErrorField message={errors.name?.message} />
                        </Box>

                        {/****************
                            DESCRIPCION
                        *****************/}
                        <Box sx={formProcessStyles.description}>
                            <Typography>Descripción</Typography>

                            {/* INPUT */}
                            <Box
                                sx={{
                                    ...formProcessStyles.inputDescription,
                                    minHeight: "135px",
                                }}
                            >
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            multiline
                                            maxRows={10}
                                            sx={formProcessStyles.inputBaseDescription}
                                        />
                                    )}
                                />
                            </Box>
                            <ErrorField message={errors.description?.message} />
                        </Box>

                        {/********************
                            JUSTISTIFICACION
                        **********************/}
                        <Box
                            sx={{
                                ...formProcessStyles.subtitle,
                            }}
                        >
                            <Typography>Justificacion</Typography>

                            {/* INPUT */}
                            <Box sx={formProcessStyles.input}>
                                <Controller
                                    name="justification"
                                    control={control}
                                    render={({ field }) => (
                                        <InputBase
                                            {...field}
                                            sx={formProcessStyles.inputBase}
                                        />
                                    )}
                                />
                            </Box>
                            <ErrorField message={errors.justification?.message} />
                        </Box>

                        {/**********************
                            CONTENEDOR BOTONES 
                        ***********************/}
                        <Box
                            sx={{
                                ...formProcessStyles.divButton,
                                marginTop: "210px !important",
                            }}
                        >
                            {/**********************************
                                BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
                            <div>
                                {/********************
                                    BOTON CREAR
                                *********************/}
                                <Button
                                    disabled={!isValid}
                                    disableTouchRipple
                                    sx={formProcessStyles.buttonSave}
                                    type='submit'
                                >
                                    {textButtonSubmit}
                                </Button>

                                {/********************
                                    BOTON CANCELAR
                                *********************/}
                                <Button
                                    onClick={onCancel}
                                    disableTouchRipple
                                    sx={formProcessStyles.buttonCancel}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fade>
    );
};


export default FormFactor;
