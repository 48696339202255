import dayjs from 'dayjs'

export const testMaxLength = (value: string | undefined, maxLength: number): boolean => {
  if (!value) return true
  if (!maxLength) return true
  return value.toString().length <= maxLength
}

export const testFileMaxSize = (files: FileList | undefined, maxSize: number): boolean => {
  if (!files) return false
  const file = files.item(0)
  if (!file) return false
  return file.size <= maxSize
}

export const testRequiredFile = (files: FileList | undefined): boolean => {
  return files !== undefined && files.item(0) !== null
}

export const testValidExtensionsFile = (files: FileList | undefined, validExtensions: string[]): boolean => {
  if (!files) return false
  const file = files.item(0)
  if (!file) return false
  const regExp = /\./g
  const extensions = validExtensions.map(ex => ex.replace(regExp, ''))
  const { name } = file
  const extensionFile = name.toLowerCase().split('.').at(-1)

  if (!extensionFile) return false

  return extensions.includes(extensionFile)
}

export const testMinDate = (current?: number, startDate?: number): boolean => {
  if (!current || !startDate) return false
  return current > startDate
}

export const testDifference = (years: number, current?: number, startDate?: number): boolean => {
  if (!current || !startDate) return false
  const daysCurrent = dayjs.unix(current)
  const daysStartDate = dayjs.unix(startDate)
  const difference = daysCurrent.diff(daysStartDate.format('YYYY-MM-DD'), 'year', false)
  return difference <= years
}
