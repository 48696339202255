import { infoAgenda } from '../../../components/data/dataAPI'
import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'contentGridPagination'
  | 'boxGrid'
  | 'contentInfoData'
  | 'contentDate'
  | 'selectButton'
  | 'contentTop'
  | 'contentBottom'

const verifyStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    // maxWidth: "1350px",
    maxWidth: '1430px',
    paddingLeft: '30px',
    paddingRight: '30px',
    margin: 'auto',
    backgroundColor: '#ffffff',

    '>.customTitle': {
      '>.customTitleButtons': {
        // backgroundColor: 'pink',
        flexWrap: 'initial',
        alignItems: 'baseline',

        '>.MuiFormControl-root': {
          minWidth: '120px',

          '>div>div': {
            backgroundColor: '#FFFFFF !important',
            boxShadow: '0px 0px 10px 0px rgba(114, 130, 184, 0.25)',
          },
        },
      },
    },
  },

  contentGridPagination: {
    // backgroundColor: 'pink',
  },

  // CAJA DE LA GRILLA
  boxGrid: {
    flexGrow: 1,

    // CONTENEDOR GRILLA
    '>.MuiGrid-container': {
      spacing: 2,

      // ITEM GRILLA
      '>.MuiGrid-item': {
        // CARD DE LA GRILLA
        '>.contentCardGrid': {
          display: 'flex',
          alignItems: 'center',
          height: '100%',

          // FRANJA CARD
          '>.franjaCard': {
            // backgroundColor: '#1F398F',
            width: '9px',
            height: '94px',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
          },

          '>.contentCard': {
            background: '#FFF',
            width: '415px',
            minHeight: '111px',
            height: '100%',
            display: 'flex',
            borderRadius: '7px',
            boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',

            // FECHA CARD
            '>.fechaCard': {
              maxWidth: '62px',
              width: '100%',
              pt: '16px',
              borderRight: '1px solid #CACACA',

              '>.day': {
                textAlign: 'center',

                // color: '#000000',
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 600,
              },
              '>.date': {
                textAlign: 'center',

                // color: '#000000',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 300,
              },
            },

            '>.contentCard': {
              display: 'flex',
              justifyContent: 'space-between',
              margin: '15px 20px 20px',

              '>.contentCardInfo': {
                '>h1': {
                  // color: '#000000',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                },
                '>p': {
                  // color: '#000000',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                },
                '>em': {
                  // color: '#000000',
                  fontSize: '12px',
                  fontStyle: 'italic',
                  fontWeight: 400,
                },
              },

              '>.contentCardIcon': {
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'baseline',

                '>.btnInfo': {
                  minWidth: 'fit-content',
                  padding: '6px 0px 0px',
                },
              },
            },
          },
        },
      },
    },
  },

  // CONTENIDO INFO VERIFICAR
  contentInfoData: {
    marginBottom: '20px',
    '>p': {
      color: '#1F398F',
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '22px',
      marginBottom: '10px',
    },
    '>span': {
      color: '#000000 !important',
      fontWeight: '400 !important',
      fontSize: '15px',
      '>svg': {
        marginBottom: '-5px',
        marginRight: '8px',
      },
    },
  },

  // EDITAR
  // CONTENIDO EDITAR FECHAS
  contentDate: {
    display: 'flex',
    marginBottom: '30px',

    '>div': {
      marginRight: '30px',
      '>p': {
        color: '#1F398F',
        fontSize: '15px',
        fontWeight: '600',
        textAlign: 'left',
        lineHeight: '22px',
        marginTop: '0px',
        marginBottom: '9px',
        width: 'auto',
      },
    },
  },

  // INPUT
  selectButton: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
    display: 'flex',

    '>p': {
      width: '100%',
      height: '-webkit-fill-available',
      padding: '4px 0px 5px',
      marginLeft: '10px',
    },

    '>button': {
      minWidth: 'auto',
      paddingX: '14px',
      ':hover': {
        backgroundColor: 'transparent',
      },
    },
  },

  // CONTENEDOR ARRIBA
  contentTop: {
    backgroundColor: '#ffffff',

    display: 'flex',
    justifyContent: 'space-between',
    // flexDirection: 'row',

    // RESPONSIVE
    '@media (max-width: 1020px)': {
      //   marginRight: '0px',
      //   marginTop: '0px',
      //   flexDirection: 'column',

      '>.contentParagraph, >.buttonEvent, >.buttomPdi, >.contentListDiary': {
        '>p': {
          fontSize: '15px !important',
        },
        '>button': {
          '>svg': {
            width: 100,
            height: 100,
          },
          '>p': {
            fontSize: '20px !important',
          },
        },
        //     marginX: '10px !important',
        //     marginY: '28px !important',
        //     width: 'auto !important',
      },
    },
    '@media (max-width: 900px)': {
      marginRight: '0px',
      marginTop: '0px',
      flexDirection: 'column',

      '>.contentParagraph, >.buttonEvent, >.buttomPdi, >.contentListDiary': {
        marginX: '10px !important',
        marginY: '28px !important',
        width: 'auto !important',
      },
    },

    // PARRAFO
    '>.contentParagraph': {
      backgroundColor: '#ffffff',
      minHeight: '363px',
      width: '663px',
      marginX: '31.5px',
      borderRadius: '50px',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',

      '>.parrafo': {
        marginY: '56px !important',
        marginX: '58px',
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '30px',
        textAlign: 'justify',
      },
    },

    // BOTON PDI
    '>.buttonEvent, >.buttomPdi': {
      borderRadius: '50px',
      width: '663px',
      minHeight: '363px',
      maxHeight: '100%',
      height: 'auto',
      // minHeight: '350px',
      marginTop: '0px',
      // marginRight: '0px',
      marginX: '31.5px',
    },

    '>.contentListDiary': {
      background: '#FFFFFF',
      width: '663px',
      minHeight: '363px',
      maxHeight: '100%',
      height: '350px',
      marginX: '31.5px',
      borderRadius: '30px',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
      '>div': {
        margin: '25px 53px auto 58px',
        maxHeight: '318px',
        padding: '10px',
        marginBottom: '46px',

        // SCROLLBAR
        overflowY: infoAgenda.length > 6 ? 'scroll !important' : 'hidden !important',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '12px',
          height: '100px',
          '&-track': {
            backgroundColor: theme => theme.palette.secondary.main,
            borderRadius: '10px',
            border: '5px solid #ffffff',
          },
          '&-thumb': {
            backgroundColor: '#1F398F',
            borderRadius: '20px',
            width: '10px',
          },
        },

        '.titleListDiary': {
          '>p': {
            color: '#1F398F',
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 500,
          },
        },
      },
    },
  },

  // CONTENEDOR ABAJO
  contentBottom: {
    marginTop: '28px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '@media (max-width: 1320px)': {
      justifyContent: 'space-evenly',
    },

    '>.buttoms': {
      borderRadius: '30px',
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
}

export default verifyStyles
