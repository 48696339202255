import { Box, InputAdornment, InputBase, Typography } from '@mui/material'
import React from 'react'
import formStyles from './forms/form.styles'

interface CustomFormulaProps {
  title?: string
  backgroundColor?: string | number
  colorText?: string | number
  numerador?: number
  denominador?: number
  resultado?: number
  meta?: number
}

const CustomFormula = ({
  title = 'Nombre del cumplimiento',
  backgroundColor = '#7282B8',
  colorText = '#FFFFFF',
  numerador = 0,
  denominador = 0,
  resultado = 0,
  meta = 0,
}: CustomFormulaProps) => {
  return (
    <>
      <Box
        className="contentCumplimientoIndicador"
        sx={{
          backgroundColor: `${backgroundColor} !important`,
          height: '227px !important',
          '>p, >.contentInputs>div>div>p': {
            color: `${colorText} !important`,
          },
          marginBottom: '59px !important',
        }}
      >
        <Typography>{title}</Typography>

        <Box className="contentInputs">
          <Box>
            {/*************/}
            {/* NUMERADOR */}
            {/*************/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Númerador</Typography>

              {/*******************/}
              {/* INPUT NUMERADOR */}
              {/*******************/}
              <Box sx={formStyles.input}>
                <InputBase disabled value={numerador} sx={formStyles.inputBase} />
              </Box>
            </Box>

            {/***************/}
            {/* DENOMINADOR */}
            {/***************/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Denominador</Typography>

              {/*********************/}
              {/* INPUT DENOMINADOR */}
              {/*********************/}
              <Box sx={formStyles.input}>
                <InputBase disabled value={denominador} sx={formStyles.inputBase} />
              </Box>
            </Box>

            {/*************/}
            {/* RESULTADO */}
            {/*************/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Resultado</Typography>

              {/*******************/}
              {/* INPUT RESULTADO */}
              {/*******************/}
              <Box sx={formStyles.input}>
                <InputBase
                  disabled
                  value={resultado}
                  sx={{ ...formStyles.inputBase, '>input': { paddingLeft: '15px' } }}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </Box>
            </Box>

            {/********/}
            {/* META */}
            {/********/}
            <Box sx={formStyles.containerTitle}>
              <Typography sx={formStyles.title}>Meta</Typography>

              {/**************/}
              {/* INPUT META */}
              {/**************/}
              <Box sx={formStyles.input}>
                <InputBase disabled value={meta} sx={formStyles.inputBase} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CustomFormula
