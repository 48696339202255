import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, InputBase, Typography } from '@mui/material'

// IMPORTADOS
import CustomTitleButtonAdd from '../../../components/CustomTitleButtonAdd'
import formBrachOfficeStyles from './FormBranchOffice.styles'
import { ValidateFields, ValidateForm } from '../../../services/ValidateForm'
import { branchOfficeCreateSchema } from '../../../schemas/branchOffice'
import ErrorField from '../../../components/ErrorField'
import useBranchOffice from '../../../hooks/branchOffice/useBranchOffice'
import useImage from '../../../hooks/image/useImage'
import UploadImage from './UploadImage'
import useCompany from '../../../hooks/company/useCompany'
import formStyles from '../../../components/forms/form.styles'

interface ImageState {
  preview?: string
  file?: any
}

const FormBranchOffice = () => {
  const { fetchCreateBranchOffice, fetchUpdateBranchOffice } = useBranchOffice()
  const { uploadImage } = useImage()
  const { fetchGetOneBranchOffice } = useBranchOffice()
  const params = useParams()
  const navigate = useNavigate()
  const { fetchGetCompany } = useCompany()

  const [img, setImg] = useState<ImageState>()
  const [dataForm, setDataForm] = useState<any>({})
  const [company, setCompany] = useState<any>({})
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    if (params.id) {
      getBranchOffice()
    }
    getCompany()
  }, [])

  const getBranchOffice = async () => {
    const response: any = await fetchGetOneBranchOffice(params.id || '')
    const { is_active, createdAt, updatedAt, __v, ...data } = response
    setDataForm({
      ...dataForm,
      ...data,
    })
  }

  const getCompany = async () => {
    const res: any = await fetchGetCompany()
    setCompany(res.items[0])
    setDataForm({
      ...dataForm,
      idCompany: res.items[0]?._id,
    })
  }

  const handleInputChange = async (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })

    const result = await ValidateFields({
      schema: branchOfficeCreateSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }

  const handleSubmit = async () => {
    const result = await ValidateForm({
      schema: branchOfficeCreateSchema,
      data: dataForm,
    })
    console.log(result)

    if (result.isValid) {
      const res = await sendImage()
      console.log(res)
      if (params.id) {
        fetchUpdateBranchOffice({
          ...dataForm,
          representativeImage: res?.data?.key,
          representativeImageLink: res?.data?.url ?? res?.data?.Url,
        })
      } else {
        const response = await fetchCreateBranchOffice({
          ...dataForm,
          representativeImage: res?.data?.key,
          representativeImageLink: res?.data?.url ?? res?.data?.Url,
        })
        if (response.ok) {
          setDataForm({})
          setImg({})
          navigate('/branchOffice')
        }
      }
    } else {
      setErrors(result.errors)
    }
  }

  const sendImage = async () => {
    if (img?.file) {
      const formData = new FormData()
      formData.append('representativeImage', img?.file)

      const resImg: any = await uploadImage({
        action: 'branchOfficeUploadImg',
        form: formData,
      })

      return resImg
    }
  }

  return (
    <>
      {/**********************************
                CONTENEDOR FORMULARIO EMPRESAS
            **********************************/}
      <Box sx={formBrachOfficeStyles.container}>
        {/**********************************
                    CONTENEDOR TITULO EMPRESAS
                **********************************/}
        <CustomTitleButtonAdd title={params.id ? 'Editar sucursal' : 'Registrar sucursal'} display="none" />

        {/**************
                    CONTENIDO
                **************/}
        <Box sx={formBrachOfficeStyles.content}>
          {/*******************
                        CONTENEDOR BLOCK
                    *******************/}
          <Box sx={formBrachOfficeStyles.containerBlock}>
            {/************
                            NOMBRE
                        ************/}
            <Box sx={formBrachOfficeStyles.subTitle}>
              <Typography>Nombre de la sucursal</Typography>

              {/* INPUT */}
              <Box sx={formBrachOfficeStyles.input}>
                <InputBase
                  sx={formBrachOfficeStyles.inputBase}
                  name="nameBranchOffice"
                  value={dataForm?.nameBranchOffice ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.nameBranchOffice ?? ''} />
              </Box>
            </Box>
          </Box>

          {/*******************
                        CONTENEDOR GRID
                    *******************/}
          <Box sx={formStyles.containerGrid}>
            {/************
                            CIUDAD
                        ************/}
            <Box
              sx={{
                ...formBrachOfficeStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Ciudad</Typography>

              {/* INPUT */}
              <Box sx={formBrachOfficeStyles.input}>
                <InputBase
                  sx={formBrachOfficeStyles.inputBase}
                  name="city"
                  value={dataForm?.city ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.city ?? ''} />
              </Box>
            </Box>

            {/************
                            DIRECCION
                        ************/}
            <Box
              sx={{
                ...formBrachOfficeStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>Dirección</Typography>

              {/* INPUT */}
              <Box sx={formBrachOfficeStyles.input}>
                <InputBase
                  sx={formBrachOfficeStyles.inputBase}
                  name="address"
                  value={dataForm?.address ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.address ?? ''} />
              </Box>
            </Box>

            {/************
                            ENCARGADO
                        ************/}
            <Box
              sx={{
                ...formBrachOfficeStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Encargado</Typography>

              {/* INPUT */}
              <Box sx={formBrachOfficeStyles.input}>
                <InputBase
                  sx={formBrachOfficeStyles.inputBase}
                  name="nameManager"
                  value={dataForm?.nameManager ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.nameManager ?? ''} />
              </Box>
            </Box>

            {/************
                            EMAIL
                        ************/}
            <Box
              sx={{
                ...formBrachOfficeStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>E-mail del encargado</Typography>

              {/* INPUT */}
              <Box sx={formBrachOfficeStyles.input}>
                <InputBase
                  sx={formBrachOfficeStyles.inputBase}
                  name="emailManager"
                  value={dataForm?.emailManager ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.emailManager ?? ''} />
              </Box>
            </Box>

            {/************
                            SIGLAS
                        ************/}
            <Box
              sx={{
                ...formBrachOfficeStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Siglas</Typography>

              {/* INPUT */}
              <Box sx={formBrachOfficeStyles.input}>
                <InputBase
                  sx={formBrachOfficeStyles.inputBase}
                  name="acronym"
                  value={dataForm?.acronym ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.acronym ?? ''} />
              </Box>
            </Box>

            {/*************************
                            NOMBRE DE LA EMPRESA
                        *************************/}
            <Box
              sx={{
                ...formBrachOfficeStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>Nombre de la empresa</Typography>

              {/* INPUT */}
              <span className="span">{company.nameCompany ?? 'No hay empresa'}</span>
            </Box>
          </Box>

          {/*******************
                        CONTENEDOR BLOCK
                    *******************/}
          <Box sx={formBrachOfficeStyles.containerBlock}>
            {/*************
                            IMAGEN
                        ***************/}
            <UploadImage img={img} setImg={setImg} />

            {/**********************
                            CONTENEDOR BOTONES 
                        ***********************/}
            <Box sx={formBrachOfficeStyles.divButton}>
              {/**********************************
                                BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
              <div>
                {/********************
                                    BOTON ACTUALIZAR
                                *********************/}
                <Button
                  disableTouchRipple
                  onClick={handleSubmit}
                  sx={{
                    ...formBrachOfficeStyles.buttonSave,
                    marginRight: '19.52px',
                  }}
                >
                  {params.id ? 'Editar' : 'Crear'}
                </Button>

                {/********************
                                    BOTON DESACTIVAR
                                *********************/}
                <Button disableTouchRipple sx={formBrachOfficeStyles.buttonCancel}>
                  Cancelar
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormBranchOffice
