import { FunctionComponent, memo } from 'react'
import { Navigate } from 'react-router-dom';
import BlankLayout from '../layouts/blank-layout/BlankLayout';
import useAuth from '../hooks/useAuth';

interface Props {
    redirectIstLogged : string,
}
 
const PublicRoute: FunctionComponent<Props> = ({ redirectIstLogged }) => {
    
    const { isAuth } = useAuth()

    return isAuth
    ? <Navigate replace to = {redirectIstLogged}/> 
    : <BlankLayout/>
}
 
export default memo(PublicRoute);