import { Box, TextField, ThemeProvider, Typography } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import dateFieldStyle from './dateField.styles'
import customizeDateField from './customizeDateField'

interface DateFieldProps<T extends FieldValues> extends UseControllerProps<T> {
  disabled?: boolean
  labelText?: string
  minDate?: any
  maxDate?: any
  translateCalendar?: 'dateInitial' | 'dateFinal' | 'dateFulfillment'
}

const toDaysFormat = (timestamp: number | null): Dayjs | null => {
  if (!timestamp) return null
  return dayjs.unix(timestamp)
}
const toTimestampFormat = (days: Dayjs | null): number | null => {
  if (!days) return null
  const finalDate = days.set('hours', 0)
  return finalDate.unix()
}

const useDateField = <T extends FieldValues>() => {
  const DateField: FC<DateFieldProps<T>> = ({
    disabled = false,
    translateCalendar = 'dateFulfillment',
    minDate,
    maxDate,
    labelText,
    ...controller
  }) => {
    const myTheme = customizeDateField(translateCalendar)

    const handleChange = (days: Dayjs | null, onChangeField: (...event: any[]) => void) => {
      // const date = new Date()
      // const nowHours = date.getUTCHours() - 5
      // const today = date.setUTCHours(nowHours)
      // console.log(today);

      const timestamp = toTimestampFormat(days)
      onChangeField(timestamp)
    }

    return (
      <Box sx={dateFieldStyle.container}>
        {/*********************************
                    CONTENEDOR CALENDARIO 
                **********************************/}
        {labelText && <Typography>{labelText}</Typography>}

        {/***************
                    CALENDARIO
                ****************/}
        <ThemeProvider theme={myTheme}>
          <LocalizationProvider locale="es-mx" dateAdapter={AdapterDayjs}>
            <Controller
              {...controller}
              render={({ field: { value, onChange, ...other } }) => (
                <DesktopDatePicker
                  value={toDaysFormat(value)}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={disabled}
                  // disablePast
                  onChange={date => {
                    handleChange(date, onChange)
                  }}
                  renderInput={params => (
                    <TextField sx={dateFieldStyle.textField} disabled={disabled} {...params} {...other} />
                  )}
                  PopperProps={{
                    placement: 'right',
                  }}
                  showDaysOutsideCurrentMonth={true}
                />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </Box>
    )
  }

  return DateField
}

export { useDateField as default, toTimestampFormat, toDaysFormat }
