import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'icon'
  | 'titleContainer'
  | 'title'
  | 'textContainer'
  | 'text'
  | 'buttonYes'
  | 'buttonNo'
  | 'containerButtonsConfirm'
  | 'textBottomContainer'
  | 'textBottom'

const alertDialogStyle: StyleComponent<Keys> = {
  icon: {
    display: 'flex',
    m: 'auto',
    mt: 3,
    mb: 2,
  },
  titleContainer: {
    mt: 1,
  },
  title: {
    color: '#1F398F',
    mx: 'auto',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textContainer: {
    mt: 1,
  },
  text: {
    maxWidth: '96%',
    fontSize: 12,
    lineHeight: '30px',
    fontWeight: 400,
    textAlign: 'center',
    mx: 'auto',
  },
  containerButtonsConfirm: {
    m: 'auto',
    mt: 2,
    width: 'fit-content',
    '&>Button': {
      minWidth: 40,
      minHeight: 17,
      height: 22,
      borderRadius: '4.5px',
      marginRight: '5.51px',

      // TEXTO
      fontSize: 10,
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },
  buttonYes: {
    mr: 1,
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonNo: {
    ml: 1,
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
  textBottomContainer: {
    mt: 4,
  },
  textBottom: {
    fontSize: 10,
    lineHeight: '18px',
    textAlign: 'center',
    fontWeight: 400,
  },
}

export default alertDialogStyle
