import { FunctionComponent } from "react";
interface IconSuitcaseProps {
    width?: number | string;
    height?: number | string;
}

const IconSuitcase: FunctionComponent<IconSuitcaseProps> = ({
    width = 46,
    height = 41,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 46 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.0556 29.6111H20.5C19.2472 29.6111 18.2222 28.5861 18.2222 27.3333H2.30056V36.4444C2.30056 38.95 4.35056 41 6.85611 41H38.7222C41.2278 41 43.2778 38.95 43.2778 36.4444V27.3333H27.3333C27.3333 28.5861 26.3083 29.6111 25.0556 29.6111ZM41 9.11111H31.8889C31.8889 4.07722 27.8117 0 22.7778 0C17.7439 0 13.6667 4.07722 13.6667 9.11111H4.55556C2.05 9.11111 0 11.1611 0 13.6667V20.5C0 23.0283 2.02722 25.0556 4.55556 25.0556H18.2222V22.7778C18.2222 21.525 19.2472 20.5 20.5 20.5H25.0556C26.3083 20.5 27.3333 21.525 27.3333 22.7778V25.0556H41C43.5056 25.0556 45.5556 23.0056 45.5556 20.5V13.6667C45.5556 11.1611 43.5056 9.11111 41 9.11111ZM18.2222 9.11111C18.2222 6.60556 20.2722 4.55556 22.7778 4.55556C25.2833 4.55556 27.3333 6.60556 27.3333 9.11111H18.1994H18.2222Z"
                fill="#7282B8"
            />
        </svg>
    );
};

export default IconSuitcase;
