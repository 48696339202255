import { FunctionComponent } from 'react'
import CustomDialog, { CustomDialogProps } from '../../Dialogs/customDialog/CustomDialog'
import { Box, Button, Typography } from '@mui/material'
import modalDetailItemPdaStyles from './modalDetailItemPda.styles'

type KeyRender = { title: string; value: string | number }

export type ModalDetailItemPdaProps = Omit<CustomDialogProps, 'children'> & {
  itemsRender: KeyRender[]
  onClickButtonAction?: React.MouseEventHandler<HTMLButtonElement>
  textButtonAction?: string
  title?: string
}

const ModalDetailItemPda: FunctionComponent<ModalDetailItemPdaProps> = ({
  itemsRender,
  onClickButtonAction,
  onClose,
  open,
  textButtonAction,
  title,
  ...propsDialog
}) => {
  return (
    <CustomDialog open={open} onClose={onClose} {...propsDialog}>
      {/**************
            CONTENEDOR
        ***************/}
      <Box sx={modalDetailItemPdaStyles.container}>
        <Box sx={modalDetailItemPdaStyles.header}>
          {/**************
                TITULO ITEM
            ***************/}
          <Box>
            <Typography noWrap sx={modalDetailItemPdaStyles.title}>
              {title}
            </Typography>
          </Box>
          {textButtonAction && (
            <Box sx={modalDetailItemPdaStyles.containerButtonheader}>
              <Button sx={modalDetailItemPdaStyles.buttonHeader} fullWidth onClick={onClickButtonAction}>
                {textButtonAction}
              </Button>
            </Box>
          )}
        </Box>

        {/**************
            BODY
        ****************/}
        <Box sx={modalDetailItemPdaStyles.body}>
          {itemsRender.map(({ title, value }, index) => (
            <Box key={`${value}--content--${index}`} sx={modalDetailItemPdaStyles.contentText}>
              <Typography sx={modalDetailItemPdaStyles.subtitle}>{title}</Typography>
              <Typography sx={modalDetailItemPdaStyles.text}>{value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default ModalDetailItemPda
