import { StyleComponent } from '../interfaces/styleComponent'

type Keys = 'customDialog' | 'containerBtn'

const customSelectListStyles: StyleComponent<Keys> = {
  customDialog: {
    // FONDO EN OPACO
    '>.MuiBackdrop-root': { backgroundColor: '#ffffff', opacity: '0.4 !important' },

    // CONTENIDO DIALOG
    '>.MuiDialog-container': {
      '>div': {
        borderRadius: '17px',
        width: '555px',
        height: '688px',
        '>.contenidoDialog': {
          padding: '51px 57px 0px 57px',

          '>.listaDialog': {
            padding: '0px',
          },
        },
      },
    },
  },

  // BOTON GUARDAR
  containerBtn: {
    paddingTop: '36px',
    paddingBottom: '35px',
    '>button': {
      width: '103px !important',
      height: '43px !important',
      textTransform: 'initial',
    },
  },
}

export default customSelectListStyles
