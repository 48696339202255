import * as yup from "yup";
import { BranchOfficeFormValues } from "../interfaces/branchOffice";

export const branchOfficeCreateSchema: yup.SchemaOf<BranchOfficeFormValues> =
  yup.object({
    nameBranchOffice: yup.string()
      .required("Este campo es obligatorio"),
    city: yup.string()
      .required("Este campo es obligatorio"),
    address: yup.string()
      .required("Este campo es obligatorio"),
    nameManager: yup.string()
      .required("Este campo es obligatorio"),
    emailManager: yup.string()
      .email("Estructura de email invalida")
      .required("Este campo es obligatorio"),
    acronym: yup.string()
      .required("Este campo es obligatorio"),
    idCompany: yup.string()
      .required("Este campo es obligatorio"),
  });
