/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import taskAssignmentTabsStyles from '../TaskAssignmentTabs.styles'
import areaNameStyles from '../../../../../components/inputSelect/AreaName.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import useUsers from '../../../../../hooks/users/useUsers'
import useDepartament from '../../../../../hooks/departament/useDepartament'
import Spinner from '../../../../../components/utilities/Spinner'

export const assignmentTaskWhoMeasures = [
  {
    valor: '1',
    name: 'Usuarios',
  },
  {
    valor: '2',
    name: 'Areas',
  },
]

// TEMA
const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '>.MuiPaper-elevation': {
            backgroundColor: '#ffffff',
            boxShadow: 'none',
            minHeight: '86px',
            borderRadius: '15px',
            overflowY: 'hidden',

            // UL
            '>ul': {
              backgroundColor: 'inherit',
              margin: '20px 20px',

              // SCROLL
              overflowY: assignmentTaskWhoMeasures.length > 14 ? 'scroll' : 'hidden',
              '&::-webkit-scrollbar': {
                width: '12px',
                size: '10px',
                '&-track': {
                  backgroundColor: '#FECF5E',
                  borderRadius: '10px',
                  border: '5px solid #ffffff',
                },
                '&-thumb': {
                  backgroundColor: '#1F398F',
                  borderRadius: '20px',
                  width: '10px',
                },
              },

              // LI
              '>li': {
                color: '#000000',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                fontSize: '16px',
                lineHeight: '24px',
                backgroundColor: 'inherit',
                ':hover': {
                  color: '#1F398F',
                  fontWeight: '600',
                  backgroundColor: 'inherit',
                },
              },

              '>.Mui-selected': {
                backgroundColor: '#F1F3F8',
                color: '#1F398F',
                fontWeight: '600',
                borderRadius: '200px',
                ':hover': {
                  backgroundColor: '#F1F3F8',
                },
              },
            },
          },
        },
      },
    },
  },
})

const TabTaskWhoMeasure = ({
  tasksAssignment,
  setTasksAssignment,
  historyTasksAssignment,
  setHistoryTasksAssignment,
}: any) => {
  //hooks
  const { fetchGetUsers } = useUsers()
  const { fetchDepartaments } = useDepartament()

  // ARRAY
  const [option, setOption] = useState('')
  const [list, setList] = useState([])
  const [users, setUsers] = useState([])
  const [usersApi, setUsersApi] = useState([])
  const [radio, setRadio] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  // ESTADO DE SELECT
  const [active, setActive] = useState(false)

  const handleToggleChecked = (id: any, object: any) => {
    const addWhoMeasure: any[] = []
    setRadio(id)
    tasksAssignment.forEach((item: any) => {
      addWhoMeasure.push({
        ...item,
        who_measure: {
          id: id,
          name: object.idProfile.name ?? '',
          lastName: object.idProfile.lastName ?? '',
        },
      })
    })
    setTasksAssignment([...addWhoMeasure])
    setHistoryTasksAssignment({
      ...historyTasksAssignment,
      tabWhoMeasure: {
        ...historyTasksAssignment.tabWhoMeasure,
        isActive: true,
        radio: id,
        name: object.idProfile.name ?? '',
        lastName: object.idProfile.lastName ?? '',
      },
    })
  }

  const handleSelectChange = async (e: any) => {
    setLoading(true)
    let response: any
    setOption(e.target.value)
    switch (e.target.value) {
      case '1':
        setActive(true)
        response = await fetchGetUsers()
        setUsers(response?.items ?? [])
        setUsersApi(response?.items ?? [])
        setHistoryTasksAssignment({
          ...historyTasksAssignment,
          tabWhoMeasure: {
            ...historyTasksAssignment.tabWhoMeasure,
            option: e.target.value,
            users: response?.items ?? [],
          },
        })
        break

      case '2':
        setActive(false)
        response = await fetchDepartaments()
        setList(response?.items ?? [])
        break

      default:
        break
    }
    setLoading(false)
  }

  const handleClickItem = async (id: any) => {
    setLoading(true)
    setUsers([])
    setUsersApi([])
    setActive(true)
    const response: any = await fetchGetUsers()
    const result = response.items.filter((item: any) => item?.idDepartament?._id === id)
    setUsers(result)
    setUsersApi(result)
    setHistoryTasksAssignment({
      ...historyTasksAssignment,
      tabWhoMeasure: {
        ...historyTasksAssignment.tabWhoMeasure,
        option,
        users: result,
      },
    })
    setLoading(false)
  }

  useEffect(() => {
    getHistory()
  }, [])

  const getHistory = async () => {
    if (historyTasksAssignment.tabWhoMeasure?.isActive) {
      setActive(true)
      setOption(historyTasksAssignment.tabWhoMeasure?.option ?? '')
      setUsers(historyTasksAssignment.tabWhoMeasure?.users ?? [])
      setUsersApi(historyTasksAssignment.tabWhoMeasure?.users ?? [])
      setRadio(historyTasksAssignment.tabWhoMeasure?.radio ?? '')
    }
  }

  return (
    <Box className="contentTab" sx={taskAssignmentTabsStyles.contentTab}>
      {/*********************
        CONTENEDOR INPUT
      *********************/}
      <Box
        className="areaName"
        sx={{
          ...areaNameStyles.containerInputSelect,
          display: 'flex',
          '>.MuiFormControl-root': {
            marginRight: '25px',

            '>.MuiInputBase-root': {
              '>.MuiInputBase-input': {
                paddingLeft: '25px',
                color: '#1F398F',
                fontWeight: '600',
              },
            },
          },

          '>.buttonSearch': {
            marginY: 'auto',
            '>div': {
              '>div': {
                width: 'inherit',
              },
            },
          },
        }}
      >
        {/*****************
                    INPUT SELECT
                *****************/}
        <ThemeProvider theme={theme}>
          <FormControl className="aqui" sx={areaNameStyles.inputSelect} variant="standard">
            <Select onChange={handleSelectChange} value={option} displayEmpty>
              <MenuItem value="" disabled disableTouchRipple>
                Selecciona una emisor
              </MenuItem>

              {assignmentTaskWhoMeasures.map(({ valor, name }, index) => (
                <MenuItem key={index} disableTouchRipple value={valor}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ThemeProvider>

        <ButtonSearch data={usersApi} setData={setUsers} fieldSearch={['idProfile.name']} />
      </Box>

      {/*********
                LISTA
            *********/}
      <Box sx={taskAssignmentTabsStyles.containerList} className="containerList">
        <List
          sx={{
            overflowY: active && list.length > 10 ? 'scroll' : 'hidden',
          }}
        >
          {active ? (
            <>
              {option !== '1' && (
                <ListItem className="checkAll">
                  <ListItemButton disableTouchRipple onClick={() => setActive(false)}>
                    Volver
                  </ListItemButton>
                </ListItem>
              )}
              {loading ? (
                <ListItem>
                  <Spinner />
                </ListItem>
              ) : users.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No se encontraron usuarios" />
                </ListItem>
              ) : (
                users.map((item: any, valueCheckbox) => (
                  <ListItem key={item.id}>
                    <ListItemButton disableTouchRipple onClick={() => handleToggleChecked(item._id, item)}>
                      <ListItemIcon className="checked" sx={taskAssignmentTabsStyles.checkedRadio}>
                        <input
                          value={item._id}
                          name="user_departament"
                          type="radio"
                          checked={radio === item._id}
                          onChange={e => handleToggleChecked(e.target.value, item)}
                        />
                        <ListItemText primary={`${item?.idProfile?.name} ${item?.idProfile?.lastName}`} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </>
          ) : (
            list?.map((item: any) => (
              <ListItem key={item._id}>
                <ListItemButton disableTouchRipple onClick={() => handleClickItem(item._id)}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Box>
    </Box>
  )
}

export default TabTaskWhoMeasure
