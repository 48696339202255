import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/styles'

// IMPORTADOS
import doStyles from '../do/Do.styles'
import DatePicker from '../home/DatePicker'
import IconMeasurement from '../../../components/icons/IconMeasurement'
import { useEffect, useState } from 'react'
import useCalendar from '../../../hooks/calendar/useCalendar'
import ListDiary from '../home/ListDiary'
import IconCalendar30 from '../../../components/icons/IconCalendar30'
import verifyStyles from './Verify.styles'

const Verify = () => {
  const navigate = useNavigate()
  const { getTaskDates } = useCalendar()
  const [taskDates, setTaskDates] = useState<string[]>([])

  // TEMA
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  const getSpecialDates = async () => {
    const response: any = await getTaskDates()
    setTaskDates(response.items ?? [])
  }

  useEffect(() => {
    getSpecialDates()
  }, [])

  return (
    <Box sx={doStyles.container}>
      {/******************
        TITULO HACER
      *******************/}
      <Typography>Verificar</Typography>

      {/***********************************
        CONTENEDOR IZQUIERDA / DERECHA
      ************************************/}
      <Box sx={{ flexDirection: 'column' }}>
        {/*************************
          CONTENEDOR IZQUIERDA
        *************************/}
        <Box sx={verifyStyles.contentTop}>
          <Box className="contentParagraph">
            {/* PARRAFO */}
            <Typography className="parrafo">
              En etapa de verificación, se analiza la información recopilada durante la etapa Hacer y se le compara con
              los objetivos y metas trazados, el propósito de este paso consiste en evaluar tu éxito, y qué cosas debes
              conservar para el siguiente paso del proceso.
            </Typography>
          </Box>

          {/*****************/}
          {/* BOTON EVENTOS */}
          {/*****************/}
          <Button
            disableTouchRipple
            onClick={() => navigate('./event/eventAssigned')}
            className="buttonEvent"
            sx={doStyles.buttom}
          >
            {/* ICONO PDI */}
            <IconCalendar30 width={130.002} height={130} />

            {/* TITULO PDI */}
            <Typography className="parrafoPdi">Eventos</Typography>
          </Button>
        </Box>

        {/**********************
          CONTENEDOR DERECHA
        ***********************/}
        <Box sx={{ ...verifyStyles.contentTop, marginTop: '56px' }}>
          {/* <DatePicker specialDates={taskDates} /> */}
          {/******************/}
          {/* BOTON MEDICION */}
          {/******************/}
          <Button
            disableTouchRipple
            onClick={() => navigate('./measurement')}
            className="buttomPdi"
            sx={doStyles.buttom}
          >
            {/* ICONO PDI */}
            <IconMeasurement width={103.07} height={130} colorBarras={secondary} />

            {/* TITULO PDI */}
            <Typography className="parrafoPdi">Medición</Typography>
          </Button>

          {/**************************/}
          {/* LISTADO EVENTOS DE HOY */}
          {/**************************/}
          <ListDiary />
        </Box>
      </Box>
    </Box>
  )
}

export default Verify
