import { FC } from 'react'
import Chart from 'react-apexcharts'
import { Box, Typography } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

// IMPORTADOS
import customGraphicStyles from './CustomGraphics.styles'

ChartJS.register(ArcElement, Tooltip, Legend)

const colors = {
  colorPa1: '#1F398F',
  colorPa2: '#7282B8',
  colorPa3: '#A5B0D2',
  colorPa4: '#FCCC64',
  colorPa5: '#F67839',
  colorPa6: '#BED000',
  colorPa7: '#6DCBD3',
  colorPa8: '#F1F3F8',
}

// const db = [15, 15, 10, 10, 5, 20, 10]

interface CustomGraphicDoughnutProps {
  title?: string
  serieDonut?: any[]
  labelDonut?: any[]
  hiddenList?: string
}

const CustomGraphicDoughnut: FC<CustomGraphicDoughnutProps> = ({
  title = 'Titulo del Pastel',
  serieDonut = [15, 15, 10, 10, 5, 20, 10],
  labelDonut = ['P.A.1', 'P.A.2', 'P.A.3', 'P.A.4', 'P.A.5', 'P.A.6', 'P.A.7'],
  hiddenList = 'inherit',
}) => {
  // *******
  // OPTIONS
  // *******
  const options: any = {
    labels: labelDonut,
    dataLabels: {
      enabled: false,
    },
    colors: Object.values(colors),

    // TEXTO CENTRAL DE LA DONA
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
          labels: {
            show: true,
            value: {
              show: true,
              showAlways: true,
              formatter: function (val: any) {
                return val + '%'
              },
            },
            total: {
              show: true,
              formatter: function (val: any) {
                val.globals.seriesTotals.pop()
                return `${val.globals.seriesTotals.reduce((a: any, b: any) => {
                  return a + b
                }, 0)}%`
              },
            },
          },
        },
      },
    },

    // PORCENTAJES EN CADA CAPA
    legend: {
      show: false,
    },
    // TEXTO FLOTANTE
    tooltip: {
      // enabled: true,
      enabledOnSeries: [1],
    },

    // RESPONSIVE
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  // PORCENTAJE CENTRAL
  const totalSeries =
    100 -
    serieDonut.reduce((a, b) => {
      return (a = a + b)
    })

  // const seriestotalsales = [10, 10, 10, 10, totalBlue];

  const series = [...serieDonut, totalSeries]

  return (
    <Box sx={{ maxWidth: '350px', minWidth: '270px' }}>
      {/*********************/}
      {/* TITULO DE GRAFICA */}
      {/*********************/}
      <Typography sx={customGraphicStyles.titleGraphics}>{title}</Typography>

      {/****************/}
      {/* GRAFICA DONA */}
      {/****************/}
      <Chart type={'donut'} series={series} options={options} />

      {/******************/}
      {/* LISTA DE DATOS */}
      {/******************/}
      <Box sx={{ ...customGraphicStyles.containerGraphicDonut, display: hiddenList }}>
        {options.labels.map((label: any, i: any) => (
          <Box display="flex" alignItems="center" ml="45px">
            {/******************/}
            {/* PUNTO DE COLOR */}
            {/******************/}
            <Box
              sx={{
                backgroundColor: options.colors[i],
              }}
            />

            {/*******************/}
            {/* DATO DE GRAFICA */}
            {/*******************/}
            <Typography color="textSecondary" variant="h6">
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default CustomGraphicDoughnut
