import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys =
    | "container"
    | "title"
    | "contentLeftRight"
    | "contentLeft"
    | "contentRight"
    | "dataTitle"
    | "data";

const infoUsersStyles: StyleComponent<Keys> = {
    container: {
        // backgroundColor: "pink",
        marginX: "100px",
        paddingTop: "93px",
    },

    // TITULO
    title: {
        color: "#1F398F",
        fontSize: "25px",
        fontWeight: "600",
        lineHeight: "38px",
        fontStyle: "normal",
        marginRight: "10px",
    },

    // CONTENIDO IZQUIERDA / DERECHA
    contentLeftRight: {
        backgroundColor: "inherit",
        backgroundRepeat: "no-repeat",
        display: "flex",
        marginTop: "37px",
        paddingBottom: "95px",

        ">div": {
            width: "50%",
        },
    },

    // CONTENIDO IZQUIERDA
    contentLeft: {
        ">div, >img": {
            backgroundColor: "#7282B81A",
            height: "498px",
            width: "428px",
            borderRadius: "17px",
            ">img": {
                // backgroundPosition: "center",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "cover",
                width: "inherit",
                height: "inherit",
                borderRadius: "17px",
            },
        },
    },

    // CONTENIDO DERECHA
    contentRight: {
        // backgroundColor: "fuchsia",

        ">div": {
            marginLeft: "50px",
        },
    },

    data: {
        // backgroundColor: "aqua",
        ">p": {
            color: "#1F398F",
            fontSize: "15px",
            fontWeight: "600",
            marginBottom: "10px",
            ">span": {
                color: "#000000 !important",
                fontWeight: "400 !important",
            },
        },
    },

    dataTitle: {
        fontSize: "20px !important",
    },
};

export default infoUsersStyles;
