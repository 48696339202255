import { FunctionComponent } from "react";
interface IconCloseModalProps {
    width?: number | string;
    height?: number | string;
}

const IconClose: FunctionComponent<IconCloseModalProps> = ({
    width = 16,
    height = 16,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.0209 14.0208L2.00006 2"
                stroke="#003487"
                strokeWidth="3"
                strokeLinecap="round"
            />
            <path
                d="M14.0209 2L2.00006 14.0208"
                stroke="#003487"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconClose;
