// const host = 'https://api-aplanner.herokuapp.com'
// const host = 'https://nextjs-production-b61a.up.railway.app'
const host = process.env.REACT_APP_API_URL

interface FetchProps{
    endpoit : string
    method : 'GET' | 'POST' | 'PUT' | 'DELETE'
    body? : any | null | undefined
    typeofBody? : 'object' | 'formData'
    token? : string     
}

const fetchApi = ({ 
    method = 'GET', 
    endpoit, 
    body, 
    typeofBody = 'object',
    token,
} : FetchProps) => {

    const headers = new Headers()

    if(token) headers.append('Authorization', token)

    const contentType = typeofBody === 'object' ? 'application/json' : null

    if(contentType) headers.append('Content-Type', contentType)

    const _body = contentType === 'application/json' ? JSON.stringify(body) : body

    return fetch(`${host}${endpoit}`, {
        method,
        headers,
        body : _body
    })
}

export default fetchApi;