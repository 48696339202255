import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// IMPORTADOS
import branchOfficeStyles from './branchOffice.styles'
import useImage from '../../../hooks/image/useImage'
import ValidateAction from '../../../routes/validateAction'
import IconAdd from '../../../components/icons/IconAdd'
import IconLocation from '../../../components/icons/IconLocation'
import IconArrowRight from '../../../components/icons/IconArrowRight'
import useBranchOffice from '../../../hooks/branchOffice/useBranchOffice'
import { BranchOffice as BranchOfficeType } from '../../../interfaces/branchOffice'
import { listPermissions } from '../../../routes/permissions'
import withoutImage from '../../../images/withoutImage.png'

const BranchOffice = () => {
  const { fetchGetBranchOffice } = useBranchOffice()
  const { getUrl } = useImage()
  const navigate = useNavigate()

  const [listBranchOffices, setListBranchOffices] = useState<BranchOfficeType[]>([])
  const [images, setImages] = useState<any>({})

  useEffect(() => {
    getBranchOffice()
  }, [])

  const getBranchOffice = async () => {
    const response: any = await fetchGetBranchOffice()
    const data = response.items
    setListBranchOffices(data)

    let keysImgs = {}
    data?.forEach((element: any, i: any) => {
      keysImgs = {
        ...keysImgs,
        [element?._id]: element?.representativeImage,
      }
    })
    const reponseUrl: any = await getUrl({ action: 'getUrl', form: keysImgs })
    setImages(reponseUrl.data ?? {})
  }

  return (
    <>
      <Box sx={branchOfficeStyles.container}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Box sx={branchOfficeStyles.title}>
          <Box>
            {/**********/}
            {/* TITULO */}
            {/**********/}
            <Typography>Sucursales</Typography>

            {/******************/}
            {/* FLECHA DERECHA */}
            {/*******************/}
            <IconArrowRight width={11} height={22} />

            {/*******************/}
            {/* BOTON SUBTITULO */}
            {/*******************/}
            <Button href="#" disableTouchRipple className="btnTitle">
              <Typography>Institución Universitaria Americana.</Typography>

              <span>I. U. A</span>
            </Button>
          </Box>

          {/*************/}
          {/* BOTON MÁS */}
          {/*************/}
          <ValidateAction permission={[listPermissions.branchOffice.create]}>
            <Button href="branchOffice/create" disableTouchRipple className="btnMore">
              <IconAdd />

              <Typography>Crear nueva sucursal</Typography>
            </Button>
          </ValidateAction>
        </Box>

        {/**************/}
        {/* SUCURSALES */}
        {/**************/}
        <Box sx={branchOfficeStyles.branchOffice}>
          {listBranchOffices.map((item: any) => (
            <Button
              key={item._id}
              disableTouchRipple
              onClick={() => navigate(`info/${item._id}`)}
              sx={{
                backgroundImage: `url(${images[item._id]})` && `url(${withoutImage})`,
                marginX: '20px',
              }}
            >
              <Box>
                <Typography>
                  <IconLocation />
                  {item.nameBranchOffice}
                </Typography>
              </Box>
            </Button>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default BranchOffice
