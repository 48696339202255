import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IconFile from '../../../components/icons/IconFile'
import IconLess from '../../../components/icons/IconLess'
import IconUpload from '../../../components/icons/IconUpload'
import customAlerts from '../../../helpers/customAlerts'
import formBrachOfficeStyles from './FormBranchOffice.styles'

interface UploadImageProps {
  img: any
  setImg: Function
}

const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg']

const UploadImage = ({ img, setImg }: UploadImageProps) => {
  const { errorAlert } = customAlerts

  const [dropAreaStyles, setDropAreaStyles] = useState({ ...formBrachOfficeStyles.imagen })
  const [drag, setDrag] = useState(false)

  useEffect(() => {
    const dropArea = document.querySelector('#drop-area')

    // Evento dragover - acciones que debe hacer al detectar que entra el archivo dentro del area
    dropArea?.addEventListener('dragover', (e: any) => {
      e.preventDefault()
      setDropAreaStyles({ ...dropAreaStyles, ...formBrachOfficeStyles.dropAreaActive })
      setDrag(true)
    })

    // Evento dragleave - acciones que debe hacer al detectar que sale el archivo dentro del area
    dropArea?.addEventListener('dragleave', (e: any) => {
      e.preventDefault()
      setDropAreaStyles({ ...formBrachOfficeStyles.imagen })
      setDrag(false)
    })

    // Evento Drop - Accciones que debe hacer al soltar el archivo dentro del area
    dropArea?.addEventListener('drop', (e: any) => {
      e.preventDefault()
      setDrag(false)
      setDropAreaStyles({ ...formBrachOfficeStyles.imagen })

      previewImg(e.dataTransfer.files[0])
      e.target.value = null
    })
  }, [])

  const previewImg = (image: any) => {
    if (allowedTypes.some((value: string) => value === image.type)) {
      const img = { preview: URL.createObjectURL(image), file: image }
      setImg(img)
    } else {
      errorAlert({ html: 'Tipo de archivo no permitido ❌' })
    }
  }

  return (
    <Box
      sx={{
        ...formBrachOfficeStyles.subTitle,
        display: 'block',
      }}
    >
      <Typography>Imagen</Typography>

      {/************
                                IMAGEN 
                            ************/}
      {img?.file === undefined ? (
        <Box sx={dropAreaStyles} id="drop-area">
          <Button variant="contained" component="label" disableTouchRipple style={{ textTransform: 'initial' }}>
            <Box>
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e: any) => {
                  previewImg(e.target.files[0])
                }}
              />

              {/********************** 
                ICONO CARGA/SUBIDA 
              ***********************/}
              <div>
                <IconUpload color={drag ? '#fff' : '#7282B8'} />
              </div>

              <Typography style={drag ? { color: 'white' } : undefined}>Agregar imagen</Typography>
              <span style={drag ? { color: 'white' } : undefined}>o arrastra y suelta</span>
            </Box>
          </Button>
        </Box>
      ) : (
        <Box sx={formBrachOfficeStyles.formImage}>
          <Box>
            <Box>
              <IconFile />

              <Typography>{img?.file?.name ?? 'Sin nombre'}</Typography>
            </Box>

            <Button disableTouchRipple onClick={() => setImg(undefined)}>
              <IconLess />
            </Button>
          </Box>

          <Button disableTouchRipple variant="contained" component="label">
            Reemplazar fotografía
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(e: any) => {
                previewImg(e.target.files[0])
              }}
            />
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default UploadImage
