import {useCallback} from 'react'

import useFetch from "../useFetch"

interface FetchTypesProps {
    limit? : number
    from? : number
    active? : boolean
}

const useTypes = () => {

    const {fetchList} = useFetch()

    const fetchTypes = useCallback( async ({ limit = 0, from = 0, active = true } : FetchTypesProps = {}) => {
        return fetchList<any>({
            action : 'getTypes',
            params : [
                ['from', `${from}`],
                ['limit', `${limit}`],
                ['active' , `${active}`]
            ]
        })
    }, [fetchList])

    return {
        fetchTypes,
    }
}

export default useTypes;