import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import CustomTitle from '../../../../../components/CustomTitle'
import doAssignmentStyles from '../DoAssignment.styles'
import IconDownload from '../../../../../components/icons/IconDownload'
import formStyles from '../../../../../components/forms/form.styles'
import useFormula from '../../../../../hooks/do/useFormula'
import { dateToString } from '../../../../../services/ParseDate'
import useImage from '../../../../../hooks/image/useImage'

const CheckIn = () => {
  const [hiddenDecision, setHiddenDecision] = useState('None')
  const [data, setData] = useState<any>({})
  const [url, setUrl] = useState<any>({})
  const [stateData, setStateData] = useState<any>({})
  let navigate = useNavigate()
  let params: any = useParams()
  const { fetchGetOneFormula, fetchCheckin } = useFormula()
  const { getUrl } = useImage()

  useEffect(() => {
    if (params.id) {
      getMeasure()
    }
  }, [])

  const getMeasure = async () => {
    const res = await fetchGetOneFormula(params.id)
    setData(res)

    const resUrl: any = await getUrl({ action: 'getUrl', form: { url: res.uploadEvidence } })
    setUrl(resUrl?.data?.url)
  }

  // Input Field handler
  const handleInputBase = (e: { target: { value: string; name: string } }) => {
    setStateData({ ...stateData, [e.target.name]: e.target.value })
  }

  // Reset Input Field handler
  const stateTrue = () => {
    setHiddenDecision('Inactivo')
    setStateData({
      ...stateData,
      idStatus: 1,
    })
  }
  const stateFalse = () => {
    setHiddenDecision('Activo')
    setStateData({
      ...stateData,
      idStatus: 2,
    })
  }

  const download = () => {
    window.open(`${url}`, 'blank')
  }

  const handleSubmit = async () => {
    await fetchCheckin({
      ...stateData,
      uid: data._id,
      idEvent: data.idEvent?._id ?? undefined,
    })
  }

  return (
    <Box sx={doAssignmentStyles.container}>
      <CustomTitle title="Asignación" subTitle="Registro de Medición" />

      {/*********************
        CONTAINER CHECK-IN
      *********************/}
      <Box sx={doAssignmentStyles.containerCheckIn}>
        <Box className="contentCheckIn">
          <Typography>Registro de Medición</Typography>

          <Box className="rowCheckIn">
            <Box>
              <Typography>Fecha</Typography>
              <Typography component={'span'}>{dateToString(data.createdAt ?? '')}</Typography>
            </Box>

            <Box>
              <Typography>Númerador</Typography>
              <Typography component={'span'}>{data.numerator ?? ''}</Typography>
            </Box>

            <Box>
              <Typography>Denominador</Typography>
              <Typography component={'span'}>{data.goal ?? ''}</Typography>
            </Box>

            <Box>
              <Typography>Resultado</Typography>
              <Typography component={'span'}>{data.result ?? ''}</Typography>
            </Box>

            <Box>
              <Typography>Meta</Typography>
              <Typography component={'span'}>{data.goal ?? ''}</Typography>
            </Box>

            <Box>
              <Typography>Tipo de indicador</Typography>
              <Typography component={'span'}>{data.idIndicator?.idType?.name ?? ''}</Typography>
            </Box>

            <Box>
              <Typography>Frecuencia de medición</Typography>
              <Typography component={'span'}>{data.idIndicator?.idMeasurementFrecuency?.name ?? ''}</Typography>
            </Box>
          </Box>

          <Box className="justifyCheckIn">
            <Typography>Justificación</Typography>

            <Typography component={'span'}>{data.mesurementJustification}</Typography>
          </Box>

          {/* Datos del evento / siempre y cuando la medicion tenga uno asociado */}
          {data.idEvent?._id && (
            <>
              <Typography>Registro de evento</Typography>

              <Box className="rowCheckIn" sx={{ display: 'block !important' }}>
                <Box sx={{ textAlign: 'start' }}>
                  <Typography>Nombre del evento</Typography>
                  <Typography component={'span'}>{data.idEvent?.name}</Typography>
                </Box>
                <Box sx={{ textAlign: 'start' }}>
                  <Typography>Justificación</Typography>
                  <Typography component={'span'}>{data.idEvent?.justification}</Typography>
                </Box>
                <Box sx={{ textAlign: 'start' }}>
                  <Typography>Fecha inicial</Typography>
                  <Typography component={'span'}>{dateToString(data.idEvent?.startDate ?? '')}</Typography>
                </Box>
                <Box sx={{ textAlign: 'start' }}>
                  <Typography>Fecha final</Typography>
                  <Typography component={'span'}>{dateToString(data.idEvent?.finalDate ?? '')}</Typography>
                </Box>
              </Box>
            </>
          )}

          <Box className="containerBtns">
            <Button disableTouchRipple onClick={download}>
              <IconDownload width={10.4} height={13} /> Descargar evidencia
            </Button>

            <Box>
              <Button
                disableTouchRipple
                onClick={stateTrue}
                sx={{
                  marginRight: '20px',
                  backgroundColor: hiddenDecision === 'Inactivo' ? '#1F398F !important' : '#FFFFFF',
                  color: hiddenDecision === 'Inactivo' ? '#FFFFFF !important' : '#1F398F',
                }}
              >
                Aprobado
              </Button>
              <Button
                disableTouchRipple
                onClick={stateFalse}
                sx={{
                  backgroundColor: hiddenDecision === 'Activo' ? '#1F398F !important' : '#FFFFFF',
                  color: hiddenDecision === 'Activo' ? '#FFFFFF !important' : '#1F398F',
                }}
              >
                No Aprobado
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/****************
        JUSTIFICACION
      ****************/}
      <Box
        sx={{
          ...doAssignmentStyles.checkInDecision,
          marginTop: hiddenDecision === 'Inactivo' ? '-0px' : '50px' && hiddenDecision === 'None' ? '-0px' : '50px',
          visibility:
            hiddenDecision === 'Inactivo' ? 'hidden' : 'inherit' && hiddenDecision === 'None' ? 'hidden' : 'inherit',
          opacity: hiddenDecision === 'Inactivo' ? '0' : '1' && hiddenDecision === 'None' ? '0' : '1',
          transition: 'all 0.5s ease-in-out',
        }}
      >
        {/*********
          TITULO
        *********/}
        <Typography>Justifique su decisión</Typography>
        <TextField
          value={stateData.justification}
          onChange={handleInputBase}
          className="inputJustify"
          name="justification"
          multiline
          rows={6}
          // error={inputValue.length > 900 ? true : false}
          // helperText={inputValue.length > 900 ? 'Límite de carácteres' : ''}
          inputProps={{
            maxLength: 900,
          }}
          sx={{
            ...doAssignmentStyles.inputJustify,
            // '>div': { '>fieldset': { borderColor: 'red' } }
          }}
        />
        {/* </Box> */}
      </Box>

      {/**********************
        CONTENEDOR BOTONES 
      ***********************/}
      <Box
        sx={{
          ...formStyles.divButton,
          marginTop: hiddenDecision === 'Inactivo' ? '-99px' : '102px' && hiddenDecision === 'None' ? '-99px' : '102px',
          transition: '0.5s ease-in-out',
        }}
      >
        {/*****************************
          BOTONES GUARDAR/CANCELAR
        ******************************/}
        <div>
          {/********************
            BOTON GUARDAR
          *********************/}
          <Button
            disabled={
              hiddenDecision === 'Inactivo' ? false : true && stateData.justification?.length >= 30 ? false : true
            }
            disableTouchRipple
            type="submit"
            sx={{
              ...formStyles.buttonSave,
              backgroundColor:
                hiddenDecision === 'Inactivo'
                  ? '#7282B8'
                  : '#F1F3F8' && stateData.justification?.length >= 30
                  ? '#7282B8'
                  : '#F1F3F8',
              color: '#ffffff',
              maxWidth: '196.741px !important',
              width: '196.741px !important',

              ':hover': { backgroundColor: '#7282B8' },
            }}
            onClick={handleSubmit}
          >
            Guardar
          </Button>

          {/********************
            BOTON CANCELAR
          *********************/}
          <Button
            // onClick={onCancel}
            disableTouchRipple
            onClick={e => navigate(-1)}
            sx={{
              ...formStyles.buttonCancel,
              maxWidth: '196.741px !important',
              width: '196.741px !important',
            }}
          >
            Cancelar
          </Button>
        </div>
      </Box>
    </Box>
  )
}

export default CheckIn
