import { useCallback, useState } from "react";
import GenericAlert, { GenericAlertProps } from "../../components/Alerts/GenericAlert/GenericAlert";
import customAlerts from "../../helpers/customAlerts";
import { ChangeActiveIndicator, IndicatorModel, NewIndicator, UpdateIndicator } from "../../interfaces/pdi/indicator";
import useFetch, { BasicFetchListProps } from "../useFetch";

const { errorAlert } = customAlerts

const useIndicator = () => {

    const { fetchCreate, fetchUpdate, fetchDisable, fetchList } = useFetch()
    
    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })
    
    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )
    const fetchGetIndicator = useCallback(async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
        return fetchList<IndicatorModel>({
            action: 'getIndicators',
            params: [
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                errorAlert({ html: "Contactar con soporte 🚫" })
            })
    }, [fetchList])

    const fetchCreateIndicator = useCallback((body: NewIndicator, done?: (_id: string) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createIndicator', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El indicador se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_id),
                })
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchCreate])

    const fetchUpdateIndicator = useCallback((body: UpdateIndicator, done?: (_updateValues: UpdateIndicator) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate<UpdateIndicator>({ action: 'updateIndicator', body })
            .then(_updateValues => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El indicador se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchUpdate])

    const fetchDisableIndicator = useCallback((body: ChangeActiveIndicator, done?: () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disableIndicator', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `El indicador ha sido ${body.is_active ? 'habilitado' : 'eliminado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return {
        fetchGetIndicator,
        fetchCreateIndicator,
        fetchUpdateIndicator,
        fetchDisableIndicator,
        ViewAlert
    }
}

export default useIndicator;