import { FunctionComponent, useState } from 'react'
import { Box, Typography, List, ListItem, ListItemButton, ListItemText, Pagination, Fade } from '@mui/material'

// IMPORTADAS
import relationsStyles from '../../process/relations/Relations.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import { Factor } from '../../../../../interfaces/factor'
import usePagination from '../../../../../hooks/usePagination'

interface RelationFactorsProps {
  onChangeFactor: (factor: Factor | null) => void
  selectedFactor: Factor | null
  factors: Factor[] | null
}

const itemsPerPage = 11

const RelationFactors: FunctionComponent<RelationFactorsProps> = ({ onChangeFactor, factors, selectedFactor }) => {
  
  const [searchFactors, setSearchFactors] = useState<Factor[]>([])

  const { currentData, currentPage, countPage, handleChangePage } = usePagination(searchFactors, itemsPerPage)

  return (
    <Box>
      {/***********************
            TITULO FACTORES/RELACIONES
        ************************/}
      <Box className="titleButtonSearch" sx={relationsStyles.titleButtonSearch}>
        <Typography>Factores</Typography>
        <ButtonSearch data={factors ?? []} setData={setSearchFactors} fieldSearch={['name']} />
      </Box>

      <Box className="containerList">
        <Fade in={Boolean(currentData().length ) && Boolean(factors)} hidden={!Boolean(currentData().length) && Boolean(factors)}>
          <List>
            {currentData().map((factor, index) => (
              <ListItem key={index} onClick={() => onChangeFactor(factor)}>
                <ListItemButton selected={selectedFactor?._id === factor._id} disableTouchRipple>
                  <ListItemText>{factor.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Fade>
        <Fade in={!Boolean( currentData().length) && Boolean(factors)} hidden={Boolean(currentData().length) || !Boolean(factors)}>
          <List>
            <Typography sx={{ my: 1 }}>No hay se enconrtraron factores disponibles para relacionar.</Typography>
          </List>
        </Fade>
        <Pagination
          page={currentPage}
          defaultPage={1}
          count={countPage}
          onChange={(e, _page) => handleChangePage(_page)}
        />
      </Box>
    </Box>
  )
}

export default RelationFactors
