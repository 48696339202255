import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  List,
  Button,
  Pagination,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Fade,
} from '@mui/material'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import containerRelationsStyles from '../../../../../components/containers/ContainerRelations/containerRelations.styles'
import indicadoresStyles from '../../process/relations/IndicatingRelations.styles'
import { IndicatorModel } from '../../../../../interfaces/pdi/indicator'
import usePagination from '../../../../../hooks/usePagination'
import { IndicatorCondition, NewRelIndicatorCondition } from '../../../../../interfaces/indicator-condition'
import { Condition } from '../../../../../interfaces/condition'

interface RelationIndicatorsConditionProps {
  indicators: IndicatorModel[]
  indicatorsCondition: IndicatorCondition[]
  selectedCondition: Condition
  onSave?: (relIndicatorCondition: NewRelIndicatorCondition) => void
  onChangedRelationships?: (changedRelationships: boolean) => void
}

const itemsPerPage = 12

const RelationIndicatorsCondition: FunctionComponent<RelationIndicatorsConditionProps> = ({
  indicators,
  indicatorsCondition,
  selectedCondition,
  onSave,
  onChangedRelationships,
}) => {
  // Lista de todos los indicadores válidos que puede relacionar la condicion seleccionada
  const [indicatorsAvailable, setIndicatorsAvailable] = useState<IndicatorModel[]>([])

  const [searchIndicatorsAvailable, setSearchIndicatorsAvailable] = useState<IndicatorModel[]>([])

  // Todos los ids relacionados y checados en la lista
  const [checkedIndicatorIds, setCheckedIndicatorIds] = useState<string[]>([])

  const [changedRelationships, setChangedRelationships] = useState(false)

  const itsRelated = (idIndicator: string) =>
    indicatorsCondition.some(
      ({ idIndicator: indicator, idCondition: condition, is_active }) =>
        is_active && indicator?.is_active && condition?.is_active && indicator._id === idIndicator
    )

  const itsRelatedToSelectedCondition = (idIndicator: string) =>
    indicatorsCondition.some(
      ({ idIndicator: indicator, idCondition: condition, is_active }) =>
        is_active &&
        condition?.is_active &&
        indicator?.is_active &&
        condition._id === selectedCondition._id &&
        indicator._id === idIndicator
    )

  const { currentData, currentPage, countPage, handleChangePage } = usePagination(
    searchIndicatorsAvailable,
    itemsPerPage
  )

  const isChechedIndicatorId = (idIndicator: string) => checkedIndicatorIds.some(id => id === idIndicator)

  const handleCheckedIndicator = (idIndicator: string) => {
    const _checkedIndicatorIds = [...checkedIndicatorIds]
    const index = _checkedIndicatorIds.indexOf(idIndicator)
    if (index >= 0) {
      _checkedIndicatorIds.splice(index, 1)
      return setCheckedIndicatorIds(_checkedIndicatorIds)
    }
    setCheckedIndicatorIds(state => [...state, idIndicator])
  }

  const saveRelations = () => {
    const { _id: idCondition } = selectedCondition
    const relIndicatorCondition: NewRelIndicatorCondition = {
      idCondition,
      idIndicator: checkedIndicatorIds,
    }
    if (onSave) onSave(relIndicatorCondition)
  }

  useEffect(() => {
    const avalilables = indicators
      .filter(({ _id }) => itsRelatedToSelectedCondition(_id) || !itsRelated(_id))
      .sort((a, b) => {
        const existA = itsRelated(a._id)
        const existB = itsRelated(b._id)
        return Number(existB) - Number(existA)
      })
    const _checkedIndicatorIds = avalilables.filter(({ _id }) => itsRelatedToSelectedCondition(_id)).map(i => i._id)
    setIndicatorsAvailable(avalilables)
    setCheckedIndicatorIds(_checkedIndicatorIds)
    if (currentPage > 1) {
      handleChangePage(1)
    }
  }, [selectedCondition, indicatorsCondition, indicators])

  useEffect(() => {
    const { _id: idCondition } = selectedCondition
    const isIncludeNewIndicators = checkedIndicatorIds.some(id => !itsRelatedToSelectedCondition(id))
    const isRemoveIndicator = indicatorsCondition.some(
      ({ idCondition: condition, idIndicator: indicator, is_active }) => {
        return (
          is_active &&
          condition?.is_active &&
          indicator?.is_active &&
          condition._id === idCondition &&
          !isChechedIndicatorId(indicator._id)
        )
      }
    )
    const _changedRelationships = isIncludeNewIndicators || isRemoveIndicator
    setChangedRelationships(_changedRelationships)
    if (onChangedRelationships) onChangedRelationships(_changedRelationships)
  }, [checkedIndicatorIds])

  return (
    <Box>
      {/***********************
          TITULO INDICADORES
      ***********************/}
      <Box className="titleButtonSearch" sx={containerRelationsStyles.titleButtonSearch}>
        <Typography>Indicadores</Typography>

        <ButtonSearch data={indicatorsAvailable} fieldSearch={['name']} setData={setSearchIndicatorsAvailable} />
      </Box>

      <Box sx={indicadoresStyles.container}>
        {/***********************
              LISTA INDICADORES
          ************************/}

        <Fade in={Boolean(currentData().length)} hidden={!Boolean(currentData().length)}>
          <List>
            {currentData().map(({ _id, name }) => (
              <ListItem key={_id}>
                <ListItemButton onClick={() => handleCheckedIndicator(_id)} disableTouchRipple disableRipple dense>
                  <ListItemIcon>
                    <Checkbox
                      // onChange={() => handleCheckedIndicator(_id)}
                      disableRipple
                      edge="start"
                      checked={isChechedIndicatorId(_id)}
                    />
                  </ListItemIcon>
                  <ListItemText>{name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Fade>

        <Fade in={!Boolean(currentData().length)} hidden={Boolean(currentData().length)} timeout={1000}>
          <List>
            <Typography sx={{ my: 1 }}>No hay se encontraron indicadores disponibles para relacionar.</Typography>
          </List>
        </Fade>

        {/********************************
              BOTONES PAGINACION Y GUARDAR
          ********************************/}
        <Box>
          {/****************
                    PAGINACION
                ****************/}
          <Pagination
            page={currentPage}
            defaultPage={1}
            count={countPage}
            onChange={(e, _page) => handleChangePage(_page)}
          />

          {/******************
                    BOTON GUARDAR

                ******************/}
          <Button disabled={!changedRelationships} disableTouchRipple onClick={saveRelations}>
            Guardar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default RelationIndicatorsCondition
