// SIMULACION DE API CARGO
export const nombreCargo = [
  {
    valor: 10,
    nombre: 'Cargo *********************',
  },
  {
    valor: 20,
    nombre: 'Cargo *********************',
  },
  {
    valor: 30,
    nombre: 'Cargo *********************',
  },
  {
    valor: 40,
    nombre: 'Cargo *********************',
  },
  {
    valor: 50,
    nombre: 'Cargo *********************',
  },
  {
    valor: 60,
    nombre: 'Cargo *********************',
  },
  {
    valor: 70,
    nombre: 'Cargo *********************',
  },
  {
    valor: 80,
    nombre: 'Cargo *********************',
  },
  {
    valor: 90,
    nombre: 'Cargo *********************',
  },
  {
    valor: 100,
    nombre: 'Cargo *********************',
  },
  {
    valor: 110,
    nombre: 'Cargo *********************',
  },
  {
    valor: 120,
    nombre: 'Cargo *********************',
  },
]

// SIMULACION DE API AREA
export const nombreArea = [
  {
    valor: 10,
    nombre: 'Area *********************',
  },
  {
    valor: 20,
    nombre: 'Area *********************',
  },
  {
    valor: 30,
    nombre: 'Area *********************',
  },
  {
    valor: 40,
    nombre: 'Area *********************',
  },
  {
    valor: 50,
    nombre: 'Area *********************',
  },
  {
    valor: 60,
    nombre: 'Area *********************',
  },
  {
    valor: 70,
    nombre: 'Area *********************',
  },
  {
    valor: 80,
    nombre: 'Area *********************',
  },
  {
    valor: 90,
    nombre: 'Area *********************',
  },
  {
    valor: 100,
    nombre: 'Area *********************',
  },
  {
    valor: 110,
    nombre: 'Area *********************',
  },
  {
    valor: 120,
    nombre: 'Area *********************',
  },
]

// SIMULACION DE API CARGO JEFE
export const cargoJefe = [
  {
    valor: 10,
    nombre: 'Cargo *********************',
  },
  {
    valor: 20,
    nombre: 'Cargo *********************',
  },
  {
    valor: 30,
    nombre: 'Cargo *********************',
  },
  {
    valor: 40,
    nombre: 'Cargo *********************',
  },
  {
    valor: 50,
    nombre: 'Cargo *********************',
  },
  {
    valor: 60,
    nombre: 'Cargo *********************',
  },
  {
    valor: 70,
    nombre: 'Cargo *********************',
  },
  {
    valor: 80,
    nombre: 'Cargo *********************',
  },
  {
    valor: 90,
    nombre: 'Cargo *********************',
  },
  {
    valor: 100,
    nombre: 'Cargo *********************',
  },
  {
    valor: 110,
    nombre: 'Cargo *********************',
  },
  {
    valor: 120,
    nombre: 'Cargo *********************',
  },
]

// SIMULACION DE API POLITICAS PDI
export const politicsPdi = [
  // {
  //     numeroPolitica: 1,
  //     nombrePolitica:
  //         "Política tal Política tal Política tal Política tal política tal política",
  // },
  // {
  //     numeroPolitica: 2,
  //     nombrePolitica:
  //         "Política tal Política tal Política tal Política tal política tal política",
  // },
  // {
  //     numeroPolitica: 2,
  //     nombrePolitica:
  //         "Política tal Política tal Política tal Política tal política tal política",
  // },
  // {
  //     numeroPolitica: 2,
  //     nombrePolitica:
  //         "Política tal Política tal Política tal Política tal política tal política",
  // },
]

// SIMULACION DE API OBJETIVOS PDI
export const objetivesPdi = [
  {
    estado: 'Activo',
    nombreObjetivo: '1. Objetivo tal Objetivo tal Objetivo tal Objetivo tal Objetivo tal.',
    tipoDeObjetivo: 'Tipo de objetivo',
  },
  {
    estado: 'Activo',
    nombreObjetivo: '2. Objetivo tal Objetivo tal Objetivo tal Objetivo tal Objetivo tal.',
    tipoDeObjetivo: 'Tipo de objetivo',
  },
  {
    estado: 'Activo',
    nombreObjetivo: '3. Objetivo tal Objetivo tal Objetivo tal Objetivo tal Objetivo tal.',
    tipoDeObjetivo: 'Tipo de objetivo',
  },
  {
    estado: 'Activo',
    nombreObjetivo: '4. Objetivo tal Objetivo tal Objetivo tal Objetivo tal Objetivo tal.',
    tipoDeObjetivo: 'Tipo de objetivo',
  },
]

// SIMULACION DE API PROGRAMAS PDI
export const programsPdi = [
  {
    numeroPrograma: 1,
    nombrePrograma: 'Nombre del programa Nombre del programa Nombre del programa',
    nombreDeObjetivo: 'Nombre del objetivo',
  },
  {
    numeroPrograma: 2,
    nombrePrograma: 'Nombre del programa Nombre del programa Nombre del programa',
    nombreDeObjetivo: 'Nombre del objetivo',
  },
  {
    numeroPrograma: 3,
    nombrePrograma: 'Nombre del programa Nombre del programa Nombre del programa',
    nombreDeObjetivo: 'Nombre del objetivo',
  },
  {
    numeroPrograma: 4,
    nombrePrograma: 'Nombre del programa Nombre del programa Nombre del programa',
    nombreDeObjetivo: 'Nombre del objetivo',
  },
]

// SIMULACION DE API PROYECTOS PDI
export const proyectsPdi = [
  {
    numeroPrograma: 1,
    nombrePrograma: 'Nombre del proyecto Nombre del proyecto Nombre del proyecto',
    numeroMetas: 0,
    numeroRetos: 0,
  },
  {
    numeroPrograma: 2,
    nombrePrograma: 'Nombre del proyecto Nombre del proyecto Nombre del proyecto',
    numeroMetas: 0,
    numeroRetos: 0,
  },
  {
    numeroPrograma: 3,
    nombrePrograma: 'Nombre del proyecto Nombre del proyecto Nombre del proyecto',
    nombreDeObjetivo: 'Nombre del objetivo',
    numeroMetas: 0,
    numeroRetos: 0,
  },
  {
    numeroPrograma: 4,
    nombrePrograma: 'Nombre del proyecto Nombre del proyecto Nombre del proyecto',
    numeroMetas: 0,
    numeroRetos: 0,
  },
]

// SIMULACION DE API METAS PDI
export const goalsPdi = [
  {
    numeroMeta: 1,
    nombreMeta: 'Nombre de la meta Nombre de la meta Nombre de la meta ',
    numeroMetas: 0,
    numeroIndicadores: 0,
  },
  {
    numeroMeta: 2,
    nombreMeta: 'Nombre de la meta Nombre de la meta Nombre de la meta ',
    numeroMetas: 0,
    numeroIndicadores: 0,
  },
  {
    numeroMeta: 3,
    nombreMeta: 'Nombre de la meta Nombre de la meta Nombre de la meta ',
    nombreDeObjetivo: 'Nombre del objetivo',
    numeroMetas: 0,
    numeroIndicadores: 0,
  },
  {
    numeroMeta: 4,
    nombreMeta: 'Nombre de la meta Nombre de la meta Nombre de la meta ',
    numeroMetas: 0,
    numeroIndicadores: 0,
  },
]

// SIMULACION DE API RETOS PDI
export const challengePdi = [
  {
    numeroReto: 1,
    nombreReto: 'Nombre del reto Nombre del reto Nombre del reto ',
    fechaRetos: '00/00/0000',
  },
  {
    numeroReto: 2,
    nombreReto: 'Nombre del reto Nombre del reto Nombre del reto ',
    fechaRetos: '00/00/0000',
  },
  {
    numeroReto: 3,
    nombreReto: 'Nombre del reto Nombre del reto Nombre del reto ',
    fechaRetos: '00/00/0000',
  },
  {
    numeroReto: 4,
    nombreReto: 'Nombre del reto Nombre del reto Nombre del reto ',
    fechaRetos: '00/00/0000',
  },
]

// SIMULACION DE API INDICADORES PDI
export const indicatingPdi = [
  {
    numeroIndicador: 1,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 2,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 3,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
  {
    numeroIndicador: 4,
    nombreIndicador: 'Nombre del indicador Nombre del indicador Nombre del indicador ',
    nombreMeta: 'Nombre de la meta',
  },
]

// SIMULACION DE API POLITICAS PDI
export const filterPoliticsPdi = [
  {
    numeroPolitica: 1,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 2,
    nombrePolitica: 'Nombre de la política nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 3,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 4,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 5,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 6,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 7,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 8,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 9,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 10,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 10,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 10,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
  {
    numeroPolitica: 10,
    nombrePolitica: 'Nombre de la política nombre de la política ',
  },
]

// SIMULACION DE API PROCESOS PLAN/RELATION
export const listProcesos = [
  {
    nameProcess: 'Direccionamiento Estratégico y Calidad',
  },
  {
    nameProcess: 'Internacionalización',
  },
  {
    nameProcess: 'Bienestar Institucional',
  },
  {
    nameProcess: 'Educación y Formación ',
  },
  {
    nameProcess: 'Investigación',
  },
  {
    nameProcess: 'Extensión y Proyección social',
  },
  {
    nameProcess: 'Gestión de las TIC',
  },
  {
    nameProcess: 'Gestión Administrativa y Financiera',
  },
  {
    nameProcess: 'Gestión de Mercadeo y Comunicaciones',
  },
  {
    nameProcess: 'Gestión del Talento Humano',
  },
  {
    nameProcess: 'Gestión de Admisiones y Registro',
  },
  {
    nameProcess: 'Inclusión y retención ',
  },
]

// SIMULACION DE API PROCESOS PLAN/RELATION
export const listSubProcesos = [
  {
    nameProcess: 'Control Interno',
  },
  {
    nameProcess: 'Secretaría General',
  },
  {
    nameProcess: 'Aseguramiento de la calidad',
  },
  {
    nameProcess: 'Centro de conciliación',
  },
  {
    nameProcess: 'Centro de Idiomas',
  },
  {
    nameProcess: 'Consultorio Jurídico',
  },
  {
    nameProcess: 'Administración de empresas',
  },
  {
    nameProcess: 'Contaduría Publica',
  },
  {
    nameProcess: 'Ingeniería de Sistemas',
  },
  {
    nameProcess: 'Tecnología en Higiene y Seguridad y salud en el trabajo',
  },
  {
    nameProcess: 'Derecho',
  },
  {
    nameProcess: 'Ingeniería Industrial',
  },

  {
    nameProcess: 'Licenciatura en Pedagogía en la primera infancia',
  },
  {
    nameProcess: 'Negocios Internacionales',
  },
  {
    nameProcess: 'Escuela de Posgrado',
  },
  {
    nameProcess: 'Medios Educativos/Biblioteca',
  },
  {
    nameProcess: 'Deporte y Cultura',
  },
  {
    nameProcess: 'Desarrollo Humano',
  },
  {
    nameProcess: 'Desarrollo Social',
  },
  {
    nameProcess: 'Salud Integral',
  },
  {
    nameProcess: 'Educación Continuada',
  },
  {
    nameProcess: 'Egresados',
  },
  {
    nameProcess: 'Innovación y Emprendimiento',
  },
  {
    nameProcess: 'Practicas Empresariales',
  },

  {
    nameProcess: 'Proyección Social',
  },
  {
    nameProcess: 'Investigación',
  },
  {
    nameProcess: 'Admisión de estudiantes',
  },
  {
    nameProcess: 'Atención al cliente',
  },
  {
    nameProcess: 'Graduación',
  },
  {
    nameProcess: 'Carnetización',
  },
  {
    nameProcess: 'Matrícula',
  },
  {
    nameProcess: 'Registro y Control Académico',
  },
  {
    nameProcess: 'Financiamiento Estudiantil',
  },
  {
    nameProcess: 'Contabilidad',
  },
  {
    nameProcess: 'Tesorería',
  },
  {
    nameProcess: 'Compras y Almacén',
  },

  {
    nameProcess: 'Infraestructura Física',
  },
  {
    nameProcess: 'Seguridad y Salud en el trabajo',
  },
  {
    nameProcess: 'Archivo y Correspondencia Institucional',
  },
  {
    nameProcess: 'Investigación',
  },
]

// SIMULACION DE API INDICADOR PLAN/RELATION
export const listIndicador = [
  {
    nameProcess: 'Nombre del indicador1',
  },
  {
    nameProcess: 'Nombre del indicador2',
  },
  {
    nameProcess: 'Nombre del indicador3',
  },
  {
    nameProcess: 'Nombre del indicador4',
  },
  {
    nameProcess: 'Nombre del indicador5',
  },
  {
    nameProcess: 'Nombre del indicador6',
  },
  {
    nameProcess: 'Nombre del indicador7',
  },
  {
    nameProcess: 'Nombre del indicador8',
  },
  {
    nameProcess: 'Nombre del indicador9',
  },
  {
    nameProcess: 'Nombre del indicador10',
  },
  {
    nameProcess: 'Nombre del indicador11',
  },
  {
    nameProcess: 'Nombre del indicador12',
  },
]

// SIMULACION DE API PROCESOS PLAN/FACTORS/RELATION
export const listRelationFactors = [
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
  {
    nameProcess: 'Nombre del factor Nombre del factor',
  },
]

// SIMULACION DE API PROCESOS PLAN/CONDITION/RELATION
export const listRelationConditions = [
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
  {
    nameProcess: 'Nombre de la condición Nombre de la condición',
  },
]

// SIMULACION DE API PROCESOS PLAN/CONDITION/RELATION
export const assignmentTask = [
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },

  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
  {
    nameProcess: 'Nombre del usuario o área que mide.',
  },
]

export const infoAgenda = [
  {
    numero: '19',
    dia: 'Lunes',
    fecha: '19/09/2022 - 23/09/2022',
    detalle: '1ABC PRUEBAS - 1ABC PRUEBAS - 1ABC PRUEBAS',
  },
  {
    numero: '22',
    dia: 'Jueves',
    fecha: '22/09/2022 - 27/09/2022',
    detalle: '2ABC PRUEBAS - 2ABC PRUEBAS - 2ABC PRUEBAS',
  },
  {
    numero: '23',
    dia: 'Viernes',
    fecha: '23/09/2022 - 26/09/2022',
    detalle: '3ABC PRUEBAS - 3ABC PRUEBAS - 3ABC PRUEBAS',
  },
  {
    numero: '26',
    dia: 'Lunes',
    fecha: '26/09/2022 - 29/09/2022',
    detalle: '4ABC PRUEBAS - 4ABC PRUEBAS - 4ABC PRUEBAS',
  },

  // HASTA AQUI
  {
    numero: '19',
    dia: 'Lunes',
    fecha: '19/09/2022 - 23/09/2022',
    detalle: '1ABC PRUEBAS - 1ABC PRUEBAS - 1ABC PRUEBAS',
  },
  {
    numero: '22',
    dia: 'Jueves',
    fecha: '22/09/2022 - 27/09/2022',
    detalle: '2ABC PRUEBAS - 2ABC PRUEBAS - 2ABC PRUEBAS',
  },
]

export const nameBranchOffice = [
  {
    valor: 1,
    name: 'Nombre del indicador',
  },
  {
    valor: 2,
    name: 'Nombre del indicador',
  },
]

export const registerMeasurement = [
  {
    valor: 1,
    fecha: '27/03/2023',
    numerador: 'xx',
    denominador: 'xx',
    resultado: 'xx',
    meta: 'xx',
    faltaCumplir: 'xx',
  },
  {
    valor: 1,
    fecha: '20/03/2023',
    numerador: 'xx',
    denominador: 'xx',
    resultado: 'xx',
    meta: 'xx',
    faltaCumplir: 'xx',
  },
  {
    valor: 1,
    fecha: '13/03/2023',
    numerador: 'xx',
    denominador: 'xx',
    resultado: 'xx',
    meta: 'xx',
    faltaCumplir: 'xx',
  },
  {
    valor: 1,
    fecha: '06/03/2023',
    numerador: 'xx',
    denominador: 'xx',
    resultado: 'xx',
    meta: 'xx',
    faltaCumplir: 'xx',
  },
]

// FILTRO DE INDICADORES
// export const filterIndicators = [
//   {
//     type: 'Array',
//     data: [
//       {
//         value: '1',
//         name: 'Áreas',
//       },
//       {
//         value: '2',
//         name: 'Políticas',
//       },
//       {
//         value: '3',
//         name: 'Objetivos',
//       },
//       {
//         value: '4',
//         name: 'Programas',
//       },
//       {
//         value: '5',
//         name: 'Proyectos',
//       },
//       {
//         value: '6',
//         name: 'Metas',
//       },
//       {
//         value: '7',
//         name: 'Retos',
//       },
//       {
//         value: '8',
//         name: 'Condiciones',
//       },
//       {
//         value: '9',
//         name: 'Factores',
//       },
//       {
//         value: '10',
//         name: 'Procesos',
//       },
//     ],
//   },
//   {
//     type: 'Menu',
//     data: [
//       {
//         value: '1',
//         name: 'Áreas',
//         children: [
//           {
//             value: '1',
//             name: 'Áreas2',
//           },
//         ],
//       },
//     ],
//   },
// ]

// FILTRO DE INDICADORES
export const filterIndicators = [
  {
    value: '1',
    name: 'Áreas',
  },
  {
    value: '2',
    name: 'Políticas',
  },
  {
    value: '3',
    name: 'Objetivos',
  },
  {
    value: '4',
    name: 'Programas',
  },
  {
    value: '5',
    name: 'Proyectos',
  },
  {
    value: '6',
    name: 'Metas',
  },
  {
    value: '7',
    name: 'Retos',
  },
  {
    value: '8',
    name: 'Condiciones',
  },
  {
    value: '9',
    name: 'Factores',
  },
  {
    value: '10',
    name: 'Procesos',
  },
]

// FILTRO DE INDICADORES
export const filterTrafficLights = [
  {
    value: '1',
    name: 'Plan de Desarrollo',
  },
  {
    value: '2',
    name: 'Plan de Acción',
    children: [
      {
        value: 1,
        name: 'Sede Barranquilla',
      },
      {
        value: 2,
        name: 'Sede Medellín',
      },
      {
        value: 3,
        name: 'Sede Montería',
      },
    ],
  },
]

// API LISTA VERIFICAR/MEDICION
export const lista = [
  {
    _id: 1,
    name: 'Nombre del indicador Nombre del indicador',
    title: 'title1',
    meta: 'meta1',
    medidorDeFrecuencia: 'Hasta el 04 de abril de 2023',
  },
  {
    _id: 2,
    name: 'Nombre del indicador Nombre del indicador',
    title: 'title2',
    meta: 'meta2',
    medidorDeFrecuencia: 'Hasta el 04 de abril de 2023',
  },
  {
    _id: 3,
    name: 'Nombre del indicador Nombre del indicador',
    title: 'title3',
    meta: 'meta3',
    medidorDeFrecuencia: 'Hasta el 04 de abril de 2023',
  },
  {
    _id: 4,
    name: 'Nombre del indicador Nombre del indicador',
    title: 'title4',
    meta: 'meta4',
    medidorDeFrecuencia: 'Hasta el 04 de abril de 2023',
  },
  {
    _id: 5,
    name: 'Nombre del indicador Nombre del indicador',
    title: 'title5',
    meta: 'meta5',
    medidorDeFrecuencia: 'Hasta el 04 de abril de 2023',
  },
  // {
  //   _id: 6,
  //   name: 'Nombre del indicador Nombre del indicador',
  //   title: 'title6',
  //   meta: 'meta6',
  //   medidorDeFrecuencia: 'Hasta el 04 de abril de 2023',
  // },
]
