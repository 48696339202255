import { FC, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

// IMPORTADOS
import IconGraphic from '../icons/IconGraphic'
import CustomGraphicLine from './CustomGraphicLine'
import CustomGraphicBar from './CustomGraphicBar'
import customGraphicStyles from './CustomGraphics.styles'
import { dateToString } from '../../services/ParseDate'

interface CustomDualGraphicProps {
  title?: string
  name?: string
  valueTs: number
  valueTi: number
  meta: number
  data?: any
  labelsApi: any[]
  valuesApi: any
}

const CustomDualGraphic: FC<CustomDualGraphicProps> = ({
  title = 'Titulo de la Grafica',
  name,
  valueTs,
  valueTi,
  meta,
  data,
  labelsApi,
  valuesApi,
}) => {
  const [changeGraphic, setChangeGraphic] = useState(true)
  const btnGraphic = () => {
    setChangeGraphic(!changeGraphic)
  }

  return (
    <Box sx={customGraphicStyles.containerGraphic}>
      {/*************************************/}
      {/* TITULO DE GRAFICAS LINEAR Y BARRA */}
      {/*************************************/}
      <Box className="titulo" sx={{ marginBottom: '20px' }}>
        {/*********************/}
        {/* BOTON DE GRAFICAS */}
        {/*********************/}
        <Button disableTouchRipple onClick={btnGraphic}>
          <IconGraphic />
        </Button>

        {/**********/}
        {/* TITULO */}
        {/**********/}
        <Typography>{title}</Typography>
        <Typography sx={{ fontSize: '20px !important' }}>{name}</Typography>
      </Box>

      {/*****************************************/}
      {/* CAMBIAR ENTRE GRAFICAS LINEAR Y BARRA */}
      {/*****************************************/}
      {changeGraphic ? (
        <CustomGraphicLine
          valueTs={(valueTs * 100) / meta}
          valueTi={(valueTi * 100) / meta}
          labelsApi={labelsApi}
          valuesApi={valuesApi.percentajes}
        />
      ) : (
        <CustomGraphicBar
          valueTs={valueTs}
          valueTi={valueTi}
          meta={meta}
          labelsApi={labelsApi}
          valuesApi={valuesApi.quantities}
        />
      )}
    </Box>
  )
}

export default CustomDualGraphic
