import { Button, Modal, Link, Box } from '@mui/material'
import { FunctionComponent, JSXElementConstructor, ReactElement, useEffect, useState } from 'react'

// IMPORTADOS
import IconClose from './icons/IconClose'
import { useLocation } from 'react-router-dom'

interface RecoverPasswordProps {
  maxWidth?: string | number
  maxHeight?: string | number
  margin?: string | number
  text?: string | any
  fontSize?: string | any
  children: ReactElement<any, string | JSXElementConstructor<any>>
  stateOpen?: boolean
}

const CustomModal: FunctionComponent<RecoverPasswordProps> = ({
  maxWidth = 1066.67,
  maxHeight = 600,
  margin = '11% auto',
  text = 'Cambia nombre',
  fontSize = '12px',
  children,
  stateOpen,
}) => {
  const { pathname } = useLocation()

  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    handleClose()
  }, [stateOpen])

  return (
    <>
      {/************************/}
      {/* LINK ACCESO AL MODAL */}
      {/************************/}
      <Box
        onClick={handleOpen}
        sx={{
          fontSize: { fontSize },
          textDecoration: pathname === '/login' ? 'underline' : 'none',
          cursor: 'pointer',
        }}
      >
        {text}
      </Box>

      {/*********************************/}
      {/* FONDO MODAL OLVIDÓ CONTRASEÑA */}
      {/*********************************/}
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          overflow: 'auto',
          display: 'flex',
        }}
      >
        <>
          {/***************/}
          {/* FONDO OPACO */}
          {/***************/}
          <Box
            sx={{
              backgroundColor: '#ffffff',
              width: '100%',
              height: '100%',
              opacity: 0.7,
            }}
          ></Box>

          {/**************/}
          {/* CARD MODAL */}
          {/**************/}
          <Box
            sx={{
              backgroundColor: '#fff',
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              minWidth: '10px',
              // minHeight : '600px',
              maxWidth: maxWidth,
              maxHeight: maxHeight,
              borderRadius: '50px',
              boxShadow: '0px 0px 20px 0px rgba(114, 130, 184, 0.25)',

              // SCROLL
              overflow: 'hidden',
              '&::-webkit-scrollbar': {
                width: '12px',
                size: '10px',
                '&-track': {
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  border: '5px solid transparent',
                },
                '&-thumb': {
                  backgroundColor: 'transparent',
                  borderRadius: '20px',
                  width: '10px',
                },
              },

              // RESPONSIVE
              '@media (max-width: 1120px)': {
                margin: 'auto 30px auto 30px',
                padding: '0px 20px',

                // CHILDREN
                '>div': {
                  marginLeft: '50px',
                  marginRight: '50px',
                },
              },
              '@media (max-height: 887px)': {
                margin: '30px',
              },

              // "@media (max-width: 590px)": {
              //     margin: "auto",
              // },

              // CHILDREN
              '>div': {
                height: '-webkit-fill-available',
                marginTop: '65px !important',
                overflowY: 'auto',
                "&::-webkit-scrollbar": {
                  width: "12px",
                  size: "10px",
                  "&-track": {
                      backgroundColor: "transparent",
                      borderRadius: "10px",
                      border: "5px solid transparent",
                  },
                  "&-thumb": {
                      backgroundColor: "transparent",
                      borderRadius: "20px",
                      width: "10px",
                  },
              },
              },
            }}
          >
            {/***********/}
            {/* BOTÓN X */}
            {/***********/}
            <Button
              onClick={handleClose}
              sx={{
                width: '0px',
                height: '17px',
                display: 'float',
                float: 'right',
                right: '30px',
                top: '40px',
                ':hover': {
                  backgroundColor: 'white',
                },
                '@media (max-width: 500px)': {
                  right: '0px',
                },
              }}
            >
              <IconClose />
            </Button>

            {/*******************************/}
            {/* CONTENIDO MODAL RENDERIZADO */}
            {/*******************************/}
            {children}
          </Box>
        </>
      </Modal>
    </>
  )
}

export default CustomModal
