import { Button, Typography, Box, Fade } from '@mui/material'
import { FunctionComponent } from 'react'
import IconAdd from '../../../icons/IconAdd'
import FilterPdi from '../../../inputSelect/FilterPdi'
import customAlerts from '../../../../helpers/customAlerts'
import useProyect from '../../../../hooks/pdi/useProject'
import useViewControlTabPanel from '../../../../hooks/pdi/useViewControlTabPanelPdi'
import { Challenge } from '../../../../interfaces/pdi/challenge'
import { Goal } from '../../../../interfaces/pdi/goal'
import { Program } from '../../../../interfaces/pdi/program'
import {
  ChangeActiveProject,
  FormProjectValues,
  NewProject,
  Project,
  UpdateProject,
} from '../../../../interfaces/pdi/project'
import FormProject from '../../../forms/pdi/formProject/FormProject'
import TableProjects from '../../../tables/pdi/tableProjects/TableProjects'
import formStyles from '../../../forms/form.styles'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface TabPanelProjectsProps {
  pdi: PdiModel
  projects: Project[]
  programs: Program[]
  goals: Goal[]
  challenges: Challenge[]
  onCreate: (_id: string) => void
  onChangeTitle: (tabTitle: string | null) => void
  onUpdate: (proyects: Project[]) => void
}

const { errorAlert } = customAlerts

const TabPanelProjects: FunctionComponent<TabPanelProjectsProps> = ({
  pdi,
  projects,
  programs,
  goals,
  challenges,
  onCreate,
  onChangeTitle,
  onUpdate,
}) => {
  const { fetchCreateProject, fetchUpdateProject, fetchDisableProject, ViewAlert } = useProyect()

  const { viewMode, showViewData, showViewCreate, showViewUpdate, updateItem, filterOption, changeFilterOption } =
    useViewControlTabPanel<Project, Program>({
      items: projects,
      onChangeTitle,
      titleOptions: {
        none: null,
        create: 'Crear proyecto',
        update: 'Actualizar proyecto',
        data: 'Proyectos',
      },
    })

  const projectFiltered = (): Project[] => {
    if (filterOption === null) return projects
    const { _id } = filterOption
    return projects.filter(project => project.idProgram === _id)
  }

  const getFormDefaultValues = (): Partial<FormProjectValues> | undefined => {
    if (!updateItem) return undefined
    const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } = updateItem
    return updateValues
  }

  const create = ({ state, ...other }: FormProjectValues, reset: () => void) => {
    const newProyect: NewProject = other
    fetchCreateProject(newProyect, _id => {
      reset()
      onCreate(_id)
    })
  }

  const update = (formValues: FormProjectValues, reset: () => void) => {
    if (!updateItem) {
      errorAlert({ text: 'Operación no disponible' })
      return
    }
    const { _id } = updateItem
    const updateProject: UpdateProject = { _id, ...formValues }
    fetchUpdateProject(updateProject, _updateValues => {
      reset()
      const { _id } = _updateValues
      const _updateProjects = projects.map(project => {
        if (project._id === _id) {
          return { ...project, ..._updateValues }
        }
        return project
      })
      onUpdate(_updateProjects)
      showViewData()
    })
  }

  const disable = ({ _id }: Project) => {
    const disableProject: ChangeActiveProject = { _id, is_active: false }
    fetchDisableProject(disableProject, () => {
      const _projects = projects.filter(pr => pr._id !== _id)
      onUpdate(_projects)
    })
  }

  const onSubmitForm = (formValues: FormProjectValues, reset: () => void) => {
    if (updateItem) return update(formValues, reset)
    create(formValues, reset)
  }

  return (
    <>
      <ViewAlert />
      {/****************
              CONTENEDOR FORMULARIO
            *****************/}
      <Fade in={viewMode === 'update' || viewMode === 'create'}>
        <Box
          sx={{
            display: viewMode === 'data' || !viewMode ? 'none' : 'block',
          }}
        >
          <FormProject
            pdi={pdi}
            defaultValues={getFormDefaultValues()}
            onCancel={showViewData}
            onSubmit={onSubmitForm}
            programs={programs}
            viewMode={viewMode === 'data' || !viewMode ? undefined : viewMode}
          />
        </Box>
      </Fade>
      {/****************
                CONTENEDOR TABLA PROYECTOS
            *****************/}
      <Fade in={viewMode === 'data'}>
        <Box sx={{ display: viewMode === 'data' ? 'block' : 'none' }}>
          <Box sx={formStyles.container}>
            {/****************
                            TITULO
                        *****************/}
            <Box sx={formStyles.buttonTitle}>
              {/***************
                                TITULO PDI
                            ****************/}
              <Typography sx={{ fontSize: '25px' }}>
                {filterOption ? `Proyectos de ${filterOption.title}` : 'Todos los proyectos'}
              </Typography>

              <Box>
                {/***************************
                                    BOTON FILTRAR POR PROGRAMAS
                                ****************************/}
                <FilterPdi
                  getOptionLabel={option => option.title}
                  id="filter-project-by-programs"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  labelInput="Programas"
                  noOptionsText="Sin resultados"
                  options={programs}
                  onChange={(e, value) => changeFilterOption(value)}
                  placeholder="Buscar programa..."
                  value={filterOption}
                />

                {/*************
                                    BOTON MÁS
                                **************/}
                <Button disableTouchRipple onClick={showViewCreate}>
                  <IconAdd />

                  <Typography>Crear nuevo proyecto</Typography>
                </Button>
              </Box>
            </Box>

            <TableProjects
              filterOption={filterOption}
              onDelete={disable}
              onUpdate={showViewUpdate}
              projects={projectFiltered()}
              programs={programs}
              goals={goals}
              challenges={challenges}
            />
          </Box>
        </Box>
      </Fade>
    </>
  )
}

export default TabPanelProjects
