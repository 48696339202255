import { StyleComponent } from '../../../../interfaces/styleComponent'

type Keys = 'container' | 'contentTitle' | 'title' | 'content' | 'data'

const infoProcessStyles: StyleComponent<Keys> = {
  // CONTENEDOR
  container: {
    marginX: '65px',
    // marginTop: "40px",
    // marginTop: '90px',
    // marginX: '100px',

    // RESPONSIVE
    '@media(max-width: 830px)': {
      marginX: '50px',
    },

    '@media(max-width: 430px)': {
      marginX: '25px',
    },
  },

  // CONTENIDO DE TITULO
  contentTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    // BOTON CREAR
    '>.buttonCreate': {
      backgroundColor: '#ffffff',
      minWidth: '180px',
      height: '38px',
      marginY: 'auto',
      marginRight: '20px',
      padding: '0px 15px',
      textTransform: 'inherit',
      borderRadius: '92px',
      boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

      // RESPONSIVE
      '@media (max-width: 1260px)': {
        minWidth: 'inherit !important',
        height: 'auto',
        padding: '10px',
        '>p': {
          display: 'none',
        },

        '>svg': {
          width: 16,
          height: 16,
        },
      },

      // HOVER
      ':hover': {
        backgroundColor: '#7282B8',
        '>svg': {
          '>path': {
            fill: '#ffffff',
          },
        },
        '>p': {
          color: '#ffffff',
        },
      },

      // PARRAFO
      '>p': {
        fontSize: '16px',
        marginLeft: '4px',
      },
    },
  },

  // TITULO
  title: {
    display: 'flex',
    alignItems: 'center',

    '>p': {
      color: '#1F398F',
      fontSize: '25px',
      fontWeight: '600',
      lineHeight: '38px',
      fontStyle: 'normal',
      marginRight: '11px',
    },
  },

  // CONTENIDO IZQUIERDA / DERECHA
  content: {
    backgroundRepeat: 'no-repeat',
    marginTop: '30px',
    height: '630px',

    // SCROLL
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '12px',
      size: '10px',
      '&-track': {
        backgroundColor: 'transparent',
        borderRadius: '10px',
        border: '5px solid transparent',
      },
      '&-thumb': {
        backgroundColor: 'transparent',
        borderRadius: '20px',
        width: '10px',
      },
    },
  },

  data: {
    marginBottom: '30px',
    '>p': {
      color: '#1F398F',
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '22px',
      marginBottom: '10px',
    },
    '>span': {
      color: '#000000 !important',
      fontWeight: '400 !important',
      fontSize: '15px',
      '>svg': {
        marginBottom: '-5px',
        marginRight: '8px',
      },
    },

    // CONTENEDOR LISTA
    '>div': {
      display: 'flex',
      marginBottom: '95px',

      '@media (max-width:820px)': {
        flexDirection: 'column',
        alignItems: 'center',

        '>.subProcess': {
          marginTop: '20px',
        },

        '>div': {
          width: '90% !important',
          marginRight: '5px !important',
        },
      },

      // INDICADOR Y SUBPROCESO
      '>div': {
        width: '350px',
        height: '250px',
        marginRight: '30px',
        marginLeft: '5px',
        borderRadius: '25px',
        boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

        '>p': {
          backgroundColor: '#7282B8',
          color: '#ffffff',
          fontSize: '15px',
          height: '56px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '25px 25px 0px 0px',
        },

        // LISTA
        '>div': {
          height: '140px',
          margin: '20px 23px 0px 55px',

          '@media (max-width: 950px)': {
            margin: '20px 23px 0px 23px',
          },

          // SCROLL
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '12px',
            size: '10px',
            '&-track': {
              backgroundColor: theme => theme.palette.secondary.main,
              borderRadius: '10px',
              border: '5px solid #ffffff',
            },
            '&-thumb': {
              backgroundColor: '#7282B8',
              borderRadius: '20px',
              width: '10px',
            },
          },

          '>p': {
            marginBottom: '4px',
          },
        },
      },
    },
  },
}

export default infoProcessStyles
