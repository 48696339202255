import { useTheme } from '@mui/styles'
import { FunctionComponent } from 'react'

interface IconMeasurementProps {
  width?: number | string
  height?: number | string
  colorBarras?: string
  colorFlecha?: string
}

const IconMeasurement: FunctionComponent<IconMeasurementProps> = ({
  width = 20,
  height = 20,
  colorBarras,
  colorFlecha,
}) => {
  const theme: any = useTheme()
  const primary: string = theme.palette.primary.main
  const secondary: string = theme.palette.secondary.main
  return (
    <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6429 6.00008C20.3924 6.00008 21 6.62633 21 7.39885V20.6012C21 21.3738 20.3924 22 19.6429 22C18.8933 22 18.2857 21.3738 18.2857 20.6012V7.39885C18.2857 6.62633 18.8933 6.00008 19.6429 6.00008ZM14.2143 8.14726C14.9638 8.14726 15.5714 8.77351 15.5714 9.54603V20.6012C15.5714 21.3738 14.9638 22 14.2143 22C13.4648 22 12.8571 21.3738 12.8571 20.6012V9.54603C12.8571 8.77351 13.4648 8.14726 14.2143 8.14726ZM8.78571 8.57671C9.53524 8.57671 10.1429 9.20296 10.1429 9.97548V20.6012C10.1429 21.3738 9.53524 22 8.78571 22C8.03618 22 7.42857 21.3738 7.42857 20.6012V9.97548C7.42857 9.20296 8.03618 8.57671 8.78571 8.57671ZM3.35714 11.5828C4.10667 11.5828 4.71429 12.2091 4.71429 12.9816V20.6012C4.71429 21.3738 4.10667 22 3.35714 22C2.60761 22 2 21.3738 2 20.6012L2 12.9816C2 12.2091 2.60761 11.5828 3.35714 11.5828Z"
        fill={colorBarras ?? primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9956 1.45991C19.3823 2.03182 19.2321 2.8089 18.6602 3.19557L12.5585 7.32095C12.0411 7.6708 11.3441 7.58544 10.9271 7.12041L9.49855 5.52735L2.72791 10.3763C2.16665 10.7783 1.3858 10.6491 0.983837 10.0879C0.581875 9.52659 0.711015 8.74574 1.27228 8.34378L8.95356 2.84265C9.47184 2.47148 10.1864 2.54978 10.612 3.02438L12.0606 4.63982L17.26 1.12451C17.8319 0.737838 18.609 0.888002 18.9956 1.45991Z"
        fill={colorFlecha ?? secondary}
      />
      <path
        d="M18.7964 4.2506L16.2631 0.79566C16.0209 0.465393 16.2568 0 16.6663 0H20.3132C20.6524 0 20.8931 0.33055 20.7891 0.653383L19.6755 4.10833C19.5504 4.49672 19.0377 4.57969 18.7964 4.2506Z"
        fill={colorFlecha ?? secondary}
      />
    </svg>
  )
}

export default IconMeasurement
