import { FunctionComponent } from 'react'

interface IconAddProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconBell2: FunctionComponent<IconAddProps> = ({ width = 25, height = 25, color = '#1F398F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0476 17.8571V11.6667C18.4524 11.7857 17.8571 11.9048 17.2619 11.9048H16.6667V19.0476H4.7619V10.7143C4.7619 7.38095 7.38095 4.7619 10.7143 4.7619C10.8333 3.21429 11.5476 1.90476 12.5 0.833333C12.1429 0.357143 11.4286 0 10.7143 0C9.40476 0 8.33333 1.07143 8.33333 2.38095V2.7381C4.7619 3.80952 2.38095 7.02381 2.38095 10.7143V17.8571L0 20.2381V21.4286H21.4286V20.2381L19.0476 17.8571ZM8.33333 22.619C8.33333 23.9286 9.40476 25 10.7143 25C12.0238 25 13.0952 23.9286 13.0952 22.619H8.33333Z"
        fill="#1F398F"
      />
      <path
        d="M21.4285 5.3571C21.4285 7.619 19.5238 9.52376 17.2619 9.52376C15 9.52376 13.0952 7.619 13.0952 5.3571C13.0952 3.09519 15 1.19043 17.2619 1.19043C19.5238 1.19043 21.4285 3.09519 21.4285 5.3571Z"
        fill={color}
      />
    </svg>
  )
}

export default IconBell2
