import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'title'
  | 'content'
  | 'containerButtonTheme'
  | 'containerBlock'
  | 'subTitle'
  | 'input'
  | 'inputBase'
  | 'imagen'
  | 'formImage'
  | 'divButton'
  | 'buttonSave'
  | 'buttonCancel'
  | 'containerImage'
  | 'dropAreaActive'

const formBrachOfficeStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    maxWidth: '1350px',
    margin: 'auto',
    backgroundColor: '#ffffff',
  },

  // TITULO Y SUBTITULO
  title: {
    margin: '30px auto',
    width: '1110px',
    '>p': {
      fontSize: '25px',
      color: '#1F398F',
      fontWeight: '600',
      lineHeight: '38px',
      marginBottom: '9px',
    },
  },

  content: { marginTop: '32px' },

  containerButtonTheme: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    right: 0,
    marginTop: '70px',
    width: '231px',
    height: '267px',
    borderRadius: '15px',
    zIndex: 1,
    '>p': {
      backgroundColor: '#7282B8',
      color: '#ffffff',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
    },
    '>div': {
      padding: '30px 41px 46px',
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
      '>button, >a': {
        backgroundColor: 'inherit',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        textTransform: 'capitalize',
        padding: '0px 0px ',
        marginBottom: '11.2px',
        borderWidth: '0px',
        cursor: 'pointer',
        ':hover': {
          '>p': {
            color: '#1F398F',
            fontWeight: '600',
          },
        },
        '>span': {
          width: '20px',
          height: '20px',
          borderRadius: '20px',
        },
        '>.MuiTouchRipple-root': {
          backgroundColor: 'transparent !important',
          ':active': {
            backgroundColor: 'transparent !important',
          },
          ':hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
  },

  // CONTENEDOR BLOCK
  containerBlock: {
    width: '1110px',
    margin: 'auto',
  },

  // SUBTITULOS
  subTitle: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },

    '>.span': {
      color: '#000000 !important',
      fontFamily: 'Poppins',
      fontSize: '20px',
      marginBottom: '9px',
    },
  },
  input: {
    height: '39px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },
  inputBase: {
    width: '100%',
    height: '100%',
    fontSize: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
    borderBottom: '0px solid transparent',
    '>div': {
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  // IMAGEN
  containerImage: {
    width: 'inherit',
  },
  imagen: {
    margin: 'auto 0px auto auto',
    backgroundColor: '#F1F3F8',
    height: '312px',
    borderRadius: '25px',
    display: 'flex',
    '>button, >label': {
      backgroundColor: 'inherit',
      width: '100%',
      height: '100%',
      padding: '0px',
      margin: 'auto',
      textAlign: 'center',
      borderRadius: '25px',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'inherit',
        boxShadow: 'none',
      },

      '>div': {
        display: 'block',
        textTransform: 'inherit',
        '>p': {
          color: '#1F398F',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '30px',
        },
        '>span': {
          color: '#7282B8',
          fontFamily: 'Poppins',
          fontSize: '15px',
          lineHeight: '22px',
        },
      },
    },
  },

  formImage: {
    '>div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '>div': {
        display: 'flex',
        alignItems: 'center',
        '>p': {
          maxWidth: '1027px',
          fontSize: '20px',
          marginLeft: '15.93px',
        },
      },
      '>button': {
        padding: '0px',
        minWidth: 'inherit',
        borderRadius: '20px',
      },
    },
    '>button, >label': {
      backgroundColor: '#F1F3F8',
      marginTop: '13px',
      color: '#1F398F',
      fontSize: '15px',
      fontWeight: '400',
      textTransform: 'initial',
      borderRadius: '200px',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#F1F3F8',
        boxShadow: 'none',
      },
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    marginTop: '97px',
    marginX: 'auto',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '169.74px',
      width: '169.74px',
      height: '33px',
      borderRadius: '20px',

      // TEXTO
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },

  buttonSave: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
  dropAreaActive: {
    border: 'dashed 2px #fff',
    background: '#7282B8',
  },
}

export default formBrachOfficeStyles
