/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import useFetch from '../../useFetch'
import { IndicatorModel } from '../../../interfaces/pdi/indicator'
import { Factor } from '../../../interfaces/factor'
import { IndicatorFactor, NewRelIndicatorFactor } from '../../../interfaces/indicator-factor'
import { IndicatorCondition, NewRelIndicatorCondition } from '../../../interfaces/indicator-condition'
import useAppDialog from '../../useAppDialog'
import { Condition } from '../../../interfaces/condition'

type Actions =
  | {
      done?: () => void
      fail?: () => void
    }
  | undefined

type GetRelFactorAndConditionData = Actions

type GetIndicatorsFactorsProps = Actions

type GetIndicatorsConditionsProps = Actions

type CreateRelationIndicatorFactorProps = Actions & {
  relIndicatorFactor: NewRelIndicatorFactor
}

type CreateRelationIndicatorConditionProps = Actions & {
  relIndicatorCondition: NewRelIndicatorCondition
}

const useRelations = () => {
  // Relación de factores y condiciones
  const [indicators, setIndicators] = useState<IndicatorModel[] | null>(null)

  const [factors, setFactors] = useState<Factor[] | null>(null)

  const [conditions, setConditions] = useState<Condition[] | null>(null)

  const [indicatorsFactors, setIndicatorsFactors] = useState<IndicatorFactor[] | null>(null)

  const [indicatorsConditions, setIndicatorsConditions] = useState<IndicatorCondition[] | null>(null)

  // Relacion de factores y condiciones

  const { fetchCreate, fetchFindOne, fetchList } = useFetch()

  const { showLoadingDialog, showSuccessDialog, showErrorDialog, hideDialog } = useAppDialog()

  const fetchCreateIndiWeigthProcess = useCallback(
    (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      fetchCreate({ action: 'createIndiWeigthProcess', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Se han creado las relaciones exitosamente!',
          })
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'contactar con soporte.',
          })
        })
    },
    [fetchCreate]
  )

  const fetchCreateIndiWeigthSubProcess = useCallback(
    (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      fetchCreate({ action: 'createIndiWeigthSubProcess', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Se han creado las relaciones exitosamente!',
          })
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'contactar con soporte.',
          })
        })
    },
    [fetchCreate]
  )

  const fetchGetOneProcessIndicators = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneProcessIndicator',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'contactar con soporte.',
          })
        })
    },
    [fetchFindOne]
  )

  const fetchGetIndicatorProcess = useCallback(async () => {
    return fetchList<any>({
      action: 'getIndicatorsProcess',
    })
      .then(res => {
        return res
      })
      .catch(error => {
        showErrorDialog({
          text: error?.message || 'contactar con soporte.',
        })
      })
  }, [fetchList])

  const fetchGetOneSubProcessIndicators = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneSubProcessIndicator',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'contactar con soporte.',
          })
        })
    },
    [fetchFindOne]
  )

  const fetchGetIndicatorSubProcess = useCallback(async () => {
    return fetchList<any>({
      action: 'getIndicatorsSubProcess',
    })
      .then(res => {
        return res
      })
      .catch(error => {
        showErrorDialog({
          text: error?.message || 'contactar con soporte.',
        })
      })
  }, [fetchList])

  //Carga los inidicadores, factores y condiciones
  const getFactorAndConditionData = useCallback(
    ({ done, fail }: GetRelFactorAndConditionData = {}) => {
      showLoadingDialog()
      const promiseIndicators = fetchList<IndicatorModel>({ action: 'getIndicators' })
      const promiseFactors = fetchList<Factor>({ action: 'getFactors' })
      const promiseConditions = fetchList<Condition>({ action: 'getConditions' })
      Promise.all([promiseIndicators, promiseFactors, promiseConditions])
        .then(([indicatorsResponse, factorsResponse, conditionsResponse]) => {
          setIndicators(indicatorsResponse.items)
          setFactors(factorsResponse.items)
          setConditions(conditionsResponse.items)
          done && done()
          hideDialog()
        })
        .catch(error => {
          fail && fail()
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar las relaciones , contactar con soporte.',
          })
        })
    },
    [fetchList]
  )

  // Carga las relaciones de indicadores con factores
  const getIndicatorsFactors = useCallback(
    ({ done, fail }: GetIndicatorsFactorsProps = {}) => {
      showLoadingDialog()
      fetchList<IndicatorFactor>({ action: 'getIndicatorsFactors' })
        .then(({ items }) => {
          setIndicatorsFactors(items)
          done && done()
          hideDialog()
        })
        .catch(error => {
          setIndicatorsFactors(null)
          fail && fail()
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar las relaciones , contactar con soporte.',
          })
        })
    },
    [fetchList]
  )

  // Carga las relaciones de indicadores con condiciones
  const getIndicatorsConditions = useCallback(
    ({ done, fail }: GetIndicatorsConditionsProps = {}) => {
      showLoadingDialog()
      fetchList<IndicatorCondition>({ action: 'getIndicatorsConditions' })
        .then(({ items }) => {
          setIndicatorsConditions(items)
          done && done()
          hideDialog()
        })
        .catch(error => {
          setIndicatorsConditions(null)
          fail && fail()
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar las relaciones , contactar con soporte.',
          })
        })
    },
    [fetchList]
  )

  // Crea o modifica nuevas relaciones entre los factores e indicadores
  const createRelationIndicatorFactor = useCallback(
    ({ relIndicatorFactor: body, done, fail }: CreateRelationIndicatorFactorProps) => {
      showLoadingDialog()
      fetchCreate({ action: 'createRelationIndicatorFactor', body })
        .then(() => {
          fetchList<IndicatorFactor>({ action: 'getIndicatorsFactors' })
            .then(({ items }) => {
              setIndicatorsFactors(items)
              done && done()
              showSuccessDialog({
                text: 'Se han creado las relaciones exitosamente!',
              })
            })
            .catch(() => {
              setIndicatorsFactors(null)
              fail && fail()
              showErrorDialog({
                text: 'Se han creado las relaciones, pero ha ocurrido un problema al visualizar los cambios',
              })
            })
        })
        .catch(err => {
          setIndicatorsFactors(null)
          fail && fail()
          showErrorDialog({
            text: err?.message || 'Ha ocurrido un error, contactar con soporte.',
          })
        })
    },
    [fetchCreate]
  )

  // Crea o modifica nuevas relaciones entre las condiciones e indicadores
  const createRelationIndicatorConditon = useCallback(
    ({ relIndicatorCondition: body, done, fail }: CreateRelationIndicatorConditionProps) => {
      showLoadingDialog()
      fetchCreate({ action: 'createRelationIndicatorCondition', body })
        .then(() => {
          fetchList<IndicatorCondition>({ action: 'getIndicatorsConditions' })
            .then(({ items }) => {
              setIndicatorsConditions(items)
              done && done()
              showSuccessDialog({
                text: 'Se han creado las relaciones exitosamente!',
              })
            })
            .catch(() => {
              setIndicatorsConditions(null)
              fail && fail()
              showErrorDialog({
                text: 'Se han creado las relaciones, pero ha ocurrido un problema al visualizar los cambios',
              })
            })
        })
        .catch(err => {
          setIndicatorsConditions(null)
          fail && fail()
          showErrorDialog({
            text: err?.message || 'Ha ocurrido un error, contactar con soporte.',
          })
        })
    },
    [fetchCreate]
  )

  return {
    fetchCreateIndiWeigthProcess,
    fetchCreateIndiWeigthSubProcess,
    fetchGetOneProcessIndicators,
    fetchGetOneSubProcessIndicators,
    getFactorAndConditionData,
    getIndicatorsFactors,
    getIndicatorsConditions,
    createRelationIndicatorFactor,
    createRelationIndicatorConditon,
    indicators,
    factors,
    conditions,
    indicatorsFactors,
    indicatorsConditions,
    fetchGetIndicatorProcess,
    fetchGetIndicatorSubProcess,
  }
}

export default useRelations
