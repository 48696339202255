import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'containerLeft'
  | 'containerRight'
  | 'containerButton'
  | 'containerInputSearch'
  | 'containerMenu'
  | 'menu'
  | 'iconMenu'
  | 'drawer'

const headerStyles: StyleComponent<Keys> = {
  container: {
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    // boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)",
    boxShadow: '0px 10px 15px rgba(114, 130, 184, 0.1)',
    marginBottom: '10px',
  },

  // CONTENEDOR IZQUIERDO
  containerLeft: {
    marginLeft: '70px',
    marginTop: '31px',
    height: '39px',
    '@media(max-width: 590px)': {
      marginLeft: '30px',
    },
  },

  // CONTENEDOR DERECHO
  containerRight: {
    // backgroundColor: "pink",
    display: 'flex',
    justifyContent: 'end',
    marginRight: '20px',
    width: '100%',
    // "@media(max-width: 1230px)": {
    //     display: "none",
    // },
  },

  // CONTENEDOR DE BOTONES
  containerButton: {
    // backgroundColor: "yellow",
    // maxWidth: "538px",
    // width: "538px",
    display: 'flex',
    justifyContent: 'center',
    margin: '38px 40px 38px auto',

    // BOTON
    '>div>button, >div>a': {
      // backgroundColor: "pink",
      color: '#1F398F',
      // width: "225px",
      textDecoration: 'none',
      mr: '110px',
      height: '23px',
      maxWidth: '225px',
      textAlign: 'center',
      textTransform: 'capitalize',
      // alignItems: "center",
      // alignContent: "center",
      // borderTopLeftRadius: "20px",
      // borderTopRightRadius: "20px",
      // BOTON HOVER
      ':hover': {
        backgroundColor: 'inherit',
      },

      // RESPONSIVE
      // "@media (max-width: 1440px)": {
      //     width: "13vw",
      // },

      '>p:hover': {
        fontWeight: '600',
      },
    },

    // PARRAFO DE BOTON
    '>p': {
      display: 'inline',
      fontSize: '16px',
      fontWeight: '400',
    },
  },

  // CONTENEDOR BUSCADOR
  containerInputSearch: {
    // backgroundColor: "#7282B8",
    maxWidth: '439px',
    minWidth: '39px',
    width: '439px',

    // RESPONSIVE
    '@media(max-width: 1440px)': {
      width: '30vw',
    },

    // BUSCADOR
    '>div': {
      backgroundColor: 'rgba(114, 130, 184, 0.1)',
      // backgroundColor: "red",
      width: '100%',
      maxWidth: '439px',
      minHeight: '41px',
      color: '#1F398F',
      marginTop: '30px',
      borderRadius: '500px',
      boxSizing: 'border-box',
      '>div>input': {
        color: '#1F398F',
        fontWeight: '400',
        fontSize: '12px',
      },

      // BOTON/ICONO BUSCAR
      '>button, >a': {
        marginTop: '3px',
        marginLeft: '3px',
      },

      // CUADRO DE TEXTO/INPUTBASE
      '>div': {
        width: '85%',
        fontSize: '12px',
        color: '#1F398F',
        '@media(max-width: 590px)': {
          width: '70%',
        },
      },
    },
  },

  // CONTENEDOR MENU
  containerMenu: {
    // backgroundColor: "red",
    paddingLeft: '8px',
    paddingRight: '25px',

    '>button, >a': {
      p: '0px',
      m: '8px',
      marginTop: '30px',
      cursor: 'pointer',
    },
  },

  menu: {
    mt: '45px',
    '&>div': {
      borderRadius: '15px',
      backgroundColor: 'transparent',
      '&>ul': {
        backgroundColor: 'white',
        width: '124px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '15px',
        paddingTop: '0px',
        paddingBottom: '0px',
        '&>div>li>a': {
          stroke: '#000000',
          color: '#000000',
          fontWeight: '400',
          textDecoration: 'none',
          '&:hover': {
            stroke: '#1F398F',
            fontWeight: '600',
            color: '#1F398F',
            SwapOverlay: 'None',
            Animate: 'Dissolve',
            animationTimingFunction: 'ease-in',
            animationDuration: '300ms',
            '&>svg>path': {
              stroke: '#1F398F',
              strokeWidth: '2px',
              color: '#1F398F',
              SwapOverlay: 'None',
              Animate: 'Dissolve',
              animationTimingFunction: 'ease-in',
              animationDuration: '300ms',
            },
          },
        },
      },
    },
  },

  // MENU HAMBURGUESA
  iconMenu: {
    paddingLeft: '8px',
    paddingRight: '25px',

    '>button, >a': {
      backgroundColor: '#ffffff',
      m: '8px',
      marginTop: '30px',
      cursor: 'pointer',

      ':hover': {
        backgroundColor: 'rgba(60, 45, 146, 0.04)',
      },

      '>svg': {
        fontSize: 25,
      },
    },
  },

  // MENU DRAWER
  drawer: {
    // BUSCADOR DEL HEADER
    '>.inputSearch': {
      width: 'inherit',
      marginX: '20px',

      '>div': { '>div': { width: '170px' } },
    },

    // LISTA HEADER
    '>.listaHeader': {
      marginTop: '20px',

      '>ul': {
        '>li': {
          '>a, >div': {
            marginX: '10px',

            // HOVER
            ':hover': {
              borderRadius: '92px',
              '>div': {
                '>span, >svg, >svg>path': {
                  color: '#1F398F',
                  fontWeight: '600',
                },
                '>.IconLeave>path': {
                  stroke: '#1F398F',
                },
                '>.IconPerson>path': {
                  fill: '#7282B8',
                },
              },
            },

            // LISTA MODULOS
            '>.modulos': {
              marginLeft: '40px',
            },

            '>div': {
              minWidth: '40px',
              '>span, >svg': {
                color: '#1F398F',
              },
              '>.IconPerson>path': {
                fill: '#7282B8',
              },
              '>.IconLeave>path': {
                stroke: '#7282B8',
              },
            },
          },
        },
      },
    },
  },
}

export default headerStyles
