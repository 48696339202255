import { FunctionComponent } from "react";

interface IconPasswordSecureProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconPasswordSecure: FunctionComponent<IconPasswordSecureProps> = ({
    width = 24,
    height = 30,
    color = "#1F398F",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5 10.5V7.5C19.5 3.3 16.2 0 12 0C7.8 0 4.5 3.3 4.5 7.5V10.5C1.95 10.5 0 12.45 0 15V25.5C0 28.05 1.95 30 4.5 30H19.5C22.05 30 24 28.05 24 25.5V15C24 12.45 22.05 10.5 19.5 10.5ZM7.5 7.5C7.5 4.95 9.45 3 12 3C14.55 3 16.5 4.95 16.5 7.5V10.5H7.5V7.5ZM13.65 20.25L13.5 20.4V22.5C13.5 23.4 12.9 24 12 24C11.1 24 10.5 23.4 10.5 22.5V20.4C9.6 19.5 9.45 18.15 10.35 17.25C11.25 16.35 12.6 16.2 13.5 17.1C14.4 17.85 14.55 19.35 13.65 20.25Z"
                fill={color}
            />
        </svg>
    );
};

export default IconPasswordSecure;
