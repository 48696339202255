import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys = 'iconX' | 'title' | 'text' | 'contentButton' | 'divButton' | 'buttonYes' | 'buttonNo'

const confirmAlertStyles: StyleComponent<Keys> = {
  // ICON X
  iconX: {
    paddingTop: '120px',
    textAlign: 'center',
  },

  // TITLE
  title: {
    maxWidth: 600,
    margin: '25px auto 30px',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 400,
    textAlign: 'center',
    '@media (max-width: 555px)': {
      margin: '30px 30px auto',
    },
  },

  // TEXTO
  text: {
    maxWidth: 600,
    margin: '30px auto 30px',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    fontWeight: 300,
  },

  // CONTENEDOR BOTON ENVIAR
  contentButton: {
    width: '136px',
    height: '40px',
    left: '440px',
    top: '408px',
    margin: '39px auto 30px',
    '>button': {
      background: '#003487',
      paddingX: 5,
      paddingY: 1.1,
      borderRadius: 20,
      boxShadow: 'none',
      ':hover': {
        background: '#003487',
        boxShadow: 'none',
      },
      ':active': {
        boxShadow: 'none',
      },
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    margin: '35px auto 60px',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '71px',
      width: '71px',
      height: '44px',
      borderRadius: '9px',
      marginRight: '10.51px',

      // TEXTO
      fontSize: '20px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },

  buttonYes: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonNo: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
}

export default confirmAlertStyles
