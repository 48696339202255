import { FunctionComponent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography, Tab, Tabs } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

// ICONOS
import IconArrowRight from '../../../components/icons/IconArrowRight'
import IconPolitics from '../../../components/icons/IconPolitics'
import IconObjetives from '../../../components/icons/IconObjetives.styles'
import IconPrograms from '../../../components/icons/IconPrograms'
import IconProyects from '../../../components/icons/IconsProyects'
import IconGoals from '../../../components/icons/IconGoals'
import IconChallenge from '../../../components/icons/IconChallenge'
import IconIndicators from '../../icons/IconIndicatorsPdi'

// IMPORTADOS
import tabManagerPdiStyles from './tabManagerPdi.styles'
import TabPanelContainer from '../../../components/tabPanels/TabPanelContainer'
import usePdi from '../../../hooks/pdi/usePdi'
import { LinkedPdi, PdiModel } from '../../../interfaces/pdi/pdi'
import { Politics } from '../../../interfaces/pdi/politics'
import TabPanelPolicies from '../../tabPanels/tabPanelsPdi/tabPanelPolicies/TabPanelPolicies'
import { Objetive } from '../../../interfaces/pdi/objetive'
import TabPanelObjetives from '../../tabPanels/tabPanelsPdi/tabPanelObjetives/TabPanelObjetives'
import TabPanelPrograms from '../../tabPanels/tabPanelsPdi/tabPanelPrograms/TabPanelPrograms'
import { Program } from '../../../interfaces/pdi/program'
import TabPanelPdi from '../../tabPanels/tabPanelsPdi/tabPanelPdi/TabPanelPdi'
import TabPanelProjects from '../../tabPanels/tabPanelsPdi/tabPanelProjects/TabPanelProjects'
import { Project } from '../../../interfaces/pdi/project'
import { Goal } from '../../../interfaces/pdi/goal'
import TabPanelGoals from '../../tabPanels/tabPanelsPdi/tabPanelGoals/TabPanelGoals'
import { BranchOffice } from '../../../interfaces/branchOffice'
import TabPanelChallenge from '../../tabPanels/tabPanelsPdi/tabPanelChallenges/TabPanelChallenges'
import { Challenge } from '../../../interfaces/pdi/challenge'
import { MeasureUnit } from '../../../interfaces/pdi/measureUnit'
import useMeasureUnit from '../../../hooks/pdi/useMeasureUnit'
import TabPanelIndicators from '../../tabPanels/tabPanelsPdi/tabPanelIndicators/TabPanelIndicators'
import useTrend from '../../../hooks/pdi/useTrend'
import { Trend } from '../../../interfaces/pdi/trend'
import { MeasurementFrecuency } from '../../../interfaces/pdi/measurementFrecuency'
import useMeasurementFrecuency from '../../../hooks/pdi/useMeasurementFrecuency'
import { IndicatorModel } from '../../../interfaces/pdi/indicator'
import GenericAlert, { GenericAlertProps } from '../../Alerts/GenericAlert/GenericAlert'
import { Company } from '../../../interfaces/company'
import useTypes from '../../../hooks/pdi/useTypes'
import { Type } from '../../../interfaces/pdi/types'
import IconCalendarCheck from '../../icons/IconCalendarCheck'

interface TabManagerPdiProps {
  updateId?: string
  userBranchOffice: BranchOffice
  userCompany: Company
  errorFetch?: (error: any) => void
}

interface CurrentData {
  pdi: PdiModel | null
  policies: Politics[]
  objetives: Objetive[]
  programs: Program[]
  projects: Project[]
  goals: Goal[]
  challenges: Challenge[]
  indicators: IndicatorModel[]
}

const TAB_PANEL_ID = 'nav-pdi'
// TABS
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${TAB_PANEL_ID}-${index}`,
    'aria-controls': `vertical-tabpanel-${TAB_PANEL_ID}-${index}`,
  }
}

const initialCurrentData: CurrentData = {
  pdi: null,
  policies: [],
  objetives: [],
  programs: [],
  projects: [],
  goals: [],
  challenges: [],
  indicators: [],
}

const TabManagerPdi: FunctionComponent<TabManagerPdiProps> = ({
  userBranchOffice,
  userCompany,
  updateId,
  errorFetch,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const [currentData, setCurrentData] = useState<CurrentData>(initialCurrentData)

  const [measureUnitsForForms, setMeasureUnitsForForms] = useState<MeasureUnit[]>([])

  const [trendsForForms, setTrendsForForms] = useState<Trend[]>([])

  const [measurementFrecuenciesForForms, setMeasurementFrecuenciesForForms] = useState<MeasurementFrecuency[]>([])

  const [typesForForms, setTypesForForms] = useState<Type[]>([])

  const [tabIndex, setTabIndex] = useState(0)

  const [activeTabTitle, setActiveTabTitle] = useState<string | null>(null)

  const [alertData, setAlerData] = useState<GenericAlertProps>({
    show: false,
  })

  const { fetchLinkedPdi } = usePdi()

  const { fetchMeasureUnits } = useMeasureUnit()

  const { fetchTrends } = useTrend()

  const { fetchMeasurementFrecuencies } = useMeasurementFrecuency()

  const { fetchTypes } = useTypes()

  const handleActiveTabTitle = (tabTitle: string | null) => setActiveTabTitle(tabTitle)

  const handleTabs = (event: SyntheticEvent, nextIndex: number) => {
    if (nextIndex >= 0 && !currentData.pdi) return
    setTabIndex(nextIndex)
  }

  const setInitialTabIndex = (_currentData: CurrentData) => {
    const { pdi, ...childrenItems } = _currentData
    if (!_currentData.pdi) return setTabIndex(0)
    const keys = Object.keys(childrenItems) as [keyof Omit<CurrentData, 'pdi'>]
    const indexVoidItem = keys.findIndex(k => childrenItems[k].length === 0) + 1
    if (indexVoidItem === 0) return setTabIndex(7)
    setTabIndex(indexVoidItem)
  }

  const handleSuccessFecthCurrentData = (
    { link_pdi_policies, ...pdi }: LinkedPdi,
    measureUnits: MeasureUnit[],
    trends: Trend[],
    measurementFrecuencies: MeasurementFrecuency[],
    types: any[]
  ) => {
    const policies: Politics[] = []
    const objetives: Objetive[] = []
    const programs: Program[] = []
    const projects: Project[] = []
    const goals: Goal[] = []
    const challenges: Challenge[] = []
    const indicators: IndicatorModel[] = []
    // recorro las políticas del PDI
    link_pdi_policies.forEach(({ link_policies_objetive, ..._politics }) => {
      if (!_politics.is_active) return
      policies.push(_politics)
      // _pol.push(_politics)
      // recorro los objetivos de cada política
      link_policies_objetive.forEach(({ link_objetive_programs, ..._objetive }) => {
        if (!_objetive.is_active) return
        objetives.push(_objetive)
        // Recorro los programas de cada objetivo
        link_objetive_programs.forEach(({ link_programs_projects, ..._program }) => {
          if (!_program.is_active) return
          programs.push(_program)
          // Recorro los proyectos de cada programa
          link_programs_projects.forEach(({ link_projects_goals, link_projects_challenges, ..._project }) => {
            if (!_project.is_active) return
            projects.push(_project)
            link_projects_goals.forEach(({ link_goals_indicators, ..._goal }) => {
              if (!_goal.is_active) return
              goals.push(_goal)
              // Recorro todos los indicadores de cada meta
              link_goals_indicators.forEach(indicator => {
                if (!indicator.is_active) return
                indicators.push(indicator)
              })
            })
            link_projects_challenges.forEach(_challenge => {
              if (!_challenge.is_active) return
              challenges.push(_challenge)
            })
          })
        })
      })
    })
    const newCurrentData: CurrentData = {
      pdi,
      policies,
      objetives,
      programs,
      projects,
      goals,
      challenges,
      indicators,
    }
    setCurrentData(prevCurrentData => {
      if (!prevCurrentData.pdi) {
        setInitialTabIndex(newCurrentData)
      }
      return newCurrentData
    })
    setMeasureUnitsForForms(() => measureUnits)
    setTrendsForForms(() => trends)
    setMeasurementFrecuenciesForForms(() => measurementFrecuencies)
    setTypesForForms(() => types)
  }

  const fetchCurrentData = useCallback(() => {
    const idPdi = updateId !== undefined ? updateId : searchParams.get('id')

    // Mode = crear PDI
    if (!idPdi) return

    // Busca un PDI creado previamente y cargo las unidades de medidas para los formularios
    // que la requieran

    setAlerData({
      show: true,
      icon: 'loadingAplanner',
      showCloseButton: false,
      title: 'Cargando...',
      text: 'Espera un momento, se encuentra cargando.',
    })

    Promise.allSettled([
      fetchLinkedPdi(idPdi),
      fetchMeasureUnits(),
      fetchTrends(),
      fetchMeasurementFrecuencies(),
      fetchTypes(),
    ])
      .then(([resultLinkedPdi, resultMeasureUnits, resultTrends, resultMeasurementFrecuencies, resultTypes]) => {
        if (resultLinkedPdi.status === 'rejected') {
          setCurrentData(initialCurrentData)
          if (errorFetch) errorFetch(resultLinkedPdi.reason)
          return
        }
        const measureUnits = resultMeasureUnits.status === 'fulfilled' ? resultMeasureUnits.value.items : []

        const trends = resultTrends.status === 'fulfilled' ? resultTrends.value.items : []

        const measurementFrecuencies =
          resultMeasurementFrecuencies.status === 'fulfilled' ? resultMeasurementFrecuencies.value.items : []

        const types = resultTypes.status === 'fulfilled' ? resultTypes.value.items : []

        handleSuccessFecthCurrentData(resultLinkedPdi.value, measureUnits, trends, measurementFrecuencies, types)
      })
      .finally(() => {
        if (updateId) setSearchParams({}, { replace: true })
        setAlerData(state => ({ ...state, show: false }))
      })
  }, [fetchLinkedPdi, searchParams, setSearchParams])

  const onCreateItemCurrentData = () => {
    fetchCurrentData()
  }

  const onUpdateItemCurrentData = <T extends CurrentData, K extends keyof T>(currentItem: K, value: T[K]) => {
    setCurrentData(state => {
      if (state.pdi === null) return initialCurrentData
      return { ...state, [currentItem]: value }
    })
  }

  useEffect(() => {
    const { _id: userCompanyId } = userCompany
    const { pdi } = currentData
    if (pdi) {
      console.log(pdi)
      const { idCompany: currentPdiCompanyId } = pdi
      if (currentPdiCompanyId !== userCompanyId) {
        if (updateId) return navigate('./../../')
        return navigate('./../')
      }
      return
    }

    fetchCurrentData()
  }, [userBranchOffice, userCompany, updateId, currentData.pdi, fetchCurrentData])

  return (
    <>
      <GenericAlert
        {...alertData}
        onCloseModal={() => setAlerData({ show: false })}
        onCloseButton={() => setAlerData({ show: false })}
      />
      {/************************
                CONTENEDOR CREAR PDIS
            *************************/}
      <Box sx={tabManagerPdiStyles.container}>
        {/****************************
                        CONTENEDOR BARRA LATERAL
                    ******************************/}
        <Box sx={tabManagerPdiStyles.containerLateralMenu}>
          {/*****************
                        MENU LATERAL
                    *****************/}
          <Box>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabIndex}
              onChange={handleTabs}
              aria-label="Vertical tabs example"
              sx={tabManagerPdiStyles.tabsLateralMenu}
            >
              {/*************
                                PDI/INICIO
                            *************/}
              <Tab
                disableTouchRipple
                label={
                  <>
                    {/* ICONO PDI */}
                    <IconCalendarCheck width={40} height={40} color="#7282B8" />

                    {/* TITULO */}
                    <Typography>PDI</Typography>
                  </>
                }
                {...a11yProps(0)}
              />
              {/*************
                                POLITICAS
                            *************/}
              <Tab
                disabled={!currentData.pdi}
                disableTouchRipple
                label={
                  <>
                    {/* ICONO POLITICAS */}
                    <IconPolitics />

                    {/* TITULO */}
                    <Typography>Políticas</Typography>
                  </>
                }
                {...a11yProps(1)}
              />

              {/************
                                OBJETIVOS
                            *************/}
              <Tab
                disabled={!currentData.policies.length}
                disableTouchRipple
                label={
                  <>
                    {/* ICONO OBJETIVOS */}
                    <IconObjetives />

                    {/* TITULO OBJETIVOS */}
                    <Typography>Objetivos</Typography>
                  </>
                }
                {...a11yProps(2)}
              />

              {/*************
                                PROGRAMAS
                            **************/}
                            
              <Tab
                disabled={!currentData.objetives.length}
                disableTouchRipple
                label={
                  <>
                    {/* ICONO PROGRAMAS */}
                    <IconPrograms />

                    {/* TITULO PROGRAMAS */}
                    <Typography>Programas</Typography>
                  </>
                }
                {...a11yProps(3)}
              />

              {/*************
                                PROYECTOS
                            **************/}
              <Tab
                disabled={!currentData.programs.length}
                disableTouchRipple
                label={
                  <>
                    {/* ICONO PROYECTOS */}
                    <IconProyects />

                    {/* TITULO PROYECTOS */}
                    <Typography>Proyectos</Typography>
                  </>
                }
                {...a11yProps(4)}
              />

              {/*********
                                METAS
                            **********/}
              <Tab
                disabled={!currentData.projects.length}
                disableTouchRipple
                label={
                  <>
                    <IconGoals />
                    <Typography>Metas</Typography>
                  </>
                }
                {...a11yProps(5)}
              />

              {/*********************
                                RETOS - CHALLENGE
                            **********************/}
              <Tab
                disableTouchRipple
                disabled={!currentData.goals.length}
                label={
                  <>
                    {/* ICONO RETOS */}
                    <IconChallenge />

                    {/* TITULO RETOS */}
                    <Typography>Retos</Typography>
                  </>
                }
                {...a11yProps(6)}
              />

              {/***************
                                INDICADORES
                            ****************/}
              <Tab
                disabled={!currentData.goals.length}
                disableTouchRipple
                label={
                  <>
                    {/* ICONO INDICADORES */}
                    <IconIndicators />

                    {/* TITULO INDICADORES */}
                    <Typography>Indicadores</Typography>
                  </>
                }
                {...a11yProps(7)}
              />
            </Tabs>
          </Box>

          {/*******************
                        CONTENIDO TABS
                    *******************/}
          <Box sx={tabManagerPdiStyles.contentItems}>
            {/********************
                            TÍTULO DINAMICO
                        ********************/}

            <Box sx={tabManagerPdiStyles.title}>
              {/***************
                                TITULO PDI
                            ****************/}
              <Typography>PDI</Typography>

              {/******************
                                FLECHA DERECHA
                            *******************/}
              <IconArrowRight width={11} height={22} />

              {/****************************
                                BOTON PLAN DE DESARROLLO
                            *****************************/}
              <Button disableTouchRipple>
                <Typography>{currentData.pdi ? currentData.pdi.title : 'Crear plan de desarrollo'}</Typography>
              </Button>

              {currentData.pdi && activeTabTitle && (
                <>
                  <IconArrowRight width={11} height={22} />
                  <Button disableTouchRipple>
                    <Typography>{activeTabTitle} </Typography>
                  </Button>
                </>
              )}
            </Box>

            {/********************************** 
                            FORMULARIO PLAN DE DESARROLLO
                        **********************************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={0}>
              <TabPanelPdi
                userBranchOffice={userBranchOffice}
                userCompany={userCompany}
                pdi={currentData.pdi}
                onCreate={onCreateItemCurrentData}
                onChangeTitle={handleActiveTabTitle}
                onUpdate={_updatePdi => onUpdateItemCurrentData('pdi', _updatePdi)}
              />
            </TabPanelContainer>

            {/*************************
                            ADMIN PANEL POLITICAS
                        *************************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={1}>
              {currentData.pdi && (
                <TabPanelPolicies
                  pdi={currentData.pdi}
                  policies={currentData.policies}
                  onCreate={onCreateItemCurrentData}
                  onChangeTitle={handleActiveTabTitle}
                  onUpdate={_policies => onUpdateItemCurrentData('policies', _policies)}
                />
              )}
            </TabPanelContainer>

            {/*************************
                            ADMIN PANEL OBJETIVOS
                        *************************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={2}>
              {currentData.pdi && (
                <TabPanelObjetives
                  pdi={currentData.pdi}
                  objetives={currentData.objetives}
                  policies={currentData.policies}
                  onCreate={onCreateItemCurrentData}
                  onChangeTitle={handleActiveTabTitle}
                  onUpdate={_objetives => onUpdateItemCurrentData('objetives', _objetives)}
                />
              )}
            </TabPanelContainer>

            {/*************************
                            ADMIN PANEL PROGRAMAS
                        *************************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={3}>
              {currentData.pdi && (
                <TabPanelPrograms
                  pdi={currentData.pdi}
                  objetives={currentData.objetives}
                  onCreate={onCreateItemCurrentData}
                  onChangeTitle={handleActiveTabTitle}
                  onUpdate={_programs => onUpdateItemCurrentData('programs', _programs)}
                  programs={currentData.programs}
                />
              )}
            </TabPanelContainer>

            {/*************************
                            ADMIN PANEL PROYECTOS
                        *************************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={4}>
              {currentData.pdi && (
                <TabPanelProjects
                  pdi={currentData.pdi}
                  onCreate={onCreateItemCurrentData}
                  onChangeTitle={handleActiveTabTitle}
                  onUpdate={_projects => onUpdateItemCurrentData('projects', _projects)}
                  projects={currentData.projects}
                  programs={currentData.programs}
                  goals={currentData.goals}
                  challenges={currentData.challenges}
                />
              )}
            </TabPanelContainer>

            {/*********************
                            ADMIN PANEL METAS
                        *********************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={5}>
              {currentData.pdi && (
                <TabPanelGoals
                  pdi={currentData.pdi}
                  measureUnits={measureUnitsForForms}
                  onCreate={onCreateItemCurrentData}
                  onChangeTitle={handleActiveTabTitle}
                  onUpdate={_goals => onUpdateItemCurrentData('goals', _goals)}
                  projects={currentData.projects}
                  goals={currentData.goals}
                  indicators={currentData.indicators}
                />
              )}
            </TabPanelContainer>

            {/*********************
                            ADMIN PANEL RETOS
                        *********************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={6}>
              {currentData.pdi && (
                <TabPanelChallenge
                  pdi={currentData.pdi}
                  challenges={currentData.challenges}
                  projects={currentData.projects}
                  onCreate={onCreateItemCurrentData}
                  onUpdate={_challenges => onUpdateItemCurrentData('challenges', _challenges)}
                  onChangeTitle={handleActiveTabTitle}
                />
              )}
            </TabPanelContainer>

            {/****************************
                            ADMIN PANEL INDICADORES
                        ****************************/}
            <TabPanelContainer parentId={TAB_PANEL_ID} value={tabIndex} index={7}>
              {currentData.pdi && (
                <TabPanelIndicators
                  pdi={currentData.pdi}
                  indicators={currentData.indicators}
                  goals={currentData.goals}
                  measurementFrecuencies={measurementFrecuenciesForForms}
                  measureUnits={measureUnitsForForms}
                  types={typesForForms}
                  trends={trendsForForms}
                  onCreate={onCreateItemCurrentData}
                  onUpdate={_indicators => onUpdateItemCurrentData('indicators', _indicators)}
                  onChangeTitle={handleActiveTabTitle}
                />
              )}
            </TabPanelContainer>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TabManagerPdi
