import { StyleComponent } from './../../../interfaces/styleComponent'

type Keys = 'container' | 'buttom'

const planStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '52.7vh',
    margin: 'auto',
    maxWidth: '1350px',

    '>p': {
      backgroundColor: 'inherit',
      fontSize: '50px',
      fontWeight: '600',
      color: '#1F398F',
      lineHeight: '75px',
      marginX: '30px',
    },

    // CONTENEDOR ARRIBA Y ABAJO
    '>div': {
      // RESPONSIVE
      '@media (max-width: 1360px)': {
        paddingX: '30px',

        '>.contentTop': {
          '>.contentParagraph': {
            marginRight: '10px',
          },

          // BOTON PDI
          '>a, >button': {
            marginLeft: '10px',
          },
        },
      },

      '@media (max-width: 850px)': {
        '>.contentTop': {
          flexDirection: 'column',
          justifyContent: 'center',

          // PARRAFO
          '>.contentParagraph': {
            width: 'auto !important',
            marginRight: '0px !important',
          },

          // BOTON PDI
          '>.buttomPdi': {
            width: 'auto !important',
            marginLeft: '0px !important',
            marginTop: '28px !important',
          },
        },
      },

      '@media (max-width: 660px)': {
        '>.contentBottom': {
          justifyContent: 'center !important',
        },
      },

      '@media (max-width: 340px)': {
        '>.contentBottom': {
          '>.buttoms': {
            minWidth: '100% !important',
          },
        },
      },

      // CONTENEDOR ARRIBA
      '>.contentTop': {
        backgroundColor: '#ffffff',
        marginTop: '46px',

        display: 'flex',
        justifyContent: 'space-between',

        // PARRAFO
        '>.contentParagraph': {
          backgroundColor: '#ffffff',
          minHeight: '350px',
          width: '650px',
          borderRadius: '50px',
          boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',

          '>.parrafo': {
            marginY: '56px !important',
            marginX: '58px',
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '30px',
            textAlign: 'justify',
          },
        },

        // BOTON PDI
        '>.buttomPdi': {
          borderRadius: '50px',
          width: '650px',
          marginTop: '0px',
          marginRight: '0px',
        },
      },

      // CONTENEDOR ABAJO
      '>.contentBottom': {
        marginTop: '28px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 1320px)': {
          justifyContent: 'space-evenly',
        },

        '>.buttoms': {
          borderRadius: '30px',
          maxWidth: '300px',
          minWidth: '300px',
        },
      },
    },
  },

  // BOTONES
  buttom: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '28px',
    marginX: '5px',

    ':hover': {
      backgroundColor: 'rgba(60, 45, 146, 0.04)',
    },

    '>svg': {
      marginX: 'auto',
    },

    '>p': {
      color: '#1F398F',
      fontSize: '25px',
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: '38px',
      textAlign: 'center',
      textTransform: 'capitalize',
      marginTop: '28px',
    },
  },
}

export default planStyles
