import { FunctionComponent, useEffect } from 'react'
import { Box, InputBase, Typography, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
// IMPORTADOS
import { FormProjectValues } from '../../../../interfaces/pdi/project'
import { Program } from '../../../../interfaces/pdi/program'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import ErrorField from '../../../ErrorField'
import CustomSelect from '../../../inputSelect/CustomSelect'
import { formProjectSchema } from '../../../../schemas/pdi/projectSchema'
import formStyles from '../../form.styles'
import SelectPagination from '../../../inputSelect/SelectPagination'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface FormProyectProp {
  pdi: PdiModel
  defaultValues?: Partial<FormProjectValues>
  programs: Program[]
  onCancel: () => void
  onSubmit: (formValues: FormProjectValues, reset: () => void) => void
  viewMode?: 'create' | 'update'
}

const _defaultValues: Partial<FormProjectValues> = {
  description: '',
  idProgram: '',
  title: '',
}

const FormProject: FunctionComponent<FormProyectProp> = ({
  pdi,
  viewMode = 'create',
  defaultValues = _defaultValues,
  programs,
  onSubmit,
  onCancel,
}) => {
  const getFixInitialValues = (): Partial<FormProjectValues> => {
    const { idProgram } = defaultValues
    const isInRange = programs.some(pr => pr._id === idProgram)
    return { ...defaultValues, idProgram: isInRange ? idProgram : '', idPdi: pdi._id }
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormProjectValues>({
    mode: 'onChange',
    resolver: yupResolver(formProjectSchema),
    defaultValues: getFixInitialValues(),
  })

  useEffect(() => {
    const fixDefaultValues = getFixInitialValues()
    reset(fixDefaultValues)
  }, [defaultValues])

  const submit: SubmitHandler<FormProjectValues> = formValues => onSubmit(formValues, () => reset(_defaultValues))

  return (
    <>
      {/**********************************
                CONTENEDOR FORMULARIO PROYECTO
            **********************************/}
      <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
        <Box
          sx={{
            ...formStyles.containerTitle,
            marginRight: '30px',
          }}
        >
          <Typography sx={formStyles.title}>Nombre del programa</Typography>

          <Controller
            name="idProgram"
            control={control}
            render={({ field }) => <SelectPagination {...field} propValue="_id" propText="title" items={programs} />}
          />
        </Box>

        {/****************
                    TITULO
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Título</Typography>

          {/***********
                        INPUT
                    ***********/}
          <Box sx={formStyles.input}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.title?.message} />
        </Box>

        {/****************
                    DESCRIPCION
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Descripción</Typography>

          {/************
                        INPUT
                    ************/}
          <Box
            sx={{
              ...formStyles.inputDescription,
              minHeight: '219px',
            }}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputBase {...field} multiline maxRows={8} sx={formStyles.inputBaseDescription} />
              )}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.description?.message} />
        </Box>

        {/**************************
                    PORCENTAJE DE CUMPLIMIENTO
                ****************************/}
        {/* <Box sx={formProjectStyles.percentageOfCompletion}>
                    <Typography>Porcentaje de cumplimiento</Typography>
                    <Box sx={formProjectStyles.input}>
                        <Controller
                            name="percentageOfCompletion"
                            control={control}
                            render={({ field: { value, ...field } }) => (
                                <InputBase
                                    {...field}
                                    placeholder='100%...'
                                    value={value ?? ''}
                                    type='number'
                                    sx={formProjectStyles.inputBase}
                                />
                            )}
                        />
                    </Box>
                    <ErrorField message={errors.percentageOfCompletion?.message} />
                </Box> */}

        {/***********
                    ESTADO 
                ************/}
        {viewMode === 'update' && (
          <>
            <Box sx={formStyles.containerState}>
              <Typography sx={formStyles.title}>Estado</Typography>

              {/***********************
                                CHECKBOX DE ESTADO
                            ***********************/}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === true ? true : false}
                          onChange={({ target: { checked } }) => onChange(checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Activo"
                  labelPlacement="start"
                />

                <FormControlLabel
                  className="inactivo"
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === false ? true : false}
                          onChange={({ target: { checked } }) => onChange(!checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Inactivo"
                  labelPlacement="start"
                />
              </FormGroup>
            </Box>

            {/*********************
                            MENSAJE DE ERROR
                        *********************/}
            <ErrorField message={errors.state?.message} />
          </>
        )}

        {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
        <Box sx={formStyles.divButton}>
          {/**********************************
                            BOTONES ACTUALIZAR/DESACTIVAR
                        ***********************************/}
          <div>
            {/********************
                            BOTON ACTUALIZAR
                        *********************/}
            <Button disabled={!isValid} disableTouchRipple type="submit" sx={formStyles.buttonSave}>
              Guardar
            </Button>

            {/********************
                            BOTON DESACTIVAR
                        *********************/}
            <Button disableTouchRipple sx={formStyles.buttonCancel} onClick={onCancel}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default FormProject
