/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, InputBase, Typography, Button } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// IMPORTADOS
import { FormObjetiveValues } from '../../../../interfaces/pdi/objetive'
import ErrorField from '../../../ErrorField'
import { Politics } from '../../../../interfaces/pdi/politics'
import { formObjetiveSchema } from '../../../../schemas/pdi/objetiveSchema'
import formStyles from '../../form.styles'
import SelectPagination from '../../../inputSelect/SelectPagination'
import { PdiModel } from '../../../../interfaces/pdi/pdi'
import useTypesObjetives from '../../../../hooks/pdi/useTypesObjetives'

interface FormObjetiveProps {
  viewMode?: 'create' | 'update'
  pdi: PdiModel
  policies: Politics[]
  defaultValues?: Partial<FormObjetiveValues>
  onSubmit: (_formValues: FormObjetiveValues, reset: () => void) => void
  onCancel: () => void
}

const _defaultValues: Partial<FormObjetiveValues> = {
  title: '',
  description: '',
  idPolicies: '',
  idTypeObjetive: '',
}

const FormObjetive: FunctionComponent<FormObjetiveProps> = ({
  viewMode = 'create',
  defaultValues = _defaultValues,
  pdi,
  policies,
  onSubmit,
  onCancel,
}) => {
  const getFixInitialValues = (): Partial<FormObjetiveValues> => {
    const { idPolicies, idTypeObjetive } = defaultValues
    const objetive: any = idTypeObjetive
    const isInRange = policies.some(p => p._id === idPolicies)
    return {
      ...defaultValues,
      idPolicies: isInRange ? idPolicies : '',
      idPdi: pdi._id,
      idTypeObjetive: objetive._id ?? '',
    }
  }

  const { fetchTypes } = useTypesObjetives()
  const [typesObjetives, setTypesObjetives] = useState([])

  const getTypeObjetives = async () => {
    const res: any = await fetchTypes()
    setTypesObjetives(res.items)
  }

  useEffect(() => {
    getTypeObjetives()
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormObjetiveValues>({
    mode: 'onChange',
    resolver: yupResolver(formObjetiveSchema),
    defaultValues: getFixInitialValues(),
  })

  useEffect(() => {
    const fixDefaultValues = getFixInitialValues()
    reset(fixDefaultValues)
  }, [defaultValues, typesObjetives])

  const submit: SubmitHandler<FormObjetiveValues> = formValues => onSubmit(formValues, () => reset(_defaultValues))

  return (
    <>
      {/**************
                CONTAINER
            ***************/}
      <Box component="form" onSubmit={handleSubmit(submit)} sx={formStyles.container}>
        {/**************************
                    NOMBRE DE LA POLITICA
                ****************************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Nombre de la política</Typography>

          <Controller
            name="idPolicies"
            control={control}
            render={({ field }) => <SelectPagination {...field} propValue="_id" propText="title" items={policies} />}
          />
        </Box>

        {/****************
                    TITULO
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Título</Typography>

          {/**********
                        INPUT
                    **********/}
          <Box sx={formStyles.input}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <InputBase {...field} sx={formStyles.inputBase} />}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.title?.message} />
        </Box>

        {/****************
                    DESCRIPCION
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Descripción</Typography>

          {/***********
                        INPUT
                    ***********/}
          <Box sx={formStyles.inputDescription}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputBase {...field} multiline maxRows={5} sx={formStyles.inputBaseDescription} />
              )}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.description?.message} />
        </Box>

        {/****************
                    TIPO DE OBJETIVO
                *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Tipo de objetivo</Typography>

          {/**********
                        INPUT
                    **********/}
          <Box sx={formStyles.input}>
            <Controller
              name="idTypeObjetive"
              control={control}
              render={({ field }) => (
                <SelectPagination {...field} propValue="_id" propText="name" items={typesObjetives} />
              )}
            />
          </Box>

          {/*********************
                        MENSAJE DE ERROR
                    *********************/}
          <ErrorField message={errors.idTypeObjetive?.message} />
        </Box>

        {/**************************
                    PORCENTAJE DE CUMPLIMIENTO
                ****************************/}
        {/* <Box sx={formStyles.percentageOfCompletion}>
                    <Typography>Porcentaje de cumplimiento</Typography>
                    <Box sx={formObjetiveStyles.input}>
                        <Controller
                            name="percentageOfCompletion"
                            control={control}
                            render={({ field: { value, ...field } }) => (
                                <InputBase
                                    {...field}
                                    placeholder='100%...'
                                    value={value ?? ''}
                                    type='number'
                                    sx={formObjetiveStyles.inputBase}
                                />
                            )}
                        />
                    </Box>

                    <ErrorField message={errors.percentageOfCompletion?.message} />
                </Box> */}

        {/***********
                    ESTADO 
                ************/}
        {viewMode === 'update' && (
          <>
            <Box sx={formStyles.containerState}>
              <Typography sx={formStyles.title}>Estado</Typography>

              {/************************
                                CHECKBOX DE ESTADO
                            ************************/}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === true ? true : false}
                          onChange={({ target: { checked } }) => onChange(checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Activo"
                  labelPlacement="start"
                />

                <FormControlLabel
                  className="inactivo"
                  control={
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={value === false ? true : false}
                          onChange={({ target: { checked } }) => onChange(!checked)}
                          disableTouchRipple
                        />
                      )}
                    />
                  }
                  label="Inactivo"
                  labelPlacement="start"
                />
              </FormGroup>
            </Box>

            {/*********************
                            MENSAJE DE ERROR
                        *********************/}
            <ErrorField message={errors.state?.message} />
          </>
        )}

        {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
        <Box sx={formStyles.divButton}>
          {/**********************************
                        BOTONES ACTUALIZAR/DESACTIVAR
                    ***********************************/}
          <div>
            {/********************
                            BOTON ACTUALIZAR
                        *********************/}
            <Button type="submit" disableTouchRipple disabled={!isValid} sx={formStyles.buttonSave}>
              Guardar
            </Button>

            {/********************
                            BOTON DESACTIVAR
                        *********************/}
            <Button onClick={onCancel} disableTouchRipple sx={formStyles.buttonCancel}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default FormObjetive
