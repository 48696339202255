/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'

const useCalendar = () => {
  const { fetchList } = useFetch()
  const { showErrorDialog } = useAppDialog()

  const getTaskDates = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getTasksDates',
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  return {
    getTaskDates,
  }
}

export default useCalendar
