import { Box, Button, Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import IconArrowLeft from '../icons/IconArrowLeft'

export const ButtonBack = () => {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  // NAVEGACION ATRÁS POR RUTA (/)
  // const toBack = () => {
  //   const matchs = pathname.split('/')
  //   const cutNumber = matchs.at(-2) === 'update' || !matchs.at(-1) ? 2 : 1
  //   const pathParent = matchs.slice(0, matchs.length - cutNumber).join('/')
  //   if (!pathParent) return navigate('home')
  //   navigate(pathParent)
  // }

  /*********************************************/
  // OCULTAR BOTON ATRÁS GLOBAL EN PÁGINA HOME
  /*********************************************/
  const regExp = /\//g
  const pathHome = pathname.replace(regExp, '') === 'home'
  if (pathHome) return null

  return (
    <>
      {/***************/}
      {/* BOTON ATRÁS */}
      {/***************/}
      <Box
        // onClick={toBack}
        onClick={() => navigate(-1)}
        className="buttonBack"
        sx={{
          marginLeft: '118px',
          marginRight: 'auto',

          '@media (max-width: 1390px)': {
            marginLeft: '80px',
          },
          '@media (max-width: 655px)': {
            marginLeft: '30px',
          },

          // BOTON
          '&>button': {
            backgroundColor: 'transparent',
            minWidth: '0px',
            height: 'max-content',
            padding: '0px 0px',
            textTransform: 'capitalize',
            ':hover': {
              backgroundColor: 'transparent',
            },

            // TYPOGRAPHY
            '&>p': {
              color: '#1F398F',
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '30px',
              marginLeft: '11px',
            },
          },
        }}
      >
        {/*********/}
        {/* BOTON */}
        {/*********/}
        <Button disableTouchRipple>
          <IconArrowLeft />

          {/*********/}
          {/* TEXTO */}
          {/*********/}
          <Typography sx={{ display: 'inline' }}>Atrás</Typography>
        </Button>
      </Box>
    </>
  )
}
