import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, List, ListItem } from '@mui/material'
import IconPensil from '../../../components/icons/IconPensil'
import ListItemText from '@mui/material/ListItemText'
import infoBranchOfficeStyles from './InfoBranchOffice.styles'
import IconLocation from '../../../components/icons/IconLocation'
import IconOpenEye from '../../../components/icons/IconOpenEye'
import useAuth from '../../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import useBranchOffice from '../../../hooks/branchOffice/useBranchOffice'
import UpdateImage from './UpdateImage'

const InfoBranchOffice = () => {
  const { userData } = useAuth()
  const { fetchGetOneBranchOffice } = useBranchOffice()
  const params = useParams()
  const navigate = useNavigate()
  const company = userData?.idBranchOffice?.idCompany

  const [branchOffice, setBranchOffice] = useState<any>({})

  useEffect(() => {
    if (params.id) {
      getBranchOffice()
    }
  }, [])

  const getBranchOffice = async () => {
    const response: any = await fetchGetOneBranchOffice(params.id || '')
    const data = response
    setBranchOffice(data)
  }

  return (
    <>
      {/**********************
                CONTENEDOR PERFIL
            ************************/}
      <Box sx={infoBranchOfficeStyles.container}>
        {/*********************
                    CONTENEDOR TITULO
                ***********************/}
        <Box sx={infoBranchOfficeStyles.containerTitle}>
          {/* ICONO USUARIO */}
          <IconLocation width={46} height={46} color="#7282B8" />

          {/* TITULO */}
          <Typography>Información sucursal</Typography>

          <Button disableTouchRipple onClick={() => navigate(`/branchOffice/update/${branchOffice?._id}`)}>
            <IconPensil width={11} height={11} />
            Editar
          </Button>
        </Box>

        {/*************
                    CONTENIDO
                **************/}
        <Box sx={infoBranchOfficeStyles.content}>
          {/************************
                        CONTENEDOR IZQUIERDO
                    **************************/}
          <Box sx={infoBranchOfficeStyles.contentLeft}>
            <List className="listInline">
              {/**********************
                                NOMBRE DE LA SUCURSAL
                            ***********************/}
              <ListItem>
                <ListItemText
                  primary="Nombre de la sucursal"
                  secondary={branchOffice?.nameBranchOffice ?? 'Sin datos'}
                />
              </ListItem>
            </List>

            <List className="listGrid">
              {/***********
                                CIUDAD
                            ************/}
              <ListItem>
                <ListItemText primary="Ciudad" secondary={branchOffice?.city ?? 'Sin datos'} />
              </ListItem>

              {/***********
                                DIRECCON
                            ************/}
              <ListItem>
                <ListItemText primary="Dirección" secondary={branchOffice?.address ?? 'Sin datos'} />
              </ListItem>

              {/**************
                                ENCARGADO
                            ***************/}
              <ListItem>
                <ListItemText primary="Encargado" secondary={branchOffice?.nameManager ?? 'Sin datos'} />
              </ListItem>

              {/***********************
                                EMAIL DEL ENCARGADO
                            ************************/}
              <ListItem>
                <ListItemText primary="Email del encargado" secondary={branchOffice?.emailManager ?? 'Sin datos'} />
              </ListItem>

              {/************
                                SIGLAS
                            *************/}
              <ListItem>
                <ListItemText primary="Siglas" secondary={branchOffice?.acronym ?? 'Sin datos'} />
              </ListItem>

              {/************************
                                NOMBRE DE LA EMPRESA
                            *************************/}
              <ListItem>
                <ListItemText primary="Nombre de la empresa" secondary={company?.nameCompany} />
              </ListItem>
            </List>

            {/* LLEVA AL PDI DE LA SUCURSAL ↓*/}
            <Button disableTouchRipple href="/plan">
              <IconOpenEye width={16} height={11} color="#FFFFFF" />
              Ver Plan de Desarrollo
            </Button>
          </Box>

          {/**********************
                        CONTENEDOR DERECHO
                    ************************/}
          <UpdateImage data={branchOffice} />
        </Box>
      </Box>
    </>
  )
}

export default InfoBranchOffice
