import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys =
    | "container"
    | "title"
    | "containerLateralMenu"
    | "tabsLateralMenu"
    | "contentItems"
    | "tabPanerContainer";

const tabManagerPdiStyles: StyleComponent<Keys> = {
    container: {
        minHeight: "60vh",
        maxWidth: "1340px",
        m: "auto",
    },
    title: {
        display: "flex",
        backgroundColor: "inherit",

        // TYPOGRAPHY
        "&>p": {
            color: "#1F398F",
            fontSize: "50px",
            fontWeight: "600",
            lineHeight: "75px",
            fontStyle: "normal",
        },

        // BOTON TITULO
        "&>svg": {
            minWidth: "0px",
            height: "max-content",
            paddingLeft: "25px",
            marginY: "auto",
            borderRadius: "20px",
            ":hover": {
                backgroundColor: "inherit",
            },
        },

        // BOTON MÁS
        "&>button": {
            minWidth: "0px",
            height: "max-content",
            marginY: "auto",
            borderRadius: "20px",
            ":hover": {
                backgroundColor: "inherit",
            },
            ">p": {
                color: "#7282B8",
                fontSize: "25px",
                fontWeight: "400",
                textTransform: "capitalize",
            },
        },
    },

    containerLateralMenu: {
        bgcolor: "background.paper",
        display: "flex",
        // pt: "21px",
        px: {
            xs: 2,
            md: 2,
            lg: 2,
            xl: 0,
        },
        margin: "auto",
        // ">#vertical-tabpanel-100, >#vertical-tabpanel-0, >#vertical-tabpanel-1, >#vertical-tabpanel-2, >#vertical-tabpanel-3, >#vertical-tabpanel-4, >#vertical-tabpanel-5, >#vertical-tabpanel-6, >#vertical-tabpanel-7":
        // {

        // ">div": {
        //     padding: "0px",
        // },
        // },
    },
    tabsLateralMenu: {
        backgroundColor: "#ffffff",
        width: "153px",
        height: "815px",
        borderRight: 1,
        borderColor: "transparent",
        paddingY: "60px",
        borderRadius: "250px",
        boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",

        // ICONOS
        ">div": {
            ">div": {
                height: "100%",
                justifyContent: "space-between",
                ">button": {
                    py: "10px",
                    margin: "auto",
                    display: "block",
                    textTransform: "capitalize",
                    ":hover": {
                        backgroundColor: "inherit",
                    },
                    ">p": {
                        mt: "5px",
                        color: "#7282B8",
                        fontSize: "12px",
                    },
                },

                // SELECCIONADO
                ">.Mui-selected": {
                    ">svg>path": {
                        fill: "#1F398F",
                    },
                    ">p": {
                        color: "#1F398F",
                        fontWeight: "600",
                    },
                },
            },
        },
    },
    contentItems: {
        // background : 'yellow',
        width: "100%",
        ml: "96px",
        mt: -3,
    },

    tabPanerContainer: {
        ">div": {
            marginTop: "20px",
        },
    },
};

export default tabManagerPdiStyles;
