import { FunctionComponent } from 'react'
import { Box } from '@mui/material'
import tabManagerPdiStyles from '../tabs/tabManagerPdi/tabManagerPdi.styles'

interface TabPanelContainerProps {
  parentId: string
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanelContainer: FunctionComponent<TabPanelContainerProps> = props => {
  const { children, value, index, parentId, ...other } = props

  return (
    <Box
      sx={tabManagerPdiStyles.tabPanerContainer}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${parentId}-${index}`}
      aria-labelledby={`vertical-tab-${parentId}-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

export default TabPanelContainer
