import { useCallback } from "react";
import customAlerts from "../../helpers/customAlerts";
import useFetch, { BasicFetchListProps } from "../useFetch";

const { loadAlert, successAlert, closeLoading, errorAlert } = customAlerts

const useRol = () => {
  const { fetchList, fetchCreate } = useFetch()

  const fetchGetRoles = useCallback(async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
    return fetchList<any>({
      action: 'getRoles',
      params: [
        ['limit', `${limit}`],
        ['active', `${active}`],
        ['from', `${from}`]
      ]
    })
      .then(res => {
        return res
      })
      .catch((error) => {
        errorAlert({ html: error?.message })
      })
      .finally(() => closeLoading())
  }, [fetchList])

  const fetchCreateRol = useCallback((body: any, done?: (_id: string) => void) => {
    loadAlert({ html: 'Creando usuario, por favor, espera...' })
    fetchCreate({ action: 'createPosition', body })
      .then(_id => {
        successAlert({
          html: 'Proyecto creado exitosamente!.',
          didClose: () => {
            done && done(_id)
          }
        })
      })
      .catch((error) => {
        errorAlert({ html: error?.message })
      })
      .finally(() => closeLoading())
  }, [fetchCreate])

  return { fetchGetRoles, fetchCreateRol }
}

export default useRol
