/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ThemeProvider,
  createTheme,
  useTheme,
  Theme,
} from '@mui/material'

// IMPORTADOS
import formPoliticsStyles from '../forms/pdi/formPolitics/formPolitics.styles'
import useDepartament from '../../hooks/departament/useDepartament'
import ErrorField from '../ErrorField'
import formStyles from '../forms/form.styles'

interface DepartamentProps {
  handleSelectChange: Function
  defaultValue?: string
  errorMessage?: string
}

const Departament = ({ handleSelectChange, defaultValue = '', errorMessage }: DepartamentProps) => {
  // EVENTOS DEL SELECT CARGO
  const [value, setValue] = useState('')
  const [departaments, setDepartaments] = useState<any>([])

  // TEMA Y ESTILOS
  const themeDepartament = (): Theme => {
    // eslint-disable-next-line
    const theme: any = useTheme()

    return createTheme({
      components: {
        MuiMenu: {
          styleOverrides: {
            root: {
              '>.MuiPaper-elevation': {
                backgroundColor: '#ffffff',
                maxHeight: '250px',
                minHeight: '86px',
                borderRadius: '15px',
                overflowY: 'hidden',

                // UL
                '>ul': {
                  backgroundColor: 'inherit',
                  maxHeight: '168px',
                  maxWidth: '570px',
                  margin: '20px 23px 20px 23px',
                  paddingTop: '0px',
                  paddingBottom: '0px',

                  ':hover': {
                    backgroundColor: 'transparent',
                  },

                  // SCROLL
                  overflowY: departaments.length > 6 ? 'scroll' : 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                    size: '10px',
                    '&-track': {
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: '10px',
                      border: '5px solid #ffffff',
                    },
                    '&-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '20px',
                      width: '10px',
                    },
                  },

                  // LI
                  '>li': {
                    color: '#000000',
                    fontFamily: 'Poppins',
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    paddingBottom: '5px',
                    ':hover': {
                      color: '#1F398F',
                      fontWeight: '600',
                      backgroundColor: 'inherit',
                    },
                  },

                  '>.Mui-selected': {
                    backgroundColor: 'inherit',
                    color: '#1F398F',
                    fontWeight: '600',
                    ':hover': {
                      backgroundColor: 'inherit',
                    },
                  },
                  '>.Mui-focusVisible': {
                    display: 'none',
                  },
                  '>.Mui-focused': {
                    display: 'none',
                  },
                },
              },
            },
          },
        },
      },
    })
  }

  // TEMA
  const myTheme = themeDepartament()

  const { fetchDepartaments } = useDepartament()

  const handleChange = (event: { target: { value: string; name: string } }) => {
    setValue(event.target.value)
    handleSelectChange({ [event.target.name]: event.target.value })
  }

  // CAMBIO DE ESTADO BOTON CREAR CARGO

  const list = async () => {
    fetchDepartaments({ limit: 0, from: 0 }).then(({ items }) => {
      setDepartaments(items)
    })
  }

  useEffect(() => {
    list()
  }, [])

  useEffect(() => {
    setValue(defaultValue)
  }, [departaments, defaultValue])

  return (
    <>
      {/*********************
        CONTENEDOR INPUT
      **********************/}
      <Box
        sx={{
          ...formStyles.containerInputSelect,
          width: '96.5% !important',
        }}
      >
        <Typography>Área</Typography>

        {/*****************
          INPUT SELECT
        ******************/}
        <ThemeProvider theme={myTheme}>
          <FormControl sx={formStyles.inputSelect} variant="standard">
            <Select value={value} onChange={handleChange} name="idDepartament">
              {departaments.map((departament: any, index: any) => (
                <MenuItem disableTouchRipple value={departament._id} focusRipple key={index}>
                  {departament.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ThemeProvider>
        <ErrorField message={errorMessage ?? ''} />
      </Box>
    </>
  )
}

export default Departament
