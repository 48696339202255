import { FunctionComponent } from "react";

interface IconRelationProps {
    width?: number | string;
    height?: number | string;
}

const IconRelation: FunctionComponent<IconRelationProps> = ({
    width = 11,
    height = 11,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 6V9H10.45L9.35 8H4.95V4H2.75V5H1.65V4H0V3H1.65V2H2.75V3H6.05V7H9.35L10.45 6H11Z"
                fill="#1F398F"
            />
        </svg>
    );
};

export default IconRelation;
