import { FunctionComponent, memo, useState } from "react";
import { Box, Grow, Pagination } from "@mui/material";

// IMPORTADOS
import tableItemsPdiStyles from "../tableItemsPdiStyles";
import { Politics } from "../../../../interfaces/pdi/politics";
import usePagination from "../../../../hooks/usePagination";
import CardItemPdi from "../../../cards/cardItemPdi/CardItemPdi";
import ModalDetailItemPdi, {
    ModalDetailItemPdiProps,
} from "../../../modals/modalDetailItemPdi/ModalDetailItemPdi";
import IconDirection from "../../../icons/IconDirection";
import { PdiModel } from "../../../../interfaces/pdi/pdi";
import ConfirmAlert, {
    ConfirmAlertprops,
} from "../../../Alerts/confirmAlert/ConfirmAlert";
import { growTimeoutEffect } from "../../../../helpers/efects";
import paginationStyles from "../../../Pagination.styles";

interface PoliciesProps {
    onUpdate: (politics: Politics) => void;
    onDelete: (politics: Politics) => void;
    policies: Politics[];
    pdi: PdiModel;
}

const itemsPerPage = 9;

const TablePolicies: FunctionComponent<PoliciesProps> = ({
    pdi,
    policies,
    onUpdate,
    onDelete,
}) => {
    const { countPage, currentPage, currentData, handleChangePage } =
        usePagination(policies, itemsPerPage);

    const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>(
        {
            show: false,
            confirmMessage: "Estas seguro que deseas eliminar esta política?",
            confirmMessageBottom:
                "Recuerde que si elimina esta política usted no podrá editarla",
            textButtonActionConfirm: "Si",
            textButtonActionCancel: "No",
        }
    );

    const [detailModalData, setDetailModalData] =
        useState<ModalDetailItemPdiProps>({
            show: false,
            itemsRender: [],
            textButtonAction: "Editar",
        });

    const handleConfirmAlertData = (politics: Politics) => {
        setConfirmAlertData((state) => ({
            ...state,
            show: true,
            onClickButtonActionConfirm: () => {
                setConfirmAlertData((state) => ({ ...state, show: false }));
                onDelete(politics);
            },
        }));
    };

    const handleDetailModal = (
        politics: Politics,
        itemsRender: ModalDetailItemPdiProps["itemsRender"]
    ) => {
        setDetailModalData((state) => ({
            ...state,
            show: true,
            title: `Detalles de la política`,
            itemsRender,
            onClickButtonAction: () => {
                setDetailModalData((state) => ({ ...state, show: false }));
                onUpdate(politics);
            },
        }));
    };

    return (
        <>
            <ConfirmAlert
                {...confirmAlertData}
                onClickButtonActionCancel={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
                onCloseForButtom={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
            />
            <ModalDetailItemPdi
                {...detailModalData}
                maxWidth={1067}
                maxHeight={1012}
                onCloseForButtom={() =>
                    setDetailModalData((state) => ({ ...state, show: false }))
                }
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        ...tableItemsPdiStyles.container,
                        justifyContent:
                            currentData().length === 2
                                ? "center !important"
                                : "space-around",
                    }}
                >
                    {/************
                        CARDS DE POLITICAS
                    *************/}
                    {currentData().map((politics, index) => (
                        <Grow
                            key={politics._id}
                            in
                            timeout={growTimeoutEffect(index)}
                        >
                            <Box>
                                <CardItemPdi
                                    title={`${politics.index}. ${politics.title}`}
                                    text={politics.description}
                                    smallText={
                                        politics.state ? "Activo" : "Inactivo"
                                    }
                                    textButtonLeft="Editar"
                                    textButtonRight="Eliminar"
                                    textButtonDetail={<IconDirection />}
                                    onClickButtonDetail={() =>
                                        handleDetailModal(politics, [
                                            {
                                                title: "Título",
                                                value: politics.title,
                                            },
                                            {
                                                title: "Subtítulo",
                                                value: politics.subTitle,
                                            },
                                            {
                                                title: "Descripción",
                                                value: politics.description,
                                            },
                                            {
                                                title: "Enlace",
                                                value: politics.link,
                                            },
                                            {
                                                title: "Nombre del PDI",
                                                value: pdi.title,
                                            },
                                            {
                                                title: "Porcentaje de cumplimiento",
                                                value: `${politics.percentageOfCompletion}%`,
                                            },
                                            {
                                                title: "Estado",
                                                value: politics.state
                                                    ? "Activo"
                                                    : "Inactivo",
                                            },
                                        ])
                                    }
                                    onClickButtonLeft={() => onUpdate(politics)}
                                    onClickButtonRight={() =>
                                        handleConfirmAlertData(politics)
                                    }
                                />
                            </Box>
                        </Grow>
                    ))}
                    {currentData().length == 1 && (
                        <>
                            <Box />
                            <Box />
                        </>
                    )}
                    {currentData().length == 2 && <Box />}
                </Box>

                {/****************
                    PAGINACION
                ****************/}
                <Box sx={tableItemsPdiStyles.pagesList}>
                    <Pagination
                        sx={paginationStyles.paginationNumbers}
                        page={currentPage}
                        defaultPage={1}
                        count={countPage}
                        onChange={(e, _page) => handleChangePage(_page)}
                    />
                </Box>
            </Box>
        </>
    );
};

export default memo(TablePolicies);
