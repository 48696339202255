import { FunctionComponent } from 'react'
import { Box, Typography, Button, Fade } from '@mui/material'
import TablePrograms from '../../../tables/pdi/tablePrograms/TablePrograms'
import IconAdd from '../../../icons/IconAdd'
import { Objetive } from '../../../../interfaces/pdi/objetive'
import {
  ChangeActiveProgram,
  FormProgramValues,
  NewProgram,
  Program,
  UpdateProgram,
} from '../../../../interfaces/pdi/program'
import useViewControlTabPanel from '../../../../hooks/pdi/useViewControlTabPanelPdi'
import FilterPdi from '../../../inputSelect/FilterPdi'
import FormProgram from '../../../forms/pdi/formProgram/FormProgram'
import useProgram from '../../../../hooks/pdi/useProgram'
import formStyles from '../../../forms/form.styles'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface TabPanelProgramsProps {
  pdi: PdiModel
  objetives: Objetive[]
  programs: Program[]
  onCreate: (_id: string) => void
  onChangeTitle: (tabTitle: string | null) => void
  onUpdate: (programs: Program[]) => void
}

const TabPanelPrograms: FunctionComponent<TabPanelProgramsProps> = ({
  pdi,
  objetives,
  programs,
  onCreate,
  onChangeTitle,
  onUpdate,
}) => {
  const { fetchCreateProgram, fetchUpdateProgram, fetchDisableProgram, ViewAlert } = useProgram()

  const { viewMode, showViewData, showViewCreate, showViewUpdate, updateItem, filterOption, changeFilterOption } =
    useViewControlTabPanel<Program, Objetive>({
      items: programs,
      onChangeTitle,
      titleOptions: {
        none: null,
        create: 'Crear programa',
        update: 'Actualizar programa',
        data: 'Programas',
      },
    })

  const programsFiltered = (): Program[] => {
    if (filterOption === null) return programs
    const { _id } = filterOption
    return programs.filter(program => program.idObjetive === _id)
  }

  const getFormDefaultValues = (): Partial<FormProgramValues> | undefined => {
    if (!updateItem) return undefined
    const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } = updateItem
    return updateValues
  }

  const create = ({ state, ...other }: FormProgramValues, reset: () => void) => {
    const newProgram: NewProgram = other
    fetchCreateProgram(newProgram, _id => {
      reset()
      onCreate(_id)
    })
  }

  const update = (formValues: FormProgramValues, reset: () => void) => {
    if (!updateItem) {
      return
    }
    const { _id } = updateItem
    const updateProgram: UpdateProgram = { _id, ...formValues }
    fetchUpdateProgram(updateProgram, _updateValues => {
      reset()
      const { _id } = _updateValues
      const _updatePrograms = programs.map(program => {
        if (program._id === _id) {
          return { ...program, ..._updateValues }
        }
        return program
      })
      onUpdate(_updatePrograms)
      showViewData()
    })
  }

  const disable = ({ _id }: Program) => {
    const disableProgram: ChangeActiveProgram = { _id, is_active: false }
    fetchDisableProgram(disableProgram, () => {
      const _programs = programs.filter(pr => pr._id !== _id)
      onUpdate(_programs)
    })
  }

  const onSubmitForm = (formValues: FormProgramValues, reset: () => void) => {
    if (updateItem) return update(formValues, reset)
    create(formValues, reset)
  }

  return (
    <>
      <ViewAlert />
      {/****************
              CONTENEDOR FORMULARIO
            *****************/}

      <Fade in={viewMode === 'update' || viewMode === 'create'}>
        <Box
          sx={{
            display: viewMode === 'data' || !viewMode ? 'none' : 'block',
          }}
        >
          <FormProgram
            pdi={pdi}
            defaultValues={getFormDefaultValues()}
            onCancel={showViewData}
            onSubmit={onSubmitForm}
            objetives={objetives}
            viewMode={viewMode === 'data' || !viewMode ? undefined : viewMode}
          />
        </Box>
      </Fade>
      {/****************
                CONTENEDOR TABLA PROGRAMAS
            *****************/}
      <Fade in={viewMode === 'data'}>
        <Box sx={{ display: viewMode === 'data' ? 'block' : 'none' }}>
          <Box sx={formStyles.container}>
            {/****************
                            TITULO
                        *****************/}
            <Box sx={formStyles.buttonTitle}>
              {/*********************************
                                TITULO PROGRAMAS DEL OBJETIVO X
                            **********************************/}
              <Typography sx={{ fontSize: '25px' }}>
                {filterOption ? `Programas de ${filterOption.title}` : 'Todos los programas'}
              </Typography>

              <Box>
                {/***************************
                                    BOTON FILTRAR POR PADRE
                                ****************************/}
                <FilterPdi
                  getOptionLabel={option => option.title}
                  id="filter-program-by-objetives"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  labelInput="Objetivos"
                  noOptionsText="Sin resultados"
                  options={objetives}
                  onChange={(e, value) => changeFilterOption(value)}
                  placeholder="Buscar objetivo..."
                  value={filterOption}
                />

                {/*************
                                    BOTON MÁS
                                **************/}
                <Button onClick={showViewCreate} disableTouchRipple>
                  <IconAdd />

                  <Typography>Crear nuevo programa</Typography>
                </Button>
              </Box>
            </Box>
            <TablePrograms
              filterOption={filterOption}
              programs={programsFiltered()}
              objetives={objetives}
              onUpdate={showViewUpdate}
              onDelete={disable}
            />
          </Box>
        </Box>
      </Fade>
    </>
  )
}

export default TabPanelPrograms
