import { StyleComponent } from "./../../../interfaces/styleComponent";

type Keys = "container";

const footerStyles: StyleComponent<Keys> = {
    container: {
        backgroundColor: "#ffffff",
        textAlign: "center",
        boxShadow: "0px -5px 15px rgba(114, 130, 184, 0.1)",
        paddingTop: "56px",
        paddingBottom: "57px",
        ">img": {
            paddingBottom: "13px",
        },
        ">p": {
            fontSize: "10px",
            fontWeight: "400",
        },
    },
};

export default footerStyles;
