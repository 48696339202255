/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  InputBase,
  Typography,
  Button,
  FormGroup,
  Checkbox,
  FormLabel,
  Divider,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material'

// IMPORTADOS
import ErrorField from '../../../ErrorField'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import formStyles from '../../form.styles'
import { FormGoalPdaFields } from '../../../../interfaces/pda/goal-pda'
import { formGoalPdaSchema } from '../../../../schemas/pda/goalPdaSchema'
import { ValidateFields, ValidateForm } from '../../../../services/ValidateForm'
import CustomSelect from '../../../inputSelect/CustomSelect'
import { PdaContext } from '../../../context/PdaProvider'
import useTasks from '../../../../hooks/useTasks'

interface FormGoalPdaProps {
  defaultValues?: any
  disabledButtonCancel?: boolean
  idGoalPda?: string | null
  onCancel?: () => void
}

const FormGoalPda: FunctionComponent<FormGoalPdaProps> = ({
  defaultValues,
  disabledButtonCancel,
  idGoalPda,
  onCancel,
}) => {
  const { getPdiActive } = useTasks()
  const pdaContext = useContext(PdaContext)
  const [dataForm, setDataForm] = useState<any>({})
  const [pdiActive, setPdiActive] = useState<any>({})
  const [filterSelects, setFilterSelects] = useState<any>({})
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    if (idGoalPda) {
      const { link_goal_indicators, index, ...copyDefaultValues } = defaultValues
      setDataForm({
        ...copyDefaultValues,
        _id: idGoalPda,
        idIndicator: link_goal_indicators.map((item: any) => {
          return { idGoalIndicator: item._id, ...item.idIndicator }
        }),
      })
    } else {
      setDataForm({ ...dataForm, idPa: pdaContext?.pda?._id, idPdi: pdaContext?.pda?.idPdi?._id })
    }
  }, [pdiActive, filterSelects])

  console.log('d', dataForm)
  console.log('f', filterSelects)

  useEffect(() => {
    if (pdiActive && idGoalPda) {
      setFilterSelects({
        objetives:
          pdiActive.link_pdi_policies?.find((item: any) => item._id === defaultValues?.idPolicies)
            ?.link_policies_objetive ?? [],
        programs:
          pdiActive.link_pdi_policies
            ?.find((item: any) => item._id === defaultValues?.idPolicies)
            ?.link_policies_objetive.find((item: any) => item._id === defaultValues?.idObjetive)
            ?.link_objetive_programs ?? [],
        projects:
          pdiActive.link_pdi_policies
            ?.find((item: any) => item._id === defaultValues?.idPolicies)
            ?.link_policies_objetive.find((item: any) => item._id === defaultValues?.idObjetive)
            ?.link_objetive_programs.find((item: any) => item._id === defaultValues?.idProgram)
            ?.link_programs_projects ?? [],
        goals:
          pdiActive.link_pdi_policies
            ?.find((item: any) => item._id === defaultValues?.idPolicies)
            ?.link_policies_objetive.find((item: any) => item._id === defaultValues?.idObjetive)
            ?.link_objetive_programs.find((item: any) => item._id === defaultValues?.idProgram)
            ?.link_programs_projects.find((item: any) => item._id === defaultValues?.idProject)?.link_projects_goals ??
          [],
        indicators:
          pdiActive.link_pdi_policies
            ?.find((item: any) => item._id === defaultValues?.idPolicies)
            ?.link_policies_objetive.find((item: any) => item._id === defaultValues?.idObjetive)
            ?.link_objetive_programs.find((item: any) => item._id === defaultValues?.idProgram)
            ?.link_programs_projects.find((item: any) => item._id === defaultValues?.idProject)
            ?.link_projects_goals.find((item: any) => item._id === defaultValues?.idGoal)?.link_goals_indicators ?? [],
      })
    }
  }, [pdiActive])

  useEffect(() => {
    getPdi()
  }, [])

  const getPdi = async () => {
    const response: any = await getPdiActive({})
    if (response._id) {
      setPdiActive(response ?? {})
      return true
    }
    return false
  }

  const handleInputChange = async (e: { target: { name: string; value: string } }) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
    const result = await ValidateFields({
      schema: formGoalPdaSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }

  const handleSelectChangeFilter = async (e: any) => {
    switch (e.target.name) {
      case 'idPolicies':
        setFilterSelects({
          ...filterSelects,
          objetives:
            pdiActive.link_pdi_policies?.find((item: any) => item._id === e.target.value)?.link_policies_objetive ?? [],
        })
        setDataForm({
          ...dataForm,
          idPolicies: e.target.value,
        })
        break

      case 'idObjetive':
        setFilterSelects({
          ...filterSelects,
          programs:
            filterSelects.objetives?.find((item: any) => item._id === e.target.value)?.link_objetive_programs ?? [],
        })
        setDataForm({
          ...dataForm,
          idObjetive: e.target.value,
        })
        break

      case 'idProgram':
        setFilterSelects({
          ...filterSelects,
          projects:
            filterSelects.programs?.find((item: any) => item._id === e.target.value)?.link_programs_projects ?? [],
        })
        setDataForm({
          ...dataForm,
          idProgram: e.target.value,
        })
        break

      case 'idProject':
        setFilterSelects({
          ...filterSelects,
          goals: filterSelects.projects?.find((item: any) => item._id === e.target.value)?.link_projects_goals ?? [],
        })
        setDataForm({
          ...dataForm,
          idProject: e.target.value,
        })
        break
      case 'idGoal':
        setFilterSelects({
          ...filterSelects,
          indicators:
            filterSelects.goals?.find((item: any) => item._id === e.target.value)?.link_goals_indicators ?? [],
        })
        setDataForm({
          ...dataForm,
          idGoal: e.target.value,
          idIndicator:
            filterSelects.goals
              ?.find((item: any) => item._id === e.target.value)
              ?.link_goals_indicators.flatMap((item: any) => {
                return { _id: item._id }
              }) ?? [],
        })
        break

      default:
        break
    }
  }

  const handleCheckedChange = async (checked: boolean) => {
    setDataForm({ ...dataForm, state: checked })
  }

  const handleSubmit = async () => {
    const result = await ValidateForm({
      schema: formGoalPdaSchema,
      data: dataForm,
    })

    if (result.isValid) {
      let resp: any
      if (idGoalPda) {
        resp = await pdaContext?.updateGoalPda({ updateValues: dataForm })
      } else {
        resp = await pdaContext?.createNewGoalPda({ values: dataForm })
        if (resp.ok) {
          setDataForm({ ...dataForm, idPa: pdaContext?.pda?._id, idPdi: pdaContext?.pda?.idPdi?._id })
        }
      }
      if (pdaContext?.pda?._id && resp.ok) {
        pdaContext?.getInitialDataForUpdatePda({ findId: pdaContext?.pda?._id })
        onCancel && onCancel()
      }
    } else {
      setErrors(result.errors)
    }
  }

  const handleChangeInputIndicator = (e: any, indicator: any) => {
    const { idIndicator, ...newData } = dataForm
    const index = idIndicator.findIndex((item: any) => item._id === indicator._id)
    idIndicator[index][e.target.name] = e.target.value
    setDataForm({ ...newData, idIndicator })
  }

  return (
    <>
      {/*******************************/}
      {/* CONTENEDOR FORMULARIO METAS */}
      {/*******************************/}
      <Box component="form" sx={formStyles.container}>
        {/**********/}
        {/* TITULO */}
        {/**********/}
        {/* <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Título</Typography>
          <Box sx={formStyles.input}>
            <InputBase sx={formStyles.inputBase} name="title" onChange={handleInputChange} value={dataForm.title} />
            <ErrorField message={errors['title'] ?? ''} />
          </Box>
        </Box> */}

        <Box>
          {/************/}
          {/* META P.A */}
          {/************/}
          <Box
            sx={{
              ...formStyles.containerTitleHorizontal,
              width: '100%',
              marginRight: { md: '30px', xs: 'inherit' },
              alignItems: 'baseline',
            }}
          >
            <Typography sx={formStyles.titleHorizontal}>Meta P.A</Typography>

            {/***************/}
            {/* INPUT SMALL */}
            {/***************/}
            <Box sx={{ width: '100%' }}>
              <Box sx={{ ...formStyles.inputDescription, minHeight: '100px' }}>
                <InputBase
                  sx={formStyles.inputBaseDescription}
                  multiline
                  maxRows={10}
                  name="title"
                  onChange={handleInputChange}
                  value={dataForm.title}
                />
              </Box>
              <ErrorField message={errors['title'] ?? ''} />
            </Box>
          </Box>

          {/* <Box sx={{ ...formStyles.containerState, mb: '50px !important' }}>
            <Typography sx={formStyles.title}>Estado</Typography> */}

          {/**********************/}
          {/* CHECKBOX DE ESTADO */}
          {/**********************/}
          {/* <FormGroup>
              <FormLabel className="activo" sx={{ color: '#000' }}>
                Activo
                <Checkbox
                  sx={{ marginLeft: '10px' }}
                  checked={dataForm.state ?? defaultValues?.state}
                  onChange={({ target: { checked } }) => handleCheckedChange(true)}
                  disableTouchRipple
                />
              </FormLabel>
              <FormLabel className="inactivo" sx={{ color: '#000' }}>
                Inactivo
                <Checkbox
                  sx={{ marginLeft: '10px' }}
                  checked={!dataForm.state ?? !defaultValues?.state}
                  onChange={({ target: { checked } }) => handleCheckedChange(false)}
                  disableTouchRipple
                />
              </FormLabel>
            </FormGroup>
          </Box> */}

          {/***********/}
          {/* DIVISOR */}
          {/***********/}
          <Divider sx={{ borderColor: '#7282B8', mb: '50px' }} />

          <Typography
            sx={{
              color: '#1F398F',
              fontFamily: 'Poppins',
              fontSize: '25px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              marginBottom: '35px',
            }}
          >
            Relación con indicador
          </Typography>

          {/************/}
          {/* POLITICA */}
          {/************/}
          <Box sx={{ ...formStyles.containerTitleHorizontal, width: '100%' }}>
            <Typography sx={formStyles.titleHorizontal}>Política</Typography>
            <CustomSelect
              name="idPolicies"
              propValue="_id"
              propText="title"
              items={pdiActive.link_pdi_policies ?? []}
              onChange={handleSelectChangeFilter}
              value={dataForm?.idPolicies ?? ''}
            />
          </Box>

          {/************/}
          {/* OBJETIVO */}
          {/************/}
          <Box sx={{ ...formStyles.containerTitleHorizontal, width: '100%' }}>
            <Typography sx={formStyles.titleHorizontal}>Objetivo</Typography>
            <CustomSelect
              name="idObjetive"
              propValue="_id"
              propText="title"
              items={filterSelects.objetives ?? []}
              onChange={handleSelectChangeFilter}
              value={dataForm?.idObjetive ?? ''}
              disabled={!filterSelects?.objetives}
            />
          </Box>

          {/************/}
          {/* PROGRAMA */}
          {/************/}
          <Box sx={{ ...formStyles.containerTitleHorizontal, width: '100%' }}>
            <Typography sx={formStyles.titleHorizontal}>Programa</Typography>
            <CustomSelect
              name="idProgram"
              propValue="_id"
              propText="title"
              items={filterSelects.programs ?? []}
              onChange={handleSelectChangeFilter}
              value={dataForm?.idProgram ?? ''}
              disabled={!filterSelects?.programs}
            />
          </Box>

          {/************/}
          {/* PROYECTO */}
          {/************/}
          <Box sx={{ ...formStyles.containerTitleHorizontal, width: '100%' }}>
            <Typography sx={formStyles.titleHorizontal}>Proyecto</Typography>
            <CustomSelect
              name="idProject"
              propValue="_id"
              propText="title"
              items={filterSelects.projects ?? []}
              onChange={handleSelectChangeFilter}
              value={dataForm?.idProject ?? ''}
              disabled={!filterSelects?.projects}
            />
          </Box>

          {/***************************/}
          {/* META PLAN DE DESARROLLO */}
          {/***************************/}
          <Box sx={{ ...formStyles.containerTitleHorizontal, width: '100%', mb: '50px !important' }}>
            <Typography sx={formStyles.titleHorizontal}>Meta del PDI</Typography>
            <CustomSelect
              name="idGoal"
              propValue="_id"
              propText="title"
              items={filterSelects.goals ?? []}
              onChange={handleSelectChangeFilter}
              value={dataForm?.idGoal ?? ''}
              disabled={!filterSelects.goals}
            />
          </Box>
        </Box>

        {/* CARD LIST TABLA */}
        <Paper
          sx={{
            width: 'auto',
            overflow: 'hidden',
            boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
            borderRadius: '25px',
            padding: '54px 64px',
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    '>.celdaIndicador': { width: '400px !important' },
                    '>th': {
                      width: '101px',
                    },
                  }}
                >
                  <TableCell className="celdaIndicador">Indicadores</TableCell>
                  <TableCell>Meta P.D.I</TableCell>
                  <TableCell>Meta P.A</TableCell>
                  <TableCell>T. Inferior</TableCell>
                  <TableCell>T. Superior</TableCell>
                  <TableCell>Cumplimiento</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filterSelects.indicators?.map((item: any) => (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell>{item.name}</TableCell>

                    <TableCell>
                      <Input name="" value={item.goal} disabled />
                    </TableCell>

                    <TableCell>
                      <Input
                        name="goal"
                        value={dataForm.idIndicator?.find((element: any) => element._id === item._id)?.goal}
                        onChange={e => handleChangeInputIndicator(e, item)}
                      />
                    </TableCell>

                    <TableCell>
                      <Input
                        name="toleranceLower"
                        value={dataForm.idIndicator?.find((element: any) => element._id === item._id)?.toleranceLower}
                        onChange={e => handleChangeInputIndicator(e, item)}
                      />
                    </TableCell>

                    <TableCell>
                      <Input
                        name="toleranceUpper"
                        value={dataForm.idIndicator?.find((element: any) => element._id === item._id)?.toleranceUpper}
                        onChange={e => handleChangeInputIndicator(e, item)}
                      />
                    </TableCell>

                    <TableCell>
                      <Input name="" value={item.goal} disabled />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {idGoalPda && (
          <>
            <Box sx={{ ...formStyles.containerState, mt: '20px' }}>
              <Typography sx={formStyles.title}>Estado</Typography>

              {/**********************/}
              {/* CHECKBOX DE ESTADO */}
              {/**********************/}
              <FormGroup>
                <FormLabel className="activo" sx={{ color: '#000' }}>
                  Activo
                  <Checkbox
                    sx={{ marginLeft: '10px' }}
                    checked={dataForm.state ?? defaultValues?.state}
                    onChange={({ target: { checked } }) => handleCheckedChange(true)}
                    disableTouchRipple
                  />
                </FormLabel>
                <FormLabel className="inactivo" sx={{ color: '#000' }}>
                  Inactivo
                  <Checkbox
                    sx={{ marginLeft: '10px' }}
                    checked={!dataForm.state ?? !defaultValues?.state}
                    onChange={({ target: { checked } }) => handleCheckedChange(false)}
                    disableTouchRipple
                  />
                </FormLabel>
              </FormGroup>
            </Box>

            {/********************/}
            {/* MENSAJE DE ERROR */}
            {/********************/}
            <ErrorField message={errors.state?.message} />
          </>
        )}

        {/**********************/}
        {/* CONTENEDOR BOTONES */}
        {/**********************/}
        <Box sx={formStyles.divButton}>
          {/**************************/}
          {/* BOTONES CREAR/CANCELAR */}
          {/**************************/}
          <div>
            {/***************/}
            {/* BOTON CREAR */}
            {/***************/}
            <Button disableTouchRipple type="button" sx={formStyles.buttonSave} onClick={handleSubmit}>
              Crear
            </Button>

            {/******************/}
            {/* BOTON CANCELAR */}
            {/******************/}
            <Button disabled={disabledButtonCancel} disableTouchRipple sx={formStyles.buttonCancel} onClick={onCancel}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default FormGoalPda
