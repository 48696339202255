import { Box, Typography, Button, Pagination } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModal from '../../../../components/CustomModal'
import IconDirection from '../../../../components/icons/IconDirection'
import ConfirmDeleteContent from '../../../../components/utilities/ConfirmDeleteContent'
import Spinner from '../../../../components/utilities/Spinner'
import useDepartament from '../../../../hooks/departament/useDepartament'
import InfoProcess from '../process/InfoProcess'
import tableProcessStyles from '../process/tableProcess.styles'
import InfoArea from './InfoArea'
import tableAreaStyles from './tableArea.styles'

const TableAreas = () => {
  const navigate = useNavigate()
  const { fetchDepartaments, fetchDeleteDepartament } = useDepartament()
  const [departaments, setDepartaments] = useState<any>([])
  const [from, setForm] = useState<number>()
  const [limit, setLimit] = useState<number>(10)
  const [loading, setLoading] = useState<boolean>(false)
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)

  const list = async () => {
    setLoading(true)
    const response: any = await fetchDepartaments({
      limit: limit,
      from: from,
    })
    setDepartaments(response.items)
    const pages = response.total / 10

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setForm((value - 1) * limit)
    setPage(value)
  }

  useEffect(() => {
    list()
  }, [page])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* CONTENEDOR CARD PDI */}
      <Box sx={tableAreaStyles.container}>
        {/************
                    CARD PDI
                *************/}
        {loading ? (
          <Spinner />
        ) : (
          departaments.map((item: any, index: number) => (
            <Box key={index} sx={{ ...tableAreaStyles.card }}>
              {/*******************
                                BOTON INFO
                            *********************/}
              <Box sx={tableProcessStyles.title}>
                <Button disableRipple>
                  <CustomModal text={<IconDirection />} maxHeight="869px">
                    <InfoArea />
                    {/* <InfoProcess id={item._id} /> */}
                  </CustomModal>
                </Button>
              </Box>

              {/***************************
                                NOMBRE Y FECHA CARD PDI
                            ****************************/}
              <Box sx={tableAreaStyles.nameAndDescription}>
                {/*******************
                                    NOMBRE CARD PDI
                                ********************/}
                <Typography sx={tableAreaStyles.name}>{item.name}</Typography>

                {/*******************
                                    FECHA CARD PDI
                                ********************/}
                <Typography sx={tableAreaStyles.description}>{item.description}</Typography>
              </Box>

              {/**********************
                                CONTENEDOR BOTONES 
                            ***********************/}
              <Box sx={tableAreaStyles.buttonsContainer}>
                {/**********************************
                                    BOTONES ACTUALIZAR/DESACTIVAR
                                ***********************************/}
                <div>
                  {/********************
                                        BOTON ACTUALIZAR
                                    *********************/}
                  <Button
                    disableTouchRipple
                    onClick={() => navigate(`/areas/update/${item._id}`)}
                    sx={{
                      backgroundColor: '#1F398F1A',
                      ':hover': {
                        backgroundColor: '#1F398F1A',
                      },
                    }}
                  >
                    Editar
                  </Button>

                  {/********************
                                        BOTON DESACTIVAR
                                    *********************/}
                  <CustomModal
                    maxWidth={600}
                    maxHeight={350}
                    text={
                      <Button
                        disableTouchRipple
                        sx={{
                          backgroundColor: theme => theme.palette.secondary.main,

                          // HOVER
                          ':hover': {
                            backgroundColor: theme => theme.palette.secondary.main,
                          },

                          '>a': {
                            paddingRight: '0px !important',
                            textDecoration: 'none',
                            ':hover': {
                              color: 'inherit',
                            },
                          },
                        }}
                      >
                        Eliminar
                      </Button>
                    }
                    stateOpen={openModalDelete}
                  >
                    <ConfirmDeleteContent
                      setOpen={setOpenModalDelete}
                      open={openModalDelete}
                      id={item._id}
                      list={list}
                      fetchDelete={fetchDeleteDepartament}
                      message="¿Estas seguro de eliminar esta area?"
                    />
                  </CustomModal>
                </div>
              </Box>
            </Box>
          ))
        )}
      </Box>

      {/****************
                PAGINACION
            ****************/}
      <Box sx={tableAreaStyles.pagesList}>
        <Pagination count={pages} page={page} onChange={changePage} />
      </Box>
    </Box>
  )
}

export default TableAreas
