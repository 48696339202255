import { useCallback } from 'react'
import customAlerts from '../../helpers/customAlerts'
import useFetch, { BasicFetchListProps } from '../useFetch'

const { loadAlert, successAlert, closeLoading, errorAlert } = customAlerts

const usePosition = () => {
  const { fetchList, fetchCreate } = useFetch()

  const fetchGetPositions = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getPositions',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
        .then(res => {
          return res
        })
        .catch(error => {
          errorAlert({ html: error?.message })
        })
        .finally(() => closeLoading())
    },
    [fetchList]
  )

  const fetchCreatePosition = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      loadAlert({ html: 'Creando cargo, por favor, espera...' })
      return await fetchCreate({ action: 'createPosition', body })
        .then(_id => {
          successAlert({
            html: 'Cargo creado exitosamente!.',
            didClose: () => {
              done && done(_id)
            },
          })
          return {
            ok: true,
          }
        })
        .catch(error => {
          errorAlert({ html: error?.message })
          return {
            ok: false,
          }
        })
        .finally(() => closeLoading())
    },
    [fetchCreate]
  )

  return { fetchGetPositions, fetchCreatePosition }
}

export default usePosition
