import { FunctionComponent } from "react";
import { Button, TextField, Typography, Box } from "@mui/material";

// IMPORTADOS
import formChangePasswordStyles from "./formChangePassword.styles";

interface FormChangePasswordProps {}

const FormChangePassword: FunctionComponent<FormChangePasswordProps> = () => {
    return (
        <>
            {/*******************************
                TITULO CAMBIAR CONTRASEÑA
            ********************************/}
            <Typography variant="h2" sx={formChangePasswordStyles.title}>
                Cambiar contraseña
            </Typography>

            <Box sx={formChangePasswordStyles.form}>
                {/**************************
                    INPUT TEXTFIELD CORREO
                ***************************/}
                <Box>
                    <TextField
                        fullWidth
                        label="Correo electronico"
                        name="email"
                        placeholder="Digite su correo electronico"
                        type="text"
                        variant="standard"
                    />
                </Box>
            </Box>

            {/*********
                TEXTO 
            **********/}
            <Typography sx={formChangePasswordStyles.text}>
                Un Email será enviado a su cuenta de correo electrónico para
                cambiar su contraseña de acceso a Aplanner.
            </Typography>

            {/***************************
                CONTENEDOR BOTON ENVIAR 
            ****************************/}
            <Box sx={formChangePasswordStyles.contentButton}>
                <Button disableTouchRipple variant="contained" type="submit">
                    Enviar
                </Button>
            </Box>
        </>
    );
};

export default FormChangePassword;
