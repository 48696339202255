import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconUpload from "../../../components/icons/IconUpload";
import IconX from "../../../components/icons/IconX";
import customAlerts from "../../../helpers/customAlerts";
import formUsersStyles from "./formUsers.styles";
import formStyles from "../../../components/forms/form.styles";

interface UploadImageProps { 
    img: any;
    setImg: Function;
}

const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg"];

const UploadImage = ({ img, setImg }: UploadImageProps) => {
    const { errorAlert } = customAlerts;

    const [dropAreaStyles, setDropAreaStyles] = useState({
        ...formUsersStyles.imagen,
    });
    const [drag, setDrag] = useState(false);

    useEffect(() => {
        const dropArea = document.querySelector("#drop-area");

        // Evento dragover - acciones que debe hacer al detectar que entra el archivo dentro del area
        dropArea?.addEventListener("dragover", (e: any) => {
            e.preventDefault();
            setDropAreaStyles({
                ...dropAreaStyles,
                ...formUsersStyles.dropAreaActive,
            });
            setDrag(true);
        });

        // Evento dragleave - acciones que debe hacer al detectar que sale el archivo dentro del area
        dropArea?.addEventListener("dragleave", (e: any) => {
            e.preventDefault();
            setDropAreaStyles({ ...formUsersStyles.imagen });
            setDrag(false);
        });

        // Evento Drop - Accciones que debe hacer al soltar el archivo dentro del area
        dropArea?.addEventListener("drop", (e: any) => {
            e.preventDefault();
            setDrag(false);
            setDropAreaStyles({ ...formUsersStyles.imagen });

            previewImg(e.dataTransfer.files[0]);
            e.target.value = null;
        });
    }, []);

    const previewImg = (image: any) => {
        if (allowedTypes.some((value: string) => value === image.type)) {
            const img = { preview: URL.createObjectURL(image), file: image };
            setImg(img);
        } else {
            errorAlert({ html: "Tipo de archivo no permitido ❌" });
        }
    };

    

    return (
        <Box sx={formStyles.containerGrid}>
            {/*********************
        CONTENEDOR IMAGEN
    **********************/}
            <Box
                sx={{
                    ...formUsersStyles.containerImage,
                    marginTop: "20px !important",
                }}
            >
                {/**********
            TITULO
        ************/}
                <Box
                    sx={{
                        ...formUsersStyles.title,
                        margin: "0px auto",
                    }}
                >
                    <Typography>Imagen</Typography>
                </Box>

                {/************
            IMAGEN 
        ************/}
                {img?.preview ? (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                }}
                            >
                                <IconButton onClick={() => setImg(undefined)}>
                                    <IconX width={20} height={20} color="red" />
                                </IconButton>
                            </Box>
                            <Box>
                                <img
                                    alt="Imagen"
                                    src={img?.preview && img?.preview}
                                    width="250"
                                />
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={dropAreaStyles} id="drop-area">
                        <Button
                            disableTouchRipple
                            variant="contained"
                            component="label"
                            id="buttonImg"
                            style={{ color: "white" }}
                        >
                            <Box>
                                <input
                                    hidden
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    onChange={(e: any) => {
                                        previewImg(e.target.files[0]);
                                    }}
                                />

                                {/********************** 
                        ICONO CARGA/SUBIDA 
                    ***********************/}
                                <div>
                                    <IconUpload
                                        color={drag ? "#fff" : "#7282B8"}
                                    />
                                </div>

                                <Typography
                                    style={
                                        drag ? { color: "white" } : undefined
                                    }
                                >
                                    Agregar imagen
                                </Typography>
                                <span
                                    style={
                                        drag ? { color: "white" } : undefined
                                    }
                                >
                                    o arrastra y suelta
                                </span>
                            </Box>
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default UploadImage;
