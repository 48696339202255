import { useCallback } from "react";
import customAlerts from "../../../helpers/customAlerts";
import useFetch, { BasicFetchListProps } from "../../useFetch";

const { loadAlert, successAlert, closeLoading, errorAlert } = customAlerts

const useSubProcess = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchFindOne, fetchDisable } = useFetch()

  const fetchGetSubProcess = useCallback(async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
    return fetchList<any>({
      action: 'getSubProcess',
      params: [
        ['limit', `${limit}`],
        ['active', `${active}`],
        ['from', `${from}`]
      ]
    })
      .then(res => {
        return res
      })
      .catch((error) => {
        errorAlert({ html: "Contactar con soporte 🚫" })
      })
  }, [fetchList])

  const fetchGetOneSubProcess = useCallback(async (id: string) => {
    return fetchFindOne<any>({
      action: 'getOneSubProcess',
      findId: id,
    })
      .then(res => {
        return res
      })
      .catch((error) => {
        errorAlert({ html: "Contactar con soporte 🚫" })
      })
      .finally(() => closeLoading())
  }, [fetchFindOne])

  const fetchUpdateSubProcess = useCallback((body: any, done?: (_id: string) => void) => {
    loadAlert({ html: 'Actualizando Subproceso, por favor, espera...' })
    fetchUpdate({ action: 'updateSubProcess', body })
      .then(_id => {
        successAlert({
          html: 'Subproceso actualizado exitosamente!.',
          didClose: () => {
            done && done(_id)
          }
        })
      })
      .catch((error) => {
        errorAlert({ html: "Contactar con soporte 🚫" })
      })
      .finally(() => closeLoading())
  }, [fetchUpdate])

  const fetchDeleteSubProcess = useCallback((body: any, done?: () => void) => {
        loadAlert({ html: `Eliminando el subproceso, por favor, espera...` })
        fetchDisable({ action: 'disableSubProcess', body })
            .then(() => {
                successAlert({
                    text: `Subproceso eliminado correctamente`,
                    didClose: () => done && done()
                })
            })
            .catch(error => errorAlert({ html: "Contactar con soporte 🚫" }))
            .finally(() => closeLoading())
    }, [fetchDisable])

  return {
    fetchGetSubProcess,
    fetchGetOneSubProcess,
    fetchUpdateSubProcess,
    fetchDeleteSubProcess,
  }
}

export default useSubProcess
