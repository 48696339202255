import React, { useEffect } from 'react'
import { Box, Typography, List, ListItem, ListItemButton, ListItemText, Pagination, Button } from '@mui/material'

// IMPORTADOS
import taskAssignment from './TaskAssignment.styles'
import TaskAssignmentTabs from './TaskAssignmentTabs'
import IconCalendarCheck from '../../../../components/icons/IconCalendarCheck'
import IconUser2 from '../../../../components/icons/IconUser2'
import IconUserNotification from '../../../../components/icons/IconUserNotification'
import IconClock from '../../../../components/icons/IconClock'
import WhoNotifyPopopver from './tabsTaskAssignment/WhoNotifyPopover'
import IconOpenEye from '../../../../components/icons/IconOpenEye'
import { useNavigate } from 'react-router-dom'
import CustomTitle from '../../../../components/CustomTitle'

const TaskAssignment = () => {
  const navigate = useNavigate()
  // PAGINACION
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(1)
  const [tasksAssignment, setTasksAssignment] = React.useState([])
  const [historyTasksAssignment, setHistoryTasksAssignment] = React.useState({})
  const listPerPage = 4

  useEffect(() => {
    const pages = tasksAssignment.length / listPerPage

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
  }, [page, tasksAssignment])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box sx={taskAssignment.container}>
      {/*******************************/}
      {/* TITULO ASIGNACION DE TAREAS */}
      {/*******************************/}
      <CustomTitle title="Asignación de Responsabilidades" display={'none'}>
        {/**************************/}
        {/* BOTON FILTRAR POR SEDE */}
        {/**************************/}
        {/* <FilterPdi
            getOptionLabel={option => option.title}
            id="filter-goal-by-project"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            labelInput="Sede"
            noOptionsText="Sin resultados"
            options={projects}
            onChange={(e, value) => changeFilterOption(value)}
            placeholder="Buscar proyecto..."
            value={filterOption}
            minWidth={176}
            disabled
          /> */}

        {/*************/}
        {/* BOTON MÁS */}
        {/*************/}
        <Button disableTouchRipple onClick={() => navigate('assignment')}>
          <IconOpenEye width={11} height={11} color="#1F398F" />

          <Typography>ver asignaciones</Typography>
        </Button>
      </CustomTitle>

      {/*****************************/}
      {/* LISTA FACTORES/RELACIONES */}
      {/*****************************/}
      <Box sx={taskAssignment.content}>
        {/*****************/}
        {/* CONTENIDO PDI */}
        {/*****************/}
        <TaskAssignmentTabs
          tasksAssignment={tasksAssignment}
          setTasksAssignment={setTasksAssignment}
          historyTasksAssignment={historyTasksAssignment}
          setHistoryTasksAssignment={setHistoryTasksAssignment}
        />

        {/************************/}
        {/* CONTENIDO ASIGNACION */}
        {/************************/}
        <Box sx={taskAssignment.contentCard}>
          {/*********************/}
          {/* TITULO ASIGNACION */}
          {/*********************/}
          <Box className="titleButtonSearch" sx={taskAssignment.titleButtonSearch}>
            <Typography>Asignaciones</Typography>
          </Box>

          <Box className="containerList">
            <List>
              {tasksAssignment.slice((page - 1) * listPerPage, listPerPage * page).map((item: any, index: any) => (
                <ListItem key={index}>
                  <ListItemButton disableTouchRipple sx={{ display: 'block' }}>
                    {/************************/}
                    {/* NOMBRE DEL INDICADOR */}
                    {/************************/}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconCalendarCheck width={20} height={20} color="#1F398F" />
                      <ListItemText primary={item.indicator?.name} sx={{ marginLeft: '10px' }} />
                    </Box>

                    {/************************/}
                    {/* NOMBRE DE QUIEN MIDE */}
                    {/************************/}
                    {item.who_measure?.name && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconUser2 width={20} height={20} color="#1F398F" />
                        <ListItemText
                          secondary={`${item.who_measure?.name} ${item.who_measure?.lastName}`}
                          sx={{ marginLeft: '10px' }}
                        />
                      </Box>
                    )}

                    {/*******************************/}
                    {/* NOMBRE DE LOS QUE NOTIFICAN */}
                    {/*******************************/}
                    {item.who_notify?.length > 0 && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconUserNotification width={20} height={20} color="#1F398F" />
                        {item.who_notify?.slice(0, 3).map((subItem: any) => (
                          <Box sx={{ marginLeft: '10px' }}>
                            <ListItemText secondary={`${subItem.name},`} />
                          </Box>
                        ))}

                        {/************************************************************/}
                        {/* LISTA DESPLEGABLE DE TODOS LOS USUARIOS A QUIEN NOTIFICA */}
                        {/************************************************************/}
                        {item.who_notify?.length > 3 && (
                          <Box
                            sx={{
                              marginLeft: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ListItemText secondary={`...`} />
                            <WhoNotifyPopopver whoNotify={item.who_notify} indexInicial={0} />
                          </Box>
                        )}
                      </Box>
                    )}

                    {/******************************************/}
                    {/* FECHA DE INICIACION DE RESPONSABILIDAD */}
                    {/******************************************/}
                    {item.date && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconClock width={20} height={20} color="#1F398F" />
                        <ListItemText secondary={`${item.date ?? ''}`} sx={{ marginLeft: '10px' }} />
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <Box>
              {/* <ButtonSearch data={} fieldSearch='' setData={} /> */}
              {pages > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemText secondary={`pagina ${page} de ${pages}`} sx={{ marginLeft: '10px' }} />
                </Box>
              )}
              <Pagination count={pages} page={page} onChange={changePage} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TaskAssignment
