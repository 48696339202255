import { StyleComponent } from '../../interfaces/styleComponent'

type Keys = 'containerGraphic' | 'titleGraphics' | 'containerGraphicDonut' | 'containerGraphicPie'

const customGraphicStyles: StyleComponent<Keys> = {
  containerGraphic: {
    backgroundColor: '#FFFFFF',
    maxWidth: '930px',
    width: '930px',
    borderRadius: '25px',
    padding: '50px',
    marginBottom: '100px',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
    // borderTopRightRadius

    '@media(max-width: 1530px)': {
      width: '830px',
    },
    '@media(max-width: 1440px)': {
      width: '730px',
    },
    '@media(max-width: 1330px)': {
      width: '630px',
    },

    // TITULO
    '>.titulo': {
      '>p': {
        color: '#1F398F',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '25px',
        fontWeight: 500,
      },
      '>button': {
        minWidth: 'fit-content',
        padding: '0px',
        float: 'right',
        ':hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  titleGraphics: {
    color: '#1F398F',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '25px',
    fontWeight: 500,
  },

  // GRAFICAS PASTEL
  containerGraphicPie: {
    backgroundColor: '#fff',
    borderRadius: '25px',
    padding: '44px 25px 0px 25px',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
    overflow: 'hidden',
    height: 'fit-content',
  },

  // GRAFICA DONA
  containerGraphicDonut: {
    // backgroundColor: 'pink',
    height: '165px',

    // SCROLL
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '12px',
      size: '10px',
      '&-track': {
        backgroundColor: theme => theme.palette.secondary.main,
        borderRadius: '10px',
        border: '5px solid #ffffff',
      },
      '&-thumb': {
        backgroundColor: '#7282B8',
        borderRadius: '20px',
        width: '10px',
      },
    },

    '>div': {
      marginBottom: '10px',
      '>div': {
        borderRadius: '50%',
        height: 18,
        width: 18,
        mr: 1,
      },
      '>h6': {
        color: '#000',
        // fontafmily: "Poppins",
        fontSize: '15px',
        fontHeight: '400',
      },
    },
  },
}

export default customGraphicStyles
