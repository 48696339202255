import { FunctionComponent, useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";

// IMPORTADOS
import tableItemsPdiStyles from "../tableItemsPdiStyles";
import { Goal } from "../../../../interfaces/pdi/goal";
import { IndicatorModel } from "../../../../interfaces/pdi/indicator";
import { MeasureUnit } from "../../../../interfaces/pdi/measureUnit";
import usePagination from "../../../../hooks/usePagination";
import { MeasurementFrecuency } from "../../../../interfaces/pdi/measurementFrecuency";
import { Trend } from "../../../../interfaces/pdi/trend";
import CardItemPdi from "../../../cards/cardItemPdi/CardItemPdi";
import IconDirection from "../../../icons/IconDirection";
import ModalDetailItemPdi, {
    ModalDetailItemPdiProps,
} from "../../../modals/modalDetailItemPdi/ModalDetailItemPdi";
import ConfirmAlert, {
    ConfirmAlertprops,
} from "../../../Alerts/confirmAlert/ConfirmAlert";
import { Type } from "../../../../interfaces/pdi/types";
import paginationStyles from "../../../Pagination.styles";

interface TableIndicatorsProps {
    filterOption: Goal | null;
    indicators: IndicatorModel[];
    goals: Goal[];
    measureUnits: MeasureUnit[];
    measurementFrecuencies: MeasurementFrecuency[];
    trends: Trend[];
    types: Type[];
    onUpdate: (indicator: IndicatorModel) => void;
    onDelete: (indicator: IndicatorModel) => void;
}

const itemsPerPage = 9;

const TableIndicators: FunctionComponent<TableIndicatorsProps> = ({
    indicators,
    goals,
    measureUnits,
    measurementFrecuencies,
    trends,
    types,
    filterOption,
    onUpdate,
    onDelete,
}) => {
    const { countPage, currentPage, currentData, handleChangePage } =
        usePagination(indicators, itemsPerPage);

    const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>(
        {
            show: false,
            confirmMessage: "Estas seguro que deseas eliminar este indicador?",
            confirmMessageBottom:
                "Recuerde que si elimina este indicador usted no podrá editarla",
            textButtonActionConfirm: "Si",
            textButtonActionCancel: "No",
        }
    );

    const [detailModalData, setDetailModalData] =
        useState<ModalDetailItemPdiProps>({
            show: false,
            itemsRender: [],
            textButtonAction: "Editar",
        });

    const handleConfirmAlertData = (indicator: IndicatorModel) => {
        setConfirmAlertData((state) => ({
            ...state,
            show: true,
            onClickButtonActionConfirm: () => {
                setConfirmAlertData((state) => ({ ...state, show: false }));
                onDelete(indicator);
            },
        }));
    };

    const handleDetailModal = (
        indicator: IndicatorModel,
        itemsRender: ModalDetailItemPdiProps["itemsRender"]
    ) => {
        setDetailModalData((state) => ({
            ...state,
            show: true,
            title: `Detalles del indicador`,
            itemsRender,
            onClickButtonAction: () => {
                setDetailModalData((state) => ({ ...state, show: false }));
                onUpdate(indicator);
            },
        }));
    };

    const getNameGoal = (id: string) => {
        const goal = goals.find((goal) => goal._id === id && goal.is_active);
        if (!goal) return "No disponible.";
        return goal.title;
    };

    const getNameTrend = (id: string) => {
        const trend = trends.find(
            (trend) => trend._id === id && trend.is_active
        );
        if (!trend) return "No disponible.";
        return trend.name;
    };

    const getMeasureUnitName = (id: string) => {
        const measureUnit = measureUnits.find(
            (measureUnit) => measureUnit._id === id && measureUnit.is_active
        );
        if (!measureUnit) return "No disponible.";
        return measureUnit.name;
    };

    const getMeasurementFrecuencyName = (id: string) => {
        const measurementFrecuency = measurementFrecuencies.find(
            (measurementFrecuency) =>
                measurementFrecuency._id === id &&
                measurementFrecuency.is_active
        );
        if (!measurementFrecuency) return "No disponible.";
        return measurementFrecuency.name;
    };

    const getNameType = (id: string) => {
        const type = types.find((type) => type._id === id && type.is_active);
        if (!type) return "No disponible";
        return type.name;
    };

    useEffect(() => {
        handleChangePage(1);
    }, [filterOption?._id]);

    return (
        <>
            <ConfirmAlert
                {...confirmAlertData}
                onClickButtonActionCancel={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
                onCloseForButtom={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
            />
            <ModalDetailItemPdi
                {...detailModalData}
                maxWidth={1067}
                maxHeight={1166}
                onCloseForButtom={() =>
                    setDetailModalData((state) => ({ ...state, show: false }))
                }
            />
            {/***************************
                CONTENEDOR INDICADORES
            ***************************/}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        ...tableItemsPdiStyles.container,
                        justifyContent:
                            currentData().length === 2
                                ? "center !important"
                                : "space-around",
                    }}
                >
                    {/*****************
                        CARD INDICADORES
                    ******************/}
                    {currentData().map((indicator) => (
                        <CardItemPdi
                            key={`${indicator._id}`}
                            title={indicator.name}
                            text={getNameGoal(indicator.idGoal)}
                            smallText={indicator.state ? "Activo" : "Inactivo"}
                            textButtonLeft="Editar"
                            textButtonRight="Eliminar"
                            textButtonDetail={<IconDirection />}
                            onClickButtonDetail={() =>
                                handleDetailModal(indicator, [
                                    {
                                        title: "Nombre de la meta",
                                        value: getNameGoal(indicator.idGoal),
                                    },
                                    {
                                        title: "Nombre",
                                        value: indicator.name,
                                    },
                                    {
                                        title: "Tipo de indicador",
                                        value: getNameType(indicator.idType),
                                    },
                                    {
                                        title: "Tendencia",
                                        value: getNameTrend(indicator.idTrend),
                                    },
                                    {
                                        title: "Unidad de medida",
                                        value: getMeasureUnitName(
                                            indicator.idMeasureUnit
                                        ),
                                    },
                                    {
                                        title: "Frecuencia de medición",
                                        value: getMeasurementFrecuencyName(
                                            indicator.idMeasurementFrecuency
                                        ),
                                    },
                                    {
                                        title: "Formula",
                                        value: indicator.formula,
                                    },
                                    {
                                        title: "Tolerancia superior",
                                        value: indicator.toleranceUpper,
                                    },
                                    {
                                        title: "Tolerancia inferior",
                                        value: indicator.toleranceLower,
                                    },
                                    {
                                        title: "Observaciones",
                                        value: indicator.observation,
                                    },
                                    {
                                        title: "Meta",
                                        value: indicator.goal,
                                    },
                                    {
                                        title: "Estado",
                                        value: indicator.state
                                            ? "Activo"
                                            : "Inactivo",
                                    },
                                    // {
                                    //     title: 'Porcentaje de cumplimiento',
                                    //     value: `${indicator.percentageOfCompletion}%`
                                    // }
                                ])
                            }
                            onClickButtonLeft={() => onUpdate(indicator)}
                            onClickButtonRight={() =>
                                handleConfirmAlertData(indicator)
                            }
                        />
                    ))}
                    {currentData().length == 1 && (
                        <>
                            <Box />
                            <Box />
                        </>
                    )}
                    {currentData().length == 2 && <Box />}
                </Box>

                {/****************
                    PAGINACION
                ****************/}
                <Box sx={tableItemsPdiStyles.pagesList}>
                    <Pagination
                        sx={paginationStyles.paginationNumbers}
                        page={currentPage}
                        defaultPage={1}
                        count={countPage}
                        onChange={(e, _page) => handleChangePage(_page)}
                    />
                </Box>
            </Box>
        </>
    );
};

export default TableIndicators;
