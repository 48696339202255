import React, { useEffect, useState } from 'react'
import { Box, Button, Pagination, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// IMPORTADOS
import usersStyles from '../../users/users.styles'
import Spinner from '../../../../components/utilities/Spinner'
import useMeasure from '../../../../hooks/do/useMeasure'
import { dateToString } from '../../../../services/ParseDate'

const DoAssignment = () => {
  const [list, setList] = useState<any[]>([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { fetchGetMyAssignments } = useMeasure()
  const navigate = useNavigate()

  const getList = async () => {
    setLoading(true)
    const response: any = await fetchGetMyAssignments({ limit, from })
    setList(response.items)
    const pages = response.total / limit

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    getList()
  }, [page])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  const getColor = (value: number, goal: number, ts: number, ti: number) => {
    let color = '#D94A4A'
    const compliancePercentage = (Number(value ?? 0) * 100) / Number(goal ?? 0)
    console.log(value, goal, ts, ti, compliancePercentage)
    if (compliancePercentage < ti) {
      color = '#D94A4A'
    } else if (compliancePercentage >= ti && compliancePercentage < ts) {
      color = '#FCCC64'
    } else if (compliancePercentage >= ts) {
      color = '#7EBD52'
    } else {
      color = '#ffffff'
    }

    return color
  }

  return (
    <>
      {/***********************************
        CONTENEDOR ASIGNACION DE HACER
      *************************************/}
      <Box sx={usersStyles.container}>
        {/**********************************
          CONTENEDOR TITULO Y BOTON CREAR
        ***********************************/}
        <Box sx={usersStyles.title}>
          {/*********************************
            TITULO ASIGNACION DE HACER
          **********************************/}
          <Typography>Asignación</Typography>

          {/**************************
            BOTONES CREAR Y BUSCAR
          ***************************/}
          {/* <Box>
            <Button
                className="buttonCreate"
                href="users/create"
                disableTouchRipple
            >
                <IconAdd />

                <Typography>Crear nuevo usuario</Typography>
            </Button>
        </Box> */}
        </Box>

        {/***********************************
          TABLA DE ASIGNACION DE HACER
        ************************************/}
        <Box sx={usersStyles.userTable}>
          <table>
            {/***********
              TITULOS
            ************/}
            <thead>
              <tr>
                <th>Nombre del indicador</th>
                <th>Meta PA</th>
                <th>Frecuencia de medición</th>
                <th>Fecha de Iniciación</th>
                <th></th>
              </tr>
            </thead>

            {/***********************************
              LISTA DE ASIGNACION DE HACER
            ************************************/}
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} rowSpan={5}>
                    <Box
                      sx={{
                        height: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </Box>
                  </td>
                </tr>
              ) : (
                list.map((row, index) => (
                  <tr key={row._id}>
                    <td>
                      <Box>{row.idIndicator?.name || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Box>{row.idIndicator?.idGoalpaIndicators[0]?.idGoalPa[0]?.title || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <Box>{row.idIndicator?.idMeasurementFrecuency?.name || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Box>{dateToString(row.complianceDate) || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Button
                        onClick={() => navigate(`./indicating/${row._id}`)}
                        disableTouchRipple
                        sx={{
                          backgroundColor: '#7282B8', 
                          borderRadius: '92px',
                          width: '94px',
                          color: '#ffffff !important',
                          marginRight: '20px !important',
                          ':hover': {
                            backgroundColor: '#7282B8',
                          },
                        }}
                      >
                        Calcular
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>

        {/****************
          PAGINACION
        ****************/}
        <Box sx={usersStyles.pagesList}>
          <Pagination count={pages} page={page} onChange={changePage} />
        </Box>
      </Box>
    </>
  )
}

export default DoAssignment
