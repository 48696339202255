import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux';
import { getPersistConfig } from 'redux-deep-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import authReducer from './features/auth/authSlice'


const rootReducer = combineReducers({
  auth : authReducer
})

const perConfig = getPersistConfig({
  key : 'app-aplanner@v1.0',
  version : 1,
  storage,
  blacklist : ['auth.errorMessage'],
  rootReducer
})

const persistedReducer = persistReducer(perConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware : (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck : false})
})

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()