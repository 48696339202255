import { Box, Button, InputBase } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react'
import IconClose from '../../../../../components/icons/IconClose';
import useSubProcess from '../../../../../hooks/pdi/process/useSubprocess';
import formProcessStyles from '../formProcess.styles';
import OpenFormStyles from '../openForm.styles';


interface EditSubProcessProps {
  openForm: any;
  setOpenForm: Function;
  defaultValue: any;
  list: Function;
}

const EditSubProcess = ({ openForm, setOpenForm, defaultValue, list }: EditSubProcessProps) => {
  const [data, setData] = useState<any>({ percentageOfCompletion: 20 });
  const { fetchUpdateSubProcess } = useSubProcess()

  const handleInputChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    await fetchUpdateSubProcess({ ...data });
    list()
    setOpenForm(false);
  }

  useEffect(() => {
    if (defaultValue) {
      const { createdAt, updatedAt, __v, is_active, idProcess, ...dataDefault } = defaultValue;
      setData({ ...dataDefault, idProcess: idProcess._id })
    }
  }, [defaultValue])


  return (
    <>
      {/*****************************
        FORMULARIO DE SUBPROCESO
      *****************************/}
      <Box
        sx={{
          ...formProcessStyles.formSubprocess,
          display: openForm === false ? "none" : "block",
          width: "750px",
          animation:
            openForm === true
              ? "open-animate 0.3s ease"
              : "open-animate 0.3s reverse ease",

          "@keyframes open-animate": {
            "0%": {
              transform: "translateX(750px)",
              animationPlayState: "running",
            },
            "100%": {
              transform: "translateX(0px)",
              animationPlayState: "paused",
            },
          },
        }}
      >
        <Box sx={OpenFormStyles.containerOpenForm}>
          {/***********
                        BOTÓN X
                    ************/}
          <Button
            onClick={(e) => {
              setOpenForm(false);
            }}
            sx={OpenFormStyles.buttonOpenForm}
          >
            <IconClose />
          </Button>

          {/**************
              CONTENIDO
          ***************/}
          <Box
            sx={{
              ...OpenFormStyles.content,
            }}
          >
            {/*************
                                    CONTENIDO
                                **************/}
            <Box sx={OpenFormStyles.title}>
              <Typography>Crear subproceso</Typography>
            </Box>

            {/*************************
                                    NOMBRE DEL SUBPROCESO
                                **************************/}
            <Box
              sx={{
                ...OpenFormStyles.subtitle,
              }}
            >
              <Typography>
                Nombre del subproceso
              </Typography>

              {/* INPUT */}
              <Box sx={OpenFormStyles.input}>
                <InputBase
                  sx={OpenFormStyles.inputBase}
                  name="name"
                  onChange={handleInputChange}
                  value={data?.name ?? ""}
                />
              </Box>
            </Box>

            {/*****************
                    JUSTIFICACION
                ********************/}
            <Box
              sx={{
                ...OpenFormStyles.subtitle,
              }}
            >
              <Typography>Justificación</Typography>

              {/* INPUT */}
              <Box sx={OpenFormStyles.input}>
                <InputBase
                  sx={OpenFormStyles.inputBase}
                  name="justification"
                  onChange={handleInputChange}
                  value={data?.justification ?? ""}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={OpenFormStyles.divButton}>
                <Button
                  className="btnCreate"
                  disableTouchRipple
                  onClick={handleSubmit}
                >
                  Editar
                </Button>
              </Box>
              <Box sx={OpenFormStyles.divButton}>
                <Button
                  className="btnCreate"
                  disableTouchRipple
                  onClick={() => setOpenForm(false)}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EditSubProcess
