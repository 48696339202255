import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'title'
  | 'containerBlock'
  | 'subTitle'
  | 'inputMultiline'
  | 'inputBaseMultiline'
  | 'input'
  | 'inputBase'
  | 'imagen'
  | 'formImage'
  | 'divButton'
  | 'buttonSave'
  | 'buttonCancel'
  | 'containerImage'
  | 'dropAreaActive'

const formCompanyStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    maxWidth: '1350px',
    margin: 'auto',
    backgroundColor: '#ffffff',
  },

  // TITULO Y SUBTITULO
  title: {
    margin: '30px auto',
    width: '1110px',
    '>p': {
      fontSize: '25px',
      color: '#1F398F',
      fontWeight: '600',
      lineHeight: '38px',
      marginBottom: '9px',
    },
  },

  dropAreaActive: {
    border: 'dashed 2px #fff',
    background: '#7282B8',
  },

  // CONTENEDOR BLOCK
  containerBlock: {
    width: '1110px',
    margin: 'auto',
  },

  // SUBTITULOS
  subTitle: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },

  inputMultiline: {
    height: '135px',
    borderRadius: '25px',
    backgroundColor: '#F1F3F8',
  },

  inputBaseMultiline: {
    width: '100%',
    height: '100%',
    fontSize: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '25px',
    borderBottom: '0px solid transparent',
    '>textarea': {
      height: '100% !important',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  input: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },

  inputBase: {
    width: '100%',
    height: '100%',
    fontSize: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
    borderBottom: '0px solid transparent',
    '>div': {
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  // IMAGEN
  containerImage: {
    width: 'inherit',
  },

  imagen: {
    margin: 'auto 0px auto auto',
    backgroundColor: '#F1F3F8',
    height: '312px',
    borderRadius: '25px',
    display: 'flex',
    '>button, >label': {
      backgroundColor: 'inherit',
      width: '100%',
      height: '100%',
      padding: '0px',
      margin: 'auto',
      textAlign: 'center',
      borderRadius: '25px',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'inherit',
        boxShadow: 'none',
      },

      '>div': {
        display: 'block',
        textTransform: 'inherit',
        '>p': {
          color: '#1F398F',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '30px',
        },
        '>span': {
          color: '#7282B8',
          fontFamily: 'Poppins',
          fontSize: '15px',
          lineHeight: '22px',
        },
      },
    },
  },

  formImage: {
    '>div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '>div': {
        display: 'flex',
        alignItems: 'center',
        '>p': {
          maxWidth: '1027px',
          fontSize: '20px',
          marginLeft: '15.93px',
        },
      },
      '>button': {
        padding: '0px',
        minWidth: 'inherit',
        borderRadius: '20px',
      },
    },
    '>button, >label': {
      backgroundColor: '#F1F3F8',
      marginTop: '13px',
      color: '#1F398F',
      fontSize: '15px',
      fontWeight: '400',
      textTransform: 'initial',
      borderRadius: '200px',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#F1F3F8',
        boxShadow: 'none',
      },
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    marginTop: '97px',
    marginX: 'auto',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '169.74px',
      // width: "169.74px",
      height: '33px',
      borderRadius: '20px',
      padding: '10px 20px',

      // TEXTO
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },

  buttonSave: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
}

export default formCompanyStyles
