import { FunctionComponent } from "react";

interface IconChallengeProps {
    width?: number | string;
    height?: number | string;
}

const IconChallenge: FunctionComponent<IconChallengeProps> = ({
    width = 40,
    height = 40,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.2788 15.957L39.4203 40H0L14.6643 10.957V0H24.7559V7.5H17.1872V10.957L23.4945 23.457L27.2788 15.957ZM17.1872 2.5V5H22.233V2.5H17.1872ZM19.0203 20.1758L15.9258 14.043L12.8313 20.1758L15.9258 23.2227L19.0203 20.1758ZM27.7519 37.5L20.2029 22.5195L15.9258 26.7773L11.6487 22.5195L4.09971 37.5H27.7519ZM30.5901 37.5H35.3206L27.2788 21.543L24.9136 26.25L30.5901 37.5Z"
                fill="#7282B8"
            />
        </svg>
    );
};

export default IconChallenge;
