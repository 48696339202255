import { forwardRef, FunctionComponent } from 'react'
import { FormControl, Select, ThemeProvider, createTheme, MenuItem, SelectProps, Theme, useTheme } from '@mui/material'

// IMPORTADOS
import formPoliticsStyles from '../forms/pdi/formPolitics/formPolitics.styles'
import { nombreArea } from '../data/dataAPI'
import formStyles from '../forms/form.styles'

type CustomSelectProps = SelectProps & {
  items: any[]
  title?: string
  propValue: string
  propText: string
}

// TEMA Y ESTILOS
const themeCustomSelect = (): Theme => {
  // eslint-disable-next-line
  const theme: any = useTheme()

  return createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            '>.MuiPaper-elevation': {
              backgroundColor: '#ffffff',
              maxHeight: '270px',
              minHeight: '86px',
              borderRadius: '15px',
              overflowY: 'hidden',

              // UL
              '>ul': {
                backgroundColor: 'inherit',
                maxHeight: '214px',
                // maxWidth: "570px",
                margin: '20px 20px',

                // SCROLL
                overflowY: nombreArea.length > 6 ? 'scroll' : 'hidden',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  size: '10px',
                  '&-track': {
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '10px',
                    border: '5px solid #ffffff',
                  },
                  '&-thumb': {
                    borderRadius: '20px',
                    backgroundColor: theme.palette.primary.main,
                    width: '10px',
                  },
                },

                // LI
                '>li': {
                  color: '#000000',
                  display: 'flex',
                  justifyContent: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  lineHeight: '24px',
                  backgroundColor: 'inherit',
                  ':hover': {
                    color: '#1F398F',
                    fontWeight: '600',
                    backgroundColor: 'inherit',
                  },
                },

                '>.Mui-selected': {
                  backgroundColor: '#F1F3F8',
                  color: '#1F398F',
                  fontWeight: '600',
                  borderRadius: '200px',
                  ':hover': {
                    backgroundColor: '#F1F3F8 !important',
                  },
                },
              },
            },
          },
        },
      },
    },
  })
}

const CustomSelect: FunctionComponent<CustomSelectProps> = forwardRef(
  ({ items, propValue, propText, title = 'Selecciona una opción', ...props }, ref) => {
    // TEMA
    const myTheme = themeCustomSelect()

    return (
      <ThemeProvider theme={myTheme}>
        <FormControl
          error={true}
          sx={{
            ...formStyles.inputSelect,
            '>div>svg>path': { fill: props.disabled ? '#cacaca !important' : '#1F398F' },
          }}
          variant="standard"
        >
          <Select ref={ref} {...props} displayEmpty>
            <MenuItem value="" disableTouchRipple>
              {title}
            </MenuItem>
            {items.map((item, index) => (
              <MenuItem disableTouchRipple key={`custom-select${index}`} value={item[propValue]}>
                {item[propText]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    )
  }
)

export default CustomSelect
