import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams, Navigate } from 'react-router-dom'
import customAlerts from '../../../helpers/customAlerts'
import useAuth from '../../../hooks/useAuth'
// import TabManagerPdi from "../../../components/tabs/tabManagerPdi/TabManagerPdi";
import TabManagerPda from '../../../components/tabs/tabManagerPda/tabManagerPda'
import { Company } from '../../../interfaces/company'
import { BranchOffice } from '../../../interfaces/branchOffice'
import GenericAlert, { GenericAlertProps } from '../../../components/Alerts/GenericAlert/GenericAlert'
import PdaProvider from '../../../components/context/PdaProvider'

const { errorAlert } = customAlerts

const UpdatePda: FunctionComponent = () => {
  const navigate = useNavigate()

  const { id } = useParams()

  const [userCompany, setUserCompany] = useState<Company | null>(null)

  const [userBranchOffice, setUserBranchOffice] = useState<BranchOffice | null>(null)

  const [alertData, setAlertData] = useState<GenericAlertProps>({
    show: false,
  })

  const toPdis = './../../'

  const { userData } = useAuth()

  const errorHandle = (error: any) => {
    setAlertData(state => ({
      ...state,
      show: true,
      title: 'Ooops!',
      text: error?.message || 'Ha ocurrido un error. por favor, contactar con soporte.',
    }))
  }

  useEffect(() => {
    if (!id) {
      navigate(toPdis)
      return
    }

    if (!userData) {
      setAlertData(state => ({
        ...state,
        show: true,
        text: 'Señor usuario, usted no cuenta con un perfíl válido para realizar esta acción. por favor, contactar con soporte.',
      }))
      return
    }

    const { idBranchOffice: _userBranchOffice } = userData.idProfile

    if (!_userBranchOffice) {
      setAlertData(state => ({
        ...state,
        show: true,
        title: 'Acceso denegado!',
        text: 'Señor usuario, usted no cuenta con una sucursal válida para relizar esta acción. por favor, contactar con soporte.',
      }))
      return
    }

    setUserBranchOffice(() => _userBranchOffice)

    const { idCompany: _userCompany } = _userBranchOffice

    if (!_userCompany) {
      setAlertData(state => ({
        ...state,
        show: true,
        title: 'Acceso denegado!',
        text: 'Señor usuario, usted no cuenta con una compañía válida para relizar esta acción. por favor, contactar con soporte.',
      }))
      return
    }

    setUserCompany(() => _userCompany)
  }, [])

  return (
    <>
      <GenericAlert
        {...alertData}
        icon="error"
        title="Acceso denegado!"
        didClose={() => {
          setAlertData(state => ({ ...state, show: false }))
          navigate(toPdis)
        }}
      />
      {userBranchOffice && userCompany && id && (
        <PdaProvider>
          <TabManagerPda updateId={id} />
        </PdaProvider>
      )}
    </>
  )
}

export default UpdatePda
