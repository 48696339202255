import { Box, Button } from '@mui/material'
import React from 'react'
import IconTrash from '../icons/IconTrash'
import confirmDeletestyles from '../../pages/private/plan/process/confirmDelete.styles'

interface ConfirmDeleteContentProps {
  setOpen: Function
  open: boolean
  id: string
  fetchDelete: Function
  list?: Function
  message?: string
}

const ConfirmDeleteContent = ({
  setOpen,
  open,
  id,
  fetchDelete,
  list,
  message = 'Esta seguro de eliminar?',
}: ConfirmDeleteContentProps) => {
  const handleDelete = async () => {
    const res = await fetchDelete({ _id: id })
    if (res.ok && list) {
      list()
    }
    setOpen(!open)
  }

  return (
    <>
      {/**************
        CONTENEDOR
      ***************/}
      <Box sx={confirmDeletestyles.container}>
        <Box>
          <Box sx={confirmDeletestyles.center}>
            <IconTrash color="#7282B8" width={68} height={68} />
          </Box>
          <p>{message}</p>
          <Box sx={confirmDeletestyles.center}>
            <Button
              sx={{
                backgroundColor: 'rgb(31, 47, 143, 0.1)',
                marginRight: '5px',
              }}
              onClick={handleDelete}
            >
              SI
            </Button>

            <Button
              sx={{
                backgroundColor: theme => theme.palette.secondary.main,
                marginLeft: '5px',
              }}
              onClick={() => setOpen(!open)}
            >
              NO
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ConfirmDeleteContent
