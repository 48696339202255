import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../../components/CustomModal";
import IconPensil from "../../../../components/icons/IconPensil";
import IconRelation from "../../../../components/icons/IconRelation";
import IconTrash from "../../../../components/icons/IconTrash";
import ConfirmDelete from "../../users/ConfirmDelete";
import infoProcessStyles from "../process/infoProcess.styles";

const InfoArea = () => {
    const [openModalDelete, setOpenModalDelete] = useState(false);
    // const [dataProcess, setDataProcess] = useState<any>({});
    const navigate = useNavigate();

    return (
        <>
            {/**************
                CONTENEDOR
            ***************/}
            <Box sx={infoProcessStyles.container}>
                {/**************
                    TITULO INFO
                ***************/}
                <Box sx={infoProcessStyles.contentTitle}>
                    <Box
                        sx={{
                            ...infoProcessStyles.title,
                            ">button, >a": {
                                backgroundColor: "#1F398F1A",
                                textTransform: "capitalize",
                                borderRadius: "200px",
                            },
                        }}
                    >
                        <Typography>Detalles del Área</Typography>

                        {/**************************
                            BOTONES EDITAR/BORRAR
                        **************************/}
                        <Button onClick={() => navigate(`/areas/update/${"id"}`)}>Editar</Button>
                    </Box>
                </Box>

                {/*************
                    CONTENIDO
                ***************/}
                <Box sx={infoProcessStyles.content}>
                    {/************** 
                        CONTENT 1 
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Nombre del área</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {/* {dataProcess?.idDepartament?.name ?? ""} */}
                            nombre del area
                        </Typography>
                    </Box>

                    {/*************** 
                        CONTENT 2 
                    ***************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Descripción</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            Aenean massa. Cum sociis natoque penatibus et magnis
                            dis parturient montes, nascetur ridiculus mus. Donec
                            quam felis, ultricies nec, pellentesque eu, pretium
                            quis, sem. Nulla consequat massa quis enim.
                            {/* {dataProcess?.description ?? ""} */}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 3
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Nombre del jefe de area</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            Nombre del jefe de area
                            {/* {dataProcess?.name ?? ""} */}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 4
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Email del jefe de area</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            Email del jefe de area
                            {/* {dataProcess?.justification ?? ""} */}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 5
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Celular del jefe de area</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            Celular del jefe de area
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InfoArea;
