import { FunctionComponent } from 'react'

interface IconGraphicProps {
  width?: number | string
  height?: number | string
  colorEje?: string
  colorBar?: string
}

const IconGraphic: FunctionComponent<IconGraphicProps> = ({
  width = 31,
  height = 30,
  colorEje = '#1F398F',
  colorBar = '#7282B8',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* BARRAS */}
      <rect x="6" y="20" width="5" height="9" fill={colorBar} />
      <rect x="14" y="11" width="5" height="18" fill={colorBar} />
      <rect x="22" y="3" width="5" height="26" fill={colorBar} />
      {/* LINEA EJE */}
      <path d="M31 29L1 29L1 0" stroke={colorEje} />
    </svg>
  )
}

export default IconGraphic
