import { useContext, useEffect } from 'react'
import { AppContext } from '../components/context/AppProvider'
import { useLocation } from 'react-router-dom'
import { AlertDialogProps } from '../components/Dialogs/alertDialog/AlertDialog'

const useAppDialog = () => {
  const { pathname } = useLocation()

  const { handleAlertDialog, alertDialogState } = useContext(AppContext)

  
  useEffect(() => {
    return () => handleClose()
  }, [pathname])
  
  const handleClose = ({didClose}:Pick<AlertDialogProps, 'didClose'> = {}) => {
    handleAlertDialog({ open: false, didClose })
  }
  const showLoadingDialog = ({
    title = 'Cargando...',
    text = 'Espera un momento, se encuentra cargando...',
    didClose,
    onClose,
  }: Omit<AlertDialogProps, 'open' | 'showCloseButton' | 'icon'> = {}) => {
    handleAlertDialog({
      open: true,
      icon: 'loadingAplanner',
      showCloseButton: false,
      title,
      text,
      didClose,
      onClose,
    })
  }

  const showSuccessDialog = ({
    title = 'Buen trabajo!',
    text = 'Acción realizada con exito!',
    didClose,
    onClose = handleClose,
  }: Omit<AlertDialogProps, 'open' | 'icon'> = {}) => {
    handleAlertDialog({
      open: true,
      icon: 'success',
      title,
      text,
      didClose,
      onClose,
    })
  }

  const showErrorDialog = ({
    title = 'Ooops!',
    text = 'Ha ocurrido un error al realizar esta acción',
    didClose,
    onClose = handleClose,
  }: Omit<AlertDialogProps, 'open' | 'icon'> = {}) => {
    handleAlertDialog({
      open: true,
      icon: 'error',
      title,
      text,
      didClose,
      onClose,
    })
  }

  const showWarningDialog = ({
    title = 'Ooops!',
    text,
    didClose,
    onClose = handleClose,
    ...other
  }: Omit<AlertDialogProps, 'open' | 'icon'> = {}) => {
    handleAlertDialog({
      open: true,
      icon: 'warning',
      title,
      text,
      didClose,
      onClose,
      ...other
    })
  }

  const showConfirmDialog = ({
    icon,
    title,
    text = undefined,
    smallText,
    confirmButton,
    cancelButton,
    didClose,
    onClose = handleClose,
  }: Omit<AlertDialogProps, 'open'> = {}) => {
    handleAlertDialog({
      open: true,
      maxWidth: 'sm',
      icon,
      onClose,
      didClose,
      title,
      text,
      smallText,
      confirmButton,
      cancelButton,
    })
  }

  return {
    alertDialogState,
    showLoadingDialog,
    showSuccessDialog,
    showErrorDialog,
    showConfirmDialog,
    showWarningDialog,
    hideDialog: handleClose,
  }
}

export default useAppDialog
