import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IconFile from '../../../components/icons/IconFile';
import IconLess from '../../../components/icons/IconLess';
import IconUpload from '../../../components/icons/IconUpload';
import customAlerts from '../../../helpers/customAlerts';
import formCompanyStyles from './FormCompany.styles'

interface UploadImageCompanyProps {
  img: any;
  setImg: Function;
  name: string;
}

const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg"]

const UploadImageCompany = ({ img, setImg, name }: UploadImageCompanyProps) => {
  const { errorAlert } = customAlerts

  const [dropAreaStyles, setDropAreaStyles] = useState({ ...formCompanyStyles.imagen });
  const [drag, setDrag] = useState(false);


  useEffect(() => {
    const dropArea = document.querySelector(`#${name}`);

    // Evento dragover - acciones que debe hacer al detectar que entra el archivo dentro del area
    dropArea?.addEventListener("dragover", (e: any) => {
      e.preventDefault();
      setDropAreaStyles({ ...dropAreaStyles, ...formCompanyStyles.dropAreaActive })
      setDrag(true)
    });

    // Evento dragleave - acciones que debe hacer al detectar que sale el archivo dentro del area
    dropArea?.addEventListener("dragleave", (e: any) => { 
    });

    // Evento Drop - Accciones que debe hacer al soltar el archivo dentro del area
    dropArea?.addEventListener("drop", (e: any) => {
      e.preventDefault();
      setDrag(false)
      setDropAreaStyles({ ...formCompanyStyles.imagen })

      previewImg(e.dataTransfer.files[0])
      e.target.value = null
    });
  }, [])

  const previewImg = (image: any) => {
    if (allowedTypes.some((value: string) => value === image.type)) {
      const img = { preview: URL.createObjectURL(image), file: image }
      setImg(img)
    } else {
      errorAlert({ html: "Tipo de archivo no permitido ❌" })
    }

  }

  return (
    <Box sx={{ width: "100%" }}>
      {/*********************
        CONTENEDOR IMAGEN
    **********************/}
      <Box
        sx={{
          ...formCompanyStyles.containerImage,
          marginTop: "20px !important"
        }}
      >
        {/************
            IMAGEN 
        ************/}
        {img?.preview
          ?
          <Box sx={formCompanyStyles.formImage}>
            <Box>
              <Box>
                <IconFile />

                <Typography>
                  {img?.file?.name}
                </Typography>
              </Box>

              <Button
                disableTouchRipple
                onClick={() => setImg(undefined)}
              >
                <IconLess />
              </Button>
            </Box>

            <Button
              disableTouchRipple
              variant="contained"
              component="label"
            >
              Reemplazar fotografía
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={
                  (e: any) => {
                    previewImg(e.target.files[0])
                  }
                }
              />
            </Button>
          </Box>
          :
          <Box sx={dropAreaStyles} id={name}>
            <Button
              disableTouchRipple
              variant="contained"
              component="label"
              id="buttonImg"
              style={{ color: "white", textTransform: "initial"}}
            >
              <Box>
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={
                    (e: any) => {
                      previewImg(e.target.files[0])
                    }
                  }
                />

                {/********************** 
                        ICONO CARGA/SUBIDA 
                    ***********************/}
                <div>
                  <IconUpload
                    color={drag ? "#fff" : "#7282B8"}
                  />
                </div>

                <Typography
                  style={drag ? { color: "white" } : undefined}
                >
                  Agregar imagen
                </Typography>
                <span
                  style={drag ? { color: "white" } : undefined}
                >
                  o arrastra y suelta
                </span>
              </Box>
            </Button>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default UploadImageCompany
