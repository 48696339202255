import * as yup from "yup";
import { FormAreaValues } from "../interfaces/area";

export const departamentCreateSchema: yup.SchemaOf<FormAreaValues> = yup.object(
  {
    name: yup.string().required("Este campo es obligatorio"),
    description: yup.string().required("Este campo es obligatorio"),
    nameManager: yup.string().required("Este campo es obligatorio"),
    emailManager: yup
      .string()
      .email("El campo debe ser un email valido")
      .required("Este campo es obligatorio"),
    phoneNumber: yup
      .number()
      .typeError("Este campo requiere un valor numerico")
      .required("Este campo es obligatorio"),
  }
);
