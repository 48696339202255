import { FunctionComponent } from "react";
interface IconPensilProps {
    width?: number | string;
    height?: number | string;
}

const IconPensil: FunctionComponent<IconPensilProps> = ({
    width = 15,
    height = 15,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.13771 0.347117C7.91538 0.124858 7.61389 0 7.29952 0C6.98515 0 6.68365 0.124858 6.46132 0.347117L0.34737 6.46107C0.125018 6.68335 6.71473e-05 6.98486 0 7.29926V10.4013C0 10.7315 0.26853 11 0.59871 11H3.70133C4.01574 10.9999 4.31724 10.875 4.53952 10.6526L10.6529 4.53809C10.8751 4.31576 11 4.01426 11 3.69989C11 3.38552 10.8751 3.08402 10.6529 2.8617L8.13771 0.347117ZM7.29952 1.18531L9.81469 3.70048L8.79155 4.72363L6.27637 2.20845L7.29952 1.18531ZM5.43818 3.04665L1.18556 7.29926V9.81384H3.70133L7.95395 5.56123L5.43877 3.04605L5.43818 3.04665Z"
                fill="#1F398F"
            />
        </svg>
    );
};

export default IconPensil;
