import { FunctionComponent, createContext, ReactNode, useState, SyntheticEvent, useEffect } from 'react'
import usePda from '../../hooks/pda/usePda'

interface PdaContextProps {
  children: ReactNode
}

type PdaContextType =
  | (ReturnType<typeof usePda> & {
      tabIndex: number
      handleTabIndex: (nextIndex: number) => void
      isActiveTabPanelGoalPda: boolean
    })
  | null

const PdaContext = createContext<PdaContextType>(null)

const PdaProvider: FunctionComponent<PdaContextProps> = ({ children }) => {

  const [tabIndex, setTabIndex] = useState(0)

  const pdaData = usePda()

  const isActiveTabPanelGoalPda = Boolean(
    pdaData.pda &&
      pdaData.goalPdaIndicatorList &&
      pdaData.indicatorList &&
      pdaData.measureUnitList &&
      pdaData.goalPaList
  )

  const handleTabIndex = (nextIndex: number) => {
    if (nextIndex >= 0 && !pdaData.pda) return
    if (nextIndex === 1 && !isActiveTabPanelGoalPda) return
    setTabIndex(nextIndex)
  }

  return (
    <PdaContext.Provider
      value={{ handleTabIndex, isActiveTabPanelGoalPda, tabIndex, ...pdaData }}
    >
      {children}
    </PdaContext.Provider>
  )
}

export { PdaProvider as default, PdaContext }
