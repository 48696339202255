import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'content'
  | 'containerTitle'
  | 'contentLeft'
  | 'contentRightPrincipal'
  | 'contentRightSecondary'
  | 'title'
  | 'input'
  | 'inputBase'
  | 'description'
  | 'inputDescription'
  | 'inputBaseDescription'
  | 'imagen'
  | 'divButton'
  | 'buttonSave'
  | 'buttonCancel'
  | 'containerImage'

const companiesStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    maxWidth: '1350px',
    margin: '70px auto auto auto',
    backgroundColor: '#ffffff',

    '@media (max-width: 1410px)': {
      margin: '70px 30px auto',
    },
  },

  // CONTENIDO
  content: {
    marginTop: '71px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(50%, 1fr))',
    '@media (max-width:1010px)': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
    },
  },

  // CONTENEDOR TITULO
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    '>p': {
      color: '#1F398F',
      fontSize: '30px',
      lineHeight: '45px',
      fontStyle: 'normal',
      marginLeft: '26px',
    },
    '>span': {
      fontSize: '20px',
      fontWeight: '500',
    },

    // BOTON MÁS
    '>a, >button': {
      backgroundColor: '#ffffff',
      minWidth: '95px',
      height: '38px',
      marginY: 'auto',
      marginLeft: '30px',
      padding: '0px 15px',
      textTransform: 'inherit',
      borderRadius: '92px',
      boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

      ':hover': {
        backgroundColor: '#7282B8',
        '>svg': {
          '>path': {
            fill: '#ffffff',
          },
        },
        '>p': {
          color: '#ffffff',
        },
      },

      '>p': {
        fontSize: '16px',
        marginLeft: '4px',
      },
    },
  },

  // CONTENIDO IZQUIERDO
  contentLeft: {
    backgroundColor: 'white',
    // backgroundColor: "aqua",

    '>ul': {
      paddingTop: '0px',
      '>div': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(50%, 1fr))',
        '@media (max-width:1150px)': {
          gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
        },
      },
    },

    '>ul>div>li, >ul>li': {
      marginBottom: '34px',
      padding: '0px',
      display: 'block',
    },

    // ESTILO TITULOS
    '>ul>div>li>div>span, >ul>li>div>span, >div>span': {
      fontFamily: 'Poppins',
      color: '#1F398F',
      fontSize: '20px',
      lineHeight: '30px',
      marginBottom: '3px',
    },

    // ESTILO TEXTOS
    '>ul>div>li>div>p, >ul>li>div>p, >div>p, >ul>li>a': {
      fontFamily: 'Poppins',
      color: '#000000',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '22px',
    },

    '&>ul>li>a': {
      color: '#1F398F',
      display: 'block',
    },
  },
  // CLAVE OCULTA
  // keyHidden: {
  //     display: "block",
  //     width: "100%",
  //     "&:after": { display: "none" },
  //     "&:before": { display: "none" },
  //     "&>input.Mui-disabled": {
  //         WebkitTextFillColor: "rgba(0, 0, 0) !important",
  //     },
  // },

  // CONTENIDO DERECHO
  contentRightPrincipal: {
    '>label': {
      margin: 'auto 0px auto auto',
      backgroundColor: '#FFFFFF',
      width: '501px',
      height: '300px',
      borderRadius: '22px',
      display: 'flex',
      boxShadow: '0px 0px 19px 5px rgba(114, 130, 184, 0.1)',
      ':hover': {
        backgroundColor: 'inherit',
      },

      '@media (max-width:1150px)': {
        margin: 'auto auto auto auto',
      },

      '>div': {
        margin: 'auto',
        textAlign: 'center',
        width: '285px',
        '>img': {
          width: 'inherit',
        },

        '>button': {
          display: 'block',
          textTransform: 'inherit',
          ':hover': {
            backgroundColor: 'transparent',
          },

          '>p': {
            color: '#1F398F',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '30px',
          },
          '>span': {
            color: '#7282B8',
            fontFamily: 'Poppins',
            fontSize: '15px',
            lineHeight: '22px',
          },
        },
      },
    },

    // BOTON REEMPLAZAR FOTOGRAFIA
    '>span': {
      display: 'block',
      margin: '32px 0px auto auto',
      width: '501px',
      textAlign: 'center',
      '>label': {
        backgroundColor: 'inherit',
        color: '#000000',
        fontSize: '15px',
        fontStyle: 'normal',
        textAlign: 'center',
        padding: '0px',
        textTransform: 'initial',
        textDecoration: 'underline',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: 'inherit',
          textDecoration: 'underline',
          boxShadow: 'none',
        },
      },

      '@media (max-width:1150px)': {
        margin: '32px auto auto auto',
      },
    },
  },

  contentRightSecondary: {
    '>label': {
      margin: '50px 0px auto auto',
      backgroundColor: '#FFFFFF',
      width: '501px',
      height: '176px',
      borderRadius: '22px',
      display: 'flex',
      boxShadow: '0px 0px 19px 5px rgba(114, 130, 184, 0.1)',
      ':hover': {
        backgroundColor: 'inherit',
      },

      '@media (max-width:1150px)': {
        margin: 'auto auto auto auto',
      },

      '>div': {
        margin: 'auto',
        textAlign: 'center',
        maxWidth: '355px',
        width: '355px',
        // height: '100%',
        height: '60%',
        '>img': {
          width: 'inherit',
          height: '100%',
        },

        '>button': {
          display: 'block',
          textTransform: 'inherit',
          ':hover': {
            backgroundColor: 'transparent',
          },

          '>p': {
            color: '#1F398F',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '30px',
          },
          '>span': {
            color: '#7282B8',
            fontFamily: 'Poppins',
            fontSize: '15px',
            lineHeight: '22px',
          },
        },
      },
    },

    // BOTON REEMPLAZAR FOTOGRAFIA
    '>span': {
      display: 'block',
      margin: '32px 0px auto auto',
      width: '501px',
      textAlign: 'center',
      '>label': {
        backgroundColor: 'inherit',
        color: '#000000',
        fontSize: '15px',
        fontStyle: 'normal',
        textAlign: 'center',
        padding: '0px',
        textTransform: 'initial',
        textDecoration: 'underline',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: 'inherit',
          textDecoration: 'underline',
          boxShadow: 'none',
        },
      },

      '@media (max-width:1150px)': {
        margin: '32px auto auto auto',
      },
    },
  },

  // SUBTITULOS
  title: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  input: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },
  inputBase: {
    width: '100%',
    height: '100%',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
    borderBottom: '0px solid transparent',
    '>div': {
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  // DESCRIPCION
  description: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  inputDescription: {
    backgroundColor: '#F1F3F8',
    borderRadius: '25px',
    minHeight: '135px',
  },
  inputBaseDescription: {
    width: '100%',
    minHeight: 'inherit',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '25px',
    '>textarea': {
      marginTop: '5px',
      marginBottom: 'auto',
    },
  },

  // IMAGEN
  containerImage: {
    width: 'inherit',
  },
  imagen: {
    margin: 'auto 0px auto auto',
    backgroundColor: '#7282B81A',
    height: '312px',
    borderRadius: '25px',
    display: 'flex',
    '>div': {
      margin: 'auto',
      textAlign: 'center',
      '>button': {
        display: 'block',
        textTransform: 'inherit',
        ':hover': {
          backgroundColor: 'transparent',
        },

        '>p': {
          color: '#1F398F',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '30px',
        },
        '>span': {
          color: '#7282B8',
          fontFamily: 'Poppins',
          fontSize: '15px',
          lineHeight: '22px',
        },
      },
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    marginTop: '102px',
    marginX: 'auto',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '169.74px',
      // width: '169.74px',
      height: '33px',
      borderRadius: '20px',
      padding: '10px 20px',

      // TEXTO
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },

  buttonSave: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
}

export default companiesStyles
