/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Box, Typography, InputBase, InputAdornment, IconButton, TextField, Button } from '@mui/material'
import CustomTitleButtonAdd from '../../../components/CustomTitleButtonAdd'
import formUsersStyles from './formUsers.styles'
import Charge from '../../../components/inputSelect/Charge'
import ChargeBoss from '../../../components/inputSelect/ChargeBoss'
import CloseEye from '../../../components/icons/IconCloseEye'
import IconOpenEye from '../../../components/icons/IconOpenEye'
import { useNavigate, useParams } from 'react-router-dom'
import useUsers from '../../../hooks/users/useUsers'
import BranchOffice from '../../../components/inputSelect/BranchOffice'
import RolUser from '../../../components/inputSelect/RolUser'
import Departament from '../../../components/inputSelect/Departament'
import useImage from '../../../hooks/image/useImage'
import customAlerts from '../../../helpers/customAlerts'
import { ValidateFields, ValidateForm } from '../../../services/ValidateForm'
import { userCreateSchema, userUpdateSchema } from '../../../schemas/userSchema'
import ErrorField from '../../../components/ErrorField'
import UploadImage from './UploadImage'
import formStyles from '../../../components/forms/form.styles'
import usePosition from '../../../hooks/position/usePosition'

interface imgProps {
  preview: string
  file: any
}

const FormUsers = () => {
  const { fetchCreateUser, fetchUpdateUser, fetchGetOneUser } = useUsers()
  const { loadAlert, closeLoading } = customAlerts
  const { uploadImage } = useImage()
  const { fetchGetPositions } = usePosition()
  const params = useParams()

  // MOSTRAR/OCULTAR CONTRASEÑA
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const [positions, setPositions] = useState<any>([])
  const [dataForm, setDataForm] = useState<any>({})
  const [img, setImg] = useState<imgProps>()
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    if (params.id) {
      getUser(params.id)
    }
  }, [])

  const getUser = async (id: string) => {
    loadAlert()
    const response: any = await fetchGetOneUser(id)
    const dataUserUpdate = response

    delete dataUserUpdate.__v
    delete dataUserUpdate.createdAt
    delete dataUserUpdate.updatedAt
    delete dataUserUpdate.is_active
    delete dataUserUpdate.password
    delete dataUserUpdate.idProfile?.__v
    delete dataUserUpdate.idProfile?.createdAt
    delete dataUserUpdate.idProfile?.updatedAt
    delete dataUserUpdate.idProfile?.is_active
    delete dataUserUpdate.idProfile?.password
    delete dataUserUpdate.idProfile?.urlPhoto
    delete dataUserUpdate.idProfile?.urlPhotoLink
    delete dataUserUpdate.idProfile?._id

    const { idProfile, ...dataUser } = dataUserUpdate

    setDataForm({
      ...dataForm,
      ...dataUser,
      profile: { ...idProfile },
    })
    closeLoading()
  }

  let navigate = useNavigate()

  const handleInputChange = async (e: { target: { name: string; value: string } }) => {
    setDataForm({ ...dataForm, profile: { ...dataForm.profile, [e.target.name]: e.target.value } })
    const result = await ValidateFields({
      schema: params.id ? userUpdateSchema : userCreateSchema,
      data: { ...dataForm, profile: { ...dataForm.profile, [e.target.name]: e.target.value } },
      fieldName: `profile.${e.target.name}`,
    })
    setErrors(result)
  }

  const handleInputChange2 = async (e: { target: { name: string; value: string } }) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
    const result = await ValidateFields({
      schema: params.id ? userUpdateSchema : userCreateSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }

  const handleSelectChange = async (option: any) => {
    console.log(option)
    setDataForm({ ...dataForm, profile: { ...dataForm.profile, ...option } })
    const result = await ValidateFields({
      schema: params.id ? userUpdateSchema : userCreateSchema,
      data: { ...dataForm, profile: { ...dataForm.profile, ...option } },
      fieldName: Object.keys(option)[0],
    })
    setErrors(result)
  }
  console.log(dataForm)
  const handleSubmit = async () => {
    const result = await ValidateForm({
      schema: params.id ? userUpdateSchema : userCreateSchema,
      data: dataForm,
    })

    if (result.isValid) {
      const res: any = await sendImage()

      if (res.ok) {
        let response: any
        if (params.id) {
          response = await fetchUpdateUser({
            ...dataForm,
            _id: params.id,
            urlPhoto: res?.data?.key,
            urlPhotoLink: res?.data?.Url ?? res?.data?.url,
          })
        } else {
          response = await fetchCreateUser({
            ...dataForm,
            urlPhoto: res?.data?.key,
            urlPhotoLink: res?.data?.Url ?? res?.data?.url,
          })
        }
        if (response.ok) {
          navigate('/users')
        }
      }
    } else {
      setErrors(result.errors)
    }
  }

  const sendImage = async () => {
    if (img?.file) {
      const formData = new FormData()
      formData.append('urlPhoto', img?.file)

      const resImg = await uploadImage({ action: 'userUploadImg', form: formData })
      return resImg
    } else {
      return { ok: true }
    }
  }

  const list = async () => {
    const response: any = await fetchGetPositions({ limit: 0, from: 0 })
    setPositions(response.items)
  }

  useEffect(() => {
    list()
  }, [])

  return (
    <>
      {/**************************
                CONTENEDOR FORMULARIO
            ****************************/}
      <Box sx={formUsersStyles.container}>
        {/*******************
                    TITULO USUARIOS
                *********************/}
        <CustomTitleButtonAdd title="Usuarios" display="none" />

        {/********************************
                    CONTENEDOR DATOS PERSONALES 
                ********************************/}
        <Box>
          {/**********
                        TITULO
                    ************/}
          <Box sx={formUsersStyles.title}>
            <Typography>Datos personales</Typography>
          </Box>

          {/*******************
                        CONTENEDOR GRID
                    *********************/}
          <Box sx={formStyles.containerGrid}>
            {/*************
                            NOMBRES
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Nombres del usuario</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <InputBase
                  sx={formUsersStyles.inputBase}
                  name="name"
                  onChange={handleInputChange}
                  value={dataForm.profile?.name}
                />
              </Box>
              <ErrorField message={errors['profile.name'] ?? ''} />
            </Box>

            {/*************
                            APELLIDOS
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>Apellidos del usuario</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <InputBase
                  sx={formUsersStyles.inputBase}
                  name="lastName"
                  onChange={handleInputChange}
                  value={dataForm.profile?.lastName}
                />
              </Box>
              <ErrorField message={errors['profile.lastName']} />
            </Box>

            {/*************
                            EMAIL
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>E-mail</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <InputBase
                  sx={formUsersStyles.inputBase}
                  name="email"
                  onChange={handleInputChange}
                  value={dataForm.profile?.email}
                />
              </Box>
              <ErrorField message={errors['profile.email']} />
            </Box>

            {/*************
                            CELULAR
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>Celular</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <InputBase
                  sx={formUsersStyles.inputBase}
                  name="phone"
                  onChange={handleInputChange}
                  value={dataForm.profile?.phone}
                />
              </Box>
              <ErrorField message={errors['profile.phone']} />
            </Box>
          </Box>
        </Box>

        {/********************************
                    CONTENEDOR DATOS RELACIONADOS
                ********************************/}
        <Box>
          {/**********
                        TITULO
                    ************/}
          <Box
            sx={{
              ...formUsersStyles.title,
              marginTop: '0px !important',
            }}
          >
            <Typography>Datos relacionados</Typography>
          </Box>

          {/*******************
                        CONTENEDOR GRID
                    *********************/}
          <Box sx={formStyles.containerGrid}>
            {/*************
                            PROCESO
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Proceso</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <InputBase
                  sx={formUsersStyles.inputBase}
                  name="process"
                  disabled
                  // onChange={handleInputChange}
                  // value={dataForm.profile?.process}
                />
              </Box>
            </Box>
            {/**********
                            CARGO
                        ***********/}
            <Charge
              name="idPosition"
              list={list}
              items={positions}
              handleSelectChange={handleSelectChange}
              defaultValue={params.id && dataForm.profile?.idPosition}
              errorMessage={errors['profile.idPosition'] ?? ''}
            />

            {/* <SelectCharge /> */}

            {/**********
                            AREA
                        ***********/}
            <Departament
              handleSelectChange={handleSelectChange}
              defaultValue={params.id && dataForm.profile?.idDepartament}
              errorMessage={errors['profile.idDepartament'] ?? ''}
            />
            {/**********
                            CARGO DEL JEFE
                        ***********/}
            <Charge
              name="idBossPosition"
              items={positions}
              buttonNew={false}
              handleSelectChange={handleSelectChange}
              defaultValue={params.id && dataForm.profile?.idBossPosition}
              errorMessage={errors['profile.idBossPosition'] ?? ''}
            />
          </Box>
          <Box
            sx={{
              ...formStyles.containerGrid,
              gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
            }}
          >
            {/*************
                            SEDE
                        ***************/}
            <BranchOffice
              handleSelectChange={handleSelectChange}
              defaultValue={params.id && dataForm.profile?.idBranchOffice}
              errorMessage={errors['profile.idBranchOffice'] ?? ''}
            />
          </Box>
        </Box>

        {/********************************
                    CONTENEDOR ACCESO AL SISTEMA
                ********************************/}
        <Box>
          {/**********
                        TITULO
                    ************/}
          <Box
            sx={{
              ...formUsersStyles.title,
              marginTop: '20px !important',
            }}
          >
            <Typography>Acceso al sistema</Typography>
          </Box>

          {/*******************
                        CONTENEDOR GRID
                    *********************/}
          <Box sx={formStyles.containerGrid}>
            {/*************
                            USUARIO
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Usuario</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <InputBase
                  sx={formUsersStyles.inputBase}
                  name="userName"
                  onChange={handleInputChange2}
                  value={dataForm.userName}
                />
              </Box>
              <ErrorField message={errors.userName ?? ''} />
            </Box>

            {/*************
                            CONTRASEÑA
                        ***************/}
            <Box
              sx={{
                ...formUsersStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>Contraseña</Typography>

              {/* INPUT */}
              <Box sx={formUsersStyles.input}>
                <TextField
                  sx={{
                    ...formUsersStyles.inputBase,
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                  }}
                  // fullWidth
                  variant="standard"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={handleInputChange2}
                  disabled={params.id ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disableTouchRipple
                          onClick={handleClickShowPassword!}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <IconOpenEye /> : <CloseEye />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {params.id && (
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                    }}
                  >
                    Deshabilitado
                  </Typography>
                )}
              </Box>
              <ErrorField message={errors?.password} />
            </Box>
            <Box
              sx={{
                ...formStyles.containerGrid,
                gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
              }}
            >
              <RolUser
                handleSelectChange={handleSelectChange}
                defaultValue={params.id && dataForm.profile?.idRol}
                errorMessage={errors?.idRol ?? ''}
              />
            </Box>
          </Box>
          <UploadImage img={img} setImg={setImg} />

          {/**********************
                        CONTENEDOR BOTONES 
                    ***********************/}
          <Box
            sx={{
              ...formUsersStyles.divButton,
              marginTop: '141px !important',
            }}
          >
            {/**********************************
                            BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
            <div>
              {/********************
                                BOTON ACTUALIZAR
                            *********************/}
              <Button
                disableTouchRipple
                sx={{
                  ...formUsersStyles.buttonSave,
                  marginRight: '19.52px',
                }}
                onClick={handleSubmit}
              >
                {params.id ? 'Actualizar' : 'Crear'}
              </Button>

              {/********************
                                BOTON DESACTIVAR
                            *********************/}
              <Button onClick={e => navigate(-1)} disableTouchRipple sx={formUsersStyles.buttonCancel}>
                Cancelar
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormUsers
