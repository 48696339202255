import { StyleComponent } from "../../../../../interfaces/styleComponent";

type Keys = "container" | "title";

const subProcessStyles: StyleComponent<Keys> = {
    container: {
        backgroundColor: "#ffffff",
        minHeight: "52.7vh",
    },

    // TITULO, BOTONES CREAR Y BUSCAR
    title: {
        maxWidth: "1350px",
        display: "flex",
        margin: "auto",
        justifyContent: "space-between",

        // RESPONSIVE
        "@media(max-width: 1670px)": {
            marginX: "160px",
        },

        "@media(max-width: 950px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
            marginX: "100px",

            ">div": {
                width: "80%",
                justifyContent: "space-between",

                // BOTON BUSCAR
                ">.buttonSearch": {
                    maxWidth: "100% !important",

                    ">div": {
                        maxWidth: "inherit !important",
                        ">div": {
                            width: "inherit",
                        },
                    },
                },

                ">.buttonCreate": {
                    minWidth: "180px !important",
                    height: "38px !important",
                    padding: "0px 15px !important",
                    ">p": {
                        display: "block !important",
                    },

                    ">svg": {
                        width: "11px !important",
                        height: "11px !important",
                    },
                },
            },
        },

        "@media(max-width: 750px)": {
            marginX: "0px",
        },

        "@media(max-width: 515px)": {
            flexDirection: "column-reverse",
            alignItems: "center",

            ">div": {
                width: "80%",
                justifyContent: "space-between",

                // BOTON BUSCAR
                ">.buttonSearch": {
                    maxWidth: "100% !important",

                    ">div": {
                        maxWidth: "inherit !important",
                        ">div": {
                            width: "inherit",
                        },
                    },
                },

                ">.buttonCreate": {
                    minWidth: "inherit !important",
                    height: "38px !important",
                    padding: "0px 15px !important",
                    ">p": {
                        display: "none !important",
                    },

                    ">svg": {
                        width: "11px !important",
                        height: "11px !important",
                    },
                },
            },
        },

        // TYPOGRAPHY
        ">p": {
            color: "#1F398F",
            fontSize: "50px",
            fontWeight: "600",
            lineHeight: "75px",
            fontStyle: "normal",

            // RESPONSIVE
            "@media (max-width: 565px)": {
                fontSize: "40px",
            },
        },

        // BOTONES CREAR Y BUSCAR
        ">div": {
            marginY: "auto",
            display: "flex",

            // BOTON CREAR
            ">.buttonCreate": {
                backgroundColor: "#ffffff",
                minWidth: "180px",
                height: "38px",
                marginY: "auto",
                marginRight: "20px",
                padding: "0px 15px",
                textTransform: "inherit",
                borderRadius: "92px",
                boxShadow: "0px 0px 10px rgba(114, 130, 184, 0.25)",

                // RESPONSIVE
                "@media (max-width: 1260px)": {
                    minWidth: "inherit",
                    height: "auto",
                    padding: "10px",
                    ">p": {
                        display: "none",
                    },

                    ">svg": {
                        width: 16,
                        height: 16,
                    },
                },

                // HOVER
                ":hover": {
                    backgroundColor: "#7282B8",
                    ">svg": {
                        ">path": {
                            fill: "#ffffff",
                        },
                    },
                    ">p": {
                        color: "#ffffff",
                    },
                },

                // PARRAFO
                ">p": {
                    fontSize: "16px",
                    marginLeft: "4px",
                },
            },

            ">.iconProcess": {
                ">svg": {
                    ">path": {
                        fill: "inherit",
                        stroke: "#1F398F",
                    },
                    ">circle": {
                        fill: "inherit",
                        stroke: "#1F398F",
                    },
                },

                // HOVER
                ":hover": {
                    backgroundColor: "#7282B8",
                    ">svg": {
                        ">path": {
                            fill: "inherit",
                            stroke: "#ffffff",
                        },
                        ">circle": {
                            fill: "inherit",
                            stroke: "#ffffff",
                        },
                    },
                },
            },

            // BOTON BUSCAR
            ">.buttonSearch": {
                maxWidth: "268px",

                ">div": {
                    maxWidth: "268px",
                    minHeight: "41px",
                    color: "#1F398F",
                    marginLeft: "auto",
                    borderRadius: "500px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    paddingX: "7px",

                    ">button:hover": {
                        backgroundColor: "transparent",
                    },
                    ">div>input": {
                        color: "#1F398F",
                        fontSize: "12px",
                        fontWeight: "400",
                    },
                },
            },
        },
    },
};

export default subProcessStyles;
