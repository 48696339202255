import { StyleComponent } from '../../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'content'
  | 'title'
  | 'input'
  | 'inputBase'
  | 'description'
  | 'inputDescription'
  | 'inputBaseDescription'
  | 'imagen'
  | 'divButton'
  | 'buttonSave'
  | 'buttonCancel'
  | 'containerImage'

const formAreasStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    maxWidth: '1350px',
    margin: 'auto',
    backgroundColor: '#ffffff',
  },

  // CONTENIDO
  content: {
    maxWidth: '1110px',
    margin: 'auto',
    marginTop: '32px',
    '@media (max-width:1250px)': {
      marginX: '70px',
    },
  },

  // SUBTITULOS
  title: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  input: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },
  inputBase: {
    width: '100%',
    height: '100%',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
    borderBottom: '0px solid transparent',
    '>div': {
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  // DESCRIPCION
  description: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  inputDescription: {
    backgroundColor: '#F1F3F8',
    borderRadius: '25px',
    minHeight: '135px',
  },
  inputBaseDescription: {
    width: '100%',
    minHeight: 'inherit',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '25px',
    '>textarea': {
      marginTop: '5px',
      marginBottom: 'auto',
    },
  },

  // IMAGEN
  containerImage: {
    width: 'inherit',
  },
  imagen: {
    margin: 'auto 0px auto auto',
    backgroundColor: '#7282B81A',
    height: '312px',
    borderRadius: '25px',
    display: 'flex',
    '>div': {
      margin: 'auto',
      textAlign: 'center',
      '>button': {
        display: 'block',
        textTransform: 'inherit',
        ':hover': {
          backgroundColor: 'transparent',
        },

        '>p': {
          color: '#1F398F',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '30px',
        },
        '>span': {
          color: '#7282B8',
          fontFamily: 'Poppins',
          fontSize: '15px',
          lineHeight: '22px',
        },
      },
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    marginTop: '102px',
    marginX: 'auto',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '169.74px',
      // width: '169.74px',
      height: '33px',
      borderRadius: '20px',
      padding: '10px 20px',

      // TEXTO
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },

  buttonSave: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
}

export default formAreasStyles
