import { Box, Button, InputBase } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import IconAdd from '../../../../components/icons/IconAdd'
import IconClose from '../../../../components/icons/IconClose'
import IconLess from '../../../../components/icons/IconLess'
import formProcessStyles from './formProcess.styles'
import OpenFormStyles from './openForm.styles'
import useAppDialog from '../../../../hooks/useAppDialog'
import { text } from 'stream/consumers'

interface FormSubProcessProps {
  openForm: any
  setOpenForm: Function
  listSubProcess: any
  setListSubProcess: Function
  idProcess?: string
}

const FormSubProcess = ({
  openForm,
  setOpenForm,
  listSubProcess,
  setListSubProcess,
  idProcess,
}: FormSubProcessProps) => {
  const [addForm, setAddForm] = useState(false)
  const [data, setData] = useState<any>({})
  const {showErrorDialog} = useAppDialog()

  const handleInputChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: any) => {
    if (data.name && data.name !== "") {
      setListSubProcess([...listSubProcess, data])
    } else {
      showErrorDialog({ text: 'No se encontro nigun subproceso' })  
    }
    if (idProcess) {
      setData({ idProcess })
    } else {
      setData({})
    }
  }

  const handleDelete = (index: number) => {
    const array = listSubProcess
    array.splice(index, 1)
    setListSubProcess([...array])
  }

  useEffect(() => {
    if (idProcess) {
      setData({ idProcess })
    }
  }, [idProcess])

  return (
    <>
      {/*****************************
        FORMULARIO DE SUBPROCESO
      *****************************/}
      <Box
        sx={{
          ...formProcessStyles.formSubprocess,
          display: openForm === false ? 'none' : 'block',
          width: '750px',
          animation: openForm === true ? 'open-animate 0.3s ease' : 'open-animate 0.3s reverse ease',

          '@keyframes open-animate': {
            '0%': {
              transform: 'translateX(750px)',
              animationPlayState: 'running',
            },
            '100%': {
              transform: 'translateX(0px)',
              animationPlayState: 'paused',
            },
          },
        }}
      >
        <Box sx={OpenFormStyles.containerOpenForm}>
          {/***********
                        BOTÓN X
                    ************/}
          <Button
            onClick={e => {
              setOpenForm(false)
            }}
            sx={OpenFormStyles.buttonOpenForm}
          >
            <IconClose />
          </Button>

          {/**************
              CONTENIDO
          ***************/}
          <Box
            sx={{
              ...OpenFormStyles.content,
              borderBottom: addForm === true ? '1px solid rgba(114, 130, 184, 0.25)' : 'none',
            }}
          >
            {listSubProcess.length === 0 ? (
              <>
                {/*************
                                    CONTENIDO
                                **************/}
                <Box sx={OpenFormStyles.title}>
                  <Typography>Crear subproceso</Typography>
                </Box>

                {/*************************
                                    NOMBRE DEL SUBPROCESO
                                **************************/}
                <Box
                  sx={{
                    ...OpenFormStyles.subtitle,
                  }}
                >
                  <Typography>Nombre del subproceso</Typography>

                  {/* INPUT */}
                  <Box sx={OpenFormStyles.input}>
                    <InputBase
                      sx={OpenFormStyles.inputBase}
                      name="name"
                      onChange={handleInputChange}
                      value={data?.name ?? ''}
                    />
                  </Box>
                </Box>

                {/*****************
                    JUSTIFICACION
                ********************/}
                <Box
                  sx={{
                    ...OpenFormStyles.subtitle,
                  }}
                >
                  <Typography>Justificación</Typography>

                  {/* INPUT */}
                  <Box sx={OpenFormStyles.input}>
                    <InputBase
                      sx={OpenFormStyles.inputBase}
                      name="justification"
                      onChange={handleInputChange}
                      value={data?.justification ?? ''}
                    />
                  </Box>
                </Box>

                <Box sx={OpenFormStyles.divButton}>
                  <Button className="btnCreate" disableTouchRipple onClick={handleSubmit}>
                    Crear
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    ...OpenFormStyles.content,
                    paddingX: '0px',
                  }}
                >
                  {/**************************
                    CONTENIDO LISTA CREADA
                  ***************************/}
                  <Box
                    sx={{
                      ...OpenFormStyles.title,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>Subprocesos creados</Typography>

                    <Button
                      onClick={f => setAddForm(true)}
                      sx={{
                        ...formProcessStyles.buttonSubProcess,
                      }}
                    >
                      <IconAdd height={23} width={23} color={'#FECF5E'} />
                    </Button>
                  </Box>

                  {/*************************
                    LISTA DE SUBPROCESOS
                  *************************/}
                  <Box
                    sx={{
                      ...formProcessStyles.containerListForm,
                      overflowY: listSubProcess.length > 8 ? 'scroll' : 'hidden',
                      marginRight: listSubProcess.length > 8 ? '-20px' : '0px',
                    }}
                  >
                    {listSubProcess.map((subProcess: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          ...formProcessStyles.listForm,
                          marginRight: listSubProcess.length > 8 ? '10px' : '0px',
                        }}
                      >
                        <Typography>{subProcess.name}</Typography>

                        <Button sx={formProcessStyles.buttonSubProcess} onClick={() => handleDelete(index)}>
                          <IconLess />
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Box>

                {/*****************************
                    FORMULARIO/SI HAY LISTA 
                ******************************/}
                <Box
                  sx={{
                    display: addForm === true ? 'block' : 'none',

                    // DEFECTO DE RECARGA
                    animation: addForm === true ? 'open-animate 0.3s ease' : 'closed-animate 0.3s ease',

                    // MOSTRAR
                    '@keyframes open-animate': {
                      '0%': {
                        transform: 'translateY(-750px)',
                        animationPlayState: 'running',
                        opacity: 0,
                      },
                      '45%': {
                        opacity: 0,
                      },
                      '100%': {
                        transform: 'translateY(0px)',
                        animationPlayState: 'paused',
                        opacity: 1,
                      },
                    },

                    // OCULTAR
                    '@keyframes closed-animate': {
                      '0%': {
                        transform: 'translateY(-750px)',
                        animationPlayState: 'running',
                        opacity: 1,
                      },
                      '45%': {
                        opacity: 1,
                      },
                      '100%': {
                        transform: 'translateY(0px)',
                        animationPlayState: 'paused',
                        opacity: 1,
                      },
                    },
                  }}
                >
                  {/*************
                                        CONTENIDO
                                    **************/}
                  <Box
                    sx={{
                      ...OpenFormStyles.title,
                      paddingTop: '45px',
                    }}
                  >
                    <Typography>Crear subproceso</Typography>
                  </Box>

                  {/*************************
                                        NOMBRE DEL SUBPROCESO
                                    **************************/}
                  <Box
                    sx={{
                      ...OpenFormStyles.subtitle,
                    }}
                  >
                    <Typography>Nombre del subproceso</Typography>

                    {/* INPUT */}
                    <Box sx={OpenFormStyles.input}>
                      <InputBase
                        sx={OpenFormStyles.inputBase}
                        name="name"
                        onChange={handleInputChange}
                        value={data?.name ?? ''}
                      />
                    </Box>
                  </Box>

                  {/*****************
                                        JUSTIFICACION
                                    ********************/}
                  <Box
                    sx={{
                      ...OpenFormStyles.subtitle,
                    }}
                  >
                    <Typography>Justificación</Typography>

                    {/* INPUT */}
                    <Box sx={OpenFormStyles.input}>
                      <InputBase
                        sx={OpenFormStyles.inputBase}
                        name="justification"
                        onChange={handleInputChange}
                        value={data?.justification ?? ''}
                      />
                    </Box>
                  </Box>

                  <Box sx={OpenFormStyles.divButton}>
                    <Button disableTouchRipple onClick={handleSubmit}>
                      Crear
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormSubProcess
