import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys =
    | "icon"
    | "title"
    | "text"

const genericAlertStyles: StyleComponent<Keys> = {
    icon: {
        paddingTop: "120px",
        textAlign: "center",
        height : 119,
        width : 117,
        m : 'auto'
    },
    title: {
        color : '#1F398F',
        maxWidth: 600,
        margin: "15px auto auto",
        fontSize: "35px",
        fontWeight: 500,
        lineHeight : '52px',
        textAlign: "center",
        "@media (max-width: 555px)": {
            margin: "30px 30px auto",
        },
    },
    text: {
        maxWidth: 600,
        margin: "25px auto 30px",
        fontSize: "20px",
        lineHeight: "30px",
        fontWeight: 400,
        textAlign: "center",
    },
}

export default genericAlertStyles;