interface ValidateFieldsProps {
  schema: any;
  data: any;
  fieldName: string;
}

interface ValidateFormProps {
  schema: any;
  data: any;
}

export const ValidateFields = async ({
  schema,
  data,
  fieldName,
}: ValidateFieldsProps) => {
  try {
    await schema.validate(data, {
      abortEarly: false,
    });

    return {}
  } catch (err: any) {
    const validationErrors: any = {};
    const keys = Object.keys(data);
    
    err.inner.forEach((error: any) => {
      if (
        error.path === fieldName ||
        keys.some((value: any) => value === error.path)
      ) {
        validationErrors[error.path] = error.message;
      }
    });

    return validationErrors;
  }
};

export const ValidateForm = async ({ schema, data }: ValidateFormProps) => {
  try {
    await schema.validate(data, {
      abortEarly: false,
    });

    return {
      isValid: true,
      errors: {},
    };
  } catch (err: any) {
    const validationErrors: any = {};

    err.inner.forEach((error: any) => {
      validationErrors[error.path] = error.message;
    });

    return {
      isValid: false,
      errors: validationErrors,
    };
  }
};
