import { LoginData } from "../interfaces/auth";

// const host = 'https://api-aplanner.herokuapp.com'
// const host = 'https://nextjs-production-b61a.up.railway.app'
const host = process.env.REACT_APP_API_URL
const login = ( body : LoginData ) => fetch(`${host}/api/v1/auth/login`, {
    method : 'POST',
    headers : { 'Content-type' : 'application/json' },
    body : JSON.stringify(body)
})

// Service
const authService = {
    login,
}

export default authService;