import { StyleComponent } from '../../../../interfaces/styleComponent'

type Keys =
  | 'superContainer'
  | 'subHeader'
  | 'container'
  | 'content'
  | 'title'
  | 'subtitle'
  | 'input'
  | 'inputBase'
  | 'description'
  | 'inputDescription'
  | 'inputBaseDescription'
  | 'formSubprocess'
  | 'buttonSubProcess'
  | 'divButton'
  | 'buttonSave'
  | 'buttonCancel'
  | 'containerListForm'
  | 'listForm'

const formProcessStyles: StyleComponent<Keys> = {
  superContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
  },

  // SUPERCONTENEDOR
  subHeader: {
    '>div': {
      display: 'flex',
      margin: '42px auto 42px auto',

      // SUBHEADER 1
      '>.subHeader1': {
        display: 'flex',
        marginLeft: 'auto',

        // BOTON
        '&>.containerButton>button, a': {
          backgroundColor: '#ffffff',
          color: '#1F398F',
          height: '23px',
          textAlign: 'center',
          textTransform: 'capitalize',
          alignItems: 'center',
          alignContent: 'center',
          borderRadius: '15px',
          boxShadow: 'none',

          // HOVER BOTON EMPRESAS - ENTERPRISE
          '&:hover': {
            backgroundColor: 'inherit',
            '&>p': {
              color: '#1F398F',
              fontWeight: '600',
            },
          },

          // RESPONSIVE
          '@media(max-width:1400px)': {
            width: '13vw',
          },

          // PARRAFO DE BOTON
          '&>p': {
            backgroundColor: '#7282B81A',
            color: '#1F398F',
            display: 'inline',
            fontSize: '16px',
            fontWeight: '400',
            padding: '7px 17px',
            borderRadius: '92px',
            ':hover': {
              color: '#1F398F',
              fontWeight: '600',
            },
          },
        },

        // BOTON SOMBREADO
        '>.containerButton>.botonSombreado>p': {
          backgroundColor: '#ffffff',
          padding: '5px 15px',
          borderRadius: '92px',
          filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',
          ':hover': {
            backgroundColor: '#7282B8',
            border: '1px solid #7282B8',
            color: '#ffffff',
          },
        },

        '&>.containerButton>div>div': {
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        },
      },

      // SUBHEADER 2
      '>.subHeader2': {
        display: 'flex',

        '>.containerLocation': {
          display: 'flex',
          marginLeft: '50px',
          '>div': {
            marginY: 'auto',
            display: 'flex',
            '>p': {
              fontSize: '16px',
              color: '#1F398F',
              fontWeight: '400',
            },
            '>svg': {
              marginY: 'auto',
              color: '#1F398F',
              fontWeight: '400',
            },
          },
        },
      },
    },
  },

  // CONTENEDOR
  container: {
    backgroundColor: '#ffffff',
    minHeight: '60vh',
    maxWidth: '1350px',
    marginLeft: '260px',
    transition: '1.5s ease-in-out',
    WebkitTransition: '1.5s ease-in-out',
  },

  // CONTENIDO
  content: {
    maxWidth: '1110px',
    marginTop: '32px',
    marginLeft: '124px',
    '@media (max-width:1250px)': {
      marginX: '70px',
    },

    '>.areaName': {
      marginBottom: '30px',
    },
  },

  // TITULO, BOTONES CREAR Y BUSCAR
  title: {
    maxWidth: '1350px',
    display: 'flex',
    '@media(max-width: 1670px)': {
      marginX: '160px',
    },

    // TYPOGRAPHY
    '>p': {
      color: '#1F398F',
      fontSize: '50px',
      fontWeight: '600',
      lineHeight: '75px',
      fontStyle: 'normal',
    },

    // BOTONES CREAR Y BUSCAR
    '>div': {
      margin: 'auto 100px auto auto',
      '@media(max-width: 1670px)': {
        marginRight: '0px !important',
        '>.buttonCreate': {
          marginRight: '0px !important',
        },
      },

      // BOTON CREAR
      '>.buttonCreate': {
        backgroundColor: '#ffffff',
        minWidth: '180px',
        height: '38px',
        marginY: 'auto',
        marginRight: '20px',
        padding: '0px 15px',
        textTransform: 'inherit',
        borderRadius: '92px',
        boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

        // HOVER
        ':hover': {
          backgroundColor: '#7282B8',
          '>svg': {
            '>path': {
              fill: '#ffffff',
            },
          },
          '>p': {
            color: '#ffffff',
          },
        },

        // PARRAFO
        '>p': {
          fontSize: '16px',
          marginLeft: '4px',
        },
      },

      '>.iconProcess': {
        '>svg': {
          '>path': {
            fill: 'inherit',
            stroke: '#1F398F',
          },
          '>circle': {
            fill: 'inherit',
            stroke: '#1F398F',
          },
        },

        // HOVER
        ':hover': {
          backgroundColor: '#7282B8',
          '>svg': {
            '>path': {
              fill: 'inherit',
              stroke: '#ffffff',
            },
            '>circle': {
              fill: 'inherit',
              stroke: '#ffffff',
            },
          },
        },
      },

      // BOTON BUSCAR
      '>.buttonSearch': {
        minWidth: 'inherit',
        padding: '0px',
        ':hover': {
          backgroundColor: 'inherit',
        },
      },
    },
  },

  // SUBTITULOS
  subtitle: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
    '>span': {
      fontSize: '20px',
      marginBottom: '9px',
    },
  },
  input: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },
  inputBase: {
    width: '100%',
    height: '100%',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
    borderBottom: '0px solid transparent',
    '>div': {
      height: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      '::after': {
        borderBottom: '0px solid transparent',
      },
      '>div>button': {
        backgroundColor: 'transparent !important',
        padding: '0px !important',
        margin: '8px',
        '>svg': {
          marginBottom: '0px !important',
        },
      },
    },
  },

  // DESCRIPCION
  description: {
    marginBottom: '30px',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  inputDescription: {
    backgroundColor: '#F1F3F8',
    borderRadius: '25px',
  },
  inputBaseDescription: {
    width: '100%',
    minHeight: 'inherit',
    fontSize: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '25px',
    '>textarea': {
      marginTop: '5px',
      marginBottom: 'auto',
    },
  },

  // FORMULARIO DE SUBPROCESOS
  formSubprocess: {
    marginTop: '-10px',
    boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',
  },
  buttonSubProcess: {
    padding: '0px',
    minWidth: 'inherit',
  },

  // CONTENEDOR BOTONES FORMULARIO
  divButton: {
    marginTop: '102px',
    marginX: 'auto',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '169.74px',
      // width: '169.74px',
      height: '33px',
      borderRadius: '20px',
      padding: '10px 20px',

      // TEXTO
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },

  buttonSave: {
    background: '#1F398F1A',
    color: '#1F398F',
    marginRight: '19.52px',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },

  // LISTA FORMULARIO SUBPROCESOS
  containerListForm: {
    height: 320,

    // SCROLL
    '&::-webkit-scrollbar': {
      width: '12px',
      size: '10px',
      '&-track': {
        backgroundColor: theme => theme.palette.secondary.main,
        borderRadius: '10px',
        border: '5px solid #ffffff',
      },
      '&-thumb': {
        backgroundColor: '#7282B8',
        borderRadius: '20px',
        width: '10px',
      },
    },
  },

  listForm: {
    marginBottom: '9px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContents: 'start',
    alignItems: 'center',
    '>p': {
      fontSize: '15px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
}

export default formProcessStyles
