import { Box } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { FC } from 'react'
import { Line } from 'react-chartjs-2'
import { dateToString } from '../../services/ParseDate'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface CustomDualGraphicProps {
  valueTs: number | undefined
  valueTi: number | undefined
  labelsApi: any[]
  valuesApi: any[]
}

// **************
// GRAFICA LINEAL
// **************
const CustomGraphicLine: FC<CustomDualGraphicProps> = ({ valueTs = 80, valueTi = 20, labelsApi, valuesApi }) => {
  // ******************
  // OPTIONS DE GRAFICA
  // ******************
  const options: any = {
    type: 'line',
    responsive: true,
    plugins: {
      // **********************
      // TITULOS DE DESCRIPCION
      // **********************
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
    grid: {
      padding: {
        top: 30,
        right: 30,
        bottom: 0,
        left: 30,
      },
    },
    // *******************
    // ESCALA AL 100% EN Y
    // *******************
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          callback: function (value: string) {
            return value + '%'
          },
        },
      },
    },
  }

  // **********
  // DATOS EN X
  // **********
  const labels = labelsApi
  // *****
  // DATOS
  // *****
  const dataNumber = valuesApi
  // *******************
  // TOLERANCIA SUPERIOR
  // *******************
  // const ts = 80
  const dataTs = labels.map(() => valueTs)

  // *******************
  // TOLERANCIA INFERIOR
  // *******************
  // const ti = 20
  const dataTi = labels.map(() => valueTi)

  // **************************
  // PROMEDIO ENTRE TOLERANCIAS
  // **************************
  const sumaPro = valueTs + valueTi
  const resulPro = sumaPro / 2
  const promedio = labels.map(() => resulPro)

  const data = {
    labels,
    datasets: [
      // *****************
      // DATOS DE LA TABLA
      // *****************
      {
        label: 'Meta',
        data: dataNumber,
        borderColor: '#1F398F',
        backgroundColor: '#1F398F',
        lineWidth: 0,
        borderWidth: 3,
        pointRadius: 2,
        pointHoverRadius: 2,
      },
      // *******************
      // TOLERANCIA SUPERIOR
      // *******************
      {
        label: 'Tolerancia Superior',
        data: dataTs,
        borderColor: '#7EBD52',
        backgroundColor: 'transparent',
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderDash: [5, 5],
      },
      // ********
      // PROMEDIO
      // ********
      {
        label: 'Promedio',
        data: promedio,
        borderColor: '#FCCC64',
        backgroundColor: 'transparent',
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderDash: [5, 5],
      },
      // *******************
      // TOLERANCIA INFERIOR
      // *******************
      {
        label: 'Tolerancia Inferior',
        data: dataTi,
        borderColor: '#D94A4A',
        backgroundColor: 'transparent',
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderDash: [5, 5],
      },
    ],
  }

  return (
    <Box>
      <Line data={data} options={options} />
    </Box>
  )
}

export default CustomGraphicLine
