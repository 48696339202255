/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ThemeProvider,
  createTheme,
  useTheme,
  Theme,
  Button,
} from '@mui/material'

// IMPORTADOS
import ErrorField from '../ErrorField'
import formStyles from '../forms/form.styles'
import ModalCreateCharge from '../modals/charge/ModalCreateCharge'
import IconAdd from '../icons/IconAdd'

interface ChargeProps {
  items: any[]
  name: string
  handleSelectChange: Function
  list?: Function
  defaultValue?: string
  errorMessage?: string
  buttonNew?: boolean
}
const Charge = ({
  items,
  name,
  handleSelectChange,
  list,
  defaultValue = '',
  errorMessage,
  buttonNew = true,
}: ChargeProps) => {
  // EVENTOS DEL SELECT CARGO
  const [charge, setCharge] = useState('')

  // TEMA Y ESTILOS
  const themeCharge = (): Theme => {
    // eslint-disable-next-line
    const theme: any = useTheme()

    return createTheme({
      components: {
        MuiMenu: {
          styleOverrides: {
            root: {
              '>.MuiPaper-elevation': {
                backgroundColor: '#ffffff',
                maxHeight: '250px',
                minHeight: '86px',
                borderRadius: '15px',
                overflowY: 'hidden',

                // UL
                '>ul': {
                  backgroundColor: 'inherit',
                  maxHeight: '168px',
                  maxWidth: '570px',
                  margin: '20px 23px 20px 23px',
                  paddingTop: '0px',
                  paddingBottom: '0px',

                  ':hover': {
                    backgroundColor: 'transparent',
                  },

                  // SCROLL
                  overflowY: items.length > 6 ? 'scroll' : 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                    size: '10px',
                    '&-track': {
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: '10px',
                      border: '5px solid #ffffff',
                    },
                    '&-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '20px',
                      width: '10px',
                    },
                  },

                  // LI
                  '>li': {
                    color: '#000000',
                    fontFamily: 'Poppins',
                    fontSize: '15px',
                    lineHeight: '22.5px',
                    paddingBottom: '5px',
                    ':hover': {
                      color: '#1F398F',
                      fontWeight: '600',
                      backgroundColor: 'inherit',
                    },
                  },

                  '>.Mui-selected': {
                    backgroundColor: 'inherit',
                    color: '#1F398F',
                    fontWeight: '600',
                    ':hover': {
                      backgroundColor: 'inherit',
                    },
                  },
                  '>.Mui-focusVisible': {
                    display: 'none',
                  },
                  '>.Mui-focused': {
                    display: 'none',
                  },
                },
              },
            },
          },
        },
      },
    })
  }

  // TEMA
  const myTheme = themeCharge()

  const [openModalCharge, setOpenModalCharge] = useState(false)

  const handleChange = (event: { target: { value: string; name: string } }) => {
    setCharge(event.target.value)
    handleSelectChange({ [event.target.name]: event.target.value })
  }

  useEffect(() => {
    setCharge(defaultValue)
  }, [items, defaultValue])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {/*********************
        CONTENEDOR INPUT
      **********************/}
        <Box
          sx={{
            ...formStyles.containerInputSelect,
            marginLeft: '19px',
            width: '96.5% !important',
          }}
        >
          <Typography>Cargo</Typography>

          <ThemeProvider theme={myTheme}>
            <FormControl sx={formStyles.inputSelect} variant="standard">
              <Select value={charge} onChange={handleChange} name={name}>
                {items.map((position: any, index: any) => (
                  <MenuItem disableTouchRipple value={position._id} focusRipple key={index}>
                    {position.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>
          <ErrorField message={errorMessage ?? ''} />
        </Box>
        {/* Modal Create Charge */}
        {buttonNew && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              sx={{ marginTop: '10px', marginLeft: '10px', borderRadius: "20px" }}
              variant="contained"
              onClick={() => setOpenModalCharge(true)}
            >
              <span style={{ marginRight: '5px' }}>Nuevo</span> <IconAdd color="#fff" />
            </Button>
            <ModalCreateCharge open={openModalCharge} setOpen={setOpenModalCharge} list={list} />
          </Box>
        )}
      </Box>
    </>
  )
}

export default Charge
