/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, InputAdornment, InputBase, Menu, MenuItem, Typography } from '@mui/material'

// IMPORTADOS
import doAssignmentStyles from '../DoAssignment.styles'
import formStyles from '../../../../../components/forms/form.styles'
import IconCloud from '../../../../../components/icons/IconCloud'
import IconArrowDown from '../../../../../components/icons/IconArrowDown'
import IconDownload from '../../../../../components/icons/IconDownload'
import { dateToString } from '../../../../../services/ParseDate'
import useFormula from '../../../../../hooks/do/useFormula'
import useAppDialog from '../../../../../hooks/useAppDialog'
import useImage from '../../../../../hooks/image/useImage'
import ErrorField from '../../../../../components/ErrorField'
import { ValidateFields, ValidateForm } from '../../../../../services/ValidateForm'
import { measureSchema } from '../../../../../schemas/do/measureSchema'
import { min } from 'rxjs'
import CustomSelect from '../../../../../components/inputSelect/CustomSelect'
import usersStyles from '../../../users/users.styles'
import useEvents from '../../../../../hooks/verify/events/useEvents'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

interface IFormDoAssignmentIndicating {
  data: any
  measureEdit: any
  listDataTable: Function
}
const FormDoAssignmentIndicating = ({ data, measureEdit, listDataTable }: IFormDoAssignmentIndicating) => {
  // ESTADO DE SELECT
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dataForm, setDataForm] = useState<any>({})
  const [date, setDate] = useState<any>()
  const [file, setFile] = useState<any>({})
  const open = Boolean(anchorEl)
  const { fetchCreateFormula, fetchUpdateFormula } = useFormula()
  const { showErrorDialog, showLoadingDialog, hideDialog } = useAppDialog()
  const { uploadImage } = useImage()
  const { getIndicatorEvents } = useEvents()
  const params = useParams()

  const [errors, setErrors] = useState<any>({})
  const [events, setEvents] = useState<any>([])
  const [event, setEvent] = useState<any>({})

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getEvents = async () => {
    if (params.idindicator) {
      const resp = await getIndicatorEvents(params.idindicator)
      setEvents(resp)
      if (params.idevent) {
        setEvent(resp.find((item: any) => item._id === params.idevent))
      }
    }
  }

  useEffect(() => {
    getEvents()
    setDataForm({
      ...dataForm,
      idIndicator: data.idIndicator?._id,
      idGoalPaIndicator: data.idIndicator?.idGoalpaIndicators[0]?._id,
      idPa: data.idIndicator?.idGoalpaIndicators[0]?.idPa,
      idPdi: data.idPdi,
      idTaskAssignments: data._id ?? '',
      result: '0',
      goal: data?.idIndicator?.idGoalpaIndicators[0]?.idIndicator?.goal ?? '0',
      idEvent: params?.idevent ?? undefined,
    })
    dateToday()
  }, [data])

  useEffect(() => {
    setDataForm({
      ...dataForm,
      ...measureEdit,
    })
  }, [measureEdit])

  const handleNumerator = async (e: any) => {
    if (e.target.value <= dataForm.goal) {
      const result = (Number(e.target.value) / Number(dataForm.goal)) * 100
      setDataForm({
        ...dataForm,
        numerator: e.target.value,
        result: Math.round(result),
      })
      const resultValidation = await ValidateFields({
        schema: measureSchema,
        data: { ...dataForm, [e.target.name]: e.target.value },
        fieldName: e.target.name,
      })
      setErrors(resultValidation)
    }
  }

  const handleInputChange = async (e: any) => {
    setDataForm({
      ...dataForm,
      [e.target.id]: e.target.value,
    })
    const result = await ValidateFields({
      schema: measureSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }

  const handleSelectChange = async (e: any) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    })
    setEvent(events.find((item: any) => item._id === e.target.value))
  }

  const handleInputFile = (e: any) => {
    setFile(e.target.files[0])
  }

  const handleDelFile = (e: any) => {
    setFile({})
  }

  const dateToday = () => {
    const date = new Date()
    setDate(dateToString(date))
  }

  const handleSubmit = async (e: any) => {
    showLoadingDialog()
    const result = await ValidateForm({
      schema: measureSchema,
      data: dataForm,
    })

    if (result.isValid) {
      if (file.name) {
        const formData = new FormData()
        formData.append('formulaUpload', file)
        const resImg: any = await uploadImage({ action: 'formulaUploadEvidence', form: formData })

        if (resImg.ok) {
          let resForm: any
          if (dataForm._id) {
            const { _id, numerator, mesurementJustification, pageNumber } = dataForm
            resForm = await fetchUpdateFormula({
              _id,
              numerator,
              mesurementJustification,
              pageNumber,
              uploadEvidence: resImg.data?.key,
              idEvent: event?._id ?? undefined,
            })
          } else {
            resForm = await fetchCreateFormula({ ...dataForm, uploadEvidence: resImg.data?.key })
          }
          setDataForm({
            idPa: data.idIndicator?.idGoalpaIndicators[0]?.idPa,
            idPdi: data.idPdi,
            idIndicator: data.idIndicator?._id,
            idGoalPaIndicator: data.idIndicator?.idGoal?._id,
            idTaskAssignments: data._id ?? '',
            result: '0',
            goal: data?.idIndicator?.idGoalpaIndicators[0]?.idIndicator?.goal ?? '0',
            idEvent: event?._id ?? undefined,
          })
          setFile({})
          if (resForm.ok) {
            listDataTable()
          }
        } else {
          showErrorDialog({ text: 'El archivo no se subio correctamente, vuelva a intentarlo' })
        }
      } else {
        showErrorDialog({ text: 'Debe cargar una evidencia' })
      }
    } else {
      setErrors(result.errors)
      hideDialog()
    }
  }

  const onCancel = () => {
    setDataForm({
      idIndicator: data.idIndicator?._id,
      idGoalPaIndicator: data.idIndicator?.idGoal?._id,
      idTaskAssignments: data._id ?? '',
      result: '0',
      goal: data?.idIndicator?.idGoalpaIndicators[0]?.idIndicator?.goal ?? '0',
    })
    setFile({})
    setErrors({})
  }

  return (
    <Box className="containerFormMeasureIndicating" id="containerFormMeasureIndicating">
      <Box sx={{ marginBottom: '100px' }}>
        <Box sx={{ width: { md: '50%', xs: '100%' }, marginX: 'auto', marginBottom: '30px' }}>
          <CustomSelect
            name="idEvent"
            propValue="_id"
            propText="name"
            items={events}
            onChange={handleSelectChange}
            value={event?._id ?? ''}
          />
          <ErrorField message={errors['idMeasureUnit'] ?? ''} />
        </Box>

        {event?._id && (
          <Box className="containerTable">
            <Box sx={{ ...usersStyles.userTable, marginTop: '0px !important' }}>
              <Box sx={doAssignmentStyles.title}>
                <Typography>Registro de Medición</Typography>
              </Box>
              <Box sx={{ ...formStyles.containerTitle }}>
                <Typography sx={formStyles.title}>Justificación</Typography>
                <Typography>{event.justification}</Typography>
              </Box>
              <Box sx={{ ...formStyles.containerTitle }}>
                <Typography sx={formStyles.title}>Fecha inicial</Typography>
                <Typography>{event.startDate.split('T')[0]}</Typography>
              </Box>
              <Box sx={{ ...formStyles.containerTitle }}>
                <Typography sx={formStyles.title}>Fecha final</Typography>
                <Typography>{event.finalDate.split('T')[0]}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {/********************
        TITULO DE FORMULA
      ********************/}
      <Typography className="titleForm">Formula de Medición</Typography>

      {/***********
        FORMULA
      ***********/}
      <Box className="formula">
        {/****************
          NUMERADOR
        *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Númerador</Typography>

          {/*******************
            INPUT NUMERADOR
          *******************/}
          <Box sx={formStyles.input}>
            <InputBase
              sx={{ ...formStyles.inputBase, '>input': { paddingLeft: '15px' } }}
              endAdornment={<InputAdornment position="end">{data?.idIndicator?.idMeasureUnit?.acronym}</InputAdornment>}
              value={dataForm.numerator ?? ''}
              placeholder="0"
              name="numerator"
              onChange={handleNumerator}
              type="number"
              inputProps={{
                min: '1',
                pattern: '^[0-9]+',
              }}
            />
            <ErrorField message={errors?.numerator ?? ''} />
          </Box>
        </Box>

        {/****************
          DENOMINADOR
        *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Denominador</Typography>

          {/*******************
            INPUT DENOMINADOR
          *******************/}
          <Box sx={formStyles.input}>
            <InputBase sx={formStyles.inputBase} value={dataForm.goal ?? 0} disabled />
          </Box>
        </Box>

        <span>=</span>

        {/****************
          RESULTADO
        *****************/}
        <Box sx={formStyles.containerTitle}>
          <Typography sx={formStyles.title}>Resultado</Typography>

          {/*******************
            INPUT RESULTADO
          *******************/}
          <Box sx={formStyles.input}>
            <InputBase
              sx={{ ...formStyles.inputBase, '>input': { paddingLeft: '15px' } }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              value={dataForm.result ?? 0}
              disabled
            />
          </Box>
        </Box>
      </Box>

      {/*********
        INPUTS
      *********/}
      <Box className="inputsLaterales">
        {/*******
          META
        ********/}
        <Box sx={formStyles.containerTitleHorizontal}>
          <Typography sx={formStyles.titleHorizontal}>Meta</Typography>

          {/*************
            INPUT META
          *************/}
          <Box sx={{ ...formStyles.input, width: '75px' }}>
            <InputBase sx={formStyles.inputBase} value={dataForm.goal} disabled />
          </Box>
        </Box>

        {/*******************
          FECHA DE LECTURA
        ********************/}
        <Box sx={formStyles.containerTitleHorizontal}>
          <Typography sx={formStyles.titleHorizontal}>Fecha de lectura</Typography>

          {/**************************
            INPUT FECHA DE LECTURA
          **************************/}
          <Box sx={{ ...formStyles.input, width: '170px' }}>
            <InputBase sx={formStyles.inputBase} value={date} disabled />
          </Box>
        </Box>

        {/************
          N° PAGINA
        *************/}
        <Box className="Npagina" sx={formStyles.containerTitleHorizontal}>
          <Typography className="textNpagina">
            Digitar el N° de página donde se encuentra el cumplimiento de la evidencia del indicador.
          </Typography>

          {/*******************
            INPUT N° PAGINA
          *******************/}
          <Box sx={{ ...formStyles.input, width: '75px' }}>
            <InputBase
              sx={formStyles.inputBase}
              id="pageNumber"
              name="pageNumber"
              type="number"
              value={dataForm.pageNumber ?? ''}
              onChange={handleInputChange}
            />
            <ErrorField message={errors?.pageNumber ?? ''} />
          </Box>
        </Box>

        <label htmlFor="upload">
          {!file.name && (
            <input style={{ display: 'none' }} id="upload" name="upload" type="file" onChange={handleInputFile} />
          )}

          <Button
            sx={{ ...doAssignmentStyles.btnEvidence, marginRight: '29px' }}
            component="span"
            onClick={handleDelFile}
          >
            <IconCloud /> {file.name ? 'quitar' : 'Subir evidencia'}
          </Button>
        </label>

        <Button className="format" sx={doAssignmentStyles.btnEvidence} onClick={handleClick}>
          <IconDownload width={10.4} height={13} /> <span>Mis formatos</span> <IconArrowDown />
        </Button>

        <Menu
          id="basic-menu"
          className="format-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={doAssignmentStyles.menu}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>.PDF</MenuItem>
          <MenuItem onClick={handleClose}>.DOCX</MenuItem>
          <MenuItem onClick={handleClose}>.XLSX</MenuItem>
          <MenuItem onClick={handleClose}>.PPTX</MenuItem>
        </Menu>
      </Box>

      {/****************
        JUSTIFICACION
      *****************/}
      <Box sx={formStyles.containerTitle}>
        <Typography sx={formStyles.title}>Justifique su medición</Typography>

        {/*******************
          INPUT DENOMINADOR
        *******************/}
        <Box sx={formStyles.inputDescription}>
          <InputBase
            sx={formStyles.inputBaseDescription}
            id="mesurementJustification"
            name="mesurementJustification"
            value={dataForm.mesurementJustification ?? ''}
            onChange={handleInputChange}
          />
        </Box>
        <ErrorField message={errors?.mesurementJustification ?? ''} />
      </Box>

      {/**********************
        CONTENEDOR BOTONES 
      ***********************/}
      <Box sx={{ ...formStyles.divButton, marginTop: '30px' }}>
        {/*****************************
          BOTONES GUARDAR/CANCELAR
        ******************************/}
        <div>
          {/********************
            BOTON GUARDAR
          *********************/}
          <Button
            disableTouchRipple
            type="submit"
            // disabled={!isValid}
            sx={{
              ...formStyles.buttonSave,
              maxWidth: '196.741px !important',
              width: '196.741px !important',
              backgroundColor: '#7282B8',
              color: '#ffffff',
              ':hover': { backgroundColor: '#7282B8' },
            }}
            onClick={handleSubmit}
          >
            Guardar
          </Button>

          {/********************
            BOTON CANCELAR
          *********************/}
          <Button
            onClick={onCancel}
            disableTouchRipple
            sx={{
              ...formStyles.buttonCancel,
              maxWidth: '196.741px !important',
              width: '196.741px !important',
            }}
          >
            Cancelar
          </Button>
        </div>
      </Box>
    </Box>
  )
}

export default FormDoAssignmentIndicating
