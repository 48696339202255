import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import IconWhatHappened from '../../../components/icons/IconWhatHappened'

const noticiasInfo = [
  {
    nombre: 'Daniel Osorio Serna.',
    fecha: '15 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Leornardo Osorio Ramirez.',
    fecha: '15 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Viviana Leon Montoya.',
    fecha: '16 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Andrea Bustamante.',
    fecha: '16 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Emmanuel Gómez Sanchéz.',
    fecha: '16 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Luis Carrillo López.',
    fecha: '17 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Luis Carrillo López.',
    fecha: '17 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Luis Carrillo López.',
    fecha: '17 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
  {
    nombre: 'Luis Carrillo López.',
    fecha: '17 Sept 2022',
    comentario: 'Envió un documento a flujo.',
  },
]

const WhatHappened = () => {
  return (
    <>
      {/*******************************
        NOTICIAS - QUE HA PASADO...
      ********************************/}
      <Box
        sx={{
          backgroundColor: '#ffffff',
          width: '346px',
          height: '616px',
          '@media (max-width: 1440px)': {
            width: '100%',
          },
          '>div': {
            display: 'flex',
            alignItems: 'center',
            margin: '49px 25px auto 47px',
            '>svg': {
              marginRight: '7px',
            },
            '>p': {
              fontSize: '20px',
              color: '#1F398F',
              fontWeight: '400',
            },
          },
        }}
      >
        <Box>
          {/*****************
            QUE HA PASADO?
          *****************/}
          <IconWhatHappened />

          {/*********
            TITULO
          *********/}
          <Typography>Qué ha pasado...</Typography>
        </Box>

        {/*******************************
          CONTENEDOR LISTA - NOTICIAS
        *********************************/}
        <Box
          sx={{
            // backgroundColor: "pink",
            flexDirection: 'column',

            // SCROLLBAR NOTICIAS
            height: '440px',
            marginTop: '0px !important',
            paddingTop: '23px',
            scrollbarWidth: 'thin',
            overflowY: noticiasInfo.length > 5 ? 'scroll' : 'hidden',
            '&::-webkit-scrollbar': {
              width: '12px',
              height: '100px',
              size: '10px',
              '&-track': {
                backgroundColor: theme => theme.palette.secondary.main,
                borderRadius: '10px',
                border: '5px solid #ffffff',
              },
              '&-thumb': {
                backgroundColor: '#1F398F',
                borderRadius: '20px',
                width: '10px',
              },
            },
          }}
        >
          {noticiasInfo.map(({ nombre, fecha, comentario }, index) => (
            <List
              key={index + nombre}
              sx={{
                // backgroundColor: "red",
                marginLeft: '0px',
                marginRight: 'auto',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  '>div': {
                    margin: '0px auto auto 0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingBottom: '10px',
                    '>span': {
                      fontSize: '15px !important',
                      fontWeight: '600 !important',
                      lineHeight: '22.5px !important',
                    },
                  },
                  '>p': {
                    margin: '0px auto auto 0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    fontSize: '10px !important',
                    fontWeight: '300 !important',
                    lineHeight: '18px !important',
                  },
                }}
              >
                {/**********
                                                LISTA
                                            ***********/}
                <ListItemText primary={nombre} secondary={fecha} />

                <Typography>{comentario}</Typography>
              </ListItem>
            </List>
          ))}
        </Box>

        <Box
          sx={{
            marginTop: '0px !important',
            '>a': {
              color: '#1F398F',
              width: '100%',
              textAlign: 'right',
              paddingRight: '10px',
              fontSize: '12px !important',
              lineHeight: '18px !important',
              fontWeight: '300 !important',
              fontStyle: 'normal !important',
              textDecoration: 'none',
            },
          }}
        >
          <Typography component="a" href="#">
            Ver más...
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default WhatHappened
