/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, ListItemText, Pagination, Typography } from '@mui/material'
import usersStyles from '../../users/users.styles'
import Spinner from '../../../../components/utilities/Spinner'
import useTasks from '../../../../hooks/useTasks'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DropdownOptions from './DropdownOptions'
import WhoNotifyPopopver from './tabsTaskAssignment/WhoNotifyPopover'
import CustomTitle from '../../../../components/CustomTitle'
import { useNavigate } from 'react-router-dom'

const ViewTaskAssignment = () => {
  const navigate = useNavigate()

  const [data, setData] = useState<any[]>([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { fetchGetTaskAssigment } = useTasks()

  const list = async () => {
    setLoading(true)
    const response: any = await fetchGetTaskAssigment({ from, limit })
    setData(response.items)
    const pages = response.total / limit

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    list()
  }, [page])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  return (
    <>
      {/************************************
        CONTENEDOR ASIGNACION DE TAREAS
      *************************************/}
      <Box sx={usersStyles.container}>
        {/**********************************
          CONTENEDOR TITULO Y BOTON CREAR
        ***********************************/}
        <CustomTitle title="Asignacion de responsabilidades" display={'none'}>
          <Button onClick={() => navigate('/plan/taskAssignment')}>Crear asignaciones</Button>
        </CustomTitle>

        {/***********************************
          TABLA DE ASIGNACION DE TAREAS
        ************************************/}
        <Box sx={usersStyles.userTable}>
          <table>
            {/***********
              TITULOS
            ************/}
            <thead>
              <tr>
                <th>Nombre del indicador</th>
                <th>¿Quién mide?</th>
                <th>¿A quién notifica?</th>
                <th>Fecha de Iniciación</th>
                <th></th>
              </tr>
            </thead>

            {/***********************************
              LISTA DE ASIGNACION DE TAREAS
            ************************************/}
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} rowSpan={5}>
                    <Box
                      sx={{
                        height: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </Box>
                  </td>
                </tr>
              ) : (
                data.map(item => (
                  <tr key={item._id}>
                    <td>
                      <Box>{item.idIndicator?.name || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <Box>
                        {item.idUser?.idProfile?.name} {item.idUser?.idProfile?.lastName || 'Sin Datos'}
                      </Box>
                    </td>
                    <td>
                      {item.userNotifies?.length > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {item.userNotifies?.slice(0, 1).map((subItem: any) => (
                            <Box>{`${subItem.idProfile?.name} ${subItem.idProfile?.lastName}${
                              item.userNotifies?.length > 1 ? ',' : ''
                            }`}</Box>
                          ))}
                          {item.userNotifies?.length > 1 && (
                            <Box
                              sx={{
                                marginLeft: '10px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <ListItemText secondary={`...`} />
                              <WhoNotifyPopopver whoNotify={item.userNotifies} indexInicial={1} />
                            </Box>
                          )}
                        </Box>
                      )}
                    </td>
                    <td>
                      <Box>{item.complianceDate || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <DropdownOptions item={item} list={list} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>

        {/****************
                    PAGINACION
                ****************/}
        <Box sx={usersStyles.pagesList}>
          <Pagination count={pages} page={page} onChange={changePage} />
        </Box>
      </Box>
    </>
  )
}

export default ViewTaskAssignment
