import { FunctionComponent } from 'react'
interface IconCircleCheckModalProps {
  width?: number | string
  height?: number | string
}

const IconCircleCheck: FunctionComponent<IconCircleCheckModalProps> = ({ width = 25, height = 25 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.75 14.75L8.03125 12.0312C7.80208 11.8021 7.51583 11.6929 7.1725 11.7037C6.82833 11.7137 6.54167 11.8333 6.3125 12.0625C6.08333 12.2917 5.96875 12.5833 5.96875 12.9375C5.96875 13.2917 6.08333 13.5833 6.3125 13.8125L9.875 17.375C10.1042 17.6042 10.3958 17.7187 10.75 17.7187C11.1042 17.7187 11.3958 17.6042 11.625 17.375L18.7188 10.2813C18.9479 10.0521 19.0575 9.76542 19.0475 9.42125C19.0367 9.07792 18.9167 8.79167 18.6875 8.5625C18.4583 8.33333 18.1667 8.21875 17.8125 8.21875C17.4583 8.21875 17.1667 8.33333 16.9375 8.5625L10.75 14.75ZM12.5 25C10.7708 25 9.14583 24.6717 7.625 24.015C6.10417 23.3592 4.78125 22.4688 3.65625 21.3438C2.53125 20.2188 1.64083 18.8958 0.985 17.375C0.328333 15.8542 0 14.2292 0 12.5C0 10.7708 0.328333 9.14583 0.985 7.625C1.64083 6.10417 2.53125 4.78125 3.65625 3.65625C4.78125 2.53125 6.10417 1.64042 7.625 0.98375C9.14583 0.327917 10.7708 0 12.5 0C14.2292 0 15.8542 0.327917 17.375 0.98375C18.8958 1.64042 20.2188 2.53125 21.3438 3.65625C22.4688 4.78125 23.3592 6.10417 24.015 7.625C24.6717 9.14583 25 10.7708 25 12.5C25 14.2292 24.6717 15.8542 24.015 17.375C23.3592 18.8958 22.4688 20.2188 21.3438 21.3438C20.2188 22.4688 18.8958 23.3592 17.375 24.015C15.8542 24.6717 14.2292 25 12.5 25ZM12.5 22.5C15.2708 22.5 17.6304 21.5263 19.5788 19.5788C21.5263 17.6304 22.5 15.2708 22.5 12.5C22.5 9.72917 21.5263 7.36958 19.5788 5.42125C17.6304 3.47375 15.2708 2.5 12.5 2.5C9.72917 2.5 7.37 3.47375 5.4225 5.42125C3.47417 7.36958 2.5 9.72917 2.5 12.5C2.5 15.2708 3.47417 17.6304 5.4225 19.5788C7.37 21.5263 9.72917 22.5 12.5 22.5Z"
        fill="#7282B8"
      />
    </svg>
  )
}

export default IconCircleCheck
