/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import useFetch, { BasicFetchListProps } from '../../useFetch'
import useAppDialog from '../../useAppDialog'

const useEvents = () => {
  const { fetchList, fetchCreate,fetchUpdate, fetchFindOne } = useFetch()
  const { showErrorDialog, showLoadingDialog, showSuccessDialog } = useAppDialog()

  const getEvents = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getEvents',
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const getMyEvents = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<any>({
        action: 'getMyEvents',
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchList]
  )

  const getIndicatorEvents = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneAssignmentByIndicator',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const createEvents = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchCreate({ action: 'createEvents', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Eventos creados exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchCreate]
  )
  
  const updateEvent = useCallback(
    async (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      return await fetchUpdate({ action: 'eventUpdate', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Evento actualizado exitosamente!.',
          })
          return { ok: true }
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
          return { ok: false }
        })
    },
    [fetchUpdate]
  )

  return {
    getEvents,
    getMyEvents,
    getIndicatorEvents,
    createEvents,
    updateEvent,
  }
}

export default useEvents
