import { StyleComponent } from '../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'containerTitle'
  | 'title'
  | 'span'
  | 'titleSearch'
  | 'containerTitleHorizontal'
  | 'titleHorizontal'
  | 'input'
  | 'inputBase'
  | 'inputDescription'
  | 'inputBaseDescription'
  | 'containerFlex'
  | 'containerGrid'
  | 'contentGrid'
  | 'contentGridThree'
  | 'containerState'
  | 'checkbox'
  | 'containerInputSelect'
  | 'inputSelect'
  | 'inputSmall'
  | 'percentageOfCompletion'
  | 'buttonTitle'
  | 'divButton'
  | 'buttonSave'
  | 'buttonCancel'
  | 'containerPaginationArrow'
  | 'pagination'
  | 'paginationButtonSearch'
  | 'paginationCenter'

const formStyles: StyleComponent<Keys> = {
  container: {
    backgroundColor: '#ffffff',
    paddingTop: '5px',
  },

  // CONTENEDOR TITULO Y SUBTITULO
  containerTitle: {
    marginBottom: '30px',
  },
  title: {
    fontSize: '20px',
    color: '#1F398F',
    fontWeight: '600',
    marginBottom: '9px',
  },
  span: {
    fontSize: '16px',
    fontWeight: '500',
  },

  // TITULO, BOTONES CREAR Y BUSCAR
  titleSearch: {
    maxWidth: '1350px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 1425px)': {
      marginX: '30px',
    },

    // TYPOGRAPHY
    '>p': {
      color: '#1F398F',
      fontSize: '25px',
      fontWeight: '600',
      fontStyle: 'normal',
    },

    // BOTONES CREAR Y BUSCAR
    '>div': {
      marginY: 'auto',
      display: 'flex',

      // BOTON BUSCAR
      '>.buttonSearch': {
        maxWidth: '268px',
        // "@media(max-width: 1440px)": {
        //     width: "30vw",
        // },

        '>div': {
          maxWidth: '268px',
          minHeight: '41px',
          color: '#1F398F',
          marginLeft: 'auto',
          borderRadius: '500px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          '>button:hover': {
            backgroundColor: 'transparent',
          },
          '>div>input': {
            color: '#1F398F',
            fontSize: '12px',
            fontWeight: '400',
            width: '70%',
            '@media(max-width: 590px)': {
              width: '70%',
            },
          },
        },
      },
    },
  },

  // CONTENEDOR TITULO Y SUBTITULO HORIZONTAL
  containerTitleHorizontal: {
    marginBottom: '30px',
    display: { xs: 'block', md: 'flex' },
    alignItems: 'center',
  },
  titleHorizontal: {
    fontSize: '20px',
    color: '#1F398F',
    fontWeight: '600',
    marginRight: '20px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },

  // INPUT
  input: {
    height: '36px',
    borderRadius: '200px',
    backgroundColor: '#F1F3F8',
  },
  inputBase: {
    width: '100%',
    height: '100%',
    color: '#000000',
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '500px',
  },

  // DESCRIPCION
  inputDescription: {
    backgroundColor: '#F1F3F8',
    borderRadius: '25px',
    minHeight: '143px',
  },
  inputBaseDescription: {
    width: '100%',
    minHeight: 'inherit',
    color: '#000000',
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '25px',
    alignItems: 'flex-start',
    '>textarea': {
      marginTop: '5px',
      marginBottom: 'auto',

      // SCROLL
      '&::-webkit-scrollbar': {
        width: '12px',
        size: '10px',
        '&-track': {
          backgroundColor: theme => theme.palette.secondary.main,
          borderRadius: '10px',
          border: '5px solid #F1F3F8',
        },
        '&-thumb': {
          backgroundColor: theme => theme.palette.primary.main,
          borderRadius: '20px',
          width: '10px',
        },
      },
    },
  },

  // CONTENEDOR FLEX
  containerFlex: {
    display: { xs: 'block', md: 'flex' },
  },

  // CONTENEDOR GRID
  containerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(50%, 1fr))',
    width: '1110px',
    margin: 'auto',
  },

  contentGrid: {
    width: { xs: '100%', md: '50%' },
  },

  contentGridThree: {
    width: { xs: '100%', md: '50%' },

    '@media (max-width: 1150px)': {
      width: '100%',
    },
  },

  // ESTADO
  containerState: {
    // CONTENEDOR CHECKBOX DE ESTADO
    '>div': {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'row',

      // CHECKBOX DE ESTADO
      '>label': {
        marginLeft: '0px',

        // TAMAÑO LETRA
        '>.MuiFormControlLabel-label': {
          fontSize: '20px !important',
        },

        // CHECKBOX
        '>.MuiCheckbox-root': {
          marginLeft: '10px',
          marginRight: '10px',
          padding: '0px',
          borderRadius: '2px',
          ':hover': {
            backgroundColor: '#1F398F1A !important',
          },
        },

        // SELECCIONADO
        '>.Mui-checked': {
          backgroundColor: '#7282B8',
          width: '14px',
          height: '14px',
          ':hover': {
            backgroundColor: '#7282B8 !important',
          },
        },
      },

      // CONTENEDOR INACTIVO
      '>.inactivo': {
        marginLeft: '50px',
      },
    },
  },

  checkbox: {
    // CHECKED
    '>div, >div>div': {
      minWidth: '37px',
      '>span, >input': {
        fontSize: '16px',
        color: '#000000 !important',
        marginLeft: '0px',
        padding: '0px',
      },

      // SELECCIONADO
      '>.Mui-checked': {
        backgroundColor: '#7282B8',
        width: '14px',
        height: '14px',
      },
    },
  },

  // INPUT SELECT
  containerInputSelect: {
    width: '60%',
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  inputSelect: {
    backgroundColor: '#ffffff',
    borderRadius: '200px',
    width: '100%',
    '>div': {
      backgroundColor: '#F1F3F8',
      borderRadius: '200px',
      height: '39px',
      ':before, :after': {
        display: 'none',
      },

      '>div': {
        backgroundColor: '#F1F3F8 !important',
        height: '39px !important',
        borderRadius: '200px !important',
        padding: '0px 0px 0px 10px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        '>button': {
          backgroundColor: 'red',
          display: 'none',
        },
      },
      '>svg': {
        backgroundColor: 'transparent',
        fontSize: '50px !important',
        '>path': {
          fill: '#1F398F',
        },
      },
    },
  },

  inputSmall: {
    backgroundColor: '#F1F3F8',
    height: '36px',
    width: '100%',
    borderRadius: '200px',
  },

  percentageOfCompletion: {
    my: 3,
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
    '>span': {
      fontSize: '20px',
      fontWeight: '500',
    },
  },

  // BOTON TITULO
  buttonTitle: {
    maxWidth: '1350px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    '@media(max-width: 1630px)': {
      marginX: '10px',
    },

    // TYPOGRAPHY
    '&>p': {
      color: '#1F398F',
      fontWeight: '600',
      lineHeight: '75px',
      fontStyle: 'normal',
    },

    // BOTON MÁS
    '>div': {
      display: 'flex',
      marginY: 'auto',
      alignItems: 'center',

      '>a, >button': {
        backgroundColor: '#ffffff',
        height: '38px',
        marginLeft: '20px',
        padding: '0px 15px',
        textTransform: 'inherit',
        borderRadius: '92px',
        boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

        ':hover': {
          backgroundColor: '#7282B8',
          '>svg': {
            '>path': {
              fill: '#ffffff',
            },
          },
          '>p': {
            color: '#ffffff',
          },
        },

        '>p': {
          fontSize: '16px',
          marginLeft: '4px',
        },
      },
    },
  },

  // CONTENEDOR BOTONES
  divButton: {
    marginTop: '102px',
    marginX: 'auto',
    width: 'fit-content',

    '&>div>Button': {
      maxWidth: '169.74px',
      // width: '169.74px',
      height: '33px',
      borderRadius: '20px',
      marginRight: '10.51px',
      padding: '10px 20px',

      // TEXTO
      fontSize: '15px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
  },
  buttonSave: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },

  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },

  // CONTENEDOR BUSCADOR/PAGINACION
  containerPaginationArrow: {
    display: 'flex',

    '@media (max-width: 540px)': {
      flexDirection: 'column-reverse',

      '>nav': {
        marginBottom: '5px',
      },
    },

    // BUSCADOR
    '.buttonSearch': {
      maxWidth: '228px',
    },

    // PAGINACION
    '>nav': {
      width: 'fit-content',
      height: '40px',
      alignSelf: 'end',
      // marginTop: "-40px",
      marginLeft: 'auto',

      '>ul': {
        justifyContent: 'space-between',
        flexWrap: 'inherit',
        '>li': {
          '>.Mui-disabled': {
            visibility: 'hidden',
          },

          // BOTON DE NUMEROS
          '>.MuiPaginationItem-page': {
            display: 'none',
          },

          // BOTON DE FLECHAS
          '>.MuiPaginationItem-previousNext': {
            backgroundColor: 'transparent',
            // backgroundColor: "red",
            height: '100%',
            ':hover': {
              backgroundColor: 'transparent',
            },
            '>svg': {
              fontSize: '2.5rem !important',
              '>path': {
                color: '#7282B8',
              },
            },
          },
        },
      },
    },
  },

  // PAGINACION
  pagination: {
    '>nav': {
      width: 'fit-content',
      height: '40px',
      alignSelf: 'end',
      // marginTop: "-40px",
      marginLeft: 'auto',

      '>ul': {
        justifyContent: 'space-between',
        flexWrap: 'inherit',
        '>li': {
          '>.Mui-disabled': {
            visibility: 'hidden',
          },

          // BOTON DE NUMEROS
          '>.MuiPaginationItem-page': {
            display: 'none',
          },

          // BOTON DE FLECHAS
          '>.MuiPaginationItem-previousNext': {
            backgroundColor: 'transparent',
            height: '100%',
            '>svg': {
              fontSize: '2.5rem !important',
              '>path': {
                color: '#7282B8',
              },
            },
          },
        },
      },
    },
  },

  paginationButtonSearch: {
    display: 'flex',
    flexDirection: 'column',

    // PAGINACION
    '>nav': {
      width: '100%',
      height: '38px',
      '>ul': {
        justifyContent: 'space-between',
        '>li': {
          '>.Mui-disabled': {
            visibility: 'hidden',
          },

          // BOTON DE NUMEROS
          '>.MuiPaginationItem-page': {
            display: 'none',
          },

          // BOTON DE FLECHAS
          '>.MuiPaginationItem-previousNext': {
            backgroundColor: 'transparent',
            height: '100%',
            '>svg': {
              fontSize: '2.5rem !important',
              '>path': {
                color: '#7282B8',
              },
            },
          },
        },
      },
    },

    // BOTON
    '>button': {
      backgroundColor: '#7282B8',
      marginTop: '-38px',
      width: '144px',
      height: '38px',
      marginX: 'auto',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '15px',

      ':hover': {
        backgroundColor: '#7282B8',
      },
    },
  },

  paginationCenter: {
    // backgroundColor: 'pink',
    display: 'flex',
    justifyContent: 'center',
  },
}

export default formStyles
