import { Box } from '@mui/material'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FunctionComponent, MouseEvent, ReactNode } from 'react';
import ConfirmAlert from '../../Alerts/confirmAlert/ConfirmAlert';
import IconDirection from '../../icons/IconDirection';
import cardItemPdiStyles from './cardItemPdi.styles';

interface CardItemPdi {
    title: string | number,
    text: string | number,
    smallText: string | number
    textButtonLeft?: string,
    textButtonRight?: string,
    textButtonDetail?: ReactNode | string
    textConfirmButtonInActionRight?: string
    textBottomConfirmButtonInActionRight?: string
    onClickButtonLeft?: React.MouseEventHandler<HTMLButtonElement>
    onClickButtonRight?: React.MouseEventHandler<HTMLButtonElement>,
    onClickButtonDetail?: React.MouseEventHandler<HTMLDivElement>,
}

const CardItemPdi: FunctionComponent<CardItemPdi> = ({
    title,
    text,
    smallText,
    textButtonLeft = 'Ok',
    textButtonRight = 'Cancelar',
    textButtonDetail = <IconDirection />,
    textConfirmButtonInActionRight = '',
    textBottomConfirmButtonInActionRight = '',
    onClickButtonLeft,
    onClickButtonRight,
    onClickButtonDetail

}) => {

    return (
        <Box sx={cardItemPdiStyles.cardContent}>

            {/* Header */}
            <Box sx={cardItemPdiStyles.header}>
                <Box component='div' onClick={onClickButtonDetail}
                    sx={cardItemPdiStyles.iconRight}
                >
                    {textButtonDetail}
                </Box>
            </Box>

            {/* Body */}
            <Box sx={cardItemPdiStyles.body}>
                <Typography noWrap sx={cardItemPdiStyles.title}>
                    {title}
                </Typography>
                <Typography noWrap sx={cardItemPdiStyles.text}>
                    {text}
                </Typography>
            </Box>

            {/* Footer */}
            <Box sx={cardItemPdiStyles.footer}>
                <Box sx={cardItemPdiStyles.footerContentLeft}>
                    <Typography noWrap sx={cardItemPdiStyles.smallText}>
                        {smallText}
                    </Typography>
                </Box>
                <Box sx={cardItemPdiStyles.footerContentRight}>
                    <Button
                        disableTouchRipple
                        sx={cardItemPdiStyles.buttonAction}
                        onClick={onClickButtonLeft}
                    >
                        {textButtonLeft}
                    </Button>

                    <Button
                        onClick={onClickButtonRight}
                        disableTouchRipple
                        sx={cardItemPdiStyles.buttonCancel}
                    >
                        {textButtonRight}
                    </Button>

                </Box>
            </Box>
        </Box>
    )
}

export default CardItemPdi;