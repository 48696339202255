import { FunctionComponent, useEffect, useState } from 'react'
import ContainerRelations from '../../../../../components/containers/ContainerRelations/ContainerRelations'
import RelationFactors from './RelationFactors'
import RelationConditions from './RelationConditions'
import { Factor } from '../../../../../interfaces/factor'
import RelationIndicatorsFactor from './RelationIndicatorsFactor'
import useRelations from '../../../../../hooks/pdi/relations/useRelations'
import { Condition } from '../../../../../interfaces/condition'
import { NewRelIndicatorFactor } from '../../../../../interfaces/indicator-factor'
import useAppDialog from '../../../../../hooks/useAppDialog'
import { NewRelIndicatorCondition } from '../../../../../interfaces/indicator-condition'
import RelationIndicatorsCondition from './RelationIndicatorsCondition'

const ConditionsAndFactorsRelations: FunctionComponent = () => {
  // Cambiar el nombre del método para extraer toda la data de factores y condiciones
  const {
    indicators,
    factors,
    conditions,
    indicatorsFactors,
    indicatorsConditions,
    getFactorAndConditionData,
    getIndicatorsFactors,
    getIndicatorsConditions,
    createRelationIndicatorFactor,
    createRelationIndicatorConditon,
  } = useRelations()

  const { showWarningDialog } = useAppDialog()

  const [selectedFactor, setSelectedFactor] = useState<Factor | null>(null)

  const [changedRelationshipsFactor, setChangedRelationshipsFactor] = useState(false)

  const [selectedCondition, setSelectedCondition] = useState<Condition | null>(null)

  const [changedRelationshipsCondition, setChangedRelationshipsCondition] = useState(false)

  useEffect(() => {
    getFactorAndConditionData()
  }, [])

  const handleChangedRelationshipsFactor = (changed: boolean) => {
    setChangedRelationshipsFactor(changed)
  }

  const handleChangedRelationshipsCondition = (changed: boolean) => {
    setChangedRelationshipsCondition(changed)
  }

  const handleChangeFactor = (factor: Factor | null, hideRelationWarning = false) => {
    if (factor && !indicatorsFactors) {
      getIndicatorsFactors({
        fail: () => {
          setSelectedFactor(null)
          handleChangedRelationshipsFactor(false)
        },
      })
    }

    if (!changedRelationshipsFactor || !selectedFactor || hideRelationWarning) {
      handleChangedRelationshipsFactor(false)
      return setSelectedFactor(factor)
    }
    showWarningDialog({
      title: 'Atención!',
      text: `Hay cambios en las relaciones para el factor actual, si continua sin guardar, estos cambios se perderan.`,
      cancelButton: {
        text: 'Cancelar',
      },
      confirmButton: {
        text: 'Continuar',
        onClick: () => {
          setSelectedFactor(factor)
          handleChangedRelationshipsFactor(false)
        },
      },
    })
  }

  const handleChangeCondition = (condition: Condition | null, hideRelationWarning = false) => {
    if (condition && !indicatorsConditions) {
      getIndicatorsConditions({
        fail: () => {
          setSelectedCondition(null)
          handleChangedRelationshipsCondition(false)
        },
      })
    }
    if (!changedRelationshipsCondition || !selectedCondition || hideRelationWarning) {
      handleChangedRelationshipsCondition(false)
      return setSelectedCondition(condition)
    }
    showWarningDialog({
      title: 'Atención!',
      text: `Hay cambios en las relaciones para el factor actual, si continua sin guardar, estos cambios se perderan.`,
      cancelButton: {
        text: 'Cancelar',
      },
      confirmButton: {
        text: 'Continuar',
        onClick: () => {
          handleChangedRelationshipsCondition(false)
          setSelectedCondition(condition)
        },
      },
    })
  }

  const handleSaveRelationsFactor = (relIndicatorFactor: NewRelIndicatorFactor) => {
    createRelationIndicatorFactor({
      relIndicatorFactor,
      fail: () => handleChangeFactor(null, true),
    })
  }
  const handleSaveRelationsCondition = (relIndicatorCondition: NewRelIndicatorCondition) => {
    createRelationIndicatorConditon({
      relIndicatorCondition,
      fail: () => handleChangeCondition(null, true),
    })
  }

  return (
    <>
      <ContainerRelations>
        <ContainerRelations.Left>
          <RelationFactors factors={factors} selectedFactor={selectedFactor} onChangeFactor={handleChangeFactor} />
        </ContainerRelations.Left>
        {selectedFactor && indicatorsFactors && indicators && (
          <ContainerRelations.Right>
            <RelationIndicatorsFactor
              selectedFactor={selectedFactor}
              indicatorsFactors={indicatorsFactors}
              indicators={indicators}
              onSave={handleSaveRelationsFactor}
              onChangedRelationships={handleChangedRelationshipsFactor}
            />
          </ContainerRelations.Right>
        )}
      </ContainerRelations>
      <ContainerRelations.Divisor />
      <ContainerRelations showTitle={false}>
        <ContainerRelations.Left>
          <RelationConditions
            conditions={conditions}
            selectedCondition={selectedCondition}
            onChangeCondition={handleChangeCondition}
          />
        </ContainerRelations.Left>
        {selectedCondition && indicatorsConditions && indicators && (
          <ContainerRelations.Right>
            <RelationIndicatorsCondition
              selectedCondition={selectedCondition}
              indicatorsCondition={indicatorsConditions}
              indicators={indicators}
              onSave={handleSaveRelationsCondition}
              onChangedRelationships={handleChangedRelationshipsCondition}
            />
          </ContainerRelations.Right>
        )}
      </ContainerRelations>
    </>
  )
}

export default ConditionsAndFactorsRelations
