import { useCallback } from 'react'
import { Area } from '../../interfaces/area'
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAppDialog from '../useAppDialog'

const useDepartament = () => {
  const { fetchList, fetchCreate, fetchUpdate, fetchDisable, fetchFindOne } = useFetch()
  const { showErrorDialog, showSuccessDialog, showLoadingDialog } = useAppDialog()

  const fetchDepartaments = useCallback(
    async ({ limit = 0, from = 0, active = true }: BasicFetchListProps = {}) => {
      return fetchList<Area>({
        action: 'getDepartaments',
        params: [
          ['limit', `${limit}`],
          ['active', `${active}`],
          ['from', `${from}`],
        ],
      })
    },
    [fetchList]
  )

  const fetchGetOneDepartament = useCallback(
    async (id: string) => {
      return fetchFindOne<any>({
        action: 'getOneDepartament',
        findId: id,
      })
        .then(res => {
          return res
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchFindOne]
  )

  const fetchCreateDepartament = useCallback(
    (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      fetchCreate({ action: 'createDepartaments', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Area creada exitosamente!.',
          })
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchCreate]
  )

  const fetchUpdateDepartament = useCallback(
    (body: any, done?: (_id: string) => void) => {
      showLoadingDialog()
      fetchUpdate({ action: 'updateDepartament', body })
        .then(_id => {
          showSuccessDialog({
            text: 'Area actualizada exitosamente!.',
          })
        })
        .catch(error => {
          showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' })
        })
    },
    [fetchUpdate]
  )

  const fetchDeleteDepartament = useCallback(
    (body: any, done?: () => void) => {
      showLoadingDialog()
      fetchDisable({ action: 'disableDepartament', body })
        .then(() => {
          showSuccessDialog({
            text: 'Area eliminada exitosamente!.',
          })
        })
        .catch(error => showErrorDialog({ text: error.message || 'Ha ocurrido un error, contactar con soporte.' }))
    },
    [fetchDisable]
  )

  return {
    fetchDepartaments,
    fetchGetOneDepartament,
    fetchCreateDepartament,
    fetchUpdateDepartament,
    fetchDeleteDepartament,
  }
}

export default useDepartament
