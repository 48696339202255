import { FunctionComponent } from "react";

interface IconPoliticsProps {
    width?: number | string;
    height?: number | string;
}

const IconPolitics: FunctionComponent<IconPoliticsProps> = ({
    width = 32,
    height = 41,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 3.66071C0 1.63958 1.62868 0 3.63636 0H13.0909C18.1059 0 22.9155 2.00554 26.4617 5.57543C30.0078 9.14532 32 13.9871 32 19.0357V37.3393C32 39.3604 30.3713 41 28.3636 41H3.63636C1.62868 41 0 39.3604 0 37.3393V3.66071ZM27.7909 12.6759C26.9968 10.8159 25.8542 9.10549 24.4046 7.64624C22.9551 6.187 21.2561 5.03666 19.4085 4.23733C20.0298 5.39989 20.3636 6.70941 20.3636 8.05357V10.9821C20.3636 11.3859 20.6899 11.7143 21.0909 11.7143H24C25.3352 11.7143 26.636 12.0503 27.7909 12.6759ZM29.0909 19.7679C29.0909 18.4086 28.5546 17.1051 27.5998 16.1439C26.6451 15.1828 25.3502 14.6429 24 14.6429H21.0909C19.0832 14.6429 17.4545 13.0033 17.4545 10.9821V8.05357C17.4545 6.69434 16.9182 5.39077 15.9635 4.42965C15.0087 3.46852 13.7138 2.92857 12.3636 2.92857H3.63636C3.23532 2.92857 2.90909 3.25699 2.90909 3.66071V37.3393C2.90909 37.743 3.23532 38.0714 3.63636 38.0714H28.3636C28.7647 38.0714 29.0909 37.743 29.0909 37.3393V19.7679ZM22.6636 19.3085C23.3173 19.7785 23.4687 20.693 23.0018 21.3511L15.7291 31.6011C15.4805 31.9514 15.0915 32.1738 14.6654 32.2093C14.2393 32.2448 13.8193 32.0898 13.5169 31.7854L9.1533 27.3925C8.58526 26.8207 8.58526 25.8936 9.1533 25.3217C9.72134 24.7499 10.6423 24.7499 11.2103 25.3217L14.3598 28.4923L20.6346 19.6489C21.1015 18.9908 22.0099 18.8384 22.6636 19.3085Z"
                fill="#7282B8"
            />
        </svg>
    );
};

export default IconPolitics;
