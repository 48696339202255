import { StyleComponent } from '../../../../interfaces/styleComponent'

type Keys =
  | 'container'
  | 'card'
  | 'title'
  | 'nameAndDate'
  | 'name'
  | 'date'
  | 'buttonsContainer'
  | 'fechaCard'
  | 'buttonUpdate'
  | 'buttonDisable'
  | 'percentageOfCompletionTitle'
  | 'percentageOfCompletionContainer'
  | 'percentageOfCompletionValue'

const tablePdiStyles: StyleComponent<Keys> = {
  container: {
    paddingY: '42px',
    transition: '1s 0.5s',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '245px',
    paddingRight: '73px',
    // display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(470px, 1fr))",
    // justifyItems: "center",
    // alignContent: "start",

    // RESPONSIVE
    '@media(max-width: 1863px)': {
      justifyContent: 'space-around',
      paddingRight: '129px',
    },

    '@media(max-width: 1305px)': {
      paddingRight: '100px !important',
      paddingLeft: '100px !important',
    },

    '@media(max-width: 830px)': {
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
    },
  },
  card: {
    backgroundColor: '#ffffff',
    width: '424px',
    height: '299px',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
    borderRadius: '17px',
    m: '21px',
    '&>div': {
      paddingX: '35px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingY: '45px',
    borderRadius: '30px',

    // TYPOGRAPHY
    '&>p': {
      color: '#000000',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '22px',
      fontStyle: 'normal',
    },

    // BOTON MÁS
    '&>button': {
      minWidth: '0px',
      height: 'max-content',
      padding: '0px 0px',
      marginY: 'auto',
      borderRadius: '20px',
      position: 'inherit',
    },
  },
  nameAndDate: {
    '&>p': {
      color: '#000000',
      fontSize: '20px',
      fontStyle: 'normal',
      lineHeight: '30px',
    },
  },
  name: {
    fontWeight: '600',
  },
  date: {
    fontWeight: '500px',
    fontSize: '15px !important',
  },
  percentageOfCompletionContainer: {
    display: 'flex',
    paddingBottom: '40px',
  },
  percentageOfCompletionTitle: {
    textAlign: 'start',
    fontWeight: '400px',
    fontSize: '15px !important',
    color: '#1F398F',
  },
  percentageOfCompletionValue: {
    textAlign: 'start',
    fontWeight: '400px',
    fontSize: '15px !important',
    color: '#000000',
    ml: '5px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>div>Button': {
      fontSize: '12px',
      fontWeight: '400',
      textTransform: 'capitalize',
      lineHeight: '30px',
      fontStyle: 'normal',
      borderRadius: '20px',

      height: '25.69px',
      width: '99.25px',
      marginRight: '10.51px',
    },

    '&>p': {
      color: '#8E8E8E',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '30px',
      alignItems: 'center',
      textAlign: 'Right',
    },
  },
  fechaCard: {
    display: 'inline',
    margin: 'auto 0px',
    fontSize: '15px !important',
  },

  buttonUpdate: {
    backgroundColor: '#1F398F1A',
    color: '#000000',
    ':hover': {
      backgroundColor: '#1F398F1A',
    },
  },
  buttonDisable: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,

    // HOVER
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },

    '>a': {
      paddingRight: '0px !important',
      textDecoration: 'none',

      // HOVER
      ':hover': {
        color: 'inherit',
      },
    },
  },
}

export default tablePdiStyles
