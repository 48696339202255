import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import useAppDialog from '../../../../hooks/useAppDialog'
import useTasks from '../../../../hooks/useTasks'
import CustomModal from '../../../../components/modals/customModal/CustomModal'
import FormEdit from './FormEdit'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

interface IDropdownOptionsOptions {
  item: any
  list: Function
}

const DropdownOptions = ({ item, list }: IDropdownOptionsOptions) => {
  const { showConfirmDialog } = useAppDialog()
  const { fetchDeleteTaskAssignment } = useTasks()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openEdit, setOpenEdit] = React.useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    setOpenEdit(true)
    handleClose()
  }

  const handleCloseModal = () => {
    setOpenEdit(false)
  }

  const handleDelete = () => {
    showConfirmDialog({
      icon: 'warning',
      text: 'Estas seguro que deseas eliminar esta asignacion?',
      smallText: 'Recuerde que esta accion sera irreversible',
      confirmButton: {
        text: 'Si',
        onClick: () => {
          deleteItem()
        },
      },
      cancelButton: { text: 'No' },
    })
    handleClose()
  }

  const deleteItem = async () => {
    const res = await fetchDeleteTaskAssignment({ _id: item._id })
    if (res.ok) {
      list()
    }
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit} disableRipple>
            <EditIcon />
            Reasignar
          </MenuItem>
          <MenuItem onClick={handleDelete} disableRipple>
            <DeleteIcon />
            Eliminar
          </MenuItem>
        </StyledMenu>
      </Menu>
      <CustomModal
        open={openEdit}
        onCloseModal={handleCloseModal}
        onCloseForButtom={handleCloseModal}
        maxHeight={1000}
        borderRadius="20px"
      >
        <FormEdit defaultValues={item} list={list} setOpen={setOpenEdit} />
      </CustomModal>
    </div>
  )
}
export default DropdownOptions
