import { StyleComponent } from "../interfaces/styleComponent";

type Keys =
    | "container"
    | "title"
    | "containerButton"
    | "button"
    | "containerButtonTheme"
    | "containerTitle";

const customTitleButtonAddStyles: StyleComponent<Keys> = {
    container: {
        maxWidth: "1350px",
        display: "flex",
        margin: "auto",
        justifyContent: "space-between",

        // RESPONSIVE
        "@media(max-width: 1390px)": {
            marginX: "20px",
        },
    },

    containerTitle: {
        mr: {
            sm: 0,
            md: "30px",
            xl: 0,
        },

        ">span": {
            display: "none",
            "@media (max-width: 655px)": {
                display: "block",
            },
        },
    },

    title: {
        textAlign: "center",
        color: "#1F398F",
        fontWeight: "600",
        lineHeight: "75px",
        fontStyle: "normal",

        // RESPONSIVE
        "@media (max-width: 830px)": {
            fontSize: "40px",
        },
        "@media (max-width: 655px)": {
            display: "none",
        },
    },

    // CONTENEDOR BOTON TEMA DE EMPRESA
    containerButton: {
        display: "flex",
        justifyContent: "center",
    },

    button: {
        backgroundColor: "#ffffff",
        minWidth: "180px",
        height: "38px",
        marginY: "auto",
        padding: "0px 15px",
        textTransform: "inherit",
        borderRadius: "92px",
        boxShadow: "0px 0px 10px rgba(114, 130, 184, 0.25)",

        ":hover, :focus": {
            backgroundColor: "#7282B8",
            ">svg": {
                ">path": {
                    fill: "#ffffff",
                },
            },
            ">p": {
                color: "#ffffff",
            },
        },

        ">p": {
            fontSize: "16px",
            color: "#1F398F",
            marginLeft: "4px",
            textTransform: "capitalize !important",
        },
    },

    // BOTON TEMA DE EMPRESA
    containerButtonTheme: {
        backgroundColor: "#ffffff",
        position: "absolute",
        right: 0,
        marginTop: "10px",
        width: "231px",
        height: "267px",
        borderRadius: "15px",
        zIndex: 1,

        ">p": {
            backgroundColor: "#7282B8",
            color: "#ffffff",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
        },
        ">div": {
            padding: "30px 41px 46px",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            ">button, >a": {
                backgroundColor: "inherit",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                textTransform: "capitalize",
                padding: "0px 0px ",
                marginBottom: "11.2px",
                borderWidth: "0px",
                cursor: "pointer",
                ":hover": {
                    ">p": {
                        color: "#1F398F",
                        fontWeight: "600",
                    },
                },
                ">span": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "20px",
                },
                ">.MuiTouchRipple-root": {
                    backgroundColor: "transparent !important",
                    ":active": {
                        backgroundColor: "transparent !important",
                    },
                    ":hover": {
                        backgroundColor: "transparent !important",
                    },
                },
            },
        },
    },
};

export default customTitleButtonAddStyles;
