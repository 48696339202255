import { Box, Typography } from "@mui/material";
import infoUsersStyles from "./infoUser.styles";
import LogoAmericana from "../../../images/logos/americanaMedellin.jpg";
import ButtonEdit from "../../../components/utilities/buttons/ButtonEdit";
import ButtonDelete from "../../../components/utilities/buttons/ButtonDelete";

interface InfoUserProps {
    dataUser: any
}
const InfoUser = ({ dataUser }: InfoUserProps) => {
    return (
        <>
            {/**************
                CONTENEDOR
            ***************/}
            <Box sx={infoUsersStyles.container}>
                {/**************
                    TITULO INFO
                ***************/}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={infoUsersStyles.title}>
                        Detalles de la información del Usuario
                    </Typography>
                    <ButtonEdit id={dataUser._id} />
                    <ButtonDelete id={dataUser._id} />
                </Box>

                {/*********************************
                    CONTENIDO IZQUIERDA / DERECHA
                **********************************/}
                <Box sx={infoUsersStyles.contentLeftRight}>
                    {/************* 
                        IZQUIERDA
                    *************/}
                    <Box sx={infoUsersStyles.contentLeft}>
                        <Box>
                            <img src={LogoAmericana} alt="Foto usuario" />
                        </Box>
                    </Box>

                    {/************* 
                        IZQUIERDA
                    *************/}
                    <Box sx={infoUsersStyles.contentRight}>
                        {/************** 
                            CONTENT 1 
                        **************/}
                        <Box sx={infoUsersStyles.data}>
                            {/*********** 
                                TITULO
                            ***********/}
                            <Typography sx={infoUsersStyles.dataTitle}>
                                Datos Personales
                            </Typography>

                            {/*********
                                INFO
                            *********/}
                            <Typography>
                                Nombres del Usuario:{" "}
                                <span>{dataUser.idProfile?.name ?? "Sin Datos"}</span>
                            </Typography>

                            <Typography>
                                Apellidos del Usuario:{" "}
                                <span>{dataUser.idProfile?.lastName ?? "Sin Datos"}</span>
                            </Typography>

                            <Typography>
                                E-mail: <span>{dataUser.idProfile?.email ?? "Sin Datos"}</span>
                            </Typography>

                            <Typography>
                                Celular: <span>{dataUser.idProfile?.phone ?? "Sin Datos"}</span>
                            </Typography>
                        </Box>

                        {/*************** 
                            CONTENT 2 
                        ***************/}
                        <Box sx={infoUsersStyles.data}>
                            {/*********** 
                                TITULO
                            ***********/}
                            <Typography sx={infoUsersStyles.dataTitle}>
                                Datos relacionales
                            </Typography>

                            {/*********
                                INFO
                            *********/}
                            <Typography>
                                Proceso: <span>xxxxxxxxxxxxxxxx</span>
                            </Typography>

                            <Typography>
                                Cargo: <span>{dataUser.idPosition?.name ?? "Sin Datos"}</span>
                            </Typography>

                            <Typography>
                                Área: <span>{dataUser.idDepartament?.name ?? "Sin Datos"}</span>
                            </Typography>

                            <Typography>
                                Cargo del jefe:{" "}
                                <span>{dataUser.idBossPosition?.name ?? "Sin Datos"}</span>
                            </Typography>

                            <Typography>
                                Sede: <span>{`${dataUser.idBranchOffice?.nameBranchOffice ?? "Sin Datos"} - ${dataUser.idBranchOffice?.city ?? "Sin Datos"}`}</span>
                            </Typography>
                        </Box>

                        {/************** 
                            CONTENT 3
                        **************/}
                        <Box sx={infoUsersStyles.data}>
                            {/*********** 
                                TITULO
                            ***********/}
                            <Typography sx={infoUsersStyles.dataTitle}>
                                Acceso al sistema
                            </Typography>

                            {/*********
                                INFO
                            *********/}
                            <Typography>
                                Usuario: <span>{dataUser.userName ?? "Sin Datos"}</span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InfoUser;
