import Swal, { SweetAlertOptions } from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const loadAlert = ({ title = 'Cargando...', allowOutsideClick = false, loaderHtml = '<img src = "./images/logos/logoFaviconAplanner.png" width = "100" height = "100%"></img>', ...other }: SweetAlertOptions = {}) => {
    MySwal.fire({ title, didOpen: () => Swal.showLoading(), allowOutsideClick, loaderHtml, ...other })
}

const successAlert = ({ title = 'Exito!', icon = 'success', allowOutsideClick = false, ...other }: SweetAlertOptions = {}) => {
    MySwal.fire({ title, icon, allowOutsideClick, ...other })
}

const errorAlert = ({ title = 'Error!', icon = 'error', allowOutsideClick = false, ...other }: SweetAlertOptions = {}) => {
    MySwal.fire({ title, icon, allowOutsideClick, ...other })
}

const confirmAlert = async ({
    title = '¿Estas seguro?',
    text = 'Esta acción es irreversible.',
    icon = 'warning',
    confirmButtonText = 'Si',
    cancelButtonText = 'cancelar',
    allowOutsideClick = false,
}: SweetAlertOptions = {}) => {
    return await Swal.fire({
        title,
        text,
        icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText,
        confirmButtonText,
        allowOutsideClick
    })
}

const closeLoading = () => {
    Swal.isVisible() && Swal.isLoading() && Swal.close()
}

const customAlerts = {
    loadAlert,
    errorAlert,
    confirmAlert,
    closeLoading,
    successAlert
}


export default customAlerts;