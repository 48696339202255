import { FunctionComponent } from "react";

interface IconUserNotificationProps {
    height?: string | number;
    width?: string | number;
    color?: string;
}

const IconUserNotification: FunctionComponent<IconUserNotificationProps> = ({
    height = 40,
    width = 40,
    color = "#7282B8",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.7962 13.6364C32.175 13.6364 32.4971 13.5036 32.7625 13.2382C33.028 12.9727 33.1604 12.6509 33.1598 12.2727H30.4325C30.4325 12.6515 30.5653 12.9736 30.8307 13.2391C31.0962 13.5045 31.418 13.637 31.7962 13.6364ZM28.1825 11.3636H35.4553C35.7128 11.3636 35.925 11.2764 36.0916 11.1018C36.2583 10.9273 36.3416 10.7115 36.3416 10.4545C36.3416 10.197 36.2543 9.98091 36.0798 9.80636C35.9053 9.63182 35.6895 9.54485 35.4325 9.54545V7.18182C35.4325 6.25757 35.1937 5.41273 34.7162 4.64727C34.2386 3.88182 33.5683 3.39333 32.7053 3.18182V2.72727C32.7053 2.46969 32.618 2.25363 32.4434 2.07909C32.2689 1.90454 32.0531 1.81757 31.7962 1.81818C31.5386 1.81818 31.3225 1.90545 31.148 2.08C30.9734 2.25454 30.8865 2.4703 30.8871 2.72727V3.18182C30.0234 3.39394 29.3531 3.88272 28.8762 4.64818C28.3992 5.41363 28.1604 6.25818 28.1598 7.18182V9.54545C27.9022 9.54545 27.6901 9.63273 27.5234 9.80727C27.3568 9.98182 27.2734 10.1976 27.2734 10.4545C27.2734 10.7121 27.3607 10.9282 27.5353 11.1027C27.7098 11.2773 27.9256 11.3642 28.1825 11.3636ZM29.978 9.54545V6.81818C29.978 6.31818 30.1562 5.89 30.5125 5.53363C30.8689 5.17727 31.2968 4.99939 31.7962 5C32.2962 5 32.7243 5.17818 33.0807 5.53454C33.4371 5.89091 33.615 6.31879 33.6143 6.81818V9.54545H29.978Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.63636 20C3.63636 10.9626 10.9626 3.63636 20 3.63636C21.0781 3.63636 22.1305 3.74037 23.1482 3.93858C24.1339 4.13054 25.1537 3.60856 25.4545 2.65055C25.7554 1.69254 25.2237 0.663288 24.2422 0.451206C22.8737 0.155495 21.4542 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 18.7264 39.8807 17.4793 39.6524 16.2697C39.4662 15.283 38.4513 14.7244 37.4857 15C36.5201 15.2756 35.9714 16.2813 36.1375 17.2716C36.2861 18.158 36.3636 19.0694 36.3636 20C36.3636 24.3316 34.6806 28.2701 31.9328 31.1972C30.5069 28.8027 28.3949 26.8933 25.8745 25.7112C27.0482 24.6629 27.9208 23.315 28.3936 21.8063C28.9427 20.0542 28.9248 18.1744 28.3425 16.4331C27.7601 14.6918 26.643 13.1776 25.1495 12.1046C23.656 11.0318 21.8619 10.4545 20.0213 10.4545C18.1807 10.4545 16.3866 11.0318 14.8932 12.1046C13.3997 13.1776 12.2825 14.6918 11.7002 16.4331C11.1178 18.1744 11.0999 20.0542 11.649 21.8063C12.1218 23.315 12.9944 24.6629 14.1682 25.7112C11.638 26.8979 9.51939 28.8174 8.09331 31.225C5.33006 28.295 3.63636 24.3453 3.63636 20ZM10.7901 33.5277C13.4137 35.3173 16.5846 36.3636 20 36.3636C23.4339 36.3636 26.6208 35.3059 29.2526 33.4984C29.159 33.4047 29.0755 33.2999 29.0042 33.1858L29.0004 33.1796L28.9968 33.1734C27.0935 29.8955 23.7397 27.946 20.0213 27.946C16.3051 27.946 12.9532 29.8921 11.0494 33.1674C10.9783 33.2982 10.8912 33.4192 10.7901 33.5277ZM21.0431 14.085C20.0271 13.8835 18.9741 13.9869 18.0171 14.382C17.0602 14.7771 16.2426 15.446 15.6674 16.3039C15.0923 17.1618 14.7854 18.1703 14.7854 19.2018C14.7871 20.5845 15.3389 21.9104 16.3203 22.8886C17.3019 23.867 18.6331 24.4176 20.0218 24.4192C21.0576 24.4191 22.0699 24.1129 22.9308 23.5395C23.7919 22.9661 24.4628 22.1511 24.8589 21.198C25.255 20.2448 25.3586 19.1961 25.1567 18.1844C24.9548 17.1726 24.4564 16.243 23.7242 15.5133C22.9921 14.7835 22.0591 14.2864 21.0431 14.085Z"
                fill={color}
            />
        </svg>
    );
};

export default IconUserNotification;
