import { useCallback } from 'react'
import useAuth from '../useAuth'
import useAppDialog from '../useAppDialog'

const actionsImage = {
  branchOfficeUploadImg: '/api/v1/branchoffice/upload',
  userUploadImg: '/api/v1/profile/upload',
  companyUploadImg: '/api/v1/company/upload',
  companyUploadImgH: '/api/v1/company/uploadH',
  formulaUploadEvidence: '/api/v1/formula/upload',
  getUrl: '/api/v1/company/do',
}

interface UploadImageProps {
  action: keyof typeof actionsImage
  form: any
}

const useImage = () => {
  const { showErrorDialog, showLoadingDialog } = useAppDialog()
  const { token } = useAuth()
  const host = process.env.REACT_APP_API_URL

  const uploadImage = useCallback(
    async ({ action, form }: UploadImageProps) => {
      return await fetch(`${host}${actionsImage[action]}`, {
        method: 'POST',
        headers: {
          Authorization: token ?? '',
        },
        body: form,
      })
        .then(res => res.json())
        .then(res => {
          return { ok: true, data: res.message }
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar la Imagen, contactar con soporte.',
          })
          return { ok: false }
        })
    },
    [host, token]
  )

  const getUrl = useCallback(
    async ({ action, form }: UploadImageProps) => {
      return await fetch(`${host}${actionsImage[action]}`, {
        method: 'POST',
        headers: {
          Authorization: token ?? '',
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then(res => res.json())
        .then(res => {
          return { ok: res.success, data: res.data }
        })
        .catch(error => {
          showErrorDialog({
            text: error?.message || 'Ha ocurrido un error al cargar la Imagen, contactar con soporte.',
          })
          return { ok: false }
        })
    },
    [host, token]
  )

  return { uploadImage, getUrl }
}

export default useImage
