import { Box, Typography, Button, Pagination } from '@mui/material'

// IMPORTADOS
import tableProcessStyles from '../tableProcess.styles'
import IconDirection from '../../../../../components/icons/IconDirection'
import CustomModal from '../../../../../components/CustomModal'
import InfoSubProcess from './InfoSubProcess'
import { useEffect, useState } from 'react'
import useSubProcess from '../../../../../hooks/pdi/process/useSubprocess'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../../../components/utilities/Spinner'
import ConfirmDeleteContent from '../../../../../components/utilities/ConfirmDeleteContent'
import EditSubProcess from './EditSubProcess'

const TableSubProcess = () => {
  const navigate = useNavigate()
  const { fetchDeleteSubProcess } = useSubProcess()

  const [listSubProcess, setListSubProcess] = useState([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [dataDefault, setDataDefault] = useState<boolean>(false)

  const { fetchGetSubProcess } = useSubProcess()

  const list = async () => {
    setLoading(true)
    const response: any = await fetchGetSubProcess({
      limit: limit,
      from: from,
    })
    setListSubProcess(response.items)
    const pages = response.total / 10

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  useEffect(() => {
    list()
  }, [page])

  const openEdit = (option: any) => {
    setDataDefault(option)
    setOpenForm(true)
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* CONTENEDOR CARD PDI */}
      <Box sx={tableProcessStyles.container}>
        {/*****************
                    CARD PROCESOS
                ******************/}
        {loading ? (
          <Spinner />
        ) : (
          listSubProcess.map((subProcess: any, index) => (
            <Box key={index} sx={{ ...tableProcessStyles.card }}>
              {/**************************
                                CONTENEDOR TITULO CARD
                            ***************************/}
              <Box sx={tableProcessStyles.title}>
                {/*******************
                                    BOTON DESCARGAR
                                *********************/}
                <Button disableRipple>
                  <CustomModal text={<IconDirection />} maxHeight="869px">
                    <InfoSubProcess id={subProcess?._id ?? ''} />
                  </CustomModal>
                </Button>
              </Box>

              {/***************************
                                NOMBRE Y FECHA CARD PDI
                            ****************************/}
              <Box sx={tableProcessStyles.nameAndDate}>
                {/*******************
                                    NOMBRE CARD PDI
                                ********************/}
                <Typography sx={tableProcessStyles.name}>{subProcess?.idProcess?.name ?? ''}</Typography>

                {/*******************
                                    FECHA CARD PDI
                                ********************/}
                <Typography sx={tableProcessStyles.date}>{subProcess?.name ?? ''}</Typography>

                {/*******************
                                    FECHA CARD PDI
                                ********************/}
                <Typography sx={tableProcessStyles.date}>
                  No. Metas: {subProcess?.percentageOfCompletion ?? ''}
                </Typography>
              </Box>

              {/**********************
                                CONTENEDOR BOTONES 
                            ***********************/}
              <Box sx={tableProcessStyles.buttonsContainer}>
                {/**********************************
                                    BOTONES ACTUALIZAR/DESACTIVAR
                                ***********************************/}
                <div>
                  {/********************
                                        BOTON ACTUALIZAR
                                    *********************/}
                  <Button
                    disableTouchRipple
                    sx={{
                      backgroundColor: '#1F398F1A',
                      ':hover': {
                        backgroundColor: '#1F398F1A',
                      },
                    }}
                    onClick={() => openEdit(subProcess)}
                  >
                    Editar
                  </Button>

                  {/********************
                                        BOTON DESACTIVAR
                                    *********************/}
                  <CustomModal
                    maxWidth={600}
                    maxHeight={350}
                    text={
                      <Button
                        disableTouchRipple
                        sx={{
                          backgroundColor: theme => theme.palette.secondary.main,

                          // HOVER
                          ':hover': {
                            backgroundColor: theme => theme.palette.secondary.main,
                          },

                          '>a': {
                            paddingRight: '0px !important',
                            textDecoration: 'none',
                            ':hover': {
                              color: 'inherit',
                            },
                          },
                        }}
                      >
                        Eliminar
                      </Button>
                    }
                    stateOpen={openModalDelete}
                  >
                    <ConfirmDeleteContent
                      setOpen={setOpenModalDelete}
                      open={openModalDelete}
                      id={subProcess._id}
                      fetchDelete={fetchDeleteSubProcess}
                      list={list}
                      message="¿Estas seguro de elminar este subproceso?"
                    />
                  </CustomModal>
                </div>
              </Box>
            </Box>
          ))
        )}
      </Box>

      <EditSubProcess openForm={openForm} setOpenForm={setOpenForm} defaultValue={dataDefault} list={list} />

      {/****************
                PAGINACION
            ****************/}
      <Box sx={tableProcessStyles.pagesList}>
        <Pagination count={pages} page={page} onChange={changePage} />
      </Box>
    </Box>
  )
}

export default TableSubProcess
