import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

// IMPORTADOS
import relationsStyles from './Relations.styles'
import CustomTitleButtonAdd from '../../../../../components/CustomTitleButtonAdd'
import IndicatingRelations from './IndicatingRelations'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import SubProcessRelations from './SubProcessRelations'
import ProcessRelations from './ProcessRelations'
import { useLocation } from 'react-router-dom'
import RelationFactors from '../../family/relation-factor-condition/RelationFactors'
import RelationConditions from '../../family/relation-factor-condition/RelationConditions'
import useIndicator from '../../../../../hooks/pdi/useIndicator'
import useRelations from '../../../../../hooks/pdi/relations/useRelations'

const Relations = () => {
  const { pathname } = useLocation()
  const [process, setProcess] = useState<string>('')
  const [subProcess, setSubProcess] = useState<string>('')
  const [indicators, setIndicators] = useState<any>([])
  const [relatedIndicators, setRelatedIndicators] = useState<any>([])

  const { fetchGetIndicator } = useIndicator()
  const { fetchGetIndicatorProcess, fetchGetIndicatorSubProcess } = useRelations()

  // Obtener todos los indicadores y los que ya estan relacionados
  const getList = async () => {
    const response: any = await fetchGetIndicator()
    setIndicators(response.items ?? [])

    const response2: any = await fetchGetIndicatorProcess()
    const response3: any = await fetchGetIndicatorSubProcess()
    const responseFusion = [...response2.items, ...response3.items]
    setRelatedIndicators([...responseFusion])
  }

  return (
    <>
      {/***********************
                CONTENEDOR PROCESOS
            *************************/}
      <Box sx={relationsStyles.container}>
        {/***********************
                    TITULO RELACIONES
                *************************/}
        <CustomTitleButtonAdd title="Relaciones" display={'none'} />

        {/**************
                    CONTENIDO
                ****************/}
        <Box sx={relationsStyles.content}>
          {/***********************
                        CONTENEDOR IZQUIERDA
                    ***********************/}
          <Box sx={relationsStyles.contentLeft}>
            {/******************************
                            LISTA PROCESOS/RELACIONES
                        ******************************/}
            {pathname === '/plan/process/relations' && <ProcessRelations process={process} setProcess={setProcess} />}

            {/****************************************
                            LISTA FAMILIAS/FACTORES/RELACIONES
                        ****************************************/}
            {/* {pathname === "/plan/family/factors/relations" && (
                            <RelationFactors />
                        )} */}

            {/****************************************
                            LISTA FAMILIAS/CONDICIONES/RELACIONES
                        ****************************************/}
            {/* {pathname === "/plan/family/conditions/relations" && (
                            <RelationFactors />
                        )} */}
          </Box>

          {/***********************
                        CONTENEDOR DERECHA
                    ************************/}
          {process !== '' && (
            <Box sx={relationsStyles.contentRight}>
              {/*******************
                            LISTA INDICADORES
                        *******************/}
              <IndicatingRelations
                id={process}
                type="idProcess"
                getList={getList}
                indicators={indicators}
                relatedIndicators={relatedIndicators}
              />
            </Box>
          )}
        </Box>
      </Box>

      {/*************
                DIVISION
            *************/}
      <Box sx={relationsStyles.divisor}></Box>

      {/**************************
                CONTENEDOR SUBPROCESOS
            ***************************/}
      <Box sx={relationsStyles.container}>
        {/**************
                    CONTENIDO
                ****************/}
        <Box sx={relationsStyles.content}>
          {/***********************
                        CONTENEDOR IZQUERDA
                    ***********************/}
          <Box sx={relationsStyles.contentLeft}>
            {/******************************
                            LISTA PROCESOS/RELACIONES
                        ******************************/}
            {pathname === '/plan/process/relations' && (
              <SubProcessRelations subProcess={subProcess} setSubProcess={setSubProcess} />
            )}

            {/****************************************
                            LISTA FAMILIAS/FACTORES/RELACIONES
                        ****************************************/}
            {/* {pathname === "/plan/family/factors/relations" && (
                            <RelationConditions />
                        )} */}

            {/****************************************
                            LISTA FAMILIAS/CONDICIONES/RELACIONES
                        ****************************************/}
            {/* {pathname === "/plan/family/conditions/relations" && (
                            <RelationConditions />
                        )} */}
          </Box>

          {/***********************
                        CONTENEDOR DERECHA
                    ************************/}
          {subProcess !== '' && (
            <Box sx={relationsStyles.contentRight}>
              {/**********************
                            LISTA INDICADORES
                        **********************/}
              <IndicatingRelations
                id={subProcess}
                type="idSubProcess"
                getList={getList}
                indicators={indicators}
                relatedIndicators={relatedIndicators}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Relations
