import { useCallback, useState } from 'react'
import { ChangeActivePdi, FormPdiValues, LinkedPdi, ListPdis, Pdi, UpdatePdi } from "../../interfaces/pdi/pdi"
import useFetch, { BasicFetchListProps } from '../useFetch'
import useAuth from '../useAuth'
import GenericAlert, { GenericAlertProps } from '../../components/Alerts/GenericAlert/GenericAlert'


type FetchPdisProps = BasicFetchListProps

const usePdi = () => {

    const { fetchFindOne, fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()

    const { userData, onLogout } = useAuth()

    const [listPdis, setListPdis] = useState<ListPdis>({
        items: [],
        total: 0
    })

    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })

    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )

    const fetchLinkedPdi = useCallback(async (idPdi: string) => {
        return fetchFindOne<LinkedPdi>({
            action: 'getLinkedPdi',
            findId: idPdi
        })
    }, [fetchFindOne])

    const fetchPdis = useCallback(({ limit = 0, from = 0, active = true }: FetchPdisProps = {}) => {
        
        if (!userData) return onLogout({ expired: true })

        const {
            idProfile: userProfile
        } = userData
        const {
            idBranchOffice: userBranchOffice,
        } = userProfile
        
        if(!userProfile) return 
        const { idRol : userRol } = userProfile
        if(!userRol) return
        const {code} = userRol
        if(!code) return
        if(!userBranchOffice) return
        if(!userBranchOffice.idCompany) return
        
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchList<Pdi>({
            action: 'getPdis',
            params: [
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(({ items, total }) => {
                // FILTRAR POR ADMIN Y SUPERADMIN
                
                if (code === 'admin' || code === 'superadmin') {
                    setListPdis(() => ({ items, total }))
                } else {
                    const itemsFiltered = items
                        .filter(({idBranchOffice : pdiBranchOffice, idCompany : pdiCompany}) => {
                            return pdiBranchOffice._id === userBranchOffice._id && pdiCompany._id === userBranchOffice.idCompany._id
                        })
                        setListPdis(() => ({ items : itemsFiltered, total }))
                }
                setAlerData((state) => ({ ...state, show: false }))
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchList])

    const fetchCreatePdi = (body: FormPdiValues, done?: (_id: string) => void, failed?: () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createPdi', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El PDI se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => {
                        if (!_id) {
                            failed && failed()
                            return
                        }
                        done && done(_id)
                    },
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }

    const fetchUpdatePdi = (body: UpdatePdi, done?: (pdi: UpdatePdi) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate({ action: 'updatePdi', body })
            .then((_updateValues) => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El PDI se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }

    const fetchDisablePdi = useCallback((body: ChangeActivePdi, done?: () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disablePdi', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `El PDI ha sido ${body.is_active ? 'habilitado' : 'desactivado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })

                setListPdis((state) => {
                    const items = state.items
                        .filter(i => i._id !== body._id)
                    return { ...state, items }
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return {
        fetchLinkedPdi,
        fetchPdis,
        listPdis,
        fetchCreatePdi,
        fetchUpdatePdi,
        fetchDisablePdi,
        ViewAlert
    }
}

export default usePdi;