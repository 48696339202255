import { Box, Button, Typography } from "@mui/material";
import IconCalendarCheck from "../../../components/icons/IconCalendarCheck";
import IconProcess from "../../../components/icons/IconProcess";
import IconFamily from "../../../components/icons/IconFamily";
import IconTask from "../../../components/icons/IconTask";
import planStyles from "./plan.styles";
import IconPa from "../../../components/icons/IconPA";
import { useNavigate } from "react-router-dom";

const Plan = () => {
    const navigate = useNavigate();

    return (
        <Box sx={planStyles.container}>
            {/******************
                TITULO PLANEAR
            *******************/}
            <Typography>Planear</Typography>

            {/***********************************
                CONTENEDOR ARRIBA Y ABAJO
            ************************************/}
            <Box>
                {/*************************
                    CONTENEDOR ARRIBA
                *************************/} 
                <Box className="contentTop">
                    <Box className="contentParagraph">
                        {/* PARRAFO */}
                        <Typography className="parrafo">
                            Comprender el estado actual y el estado deseado. En
                            pocas palabras, el propósito de la etapa de
                            planificación es definir tus objetivos, cómo
                            alcanzarlos, y cómo medir tu progreso hacia ellos.
                        </Typography>
                    </Box>

                    {/**************
                        BOTON PDI
                    **************/}
                    <Button
                        disableTouchRipple
                        onClick={() => navigate("./pdis")}
                        className="buttomPdi"
                        sx={planStyles.buttom}
                    >
                        {/* ICONO PDI */}
                        <IconCalendarCheck width={131} height={130} />

                        {/* TITULO PDI */}
                        <Typography className="parrafoPdi">
                            Plan de desarrollo institucional
                        </Typography>
                    </Button>
                </Box>

                {/**********************
                    CONTENEDOR ABAJO
                ***********************/}
                <Box className="contentBottom">
                    {/******************
                        BOTON PROCESOS
                    *******************/}
                    <Button
                        disableTouchRipple
                        onClick={() => navigate("./process")}
                        className="buttoms"
                        sx={{ ...planStyles.buttom }}
                    >
                        {/* ICONO FACTORES */}
                        <IconProcess width={131} height={130} />

                        {/* TITULO FACTORES */}
                        <Typography className="parrafoProcesos">
                            Procesos
                        </Typography>
                    </Button>

                    {/******************
                        BOTON FAMILIAS
                    *******************/}
                    <Button
                        disableTouchRipple
                        onClick={() => navigate("./family")}
                        className="buttoms"
                        sx={{ ...planStyles.buttom }}
                    >
                        {/* ICONO PROCESOS */}
                        <IconFamily width={134} height={130} />

                        {/* TITULO PROCESOS */}
                        <Typography className="parrafoFamilias">
                            Familias
                        </Typography>
                    </Button>

                    {/**************
                        BOTON PDI
                    **************/}
                    <Button
                        disableTouchRipple
                        onClick={() => navigate("./pdas")}
                        className="buttoms"
                        sx={{ ...planStyles.buttom }}
                    >
                        {/* ICONO PDI */}
                        <IconPa width={148} height={134} />

                        {/* TITULO PDI */}
                        <Typography className="parrafoPdi">
                            Plan de Acción
                        </Typography>
                    </Button>

                    {/******************************
                        BOTON ASIGNACIÓN DE TAREAS
                    *******************************/}
                    <Button
                        disableTouchRipple
                        onClick={() => navigate("./taskAssignment")}
                        className="buttoms"
                        sx={{ ...planStyles.buttom }}
                    >
                        {/* ICONO ASIGNACIÓN DE TAREAS */}
                        <IconTask width={95} height={130} />

                        {/* TITULO ASIGNACIÓN DE TAREAS */}
                        <Typography className="parrafoAsignacionDeTareas">
                            Asignación <br /> de Tareas
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Plan;
