import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys = 'container' | 'title' | 'userTable' | 'pagesList'

const usersStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    // maxWidth: "1350px",
    maxWidth: '1430px',
    paddingLeft: '30px',
    paddingRight: '30px',
    margin: 'auto',
    backgroundColor: '#ffffff',
  },

  // TITULO, BOTONES CREAR Y BUSCAR
  title: {
    maxWidth: '1350px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 1425px)': {
      marginX: '30px',
    },

    // TYPOGRAPHY
    '>p': {
      color: '#1F398F',
      fontSize: '50px',
      fontWeight: '600',
      lineHeight: '75px',
      fontStyle: 'normal',
    },

    // BOTONES CREAR Y BUSCAR
    '>div': {
      marginY: 'auto',
      display: 'flex',

      // BOTON CREAR
      '>.buttonCreate': {
        backgroundColor: '#ffffff',
        minWidth: '213px',
        height: '38px',
        marginY: 'auto',
        marginRight: '20px',
        padding: '0px 15px',
        textTransform: 'inherit',
        borderRadius: '92px',
        boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

        // HOVER
        ':hover': {
          backgroundColor: '#7282B8',
          '>svg': {
            '>path': {
              fill: '#ffffff',
            },
          },
          '>p': {
            color: '#ffffff',
          },
        },

        // PARRAFO
        '>p': {
          fontSize: '16px',
          marginLeft: '4px',
        },
      },

      // BOTON BUSCAR
      '>.buttonSearch': {
        maxWidth: '268px',
        // "@media(max-width: 1440px)": {
        //     width: "30vw",
        // },

        '>div': {
          maxWidth: '268px',
          minHeight: '41px',
          color: '#1F398F',
          marginLeft: 'auto',
          borderRadius: '500px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          '>button:hover': {
            backgroundColor: 'transparent',
          },
          '>div>input': {
            color: '#1F398F',
            fontSize: '12px',
            fontWeight: '400',
            width: '70%',
            '@media(max-width: 590px)': {
              width: '70%',
            },
          },
        },
      },
    },
  },

  // TABLA, LISTA DE USUARIOS
  userTable: {
    backgroundColor: 'inherit',
    marginTop: '42px',
    maxHeight: '720px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '12px',
      size: '10px',
      '&-track': {
        backgroundColor: 'transparent',
        borderRadius: '10px',
        border: '5px solid transparent',
      },
      '&-thumb': {
        backgroundColor: 'transparent',
        borderRadius: '20px',
        width: '10px',
      },
    },

    '>table': {
      backgroundColor: '#ffffff',
      width: '100%',
      textAlign: 'center',
      fontFamily: 'Poppins',
      borderSpacing: '0px',

      // TITULOS
      '>thead': {
        '>tr': {
          '>th': {
            width: '20%',
            minWidth: '130px',
            height: '60px',
            fontSize: '20px',
            color: '#1F398F',
          },
        },
      },

      // LISTA DE USUARIOS
      '>tbody': {
        '>tr': {
          '>td': {
            height: '40px',
            '>div': {
              padding: '6px 8px',
              lineHeight: 1.75,
            },
            '>div, >button': {
              maxWidth: '207px',
              margin: 'auto',
              fontSize: '15px',
              color: '#000000',
              textTransform: 'capitalize',
              '>a': {
                fontSize: '15px',
                color: '#000000',
                textTransform: 'capitalize',
                textDecoration: 'none',
                paddingRight: '0px',
                ':hover': {
                  color: 'inherit',
                },
              },
            },
          },
        },
      },
    },
  },

  pagesList: {
    backgroundColor: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
}

export default usersStyles
