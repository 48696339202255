import { FormGoalPdaFields } from './../../interfaces/pda/goal-pda'
import * as yup from 'yup'
import { ValidateForm } from '../../services/ValidateForm'

export const relationGoalPaIndicatorSchema = yup.object().shape({
  idIndicator: yup.string().trim('El indicador seleccionado no es válido').required('Este campo es requerido'),
  goal: yup
    .number()
    // .positive('La meta requiere un valor numerico positivo')
    .typeError('La meta requiere un valor numerico positivo')
    // .transform((_, value) => {
    //   if (value.includes('.')) return null
    //   return +value.replace(/,/, '.')
    // })
    .required('La meta es un campo requerido'),
})

export const formGoalPdaSchema: yup.SchemaOf<FormGoalPdaFields> = yup
  .object({
    title: yup
      .string()
      .required('El título es un campo requerido')
      .trim('El título no puede contener solo espacios en blanco'),
    idIndicator: yup.array().required(),
    state: yup.boolean().required('El estado es un campo requerido').default(true),
  })
  .required()

export const validateRelGoalPaIndicator = async (data: any) => {
  return await ValidateForm({ data, schema: relationGoalPaIndicatorSchema })
}
