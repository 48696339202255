import { useCallback } from "react"
import { MeasurementFrecuency } from "../../interfaces/pdi/measurementFrecuency"
import useFetch from "../useFetch"

interface FetchMeasurementFrecuenciesProps {
    limit? : number
    from? : number
    active? : boolean
}

const useMeasurementFrecuency = () => {

    const {fetchList} = useFetch()

    const fetchMeasurementFrecuencies = useCallback( async ({ limit = 0, from = 0, active = true } : FetchMeasurementFrecuenciesProps = {}) => {
        return fetchList<MeasurementFrecuency>({
            action : 'getMeasurementFrecuency',
            params : [
                ['from', `${from}`],
                ['limit', `${limit}`],
                ['active' , `${active}`]
            ]
        })
    }, [fetchList])

    return {
        fetchMeasurementFrecuencies
    }
}

export default useMeasurementFrecuency;