import { Box, Typography, Button, Fade } from "@mui/material";
import { FunctionComponent } from "react";
import IconAdd from "../../../icons/IconAdd";
import FilterPdi from "../../../inputSelect/FilterPdi";
import customAlerts from "../../../../helpers/customAlerts";
import useObjetive from "../../../../hooks/pdi/useObjetive";
import useViewControlTabPanel from "../../../../hooks/pdi/useViewControlTabPanelPdi";
import {
    ChangeActiveObjetive,
    FormObjetiveValues,
    NewObjetive,
    Objetive,
    UpdateObjetive,
} from "../../../../interfaces/pdi/objetive";
import { Politics } from "../../../../interfaces/pdi/politics";
import FormObjetive from "../../../forms/pdi/formObjetive/FormObjetive";
import TableObjetives from "../../../tables/pdi/tableObjetives/TableObjetives";
import formStyles from "../../../forms/form.styles";
import { PdiModel } from "../../../../interfaces/pdi/pdi";

interface TabPanelObjetivesProps {
    pdi: PdiModel
    policies: Politics[];
    objetives: Objetive[];
    onCreate: (_id: string) => void;
    onUpdate: (objetives: Objetive[]) => void;
    onChangeTitle: (tabTitle: string | null) => void;
}

const { errorAlert } = customAlerts;

const TabPanelObjetives: FunctionComponent<TabPanelObjetivesProps> = ({
    pdi,
    policies,
    objetives,
    onCreate,
    onChangeTitle,
    onUpdate,
}) => {
    const {
        fetchCreateObjetive,
        fetchUpdateObjetive,
        fetchDisableObjetive,
        ViewAlert,
    } = useObjetive();

    const {
        viewMode,
        showViewData,
        showViewCreate,
        showViewUpdate,
        updateItem,
        filterOption,
        changeFilterOption,
    } = useViewControlTabPanel<Objetive, Politics>({
        items: objetives,
        onChangeTitle,
        titleOptions: {
            none: null,
            create: "Crear Objetivo",
            update: "Actualizar objetivo",
            data: "Objetivos",
        },
    });

    const objetivesFiltered = (): Objetive[] => {
        if (!filterOption?._id) return objetives;
        const { _id } = filterOption;
        return objetives.filter((objetive) => objetive.idPolicies === _id);
    };

    const getFormDefaultValues = ():
        | Partial<FormObjetiveValues>
        | undefined => {
        if (!updateItem) return undefined;
        const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } =
            updateItem;
        return updateValues;
    };

    const create = (
        { state, ...other }: FormObjetiveValues,
        reset: () => void
    ) => {
        const newObjetive: NewObjetive = other;
        fetchCreateObjetive(newObjetive, (_id) => {
            reset();
            onCreate(_id);
        });
    };

    const update = (formValues: FormObjetiveValues, reset: () => void) => {
        if (!updateItem) {
            errorAlert({ text: "Operación no disponible" });
            return;
        }
        const { _id } = updateItem;
        const updateObjetive: UpdateObjetive = { _id, ...formValues };
        fetchUpdateObjetive(updateObjetive, (_updateValues) => {
            reset();
            const { _id } = _updateValues;
            const _updateObjetives = objetives.map((objetive) => {
                if (objetive._id === _id) {
                    return { ...objetive, ..._updateValues };
                }
                return objetive;
            });
            onUpdate(_updateObjetives);
            showViewData();
        });
    };

    const disable = ({ _id }: Objetive) => {
        const disableObjetive: ChangeActiveObjetive = { _id, is_active: false };
        fetchDisableObjetive(disableObjetive, () => {
            const _objetives = objetives.filter((ob) => ob._id !== _id);
            onUpdate(_objetives);
        });
    };

    const onSubmitForm = (
        formValues: FormObjetiveValues,
        reset: () => void
    ) => {
        if (updateItem) return update(formValues, reset);
        create(formValues, reset);
    };

    return (
        <>
            <ViewAlert />
            {/****************
                CONTENEDOR FORMULARIO
            *****************/}
            <Fade in={viewMode === "update" || viewMode === "create"}>
                <Box
                    sx={{
                        display:
                            viewMode === "create" || viewMode === "update"
                                ? "block"
                                : "none",
                    }}
                >
                    <FormObjetive
                        viewMode={
                            viewMode === "data" || !viewMode
                                ? undefined
                                : viewMode
                        }
                        onSubmit={onSubmitForm}
                        onCancel={showViewData}
                        defaultValues={getFormDefaultValues()}
                        policies={policies}
                        pdi={pdi}
                    />
                </Box>
            </Fade>

            {/****************
                CONTENEDOR TABLA
            *****************/}
            <Fade in={viewMode === "data"}>
                <Box
                    sx={{
                        ...formStyles.container,
                        display: viewMode === "data" ? "block" : "none",
                    }}
                >
                    {/****************
                        TITULO
                    *****************/}
                    <Box sx={formStyles.buttonTitle}>
                        {/***************
                            TITULO
                        ****************/}
                        <Typography sx={{ fontSize: "25px" }}>
                            {filterOption
                                ? `Objetivos de ${filterOption.title}`
                                : "Todos los objetivos"}
                        </Typography>

                        <Box>
                            {/***************************
                                BOTON FILTRAR POR POLITICAS
                            ****************************/}
                            <FilterPdi
                                getOptionLabel={(option) => option.title}
                                id="filter-objetive-by-policies"
                                isOptionEqualToValue={(option, value) =>
                                    option._id === value._id
                                }
                                labelInput="Políticas"
                                noOptionsText="Sin resultados"
                                options={policies}
                                onChange={(e, value) =>
                                    changeFilterOption(value)
                                }
                                placeholder="Buscar política..."
                                value={filterOption}
                            />

                            {/*************************
                                CREAR NUEVO OBJETIVO
                            *************************/}
                            <Button
                                onClick={showViewCreate}
                                disableTouchRipple
                                sx={{
                                    minWidth: "213px",
                                }}
                            >
                                <IconAdd />
                                <Typography>Crear nuevo objetivo</Typography>
                            </Button>
                        </Box>
                    </Box>

                    <TableObjetives
                        filterOption={filterOption}
                        onUpdate={showViewUpdate}
                        onDelete={disable}
                        objetives={objetivesFiltered()}
                        policies={policies}
                    />
                </Box>
            </Fade>
        </>
    );
};
export default TabPanelObjetives;
