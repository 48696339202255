import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useFactor from '../../../../../hooks/family/useFactor'
import { FactorModel, FormFactorValues, UpdateFactorValues } from '../../../../../interfaces/factor'
import FormFactor from '../../../../../components/forms/family/formFactor/FormFactor'
import { Box, Fade } from '@mui/material'

const UpdateFactor: FunctionComponent = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const {
    getFactorAndAreas,
    handleUpdateFactor,
    areaList: { items: areas },
  } = useFactor()

  const [defaultValues, setDefaultValues] = useState<FormFactorValues | undefined>(undefined)

  const goToBack = () => navigate('./../../')

  const handleValueForm = (factor: FactorModel) => {
    const { __v, _id, is_active, createdAt, updatedAt, ...other } = factor
    setDefaultValues(other)
  }

  useEffect(() => {
    // Definir acción de error
    if (!id) return goToBack()
    getFactorAndAreas(id, {
      successFactor: handleValueForm,
      errorFactor: goToBack,
    })
  }, [])

  const handleSubmit = (formValues: FormFactorValues) => {
    if (!id) return
    const updateFactor: UpdateFactorValues = { _id: id, ...formValues }
    handleUpdateFactor(updateFactor, goToBack)
  }

  return (
    <Fade in={Boolean(defaultValues)}>
      <Box>
        <FormFactor
          areas={areas}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          textButtonSubmit="Actualizar"
          title="Editar factor"
          onCancel={goToBack}
        />
      </Box>
    </Fade>
  )
}

export default UpdateFactor
