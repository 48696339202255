import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys =
    | "container"
    | "card"
    | "title"
    | "nameAndDate"
    | "name"
    | "date"
    | "buttonsContainer"
    | "fechaCard"
    | "pagesList";

const tableProcessStyles: StyleComponent<Keys> = {
    container: {
        paddingY: "9px",
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: "245px",
        paddingRight: "73px",
        transition: "1s 0.5s",

        // RESPONSIVE
        "@media(max-width: 1863px)": {
            justifyContent: "space-around",
            paddingRight: "129px",
        },

        "@media(max-width: 1305px)": {
            paddingRight: "100px !important",
            paddingLeft: "100px !important",
        },

        "@media(max-width: 830px)": {
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
        },
    },
    card: {
        backgroundColor: "#ffffff",
        minWidth: "205.27px",
        width: "424px",
        height: "299px",
        boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",
        borderRadius: "17px",
        margin: "21px",
        "&>div": {
            paddingX: "35px",
        },

        "@media (max-width: 775px)": {
            height: "auto",
            paddingBottom: "20px",
        },
    },
    title: {
        display: "flex",
        justifyContent: "end",
        padding: "45px 0px 40px",
        borderRadius: "30px",

        // TYPOGRAPHY
        "&>p": {
            color: "#000000",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "22px",
            fontStyle: "normal",
        },

        // BOTON MÁS
        "&>button": {
            minWidth: "0px",
            height: "max-content",
            padding: "0px 0px",
            marginY: "auto",
            borderRadius: "20px",
            position: "inherit",
        },
    },
    nameAndDate: {
        paddingBottom: "35px",
        "&>p": {
            color: "#000000",
            fontSize: "20px",
            fontStyle: "normal",
            lineHeight: "30px",
        },

        ">button, >a": {
            minWidth: "0px",
            padding: "15px 0px 0px",
        },
    },
    name: {
        fontWeight: "600",
    },
    date: {
        fontWeight: "500",
        fontSize: "15px !important",
    },

    // CONTENEDOR DE BOTONES EDITAR/ELIMINAR
    buttonsContainer: {
        ">div": {
            display: "flex",
            // justifyContent: "space-between",
            flexWrap: "wrap",

            // BOTONES EDITAR/ELIMINAR
            ">Button, >div>button, >a>button": {
                fontSize: "12px",
                fontWeight: "400",
                textTransform: "capitalize",
                lineHeight: "30px",
                fontStyle: "normal",
                borderRadius: "20px",

                height: "25.69px",
                width: "99.25px",
                color: "#000000",
                marginRight: "10.51px",
                marginTop: "5px",
            },

            ">a": {
                textDecoration: "none",
            },
        },

        "&>p": {
            color: "#8E8E8E",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
            alignItems: "center",
            textAlign: "Right",
        },
    },
    fechaCard: {
        display: "inline",
        margin: "auto 0px",
        fontSize: "15px !important",
    },

    pagesList: {
        backgroundColor: "inherit",
        margin: "0 auto",
        paddingLeft: "100px",

        "@media (max-width: 1305px)": {
            paddingLeft: "0px",
        },
    },
};

export default tableProcessStyles;
