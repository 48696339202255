import React, { useState } from 'react'
import { Box, Typography, Button, InputBase, IconButton } from '@mui/material'

// IMPORTADOS
import subProcessStyles from './SubProcess.styles'
import IconRelation from '../../../../../components/icons/IconRelation'
import IconSearch from '../../../../../components/icons/IconSearch'
import IconX2 from '../../../../../components/icons/IconX2'
import TableSubProcess from './TableSubProcess'
import { useNavigate } from 'react-router-dom'
import ValidateAction from '../../../../../routes/validateAction'
import { listPermissions } from '../../../../../routes/permissions'

const SubProcess = () => {
  const [btnOpen, setBtnOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <Box sx={subProcessStyles.container}>
      {/**********************************
                CONTENEDOR TITULO Y BOTON CREAR
            ***********************************/}
      <Box sx={subProcessStyles.title}>
        {/******************
                    TITULO PROCESOS
                *******************/}
        <Typography>Subprocesos</Typography>

        {/**********
                    BOTONES
                ************/}
        <Box className="buttons">
          {/***************************
                        BOTON CREAR RELACIONES
                    ****************************/}
          <ValidateAction permission={[listPermissions.relationsProcessAndSubProcess.create]}>
            <Button className="buttonCreate" disableTouchRipple onClick={() => navigate('/plan/process/relations')}>
              <IconRelation />

              <Typography>Crear relaciones</Typography>
            </Button>
          </ValidateAction>

          {/************
                        BUSCADOR 
                    *************/}
          <Box
            className="buttonSearch"
            sx={{
              width: btnOpen === true ? '100%' : 'fit-content',
            }}
          >
            <Box
              sx={{
                backgroundColor: btnOpen === true ? 'rgba(114, 130, 184, 0.1)' : 'transparent',
                width: btnOpen === true ? '100%' : 'fit-content',
              }}
            >
              {/******************
                                ICONO BUSCADOR
                            *******************/}
              <IconButton onClick={e => setBtnOpen(true)} disableTouchRipple>
                <IconSearch width={19} height={19} />
              </IconButton>

              <InputBase
                type="text"
                placeholder="Buscar..."
                sx={{
                  display: btnOpen === false ? 'none' : 'inline-block',
                }}
              />

              {/******************
                                ICONO BUSCADOR
                            *******************/}
              <IconButton
                onClick={e => setBtnOpen(false)}
                sx={{
                  display: btnOpen === false ? 'none' : 'inline-flex',
                }}
              >
                <IconX2 />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      {/*******************
                TABLA SUBPROCESOS
            ********************/}
      <TableSubProcess />
    </Box>
  )
}

export default SubProcess
