import { FunctionComponent } from "react";

interface OpenEyeProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}

const IconOpenEye: FunctionComponent<OpenEyeProps> = ({
    width = 20,
    height = 14,
    color = "#CACACA",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 12.5C5.2 12.5 2 8.83333 1 7C2.33333 5 6 1 10 1C14 1 17.6667 5 19 7C18 8.83333 14.8 12.5 10 12.5Z"
                stroke={color}
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <circle cx="10" cy="7" r="3" stroke={color} strokeWidth="2" />
        </svg>
    );
};

export default IconOpenEye;
