import { FunctionComponent } from 'react'
import useViewControlTabPanel from '../../../../hooks/pdi/useViewControlTabPanelPdi'
import {
  Challenge,
  ChangeActiveChallenge,
  FormChallengeValues,
  NewChallenge,
  UpdateChallenge,
} from '../../../../interfaces/pdi/challenge'
import { Project } from '../../../../interfaces/pdi/project'
import FormChallenge from '../../../forms/pdi/formChallenge/FormChallenge'
import { Box, Typography, Button, Fade } from '@mui/material'
import IconAdd from '../../../icons/IconAdd'
import TableChallenges from '../../../tables/pdi/tableChallenges/TableChallenges'
import useChallenge from '../../../../hooks/pdi/useChallenge'
import FilterPdi from '../../../inputSelect/FilterPdi'
import formStyles from '../../../forms/form.styles'
import { PdiModel } from '../../../../interfaces/pdi/pdi'

interface TabPanelGoalChallengesProps {
  pdi: PdiModel
  projects: Project[]
  challenges: Challenge[]
  onCreate: (_id: string) => void
  onChangeTitle: (tabTitle: string | null) => void
  onUpdate: (challenges: Challenge[]) => void
}

const TabPanelChallenges: FunctionComponent<TabPanelGoalChallengesProps> = ({
  pdi,
  challenges,
  onCreate,
  onChangeTitle,
  onUpdate,
  projects,
}) => {
  const { fetchCreateChallenge, fetchUpdateChallenge, fetchDisableChallenge, ViewAlert } = useChallenge()

  const challengesFiltered = (): Challenge[] => {
    if (filterOption === null) return challenges
    const { _id } = filterOption
    return challenges.filter(challenge => challenge.idProject === _id)
  }

  const { viewMode, showViewData, showViewCreate, showViewUpdate, updateItem, filterOption, changeFilterOption } =
    useViewControlTabPanel<Challenge, Project>({
      items: challenges,
      onChangeTitle,
      titleOptions: {
        none: null,
        create: 'Crear reto',
        update: 'Actualizar reto',
        data: 'Retos',
      },
    })

  const getFormDefaultValues = (): Partial<FormChallengeValues> | undefined => {
    if (!updateItem) return undefined
    const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } = updateItem
    return updateValues
  }

  const create = ({ state, ...other }: FormChallengeValues, reset: () => void) => {
    const newChallenge: NewChallenge = other
    fetchCreateChallenge(newChallenge, _id => {
      reset()
      onCreate(_id)
    })
  }

  const update = (formValues: FormChallengeValues, reset: () => void) => {
    if (!updateItem) {
      return
    }
    const { _id } = updateItem
    const updateChallenge: UpdateChallenge = { _id, ...formValues }
    fetchUpdateChallenge(updateChallenge, _updateValues => {
      reset()
      const { _id } = _updateValues
      const _updateChallenges = challenges.map(challenge => {
        if (challenge._id === _id) {
          return { ...challenge, ..._updateValues }
        }
        return challenge
      })
      onUpdate(_updateChallenges)
      showViewData()
    })
  }

  const disable = ({ _id }: Challenge) => {
    const disableChallenge: ChangeActiveChallenge = {
      _id,
      is_active: false,
    }
    fetchDisableChallenge(disableChallenge, () => {
      const _challenges = challenges.filter(challenge => challenge._id !== _id)
      onUpdate(_challenges)
    })
  }

  const onSubmitForm = (formValues: FormChallengeValues, reset: () => void) => {
    if (updateItem) return update(formValues, reset)
    create(formValues, reset)
  }

  return (
    <>
      <ViewAlert />

      {/**************************
                CONTENEDOR FORMULARIO
            ***************************/}
      <Fade in={viewMode === 'update' || viewMode === 'create'}>
        <Box
          sx={{
            display: viewMode === 'data' || !viewMode ? 'none' : 'block',
          }}
        >
          <FormChallenge
            pdi={pdi}
            onCancel={showViewData}
            onSubmit={onSubmitForm}
            projects={projects}
            defaultValues={getFormDefaultValues()}
            viewMode={viewMode === 'data' || !viewMode ? undefined : viewMode}
          />
        </Box>
      </Fade>

      {/****************
                CONTENEDOR TABLA RETOS
            *****************/}
      <Fade in={viewMode === 'data'}>
        <Box sx={{ display: viewMode === 'data' ? 'block' : 'none' }}>
          <Box sx={formStyles.container}>
            {/****************
                            TITULO
                        *****************/}
            <Box sx={formStyles.buttonTitle}>
              {/*******************
                                TITULO PROYECTO
                            ********************/}
              <Typography sx={{ fontSize: '25px' }}>
                {filterOption ? `Retos de ${filterOption.title}` : 'Todos los retos'}
              </Typography>

              <Box>
                {/***************************
                                    BOTON FILTRAR PORPROYECTOS
                                ****************************/}
                <FilterPdi
                  getOptionLabel={option => option.title}
                  id="filter-challenge-by-project"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  labelInput="Proyectos"
                  noOptionsText="Sin resultados"
                  options={projects}
                  onChange={(e, value) => changeFilterOption(value)}
                  placeholder="Buscar proyecto..."
                  value={filterOption}
                />

                {/*************
                                    BOTON MÁS
                                **************/}
                <Button disableTouchRipple onClick={showViewCreate}>
                  <IconAdd />
                  <Typography>Crear nuevo reto</Typography>
                </Button>
              </Box>
            </Box>

            <TableChallenges
              filterOption={filterOption}
              onUpdate={showViewUpdate}
              onDelete={disable}
              challenges={challengesFiltered()}
              projects={projects}
            />
          </Box>
        </Box>
      </Fade>
    </>
  )
}

export default TabPanelChallenges
