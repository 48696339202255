/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Menu, MenuItem, Pagination, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

// IMPORTADOS
import usersStyles from '../../../users/users.styles'
import doAssignmentStyles from '../DoAssignment.styles'
import ButtonSearch from '../../../../../components/buttons/ButtonSearch'
import Spinner from '../../../../../components/utilities/Spinner'
import IconCircleLoading from '../../../../../components/icons/IconCircleLoading'
import IconCircleCheck from '../../../../../components/icons/IconCircleCheck'
import IconDownload from '../../../../../components/icons/IconDownload'
import { useNavigate } from 'react-router-dom'
import { dateToString } from '../../../../../services/ParseDate'
import useFormula from '../../../../../hooks/do/useFormula'
import IconBell from '../../../../../components/icons/IconBell'
import IconCircleClose from '../../../../../components/icons/IconCircleClose'
import formStyles from '../../../../../components/forms/form.styles'
import CustomModal from '../../../../../components/modals/customModal/CustomModal'
import IconBell2 from '../../../../../components/icons/IconBell2'
import useImage from '../../../../../hooks/image/useImage'

interface ITableDoAssignmentIndicating {
  dataTable: any
  setMeasureEdit: Function
}

const TableDoAssignmentIndicating = ({ dataTable, setMeasureEdit }: ITableDoAssignmentIndicating) => {
  const [list, setList] = useState<any[]>([])
  const [status, setStatus] = useState<any[]>([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(4)
  const [from, setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [openBell, setOpenBell] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { fetchGetStatus } = useFormula()
  const { getUrl } = useImage()

  const navigate = useNavigate()

  // PAGINACION
  const listData = async () => {
    setLoading(true)
    const res: any = await fetchGetStatus()
    setStatus(res.items.reverse())

    setList(dataTable)
    const pages = dataTable.length / limit

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleBell = () => {
    setOpenBell(true)
    handleClose()
  }

  const handleCloseModal = () => {
    setOpenBell(false)
  }

  useEffect(() => {
    if (dataTable) {
      listData()
    }
  }, [dataTable])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  const handleChange = (option: number) => {
    let _data = []
    switch (option) {
      case 0:
        _data = dataTable.filter((item: any) => item.idStatus?.code === 0)
        setList(_data)
        handleClose()
        break
      case 1:
        _data = dataTable.filter((item: any) => item.idStatus?.code === 1)
        setList(_data)
        handleClose()
        break

      case 2:
        _data = dataTable.filter((item: any) => item.idStatus?.code === 2)
        setList(_data)
        handleClose()
        break
      case 3:
        setList(dataTable)
        handleClose()
        break

      default:
        break
    }

    setPage(1)
    const pages = _data.length / limit

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
  }

  const handleDownload = async (uploadEvidence: string) => {
    const res: any = await getUrl({ action: 'getUrl', form: { url: uploadEvidence } })
    window.open(`${res.data?.url}`, 'blank')
  }

  return (
    <Box className="containerTable">
      <Box className="contentTable" sx={{ ...usersStyles.userTable, marginTop: '30px !important' }}>
        <Box sx={doAssignmentStyles.title}>
          <Typography>Registro de Medición</Typography>

          <ButtonSearch />
        </Box>

        <table>
          {/***********
            TITULOS
          ************/}
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Númerador</th>
              <th>Denominador</th>
              <th>Resultado</th>
              <th>Meta</th>
              <th>Check-in</th>
              <th>
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                  onClick={e => handleClick(e)}
                >
                  <span>Estado</span>
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </div>
                <Menu
                  id="basic-menu"
                  className="format-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={doAssignmentStyles.menu}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => handleChange(3)}>Todos</MenuItem>
                  {status.map((item: any) => (
                    <MenuItem onClick={() => handleChange(item.code)}>{item.name}</MenuItem>
                  ))}
                </Menu>
              </th>
              <th></th>
            </tr>
          </thead>

          {/***********************************
            LISTA DE ASIGNACION DE TAREAS
          ************************************/}
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={9} rowSpan={5}>
                  <Box
                    sx={{
                      height: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner />
                  </Box>
                </td>
              </tr>
            ) : (
              list.slice(from, limit * page).map((row, index) => (
                <tr key={row._id}>
                  {/* FECHA */}
                  <td>
                    <Box>{dateToString(row.createdAt) || 'Sin Datos'}</Box>
                  </td>

                  {/* NUMERADOR */}
                  <td>
                    <Box>{row.numerator || 'Sin Datos'}</Box>
                  </td>

                  {/* DENOMINADOR */}
                  <td>
                    <Box>{row.goal || 'Sin Datos'}</Box>
                  </td>

                  {/* RESULTADO */}
                  <td>
                    <Box>{row.result || 'Sin Datos'}</Box>
                  </td>

                  {/* META */}
                  <td>
                    <Box>{row.goal || 'Sin Datos'}</Box>
                  </td>

                  {/* CHECK-IN */}
                  <td>
                    <Button
                      onClick={() => navigate(`/do/assignment/indicating/checkIn/${row._id}`)}
                      disableTouchRipple
                      sx={{
                        backgroundColor:
                          row.idStatus?.code === 0 ? theme => theme.palette.secondary.main : '#fff !important',
                        borderRadius: '92px',
                        width: 'auto',
                        color: theme =>
                          theme.palette.secondary.main === '#FCCC64' ? '#765404 !important' : '#000000 !important',
                        padding: '10px 20px',
                        ':hover': {
                          backgroundColor: theme => theme.palette.secondary.main,
                        },
                      }}
                      disabled={row.idStatus?.code !== 0}
                    >
                      Check-in
                    </Button>
                  </td>

                  {/* ESTADO */}
                  <td>
                    {row.idStatus?.code === 0 ? (
                      <IconCircleLoading />
                    ) : row.idStatus?.code === 1 ? (
                      <IconCircleCheck />
                    ) : (
                      row.idStatus?.code === 2 && <IconCircleClose />
                    )}
                  </td>

                  {/* ICONOS */}
                  <td>
                    {row.idStatus?.code === 2 ? (
                      <>
                        <IconButton sx={{ minWidth: 'inherit' }} onClick={handleBell}>
                          <IconBell2 color="#FCCC64" />
                        </IconButton>
                        <CustomModal
                          open={openBell}
                          onCloseModal={handleCloseModal}
                          onCloseForButtom={handleCloseModal}
                          maxHeight={300}
                          maxWidth={900}
                          borderRadius="40px"
                        >
                          <div
                            style={{
                              margin: '60px',
                            }}
                          >
                            <Typography sx={formStyles.title}>Justificación de la decisión</Typography>
                            <p style={{ marginTop: '20px' }}>{row.justification}m</p>
                          </div>
                        </CustomModal>
                      </>
                    ) : (
                      <IconButton sx={{ minWidth: 'inherit' }} onClick={handleBell} disabled>
                        <IconBell color="#FCCC64" />
                      </IconButton>
                    )}
                  </td>
                  <td>
                    <Button sx={{ minWidth: 'inherit' }} onClick={() => handleDownload(row.uploadEvidence)}>
                      <IconDownload />
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Box>

      {/****************
        PAGINACION
      ****************/}
      <Box sx={usersStyles.pagesList}>
        <Pagination count={pages} page={page} onChange={changePage} />
      </Box>
    </Box>
  )
}

export default TableDoAssignmentIndicating
