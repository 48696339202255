import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

// IMPORTADOS
import logoAplanner from "../../../images/logos/logoAplanner.png";
import footerStyles from "./footer.styles";

const Footer = () => {
    const { pathname } = useLocation();

    return (
        <>
            {/**********************
                CONTENEDOR FOOTER
            ***********************/}
            <Box
                sx={{
                    ...footerStyles.container,
                    marginTop:
                        pathname === "/home"
                            ? "33px"
                            : "158px" && pathname === "/plan/process/create"
                            ? "0px"
                            : "158px" && pathname === "/companies"
                            ? "293px"
                            : "250px",
                }}
            >
                {/*********
                    LOGO
                **********/}
                <img src={logoAplanner} alt="Logo Aplanner" />

                {/***********************
                    DERECHOS RESERVADOS
                ************************/}
                <Typography>
                    Todos los derechos reservados © 2022. Aplanner
                </Typography>
            </Box>
        </>
    );
};

export default Footer;
