import { StyleComponent } from "./../../../interfaces/styleComponent";

type Keys = "container" | "grid" | "parrafo";

const footerStyles: StyleComponent<Keys> = {
    container: {
        // backgroundColor: "pink",
        paddingTop: "40px",
        paddingBottom: "50px",
    },

    grid: {
        // backgroundColor: "aqua",
        maxWidth: "730px",
        margin: "auto",
        paddingLeft: "0px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",

        "@media(max-width: 645px)": {
            flexDirection: "column",

            ">#logoFooter": {
                order: -1,
                marginTop: "0px",
            },

            ">a": {
                marginTop: "20px",
                textAlign: "center",
            },
        },

        ">a": {
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "bold",
            color: "#003487",
            textDecoration: "none",
        },
    },

    parrafo: {
        padding: "20px",
        textAlign: "center",
        fontSize: "10px",
    },
};

export default footerStyles;
