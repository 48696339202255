import { FunctionComponent } from "react";
interface CloseEyeProps {
    width?: number | string;
    height?: number | string;
}

const CloseEye: FunctionComponent<CloseEyeProps> = ({
    width = 22,
    height = 18,
}) => {
    return (
        <svg
            width={width}
            height={height}
            style={{
                margin: "auto auto 5px",
                right: "30px",
                left: "30px",
                bottom: 0,
            }}
            viewBox="0 0 22 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.90918 1C2.96979 2.83333 6.27282 6.5 11.0001 6.5C15.7274 6.5 19.0304 2.83333 20.091 1"
                stroke="#CACACA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 7V10"
                stroke="#CACACA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5454 6L16.4545 9"
                stroke="#CACACA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1819 4L21.0001 6"
                stroke="#CACACA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.45459 6L5.5455 9"
                stroke="#CACACA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.81812 4L0.999933 6"
                stroke="#CACACA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CloseEye;
