import { StyleComponent } from "../../../../../interfaces/styleComponent";

type Keys =
    | "divisor"
    | "container"
    | "content"
    | "contentLeft"
    | "contentRight"
    | "titleButtonSearch";

const relationsStyles: StyleComponent<Keys> = {
    divisor: {
        border: "1px solid rgba(114, 130, 184, 0.5)",
        width: "inherit",
        marginY: "122px",
    },

    container: {
        backgroundColor: "#ffffff",
        maxWidth: "1388px",
        minHeight: "60vh",
        margin: "auto",

        "@media (max-width: 1322px)": {
            paddingX: "100px",
        },
    },

    content: {
        marginTop: "42px",
        display: "flex",
        justifyContent: "space-between",
        // display: "grid",

        "@media (max-width: 1322px)": {
            display: "grid",
            justifyContent: "center",
        },
    },

    // CONTENEDOR IZQUERDA
    contentLeft: {
        backgroundColor: "#ffffff",
        width: "739px",
        // height: "868px",
        borderRadius: "17px",
        boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",

        "@media (max-width: 1322px)": {
            marginBottom: "50px",
        },

        "@media (max-width: 980px)": {
            width: "100%",
        },

        ">div": {
            margin: "51px 57px 0px 57px",

            // TITULO
            ">p": {
                color: "#1F398F",
                fontSize: "25px",
                fontWeight: "600",
                lineHeight: "38px",
            },

            // LISTA PROCESOS Y SUBPROCESOS
            ">.containerList": {
                marginBottom: "40px",
                ">ul": {
                    minHeight: "736px",

                    ".container-spinner": {
                        justifyContent: "center",
                    },

                    ">li": {
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        ">.Mui-selected": {
                            fontWeight: "bold",
                            color: "#1F398F",
                        },
                        ">div": {
                            paddingLeft: "15px",
                            borderRadius: "200px",
                            ":hover": {
                                ">div": {
                                    ">span": {
                                        fontWeight: "bold",
                                        color: "#1F398F",
                                    },
                                },
                            },

                            ">div": {
                                ">span": {
                                    fontSize: "20px",
                                },
                            },
                        },
                    },
                },

                // PAGINACION
                ">nav": {
                    width: "fit-content",
                    alignSelf: "end",
                    marginTop: "-40px",
                    marginLeft: "auto",

                    ">ul": {
                        justifyContent: "space-between",
                        ">li": {
                            ">.Mui-disabled": {
                                visibility: "hidden",
                            },

                            // BOTON DE NUMEROS
                            ">.MuiPaginationItem-page": {
                                display: "none",
                            },

                            // BOTON DE FLECHAS
                            ">.MuiPaginationItem-previousNext": {
                                backgroundColor: "transparent",
                                height: "100%",
                                ">svg": {
                                    fontSize: "2.5rem !important",
                                    ">path": {
                                        color: "#7282B8",
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },

    // CONTENEDOR DERECHA
    contentRight: {
        backgroundColor: "#FFFFFF",
        width: "555px",
        borderRadius: "17px",
        boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",

        "@media (max-width: 1322px)": {
            width: "inherit",
        },

        ">div": {
            margin: "51px 57px 0px 57px",

            // TITULO Y BOTON BUSCAR
            ">.titleButtonSearch": {
                display: "flex",
                justifyContent: "space-between",

                // TITULO
                ">p": {
                    color: "#1F398F",
                    fontSize: "25px",
                    fontWeight: "600",
                    lineHeight: "38px",
                },

                // BOTON BUSCAR
                ">div": {
                    width: "fit-content",
                },
            },
        },
    },

    titleButtonSearch: {
        display: "flex",
        justifyContent: "space-between",

        // TITULO
        ">p": {
            color: "#1F398F",
            fontSize: "25px",
            fontWeight: "600",
            lineHeight: "38px",
        },

        // BOTON BUSCAR
        ">div": {
            width: "fit-content",
        },
    },
};

export default relationsStyles;
