import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconRelation from "../../../../../components/icons/IconRelation";
import useRelations from "../../../../../hooks/pdi/relations/useRelations";

// IMPORTADOS
import infoProcessStyles from "../infoProcess.styles";

interface InfoSubProcessProps {
    id: string;
}

const InfoSubProcess = ({ id }: InfoSubProcessProps) => {
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
    const [dataSubProcess, setDataSubProcess] = useState<any>({});
    const navigate = useNavigate();
    const { fetchGetOneSubProcessIndicators } = useRelations()

    useEffect(() => {
        getSubProcess();
    }, [id]);

    const getSubProcess = async () => {
        const response = await fetchGetOneSubProcessIndicators(id)
        const data = response
        setDataSubProcess(data)
    }

    return (
        <>
            {/**************
                CONTENEDOR
            ***************/}
            <Box sx={infoProcessStyles.container}>
                {/**************
                    TITULO INFO
                ***************/}
                <Box sx={infoProcessStyles.contentTitle}>
                    <Box sx={infoProcessStyles.title}>
                        <Typography>Detalles del subproceso</Typography>
                    </Box>

                    {/***************************
                        BOTON VER RELACIONES
                    ****************************/}
                    <Button
                        className="buttonCreate"
                        href="relations"
                        disableTouchRipple
                    >
                        <IconRelation />

                        <Typography>Ver relaciones</Typography>
                    </Button>
                </Box>

                {/*************
                    CONTENIDO
                ***************/}
                <Box sx={infoProcessStyles.content}>
                    {/************** 
                        CONTENT 1 
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Nombre del proceso</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataSubProcess?.idProcess?.name ?? ""}
                        </Typography>
                    </Box>

                    {/*************** 
                        CONTENT 2 
                    ***************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Nombre del subproceso</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataSubProcess?.name ?? ""}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 3
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Justificación.</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataSubProcess?.justification ?? ""}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 4
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Porcentaje de cumplimiento</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>00%</Typography>
                    </Box>

                    {/*********************************
                        CONTENT 5 - CONTENEDOR LISTA
                    *********************************/}
                    <Box sx={infoProcessStyles.data}>
                        <Box>
                            {/***************
                                INDICADORES
                            ****************/}
                            <Box>
                                <Typography>Indicadores</Typography>

                                {/**********
                                    LISTA
                                **********/}
                                <Box>
                                    {dataSubProcess?.link_subprocessIndicator?.map((item: any, index: number) => (
                                        <Typography>{item.idIndicator[0].name}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InfoSubProcess;
