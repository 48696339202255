import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys =
  | 'cardContent'
  | 'header'
  | 'iconRight'
  | 'body'
  | 'title'
  | 'text'
  | 'footer'
  | 'footerContentLeft'
  | 'footerContentRight'
  | 'smallText'
  | 'buttonAction'
  | 'buttonCancel'

const cardItemPdiStyles: StyleComponent<Keys> = {
  cardContent: {
    backgroundColor: '#ffffff',
    minWidth: '194.27px',
    width: '341px',
    height: '200px',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
    borderRadius: '17px',
    margin: '14.8px 23px 12.8px',
    display: 'flex',
    flexDirection: 'column',
    '&>div': {
      paddingX: '30px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'end',
    mt: 3,
    pr: 1,
  },
  iconRight: {
    cursor: 'pointer',
    borderRadius: '20%',
  },
  body: {
    p: 1,
  },
  title: {
    color: '#000000',
    fontSize: '18px',
    fontStyle: 'normal',
    lineHeight: '30px',
    fontWeight: '600',
  },
  text: {
    fontWeight: '500',
    fontSize: '15px',
    my: '5px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    mb: 3,
    mt: 'auto',
  },
  footerContentLeft: {
    width: '50%',
  },
  footerContentRight: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '>button': {
      fontSize: '12px',
      fontWeight: '500',
      textTransform: 'capitalize',
      lineHeight: '30px',
      fontStyle: 'normal',
      borderRadius: '20px',
      height: '27px',
      width: '67px',
      marginRight: '5.51px',
      marginLeft: '5px',
    },
  },
  smallText: {
    fontWeight: '500',
    fontSize: '12px',
    my: '5px',
  },
  buttonAction: {
    background: '#1F398F1A',
    color: '#1F398F',
    ':hover': {
      background: '#1F398F1A',
    },
  },
  buttonCancel: {
    backgroundColor: theme => theme.palette.secondary.main,
    color: theme => theme.palette.secondary.contrastText,
    ':hover': {
      backgroundColor: theme => theme.palette.secondary.main,
    },
  },
}

export default cardItemPdiStyles
