import * as yup from 'yup'
import { FormFactorValues } from './../../interfaces/factor';
import { testMaxLength } from '../validations';

export const formFactorSchema: yup.SchemaOf<FormFactorValues> = yup.object({
    idDepartament: yup.string()
        .required('El area es un campo requerido')
        .length(24, 'El area seleccionada no es válida'),
    name: yup.string()
        .trim('El nombre no puede contener solo espacios en blanco')
        .test('maxLength', 'El nombre no puede contener mas de 50 caracteres', val => testMaxLength(val, 50))
        .required('El nombre es un campo requerido'),
    description: yup.string()
        .required('La descripción es un campo requerido')
        .test('maxLength', 'La descripción no puede contener mas de 500 caracteres', val => testMaxLength(val, 500))
        .trim('La descripción no puede contener solo espacion en blanco'),
    justification: yup.string()
        .trim('La justificación no puede contener solo campos en blanco')
        .test('maxLength', 'La justificación no puede contener mas de 500 caracteres', val => testMaxLength(val, 500) )
        .required('La justificación es un campo requerido')

}).required()