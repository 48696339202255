import { StyleComponent } from './../../../interfaces/styleComponent'
import { infoAgenda } from '../../../components/data/dataAPI'

type Keys = 'container' | 'contentLeft' | 'contentRight' | 'buttom'

const doStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '52.7vh',
    margin: 'auto',
    maxWidth: '1350px',

    '>p': {
      backgroundColor: 'inherit',
      fontSize: '50px',
      fontWeight: '600',
      color: '#1F398F',
      lineHeight: '75px',
      marginBottom: '46px',
      marginX: '30px',
    },

    // CONTENEDOR IZQUIERDA / DERECHA
    '>div': {
      display: 'flex',

      // RESPONSIVE
      '@media (max-width: 1360px)': {
        paddingX: '30px',
      },

      '@media (max-width: 1300px)': {
        flexDirection: 'column',
      },
    },
  },

  // CONTENEDOR IZQUIERDA
  contentLeft: {
    backgroundColor: '#ffffff',
    marginRight: '30px',

    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 1300px)': {
      marginRight: '0px',
      flexDirection: 'row',

      '>.contentParagraph': {
        marginX: '10px',
      },

      '>.buttomPdi': {
        marginX: '10px !important',
      },
    },

    '@media (max-width: 1036px)': {
      flexDirection: 'column',

      '>.contentParagraph': {
        width: 'auto !important',
      },

      '>.buttomPdi': {
        marginX: '10px !important',
        marginBottom: '56px',
        width: 'auto !important',
      },
    },

    // PARRAFO
    '>.contentParagraph': {
      backgroundColor: '#ffffff',
      minHeight: '350px',
      width: '650px',
      borderRadius: '50px',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
      marginBottom: '56px',

      '>.parrafo': {
        marginY: '56px !important',
        marginX: '58px',
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '30px',
        textAlign: 'justify',
      },
    },

    // BOTON PDI
    '>.buttomPdi': {
      borderRadius: '50px',
      width: '650px',
      marginTop: '0px',
      marginRight: '0px',
    },
  },

  // CONTENEDOR DERECHA
  contentRight: {
    marginLeft: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '@media (max-width: 1300px)': {
      marginLeft: '10px',
      marginRight: '10px',
    },

    '>div': {
      marginBottom: '0px',
      '>.contentDiary': {
        height: infoAgenda.length > 6 ? '318px' : '318px',
        overflowY: infoAgenda.length > 6 ? 'scroll' : 'hidden',
      },
    },

    '>.buttonEvent': {
      borderRadius: '50px',
      width: '100%',
      maxWidth: '663px',
      marginTop: '0px',
      marginRight: '0px',
      marginBottom: '56px',
    },

    '>.contentListDiary': {
      background: '#FFFFFF',
      width: '100%',
      maxWidth: '663px',
      height: '350px',
      borderRadius: '30px',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
      '>div': {
        margin: '25px 53px auto 58px',
        maxHeight: '318px',
        padding: '10px',
        marginBottom: '46px',

        // SCROLLBAR
        overflowY: infoAgenda.length > 6 ? 'scroll !important' : 'hidden !important',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '12px',
          height: '100px',
          '&-track': {
            backgroundColor: theme => theme.palette.secondary.main,
            borderRadius: '10px',
            border: '5px solid #ffffff',
          },
          '&-thumb': {
            backgroundColor: '#1F398F',
            borderRadius: '20px',
            width: '10px',
          },
        },

        '.titleListDiary': {
          '>p': {
            color: '#1F398F',
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 500,
          },
        },
      },
    },
  },

  // BOTONES
  buttom: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '28px',

    ':hover': {
      backgroundColor: 'rgba(60, 45, 146, 0.04)',
    },

    '>svg': {
      marginX: 'auto',
    },

    '>p': {
      color: '#1F398F',
      fontSize: '25px',
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: '38px',
      textAlign: 'center',
      textTransform: 'capitalize',
      marginTop: '28px',
    },
  },
}

export default doStyles
