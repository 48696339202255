import { Box, Breakpoint, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { FunctionComponent, forwardRef } from 'react'
import customDialogStyles from './customDialog.styles'
import IconClose from '../../icons/IconClose'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export interface CustomDialogProps {
  open: boolean
  onClose?: () => void
  children: React.ReactElement<any, any>
  maxWidth?: Breakpoint | false
  showCloseButton?: boolean
  // heigth?: number | string
  // width?: number | string
}

const CustomDialog: FunctionComponent<CustomDialogProps> = ({
  open,
  onClose,
  showCloseButton = true,
  maxWidth,
  children,
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: '30px', boxShadow: '0px 0px 20px rgba(114, 130, 184, 0.25)', width: '100%', p: '0px' },
      }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgb(255 255 255 / 70%)' },
        },
      }}
      scroll="body"
    >
      <Box sx={{ mb: '30px', mt : maxWidth && maxWidth != 'xs' && maxWidth !== 'sm' ? 8 : undefined }}>
        {showCloseButton && (
          <Box
            sx={{ ...customDialogStyles.closeButton, m: maxWidth && maxWidth != 'xs' && maxWidth !== 'sm' ? 3 : undefined }}
            onClick={onClose}
          >
            <IconClose width="100%" height="100%" />
          </Box>
        )}
      </Box>
      <DialogContent>{children ?? null}</DialogContent>
    </Dialog>
  )
}

export default CustomDialog
