import { StyleComponent } from '../../../interfaces/styleComponent'
import AmericanaBarranquilla from '../../../images/logos/americanaBarranquilla.jpg'
import AmericanaMedellin from '../../../images/logos/americanaMedellin.jpg'
import AmericanaMonteria from '../../../images/logos/americanaMonteria.jpg'

type Keys = 'container' | 'title' | 'branchOffice'

const branchOfficeStyles: StyleComponent<Keys> = {
  container: {
    minHeight: '60vh',
    maxWidth: '1350px',
    margin: 'auto',
    backgroundColor: '#ffffff',
  },
  title: {
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: "pink",

    '@media (max-width:1430px)': {
      marginX: '30px',
    },

    '>div': {
      display: 'flex',
      backgroundColor: 'inherit',

      '@media (max-width:1070px)': {
        '>p': {
          fontSize: '40px !important',
        },

        // BOTON TITLE
        '>.btnTitle': {
          '>p': {
            fontSize: '20px !important',
            textTransform: 'uppercase',
          },
        },
      },

      '@media (max-width:900px)': {
        '>.btnTitle': {
          '>p': {
            display: 'none',
          },

          '>span': {
            fontSize: '20px !important',
            textTransform: 'uppercase',
            display: 'block !important',
          },
        },
      },

      '@media (max-width:630px)': {
        '>p': {
          fontSize: '30px !important',
        },

        '>svg': {
          margin: 'auto 5px auto 5px !important',
        },
      },

      // TYPOGRAPHY
      '>p': {
        color: '#1F398F',
        fontSize: '50px',
        fontWeight: '600',
        lineHeight: '75px',
        fontStyle: 'normal',
      },

      // BOTON TITULO
      '>svg': {
        minWidth: '0px',
        height: 'max-content',
        margin: 'auto 15px auto 25px',
        borderRadius: '20px',
        ':hover': {
          backgroundColor: 'inherit',
        },
      },

      // BOTON TITLE
      '>.btnTitle': {
        minWidth: '0px',
        height: 'max-content',
        marginY: 'auto',
        padding: 0,
        borderRadius: '20px',
        ':hover': {
          backgroundColor: 'inherit',
        },
        '>p, >span': {
          color: '#7282B8',
          fontSize: '25px',
          fontWeight: '400',
          textTransform: 'capitalize',
        },

        '>span': {
          display: 'none',
        },
      },
    },

    // BOTON MORE
    '>.btnMore': {
      backgroundColor: '#ffffff',
      minWidth: '180px',
      height: '38px',
      marginY: 'auto',
      padding: '0px 15px',
      textTransform: 'inherit',
      borderRadius: '92px',
      boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',

      '@media (max-width: 630px)': {
        minWidth: 'auto',
        height: 'auto',
        padding: '10px',
        '>p': {
          display: 'none',
        },

        '>svg': {
          width: 15,
          height: 15,
        },
      },

      ':hover, :focus': {
        backgroundColor: '#7282B8',
        '>svg': {
          '>path': {
            fill: '#ffffff',
          },
        },
        '>p': {
          color: '#ffffff',
        },
      },

      '>p': {
        fontSize: '16px',
        marginLeft: '4px',
      },
    },
  },

  // SUCURSALES
  branchOffice: {
    backgroundColor: '#ffffff',
    display: 'flex',

    '@media (max-width: 1430px)': {
      marginX: '30px',
    },

    '@media (max-width: 1070px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      '>a': {
        marginRight: '0px !important',
      },
    },

    '@media (max-width: 887px)': {
      '>a': {
        marginLeft: '0px !important',
      },
    },

    '>button, >a': {
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
      padding: '0px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '424px',
      height: '425px',
      borderRadius: '17px',

      '>div': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.77) 0%, rgba(217, 217, 217, 0) 49.88%)',
        width: 'inherit',
        height: 'inherit',
        textAlign: 'center',
        borderRadius: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',

        '>button': {
          backgroundColor: '#ffffff',
          width: '95px',
          height: '38px',
          alignSelf: 'end',
          fontSize: '16px',
          textTransform: 'capitalize',
          borderRadius: '92px',
          marginTop: '20px',
          marginRight: '20px',
          boxShadow: '0px 0px 10px rgba(114, 130, 184, 0.25)',
          ':hover': {
            backgroundColor: '#7282B8',
            color: '#ffffff',
            '>svg>path': {
              fill: '#ffffff',
            },
          },
          '>svg': {
            marginRight: '4px',
          },
        },

        '>p': {
          marginBottom: '43px',
          color: '#ffffff',
          fontSize: '25px',
          textTransform: 'capitalize',
          '>svg': {
            marginRight: '7.1px',
          },
        },
      },
    },

    // AMERICANA BARRANQUILLA
    '>.americanaBarranquilla': {
      backgroundImage: `url(${AmericanaBarranquilla})`,
    },

    // AMERICANA MEDELLIN
    '>.americanaMedellin': {
      backgroundImage: `url(${AmericanaMedellin})`,
      marginLeft: '57px',
      marginRight: '59px',

      '@media (max-width: 944px)': {
        marginTop: '30px',
      },
    },

    // AMERICANA MONTERIA
    '>.americanaMonteria': {
      backgroundImage: `url(${AmericanaMonteria})`,

      '@media (max-width: 1070px)': {
        marginTop: '30px',
      },
    },
  },
}

export default branchOfficeStyles
