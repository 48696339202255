import { StyleComponent } from "../../interfaces/styleComponent";

type Keys = "container" | "textField";

const dateFieldStyles: StyleComponent<Keys> = {
    container: {
        width: "fit-content",
        textAlign: "center",
        ">p": {
            mt: "17px",
            width: "82%",
        },
    },
    textField: {
        ">div": {
            // INPUT
            ">input": {
                backgroundColor: "#F1F3F8",
                width: "184px",
                height: "39px",
                borderRadius: "200px",
                padding: "0px",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "15px",
                letterSpacing: "0.4em",
                border: "2.5px solid #7282B8",
            },

            // ICONO CALENDARIO
            ">div": {
                marginLeft: "0px",
                ">button": {
                    ":hover": {
                        backgroundColor: "inherit",
                    },
                    ">svg": {
                        ">path": {
                            fill: "#1F398F !important",
                        },
                    },
                },
            },

            // BORDES POR DEFECTO
            ">fieldset": {
                border: "none !important",
            },
        },
    },
};

export default dateFieldStyles;
