import { Box, Typography, Button, Pagination, Grow, PaginationProps } from '@mui/material'
import { FunctionComponent, memo, useState } from 'react'
import dayjs from 'dayjs'

// IMPORTADOS
import tablePdiStyles from '../../pdi/tablePdis/tablePdi.styles'
import IconDownload from '../../../icons/IconDownload'
import tableProcessStyles from '../../../../pages/private/plan/process/tableProcess.styles'
import { growTimeoutEffect } from '../../../../helpers/efects'
import { Pda } from '../../../../interfaces/pda/pda'
import ConfirmAlert, { ConfirmAlertprops } from '../../../Alerts/confirmAlert/ConfirmAlert'
import usePda from '../../../../hooks/pda/usePda'

interface TablePdasProps {
  pdas: Pda[]
  onUpdate: (pda: Pda) => void
  onDisable: (pda: Pda) => void
  paginationProps: PaginationProps
}

const TablePdas: FunctionComponent<TablePdasProps> = ({ pdas, onUpdate, onDisable, paginationProps }) => {
  const { disabledPA } = usePda()
  const getYear = (timestamp: number) => {
    return dayjs.unix(timestamp).format('YYYY')
  }

  const getFullDate = (timestamp: number) => {
    return dayjs.unix(timestamp).format('DD/MM/YYYY')
  }

  const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>({
    show: false,
    confirmMessage: 'Estas seguro que deseas eliminar esta meta?',
    confirmMessageBottom: 'Recuerde que si elimina este plan de accion no podra ser modificado nuevamente',
    textButtonActionConfirm: 'Si',
    textButtonActionCancel: 'No',
  })

  const handleDisabled = (id: string) => {
    setConfirmAlertData(state => ({
      ...state,
      show: true,
      onClickButtonActionConfirm: async () => {
        setConfirmAlertData(state => ({ ...state, show: false }))
        const resp = await disabledPA({ _id: id })
        if (resp.ok) {
          
        }
      },
    }))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ConfirmAlert
        {...confirmAlertData}
        onClickButtonActionCancel={() => setConfirmAlertData(state => ({ ...state, show: false }))}
        onCloseForButtom={() => setConfirmAlertData(state => ({ ...state, show: false }))}
      />
      {/*************************
                    CONTENEDOR CARD PDA
                *************************/}
      <Box sx={tablePdiStyles.container}>
        {/************
                        CARD PDA
                    *************/}
        {pdas.map((pda, index) => (
          <Grow key={pda._id} in={true} timeout={growTimeoutEffect(index)}>
            <Box key={pda._id} sx={{ ...tablePdiStyles.card }}>
              {/**************************
                    CONTENEDOR TITULO CARD
                ***************************/}
              <Box sx={tablePdiStyles.title}>
                {/***************
                    TITULO PDI
                ****************/}
                <Typography>{pda.is_active ? 'Activo' : 'Inactivo'}</Typography>

                {/*******************
                    BOTON DESCARGAR
                *********************/}
                <Button disableRipple>
                  <IconDownload />
                </Button>
              </Box>

              {/***************************
                NOMBRE Y FECHA CARD PDI
            ****************************/}
              <Box sx={tablePdiStyles.nameAndDate}>
                {/*******************
                    NOMBRE CARD PDI
                ********************/}
                <Typography sx={tablePdiStyles.name} noWrap>
                  {pda.title}
                </Typography>

                {/*******************
                    FECHA CARD PDI
                ********************/}
                <Typography>{pda.idBranchOffice?.nameBranchOffice}</Typography>

                <Box sx={tablePdiStyles.percentageOfCompletionContainer}>
                  {/* <Typography sx={tablePdiStyles.percentageOfCompletionTitle}>Porcentaje de cumplimiento:</Typography>

                  <Typography sx={tablePdiStyles.percentageOfCompletionValue}>
                    {`${pda.percentageOfCompletion}%`}
                  </Typography> */}
                </Box>
              </Box>

              {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
              <Box sx={tablePdiStyles.buttonsContainer}>
                {/**********************************
                    BOTONES ACTUALIZAR/DESACTIVAR
                ***********************************/}
                <div>
                  {/********************
                    BOTON ACTUALIZAR
                *********************/}
                  <Button
                    disableTouchRipple
                    onClick={() => onUpdate(pda)}
                    sx={{
                      ...tablePdiStyles.buttonUpdate,
                      display: pda.is_active ? '' : 'none',
                    }}
                  >
                    Actualizar
                  </Button>

                  {/********************
                        BOTON DESACTIVAR
                    *********************/}
                  <Button
                    disableTouchRipple
                    sx={{
                      ...tablePdiStyles.buttonDisable,
                      display: pda.is_active ? '' : 'none',
                    }}
                    onClick={() => handleDisabled(pda._id)}
                  >
                    {/***************
                        DESACTIVAR
                    ***************/}
                    Desactivar
                  </Button>
                </div>

                {/**************
                    FECHA CARD
                ***************/}
                <Typography sx={tablePdiStyles.fechaCard}>{`${getFullDate(pda.finalDate)}`}</Typography>
              </Box>
            </Box>
          </Grow>
        ))}
      </Box>

      {/****************
            PAGINACION
        ****************/}
      <Box sx={tableProcessStyles.pagesList}>
        <Pagination {...paginationProps} />
      </Box>
    </Box>
  )
}

export default memo(TablePdas)
