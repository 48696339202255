/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable valid-typeof */
import { useCallback } from 'react'
import { CustomResponse } from '../interfaces/custom-response'
import fetchApi from '../services/fetchApi'
import useAuth from './useAuth'
import useMessages from './useMessages'

const actionsList = {
  getPdis: '/api/v1/pdi/list',
  getPolicies: '/api/v1/policies/list',
  getObjetives: '/api/v1/objetive/list',
  getPrograms: '/api/v1/program/list',
  getProjects: '/api/v1/project/list',
  getGoals: '/api/v1/goal/list',
  getChallenge: '/api/v1/challenge/list',
  getAreas: '/api/v1/area/list',
  getBranchOffices: '/api/v1/branchoffice/list',
  getUsers: '/api/v1/users/list',
  getMeasureUnits: '/api/v1/measureUnit/list',
  getTrends: '/api/v1/trend/list',
  getPositions: '/api/v1/position/list',
  getRoles: '/api/v1/rol/list',
  getDepartaments: '/api/v1/departament/list',
  getTypes: '/api/v1/type/list',
  getMeasurementFrecuency: '/api/v1/measurementFrecuency/list',
  getCompany: '/api/v1/company/list',
  getBranchOffice: '/api/v1/branchoffice/list',
  getColorThemes: '/api/v1/colorThemes/list',
  getProcess: '/api/v1/process/list',
  getIndicatorsProcess: '/api/v1/indiWeigthProcess/list',
  getSubProcess: '/api/v1/subProcess/list',
  getIndicatorsSubProcess: '/api/v1/indiWeigthSubProcess/list',
  getIndicators: '/api/v1/indicator/list',
  getFactors: '/api/v1/factors/list',
  getIndicatorsFactors: '/api/v1/indicatorFactor/list',
  getPdiActive: '/api/v1/pdi/filter-pdi-activate',
  getConditions: '/api/v1/conditions/list',
  getIndicatorsConditions: '/api/v1/indicatorCondition/list',
  getTaskAssigment: '/api/v1/taskAssignments/list',
  getPdas: '/api/v1/pa/list',
  getGoalsPaIndicators: '/api/v1/goalPaIndicator/list',
  getMyAssignments: '/api/v1/myAssignments/list',
  getAssignments: '/api/v1/assignments/list',
  getStatus: '/api/v1/status/list',
  getEvents: '/api/v1/event/list',
  getMyEvents: '/api/v1/event/listE',
  getTasksDates: '/api/v1/myAssignments/alerte',
  getEventDates: '/api/v1/myAssignments/alerte',
  getTypeObjetives: '/api/v1/typeObjetive/list',
}

const actionFindOne = {
  getLinkedPdi: '/api/v1/pdi/list',
  getOneUser: '/api/v1/users/list',
  getOneBranchOffice: '/api/v1/branchoffice/list',
  getOneProcess: '/api/v1/process/list',
  getOneSubProcess: '/api/v1/subProcess/list',
  getOneProcessIndicator: '/api/v1/process/listI',
  getOneSubProcessIndicator: '/api/v1/subProcess/listI',
  getOneCondition: '/api/v1/conditions/list',
  getOneDepartament: '/api/v1/departament/list',
  getOneFactorIndicators: '/api/v1/factors/listI',
  getOneFactor: '/api/v1/factors/list',
  getPdiActive: '/api/v1/pdi/filter-pdi-activate',
  getOnePa: '/api/v1/pa/list',
  getOneAssignment: '/api/v1/myAssignments/list',
  getOneAssignmentByIndicator: '/api/v1/formula/listE',
  getAcumulative: '/api/v1/formula/accumulated',
  getFormulas: '/api/v1/formula/indicator',
  getFormula: '/api/v1/formula/list',
  getIndicatorEvents: '/api/v1/myAssignments/listS',
  getAcumulativePdi: '/api/v1/graphics/list',
  getGraphicPa: '/api/v1/graphics/listPa',
  getGraphicPaPdi: '/api/v1/graphics/listPaPdi',
}

const actionsCreate = {
  createPdi: '/api/v1/pdi/create',
  createPolitics: '/api/v1/policies/create',
  createObjetive: '/api/v1/objetive/create',
  createProgram: '/api/v1/program/create',
  createProject: '/api/v1/project/create',
  createGoal: '/api/v1/goal/create',
  createChallenge: '/api/v1/challenge/create',
  createIndicator: '/api/v1/indicator/create',
  createUser: '/api/v1/users/create',
  createPosition: '/api/v1/position/create',
  createCompany: '/api/v1/company/create',
  createBranchOffice: '/api/v1/branchoffice/create',
  createProcess: '/api/v1/process/created',
  createIndiWeigthProcess: '/api/v1/indiWeigthProcess/create',
  createIndiWeigthSubProcess: '/api/v1/indiWeigthSubProcess/create',
  createFactor: '/api/v1/factors/create',
  createCondition: '/api/v1/conditions/create',
  createDepartaments: '/api/v1/departament/create',
  createTasks: '/api/v1/taskAssignments/create',
  createRelationIndicatorFactor: '/api/v1/indicatorFactor/create',
  createRelationIndicatorCondition: '/api/v1/indicatorCondition/create',
  createPa: '/api/v1/pa/create',
  createGoalPa: '/api/v1/goalPa/create',
  createFormula: '/api/v1/formula/create',
  createRelationsIndicatorsGoal: '/api/v1/goalPaIndicator/create',
  createEvents: '/api/v1/event/create',
}

const actionsUpdate = {
  updatePdi: '/api/v1/pdi/update',
  updatePolitics: '/api/v1/policies/update',
  updateObjetive: '/api/v1/objetive/update',
  updateProgram: '/api/v1/program/update',
  updateProject: '/api/v1/project/update',
  updateGoal: '/api/v1/goal/update',
  updateChallenge: '/api/v1/challenge/update',
  updateIndicator: '/api/v1/indicator/update',
  updateUser: '/api/v1/users/update',
  updateCompany: '/api/v1/company/update',
  updateBranchOffice: '/api/v1/branchoffice/update',
  updateProcess: '/api/v1/process/update',
  updateSubProcess: '/api/v1/subProcess/update',
  updateIndiWeigthProcess: '/api/v1/indiWeigthProcess/update',
  updateDepartament: '/api/v1/departament/update',
  updateFactor: '/api/v1/factors/update',
  updateCondition: '/api/v1/conditions/update',
  updateIndicatorsFactors: '/api/v1/indicatorFactor/update',
  updateTaskAssignment: '/api/v1/taskAssignments/update',
  updatePa: '/api/v1/pa/update',
  updateGoaLPa: '/api/v1/goalPa/update',
  updateFormula: '/api/v1/formula/update',
  checkin: '/api/v1/formula/checkIn',
  eventUpdate: '/api/v1/event/update',
}

const actionDelete = {
  disablePdi: '/api/v1/pdi/delete',
  disablePolitics: '/api/v1/policies/delete',
  disableObjetive: '/api/v1/objetive/delete',
  disableProgram: '/api/v1/program/delete',
  disableProject: '/api/v1/project/delete',
  disableGoal: '/api/v1/goal/delete',
  disableChallenge: '/api/v1/challenge/delete',
  disableIndicator: '/api/v1/indicator/delete',
  disableUser: '/api/v1/users/disable',
  disableProcess: '/api/v1/process/delete',
  disableSubProcess: '/api/v1/subProcess/delete',
  disableDepartament: '/api/v1/departament/delete',
  deleteFactor: '/api/v1/factors/delete',
  deleteCondition: '/api/v1/conditions/delete',
  disableTaskAssigment: '/api/v1/taskAssignments/delete',
  disabledPA: '/api/v1/pa/delete',
  deleteGoalPa: '/api/v1/goalPa/delete',
  deleteIndicatorPa: '/api/v1/goalPaIndicator/delete',
}

const actionsUpload = {
  uploadJustificationCondition: '/api/v1/conditions/upload',
}

type ParamsUrl = [string, string | number | boolean][]

interface FetchFindOne {
  action: keyof typeof actionFindOne
  findId: string
}
interface FetchListProps {
  action: keyof typeof actionsList
  params?: ParamsUrl
}

interface FetchCreateProps {
  action: keyof typeof actionsCreate
  body: any
}

interface FetchUpdateProps<T> {
  action: keyof typeof actionsUpdate
  body: T
}

interface fetchDisableProps {
  action: keyof typeof actionDelete
  body: { _id: string; is_active?: boolean }
}

type FetchUploadProps = Partial<CustomResponse> & {
  action: keyof typeof actionsUpload
  body: FormData
}

type ResponseFindOne<T> = Partial<CustomResponse> & {
  data: T
}

type ResponseList<T> = Partial<CustomResponse> & {
  items?: T[]
  total?: number
}

type ResponseCreate = Partial<CustomResponse> & {
  _id: string
}

export type BasicFetchListProps =
  | {
      active?: boolean
      limit?: number
      from?: number
    }
  | undefined

const getParamsUrl = (paramsUrl: ParamsUrl = []): string => {
  return paramsUrl.length > 0 ? `?${paramsUrl.map(item => item.join('=')).join('&')}` : ''
}

const useFetch = () => {
  const { token, onLogout } = useAuth()

  const { getMessage } = useMessages()

  const getFixErrorMessage = (
    props: Partial<Pick<CustomResponse, 'error' | 'typeError' | 'msg' | 'message'>>,
    status: number
  ): string => {
    // const orderProps = Object.keys(props).sort(() => -1) as (keyof typeof props)[]
    // const findProp = orderProps.find(p => typeof props[p] === 'string')
    // if (!findProp) return getMessage(status)
    // return props[findProp] as string
    // const priorityKeys = Object.keys(props)
    //     .sort(() => -1) as (keyof typeof props)[]

    // const propString = priorityKeys.find((key) => typeof props[key] === 'string')
    // if(propString) return propString

    // const propObject =
    // return 'Ha ocurrido un error. por favor, contactar con soporte'

    // priorityKeys.forEach(k => {
    //     if(k !== 'message')
    // })
    const { error, message, typeError, msg } = props
    if (typeof message === 'string' && message) return message
    if (msg) return msg
    if (error && typeof error === 'string') return error
    if (typeof error === 'object' && typeof error !== null && typeof error !== undefined) {
      const _message = error.message
      if (typeof _message === 'string' && _message) return _message
    }

    if (typeof typeError === 'string' && typeError) return typeError

    return getMessage(status)
  }

  const fetchFindOne = useCallback(
    async <T extends {}>({ action, findId }: FetchFindOne) => {
      try {
        if (!token) {
          onLogout({ expired: true })
          throw Error('Usuario no autenticado')
        }
        const endpoit = `${actionFindOne[action]}/${findId}`
        const response = await fetchApi({ method: 'GET', endpoit, token })
        const { data, statusCode, success, ...errors } = await (response.json() as Promise<ResponseFindOne<T>>)
        if (response.status !== 200 && !response.ok) {
          if (response.status === 401) {
            const sesionExpiredMessage = getMessage(response.status)
            onLogout({ expired: true })
            throw Error(sesionExpiredMessage)
          }
          const errorMessage = getFixErrorMessage(errors, response.status)
          throw Error(errorMessage)
        }

        if (!data) {
          throw Error('Datos no encontrados.')
        }

        return data
      } catch (error) {
        throw error
      }
    },
    [token, onLogout]
  )

  const fetchList = useCallback(
    async <T extends {}>({ action, params = [] }: FetchListProps) => {
      try {
        if (!token) {
          onLogout({ expired: true })
          throw Error('Usuario no autenticado')
        }
        const url = actionsList[action]
        const paramsUrl = getParamsUrl(params)
        const endpoit = `${url}${paramsUrl}`
        const response = await fetchApi({ method: 'GET', endpoit, token })
        const {
          items = [],
          total = 0,
          statusCode,
          success,
          ...errors
        } = await (response.json() as Promise<ResponseList<T>>)
        if (response.status !== 200 && !response.ok) {
          if (response.status === 401) {
            const sesionExpiredMessage = getMessage(response.status)
            onLogout({ expired: true })
            throw Error(sesionExpiredMessage)
          }
          const errorMessage = getFixErrorMessage(errors, response.status)
          throw Error(errorMessage)
        }
        return { items, total }
      } catch (error) {
        throw error
      }
    },
    [token, onLogout, getMessage]
  )

  const fetchCreate = useCallback(
    async ({ action, body }: FetchCreateProps) => {
      try {
        if (!token) {
          onLogout({ expired: true })
          throw Error('Usuario no autenticado')
        }
        const endpoit = actionsCreate[action]
        const response = await fetchApi({ method: 'POST', endpoit, body, token })
        const { _id, statusCode, success, ...errors } = await (response.json() as Promise<ResponseCreate>)
        if (response.status !== 200 && !response.ok) {
          if (response.status === 401) {
            const sesionExpiredMessage = getMessage(response.status)
            onLogout({ expired: true })
            throw Error(sesionExpiredMessage)
          }
          const errorMessage = getFixErrorMessage(errors, response.status)
          throw Error(errorMessage)
        }
        return _id
      } catch (error) {
        throw error
      }
    },
    [token, onLogout, getMessage]
  )

  const fetchUpdate = useCallback(
    async <T extends {}>({ action, body }: FetchUpdateProps<T>) => {
      try {
        if (!token) {
          onLogout({ expired: true })
          throw Error('Usuario no valido')
        }
        const endpoit = actionsUpdate[action]

        const response = await fetchApi({ method: 'PUT', endpoit, body, token })
        const { statusCode, success, ...errors } = await (response.json() as Promise<CustomResponse>)

        if (response.status !== 200 && !response.ok) {
          if (response.status === 401) {
            const sesionExpiredMessage = getMessage(response.status)
            onLogout({ expired: true })
            throw Error(sesionExpiredMessage)
          }
          const errorMessage = getFixErrorMessage(errors, response.status)
          throw Error(errorMessage)
        }
        return body
      } catch (error: any) {
        throw error
      }
    },
    [token, onLogout, getMessage]
  )

  const fetchDisable = useCallback(
    async ({ action, body }: fetchDisableProps) => {
      try {
        if (!token) {
          onLogout({ expired: true })
          throw Error('Usuario no valido')
        }
        const endpoit = actionDelete[action]
        const response = await fetchApi({ method: 'DELETE', endpoit, body, token })
        const { statusCode, success, ...errors } = await (response.json() as Promise<CustomResponse>)
        if (response.status !== 200 && !response.ok) {
          if (response.status === 401) {
            const sesionExpiredMessage = getMessage(response.status)
            onLogout({ expired: true })
            throw Error(sesionExpiredMessage)
          }
          const errorMessage = getFixErrorMessage(errors, response.status)
          throw Error(errorMessage)
        }
        return body
      } catch (error) {
        throw error
      }
    },
    [token, onLogout, getMessage]
  )

  const fetchUpload = useCallback(
    async <T extends {} = {}>({ action, body }: FetchUploadProps) => {
      try {
        if (!token) {
          onLogout({ expired: true })
          throw Error('Usuario no valido')
        }
        const endpoit = actionsUpload[action]
        const response = await fetchApi({
          method: 'POST',
          endpoit,
          body,
          typeofBody: 'formData',
          token,
        })
        const { statusCode, success, ...data } = await (response.json() as Promise<CustomResponse<T>>)
        if (response.status !== 200 && !response.ok) {
          if (response.status === 401) {
            const sesionExpiredMessage = getMessage(response.status)
            onLogout({ expired: true })
            throw Error(sesionExpiredMessage)
          }
          const errorMessage = getFixErrorMessage(data, response.status)
          throw Error(errorMessage)
        }
        return data.message as T
      } catch (error) {
        throw error
      }
    },
    [token, onLogout, getMessage]
  )

  return {
    fetchList,
    fetchFindOne,
    fetchCreate,
    fetchUpdate,
    fetchDisable,
    fetchUpload,
  }
}

export default useFetch
