import { FunctionComponent } from 'react'
import { useTheme } from '@mui/styles'

interface IconQuickAccessProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconQuickAccess: FunctionComponent<IconQuickAccessProps> = ({ width = 15, height = 34, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main

  return (
    <svg width={width} height={height} viewBox="0 0 15 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3649 0.0187396C10.0634 0.630929 -0.390777 19.7108 0.0113061 19.7108H3.05601C4.89197 19.7108 6.2981 21.3436 6.02735 23.1595C5.38715 27.4532 4.39732 34.2045 4.53474 33.9953C4.73578 33.6892 15.391 13.9971 14.9889 13.9971H11.9442C10.1082 13.9971 8.70195 12.3644 8.97113 10.5483C9.60448 6.27512 10.5709 -0.399409 10.3649 0.0187396Z"
        fill={color ?? secondary}
      />
    </svg>
  )
}

export default IconQuickAccess
