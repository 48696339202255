/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, Button, InputBase } from '@mui/material'
import './companiesStyles.css'

// IMPORTADOS
import customTitleButtonAddStyles from '../../../components/customTitleButtonAdd.styles'
import formCompanyStyles from './FormCompany.styles'
import UploadImageCompany from './UploadImageCompany'
import PopoverPrincipal from './PopoverPrincipal'
import PopoverCabecera from './PopoverCabecera'
import { ValidateFields, ValidateForm } from '../../../services/ValidateForm'
import { setCompany } from '../../../redux/features/auth/authSlice'
import { companyCreateSchema } from '../../../schemas/companySchema'
import ErrorField from '../../../components/ErrorField'
import CustomizeTheme from './CustomizeTheme'
import useCompany from '../../../hooks/company/useCompany'
import useImage from '../../../hooks/image/useImage'
import { useDispatch } from 'react-redux'
import formStyles from '../../../components/forms/form.styles'
import useAppDialog from '../../../hooks/useAppDialog'

// import useCompany, { FailResponse } from '../../../hooks/useCompany';
interface ImageState {
  preview: string
  file: any
}

const FormCompany: FunctionComponent = () => {
  const { fetchUpdateCompany } = useCompany()
  const { uploadImage } = useImage()
  const { fetchGetCompany } = useCompany()
  const { showErrorDialog } = useAppDialog()

  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()

  const [imgPrincipal, setImgPrincipal] = useState<ImageState>()
  const [imgHeader, setImgHeader] = useState<ImageState>()
  const [dataForm, setDataForm] = useState<any>({})
  const [errors, setErrors] = useState<any>()

  const handleInputChange = async (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })

    const result = await ValidateFields({
      schema: companyCreateSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }

  const handleSubmit = async () => {
    const result = await ValidateForm({
      schema: companyCreateSchema,
      data: dataForm,
    })

    if (result.isValid) {
      const response: any = await sendImage()
      if (response.ok) {
        if (params.id) {
          const resUpdate: any = await fetchUpdateCompany({ ...dataForm, ...response.dataImg })
          if (resUpdate.ok) {
            navigate('/companies')
            dispatch(setCompany({ ...dataForm, ...response.dataImg }))
          }
        }
      } else {
        showErrorDialog({ text: response.msg })
      }
    } else {
      setErrors(result.errors)
    }
  }

  const sendImage = async () => {
    let res: any = { ok: true }
    let dataImg: any = {}
    if (imgPrincipal?.file) {
      const formData = new FormData()
      formData.append('logo', imgPrincipal?.file)
      const resImg1: any = await uploadImage({ action: 'companyUploadImg', form: formData })
      if (resImg1.ok) {
        dataImg = {
          ...dataImg,
          logo: resImg1.data?.key,
          logoLink: resImg1.data?.Url ?? resImg1.data?.url,
        }
      } else {
        res = {
          ok: false,
          msg: 'Error al cargar imagen de logo principal',
        }
      }
    }
    if (imgHeader?.file) {
      const formData = new FormData()
      formData.append('horizontalLogo', imgHeader?.file)
      const resImg2: any = await uploadImage({ action: 'companyUploadImgH', form: formData })
      console.log(resImg2)
      if (resImg2.ok) {
        dataImg = {
          ...dataImg,
          horizontalLogo: resImg2.data?.key,
          horizontalLogoLink: resImg2.data?.Url ?? resImg2.data?.url,
        }
      } else {
        res = {
          ok: false,
          msg: 'Error al cargar imagen de logo cabecera',
        }
      }
    }
    return { ...res, dataImg }
  }

  useEffect(() => {
    getCompany()
  }, [])

  const getCompany = async () => {
    const company: any = await fetchGetCompany()
    const { is_active, createdAt, updatedAt, __v, ...data } = company.items[0]
    setDataForm(data)
  }

  return (
    <>
      {/* CONTENEDOR FORMULARIO EMPRESAS */}
      <Box sx={formCompanyStyles.container}>
        {/**************************
                    CONTENEDOR TITULO PDI 
                ***************************/}
        <Box
          sx={{
            ...customTitleButtonAddStyles.container,
            marginBottom: '32px',
            position: 'relative',
          }}
        >
          {/***************
                        TITULO PDI
                    ****************/}
          <Typography
            sx={{
              ...customTitleButtonAddStyles.title,
              fontSize: '50px',
            }}
          >
            {params.id ? 'Editar empresa' : 'Registrar empresa'}
          </Typography>

          {/***********************
                        BOTON PERSONALIZAR
                    ************************/}
          <CustomizeTheme />
        </Box>

        {/**************
                    CONTENIDO
                ***************/}
        <Box>
          {/*******************
                        CONTENEDOR GRID
                    *********************/}
          <Box sx={formStyles.containerGrid}>
            {/*************
                            NOMBRES
                        ***************/}
            <Box
              sx={{
                ...formCompanyStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Nombre de la empresa</Typography>

              {/* INPUT */}
              <Box sx={formCompanyStyles.input}>
                <InputBase
                  autoFocus={true}
                  sx={formCompanyStyles.inputBase}
                  name="nameCompany"
                  value={dataForm?.nameCompany ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.name ?? ''} />
              </Box>
            </Box>

            {/*************
                APELLIDOS
            ***************/}
            <Box
              sx={{
                ...formCompanyStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>No. NIT</Typography>

              {/* INPUT */}
              <Box sx={formCompanyStyles.input}>
                <InputBase
                  sx={formCompanyStyles.inputBase}
                  type="text"
                  name="nit"
                  value={dataForm?.nit ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.nit ?? ''} />
              </Box>
            </Box>
          </Box>

          {/*******************
                        CONTENEDOR BLOCK
                    *********************/}
          <Box sx={formCompanyStyles.containerBlock}>
            {/*************
                            EMAIL
                        ***************/}
            <Box
              sx={{
                ...formCompanyStyles.subTitle,
                display: 'block',
              }}
            >
              <Typography>Actividad de la empresa</Typography>

              {/* INPUT */}
              <Box sx={formCompanyStyles.inputMultiline}>
                <InputBase
                  rows={4}
                  multiline
                  sx={formCompanyStyles.inputBaseMultiline}
                  name="activity"
                  value={dataForm?.activity ?? ''}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: '500',
                  }}
                />
                <ErrorField message={errors?.activity ?? ''} />
              </Box>
            </Box>
          </Box>

          {/*******************
                        CONTENEDOR GRID
                    *********************/}
          <Box sx={formStyles.containerGrid}>
            {/*************
                            CELULAR
                        ***************/}
            <Box
              sx={{
                ...formCompanyStyles.subTitle,
                marginRight: '19px',
              }}
            >
              <Typography>Misión de la empresa</Typography>

              {/* INPUT */}
              <Box sx={formCompanyStyles.inputMultiline}>
                <InputBase
                  rows={4}
                  multiline
                  sx={formCompanyStyles.inputBaseMultiline}
                  name="mission"
                  value={dataForm?.mission ?? ''}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: '500',
                  }}
                />
                <ErrorField message={errors?.mission ?? ''} />
              </Box>
            </Box>

            {/*************
                            CELULAR
                        ***************/}
            <Box
              sx={{
                ...formCompanyStyles.subTitle,
                marginLeft: '19px',
              }}
            >
              <Typography>Visión de la empresa</Typography>

              {/* INPUT */}
              <Box sx={formCompanyStyles.inputMultiline}>
                <InputBase
                  multiline
                  rows={4}
                  sx={formCompanyStyles.inputBaseMultiline}
                  name="vision"
                  value={dataForm?.vision ?? ''}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: '500',
                  }}
                />
                <ErrorField message={errors?.vision ?? ''} />
              </Box>
            </Box>
          </Box>

          {/*******************
                        CONTENEDOR BLOCK
                    *********************/}
          <Box sx={formCompanyStyles.containerBlock}>
            {/*************
                            EMAIL
                        ***************/}
            <Box
              sx={{
                ...formCompanyStyles.subTitle,
                display: 'block',
              }}
            >
              <Typography>E-mail representante legal</Typography>

              {/* INPUT */}
              <Box sx={formCompanyStyles.input}>
                <InputBase
                  sx={formCompanyStyles.inputBase}
                  type="email"
                  name="emailLegalRepresentative"
                  value={dataForm?.emailLegalRepresentative ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.emailLegalRepresentative ?? ''} />
              </Box>
            </Box>

            {/*************
                            IMAGEN
                        ***************/}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  ...formCompanyStyles.subTitle,
                  display: 'block',
                  width: '50% !important',
                  marginRight: '19px',
                }}
              >
                <Typography sx={{ display: 'flex', text: 'center' }}>
                  Logo principal <PopoverPrincipal />
                </Typography>
                <UploadImageCompany img={imgPrincipal} setImg={setImgPrincipal} name="imgLogin" />
              </Box>

              <Box
                sx={{
                  ...formCompanyStyles.subTitle,
                  display: 'block',
                  width: '50% !important',
                  marginLeft: '19px',
                }}
              >
                <Typography sx={{ display: 'flex', text: 'center' }}>
                  Logo cabecera
                  <PopoverCabecera />
                </Typography>
                <UploadImageCompany img={imgHeader} setImg={setImgHeader} name="imgHeader" />
              </Box>
            </Box>

            {/**********************
                            CONTENEDOR BOTONES 
                        ***********************/}
            <Box sx={formCompanyStyles.divButton}>
              {/**********************************
                                BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
              <div>
                {/********************
                                    BOTON ACTUALIZAR
                                *********************/}
                <Button
                  disableTouchRipple
                  sx={{
                    ...formCompanyStyles.buttonSave,
                    marginRight: '19.52px',
                  }}
                  onClick={handleSubmit}
                >
                  {params.id ? 'Editar' : 'Crear'}
                </Button>

                {/********************
                                    BOTON DESACTIVAR
                                *********************/}
                <Button onClick={() => navigate(-1)} disableTouchRipple sx={formCompanyStyles.buttonCancel}>
                  Cancelar
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormCompany
