import * as React from 'react';
import Popover from '@mui/material/Popover';
import IconInfo from '../../../components/icons/IconInfo';
import { Box, Typography } from "@mui/material";
import logoCabecera from '../../../images/logos/logoAmericanaHorizontalGrande.png';

const styles = {
  text: {
    textAlign: "center"
  }
}
export default function PopoverCabecera() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popoverImage' : undefined;

  return (
    <span
      className="info-image-company"
      title="Ejemplo de logo"
    >
      <span
        aria-describedby={id}
        onClick={handleClick}
      >
        <IconInfo />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 5px 5px -3px rgba(114, 130, 184, 0.1), 0px 8px 10px 1px rgba(114, 130, 184, 0.1), 0px 3px 14px 2px rgba(114, 130, 184, 0.1)",
          }
        }}
      >
        <Box sx={{ padding: "20px 30px" }}>
          <Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant="h6" sx={styles.text}>
                Ejemplo de logo cabecera
              </Typography>
              <Typography variant="subtitle2" sx={styles.text}>
                Es usado en la barra de navegación
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logoCabecera}
                alt='logo'
                width={200}
              ></img>
            </Box>
          </Box>
        </Box>
      </Popover>
    </span >
  );
}