import { useState, useCallback } from "react"
import GenericAlert, { GenericAlertProps } from "../../components/Alerts/GenericAlert/GenericAlert"
import { NewObjetive, Objetive, UpdateObjetive, ChangeActiveObjetive } from "../../interfaces/pdi/objetive"
import useFetch from "../useFetch"

interface FetchObjetivesProps {
    idPdi: string
    limit?: number
    from?: number
    active?: boolean
}

const useObjetive = () => {

    const { fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()

    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })

    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )

    const fetchGetObjetives = useCallback(async ({ idPdi, limit = 0, from = 0, active = true }: FetchObjetivesProps) => {
        return fetchList<any>({
            action: 'getObjetives',
            params: [
                ['idPdi', `${idPdi}`],
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchList])

    const fetchCreateObjetive = useCallback((body: NewObjetive, done?: (_id: string) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createObjetive', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El objetivo se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_id),
                })
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchCreate])

    const fetchUpdateObjetive = useCallback((body: UpdateObjetive, done?: (_updateValues: UpdateObjetive) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate<UpdateObjetive>({ action: 'updateObjetive', body })
            .then(_updateValues => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El objetivo se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchUpdate])

    const fetchDisableObjetive = useCallback((body : ChangeActiveObjetive, done? : () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disableObjetive', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `El objetivo ha sido ${body.is_active ? 'habilitado' : 'eliminado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return {
        fetchGetObjetives,
        fetchCreateObjetive,
        fetchUpdateObjetive,
        fetchDisableObjetive,
        ViewAlert
    }
}

export default useObjetive