export const listPermissions = {
  user: {
    create: 'createUser',
    read: 'readUser',
    update: 'updateUser',
    delete: 'deleteUser',
  },
  company: {
    create: 'createCompany',
    read: 'readCompany',
    update: 'updateCompany',
    delete: 'deleteCompany',
  },
  branchOffice: {
    create: 'createBranchOffice',
    read: 'readBranchOffice',
    update: 'updateBranchOffice',
    delete: 'deleteBranchOffice',
  },
  area: {
    create: 'createArea',
    read: 'readArea',
    update: 'updateArea',
    delete: 'deleteArea',
  },
  pdi: {
    create: 'createPDI',
    read: 'readPDI',
    update: 'updatePDI',
    delete: 'deletePDI',
  },
  pa: {
    create: 'createPA',
    read: 'readPA',
    update: 'updatePA',
    delete: 'deletePA',
  },
  process: {
    create: 'createProcess',
    read: 'readProcess',
    update: 'updateProcess',
    delete: 'deleteProcess',
  },
  subProcess: {
    create: 'createSubProcess',
    read: 'readSubProcess',
    update: 'updateSubProcess',
    delete: 'deleteSubProcess',
  },
  relationsProcessAndSubProcess: {
    create: 'createProcess',
  },
  factor: {
    create: 'createFactor',
    read: 'readFactor',
    update: 'updateFactor',
    delete: 'deleteFactor',
  },
  condition: {
    create: 'createCondition',
    read: 'readCondition',
    update: 'updateCondition',
    delete: 'deleteCondition',
  },
  relationsFactorsAndConditions: {
    create: 'createCondition',
  },
  taskAssignment: {
    create: 'createTaskAssignment',
    read: 'readTaskAssignment',
    update: 'updateTaskAssignment',
    delete: 'deleteTaskAssignment',
  },
  measurement: {
    create: 'createFormula',
    read: 'readFormula',
  },
  checkin: {
    create: 'createCheckin',
  },
}
