import { StyleComponent } from '../../interfaces/styleComponent'

type Keys = 'containerInputSelect' | 'inputSelect'

const areaNameStyles: StyleComponent<Keys> = {
  // INPUT SELECT
  containerInputSelect: {
    // width: "60%",
    '>p': {
      fontSize: '20px',
      color: '#1F398F',
      fontWeight: '600',
      marginBottom: '9px',
    },
  },
  inputSelect: {
    backgroundColor: '#ffffff',
    borderRadius: '200px',
    width: '100%',
    // LABEL DEL SELECT
    '>label, >.MuiInputLabel': {
      color: '#1F398F',
      zIndex: 1,
      fontFamily: 'Poppins',
      marginTop: '4px',
      marginLeft: '15px',
    },

    '>.MuiInputLabel-shrink': {
      display: 'none',
    },

    // SELECT
    '>div': {
      backgroundColor: '#F1F3F8',
      borderRadius: '200px',
      height: '39px',
      ':before, :after': {
        display: 'none',
      },

      '>div': {
        backgroundColor: '#F1F3F8 !important',
        height: '39px !important',
        borderRadius: '200px !important',
        padding: '0px 40px 0px 10px !important',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
      },
      '>svg': {
        backgroundColor: 'transparent',
        fontSize: '50px !important',
        '>path': {
          fill: '#1F398F',
        },
      },
    },
  },
}

export default areaNameStyles
