import { FunctionComponent } from 'react'
import { useTheme } from '@mui/styles'

interface IconArrowBottomProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconArrowBottom: FunctionComponent<IconArrowBottomProps> = ({ width = 13, height = 12, color = '#1F398F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.2711 11.1585C7.00507 11.6138 6.36588 11.6138 6.09985 11.1585L0.499233 1.57192C0.22406 1.10091 0.552355 0.499999 1.08486 0.499999L12.2861 0.5C12.8186 0.5 13.1469 1.10091 12.8717 1.57192L7.2711 11.1585Z"
        fill={color}
      />
    </svg>
  )
}

export default IconArrowBottom
