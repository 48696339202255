import { FunctionComponent } from "react"
import { Box, Typography, Button } from '@mui/material'
import CustomModal from "../../modals/customModal/CustomModal"
import confirmAlertStyles from "./confirmAlert.styles"
import IconX from "../../icons/IconX"

export interface ConfirmAlertprops {
    show : boolean
    confirmMessage: string,
    confirmMessageBottom?: string
    textButtonActionConfirm?: string
    textButtonActionCancel?: string
    onClickButtonActionConfirm?: React.MouseEventHandler<HTMLButtonElement>
    onClickButtonActionCancel? : React.MouseEventHandler<HTMLButtonElement>
    onCloseForButtom ? : React.MouseEventHandler<HTMLButtonElement>
}

const ConfirmAlert: FunctionComponent<ConfirmAlertprops> = ({ show,
    confirmMessage, confirmMessageBottom, textButtonActionConfirm = 'SI',
    textButtonActionCancel = 'NO', onClickButtonActionConfirm, onClickButtonActionCancel,
    onCloseForButtom }) => {

    return (
        <CustomModal
            maxWidth={898}
            maxHeight={520}
            open = { show }
            onCloseForButtom = {onCloseForButtom}
            viewEntrie={ null }
        >
            <>
                {/***********
                    ICONO X
                *************/}
                <Box sx={confirmAlertStyles.iconX}>
                    <IconX />
                </Box>

                {/*********
                    TITLE
                ***********/}
                <Typography sx={confirmAlertStyles.title}>
                    {confirmMessage}
                </Typography>
                {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
                <Box sx={confirmAlertStyles.divButton}>
                    {/******************
                        BOTONES SI/NO
                    ********************/}
                    <div>
                        {/************
                            BOTON SI
                        *************/}
                        <Button
                            disableTouchRipple
                            sx={confirmAlertStyles.buttonYes}
                            onClick={onClickButtonActionConfirm}
                        >
                            {textButtonActionConfirm}
                        </Button>

                        {/************
                            BOTON NO
                        *************/}
                        <Button
                            disableTouchRipple
                            sx={confirmAlertStyles.buttonNo}
                            onClick={onClickButtonActionCancel}
                        >
                            {textButtonActionCancel}
                        </Button>
                    </div>
                </Box>

                {/*********
                    TEXTO 
                **********/}
                {confirmMessageBottom && (
                    <Typography sx={confirmAlertStyles.text}>
                        {confirmMessageBottom}
                    </Typography>
                )}
            </>
        </CustomModal>
    )
}

export default ConfirmAlert