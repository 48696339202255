import { StyleComponent } from "../../../../interfaces/styleComponent";

type Keys =
    | "containerList"
    | "titleButtonSearch"
    | "contentTab"
    | "contentCard"
    | "contentCheckAll"
    | "checked"
    | "checkedRadio"
    | "contentInputSearch"
    | "text"
    | "containerPagination"
    | "divButton"
    | "buttonSave"
    | "buttonBack";

const taskAssignmentTabsStyles: StyleComponent<Keys> = {
    contentCard: {
        // backgroundColor: "#ffffff",
        // backgroundColor: "aqua",
        width: "906px",
        // width: "424px",
        minHeight: "736px",
        // height: "868px",
        borderRadius: "17px",
        boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",
        // padding: "0px 40px 62px",

        // RESPONSIVE
        "@media (max-width: 1322px)": {
            marginBottom: "50px",
        },

        "@media (max-width: 980px)": {
            width: "100%",
        },

        // CONTENEDOR LISTA Y TABS
        ">div": {
            // backgroundColor: "red",
            margin: "70px 38px 50px",

            // CONTENEDOR DE TABS
            ">.MuiTabs-root": {
                backgroundColor: "#ffffff",
                width: "450px",
                height: "75px",
                margin: "auto",
                borderRadius: "250px",
                boxShadow: "0px 0px 10px 5px rgba(114, 130, 184, 0.1)",

                ">.MuiTabs-scroller": {
                    margin: "auto",
                    marginBottom: "auto !important",
                    ">.MuiTabs-flexContainer": {
                        justifyContent: "center",
                    },
                },
            },

            // PADDING-TOP REMOVIDO
            ">div>div": {
                paddingTop: "0px",
            },
        },
    },

    // LISTA PROCESOS Y SUBPROCESOS
    containerList: {
        // backgroundColor: "green",
        // marginBottom: "40px",
        overflowY: "hidden",

        ">ul": {
            // minHeight: "548px",
            // minHeight: "367px",
            minHeight: "330px",
            maxHeight: "330px",
            paddingTop: "18px",

            // SCROLL
            "::-webkit-scrollbar": {
                width: "12px",
                size: "10px",
                "&-track": {
                    backgroundColor: "#FECF5E",
                    borderRadius: "10px",
                    border: "5px solid #ffffff",
                },
                "&-thumb": {
                    backgroundColor: "#7282B8",
                    borderRadius: "20px",
                    width: "10px",
                },
            },

            // CHECKBOX ALL
            // ">div": {
            //     backgroundColor: "red",
            //     display: "grid",
            //     justifyContent: "space-between",
            //     ">.checkAll": {
            //         display: "grid",
            //         justifyContent: "space-between",
            //     },
            // },

            // LIST CHECKBOX
            ">li": {
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                ">div": {
                    // paddingLeft: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",

                    ":hover": {
                        backgroundColor: "transparent",
                        ">div": {
                            ">span": {
                                fontWeight: "bold !important",
                                color: "#1F398F",
                            },
                        },
                    },

                    ">div": {
                        marginY: "0px",
                        ">span": {
                            fontSize: "15px",
                        },
                    },

                    // CHECKBOX
                },
            },
        },
    },

    // CHECKBOX ALL
    contentCheckAll: {
        display: "flex",
        justifyContent: "space-between",

        ">.checkAllLeft": {
            paddingY: "0px",
            ">div": {
                width: "fit-content",
                paddingLeft: "0px",

                ":hover": {
                    backgroundColor: "inherit",
                },

                // BOTON VOLVER
                ">button": {
                    fontSize: "12px",
                    textTransform: "capitalize",

                    ":hover": {
                        backgroundColor: "inherit",
                        color: "#1F398F",
                        fontWeight: "bold",
                    },

                    ">svg": {
                        marginRight: "5px",
                    },
                },
            },
        },

        ">.checkAllRight": {
            display: "grid",
            justifyContent: "end",
            paddingY: "0px",
            ">div": {
                "div>span": {
                    fontSize: "12px",
                },

                ":hover": {
                    backgroundColor: "inherit",
                    color: "#1F398F",

                    "div>span": {
                        color: "#1F398F",
                        fontWeight: "bold",
                    },
                },
            },
        },
    },

    // CHECKBOX
    checked: {
        minWidth: "20px",
        marginLeft: "10px",
        marginRight: "10px",

        ">span, >input": {
            fontSize: "20px",
            color: "#000000 !important",
            marginLeft: "0px",
            padding: "0px",
            borderRadius: "2px",
            width: "14px",
            height: "14px",
        },

        // SELECCIONADO
        ">.Mui-checked": {
            backgroundColor: "#7282B8",
            width: "14px",
            height: "14px",
        },
    },

    // RADIO BUTTON
    checkedRadio: {
        ">input[type=radio]": { marginY: "4px" },
        ">input[type='radio' i]:checked": {
            backgroundColor: "yellow",
            border: "8px solid #0fbf12",
            borderRadius: "100%",
            color: "purple",
        },
        // ">input[type=radio]:not(:checked)": {
        //     backgroundColor: "yellow",
        //     border: "8px solid #0fbf12",
        //     borderRadius: "100%",
        //     color: "red",
        // },
    },

    contentInputSearch: {
        backgroundColor: "aqua",
        display: "flex",
    },

    titleButtonSearch: {
        display: "flex",
        justifyContent: "center",
        marginTop: "35px",
        marginBottom: "29px",

        // TITULO
        ">p": {
            color: "#1F398F",
            fontSize: "25px",
            fontWeight: "600",
            lineHeight: "38px",
        },

        // BOTON BUSCAR
        ">div": {
            width: "fit-content",
        },
    },

    contentTab: {
        // backgroundColor: "red",
    },

    text: {
        marginBottom: "30px",
    },

    // CONTENEDOR BUSCADOR/PAGINACION
    containerPagination: {
        display: "flex",

        "@media (max-width: 540px)": {
            flexDirection: "column-reverse",

            ">nav": {
                marginBottom: "5px",
            },
        },

        // BUSCADOR
        ".buttonSearch": {
            backgroundColor: "yellow",
            maxWidth: "228px",
        },

        // PAGINACION
        ">nav": {
            width: "fit-content",
            height: "40px",
            alignSelf: "end",
            // marginTop: "-40px",
            marginLeft: "auto",

            ">ul": {
                justifyContent: "space-between",
                flexWrap: "inherit",
                ">li": {
                    ">.Mui-disabled": {
                        visibility: "hidden",
                    },

                    // BOTON DE NUMEROS
                    ">.MuiPaginationItem-page": {
                        display: "none",
                    },

                    // BOTON DE FLECHAS
                    ">.MuiPaginationItem-previousNext": {
                        backgroundColor: "transparent",
                        height: "100%",
                        ">svg": {
                            fontSize: "2.5rem !important",
                            ">path": {
                                color: "#7282B8",
                            },
                        },
                    },
                },
            },
        },
    },

    // CONTENEDOR BOTONES
    divButton: {
        marginTop: "38px",
        marginX: "auto",
        width: "fit-content",

        "&>div>Button": {
            maxWidth: "123px",
            width: "123px",
            height: "33px",
            borderRadius: "200px",
            marginRight: "10.51px",

            // TEXTO
            fontSize: "12px",
            fontWeight: "500",
            textTransform: "uppercase",
            lineHeight: "18px",
            fontStyle: "normal",
        },
    },
    buttonBack: {
        backgroundColor: "#1F398F1A",
        color: "#1F398F",
        ":hover": {
            backgroundColor: "#1F398F1A",
        },
    },
    buttonSave: {
        background: "#1F398F",
        color: "#FFFFFF",
        ":hover": {
            background: "#1F398F",
        },
    },
};

export default taskAssignmentTabsStyles;
