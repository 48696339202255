import { useEffect, useState } from 'react'
import { Box, Typography, Button, Pagination, Grow } from '@mui/material'

// IMPORTADOS
import tableProcessStyles from '../../../../pages/private/plan/process/tableProcess.styles'
import IconDirection from '../../../icons/IconDirection'
import IconFile from '../../../icons/IconFile'
import InfoCondition, { InfoConditionProps } from '../../../Dialogs/family/InfoCondition'
import { useNavigate } from 'react-router-dom'
import useCondition from '../../../../hooks/family/useCondition'
import useDinamicPagination from '../../../../hooks/useDinamicPagination'
import { Condition, LinkedConditionIndicators } from '../../../../interfaces/condition'
import { growTimeoutEffect } from '../../../../helpers/efects'
import useAppDialog from '../../../../hooks/useAppDialog'

const itemsPerPage = 9

const TableConditions = () => {
  const navigate = useNavigate()

  const { conditionList, getConditions, getConditionsWithRelations, indicatorConditionList, deleteCondition } =
    useCondition()

  const { showConfirmDialog } = useAppDialog()

  const [isLoadConditionAndRelations, setIsLoadConditionAndRelations] = useState(false)

  const { currentPage, handleChangePage, limit, from, countPages, handleItemsPerPage } = useDinamicPagination(
    itemsPerPage,
    conditionList.total
  )

  useEffect(() => {
    if (!isLoadConditionAndRelations) {
      getConditionsWithRelations({ limit, from })
      setIsLoadConditionAndRelations(true)
      return
    }
    getConditions({ limit, from })
  }, [currentPage])

  const [infoConditionData, setInfoConditionData] = useState<InfoConditionProps>({
    open: false,
    condition: null,
  })

  const handleUpdate = ({ _id }: Condition | LinkedConditionIndicators) => navigate(`./update/${_id}`)

  const handleConfirmDialog = (condition: Condition | LinkedConditionIndicators) => {
    showConfirmDialog({
      icon: 'warning',
      text: 'Estas seguro que deseas eliminar esta condición?',
      smallText: 'Recuerde que si elimina esta condición usted no podrá editarla',
      confirmButton: {
        text: 'Si',
        onClick: () => {
          handleDelete(condition)
        },
      },
      cancelButton: { text: 'No' },
    })
  }

  const handleDelete = ({ _id }: Condition | LinkedConditionIndicators) => {
    deleteCondition(_id, () => {
      if (infoConditionData.open) setInfoConditionData(state => ({ ...state, open: false }))
      if (currentPage >= 2) return handleChangePage(1)
      getConditions({ limit, from })
    })
  }

  const handleModalInfo = (condition: Condition) => {
    setInfoConditionData(state => ({
      ...state,
      open: true,
      onClose: () => setInfoConditionData(prev => ({ ...prev, open: false })),
      condition,
    }))
  }

  const countNumbersIndicators = (conditionId: string) => {
    return indicatorConditionList.items.filter(
      rel => rel.is_active && rel.idCondition?.is_active && rel.idIndicator?.is_active && rel.idCondition._id === conditionId
    ).length
  }
  return (
    <>
      <InfoCondition
        indicatorsConditions={indicatorConditionList.items}
        onClose={() => setInfoConditionData(_state => ({ ..._state, open: false }))}
        onButtonDelete={handleConfirmDialog}
        onButtonUpdate={handleUpdate}
        {...infoConditionData}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/************************
          CONTENEDOR CARD PDI 
        ************************/}
        <Box sx={tableProcessStyles.container}>
          {/******************
            CARD PROCESOS
          *******************/}
          {conditionList.items.map((condition, index) => (
            <Grow key={condition._id} in={conditionList.total > 0} timeout={growTimeoutEffect(index)}>
              <Box sx={{ ...tableProcessStyles.card }}>
                {/**************************
                  CONTENEDOR TITULO CARD
                ***************************/}
                <Box sx={tableProcessStyles.title}>
                  {/*******************
                    BOTON INFO
                  *********************/}
                  <Button disableRipple onClick={() => handleModalInfo(condition)}>
                    <IconDirection />
                  </Button>
                </Box>

                {/***************************
                  NOMBRE Y FECHA CARD PDI
                ****************************/}
                <Box sx={tableProcessStyles.nameAndDate}>
                  {/*******************
                    NOMBRE CARD PDI
                  ********************/}
                  <Typography sx={tableProcessStyles.name}>{condition.name}</Typography>

                  {/*******************
                    FECHA CARD PDI
                  ********************/}
                  <Typography sx={tableProcessStyles.date}>
                    No. Indicadores: {`${countNumbersIndicators(condition._id)}`}
                  </Typography>

                  <Button href={condition.justificationLink} target="_blank" disableTouchRipple>
                    <IconFile />
                  </Button>
                </Box>

                {/**********************
                  CONTENEDOR BOTONES 
                ***********************/}
                <Box sx={tableProcessStyles.buttonsContainer}>
                  {/**********************************
                    BOTONES ACTUALIZAR/DESACTIVAR
                  ***********************************/}
                  <div>
                    {/********************
                      BOTON ACTUALIZAR
                    *********************/}
                    <Button
                      onClick={() => handleUpdate(condition)}
                      disableTouchRipple
                      sx={{
                        backgroundColor: '#1F398F1A',
                        ':hover': {
                          backgroundColor: '#1F398F1A',
                        },
                      }}
                    >
                      Editar
                    </Button>

                    {/********************
                      BOTON DESACTIVAR
                    *********************/}
                    <Button
                      onClick={() => handleConfirmDialog(condition)}
                      disableTouchRipple
                      sx={{
                        backgroundColor: theme => theme.palette.secondary.main,

                        // HOVER
                        ':hover': {
                          backgroundColor: theme => theme.palette.secondary.main,
                        },

                        '>a': {
                          paddingRight: '0px !important',
                          textDecoration: 'none',
                          ':hover': {
                            color: 'inherit',
                          },
                        },
                      }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </Box>
              </Box>
            </Grow>
          ))}
        </Box>

        {/****************
          PAGINACION
        ****************/}
        {conditionList.items.length >= 1 && (
          <Box sx={tableProcessStyles.pagesList}>
            <Pagination count={countPages} onChange={(e, page) => handleChangePage(page)} page={currentPage} />
          </Box>
        )}
      </Box>
    </>
  )
}

export default TableConditions
