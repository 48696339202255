import { useState, useCallback } from "react";
import GenericAlert, { GenericAlertProps } from "../../components/Alerts/GenericAlert/GenericAlert";
import { ChangeActiveGoal, NewGoal, UpdateGoal } from "../../interfaces/pdi/goal";
import useFetch from "../useFetch";

interface FetchGoalsProps {
    idPdi: string
    limit?: number
    from?: number
    active?: boolean
}


const useGoal = () => {

    const { fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()

    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })

    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )

    const fetchGetGoals = useCallback(async ({ idPdi, limit = 0, from = 0, active = true }: FetchGoalsProps) => {
        return fetchList<any>({
            action: 'getGoals',
            params: [
                ['idPdi', `${idPdi}`],
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchList])

    const fetchCreateGoal = useCallback((body: NewGoal, done?: (_id: string) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createGoal', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'La meta se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_id),
                })
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchCreate])

    const fetchUpdateGoal = useCallback((body: UpdateGoal, done?: (_updateValues: UpdateGoal) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate<UpdateGoal>({ action: 'updateGoal', body })
            .then(_updateValues => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'La meta se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchUpdate])

    const fetchDisableGoal = useCallback((body: ChangeActiveGoal, done?: () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disableGoal', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `La meta ha sido ${body.is_active ? 'habilitado' : 'eliminado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return { 
        fetchGetGoals,
        fetchCreateGoal, 
        fetchUpdateGoal, 
        fetchDisableGoal,
        ViewAlert
    }
}

export default useGoal;
