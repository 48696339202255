import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Pagination,
} from "@mui/material";
import useProcess from "../../../../../hooks/pdi/process/useProcess";
import Spinner from "../../../../../components/utilities/Spinner";

interface ProcessRelationsProps {
    process: string;
    setProcess: Function;
}

const ProcessRelations = ({ process, setProcess }: ProcessRelationsProps) => {
    // PAGINACION
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [limit, setLimit] = useState<number>(12);
    const [from, setfrom] = useState<number>(0);
    const [list, setList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { fetchGetProcess } = useProcess();

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setfrom((value - 1) * limit);
        setPage(value);
    };

    const getList = async () => {
        setLoading(true);
        const response: any = await fetchGetProcess({
            limit,
            from,
        });
        const data = response.items;
        setList(data);
        const pages = response.total / 10;

        if (Number.isInteger(pages)) {
            setTotalPages(pages);
        } else {
            setTotalPages(Math.trunc(pages) + 1);
        }
        setLoading(false);
    };

    useEffect(() => {
        getList();
    }, [page]);

    return (
        <Box>
            {/*******************
                TITULO PROCESOS
            *******************/}
            <Typography>Procesos</Typography>

            <Box className="containerList">
                {loading ? (
                    <List>
                        <Spinner />
                    </List>
                ) : (
                    <List>
                        {list.map((item: any, index: any) => (
                            <ListItem
                                key={index}
                                onClick={() => setProcess(item._id)}
                            >
                                <ListItemButton
                                    disableTouchRipple
                                    selected={process === item._id}
                                >
                                    <ListItemText>{item.name}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}

                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={changePage}
                />
            </Box>
        </Box>
    );
};

export default ProcessRelations;
