import { FunctionComponent } from "react";

interface IconSearchProp {
    width?: number | string;
    height?: number | string;
}

const IconSearch: FunctionComponent<IconSearchProp> = ({
    width = 12,
    height = 12,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="7.84783"
                cy="7.84783"
                r="6.84783"
                stroke="#1F398F"
                strokeWidth="2"
            />
            <line
                x1="13.8024"
                y1="13.1233"
                x2="18.9059"
                y2="17.5889"
                stroke="#1F398F"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconSearch;
