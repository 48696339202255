import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, InputBase, Typography } from '@mui/material'

// IMPORTADOS
import IconRelation from '../../../../components/icons/IconRelation'
import formProcessStyles from './formProcess.styles'
import AreaName from '../../../../components/inputSelect/AreaName'
import useProcess from '../../../../hooks/pdi/process/useProcess'
import { ValidateFields, ValidateForm } from '../../../../services/ValidateForm'
import { processCreateSchema } from '../../../../schemas/process'
import ErrorField from '../../../../components/ErrorField'
import FormSubProcess from './FormSubProcess'

const FormProcess = () => {
  const { fetchCreateProcess, fetchUpdateProcess, fetchGetOneProcess } = useProcess()
  const params = useParams()

  const [openForm, setOpenForm] = useState(false)
  const [dataForm, setDataForm] = useState<any>({})
  const [listSubProcess, setListSubProcess] = useState<any>([])
  const [errors, setErrors] = useState<any>({})

  let navigate = useNavigate()

  const handleInputChange = async (e: any) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
    const result = await ValidateFields({
      schema: processCreateSchema,
      data: { ...dataForm, [e.target.name]: e.target.value },
      fieldName: e.target.name,
    })
    setErrors(result)
  }
  const handleSelectChange = async (option: any) => {
    setDataForm({ ...dataForm, ...option })
  }

  const handleSubmit = async () => {
    const result = await ValidateForm({
      schema: processCreateSchema,
      data: dataForm,
    })

    if (result.isValid) {
      if (params.id) {
        const { link_Subprocess, link_processIndicator, ...data } = dataForm
        const res = await fetchUpdateProcess({ ...data, _id: params.id })
        if (res.ok) {
          navigate("/plan/process")
        }
      } else {
        const res = await fetchCreateProcess({ ...dataForm })
        if (res.ok) {
          setDataForm({})
          setListSubProcess([])
        }
      }
    } else {
      setErrors(result.errors)
    }
  }

  useEffect(() => {
    if (params.id) {
      getProcess()
    }
  }, [])

  useEffect(() => {
    setDataForm({ ...dataForm, subProcess: listSubProcess })
  }, [listSubProcess])

  const getProcess = async () => {
    const response: any = await fetchGetOneProcess(params.id || '')
    const { is_active, createdAt, updatedAt, idDepartament, __v, ...data } = response
    setDataForm({ ...dataForm, ...data, idDepartament: idDepartament._id })
    let subProcess: any = []
    data?.link_Subprocess?.forEach((element: any) => {
      subProcess = [
        ...subProcess,
        {
          _id: element._id,
          name: element.name,
          justification: element.justification,
          idProcess: element.idProcess,
        },
      ]
    })
    setListSubProcess(subProcess)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/*******************
                SUPER CONTENEDOR
            *********************/}
      <Box
        sx={{
          ...formProcessStyles.superContainer,
          marginRight: openForm === true ? '117px' : 'inherit',
          marginBottom: '158px',
          transition: 'margin-right 0.3s ease',
          animation: openForm === true ? 'margin-right 0.3s ease' : 'margin-right 0.3s reverse ease',
        }}
      >
        {/**************************
                    CONTENEDOR FORMULARIO
                ****************************/}
        <Box sx={formProcessStyles.container}>
          {/************************************
                        CONTENEDOR TITULO Y BOTON CREAR
                    **************************************/}
          <Box sx={formProcessStyles.title}>
            {/******************
                            TITULO PROCESOS
                        *******************/}
            <Typography>{params.id ? 'Editar Proceso' : 'Crear Proceso'}</Typography>

            {/**********
                            BOTONES
                        ************/}
            <Box className="buttons">
              {/***************************
                                BOTON AÑADIR SUBPROCESO
                            ****************************/}
              <Button
                className="buttonCreate"
                // href="process/create"
                onClick={e => setOpenForm(true)}
                disableTouchRipple
                sx={{
                  display: openForm === true ? 'none' : 'inline-flex',
                }}
              >
                <IconRelation />

                <Typography>Añadir subproceso</Typography>
              </Button>
            </Box>
          </Box>

          {/**************
                        CONTENIDO
                    **************/}
          <Box sx={formProcessStyles.content}>
            {/*************
                            AREA
                        ***************/}

            <AreaName
              handleSelectChange={handleSelectChange}
              errorMessage={errors?.idDepartament ?? ''}
              defaultValue={dataForm?.idDepartament ?? ''}
            />

            {/********************
                            NOMBRE DEL PROCESO
                        **********************/}
            <Box
              sx={{
                ...formProcessStyles.subtitle,
              }}
            >
              <Typography>Nombre del proceso</Typography>

              {/* INPUT */}
              <Box sx={formProcessStyles.input}>
                <InputBase
                  sx={formProcessStyles.inputBase}
                  name="name"
                  value={dataForm?.name ?? ''}
                  onChange={handleInputChange}
                />
                <ErrorField message={errors?.name ?? ''} />
              </Box>
            </Box>

            {/****************
                            DESCRIPCION
                        *****************/}
            <Box sx={formProcessStyles.description}>
              <Typography>Descripción</Typography>

              {/* INPUT */}
              <Box
                sx={{
                  ...formProcessStyles.inputDescription,
                  minHeight: openForm === true ? '225px' : '135px',
                }}
              >
                <InputBase
                  multiline
                  sx={formProcessStyles.inputBaseDescription}
                  name="description"
                  value={dataForm?.description ?? ''}
                  onChange={handleInputChange}
                  inputProps={{
                    maxlength: "500"
                  }}
                />
              </Box>
              <ErrorField message={errors?.description ?? ''} />
            </Box>

            {/********************
                            JUSTISTIFICACION
                        **********************/}
            <Box
              sx={{
                ...formProcessStyles.subtitle,
              }}
            >
              <Typography>Justificacion</Typography>

              {/* INPUT */}
              <Box sx={formProcessStyles.input}>
                <InputBase
                  sx={formProcessStyles.inputBase}
                  name="justification"
                  value={dataForm?.justification ?? ''}
                  onChange={handleInputChange}
                />
              </Box>
              <ErrorField message={errors?.justification ?? ''} />
            </Box>

            {/**********************
                            CONTENEDOR BOTONES 
                        ***********************/}
            <Box
              sx={{
                ...formProcessStyles.divButton,
                marginTop: '210px !important',
              }}
            >
              {/**********************************
                                BOTONES ACTUALIZAR/DESACTIVAR
                            ***********************************/}
              <div>
                {/********************
                                    BOTON CREAR
                                *********************/}
                <Button disableTouchRipple sx={formProcessStyles.buttonSave} onClick={handleSubmit}>
                  {params.id ? 'Actualizar' : 'Crear'}
                </Button>

                {/********************
                                    BOTON CANCELAR
                                *********************/}
                <Button onClick={e => navigate(-1)} disableTouchRipple sx={formProcessStyles.buttonCancel}>
                  Cancelar
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <FormSubProcess
        openForm={openForm}
        setOpenForm={setOpenForm}
        listSubProcess={listSubProcess}
        setListSubProcess={setListSubProcess}
        idProcess={dataForm._id ?? undefined}
      />
    </Box>
  )
}

export default FormProcess
