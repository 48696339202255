import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FormPda from '../../../forms/pda/formPda/FormPda'
import { FormPdaFields, Pda, UpdatePdaValues } from '../../../../interfaces/pda/pda'
import { PdaContext } from '../../../context/PdaProvider'

interface TabPanelPdaProps {

}

const TabPanelPda: FunctionComponent<TabPanelPdaProps> = () => {

  const pdaContext = useContext(PdaContext)

  const handleSubmit = (values: FormPdaFields) => {
    if(!pdaContext) return
    if (!pdaContext.pda) {
      pdaContext.createNewPda({
        values,
        handleSuccess: id => {
          pdaContext.handleTabIndex(1)
          // setSearchParams({ id }, { replace: true })
        },
      })
      return
    }

    const { _id } = pdaContext.pda

    const updateValues: UpdatePdaValues = {
      _id,
      ...values,
    }
    pdaContext.editPda({ updateValues })
  }
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState<Partial<FormPdaFields> | undefined>(undefined)

  useEffect(() => {
    if(!pdaContext) return
    
    if (!pdaContext.pda) {
      setFormValues(undefined)
      return
    }
    const { description, title, startDate, finalDate, idBranchOffice: branchOffice } = pdaContext.pda
    const inRange = branchOffice && pdaContext.branchOfficeList?.items.some(b => b._id === branchOffice?._id)
    setFormValues({
      description,
      title,
      startDate,
      finalDate,
      idBranchOffice: inRange ? branchOffice._id : '',
    })
  }, [pdaContext?.pda, pdaContext?.branchOfficeList])

  if(!pdaContext || !pdaContext.branchOfficeList || (!pdaContext.activePdi && !pdaContext.pda)) return null

  return (
    <FormPda
      activePdi={pdaContext.pda ? pdaContext.pda.idPdi : pdaContext.activePdi}
      branchOffices={pdaContext.branchOfficeList.items}
      buttonSubmitTitle={pdaContext.pda ? 'Actualizar' : 'Crear'}
      onSubmit={handleSubmit}
      onCancel={() => navigate('./../')}
      defaultValues={formValues}
    />
  )
}

export default TabPanelPda
