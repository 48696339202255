import { FunctionComponent } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Store } from './redux/Store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import AppRoutes from './routes/AppRoutes'
import  AppContext  from './components/context/AppProvider'

const App: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

const persistor = persistStore(Store)

const AppAplanner = () => (
  <Provider store={Store}>
    <PersistGate persistor={persistor}>
      <AppContext>
        <App />
      </AppContext>
    </PersistGate>
  </Provider>
)
export default AppAplanner
