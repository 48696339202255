import * as yup from 'yup'
import { FormProcessValues } from '../interfaces/pdi/process/process'

export const processCreateSchema: yup.SchemaOf<FormProcessValues> = yup.object({
  name: yup.string().required('Este campo es obligatorio'),
  idDepartament: yup.string().required('Este campo es obligatorio'),
  description: yup
    .string()
    .required('Este campo es obligatorio')
    .matches(/^.{1,500}$/, "Este campo no debe tener mas de 500 caracteres"),
  justification: yup.string().required('Este campo es obligatorio'),
})
