import React, { useEffect, useState } from 'react'
import { Box, IconButton, InputBase } from '@mui/material'
import IconSearch from '../icons/IconSearch'
import IconX2 from '../icons/IconX2'

interface IButtonSearchProps {
  data?: any[]
  setData?: Function
  fieldSearch?: string | string[]
}

const ButtonSearch = ({ data, setData = () => {}, fieldSearch = [''] }: IButtonSearchProps) => {
  const [btnOpen, setBtnOpen] = useState(false)
  const [value, setValue] = useState('')

  const handleSearch = (e: any) => {
    const text = String(e.target.value).toLowerCase()
    if (e.target.value !== '') {
      const dataResult = data?.filter((item: any) => {
        let values: any = []
        const isArray = Array.isArray(fieldSearch)
        if (isArray) {
          fieldSearch.forEach((field: any) => {
            const isLevels = field.includes('.')
            if (isLevels) {
              const array = field.split('.')
              let value = item
              array.forEach((element: any) => {
                if (value[element]) {
                  if (typeof value[element] === 'string') {
                    values = [...values, value[element].toLowerCase()]
                  } else {
                    value = value[element]
                  }
                }
              })
            } else {
              values = [...values, item[field].toLowerCase()]
            }
          })
        }
        return values.some((v: string) => {
          const c = String(v).toLowerCase()
          return c.includes(text)
        })
      })
      setData(dataResult)
    } else {
      setData(data)
    }
    setValue(e.target.value)
  }

  useEffect(() => {
    setValue('')
    setData(data)
  }, [data])

  const cleanSearch = () => {
    setData(data)
    setBtnOpen(false)
    setValue('')
  }

  return (
    // BOTON BUSCAR
    <Box
      className="buttonSearch"
      sx={{
        width: btnOpen === true ? '100%' : 'fit-content',
        maxWidth: '268px',

        '>div': {
          maxWidth: '268px',
          minHeight: '34px',
          color: '#1F398F',
          marginLeft: 'auto',
          paddingX: '7px',
          borderRadius: '500px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          '>button:hover': {
            backgroundColor: 'transparent',
          },
          '>div': {
            width: '100%',

            '>input': {
              color: '#1F398F',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Poppins',
            },
          },
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: btnOpen === true ? 'rgba(114, 130, 184, 0.1)' : 'transparent',
          width: btnOpen === true ? '100%' : 'fit-content',
        }}
      >
        {/******************
                    ICONO BUSCADOR
                *******************/}
        <IconButton onClick={e => setBtnOpen(true)} disableTouchRipple>
          <IconSearch width={19} height={19} />
        </IconButton>

        <InputBase
          type="text"
          placeholder="Buscar..."
          value={value}
          sx={{
            display: btnOpen === false ? 'none' : 'inline-block',
          }}
          onChange={handleSearch}
        />

        {/******************
                    ICONO BUSCADOR
                *******************/}
        <IconButton
          onClick={cleanSearch}
          sx={{
            display: btnOpen === false ? 'none' : 'inline-flex',
          }}
        >
          <IconX2 />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ButtonSearch
