import { Box, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { BuildTheme } from "../../theme/BuildTheme";
import Footer from "./footer/Footer";

const BlankLayout = () => {
  const theme = BuildTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Outlet />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default BlankLayout;
