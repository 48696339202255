import { FunctionComponent } from "react";

interface IconLeaveProps {
    width?: number | string;
    height?: number | string;
    color?: string;
}
const IconLeave: FunctionComponent<IconLeaveProps> = ({
    width = 12,
    height = 12,
    color = "#000000",
}) => {
    return (
        <svg
            className="IconLeave"
            width={width}
            height={height}
            viewBox="0 0 9 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H6"
                stroke={color}
                strokeLinecap="round"
            />
            <path d="M4 6L7 6" stroke={color} strokeLinecap="round" />
            <path
                d="M6 4C6 4 8 5.5 8 6C8 6.5 6 8 6 8"
                stroke={color}
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconLeave;
