import { useCallback } from "react"
import Swal from "sweetalert2"

interface StateCode {
    code: number,
    message: string
}

const stateCodes = [
    {
        code: 400,
        message: 'Ha ocurrido un error al realizar la operación, reintentalo mas tarde.'
    },
    {
        code: 404,
        message: 'Servicio no encontrado.'
    },
    {
        code: 500,
        message: 'Ha ocurrido un error interno del servidor.'
    },
    {
        code: 401,
        message: 'La sesión actual ha expirado, inicia sesión nuevamente.!'
    }
]
const useMessages = () => {

    const getMessage = useCallback((code: number, customStateCodes: StateCode[] = []): string => {
        const customMessage = customStateCodes.find(s => s.code === code)
        if (customMessage) return customMessage.message
        const { message } = stateCodes.find(s => s.code === code) ?? {
            message: 'Ha ocurrido un error al reaizar la petición.'
        }
        return message
    }, [])

    const showError = useCallback((error?: any) => {
        Swal.fire(
            '¡Error!',
            error?.message ?? error?.msg ?? error ?? 'Ha ocurrido un error, reintentalo mas tarde.',
            "error"
        );
    }, [])

    return { getMessage, showError }

}

export default useMessages;