import { Box, Typography, IconButton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../../components/CustomModal";
import IconPensil from "../../../../components/icons/IconPensil";
import IconRelation from "../../../../components/icons/IconRelation";
import IconTrash from "../../../../components/icons/IconTrash";
import ConfirmDelete from "../../../../components/utilities/ConfirmDeleteContent";
import useProcess from "../../../../hooks/pdi/process/useProcess";
import infoProcessStyles from "./infoProcess.styles";

interface InfoProcessProps {
    id: string;
}
const InfoProcess = ({ id }: InfoProcessProps) => {
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [dataProcess, setDataProcess] = useState<any>({});
    const navigate = useNavigate();
    const { fetchDeleteProcess, fetchGetOneProcess } = useProcess();

    useEffect(() => {
        getSubProcess();
    }, [id]);

    const getSubProcess = async () => {
        const response = await fetchGetOneProcess(id);
        const data = response;
        setDataProcess(data);
    };
    return (
        <>
            {/**************
                CONTENEDOR
            ***************/}
            <Box sx={{...infoProcessStyles.container}}>
                {/**************
                    TITULO INFO
                ***************/}
                <Box className='aquii' sx={infoProcessStyles.contentTitle}>
                    <Box sx={infoProcessStyles.title}>
                        <Typography>Detalles del proceso</Typography>

                        {/**************************
                            BOTONES EDITAR/BORRAR
                        **************************/}
                        {/*****************
                            BOTON EDITAR
                        ******************/}
                        <Box>
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `/plan/process/update/${dataProcess._id}`
                                    )
                                }
                            >
                                <IconPensil />
                            </IconButton>
                        </Box>

                        {/*****************
                            BOTON BORRAR
                        ******************/}
                        <CustomModal
                            maxWidth={600}
                            maxHeight={350}
                            text={
                                <IconButton>
                                    <IconTrash />
                                </IconButton>
                            }
                            stateOpen={openModalDelete}
                        >
                            <ConfirmDelete
                                setOpen={setOpenModalDelete}
                                open={openModalDelete}
                                id={dataProcess._id}
                                fetchDelete={fetchDeleteProcess}
                                message="¿Estas seguro que quieres eliminar este proceso?"
                            />
                        </CustomModal>
                    </Box>

                    {/***************************
                        BOTON VER RELACIONES
                    ****************************/}
                    <Button
                        className="buttonCreate"
                        href="process/relations"
                        disableTouchRipple
                    >
                        <IconRelation />

                        <Typography>Ver relaciones</Typography>
                    </Button>
                </Box>

                {/*************
                    CONTENIDO
                ***************/}
                <Box sx={infoProcessStyles.content}>
                    {/************** 
                        CONTENT 1 
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Nombre del área</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataProcess?.idDepartament?.name ?? ""}
                        </Typography>
                    </Box>

                    {/*************** 
                        CONTENT 2 
                    ***************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Nombre del proceso</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataProcess?.name ?? ""}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 3
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Descripción</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataProcess?.description ?? ""}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 4
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Justificación.</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>
                            {dataProcess?.justification ?? ""}
                        </Typography>
                    </Box>

                    {/************** 
                        CONTENT 5
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        {/*********** 
                            TITULO
                        ***********/}
                        <Typography>Porcentaje de cumplimiento</Typography>

                        {/*********
                            INFO
                        *********/}
                        <Typography component={"span"}>00%</Typography>
                    </Box>

                    {/**************
                        CONTENT 6 - CONTENEDOR LISTA
                    **************/}
                    <Box sx={infoProcessStyles.data}>
                        <Box>
                            {/***************
                                INDICADORES
                            ****************/}
                            <Box>
                                <Typography>Indicadores</Typography>

                                {/**********
                                    LISTA
                                **********/}
                                <Box>
                                    {dataProcess?.link_processIndicator?.map((item: any, index: number) => (
                                        <Typography key={index}>{item.idIndicator[0].name}</Typography>
                                    ))}
                                </Box>
                            </Box>

                            {/***************
                                SUBPROCESOS
                            ****************/}
                            <Box className="subProcess">
                                <Typography>Subprocesos</Typography>

                                {/**********
                                    LISTA
                                **********/}
                                <Box>
                                    {dataProcess?.link_Subprocess?.map(
                                        (item: any, index: number) => (
                                            <Typography key={index}>
                                                {item.name}
                                            </Typography>
                                        )
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InfoProcess;
