import { FunctionComponent } from 'react'

interface IconWarningProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconWarning: FunctionComponent<IconWarningProps> = ({ width = 68, height = 68, color = '#7282B8' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 78 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6419 1.32355C35.1841 0.455827 36.9239 0 38.6935 0C40.4631 0 42.2029 0.455826 43.7452 1.32355C45.2874 2.19127 46.5802 3.44163 47.4988 4.95411L47.5101 4.97275L75.9816 52.4986C75.9917 52.5153 76.0015 52.5322 76.0113 52.5491C76.9101 54.1066 77.3855 55.8722 77.3904 57.6705C77.3952 59.4687 76.9293 61.2369 76.0389 62.7992C75.1486 64.3616 73.8648 65.6636 72.3152 66.576C70.7655 67.4883 69.0041 67.9792 67.206 67.9998L67.1651 68H10.2253L10.1844 67.9998C8.3863 67.9792 6.62487 67.4884 5.07527 66.576C3.52566 65.6636 2.24185 64.3616 1.35148 62.7992C0.461111 61.2369 -0.00481218 59.4687 3.74754e-05 57.6705C0.00488714 55.8722 0.480341 54.1066 1.37913 52.5491C1.38885 52.5322 1.39871 52.5154 1.4087 52.4988L29.8769 4.97291L29.8882 4.95411C30.8069 3.44163 32.0996 2.19127 33.6419 1.32355ZM38.6935 7.15789C38.1534 7.15789 37.6224 7.29702 37.1517 7.56185C36.6836 7.82521 36.2909 8.20405 36.0108 8.6622L7.56705 56.1472C7.30035 56.6176 7.15936 57.1488 7.15791 57.6898C7.15643 58.2386 7.29863 58.7783 7.57038 59.2551C7.84213 59.7319 8.23396 60.1293 8.7069 60.4078C9.17511 60.6835 9.70667 60.833 10.2497 60.8421H67.1407C67.6838 60.833 68.2153 60.6835 68.6835 60.4078C69.1565 60.1293 69.5483 59.7319 69.82 59.2551C70.0918 58.7783 70.234 58.2386 70.2325 57.6898C70.2311 57.1489 70.0901 56.6177 69.8235 56.1474L41.381 8.66993C41.3794 8.66736 41.3778 8.66479 41.3763 8.66222C41.0962 8.20406 40.7034 7.82521 40.2353 7.56185C39.7646 7.29702 39.2336 7.15789 38.6935 7.15789ZM38.8963 20.2795C40.8729 20.2795 42.4753 21.8819 42.4753 23.8585V37.3793C42.4753 39.3559 40.8729 40.9583 38.8963 40.9583C36.9197 40.9583 35.3174 39.3559 35.3174 37.3793V23.8585C35.3174 21.8819 36.9197 20.2795 38.8963 20.2795ZM35.3174 50.9002C35.3174 48.9236 36.9197 47.3212 38.8963 47.3212H38.9301C40.9067 47.3212 42.5091 48.9236 42.5091 50.9002C42.5091 52.8768 40.9067 54.4791 38.9301 54.4791H38.8963C36.9197 54.4791 35.3174 52.8768 35.3174 50.9002Z"
        fill={color}
      />
    </svg>
  )
}

export default IconWarning
