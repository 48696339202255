import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys = "container"
    | "title"
    | "body"
    | "contentText"
    | "subtitle"
    | "text"
    | "header"
    | "containerButtonheader"
    | "buttonHeader"

const modalDetailItemPdaStyles: StyleComponent<Keys> = {
    container: {
        // margin: "0px 120px 120px 120px",
        // mx : '50px'
        mx : '65px'
    },

    // TITULO
    title: {
        color: "#1F398F",
        fontSize: "25px",
        fontWeight: "600",
        lineHeight: "38px",
        fontStyle: "normal",
        textAlign: {
            xs: 'center',
            md: 'left'
        }
    },
    // ENCABEZADO- TITULO Y BOTON
    header: {
        display: {
            xs: 'block',
            md: 'flex'
        },
    },
    containerButtonheader: {
        ml: { xs: 0, md: 2 },
        mt: { xs: 2, md: 0 },
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    buttonHeader: {
        fontSize: "12px",
        fontWeight: '600',
        textTransform: "capitalize",
        lineHeight: "30px",
        fontStyle: "normal",
        borderRadius: "20px",
        height: "25.69px",
        width: {
            xs: '100%',
            md: "99.25px"
        },

        marginRight: "10.51px",
        background: "#1F398F1A",
        color: "#1F398F",
        ":hover": {
            background: "#1F398F1A",
        },
    },
    // CONTENIDO 
    body: {
        backgroundRepeat: "no-repeat",
        mt: 5,
        height: "100%",

        // SCROLL
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: "12px",
            size: "10px",
            "&-track": {
                backgroundColor: "transparent",
                borderRadius: "10px",
                border: "5px solid transparent",
            },
            "&-thumb": {
                backgroundColor: "transparent",
                borderRadius: "20px",
                width: "10px",
            },
        },
    },
    contentText: {
        marginBottom: "30px",
    },
    subtitle: {
        color: "#1F398F",
        fontSize: "15px",
        fontWeight: "600",
        lineHeight: "22px",
        marginBottom: "10px",
    },
    text: {
        color: "#000000 !important",
        fontWeight: "400 !important",
        fontSize: "15px",
    }
}

export default modalDetailItemPdaStyles