import { FunctionComponent, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'

// IMPORTADOS
import { useNavigate } from 'react-router-dom'
import IconAdd from '../../../components/icons/IconAdd'
import formStyles from '../../../components/forms/form.styles'
import FilterPdi from '../../../components/inputSelect/FilterPdi'
import TablePdas from '../../../components/tables/pda/tablePdas/TablePdas'
import { Pda } from '../../../interfaces/pda/pda'
import usePda from '../../../hooks/pda/usePda'
import useDinamicPagination from '../../../hooks/useDinamicPagination'

const itemsPerPage = 9

const Pdas: FunctionComponent = () => {
  const { pdaList, getPdas } = usePda()

  const { countPages, currentPage, handleChangePage, limit, from } = useDinamicPagination(
    itemsPerPage,
    pdaList?.total ?? 0
  )

  const navigate = useNavigate()

  const disable = ({ _id }: Pda) => {}

  const update = ({ _id }: Pda) => {
    navigate(`./update/${_id}`)
  }

  const handleAddButton = () => {
    navigate('./create')
  }

  useEffect(() => {
    getPdas({limit, from})
  }, [limit, from])

  return (
    <>
      {/***************
        CONTENEDOR PDA
      ***************/}
      <Box
        sx={{
          backgroundColor: '#ffffff',
          // maxWidth : '1530px',
          // m : 'auto'
          minHeight: '52.7vh',
        }}
      >
        {/********
          TITULO
        *********/}
        <Box sx={formStyles.buttonTitle}>
          {/*******************
            TITULO PROYECTO
          ********************/}
          <Typography sx={{ fontSize: '50px' }}>
            Plan de Acción
            {/* {filterOption
                ? `Retos de ${filterOption.title}`
                : "Todos los retos"} */}
          </Typography>

          <Box>
            {/***************************
              BOTON FILTRAR POR SUCURSAL
            ****************************/}
            <FilterPdi
              // getOptionLabel={(option) => option.title}
              // id="filter-challenge-by-project"
              // isOptionEqualToValue={(option, value) =>
              //     option._id === value._id
              // }
              labelInput="Sucursal"
              noOptionsText="Sin resultados"
              options={[]}
              // onChange={(e, value) => changeFilterOption(value)}
              placeholder="Buscar proyecto..."
              // value={filterOption}
              disabled
              style={{ marginRight: '20px' }}
            />

            {/**********************
              BOTON FILTRAR POR PDA
            ***********************/}
            <FilterPdi
              // getOptionLabel={(option) => option.title}
              // id="filter-challenge-by-project"
              // isOptionEqualToValue={(option, value) =>
              //     option._id === value._id
              // }
              labelInput="PDI"
              noOptionsText="Sin resultados"
              options={[]}
              // onChange={(e, value) => changeFilterOption(value)}
              placeholder="Buscar proyecto..."
              // value={filterOption}
              disabled
            />

            {/*************
              BOTON MÁS
            **************/}
            <Button disableTouchRipple onClick={handleAddButton}>
              <IconAdd />
              <Typography>Crear nuevo PA</Typography>
            </Button>
          </Box>
        </Box>

        {/**********
          TABLA PDI
        ***********/}
        {pdaList && (
          <TablePdas
            pdas={pdaList.items}
            onUpdate={update}
            onDisable={disable}
            paginationProps={{
              count: countPages,
              onChange: (a, page) => handleChangePage(page),
              page : currentPage,
            }}
          />
        )}
      </Box>
    </>
  )
}

export default Pdas
