import { FunctionComponent } from 'react'
import { ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import { Outlet } from 'react-router-dom'
import Header from './headers/Header'
import Footer from './footer/Footer'
import { BuildTheme } from '../../theme/BuildTheme'
import { AppDialog } from '../AppDialog'
import SubHeader from './headers/SubHeader'

const FullLayout: FunctionComponent = () => {
  const theme = BuildTheme()

  return (
    <ThemeProvider theme={theme}>
       <Box>
        <Header />
        <SubHeader/>
        <Outlet />
        <Footer />
      </Box>
      <AppDialog />
    </ThemeProvider>
  )
}

export default FullLayout
