import * as yup from 'yup'
import { UserCreateFields, UserUpdateFields } from '../interfaces/user'

export const userCreateSchema: yup.SchemaOf<UserCreateFields> = yup.object({
  userName: yup.string().required('Este campo es obligatorio'),
  password: yup.string().min(6, 'La contraseña debe tener minimo 6 caracteres').required('Este campo es obligatorio'),
  profile: yup.object({
    name: yup.string().required('Este campo es obligatorio'),
    lastName: yup.string().required('Este campo es obligatorio'),
    phone: yup.number().typeError('Este campo requiere un valor numerico').required('Este campo es obligatorio'),
    email: yup.string().email('El campo debe ser un email valido').required('Este campo es obligatorio'),
    idPosition: yup.string().required('Este campo es obligatorio'),
    idDepartament: yup.string().required('Este campo es obligatorio'),
    idBossPosition: yup.string().required('Este campo es obligatorio'),
    idBranchOffice: yup.string().required('Este campo es obligatorio'),
    idRol: yup.string().required('Este campo es obligatorio'),
  }),
})

export const userUpdateSchema: yup.SchemaOf<UserUpdateFields> = yup.object({
  userName: yup.string().required('Este campo es obligatorio'),
  profile: yup.object({
    name: yup.string().required('Este campo es obligatorio'),
    lastName: yup.string().required('Este campo es obligatorio'),
    phone: yup.number().typeError('Este campo requiere un valor numerico').required('Este campo es obligatorio'),
    email: yup.string().email('El campo debe ser un email valido').required('Este campo es obligatorio'),
    idPosition: yup.string().required('Este campo es obligatorio'),
    idDepartament: yup.string().required('Este campo es obligatorio'),
    idBossPosition: yup.string().required('Este campo es obligatorio'),
    idBranchOffice: yup.string().required('Este campo es obligatorio'),
    idRol: yup.string().required('Este campo es obligatorio'),
  }),
})
