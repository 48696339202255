import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material'

// IMPORTADOS
import verifyStyles from '../Verify.styles'
import CustomTitle from '../../../../components/CustomTitle'
import ButtonSearch from '../../../../components/buttons/ButtonSearch'
import IconDirection from '../../../../components/icons/IconDirection'
import formStyles from '../../../../components/forms/form.styles'
import paginationStyles from '../../../../components/Pagination.styles'
import areaNameStyles from '../../../../components/inputSelect/AreaName.styles'
import CustomModal from '../../../../components/CustomModal'
import InfoVerifyEventAssigned from './infoVerifyEventAssigned/InfoVerifyEventAssigned'
import useEvents from '../../../../hooks/verify/events/useEvents'
import { getWeekDayName } from '../../../../services/ParseDate'
import IconAdd from '../../../../components/icons/IconAdd'
import { useNavigate } from 'react-router-dom'

const select = [
  {
    id: 1,
    name: 'Activo',
  },
  {
    id: 2,
    name: 'Inactivo',
  },
  {
    id: 3,
    name: 'Todos',
  },
]

// TEMA Y ESTILOS
const themeCustomSelect = (): Theme => {
  // eslint-disable-next-line
  const theme: any = useTheme()

  return createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            '>.MuiPaper-elevation': {
              backgroundColor: '#ffffff',
              maxHeight: '270px',
              minHeight: '86px',
              borderRadius: '15px',
              overflowY: 'hidden',

              // UL
              '>ul': {
                backgroundColor: 'inherit',
                maxHeight: '214px',
                // maxWidth: "570px",
                margin: '20px 20px',

                // SCROLL
                overflowY: select.length > 6 ? 'scroll' : 'hidden',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  size: '10px',
                  '&-track': {
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '10px',
                    border: '5px solid #ffffff',
                  },
                  '&-thumb': {
                    borderRadius: '20px',
                    backgroundColor: theme.palette.primary.main,
                    width: '10px',
                  },
                },

                // LI
                '>li': {
                  color: '#000000',
                  display: 'flex',
                  justifyContent: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  lineHeight: '24px',
                  backgroundColor: 'inherit',
                  ':hover': {
                    color: '#1F398F',
                    fontWeight: '600',
                    backgroundColor: 'inherit',
                  },
                },

                '>.Mui-selected': {
                  backgroundColor: '#F1F3F8',
                  color: '#1F398F',
                  fontWeight: '600',
                  borderRadius: '200px',
                  ':hover': {
                    backgroundColor: '#F1F3F8 !important',
                  },
                },
              },
            },
          },
        },
      },
    },
  })
}

const VerifyEventAssigned = () => {
  const { getEvents } = useEvents()
  const [infoCardsFull, setInfoCardsFull] = useState<any>([])
  const [infoCards, setInfoCards] = useState<any>([])
  const [area, setArea] = useState<any>('')
  const theme = useTheme()
  const primary = theme.palette.primary.main
  const navigate = useNavigate()

  const handleChange = (event: { target: { value: string; name: string } }) => {
    setArea(event.target.value)
  }

  // TEMA
  const myTheme = themeCustomSelect()

  const listEvents = async () => {
    const response: any = await getEvents()
    const data =
      response.items?.sort((a: any, b: any) => {
        const existA = a.is_active
        const existB = b.is_active
        return Number(existB) - Number(existA)
      }) ?? []
    setInfoCards(data)
    setInfoCardsFull(data)
  }

  const filterEvents = () => {
    let filterData = []
    switch (area) {
      case 1:
        filterData = infoCardsFull.filter((item: any) => item.is_active === true)
        break
      case 2:
        filterData = infoCardsFull.filter((item: any) => item.is_active === false)
        break
      case 3:
        filterData = infoCardsFull
        break

      default:
        break
    }
    setInfoCards(filterData)
  }

  useEffect(() => {
    listEvents()
  }, [])

  useEffect(() => {
    filterEvents()
  }, [area])

  return (
    // CONTENEDOR EVENTOS ASIGNADOS
    <Box sx={verifyStyles.container}>
      <CustomTitle title="Eventos asignados" subTitle="Ver eventos">
        <>
          {/*****************/}
          {/* BOTON FILTRAR */}
          {/*****************/}
          <ThemeProvider theme={myTheme}>
            {/***********************/}
            {/* BOTON CREAR EVENTOS */}
            {/***********************/}
            <Button disableTouchRipple style={{ color: '#1F398F' }} onClick={() => navigate('/verify/event')}>
              <IconAdd />
              <Typography>Crear eventos</Typography>
            </Button>
            <FormControl sx={areaNameStyles.inputSelect} variant="standard">
              {/*********/}
              {/* LABEL */}
              {/*********/}
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              {/**********/}
              {/* SELECT */}
              {/**********/}
              <Select value={area} name="Estado" onChange={handleChange}>
                {select.map(({ id, name }, index) => (
                  <MenuItem key={id} disableTouchRipple value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>

          {/****************/}
          {/* BOTON BUSCAR */}
          {/****************/}
          <ButtonSearch />
        </>
      </CustomTitle>

      {/*************/}
      {/* CONTENIDO */}
      {/*************/}
      <Box sx={verifyStyles.contentGridPagination}>
        <Box sx={verifyStyles.boxGrid}>
          {/*********************/}
          {/* CONTENEDOR GRILLA */}
          {/*********************/}
          <Grid container spacing={2}>
            {infoCards.map((item: any) => (
              // GRILLA
              <Grid item xs={4}>
                <Box
                  className="contentCardGrid"
                  sx={{
                    // FRANJA CARD
                    '>.franjaCard': {
                      backgroundColor: item.is_active ? '#1F398F' : '#FCCC64',
                    },

                    // COLOR ACTIVO E INACTIVO
                    '>div': {
                      '>.fechaCard>.day, >.fechaCard>.date, >.contentCard>.contentCardInfo>h1, >.contentCard>.contentCardInfo>p, >.contentCard>.contentCardInfo>em,':
                        {
                          color: item.is_active ? '#000000' : '#BABABA',
                        },
                    },
                  }}
                >
                  {/****************/}
                  {/* FRANJA CARTA */}
                  {/****************/}
                  <Box className="franjaCard"> </Box>

                  {/********************/}
                  {/* CONTENEDOR CARTA */}
                  {/********************/}
                  <Box className="contentCard">
                    {/*********/}
                    {/* FECHA */}
                    {/*********/}
                    <Box className="fechaCard">
                      <Box className="day">{item.startDate.split('T')[0].split('-')[2]}</Box>
                      <Box className="date">{getWeekDayName(item.startDate.split('T')[0])?.substring(0, 2)}</Box>
                    </Box>

                    {/*******************/}
                    {/* CONTENIDO CARTA */}
                    {/*******************/}
                    <Grid container className="contentCard">
                      {/********/}
                      {/* INFO */}
                      {/********/}
                      <Grid item xs={8} className="contentCardInfo">
                        <Typography>
                          {item.startDate.split('T')[0]} - {item.finalDate.split('T')[0]}
                        </Typography>

                        <Typography component={'h1'}>
                          {item.name?.substring(0, 20)} {item.name?.length > 20 ? '...' : ''}
                        </Typography>

                        <Typography>
                          {item.idUser?.userName?.substring(0, 30)}
                          {item.idUser?.userName?.length > 30 ? '...' : ''}
                        </Typography>

                        <Typography component={'em'}>
                          {item.idUser?.idProfile?.idDepartament?.name?.substring(0, 20)}{' '}
                          {item.idUser?.idProfile?.idDepartament?.name?.length > 20 ? '...' : ''}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: 'end',
                            fontWeight: 'bold !important',
                            color: `${primary} !important`,
                          }}
                        >
                          {item.idStatus?.code === 0 ? 'Sin Cumplir' : item.idStatus?.code === 1 && 'Cumplido'}
                        </Typography>
                      </Grid>

                      {/**************/}
                      {/* ICONO INFO */}
                      {/**************/}
                      <Grid item xs={4} className="contentCardIcon">
                        <Button
                          disableTouchRipple
                          className="btnInfo"
                          sx={{ display: item.is_active ? 'inline-flex' : 'none' }}
                        >
                          <CustomModal text={<IconDirection />} maxHeight="869px">
                            <InfoVerifyEventAssigned dataInfo={item} />
                          </CustomModal>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <br />

        {/**************/}
        {/* PAGINACION */}
        {/**************/}
        <Box sx={formStyles.paginationCenter}>
          <Pagination sx={{ ...paginationStyles.paginationNumbers, marginLeft: 'inherit !important' }} />
        </Box>
      </Box>
    </Box>
  )
}

export default VerifyEventAssigned
