import React, { forwardRef, FunctionComponent, useState } from "react";
import {
    FormControl,
    Select,
    ThemeProvider,
    createTheme,
    MenuItem,
    SelectProps,
    Box,
    Pagination,
} from "@mui/material";

// IMPORTADOS
import formPoliticsStyles from "../forms/pdi/formPolitics/formPolitics.styles";
import { nombreArea } from "../data/dataAPI";
import formStyles from "../forms/form.styles";
import ButtonSearch from "../buttons/ButtonSearch";
import taskAssignmentTabsStyles from "../../pages/private/plan/taskAssignment/TaskAssignmentTabs.styles";
import usePagination from "../../hooks/usePagination";

type SelectPaginationProps = SelectProps & {
    items: any[];
    propValue: string;
    propText: string;
};

// TEMA Y ESTILOS
const theme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                root: {
                    ">.MuiPaper-elevation": {
                        backgroundColor: "#ffffff",
                        // maxHeight: "270px",
                        maxHeight: "484px",
                        // height: "484px",
                        minHeight: "86px",
                        borderRadius: "15px",
                        // overflowY: "hidden",

                        // UL
                        ">ul": {
                            backgroundColor: "inherit",
                            height: "-webkit-fill-available",
                            margin: "20px 20px",
                            // maxHeight: "214px",
                            // maxWidth: "570px",

                            // LI
                            ">li": {
                                color: "#000000",
                                display: "flex",
                                // justifyContent: "center",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                lineHeight: "24px",
                                backgroundColor: "inherit",
                                ":hover": {
                                    color: "#1F398F",
                                    fontWeight: "600",
                                    backgroundColor: "inherit",
                                },
                            },

                            ">.Mui-selected": {
                                backgroundColor: "#F1F3F8",
                                color: "#1F398F",
                                fontWeight: "600",
                                borderRadius: "200px",
                                ":hover": {
                                    backgroundColor: "#F1F3F8 !important",
                                },
                            },
                        },
                    },
                },
            },
        },
    },
});

const itemsPerPage = 2

const SelectPagination: FunctionComponent<SelectPaginationProps> = forwardRef(
    ({ items, propValue, propText, defaultValue = "", ...props }, ref) => {
       
        const [searchItems, setSearchItems] = useState<any[]>(items)
        
        const {handleChangePage, countPage, currentData, currentPage} = usePagination(searchItems, itemsPerPage)

        return (
            <ThemeProvider theme={theme}>
                <FormControl
                    error={true}
                    sx={formStyles.inputSelect}
                    variant="standard"
                >
                    <Select
                        ref={ref}
                        {...props}
                        defaultValue={defaultValue}
                        displayEmpty
                    >
                        {/*****************
                            BOTON BUSCAR
                        *****************/}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <ButtonSearch fieldSearch={[propText]} data={items} setData={setSearchItems} />
                        </Box>

                        {/**********
                            LISTA
                        **********/}
                        <MenuItem value="" disabled disableTouchRipple>
                            Selecciona una opción
                        </MenuItem>

                        {currentData().map((item, index) => (
                            <MenuItem
                                disableTouchRipple
                                key={`custom-select${index}`}
                                value={item[propValue]}
                            >
                                {item[propText]}
                            </MenuItem>
                        ))}

                        {/***************
                            PAGINACION
                        ***************/}
                        <Box sx={formStyles.containerPaginationArrow}>
                            <Pagination page={currentPage} count={countPage} onChange={(e, _page) => handleChangePage(_page)} />
                        </Box>
                    </Select>
                </FormControl>
            </ThemeProvider>
        );
    }
);

export default SelectPagination;
