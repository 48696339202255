import React, { useEffect, useState } from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, InputAdornment } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

interface ItemIndicatorsProps {
  item: any
  checked: any[]
  setChecked: Function
  type: string
  id: string
}

const ItemIndicators = ({ item, checked, setChecked, type, id }: ItemIndicatorsProps) => {
  const [value, setValue] = useState('')
  const handleToggle = (check: any, idOption: any) => {
    if (check === true) {
      setChecked([
        ...checked,
        {
          [type]: id,
          idIndicator: idOption,
        },
      ])
    } else {
      const checks: any[] = checked.filter(item => item.idIndicator !== idOption)
      setChecked(checks)
    }
  }

  const handleInputChange = (e: any) => {
    const regExp = /^\d{0,3}$/
    if (regExp.test(e.target.value)) {
      const checks = [...checked]
      const index = checks.findIndex((element: any) => element.idIndicator === item._id)
      checks[index].weigthIndi = Number(e.target.value)
      setChecked(checks)
      getValue()
    }
  }

  const getValue = () => {
    const index = checked.findIndex((element: any) => element.idIndicator === item._id)
    setValue(checked[index]?.weigthIndi)
  }

  useEffect(() => {
    getValue()
  }, [id, checked, item])

  return (
    <ListItem>
      <ListItemButton disableTouchRipple disableRipple dense>
        {/*************
            CHECKBOX
        **************/}
        <ListItemIcon>
          <Checkbox
            disableRipple
            edge="start"
            onChange={e => handleToggle(e.target.checked, item._id)}
            checked={checked.some(element => element.idIndicator === item._id)}
          />
        </ListItemIcon>

        {/*************
            NOMBRE
        **************/}
        <ListItemText
          onClick={() => handleToggle(!checked.some(element => element.idIndicator === item._id), item._id)}
        >
          {item.name}
        </ListItemText>
        {checked.some(element => element.idIndicator === item._id) && (
          <>
            <OutlinedInput
              id={item._id}
              onChange={handleInputChange}
              value={value}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              inputProps={{
                maxlength: 3,
              }}
            />
          </>
        )}
      </ListItemButton>
    </ListItem>
  )
}

export default ItemIndicators
