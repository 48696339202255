import { useTheme } from '@mui/styles'
import { FunctionComponent } from 'react'

interface IconConditionProps {
  width?: number | string
  height?: number | string
  color?: string
}

const IconCondition: FunctionComponent<IconConditionProps> = ({ width = 147, height = 150, color }) => {
  const theme: any = useTheme()
  const secondary = theme.palette.secondary.main
  return (
    <svg width={width} height={height} viewBox="0 0 147 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.3549 46.6555C76.0073 46.6555 78.5511 45.6018 80.4267 43.7262C82.3023 41.8507 83.356 39.3069 83.356 36.6544C83.356 34.0019 82.3023 31.4581 80.4267 29.5826C78.5511 27.707 76.0073 26.6533 73.3549 26.6533C70.7024 26.6533 68.1586 27.707 66.283 29.5826C64.4075 31.4581 63.3538 34.0019 63.3538 36.6544C63.3538 39.3069 64.4075 41.8507 66.283 43.7262C68.1586 45.6018 70.7024 46.6555 73.3549 46.6555V46.6555ZM79.2889 1.95064C77.5698 0.683567 75.4904 0 73.3549 0C71.2194 0 69.1399 0.683567 67.4209 1.95064L4.0807 48.6557C-3.68014 54.383 0.36697 66.7043 10.0147 66.7043H13.3484V114.99C9.3984 116.635 6.02415 119.414 3.65105 122.975C1.27796 126.535 0.0122821 130.719 0.0135987 134.998V144.999C0.0135987 147.76 2.25384 150 5.01414 150H141.696C143.022 150 144.294 149.473 145.232 148.535C146.169 147.598 146.696 146.326 146.696 144.999V134.998C146.696 130.72 145.43 126.538 143.057 122.979C140.684 119.419 137.31 116.642 133.361 114.996V66.7043H136.688C146.343 66.7043 150.39 54.383 142.622 48.6557L79.2889 1.95064ZM23.3495 113.329V66.7043H40.0179V113.329H23.3495ZM123.36 66.7043V113.329H106.692V66.7043H123.36ZM96.6907 66.7043V113.329H78.3554V66.7043H96.6907ZM68.3543 66.7043V113.329H50.019V66.7043H68.3543ZM10.0147 56.7032L73.3549 9.99818L136.688 56.7032H10.0147ZM10.0147 134.998C10.0147 128.558 15.2419 123.33 21.6826 123.33H125.027C131.468 123.33 136.695 128.558 136.695 134.998V139.999H10.0147V134.998Z"
        fill={secondary ?? color}
      />
    </svg>
  )
}

export default IconCondition
