import { StyleComponent } from "../interfaces/styleComponent";

type Keys = "paginationNumbers" | "paginationArrows";

const paginationStyles: StyleComponent<Keys> = {
    // PAGINACION CON NUMEROS
    paginationNumbers: {
        width: "fit-content",
        height: "40px",
        alignSelf: "end",
        // marginTop: "-40px",
        marginLeft: "auto",

        ">ul": {
            justifyContent: "space-between",
            flexWrap: "inherit",
            ">li": {
                ">.Mui-disabled": {
                    visibility: "hidden",
                },

                // BOTON DE NUMEROS
                ">.MuiPaginationItem-page": {
                    backgroundColor: "inherit",
                    color: "#000000",

                    ":hover": {
                        backgroundColor: "inherit",
                    },
                },

                // BOTON DE NUMEROS
                ">.Mui-selected": {
                    color: "#7282B8",
                    fontWeight: "700",
                },

                // BOTON DE FLECHAS
                ">.MuiPaginationItem-previousNext": {
                    backgroundColor: "transparent",
                    height: "100%",
                    ">svg": {
                        fontSize: "2.5rem !important",
                        ">path": {
                            color: "#7282B8",
                        },
                    },
                },
            },
        },
    },

    // PAGINACION SOLO FLECHAS
    paginationArrows: {
        width: "fit-content",
        height: "40px",
        alignSelf: "end",
        // marginTop: "-40px",
        marginLeft: "auto",

        ">ul": {
            justifyContent: "space-between",
            flexWrap: "inherit",
            ">li": {
                ">.Mui-disabled": {
                    visibility: "hidden",
                },

                // BOTON DE NUMEROS
                ">.MuiPaginationItem-page": {
                    display: "none",
                },

                // BOTON DE FLECHAS
                ">.MuiPaginationItem-previousNext": {
                    backgroundColor: "transparent",
                    height: "100%",
                    ">svg": {
                        fontSize: "2.5rem !important",
                        ">path": {
                            color: "#7282B8",
                        },
                    },
                },
            },
        },
    },
};

export default paginationStyles;
