import { FunctionComponent } from "react";
import { Box, Typography, Button, Fade } from "@mui/material";

// IMPORTADOS
import formPoliticsStyles from "../../../forms/pdi/formPolitics/formPolitics.styles";
import TablePolicies from "../../../tables/pdi/tablePolicies/TablePolicies";
import customTitleButtonAddStyles from "../../../customTitleButtonAdd.styles";
import IconAdd from "../../../icons/IconAdd";
import { PdiModel } from "../../../../interfaces/pdi/pdi";
import {
    FormPoliticsValues,
    Politics,
    UpdatePolitics,
    NewPolitics,
    ChangeActivePolitics,
} from "../../../../interfaces/pdi/politics";
import FormPolitics from "../../../forms/pdi/formPolitics/FormPolitics";
import usePolitics from "../../../../hooks/pdi/usePolitics";
import useViewControlTabPanel from "../../../../hooks/pdi/useViewControlTabPanelPdi";
import formStyles from "../../../forms/form.styles";

interface TabPanelPoliciesProps {
    pdi: PdiModel;
    policies: Politics[];
    onUpdate: (_policies: Politics[]) => void;
    onCreate: (_id: string) => void;
    onChangeTitle: (tabTitle: string | null) => void;
}

const TabPanelPolicies: FunctionComponent<TabPanelPoliciesProps> = ({
    onChangeTitle,
    pdi,
    policies,
    onUpdate,
    onCreate,
}) => {
    const {
        fetchCreatePolitics,
        fetchUpdatePolitics,
        fetchDisablePolitics,
        ViewAlert,
    } = usePolitics();

    const {
        viewMode,
        showViewData,
        showViewCreate,
        showViewUpdate,
        updateItem,
    } = useViewControlTabPanel<Politics>({
        items: policies,
        onChangeTitle,
        titleOptions: {
            none: null,
            create: "Crear política",
            update: "Actualizar política",
            data: "Políticas",
        },
    });

    const getFormDefaultValues = ():
        | Partial<FormPoliticsValues>
        | undefined => {
        if (!updateItem) return undefined;
        const { __v, _id, createdAt, updatedAt, is_active, ...updateValues } =
            updateItem;
        return updateValues;
    };

    const create = (
        { state, ...other }: FormPoliticsValues,
        reset: () => void
    ) => {
        const newPolitics: NewPolitics = other;
        fetchCreatePolitics(newPolitics, (_id) => {
            reset();
            onCreate(_id);
        });
    };

    const update = (formValues: FormPoliticsValues, reset: () => void) => {
        if (!updateItem) {
            return;
        }
        const { _id } = updateItem;
        const updatePolitics: UpdatePolitics = { _id, ...formValues };

        fetchUpdatePolitics(updatePolitics, (_updateValues) => {
            reset();
            const _updatePolicies = policies.map((_politics) => {
                if (_politics._id === _updateValues._id) {
                    return { ..._politics, ..._updateValues };
                }
                return _politics;
            });
            onUpdate(_updatePolicies);
            showViewData();
        });
    };

    const disable = ({ _id }: Politics) => {
        const disabledPolitics: ChangeActivePolitics = {
            _id,
            is_active: false,
        };
        fetchDisablePolitics(disabledPolitics, () => {
            const _policies = policies.filter((p) => p._id !== _id);
            onUpdate(_policies);
        });
    };

    const onSubmtitForm = (
        formValues: FormPoliticsValues,
        reset: () => void
    ) => {
        if (updateItem) return update(formValues, reset);
        create(formValues, reset);
    };

    return (
        <>
            <ViewAlert />
            {/*****************************
                CONTENEDOR DEL FORMULARIO
            ******************************/}
            <Fade in={viewMode === "update" || viewMode === "create"}>
                <Box
                    sx={{
                        display:
                            viewMode === "data" || !viewMode ? "none" : "block",
                    }}
                >
                    <FormPolitics
                        pdi={pdi}
                        viewMode={
                            viewMode === "data" || !viewMode
                                ? undefined
                                : viewMode
                        }
                        defaultValues={getFormDefaultValues()}
                        onSubmit={onSubmtitForm}
                        onCancel={showViewData}
                    />
                </Box>
            </Fade>

            {/*****************************
                CONTENEDOR DE TABLA POLITICAS
            ******************************/}
            <Fade in={viewMode === "data"}>
                <Box sx={{ display: viewMode === "data" ? "block" : "none" }}>
                    <Box sx={formStyles.container}>
                        {/****************
                            TITULO
                        *****************/}
                        <Box sx={formStyles.buttonTitle}>
                            {/***************
                                TITULO PDI
                            ****************/}
                            <Typography sx={{ fontSize: "25px" }}>
                                Políticas del Plan de Desarrollo
                            </Typography>

                            {/*************
                                BOTON MÁS
                            **************/}
                            <Button
                                sx={customTitleButtonAddStyles.button}
                                onClick={showViewCreate}
                                type="submit"
                                disableTouchRipple
                            >
                                <IconAdd />

                                <Typography>Crear nueva política</Typography>
                            </Button>
                        </Box>

                        <TablePolicies
                            onUpdate={showViewUpdate}
                            onDelete={disable}
                            policies={policies}
                            pdi={pdi}
                        />
                    </Box>
                </Box>
            </Fade>
        </>
    );
};

export default TabPanelPolicies;
