import { useState, useCallback } from "react";
import GenericAlert, { GenericAlertProps } from "../../components/Alerts/GenericAlert/GenericAlert";
import { ChangeActiveChallenge, NewChallenge, UpdateChallenge } from "../../interfaces/pdi/challenge";
import useFetch from "../useFetch";

interface FetchChallengeProps {
    idPdi: string
    limit?: number
    from?: number
    active?: boolean
}

const useChallenge = () => {

    const { fetchList, fetchCreate, fetchUpdate, fetchDisable } = useFetch()

    const [alertData, setAlerData] = useState<GenericAlertProps>({
        show: false,
    })

    const ViewAlert = () => (
        <GenericAlert
            {...alertData}
            onCloseModal={() => setAlerData({ show: false })}
            onCloseButton={() => setAlerData({ show: false })}
        />
    )

    const fetchGetChallenge = useCallback(async ({ idPdi, limit = 0, from = 0, active = true }: FetchChallengeProps) => {
        return fetchList<any>({
            action: 'getChallenge',
            params: [
                ['idPdi', `${idPdi}`],
                ['limit', `${limit}`],
                ['active', `${active}`],
                ['from', `${from}`]
            ]
        })
            .then(res => {
                return res
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchList])

    const fetchCreateChallenge = useCallback((body: NewChallenge, done?: (_id: string) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchCreate({ action: 'createChallenge', body })
            .then(_id => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El reto se ha creado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_id),
                })
            })
            .catch((error) => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchCreate])

    const fetchUpdateChallenge = useCallback((body: UpdateChallenge, done?: (_updateValues: UpdateChallenge) => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchUpdate<UpdateChallenge>({ action: 'updateChallenge', body })
            .then(_updateValues => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: 'El reto se ha actualizado exitosamente!.',
                    icon: 'success',
                    showCloseButton: true,
                    didClose: () => done && done(_updateValues),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchUpdate])

    const fetchDisableChallenge = useCallback((body: ChangeActiveChallenge, done?: () => void) => {
        setAlerData({ show: true, icon: 'loadingAplanner', showCloseButton: false, title: 'Cargando...', text: 'Espera un momento, se encuentra cargando.' })
        fetchDisable({ action: 'disableChallenge', body })
            .then(() => {
                setAlerData({
                    show: true,
                    title: '¡Buen trabajo!',
                    text: `El reto ha sido ${body.is_active ? 'habilitado' : 'eliminado exitosamente'}!`,
                    icon: 'success',
                    didClose: () => done && done(),
                })
            })
            .catch(error => {
                setAlerData({
                    show: true,
                    title: 'Ooops!',
                    text: error?.message,
                    icon: 'error',
                })
            })
    }, [fetchDisable])

    return {
        fetchGetChallenge,
        fetchCreateChallenge,
        fetchUpdateChallenge,
        fetchDisableChallenge,
        ViewAlert
    }
}

export default useChallenge;