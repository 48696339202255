import { StyleComponent } from "../../../interfaces/styleComponent";

type Keys = "title" | "form" | "text" | "contentButton";

const formChangePasswordStyles: StyleComponent<Keys> = {
    // TITULO
    title: {
        maxWidth: "372px",
        margin: "auto",
        paddingTop: "152px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 35,
        lineHeight: "41px",
        color: "#1F398F",
    },

    // FORMULARIO
    form: {
        paddingLeft: "20px",
        paddingRight: "20px",
        maxWidth: 358,
        margin: "auto",
        ">div": {
            marginLeft: 44.2,
            marginTop: 30,
            margin: "30px auto auto",
            display: "flex",
            flexDirection: "row",
            ">div>div:after": {
                display: "none",
            },
        },
    },

    // TEXTO
    text: {
        maxWidth: "451px",
        margin: "39px auto 30px",
        fontSize: "10px",
        lineHeight: "12px",
        textAlign: "center",
        fontWeight: "400px",
    },

    // CONTENEDOR BOTON ENVIAR
    contentButton: {
        width: "136px",
        height: "40px",
        left: "440px",
        top: "408px",
        margin: "auto",
        ">button": {
            background: "#003487",
            paddingX: 5,
            paddingY: 1.1,
            borderRadius: 20,
            boxShadow: "none",
            ":hover": {
                background: "#003487",
                boxShadow: "none",
            },
            ":active": {
                boxShadow: "none",
            },
        },
    },
};

export default formChangePasswordStyles;
