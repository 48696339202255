import { FunctionComponent, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setError } from '../redux/features/auth/authSlice'

interface ValidateRouteProps {
  children: any
  permission: string[]
}

const ValidateRoute: FunctionComponent<ValidateRouteProps> = ({ permission, children }): ReactElement => {
  const { user } = useSelector((state: any) => state.auth)
  const userPermissions = user?.idProfile?.idRol?.idRolPermissions ?? []
  
  const dispatch = useDispatch()

  const isAllowed = userPermissions?.some((item: any) => permission.includes(item.idPermissions))

  !isAllowed && dispatch(setError('Acceso denegado, no tienes permiso para acceder a este espacio'))

  return isAllowed ? children : <Navigate to="/" />
}

export default ValidateRoute
