import { StyleComponent } from '../../../interfaces/styleComponent'

type Keys = 'containerHome' | 'contentIndicatingTask'

const homeStyles: StyleComponent<Keys> = {
  containerHome: {
    backgroundColor: 'f5f5f5',
    // backgroundColor: "pink",
    padding: '0px 70px 0px 70px',

    // RESPONSIVE
    '@media (max-width: 1440px)': {
      padding: '0px 30px 70px 30px',
    },

    '>div': {
      display: 'flex',
      // transition: "1.5s 7s ",

      // RESPONSIVE
      '@media (max-width: 1440px)': {
        flexFlow: 'wrap row',
      },

      '>div': {
        borderRadius: '30px',
        boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',
      },
    },
  },

  contentIndicatingTask: {
    width: '682px',
    boxShadow: 'none !important',

    // RESPONSIVE
    '@media (max-width: 1440px)': {
      // width: "100vw",
      width: '100%',
      display: 'flex',
      flexDirection: 'row',

      // CONTENEDOR TAREAS
      '>.contenedorTareas': {
        marginRight: '0px',
      },
    },

    '@media(max-width: 990px)': {
      flexDirection: 'column',
    },

    '>div': {
      backgroundColor: '#ffffff',
      // backgroundColor: "red",
      height: '286px',
      marginBottom: '44px',
      marginRight: '44px',
      borderRadius: '30px',
      boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.1)',

      // RESPONSIVE
      '@media (max-width: 1440px)': {
        // width: "100vw",
        width: 'inherit',
      },

      // TITULO
      '>.tituloIndicadores, >.tituloTareas': {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '49px',
        marginLeft: '47px',
        marginRight: '47px',
        marginBottom: '25px',
        borderTopRightRadius: '30px',
        '>svg': {
          marginRight: '7px',
        },
        '>p, >a, >div': {
          fontSize: '20px',
          color: '#1F398F',
          fontWeight: '400',
          width: '50%',
          textDecoration: 'none',
          '>div': {
            display: 'flex',
            justifyContent: 'end',
            '>span': {
              backgroundColor: theme => theme.palette.secondary.main,
              width: '27px',
              height: '27px',
              borderRadius: '20px',
              marginRight: '15px',
              textAlign: 'center',
              fontFamily: 'Poppins',
              color: '#ffffff',
            },
          },
        },
      },

      // LISTA
      '>.listaIndicadores, >.listaTareas': {
        marginLeft: '47px',
        marginRight: '27px',
        height: '135px',

        // SCROLLBAR
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '12px',
          height: '100px',
          '&-track': {
            backgroundColor: theme => theme.palette.secondary.main,
            borderRadius: '10px',
            border: '5px solid #ffffff',
          },
          '&-thumb': {
            backgroundColor: '#1F398F',
            borderRadius: '20px',
            width: '10px',
          },
        },
        '>ul': {
          // marginLeft: "47px",
          marginRight: '20px',
          paddingTop: '0px',
          paddingBottom: '0px',
          '>li, >a': {
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',

            // ICONO
            '>svg': {
              color: 'red',
              marginLeft: '3.5px',
              marginRight: '7px',
              '>path': {
                width: '15px',
              },
            },

            // LISTA
            '>div': {
              right: '0px',
              '>span, >div>span': {
                color: '#000000',
                fontSize: '12px',
                fontWeight: '300',
                fontStyle: 'normal',
                lineHeight: '18px',
                textDecorationLine: 'underline',
              },
              '>div': {
                width: '66px',
                '>span': {
                  textDecorationLine: 'none',
                  textAlign: 'Right',
                },
              },
            },
          },
        },
      },

      // LISTA INDICADORES
      '>.listaIndicadores': {
        '>ul': {
          '>li, >a': {
            // LISTA
            '>div': {
              '>span, >div>span': {
                '@media(max-width: 530px)': {
                  // backgroundColor:
                  //     "aqua",
                  width: '65%',
                },
              },
            },
          },
        },
      },
    },
  },
}

export default homeStyles
