import { FunctionComponent } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, InputBase, Button } from "@mui/material";
import useDateField from "../../../../hooks/dateField/useDateField";
import { FormPdiValues } from "../../../../interfaces/pdi/pdi";
import ErrorField from "../../../ErrorField";
import { BranchOffice } from "../../../../interfaces/branchOffice";
import { formPdiSchema } from "../../../../schemas/pdi/pdiSchema";
import { Company } from "../../../../interfaces/company";
import formStyles from "../../form.styles";

interface FormPdiProps {
    branchOffice: BranchOffice;
    company: Company;
    onSubmit: (formValues: FormPdiValues) => void;
    onCancel: () => void;
    defaultValues?: Partial<FormPdiValues>;
}

const _defaultValues: Partial<FormPdiValues> = {
    title: "",
    description: "",
};

const FormPdi: FunctionComponent<FormPdiProps> = ({
    branchOffice,
    company,
    defaultValues = _defaultValues,
    onSubmit,
    onCancel,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<FormPdiValues>({
        mode: "onChange",
        resolver: yupResolver(formPdiSchema),
        defaultValues: {
            ...defaultValues,
            idCompany: company._id
        },
    });

    const StartDateField = useDateField<FormPdiValues>();

    const submit: SubmitHandler<FormPdiValues> = (formValues) =>
        onSubmit(formValues);

    return (
        <>
            {/*****************************
                CONTENEDOR FORMULARIO PDI
            ******************************/}
            <Box
                component="form"
                onSubmit={handleSubmit(submit)}
                sx={formStyles.container}
            >
                {/****************
                    TITULO
                *****************/}
                <Box sx={formStyles.containerTitle}>
                    <Typography sx={formStyles.title}>Título</Typography>

                    {/***********
                        INPUT
                    ***********/}
                    <Box sx={formStyles.input}>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <InputBase
                                    {...field}
                                    disabled={!branchOffice}
                                    sx={formStyles.inputBase}
                                />
                            )}
                        />
                    </Box>

                    {/*********************
                        MENSAJE DE ERROR
                    *********************/}
                    <ErrorField message={errors.title?.message} />
                </Box>

                {/****************
                    DESCRIPCION
                *****************/}
                <Box sx={formStyles.containerTitle}>
                    <Typography sx={formStyles.title}>Descripción</Typography>

                    {/***********
                        INPUT
                    ***********/}
                    <Box sx={formStyles.inputDescription}>
                        <Controller
                            control={control}
                            name="description"
                            render={({ field }) => (
                                <InputBase
                                    {...field}
                                    disabled={!branchOffice}
                                    maxRows={5}
                                    multiline
                                    sx={formStyles.inputBaseDescription}
                                />
                            )}
                        />
                    </Box>

                    {/*********************
                        MENSAJE DE ERROR
                    *********************/}
                    <ErrorField message={errors.description?.message} />
                </Box>

                {/*******************
                    NOMBRE SUCURSAL
                ********************/}
                <Box sx={formStyles.containerTitle}>
                    <Typography sx={formStyles.title}>
                        Nombre de la compañia
                    </Typography>

                    <Typography component={"span"} sx={formStyles.span}>
                        {company?.nameCompany ?? "No disponible"}
                    </Typography>
                </Box>

                {/****************
                    PORCENTAGE DE CUMPLIMIENTO
                *****************/}
                {/* <Box sx={formStyles.title}>
                    <Typography>Porcentaje de cumplimiento</Typography>
                    <Box sx={formStyles.input}>
                        <Controller
                            name="percentageOfCompletion"
                            control={control}
                            render={({ field: { value, ...field } }) => (
                                <InputBase
                                    {...field}
                                    value={value ?? ''}
                                    disabled = {!branchOffice}
                                    placeholder='100%...'
                                    type='number'
                                    sx={formStyles.inputBase}
                                />
                            )}
                        />
                    </Box>
                    <ErrorField message={errors.percentageOfCompletion?.message} />
                </Box> */}

                {/************
                    PERIODO
                **************/}
                <Box sx={formStyles.containerTitle}>
                    <Typography sx={formStyles.title}>Periodo</Typography>

                    {/****************
                        CALENDARIOS
                    *****************/}
                    {/**********************
                        CALENDARIO INICIAL
                    ***********************/}
                    <StartDateField
                        name="startDate"
                        control={{ ...control }}
                        labelText="Fecha inicial"
                        translateCalendar="dateInitial"
                        disabled={!branchOffice}
                    />

                    {/*********************
                        MENSAJE DE ERROR
                    *********************/}
                    <ErrorField message={errors.startDate?.message} />

                    {/*********************
                        CALENDARIO FINAL
                    *********************/}
                    <StartDateField
                        name="finalDate"
                        control={control}
                        labelText="Fecha final"
                        translateCalendar="dateFinal"
                        disabled={!branchOffice}
                    />

                    {/*********************
                        MENSAJE DE ERROR
                    *********************/}
                    <ErrorField message={errors.finalDate?.message} />
                </Box>

                {/**********************
                    CONTENEDOR BOTONES 
                ***********************/}
                <Box sx={formStyles.divButton}>
                    {/**********************************
                        BOTONES ACTUALIZAR/DESACTIVAR
                    ***********************************/}
                    <Box>
                        {/********************
                            BOTON ACTUALIZAR
                        *********************/}
                        <Button
                            disableTouchRipple
                            sx={formStyles.buttonSave}
                            type="submit"
                            disabled={!isValid}
                        >
                            Guardar
                        </Button>

                        {/********************
                            BOTON DESACTIVAR
                        *********************/}
                        <Button
                            onClick={onCancel}
                            disableTouchRipple
                            sx={formStyles.buttonCancel}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FormPdi;
