import { StyleComponent } from '../interfaces/styleComponent'

type Keys = 'containerTitle' | 'title' | 'buttons'

const customTitleStyles: StyleComponent<Keys> = {
  containerTitle: {
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    display: 'flex',
    backgroundColor: 'inherit',

    '>div': {
      margin: 'auto',
      display: 'flex',
    },

    // TITULO
    '>p': {
      color: '#1F398F',
      fontSize: '50px',
      fontWeight: '600',
      lineHeight: '75px',
      fontStyle: 'normal',

      // RESPONSIVE
      '@media (max-width: 565px)': {
        fontSize: '40px',
      },
    },

    // FLECHA
    '&>svg, >div>svg': {
      minWidth: '0px',
      height: 'max-content',
      margin: 'auto 15px auto 25px',
      borderRadius: '20px',
      ':hover': {
        backgroundColor: 'inherit',
      },
    },

    // BOTON SUBTITLE
    '&>button, >div>button': {
      minWidth: '0px',
      height: 'max-content',
      marginY: 'auto',
      padding: 0,
      borderRadius: '20px',
      ':hover': {
        backgroundColor: 'inherit',
      },
      '>p': {
        color: '#7282B8',
        fontSize: '25px',
        fontWeight: '400',
        textTransform: 'capitalize',
      },
    },
  },

  // BOTONES HIJOS
  buttons: {
    // backgroundColor: 'red',
    display: 'flex',
    flexWrap: 'wrap',
    MsFlexWrap: 'wrap',
    WebkitFlexWrap: 'wrap',
    WebkitBoxFlexWrap: 'wrap',

    // BOTON FILTRAR
    '>.buttonFilter': {
      // backgroundColor: 'pink',
      position: 'relative',
      '>.contentListFilter': {
        // backgroundColor: 'red',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        // COLLAPSE
        '>div>div>div': {
          '>ul': {
            // width: '141px',
            overflow: 'hidden',
            margin: '8px auto',
            padding: '0px',
            borderRadius: '7px',
            boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
            '>li': {
              backgroundColor: '#FFFFFF',
              // width: '141px',
              margin: 'auto',

              '>.buttonListFilter': {
                padding: '0px 14px',
                ':hover': {
                  backgroundColor: 'transparent',
                },
                '>div': {
                  // backgroundColor: 'blue',
                  flex: 'initial',
                  borderRadius: '7px',
                  padding: '10px',

                  // HOVER
                  ':hover': {
                    backgroundColor: '#F1F3F8',
                    '>span': {
                      color: '#1F398F',
                      fontWeight: 500,
                    },
                  },

                  '>span': {
                    color: '#242424',
                  },
                },
              },

              // backgroundColor: "#FFF",

              //   color: '#242424',
              //   fontFamily: 'Plus Jakarta Sans',
              //   fontSize: '14px',
              //   fontStyle: 'normal',
              //   fontWeight: 500,
              //   lineHeight: 'normal',
            },

            // COLLAPSE 2
            '>div': {
              backgroundColor: '#FFFFFF',
              '>div>div': {
                '>ul': {
                  paddingY: '0px',
                  '>div': {
                    ':hover': {
                      backgroundColor: 'transparent',
                    },

                    '>div': {
                      borderRadius: '7px',
                      width: '170px',
                      padding: '10px',
                      ':hover': {
                        background: '#F1F3F8',
                        '>span': {
                          color: '#1F398F',
                        },
                      },
                      '>span': {
                        textAlign: 'center',
                        // fontFamily: 'Plus Jakarta Sans',
                        // fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },

    '>button, >.buttonFilter>button': {
      backgroundColor: '#FFFFFF',
      // maxWidth: '144px',
      minHeight: '38px',
      paddingX: '17px',
      marginLeft: '10px',
      marginRight: '10px',
      flexShrink: 0,
      borderRadius: '92px',
      background: '#FFF',
      boxShadow: '0px 0px 10px 0px rgba(114, 130, 184, 0.25)',
      ':hover': {
        backgroundColor: '#7282B8',
        '>p, >svg>path, >svg>circle': {
          color: '#FFFFFF',
          stroke: '#FFFFFF',
        },
      },

      '>p': {
        textTransform: 'capitalize',
        marginLeft: '4px',
      },
    },

    '>.buttonSearch': {
      marginLeft: '10px',
      marginRight: '10px',
      // '>div': {
      //   paddingX: '0px',
      // },
    },
  },
}

export default customTitleStyles
