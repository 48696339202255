import * as React from 'react'
import Popover from '@mui/material/Popover'
import { Box, ListItemText } from '@mui/material'
import IconInfo from '../../../../../components/icons/IconInfo'

export default function WhoNotifyPopopver({ whoNotify, indexInicial = 3 }: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popoverImage' : undefined

  return (
    <span className="info-image-company" title="Mas usuarios">
      <span aria-describedby={id} onClick={handleClick}>
        <IconInfo height={20} width={20} />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            boxShadow:
              '0px 5px 5px -3px rgba(114, 130, 184, 0.1), 0px 8px 10px 1px rgba(114, 130, 184, 0.1), 0px 3px 14px 2px rgba(114, 130, 184, 0.1)',
          },
        }}
      >
        <Box sx={{ padding: '20px 30px' }}>
          <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
            {whoNotify?.slice(indexInicial).map((subItem: any) => (
              <Box>
                <ListItemText secondary={`${subItem.name ?? subItem.idProfile?.name} ${subItem.lastName ?? subItem.idProfile?.lastName}`} />
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </span>
  )
}
