/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material'

// IMPORTADOS
import doAssignmentStyles from '../../do/DoAssignment/DoAssignment.styles'
import CustomTitle from '../../../../components/CustomTitle'
import CustomFormula from '../../../../components/CustomFormula'
import CustomDualGraphic from '../../../../components/Graphic/CustomDualGraphic'
import CustomGraphicDoughnut from '../../../../components/Graphic/CustomGraphicDoughnut'
import { useEffect, useState } from 'react'
import useFormula from '../../../../hooks/do/useFormula'
import { useParams } from 'react-router'
import useGraphics from '../../../../hooks/verify/Graphics/useGraphics'
import { dateToString } from '../../../../services/ParseDate'

const VerifyMeasurementProgress = () => {
  const { fetchGetAcumulative, fetchGetAcumulativePdi } = useFormula()
  const { fetchGetDataGraphicPa, fetchGetDataGraphicPdi } = useGraphics()
  const params: any = useParams()
  // Datas
  const [compliancePdi, setCompliancePdi] = useState<any>({})
  const [compliancePa, setCompliancePa] = useState<any>({})
  const [dataGraphicPa, setDataGraphicPa] = useState<any>({})
  const [dataGraphicPdi, setDataGraphicPdi] = useState<any>({})

  const getAcumulatives = async () => {
    const acumulativePdi = await fetchGetAcumulativePdi(params.idT)
    setCompliancePdi(acumulativePdi)

    const acumulativePa = await fetchGetAcumulative(params.idT)
    setCompliancePa(acumulativePa)
  }

  const getGraphicPa = async () => {
    const dataGraphic = await fetchGetDataGraphicPa(params.idI)
    setDataGraphicPa(dataGraphic)
  }
  const getGraphicPdi = async () => {
    const dataGraphic = await fetchGetDataGraphicPdi(params.idI)
    console.log(dataGraphic)
    setDataGraphicPdi(dataGraphic.newData)
  }

  useEffect(() => {
    getAcumulatives()
    getGraphicPa()
    getGraphicPdi()
  }, [])

  return (
    <>
      {/*********************/}
      {/* TITULO Y FORMULAS */}
      {/*********************/}
      <Box sx={{ ...doAssignmentStyles.container }}>
        {/*********************************/}
        {/* TITULO PROGRESO DEL INDICADOR */}
        {/*********************************/}
        <CustomTitle title="Medición" subTitle={'Progreso del Indicador'} />

        {/************************/}
        {/* CUMPLIMIENTO DEL PDI */}
        {/************************/}
        <CustomFormula
          title="Cumplimiento del Plan de Desarrollo"
          backgroundColor={'#FCCC64'}
          colorText={'#1F398F'}
          numerador={compliancePdi?.accumulated ?? 0}
          denominador={compliancePdi?.goalindicatorPdi ?? 0}
          resultado={Math.round(compliancePdi?.result ?? 0)}
          meta={compliancePdi?.goalindicatorPdi ?? 0}
        />

        {/************************/}
        {/* CUMPLIMIENTO DEL PDA */}
        {/************************/}
        <CustomFormula
          title="Cumplimiento del Plan de Acción"
          numerador={compliancePa?.accumulated ?? 0}
          denominador={compliancePa?.goalindicator ?? 0}
          resultado={Math.round(compliancePa?.result ?? 0)}
          meta={compliancePa?.goalindicator ?? 0}
        />
      </Box>

      {/************/}
      {/* GRAFICAS */}
      {/************/}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginX: '50px',
          '@media(max-width: 1330px)': {
            flexWrap: 'wrap',
          },

          '>div': { marginX: '20px' },
        }}
      >
        {/********************************/}
        {/* GRAFICAS DUAL BARRA Y LINEAL */}
        {/********************************/}
        <Box /* sx={{ maxWidth: '930px', width: '930px' }} */>
          {/*********************/}
          {/* GRAFICAS DUAL PDI */}
          {/*********************/}
          <CustomDualGraphic
            title="Indicador respecto al Plan de desarrollo segun el cumplimiento de cada PA"
            name={dataGraphicPdi?.name}
            valueTs={dataGraphicPdi?.toleranceUpper ?? 0}
            valueTi={dataGraphicPdi?.toleranceLower ?? 0}
            meta={dataGraphicPdi?.goal ?? 0}
            data={dataGraphicPdi ?? {}}
            labelsApi={
              Object.values(dataGraphicPdi?.formulas ?? {}).map(
                (item: any) => dateToString(new Date(item.date * 1000).toISOString()).split('-')[2]
              ) ?? []
            }
            valuesApi={{
              percentajes:
                Object.keys(dataGraphicPdi?.formulas ?? {}).flatMap(
                  (item: any) => dataGraphicPdi?.formulas[item].result
                ) ?? [],
              quantities:
                Object.keys(dataGraphicPdi?.formulas ?? {}).flatMap(
                  (item: any) => dataGraphicPdi?.formulas[item].numerator
                ) ?? [],
            }}
          />

          {/*********************/}
          {/* GRAFICAS DUAL PDA */}
          {/*********************/}
          <CustomDualGraphic
            title="Indicador respecto al Plan de Acción"
            name={dataGraphicPa?.name}
            valueTs={dataGraphicPa?.link_goalpaindicators?.idIndicator?.toleranceUpper ?? 0}
            valueTi={dataGraphicPa?.link_goalpaindicators?.idIndicator?.toleranceLower ?? 0}
            meta={dataGraphicPa?.link_goalpaindicators?.idIndicator?.goal ?? 0}
            data={dataGraphicPa ?? {}}
            labelsApi={
              dataGraphicPa?.link_indicatorformula?.flatMap((item: any) => dateToString(item.readingDate)) ?? []
            }
            valuesApi={{
              percentajes: dataGraphicPa?.link_indicatorformula?.flatMap((item: any) => item.result) ?? [],
              quantities: dataGraphicPa?.link_indicatorformula?.flatMap((item: any) => item.numerator) ?? [],
            }}
          />
        </Box>

        {/*******************/}
        {/* GRAFICAS PASTEL */}
        {/*******************/}
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '25px',
            padding: '44px 25px',
            boxShadow: '0px 0px 10px 5px rgba(114, 130, 184, 0.10)',
            overflow: 'hidden',
            height: 'fit-content',
          }}
        >
          {/* INDICADOR RESPECTO AL PLAN DE ACCION (HOY) */}
          <CustomGraphicDoughnut
            title="Indicador respecto al
            Plan de Desarrollo"
            labelDonut={['Cumplimiento', 'Restante']}
            serieDonut={[Math.round(compliancePdi?.result ?? 0)]}
            hiddenList="none"
          />

          {/* INDICADOR RESPECTO AL PLAN DE ACCION (HOY) */}
          <CustomGraphicDoughnut
            title="Indicador respecto al Plan de Acción (Hoy)"
            labelDonut={['Cumplimiento', 'Restante']}
            serieDonut={[Math.round(compliancePa?.result ?? 0)]}
            hiddenList="none"
          />

          {/* INDICADOR PLANES DE ACCION DEL PDI */}
          <CustomGraphicDoughnut
            title="Indicador respecto al PDI segun el cumplimiento de cada PA"
            labelDonut={
              Object.values(dataGraphicPdi?.formulas ?? {}).map(
                (item: any) => dateToString(new Date(item.date * 1000).toISOString()).split('-')[2]
              ) ?? []
            }
            serieDonut={[1]}
          />
        </Box>
      </Box>
    </>
  )
}

export default VerifyMeasurementProgress
