import { FunctionComponent } from "react";

interface IconInfoProps {
    width?: number | string;
    height?: number | string;
    backgroundColor?: string;
    color?: string;
}

const IconInfo: FunctionComponent<IconInfoProps> = ({
    width = 25,
    height = 25,
    backgroundColor = "#7282B8",
    color = "white",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10.5" cy="10.5" r="10.5" fill={backgroundColor} />
            <path d="M11.0664 5C10.8188 5 10.5813 5.09874 10.4062 5.27451C10.2311 5.45028 10.1328 5.68867 10.1328 5.93724C10.1328 6.18582 10.2311 6.42421 10.4062 6.59998C10.5813 6.77574 10.8188 6.87449 11.0664 6.87449C11.314 6.87449 11.5515 6.77574 11.7266 6.59998C11.9016 6.42421 12 6.18582 12 5.93724C12 5.68867 11.9016 5.45028 11.7266 5.27451C11.5515 5.09874 11.314 5 11.0664 5ZM10.8423 7.98044C10.1017 8.04292 8.07882 9.66123 8.07882 9.66123C7.95434 9.75495 7.99168 9.7487 8.09127 9.92366C8.19086 10.0924 8.17841 10.1049 8.29667 10.0236C8.42115 9.9424 8.62654 9.81119 8.96887 9.59874C10.2884 8.74898 9.18049 10.7109 8.61409 14.0163C8.39003 15.6533 9.85891 14.8098 10.2386 14.5599C10.612 14.3162 11.6141 13.6226 11.7137 13.5539C11.8506 13.4602 11.751 13.3852 11.6452 13.229C11.5705 13.1228 11.4958 13.1978 11.4958 13.1978C11.0913 13.4664 10.3506 14.0288 10.251 13.6726C10.1328 13.3165 10.8921 10.8734 11.3091 9.1926C11.3776 8.79271 11.5643 7.91795 10.8423 7.98044Z" fill={color} />
        </svg>

    );
};

export default IconInfo;
