import { FunctionComponent } from 'react'
interface IconCircleCloseModalProps {
  width?: number | string
  height?: number | string
}

const IconCircleClose: FunctionComponent<IconCircleCloseModalProps> = ({ width = 26, height = 25 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.28997 8.28736C8.73389 7.84358 9.45362 7.84358 9.89753 8.28736L12.5039 10.8929L15.1103 8.28736C15.5542 7.84358 16.2739 7.84358 16.7179 8.28736C17.1618 8.73114 17.1618 9.45065 16.7179 9.89442L14.1115 12.5L16.7179 15.1055C17.1618 15.5493 17.1618 16.2688 16.7179 16.7126C16.2739 17.1564 15.5542 17.1564 15.1103 16.7126L12.5039 14.107L9.89753 16.7126C9.45362 17.1564 8.73389 17.1564 8.28997 16.7126C7.84605 16.2688 7.84605 15.5493 8.28997 15.1055L10.8963 12.5L8.28997 9.89442C7.84605 9.45065 7.84605 8.73114 8.28997 8.28736Z"
        fill="#7282B8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5039 2.27273C6.85378 2.27273 2.27344 6.85163 2.27344 12.5C2.27344 18.1484 6.85378 22.7273 12.5039 22.7273C18.1541 22.7273 22.7344 18.1484 22.7344 12.5C22.7344 6.85163 18.1541 2.27273 12.5039 2.27273ZM0 12.5C0 5.59644 5.5982 0 12.5039 0C19.4096 0 25.0078 5.59644 25.0078 12.5C25.0078 19.4036 19.4096 25 12.5039 25C5.5982 25 0 19.4036 0 12.5Z"
        fill="#7282B8"
      />
    </svg>
  )
}

export default IconCircleClose
