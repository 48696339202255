import React, { useEffect, useState } from 'react'
import { Box, Button, Pagination, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// IMPORTADOS
import usersStyles from '../../users/users.styles'
import Spinner from '../../../../components/utilities/Spinner'
// import useMeasure from '../../../../hooks/do/useMeasure'
import IconAdd from '../../../../components/icons/IconAdd'
import CustomTitle from '../../../../components/CustomTitle'
import ButtonSearch from '../../../../components/buttons/ButtonSearch'
import IconOpenEye from '../../../../components/icons/IconOpenEye'
import ButtonFilter from '../../../../components/buttons/buttonFilter/ButtonFilter'
import { filterIndicators, filterTrafficLights } from '../../../../components/data/dataAPI'
import useMeasurement from '../../../../hooks/verify/measure/useMeasurement'
import { dateToString } from '../../../../services/ParseDate'

const VerifyMeasurement = () => {
  const [list, setList] = useState<any[]>([])
  const [pages, setPages] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit /* setLimit */] = useState<number>(10)
  const [, /* from */ setfrom] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const [valueFilter, setValueFilter] = useState<any>()

  const { getAssignments } = useMeasurement()
  const navigate = useNavigate()

  const getList = async () => {
    setLoading(true)
    const response: any = await getAssignments()
    setList(response.items ?? [])
    const pages = response.items?.length / limit

    if (Number.isInteger(pages)) {
      setPages(pages)
    } else {
      setPages(Math.trunc(pages) + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    getList()
    // eslint-disable-next-line
  }, [page])

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setfrom((value - 1) * limit)
    setPage(value)
  }

  const getStatus = (row: any) => {
    const compliance = row.accumulated ?? 0
    const toleranceLower = row.idIndicator?.idGoalpaIndicators[0]?.idIndicator?.toleranceLower ?? 0
    const toleranceUpper = row.idIndicator?.idGoalpaIndicators[0]?.idIndicator?.toleranceUpper ?? 0

    if (compliance < toleranceLower) {
      return '#D94A4A'
    }

    if (compliance >= toleranceLower && compliance < toleranceUpper) {
      return '#FCCC64 '
    }

    if (compliance >= toleranceUpper) {
      return '#7EBD52'
    }
  }

  return (
    <>
      {/**********************************/}
      {/* CONTENEDOR ASIGNACION DE HACER */}
      {/**********************************/}
      <Box sx={usersStyles.container}>
        {/***********************************/}
        {/* CONTENEDOR TITULO Y BOTON CREAR */}
        {/***********************************/}
        <CustomTitle title="Medición" subTitle="Indicadores">
          <>
            {/*****************/}
            {/* BOTON FILTRAR */}
            {/*****************/}
            <ButtonFilter api={filterIndicators} title="Filtrar indicadores" />

            {/***********************/}
            {/* BOTON CREAR EVENTOS */}
            {/***********************/}
            <Button disableTouchRipple onClick={() => navigate('/verify/event')}>
              <IconAdd />
              <Typography>Crear eventos</Typography>
            </Button>

            {/*********************/}
            {/* BOTON VER EVENTOS */}
            {/*********************/}
            <Button disableTouchRipple onClick={() => navigate('/verify/event/eventAssigned')}>
              <IconOpenEye width={11} height={11} color="#1F398F" />
              <Typography>Ver eventos</Typography>
            </Button>

            {/*****************/}
            {/* BOTON FILTRAR */}
            {/*****************/}
            <ButtonFilter api={filterTrafficLights} title="Filtrar semaforo" setValue={setValueFilter} width="230px" />

            <ButtonSearch />
          </>
        </CustomTitle>

        {/********************************/}
        {/* TABLA DE ASIGNACION DE HACER */}
        {/********************************/}
        <Box sx={usersStyles.userTable}>
          <table>
            {/***********/}
            {/* TITULOS */}
            {/***********/}
            <thead>
              <tr>
                <th>Nombre del indicador</th>
                <th>Meta PA</th>
                <th>Frecuencia de medición</th>
                <th>Fecha de Iniciación</th>
                <th></th>
              </tr>
            </thead>

            {/********************************/}
            {/* LISTA DE ASIGNACION DE HACER */}
            {/********************************/}
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} rowSpan={5}>
                    <Box
                      sx={{
                        height: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </Box>
                  </td>
                </tr>
              ) : (
                list.map((row, index) => (
                  <tr key={row._id}>
                    <td>
                      <Box>{row?.idIndicator?.name || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Box>{row?.idIndicator?.idGoalpaIndicators[0]?.idGoalPa[0]?.title || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <Box>{row?.idIndicator?.idMeasurementFrecuency?.name || 'Sin Datos'}</Box>
                    </td>
                    <td>
                      <Box>{dateToString(row?.complianceDate) || 'Sin Datos'}</Box>
                    </td>

                    <td>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          /* onClick={() => navigate(`./indicating/${row._id}`)} */
                          onClick={() => navigate(`progress/${row._id}/${row.idIndicator?._id}`)}
                          disableTouchRipple
                          sx={{
                            backgroundColor: '#7282B8',
                            borderRadius: '92px',
                            width: '94px',
                            color: '#ffffff !important',
                            marginRight: '20px !important',
                            ':hover': {
                              backgroundColor: '#7282B8',
                            },
                          }}
                        >
                          Progreso
                        </Button>

                        <Typography
                          sx={{
                            display: 'inline-flex',
                            backgroundColor: () => getStatus(row),
                            borderRadius: '92px',
                            width: '18px',
                            height: '18px',
                            color: '#ffffff !important',
                            filter: `drop-shadow(0px 0px 10px ${getStatus(row)})`,
                          }}
                        ></Typography>
                      </Box>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>

        {/**************/}
        {/* PAGINACION */}
        {/**************/}
        <Box sx={usersStyles.pagesList}>
          <Pagination count={pages} page={page} onChange={changePage} />
        </Box>
      </Box>
    </>
  )
}

export default VerifyMeasurement
