import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useDepartament from '../../../../../hooks/departament/useDepartament'
import useFactor from '../../../../../hooks/family/useFactor'
import { Area } from '../../../../../interfaces/area'
import { FormFactorValues } from '../../../../../interfaces/factor'
import FormFactor from '../../../../../components/forms/family/formFactor/FormFactor'
import { Box, Fade } from '@mui/material'
import useAppDialog from '../../../../../hooks/useAppDialog'

const CreateFactor: FunctionComponent = () => {
  const navigate = useNavigate()

  const { fetchDepartaments } = useDepartament()

  const { createNewFactor } = useFactor()

  const [areas, setAreas] = useState<Area[]>([])

  const { showLoadingDialog, hideDialog } = useAppDialog()

  const toFactors = () => navigate('./../')

  useEffect(() => {
    showLoadingDialog()
    fetchDepartaments()
      .then(({ items }) => {
        if (!items.length) {
          return showLoadingDialog({
            title: 'No puedes crear factores!.',
            text: 'Actualmente no existen áreas activas para crear condiciones. por favor, contactar con soporte.',
            didClose: toFactors,
          })
        }
        hideDialog()
        setAreas(items)
      })
      .catch(error => {
        showLoadingDialog({
          title: error?.message,
          text: 'Ha ocurrido un error al cargar las áreas. por favor, contactar con soporte.',
          didClose: toFactors,
        })
      })
  }, [])

  const handleSubmit = (formValues: FormFactorValues, reset: () => void) => {
    createNewFactor(formValues, reset)
  }

  return (
    <Fade in={Boolean(areas.length)}>
      <Box>
        <FormFactor areas={areas} onSubmit={handleSubmit} onCancel={toFactors} />
      </Box>
    </Fade>
  )
}

export default CreateFactor
