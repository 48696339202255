import { FunctionComponent } from "react";

interface IconArrowRightProps {
    width?: number | string;
    height?: number | string;
}

const IconArrowRight: FunctionComponent<IconArrowRightProps> = ({
    width = 11,
    height = 9,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 20L9 11L2 2"
                stroke="#1F398F"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconArrowRight;
