import { Box, Typography, Button, Pagination, Grow } from "@mui/material";
import { FunctionComponent, memo, useState } from "react";
import IconDownload from "../../../icons/IconDownload";
import tablePdiStyles from "./tablePdi.styles";
import { Pdi } from "../../../../interfaces/pdi/pdi";
import dayjs from "dayjs";
import tableProcessStyles from "../../../../pages/private/plan/process/tableProcess.styles";
import usePagination from "../../../../hooks/usePagination";
import { growTimeoutEffect } from "../../../../helpers/efects";
import ConfirmAlert, {
    ConfirmAlertprops,
} from "../../../Alerts/confirmAlert/ConfirmAlert";
import paginationStyles from "../../../Pagination.styles";

interface TablePdisProps {
    pdis: Pdi[];
    onUpdate: (pdi: Pdi) => void;
    onDisable: (pdi: Pdi) => void;
}

const itemsPerPage = 9;

const TablePdis: FunctionComponent<TablePdisProps> = ({
    pdis,
    onUpdate,
    onDisable,
}) => {
    const { countPage, currentData, currentPage, handleChangePage } =
        usePagination(pdis, itemsPerPage);

    const [confirmAlertData, setConfirmAlertData] = useState<ConfirmAlertprops>(
        {
            show: false,
            confirmMessage: "Estas seguro que deseas desactivar este PDI?",
            confirmMessageBottom:
                "Recuerde que si desactiva este PDI usted no podrá editarlo",
            textButtonActionConfirm: "Si",
            textButtonActionCancel: "No",
        }
    );

    const handleConfirmAlertData = (pdi: Pdi) => {
        setConfirmAlertData((state) => ({
            ...state,
            show: true,
            onClickButtonActionConfirm: () => {
                setConfirmAlertData((state) => ({ ...state, show: false }));
                onDisable(pdi);
            },
        }));
    };

    const getYear = (timestamp: number) => {
        return dayjs.unix(timestamp).format("YYYY");
    };

    const getFullDate = (timestamp: number) => {
        return dayjs.unix(timestamp).format("DD/MM/YYYY");
    };

    return (
        <>
            {/***************************
                ALERTA DE CONFIRMACIÓN
            ***************************/}
            <ConfirmAlert
                {...confirmAlertData}
                onClickButtonActionCancel={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
                onCloseForButtom={() =>
                    setConfirmAlertData((state) => ({ ...state, show: false }))
                }
            />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/*************************
                    CONTENEDOR CARD PDA
                *************************/}
                <Box sx={tablePdiStyles.container}>
                    {/************
                        CARD PDA
                    *************/}
                    {currentData().map((pdi, index) => (
                        <Grow
                            key={pdi._id}
                            in={true}
                            timeout={growTimeoutEffect(index)}
                        >
                            <Box key={pdi._id} sx={{ ...tablePdiStyles.card }}>
                                {/**************************
                                    CONTENEDOR TITULO CARD
                                ***************************/}
                                <Box sx={tablePdiStyles.title}>
                                    {/***************
                                        TITULO PDI
                                    ****************/}
                                    <Typography>
                                        {pdi.is_active ? "Activo" : "Inactivo"}
                                    </Typography>

                                    {/*******************
                                        BOTON DESCARGAR
                                    *********************/}
                                    <Button disableRipple>
                                        <IconDownload />
                                    </Button>
                                </Box>

                                {/***************************
                                    NOMBRE Y FECHA CARD PDI
                                ****************************/}
                                <Box sx={tablePdiStyles.nameAndDate}>
                                    {/*******************
                                        NOMBRE CARD PDI
                                    ********************/}
                                    <Typography sx={tablePdiStyles.name} noWrap>
                                        {pdi.title}
                                    </Typography>

                                    {/*******************
                                        FECHA CARD PDI
                                    ********************/}
                                    <Typography sx={tablePdiStyles.date}>
                                        {`${getYear(pdi.startDate)} - ${getYear(
                                            pdi.finalDate
                                        )}`}
                                    </Typography>

                                    <Box
                                        sx={
                                            tablePdiStyles.percentageOfCompletionContainer
                                        }
                                    >
                                        <Typography
                                            sx={
                                                tablePdiStyles.percentageOfCompletionTitle
                                            }
                                        >
                                            Porcentaje de cumplimiento:
                                        </Typography>
                                        <Typography
                                            sx={
                                                tablePdiStyles.percentageOfCompletionValue
                                            }
                                        >
                                            {`${pdi.percentageOfCompletion}%`}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/**********************
                                    CONTENEDOR BOTONES 
                                ***********************/}
                                <Box sx={tablePdiStyles.buttonsContainer}>
                                    {/**********************************
                                        BOTONES ACTUALIZAR/DESACTIVAR
                                    ***********************************/}
                                    <div>
                                        {/********************
                                            BOTON ACTUALIZAR
                                        *********************/}
                                        <Button
                                            disableTouchRipple
                                            onClick={() => onUpdate(pdi)}
                                            sx={{
                                                ...tablePdiStyles.buttonUpdate,
                                                display: pdi.is_active
                                                    ? ""
                                                    : "none",
                                            }}
                                        >
                                            Actualizar
                                        </Button>

                                        {/********************
                                            BOTON DESACTIVAR
                                        *********************/}
                                        <Button
                                            onClick={() =>
                                                handleConfirmAlertData(pdi)
                                            }
                                            disableTouchRipple
                                            sx={{
                                                ...tablePdiStyles.buttonDisable,
                                                display: pdi.is_active
                                                    ? ""
                                                    : "none",
                                            }}
                                        >
                                            {/* Desactivar */}
                                            Desactivar
                                        </Button>
                                    </div>

                                    {/**************
                                        FECHA CARD
                                    ***************/}
                                    <Typography sx={tablePdiStyles.fechaCard}>
                                        {`${getFullDate(pdi.finalDate)}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grow>
                    ))}
                    {pdis.length == 1 && (
                        <>
                            <Box />
                            <Box />
                        </>
                    )}
                    {pdis.length == 2 && <Box />}
                </Box>

                {/****************
                    PAGINACION
                ****************/}
                <Box sx={tableProcessStyles.pagesList}>
                    <Pagination
                        sx={paginationStyles.paginationNumbers}
                        count={countPage}
                        page={currentPage}
                        onChange={(e, _page) => handleChangePage(_page)}
                    />
                </Box>
            </Box>
        </>
    );
};

export default memo(TablePdis);
